export const applicantsPerRole = [
  { role: "Software Engineer", applicants: 112 },
  { role: "Product Manager", applicants: 90 },
  { role: "Data Scientists", applicants: 88 },
  { role: "UX Designer", applicants: 65 },
  { role: "Marketing Specialist", applicants: 75 },
  { role: "Business Analyst", applicants: 94 },
  { role: "Operations Manager", applicants: 40 },
];

export const applicationSourceData = [
  { name: "Referrals", value: 5 },
  { name: "Company Website", value: 20 },
  { name: "ATS", value: 25 },
  { name: "Sprouts Sourced", value: 50 },
];

export const candidatePipeline = [
  {
    name: "Software Engineer",
    applied: 120,
    phoneScreen: 60,
    interview: 25,
    offer: 10,
  },
  {
    name: "Product Manager",
    applied: 95,
    phoneScreen: 70,
    interview: 45,
    offer: 18,
  },
  {
    name: "Data Analyst",
    applied: 85,
    phoneScreen: 65,
    interview: 40,
    offer: 15,
  },
  {
    name: "UX Designer",
    applied: 65,
    phoneScreen: 50,
    interview: 30,
    offer: 10,
  },
  {
    name: "Marketing Specialist",
    applied: 75,
    phoneScreen: 55,
    interview: 35,
    offer: 12,
  },
  {
    name: "Sales Representative",
    applied: 110,
    phoneScreen: 80,
    interview: 50,
    offer: 20,
  },
  {
    name: "Data Scientist",
    applied: 130,
    phoneScreen: 100,
    interview: 25,
    offer: 5,
  },
];

export const referralConversionRate = [
  { month: "Jan", rate: 0.15 },
  { month: "Feb", rate: 0.18 },
  { month: "Mar", rate: 0.2 },
  { month: "Apr", rate: 0.17 },
  { month: "May", rate: 0.23 },
  { month: "Jun", rate: 0.25 },
  { month: "Jul", rate: 0.28 },
  { month: "Aug", rate: 0.22 },
  { month: "Sep", rate: 0.26 },
  { month: "Oct", rate: 0.24 },
  { month: "Nov", rate: 0.19 },
  { month: "Dec", rate: 0.21 },
];

export const referralConversionRatesByQuarter = [
  {
    quarter: "Q1",
    SoftwareEngineer: 0.15,
    ProductManager: 0.2,
    DataAnalyst: 0.3,
    MarketingSpecialist: 0.18,
  },
  {
    quarter: "Q2",
    SoftwareEngineer: 0.3,
    ProductManager: 0.13,
    DataAnalyst: 0.18,
    MarketingSpecialist: 0.25,
  },
  {
    quarter: "Q3",
    SoftwareEngineer: 0.2,
    ProductManager: 0.3,
    DataAnalyst: 0.27,
    MarketingSpecialist: 0.35,
  },
  {
    quarter: "Q4",
    SoftwareEngineer: 0.25,
    ProductManager: 0.35,
    DataAnalyst: 0.2,
    MarketingSpecialist: 0.3,
  },
];

export const interviewsPerHire = [
  { role: "Product Manager", interviews: 3 },
  { role: "Data Analyst", interviews: 5 },
  { role: "UX Designer", interviews: 4 },
  { role: "Marketing Specialist", interviews: 2 },
  { role: "Sales Representative", interviews: 3 },
  { role: "Software Engineer - 2", interviews: 4 },
  { role: "Human Resources", interviews: 3 },
  { role: "Technical Writer", interviews: 1 },
  { role: "Data Scientist", interviews: 5 },
];

export const newApplications = [
  { role: "Software Engineer", applications: 30 },
  { role: "Product Manager", applications: 20 },
  { role: "Data Analyst", applications: 25 },
  { role: "UX Designer", applications: 15 },
  { role: "Marketing Specialist", applications: 18 },
  { role: "Sales Representative", applications: 22 },
  { role: "Customer Support", applications: 35 },
  { role: "Human Resources", applications: 10 },
  { role: "Technical Writer", applications: 12 },
  { role: "Operations Manager", applications: 14 },
];

export const timeToFill = [
  { role: "Software Engineer", days: 30 },
  { role: "Product Manager", days: 35 },
  { role: "Data Analyst", days: 20 },
  { role: "Sales Representative", days: 15 },
  { role: "Marketing Specialist", days: 40 },
  { role: "Human Resources Manager", days: 55 },
];

export const genderDiversity = [
  { gender: "Male", percentage: 50 },
  { gender: "Female", percentage: 45 },
  { gender: "Non-binary", percentage: 5 },
];

export const diversityMetrics = [
  { race: "White", percentage: 40 },
  { race: "Asian", percentage: 28 },
  { race: "Hispanic", percentage: 20 },
  { race: "Black", percentage: 8 },
  { race: "Middle Eastern", percentage: 2 },
  { race: "Other", percentage: 2 },
];

export const applicantLocations = [
  { location: "California", applicants: 300 },
  { location: "New York", applicants: 250 },
  { location: "Texas", applicants: 200 },
  { location: "Washington", applicants: 80 },
  { location: "Other", applicants: 120 },
];

export const costPerHireData = [
  { department: "Engineering", cost: 5000 },
  { department: "Marketing", cost: 3500 },
  { department: "Sales", cost: 4000 },
  { department: "HR", cost: 3000 },
];
export const totalCostPerHire = [
  { role: "SDE-III", cost: 4000 },
  { role: "Staff Data Scientist", cost: 7000 },
  { role: "Talent Acquisition Lead", cost: 5000 },
  { role: "VP of Engineering", cost: 12000 },
];

export const metricData = [
  { id: "activeJobs", title: "Active Jobs", value: 4, change: "+33%" },
  { id: "newApplicants", title: "New Applicants", value: 5, change: "+25%" },
  { id: "pendingTasks", title: "Pending Tasks", value: 3, change: "0%" },
  { id: "upcomingEvents", title: "Upcoming Events", value: 4, change: "+100%" },
];

export const COLORS = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "#8884D8", // existing colors
  "#FF6384",
  "#36A2EB",
  "#FFCE56",
  "#4BC0C0",
  "#1E90FF", // Dodger Blue
  "#32CD32", // Lime Green
  "#FFA500", // Orange
  "#9370DB", // Medium Purple
  "#FF69B4", // Hot Pink
  "#20B2AA", // Light Sea Green
  "#8A2BE2", // Blue Violet
  "#FF7F50", // Coral
  "#3CB371", // Medium Sea Green
  "#DC143C", // Crimson
  "#4169E1", // Royal Blue
  "#FF4500", // Orange Red
];

export const ROLES = [
  "SoftwareEngineer",
  "ProductManager",
  "DataAnalyst",
  "MarketingSpecialist",
];

export const timeRequiredToHire = [
  {
    role: "SDE-III",
    "Resume Submission": 3,
    "Initial Assessment": 5,
    "Technical Interview": 10,
  },
  {
    role: "Staff Data Scientist",
    "Resume Submission": 2,
    "Initial Assessment": 4,
    "Technical Interview": 9,
  },
  {
    role: "Talent Acquisition Lead",
    "Resume Submission": 1,
    "Initial Assessment": 3,
    "Technical Interview": 7,
  },
  {
    role: "VP of Engineering",
    "Resume Submission": 4,
    "Initial Assessment": 6,
    "Technical Interview": 12,
  },
];

export const qualityOfHire = [
  { role: "SDE-III", score: 8.5 },
  { role: "Staff Data Scientist", score: 9.0 },
  { role: "Talent Acquisition Lead", score: 7.5 },
  { role: "VP of Engineering", score: 9.2 },
];

export const offerAcceptanceRate = [
  { role: "SDE-III", rate: 80 },
  { role: "Staff Data Scientist", rate: 65 },
  { role: "Talent Acquisition Lead", rate: 70 },
  { role: "VP of Engineering", rate: 90 },
];

export const applicationCompletionRates = [
  {
    role: "SDE-III",
    "Resume Submission": 98,
    "Initial Assessment": 80,
    "Technical Interview": 30,
  },
  {
    role: "Staff Data Scientist",
    "Resume Submission": 97,
    "Initial Assessment": 75,
    "Technical Interview": 25,
  },
  {
    role: "Talent Acquisition Lead",
    "Resume Submission": 95,
    "Initial Assessment": 70,
    "Technical Interview": 40,
  },
  {
    role: "VP of Engineering",
    "Resume Submission": 93,
    "Initial Assessment": 65,
    "Technical Interview": 20,
  },
];

export const genderDiversityByRole = [
  { role: "SDE-III", Male: 50, Female: 45, Non_binary: 5 },
  { role: "Staff Data Scientist", Male: 55, Female: 40, Non_binary: 5 },
  { role: "Talent Acquisition Lead", Male: 40, Female: 55, Non_binary: 5 },
  { role: "VP of Engineering", Male: 60, Female: 35, Non_binary: 5 },
];

export const applicantLocationsByRole = [
  {
    role: "SDE-III",
    California: 100,
    New_York: 80,
    Texas: 70,
    Florida: 50,
    Illinois: 30,
    Washington: 20,
    Other: 50,
  },
  {
    role: "Staff Data Scientist",
    California: 90,
    New_York: 85,
    Texas: 65,
    Florida: 45,
    Illinois: 25,
    Washington: 20,
    Other: 40,
  },
  {
    role: "Talent Acquisition Lead",
    California: 110,
    New_York: 75,
    Texas: 55,
    Florida: 40,
    Illinois: 15,
    Washington: 15,
    Other: 40,
  },
  {
    role: "VP of Engineering",
    California: 120,
    New_York: 70,
    Texas: 60,
    Florida: 30,
    Illinois: 20,
    Washington: 10,
    Other: 40,
  },
];

export const tableData = {
  activeJobs: [],
  newApplicants: [],
  pendingTasks: [
    {
      task: "Review resumes",
      dueDate: "2024-07-10",
      assignee: "John Doe",
      priority: "High",
    },
    {
      task: "Schedule interviews",
      dueDate: "2024-07-12",
      assignee: "Jane Smith",
      priority: "Medium",
    },
    {
      task: "Send offer letters",
      dueDate: "2024-07-15",
      assignee: "Mike Johnson",
      priority: "Low",
    },
  ],
  upcomingEvents: [
    {
      event: "Tech Interview Round 1 with Candidate (SDE Role)",
      date: "2024-07-20",
      time: "10:00 AM",
      duration: "1 hour",
      location: "Zoom",
    },
    {
      event: "Interview with Candidate for Marketing Manager Position",
      date: "2024-07-22",
      time: "2:00 PM",
      duration: "1 hour 30 minutes",
      location: "Google Meet",
    },
    {
      event: "Team Debrief Post-Candidate Interviews",
      date: "2024-07-26",
      time: "4:00 PM",
      duration: "45 minutes",
      location: "Zoom",
    },
    {
      event: "Recruitment Strategy Session for Q3",
      date: "2024-07-30",
      time: "11:00 AM",
      duration: "1 hour 30 minutes",
      location: "Zoom",
    },
  ],
};
