import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import { Users, Mail } from "lucide-react";
const core = require("../../../src/lib/core");
import { GrValidate } from "react-icons/gr";
import { ScaleLoader } from "react-spinners";

function ContactSalesModal({ candidateDetail, modalDismiss }) {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [sent, setSent] = useState(false);

  const validateForm = () => {
    let formErrors = { firstName: "", lastName: "", email: "", phone: "" };
    let isValid = true;

    if (firstName.trim() === "") {
      formErrors.firstName = "Name is required";
      isValid = false;
    }

    if (email.trim() === "") {
      formErrors.email = "Work Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formErrors.email = "Email is invalid";
      isValid = false;
    }

    if (phone.trim() !== "") {
      const digitsOnly = phone.replace(/[+\-]/g, "");
      if (
        !/^[+\-\d]+$/.test(phone) ||
        digitsOnly.length < 10 ||
        digitsOnly.length > 15
      ) {
        formErrors.phone = "Enter a valid phone number";
        isValid = false;
      }
    }

    setErrors(formErrors);
    return isValid;
  };
  const [loading, setLoading] = useState(false);
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.CONTROLS_API.SEND_MESSAGE,
        1,
        {
          email: email,
          to: "sales@sproutsai.com",
          message: message,
          subject: "Lead Request - SproutsAI",
          name: firstName,
          phone: phone,
          forReq: "Lead",
        },
      );
      console.log("Response is:", response);
      setSent(true);
    } catch (error) {
      console.error("Error sending the email", error);
      setErrors("An error occurred while sending the email.");
      setLoading(false);
    }
    setLoading(false);
  };

  const handleNameChange = (e) => {
    setFirstName(e.target.value);
    if (errors.firstName) {
      setErrors((prevErrors) => ({ ...prevErrors, firstName: "" }));
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errors.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
    }
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    if (/^[+\-\d]*$/.test(value)) {
      if (value.length <= 15) {
        setPhone(value);
        if (errors.phone) {
          setErrors((prevErrors) => ({ ...prevErrors, phone: "" }));
        }
      }
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  return (
    <>
      {sent ? (
        <>
          <Modal modalDismiss={modalDismiss} noBtns={true}>
            <div className=" h-36 flex  items-center justify-center space-y-5 animate-fadeIn duration-300 ease-in-out">
              <svg
                className="row-span-3 flex  items-center justify-center mx-10"
                xmlns="http://www.w3.org/2000/svg"
                width="84"
                height="84"
                viewBox="0 0 72 72"
                fill="none"
              >
                <path
                  d="M34.1841 1.38145C35.2573 0.565137 36.7433 0.565137 37.8165 1.38145L42.9371 5.27629C43.5634 5.75265 44.3517 5.96389 45.1323 5.86449L51.5142 5.05174C52.8518 4.88139 54.1387 5.62438 54.66 6.86794L57.1471 12.8012C57.4513 13.5269 58.0284 14.104 58.7541 14.4082L64.6874 16.8954C65.9309 17.4166 66.6739 18.7035 66.5036 20.0411L65.6908 26.423C65.5914 27.2036 65.8027 27.992 66.279 28.6182L70.1739 33.7388C70.9902 34.812 70.9902 36.298 70.1739 37.3712L66.279 42.4918C65.8027 43.118 65.5914 43.9064 65.6908 44.687L66.5036 51.0689C66.6739 52.4065 65.9309 53.6934 64.6874 54.2146L58.7541 56.7018C58.0284 57.006 57.4513 57.5831 57.1471 58.3088L54.66 64.2421C54.1387 65.4856 52.8518 66.2286 51.5142 66.0583L45.1323 65.2455C44.3517 65.1461 43.5634 65.3574 42.9371 65.8337L37.8165 69.7286C36.7433 70.5449 35.2573 70.5449 34.1841 69.7286L29.0636 65.8337C28.4373 65.3574 27.6489 65.1461 26.8684 65.2455L20.4864 66.0583C19.1488 66.2286 17.8619 65.4856 17.3407 64.2421L14.8535 58.3088C14.5494 57.5831 13.9722 57.006 13.2466 56.7018L7.31325 54.2146C6.0697 53.6934 5.32671 52.4065 5.49705 51.0689L6.3098 44.6869C6.4092 43.9064 6.19796 43.118 5.7216 42.4918L1.82676 37.3712C1.01045 36.298 1.01045 34.812 1.82676 33.7388L5.7216 28.6182C6.19796 27.992 6.4092 27.2036 6.3098 26.4231L5.49705 20.0411C5.32671 18.7035 6.0697 17.4166 7.31325 16.8954L13.2466 14.4082C13.9722 14.104 14.5494 13.5269 14.8535 12.8012L17.3407 6.86794C17.8619 5.62438 19.1488 4.88139 20.4864 5.05174L26.8684 5.86449C27.6489 5.96389 28.4373 5.75265 29.0636 5.27629L34.1841 1.38145Z"
                  fill="#00AC85"
                />
                <path
                  d="M35.9642 37.2984L37.4437 38.8041C37.8356 39.203 38.4784 39.203 38.8703 38.8041L50.1869 27.2862C50.8007 26.6615 51.8076 26.6615 52.4214 27.2862C53.0202 27.8956 53.0202 28.8724 52.4214 29.4818L39.5836 42.548C38.7998 43.3457 37.5142 43.3457 36.7304 42.548L29.3527 35.039C28.7539 34.4296 28.7539 33.4529 29.3527 32.8435C29.9665 32.2187 30.9734 32.2187 31.5872 32.8435L33.7699 35.0651L35.9642 37.2984ZM37.0427 33.9231C36.4458 33.3155 36.4457 32.3416 37.0426 31.7339L42.5434 26.1342C43.1555 25.5111 44.1596 25.511 44.7717 26.1341C45.3687 26.7417 45.3688 27.7156 44.7718 28.3232L39.2711 33.923C38.659 34.5461 37.6549 34.5461 37.0427 33.9231ZM30.4994 40.6697C31.0977 41.2786 31.0977 42.2547 30.4994 42.8636C29.886 43.488 28.8798 43.488 28.2664 42.8636L20.5786 35.039C19.9798 34.4296 19.9803 33.4524 20.5791 32.843C21.1924 32.2188 22.1988 32.2183 22.8121 32.8425L23.8894 33.9389C23.8898 33.9393 23.8898 33.94 23.8894 33.9405C23.8889 33.9409 23.8889 33.9416 23.8894 33.9421L30.4994 40.6697Z"
                  fill="white"
                  stroke="white"
                  strokeWidth="0.2"
                />
              </svg>
              <h1 className=" flex items-center pb-5  text-black text-base font-medium w-[calc(100%-32px)] not-italic">
                We’ll connect with you shortly!
              </h1>
            </div>
          </Modal>
        </>
      ) : (
        <Modal
          heading={"Unlock Candidate Profiles"}
          modalDismiss={modalDismiss}
          noBtns={true}
        >
          <p className="text-gray-600 mb-6">
            Contact our sales team to unlock access to our extensive database of
            pre-screened candidates.
          </p>

          <div className="space-y-4 mb-4">
            <div className="flex space-x-4">
              <div className="w-full">
                <label
                  htmlFor="firstName"
                  className="block text-sm font-medium text-gray-700 mb-1 required"
                >
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter your Name"
                  value={firstName}
                  onChange={handleNameChange}
                  className={`w-full px-4 py-2 border ${errors.firstName ? "border-red-500" : "border-gray-500"} rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition duration-200 placeholder:text-gray-500`}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-xs mt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
            </div>

            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700 mb-1 required"
              >
                Email
              </label>
              <input
                type="email"
                placeholder="Enter your Work Email"
                value={email}
                onChange={handleEmailChange}
                className={`w-full px-4 py-2 border ${errors.email ? "border-red-500" : "border-gray-500"} rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition duration-200 placeholder:text-gray-500`}
              />
              {errors.email && (
                <p className="text-red-500 text-xs mt-1">{errors.email}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700 mb-1 "
              >
                Phone Number
              </label>
              <input
                type="text"
                placeholder="Enter your Phone Number"
                value={phone}
                onChange={handlePhoneChange}
                className={`w-full px-4 py-2 border  ${errors.phone ? "border-red-500" : "border-gray-500"} rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition duration-200 placeholder:text-gray-500`}
              />
              {errors.phone && (
                <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
              )}
            </div>

            <div>
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700 mb-1"
              >
                Message
              </label>
              <textarea
                placeholder="Enter your message"
                value={message}
                onChange={handleMessageChange}
                className="w-full px-4 py-2 border border-gray-500 rounded-lg focus:ring-1 focus:ring-blue-500 focus:border-blue-500 outline-none transition duration-200 placeholder:text-gray-500 h-28 overflow-y-auto resize-none"
              />
            </div>
          </div>

          <button
            onClick={handleFormSubmit}
            className="flex w-full items-center justify-center bg-blue-800 text-white py-2 px-4 rounded-lg hover:bg-blue-900 transition duration-300 mb-6"
          >
            {loading ? (
              <>
                <ScaleLoader height={14} color="#ffffff" loading={true} />
              </>
            ) : (
              <>
                <div className="flex items-center justify-center font-medium">
                  <Mail size={20} className="mr-2" />
                  Send
                </div>
              </>
            )}
          </button>

          <ul className="space-y-3">
            <li className="flex items-center text-gray-700">
              <Users size={20} className="mr-2 text-green-500" />
              Access to 50,000+ vetted profiles
            </li>
            <li className="flex items-center text-gray-700">
              <Users size={20} className="mr-2 text-green-500" />
              Customized candidate matching
            </li>
          </ul>
        </Modal>
      )}
    </>
  );
}

export default ContactSalesModal;
