import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ProfileSection from "../../../components/recruiter/candidate/Profile";
import ResumeTab from "../../../components/recruiter/candidate/Resume";
import ActivityTab from "../../../components/recruiter/candidate/activity/ActivityTab";
import SchedulingInterview from "../../../components/recruiter/candidate/interview/SchedulingInterview";
import InterviewTestTab from "../../../components/recruiter/candidate/interview/InterviewTestTab";
import { CandidatePageContext } from "../../../lib/context";
import JobSidebar from "../../../components/all/JobSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
import TopBanner from "../../../components/recruiter/candidate/TopBanner";
import { ScaleLoader } from "react-spinners";
import ScheduleInterview from "../../../components/recruiter/applicant/ScheduleInterview";
import InviteModal from "../../../components/recruiter/job/assessment/InviteModal";
import NotesModal from "../../../components/recruiter/candidate/notes/NotesModal";
import Modal from "../../../components/modal/Modal";
import {
  fetchApplicantDetails,
  fetchCandidateActivity,
} from "../../../redux/services";

const core = require("../../../lib/core");

const CandidatePage = () => {
  const dispatch = useDispatch();
  const appId = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.applicant_id,
  );
  const candId = useParams().candidateId;
  const [activeTab, setActiveTab] = useState(<ProfileSection />);
  const [reload, setReload] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  // const candId = params?.candidateId;
  useEffect(() => {
    getApplicantsDetails();
  }, [dispatch, reload]);

  const getApplicantsDetails = async () => {
    dispatch(fetchApplicantDetails({ candId: candId }));
    appId && dispatch(fetchCandidateActivity({ appId: appId }));
  };
  const btnList = [
    {
      name: "Details",
      component: <ProfileSection />,
    },
    { name: "Resume", component: <ResumeTab /> },
    { name: "Activity", component: <ActivityTab /> },
    {
      name: "Test & Interview",
      component: <InterviewTestTab reload={reload} />,
    },
    { name: "Schedule Interview", component: <SchedulingInterview /> },
    // {
    //   name: "Analytics",
    //   component: <CandidateAnalytics />,
    // },
  ];

  const deleteNotes = async (id) => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.DELETE,
        core.CANDIDATE_API.PUT_CANDIDATE_NOTES + id,
        1,
      );
      setLoading(false);
      if (json?.message == "OK") {
        dispatch(fetchCandidateActivity({ appId: appId }));
        setMessage({ type: "success", message: "Note deleted successfully" });
        setTimeout(() => {
          setMessage({});
          setShowModal(false);
        }, 1000);
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          message: json?.message,
        });
        setTimeout(() => {
          setMessage({});
        }, 1000);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
      setTimeout(() => {
        setMessage({});
      }, 1000);
    }
  };

  return (
    <CandidatePageContext.Provider value={{ setReload, reload, setShowModal }}>
      <main className="relative block w-screen h-screen p-0 overflow-hidden">
        <div className="flex flex-row w-screen h-screen left-0 relative top-0">
          <JobSidebar />
          <div className="w-[calc(100%-350px)] grow h-screen flex flex-col gap-4 pb-4">
            <HeaderNew />
            <div className="w-[calc(100%-16px)] relative grow flex flex-col gap-4 mx-2 rounded-lg bg-[#F3F5F9] scrollbar-candidateflex h-full  overflow-y-auto pt-6">
              <TopBanner
                setActiveTab={setActiveTab}
                btnList={btnList}
                reload={setReload}
              />
              <React.Suspense
                fallback={
                  <div className="w-full h-full flex grow item-center justify-center">
                    <ScaleLoader color="#2563EB" loading={true} />
                  </div>
                }
              >
                <div className="w-[calc(100%-32px)] bg-white rounded-xl m-4">
                  {activeTab}
                </div>
              </React.Suspense>
            </div>
          </div>
        </div>
        {showModal && showModal?.type == "interview" ? (
          <ScheduleInterview
            CandidateId={candId}
            edit={showModal?.edit}
            interviewDetails={showModal?.edit ? showModal?.data : {}}
          />
        ) : showModal?.type == "assessment" ? (
          <InviteModal
            modalDismiss={closeModal}
            assessmentDetails={activeAssessments}
          />
        ) : showModal?.type == "notes" ? (
          <NotesModal
            edit={showModal?.edit ?? false}
            data={showModal?.data ?? ""}
            noteid={showModal?.id ?? ""}
          />
        ) : showModal?.type == "deleteNotes" ? (
          <Modal
            heading={"Delete this note"}
            subheading={"Are you sure you want to delete this note?"}
            modalDismiss={() => setShowModal(false)}
            onSave={() => deleteNotes(showModal?.id)}
            defaultButtons={{
              primaryClass: "hover:!bg-[#ad3333] !bg-red-900 !border-[#ad3333]",
              primaryLabel: loading ? (
                <ScaleLoader height={14} color="#ffffff" loading={true} />
              ) : (
                "Delete"
              ),
              primaryDisabled: loading,
            }}
          >
            {Object.entries(message)?.length > 0 && (
              <span
                className={
                  message.type == "success"
                    ? "text-green-800 font-normal text-sm"
                    : "text-red-800 font-normal text-sm"
                }
              >
                {message.message}
              </span>
            )}
          </Modal>
        ) : null}
      </main>
    </CandidatePageContext.Provider>
  );
};

export default CandidatePage;
