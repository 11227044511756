import React from "react";
import Modal from "../../components/modal/Modal";
import { useState } from "react";
import { useEffect } from "react";
import { ScaleLoader } from "react-spinners";

const core = require("../../lib/core");

function CreateRoleModal({ modalDismiss, Get_all_roles, loading, setLoading }) {
  const [name, setName] = useState();

  const POST_CREATE_ROLES = async () => {
    console.log({
      name: name,
      resourceIds: Object.values(allResources).flatMap((section) =>
        section.filter((item) => item.checked).map((item) => item.id),
      ),
    });
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_CREATE_ROLES,
        1,
        {
          name: name,
          resourceIds: Object.values(allResources).flatMap((section) =>
            section.filter((item) => item.checked).map((item) => item.id),
          ),
        },
      );
      if (json.data) {
        setName("");
        Get_all_roles();
      } else {
        console.log("error");
        Get_all_roles();
      }
    } catch (error) {
      modalDismiss();
    }
  };

  const onCancel = () => {
    modalDismiss();
  };

  const modalElements = {
    heading: "Create new role",
    subheading: "You can create a new role with customized permissions.",
    modalDismiss: () => onCancel(),
    onSave: () => {
      POST_CREATE_ROLES();
    },
    defaultButtons: {
      primaryDisabled: !(name?.length > 0),
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Save"
      ),
    },
    width: "max-w-4xl",
  };

  const [allResources, setAllResources] = useState([]);

  const get_all_resources = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_RESOURCES,
      1,
    );
    if (json?.data) {
      setAllResources(json?.data);
    } else {
    }
  };

  const handleCheckboxChange = (section, id) => {
    setAllResources((prevData) => ({
      ...prevData,
      [section]: prevData[section].map((item) =>
        item.id === id ? { ...item, checked: !item.checked } : item,
      ),
    }));
  };

  useEffect(() => {
    get_all_resources();
  }, []);
  return (
    <Modal {...modalElements}>
      <div className="w-full flex flex-col space-y-4">
        <div>
          <h3 className="text-black text-sm font-normal mb-2">Role name</h3>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          />
        </div>

        <div className="space-y-4">
          {Object.entries(allResources).map(([resourceName, permissions]) => (
            <div
              key={resourceName}
              className="border border-blue-300 rounded-lg overflow-hidden"
            >
              <div className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-50">
                <span className="font-semibold">{resourceName}</span>
                <div className="flex items-center space-x-4">
                  <label className="relative inline-block w-10 h-6 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={permissions.every((item) => item.checked)}
                      onChange={() => {
                        const allChecked = permissions.every(
                          (item) => item.checked,
                        );
                        setAllResources((prev) => ({
                          ...prev,
                          [resourceName]: prev[resourceName].map((item) => ({
                            ...item,
                            checked: !allChecked,
                          })),
                        }));
                      }}
                      className="sr-only"
                    />
                    <div
                      className={`absolute inset-0 rounded-full transition-colors duration-200 ease-in-out ${
                        permissions.every((item) => item.checked)
                          ? "bg-[#1563E9]"
                          : "bg-[#E5E7EB]"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${
                        permissions.every((item) => item.checked)
                          ? "transform translate-x-4"
                          : ""
                      }`}
                    ></div>
                  </label>
                </div>
              </div>
              <div className="px-4 py-2">
                <h3 className="font-semibold mb-4">Permissions include:</h3>
                <div className="grid grid-cols-2 gap-4">
                  {permissions.map((permission) => (
                    <div
                      key={permission.id}
                      className="flex items-start justify-between max-w-[300px]"
                    >
                      <span className="text-sm max-w-[200px] text-wrap">
                        {permission.action.charAt(0).toUpperCase() +
                          permission.action.slice(1)}
                      </span>
                      <label className="relative inline-block w-10 h-6 cursor-pointer">
                        <input
                          type="checkbox"
                          checked={permission.checked}
                          onChange={() =>
                            handleCheckboxChange(resourceName, permission.id)
                          }
                          className="sr-only"
                        />
                        <div
                          className={`absolute inset-0 rounded-full transition-colors duration-200 ease-in-out ${
                            permission.checked ? "bg-[#1563E9]" : "bg-[#E5E7EB]"
                          }`}
                        ></div>
                        <div
                          className={`absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${
                            permission.checked ? "transform translate-x-4" : ""
                          }`}
                        ></div>
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}

export default CreateRoleModal;
