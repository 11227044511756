import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Range, getTrackBackground } from "react-range";
import FormAutocomplete from "../../../createJob/Autocomplete";
// import errorIcon from "../../assets/recruiter/errorIcon.svg";
import { minAlphaNumericVal } from "../../../createJob2/ValidateValues";
import TextAreaBox from "../../../../../layouts/TextAreaBox";
import InputBox from "../../../../../layouts/InputBox";
const core = require("../../../../../lib/core");

export default function FeedbackCriteria({
  analytics,
  classTxt,
  skills,
  setSkills,
}) {
  const [errorList, setErrorList] = useState({});
  // console.log("errorlist", errorList);
  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  const levels = [
    "No experience",
    "Novice",
    "Beginner",
    "Competent",
    "Proficient",
    "Expert",
  ];

  const handleNewCriteria = (data) => {
    try {
      if (data.criteria == "") throw "Empty value, please type a category";
      skills.map((d) => {
        if (checkLabel(d.criteria, d.label) == data.criteria) {
          throw "Criteria already exists";
        }
      });
      setSkills((prev) => [...prev, data]);
      setErrorList((current) => {
        const { criteria, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, criteria: error });
    }
  };
  const handleNewSkill = (parentObj) => {
    try {
      let updatedSkills = skills.map((d) => {
        if (d != parentObj) return d;
        let updatedKeySkills = [
          ...checkLabel(d?.skills, d?.keySkills),
          {
            label: "",
            value: 5,
            comment: "",
          },
        ];
        return { ...d, skills: updatedKeySkills };
      });
      setSkills(updatedSkills);
      return;
    } catch (error) {
      console.error(error);
      // error == "Criteria exists" || "Empty value, please type a category"
      //   ? toast.error(error)
      //   : toast.error("Please try again");
    }
  };
  function handleCriteriaChange(newStr, parentIdx) {
    try {
      skills.map((d) => {
        if (d.criteria == newStr) {
          throw "Criteria already exists";
        }
      });
      let us = [...skills];
      us[parentIdx].criteria = newStr;
      setSkills(us);
      setErrorList((current) => {
        const key = "criteria" + parentIdx;
        const { [key]: val, ...rest } = current;
        return rest;
      });
      if (newStr == "") throw "Empty value, please type a category";
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, ["criteria" + parentIdx]: error });
    }
  }
  const handleChildValueChange = (newObj, [parentIdx, childIdx]) => {
    try {
      let updatedSkills = skills.map((parent, pId) => {
        if (pId === parentIdx) {
          let updatedKeySkills = parent?.skills?.map((child, cId) => {
            if (cId != childIdx) return child;
            return { ...child, ...newObj };
          });
          let values = updatedKeySkills?.map((child) => child?.value);
          // let averageValue = values.reduce((a, b) => a + b) / values.length;
          return { ...parent, skills: updatedKeySkills };
        }
        return parent;
      });

      setSkills(updatedSkills);
      setErrorList((current) => {
        const key = "criteria" + parentIdx + "skill" + childIdx;
        const { [key]: val, ...rest } = current;
        return rest;
      });

      minAlphaNumericVal(newObj?.label);
    } catch (error) {
      console.error(error);
      setErrorList({
        ...errorList,
        ["criteria" + parentIdx + "skill" + childIdx]: error,
      });
    }
  };
  const handleCriteriaDelete = (idx) => {
    try {
      let temp = skills.filter((d, id) => {
        if (id != idx) return d;
      });
      setSkills(temp);
      // setErrorList((current) => {
      //   const key = "criteria" + idx;
      //   const { [key]: val, ...rest } = current;
      //   return rest;
      // });
      setErrorList((current) =>
        Object.keys(current).filter((d) => !d.includes("criteria" + idx)),
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleSkillDelete = ([pIdx, cIdx]) => {
    try {
      let updatedSkills = skills.map((parent, pId) => {
        if (pId != pIdx) return parent;

        let updatedKeySkills = checkLabel(
          parent.skills,
          parent.keySkills,
        ).filter((child, cId) => {
          if (cId != cIdx) return child;
        });
        let averageValue;
        if (updatedKeySkills?.length > 0) {
          let values = updatedKeySkills.map((child) => child.value);
          averageValue = values.reduce((a, b) => a + b) / values.length;
        } else {
          averageValue = 0;
        }
        return { ...parent, skills: updatedKeySkills, value: averageValue };
      });
      setSkills(updatedSkills);
      setErrorList((current) => {
        const key = "criteria" + pIdx + "skill" + cIdx;
        const { [key]: val, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
    }
  };

  const [isDragging, setIsDragging] = useState(false);

  const [listpreferSkill, setListpreferSkill] = useState([]);

  // functions for search the skills
  function searchPreferSkillList(term) {
    try {
      if (term?.length < 1) {
        setListpreferSkill([]);
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.JOB_API.GET_ALL_SKILLS + "?name=" + term,
            1,
            {},
          );
          if (response?.data?.skills?.length > 0) {
            setListpreferSkill(response?.data.skills.map((d) => d.name));
          } else {
            setListpreferSkill([]);
          }
        }, 300);
      }
    } catch (err) {
      setListpreferSkill([]);
    }
  }

  const [allCriterias, setAllCriterias] = useState([]);

  const [criteriaValue, setCriteriaValue] = useState();

  console.log("skills", skills);
  const handleCriteriaValueChange = (value, idx) => {
    try {
      let us = [...skills];
      us[idx].value = value;
      setSkills(us);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCriteriaCommentChange = (value, idx) => {
    try {
      let us = [...skills];
      us[idx].comment = value;
      setSkills(us);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    // analytics != null && (
    <section className={classTxt} id="Skills-section mt-4">
      <div className="w-full data-section no-padding p-4">
        <div className="w-full input-data-section">
          <div className={`w-full`} id="create-skills-section">
            <div className="w-full">
              {skills?.map((skill, it) => (
                <div className="section-skills w-full">
                  <div className="w-full section-header no-padding">
                    <div className="w-1/3 no-padding ">
                      <InputBox
                        type={"text"}
                        required
                        onKeyDown={(e) => {
                          if (e.key == "Enter") {
                            e.preventDefault();
                            handleNewSkill(skill);
                          }
                        }}
                        className="w-full"
                        value={checkLabel(skill.criteria, skill.label)}
                        onChange={(e) => {
                          handleCriteriaChange(e.target.value, it);
                        }}
                      />
                    </div>

                    <div className="w-1/3 flex justify-between gap-3 items-center">
                      <Range
                        step={1}
                        min={0}
                        max={10}
                        values={[Math.floor(skill?.value)]}
                        onChange={(value) => {
                          handleCriteriaValueChange(value[0], it);
                        }}
                        renderTrack={({ props, children }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "8px",
                              width: "calc(100% - 30px)",
                              borderRadius: "4px",
                              right: 0,
                              zIndex: 10,
                              position: "relative",
                              background: getTrackBackground({
                                values: [Math.floor(skill?.value)],
                                colors: ["teal", "#f2f2f2"],
                                min: 0,
                                max: 10,
                              }),
                            }}
                          >
                            {children}
                            {skill?.required &&
                            Math.floor(skill?.required) >= 0 ? (
                              <div
                                className="bg-orange-400"
                                data-tooltip-content={`Job required = ${Math.floor(skill?.required)}`}
                                data-tooltip-id="tooltip"
                                data-tooltip-place="top"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  left: `${Math.floor(skill?.required) * 10}%`,
                                  width: "14px",
                                  height: "14PX",
                                  borderRadius: "4px",
                                  zIndex: 1,
                                  // color of the required score marker
                                  zIndex: 1,
                                }}
                              ></div>
                            ) : null}
                          </div>
                        )}
                        renderThumb={({ props }) => (
                          <div
                            {...props}
                            style={{
                              ...props.style,
                              height: "19px",
                              width: "19px",
                              borderRadius: "33.043px",
                              background: "#FFF",
                              boxShadow:
                                "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                            }}
                          />
                        )}
                      />
                      <span className="inline-block w-[75px] text-ellipsis grow-0 shrink-0">
                        {Math.floor(skill?.value)}
                      </span>
                    </div>
                    <div
                      className="w-[18px] h-[18px] group cursor-pointer"
                      onClick={() => {
                        handleCriteriaDelete(it);
                      }}
                    >
                      <svg
                        className="w-[18px] h-[18px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g id="left icon">
                          <path
                            className="group-hover:stroke-[#910000]"
                            id="Vector"
                            d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                            stroke="#FF475D"
                            strokeLinecap="round"
                          />
                        </g>
                      </svg>
                    </div>
                  </div>

                  <div>
                    <TextAreaBox
                      placeholder={"Type comment"}
                      value={skill?.comment}
                      onChange={(e) =>
                        handleCriteriaCommentChange(e.target.value, it)
                      }
                    />
                  </div>
                  {checkLabel(skill?.skills, skill?.keySkills)?.length > 0 && (
                    <div className="w-full flex justify-between items-center mt-4 text-center text-sm text-gray-700 font-semibold">
                      <span className="w-[30%]">Label</span>
                      <span className="w-[40%]">Score</span>
                    </div>
                  )}

                  <div className="list-section w-full divide-y">
                    {checkLabel(skill.skills, skill.keySkills)?.map((ks, i) => (
                      <>
                        <div className="w-full mb-2 flex justify-between items-center">
                          <div className="w-[30%] name flex justify-between items-center relative">
                            <FormAutocomplete
                              type="text"
                              className="w-[calc(100%-20px)]"
                              defaultValue="Type to search... "
                              idName={"skill" + i + it}
                              required={true}
                              name="skill"
                              value={ks?.label}
                              data={listpreferSkill}
                              inputClassName={
                                Object.keys(errorList).includes(
                                  "criteria" + it + "skill" + i,
                                )
                                  ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                                  : ""
                              }
                              onEnterFunction={(e) => {
                                if (e.key == "Enter") {
                                  handleNewSkill(skill);
                                }
                              }}
                              searchFunction={searchPreferSkillList}
                              selectionFunction={(name, value) => {
                                handleChildValueChange({ label: value }, [
                                  it,
                                  i,
                                ]);
                              }}
                            />
                          </div>
                          <div className="w-[40%] flex justify-between gap-3 items-center">
                            <Range
                              step={1}
                              min={ks?.type ? (ks?.type < 0 ? -10 : 0) : 0}
                              max={ks?.type ? (ks?.type < 0 ? 0 : 10) : 10}
                              values={[Math.floor(ks?.value)]}
                              onChange={(value) => {
                                handleChildValueChange({ value: value[0] }, [
                                  it,
                                  i,
                                ]);
                              }}
                              renderTrack={({ props, children }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: "8px",
                                    width: "calc(100% - 30px)",
                                    borderRadius: "4px",
                                    right: 0,
                                    position: "relative",
                                    background: getTrackBackground({
                                      values: [Math.floor(ks?.value)],
                                      colors: ks?.type
                                        ? ks?.type < 0
                                          ? ["#f2f2f2", "red"]
                                          : ["red", "#f2f2f2"]
                                        : ["teal", "#f2f2f2"],
                                      min: ks?.type
                                        ? ks?.type < 0
                                          ? -10
                                          : 0
                                        : 0,
                                      max: ks?.type
                                        ? ks?.type < 0
                                          ? 0
                                          : 10
                                        : 10,
                                    }),
                                  }}
                                >
                                  {children}
                                  {ks?.required >= 0 && (
                                    <div
                                      className="bg-orange-400"
                                      data-tooltip-content={`Job required = ${Math.floor(ks?.required)}`}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-place="top"
                                      style={{
                                        position: "absolute",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        left: `${Math.floor(ks?.required) * 10}%`,
                                        width: "14px",
                                        height: "14PX",
                                        borderRadius: "4px",
                                        // color of the required score marker
                                        zIndex: 1,
                                      }}
                                    />
                                  )}
                                </div>
                              )}
                              renderThumb={({ props }) => (
                                <div
                                  {...props}
                                  style={{
                                    ...props.style,
                                    height: "19px",
                                    width: "19px",
                                    borderRadius: "33.043px",
                                    background: "#FFF",
                                    boxShadow:
                                      "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                                  }}
                                />
                              )}
                            />
                            <span className="inline-block w-[75px] text-ellipsis grow-0 shrink-0">
                              {ks?.value}
                            </span>
                          </div>
                        </div>
                        {Object.keys(errorList).includes(
                          "criteria" + it + "skill" + i,
                        ) ? (
                          <div className="w-full my-2">
                            <img
                              className="mr-2 w-3.5 h-3.5 inline"
                              // src={errorIcon}
                            />
                            <span className="text-red-800 text-sm">
                              {errorList["criteria" + it + "skill" + i]}
                            </span>
                          </div>
                        ) : null}
                      </>
                    ))}
                  </div>

                  <button
                    className="add-skills-button"
                    type="button"
                    onClick={() => {
                      handleNewSkill(skill);
                    }}
                  >
                    + Add
                  </button>
                </div>
              ))}
            </div>
          </div>

          <InputBox
            type={"text"}
            placeholder={"Add new category and press enter "}
            idName={"Criterias"}
            required={false}
            name={"skill"}
            value={criteriaValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                const inputValue = e.target.value.trim();
                handleNewCriteria({
                  criteria: inputValue,
                  value: 0,
                  skills: [],
                });
                setCriteriaValue("");
                e.target.value = ""; // Clear the input field after adding the skill
              }
            }}
            onChange={(e) => {
              setCriteriaValue(e.target.value);
            }}
          />
        </div>
      </div>
    </section>
    // )
  );
}
