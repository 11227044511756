// src/AvailabilityForm.js
import React, { useState, useEffect } from "react";
import InputBox from "../../layouts/InputBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomSelect from "../../layouts/CustomSelect";
import moment from "moment";

const ToggleButton = ({ isEnabled, toggle }) => {
  return (
    <div
      onClick={toggle}
      className={`relative inline-flex items-center h-6 rounded-full w-11 cursor-pointer ${isEnabled ? "bg-[#1369E9]" : "bg-gray-300"}`}
    >
      <span
        className={`transform transition ease-in-out duration-200 ${isEnabled ? "translate-x-6" : "translate-x-1"} inline-block w-4 h-4 bg-white rounded-full`}
      ></span>
    </div>
  );
};

const AvailabilityForm = ({
  availability,
  setAvailability,
  timeZone,
  setTimeZone,
  isEditing,
  status,
  changeStatus,
  Timings,
}) => {
  // const handleTimeChange = (index, slotIndex, field, value) => {
  //   const updatedAvailability = [...availability];
  //   updatedAvailability[index].slots[slotIndex][field] = value;
  //   setAvailability(updatedAvailability);
  //   console.log(availability)
  // };
  const [warnings, setWarnings] = useState({});
  const addTimeSlot = (index) => {
    const updatedAvailability = [...availability];
    const newSlot = { start_time: "", end_time: "" };

    if (isValidNewSlot(updatedAvailability[index].slots, newSlot)) {
      updatedAvailability[index].slots.push(newSlot);
      setAvailability(updatedAvailability);
    } else {
      setTemporaryWarning(
        index,
        "new",
        "Cannot add overlapping or duplicate time slot.",
      );
    }
  };
  const checkConflictsForDay = (slots) => {
    let conflicts = {};
    slots.forEach((slot, i) => {
      slots.forEach((otherSlot, j) => {
        if (i !== j) {
          if (!isValidNewSlot([slot], otherSlot)) {
            conflicts[`${i}-${j}`] = "Time slot conflict";
          }
        }
      });
    });
    return conflicts;
  };

  const deleteTimeSlot = (index, slotIndex) => {
    const updatedAvailability = [...availability];

    if (updatedAvailability[index].slots.length === 1) {
      updatedAvailability.splice(index, 1);
    } else {
      updatedAvailability[index].slots.splice(slotIndex, 1);
    }

    // Update the availability state
    setAvailability(updatedAvailability);

    // Check for conflicts and update warnings
    const newWarnings = {};
    updatedAvailability.forEach((dateObj, i) => {
      if (dateObj.slots.length > 0) {
        const conflicts = checkConflictsForDay(dateObj.slots);
        Object.keys(conflicts).forEach((key) => {
          newWarnings[`${i}-${key}`] = conflicts[key];
        });
      }
    });

    setWarnings((prev) => {
      const filteredWarnings = { ...prev };
      Object.keys(filteredWarnings).forEach((key) => {
        if (!newWarnings[key]) {
          delete filteredWarnings[key];
        }
      });
      return { ...filteredWarnings, ...newWarnings };
    });

    console.log("warnings", warnings);
  };
  useEffect(() => {
    const hasWarnings = Object.keys(warnings).length > 0;
    console.log("Has warning", hasWarnings, warnings);
    changeStatus(hasWarnings);
  }, [warnings, changeStatus]);

  const addDate = () => {
    let nextDate = new Date();
    nextDate.setDate(nextDate.getDate() + 1);

    while (
      availability.some(
        (item) =>
          new Date(item.date).toDateString() === nextDate.toDateString(),
      )
    ) {
      nextDate.setDate(nextDate.getDate() + 1);
    }

    setAvailability([
      ...availability,
      {
        date: nextDate.toISOString(),
        slots: [{ start_time: "", end_time: "" }],
      },
    ]);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };
  const deleteDate = (index) => {
    const updatedAvailability = availability.filter((_, i) => i !== index);
    setAvailability(updatedAvailability);
  };

  const handleToggleDay = (index) => {
    const updatedAvailability = [...availability];
    if (updatedAvailability[index].slots.length === 0) {
      updatedAvailability[index].slots.push({ start_time: "", end_time: "" });
      updatedAvailability[index].isActive = true;
    } else {
      updatedAvailability[index].slots = [];
      updatedAvailability[index].isActive = false;
    }
    setAvailability(updatedAvailability);
  };
  // function formatTimeWithPeriod(time) {
  //   const [hourStr, minuteStr, period] = time.split(/:/);
  //   const hours = parseInt(hourStr, 10);
  //   const minutes = parseInt(minuteStr, 10);
  //   const formattedHours = hours.toString().padStart(2, "0");
  //   const formattedMinutes = minutes.toString().padStart(2, "0");
  //   const formattedTime = `${formattedHours}:${formattedMinutes} ${period.toUpperCase()}`;
  //   return formattedTime;
  // }

  //
  const setTemporaryWarning = (index, slotIndex, message) => {
    setWarnings((prev) => ({
      ...prev,
      [`${index}-${slotIndex}`]: message,
    }));

    changeStatus(true);
  };
  const timeToMinutes = (timeString) => {
    const [time, period] = timeString.split(" ");
    let [hours, minutes] = time.split(":").map(Number);
    if (period === "PM" && hours !== 12) hours += 12;
    if (period === "AM" && hours === 12) hours = 0;
    return hours * 60 + minutes;
  };

  const handleTimeChange = (index, slotIndex, field, value) => {
    const updatedAvailability = [...availability];
    const currentSlot = { ...updatedAvailability[index].slots[slotIndex] };
    let updatedSlot = { ...currentSlot, [field]: value };

    // Clear the warning for this slot immediately
    setWarnings((prev) => {
      const newWarnings = { ...prev };
      delete newWarnings[`${index}-${slotIndex}`];
      return newWarnings;
    });
    changeStatus(false);

    if (field === "end_time" && currentSlot.start_time === "") {
      setTemporaryWarning(
        index,
        slotIndex,
        "Please select a start time first.",
      );
      return;
    }

    // Validate start and end times
    if (updatedSlot.start_time && updatedSlot.end_time) {
      const startMinutes = timeToMinutes(updatedSlot.start_time);
      const endMinutes = timeToMinutes(updatedSlot.end_time);

      if (startMinutes >= endMinutes) {
        // Set end time to empty string if start time is greater than or equal to end time
        updatedSlot = { ...updatedSlot, end_time: "" };
        setTemporaryWarning(
          index,
          slotIndex,
          "End time must be after start time.",
        );
      }
    }

    if (
      field === "start_time" ||
      (field === "end_time" && currentSlot.start_time !== "")
    ) {
      const otherSlots = updatedAvailability[index].slots.filter(
        (_, i) => i !== slotIndex,
      );

      if (isValidNewSlot(otherSlots, updatedSlot)) {
        updatedAvailability[index].slots[slotIndex] = updatedSlot;
        setAvailability(updatedAvailability);
      } else {
        setTemporaryWarning(
          index,
          slotIndex,
          "This time conflicts with existing slots.",
        );
        return;
      }
    }

    updatedAvailability[index].slots[slotIndex] = updatedSlot;
    setAvailability(updatedAvailability);
  };

  function changeTime(originalISOString, newTime) {
    // Parse the original date string to get the date parts
    const originalDate = new Date(originalISOString);

    // Extract the year, month, and day
    const year = originalDate.getUTCFullYear();
    const month = originalDate.getUTCMonth(); // Note: getUTCMonth() returns month index (0-11)
    const day = originalDate.getUTCDate();

    // Extract hours, minutes, and period from newTime
    const [hourStr, minuteStr, period] = newTime.split(/:/);
    const hours = parseInt(hourStr, 10);
    const minutes = parseInt(minuteStr, 10);
    const isPM = period.toLowerCase() === "pm";

    // Convert to 24-hour format
    const hour24 = isPM ? hours + 12 : hours === 12 ? 0 : hours;

    // Create a new date object with the desired time and same date
    const newDate = new Date(Date.UTC(year, month, day, hour24, minutes));

    return newDate.toISOString();
  }

  // function extractTimeFromISOString(isoString) {
  //   const date = new Date(isoString);
  //   let hours = date.getUTCHours();
  //   const minutes = date.getUTCMinutes();
  //   const isPM = hours >= 12;
  //   hours = hours % 12 || 12;
  //   const formattedHours = hours.toString().padStart(2, "0");
  //   const formattedMinutes = minutes.toString().padStart(2, "0");
  //   const period = isPM ? "PM" : "AM";
  //   return `${formattedHours}:${formattedMinutes} ${period}`;
  // }
  const timeOptions = Array.from({ length: 96 }, (_, index) => {
    const hour = Math.floor(index / 4);
    const minute = (index % 4) * 15;
    const formattedHour = hour.toString().padStart(2, "0");
    const formattedMinute = minute.toString().padStart(2, "0");
    const ampm = hour < 12 ? "AM" : "PM";
    const formattedHour12 = hour % 12 || 12;

    const time = `${formattedHour12}:${formattedMinute} ${ampm}`;
    return {
      value: time,
      label: time,
    };
  });

  const getFilteredTimeOptions = (startTime, isStartTimeSelected) => {
    if (!isStartTimeSelected) {
      return timeOptions;
    } else {
      const startTimeIndex = timeOptions.findIndex(
        (option) => option.value === startTime,
      );
      return startTimeIndex !== -1
        ? timeOptions.slice(startTimeIndex + 1)
        : timeOptions;
    }
  };
  const getAvailableDates = (date) => {
    const selectedDates = availability.map((item) =>
      new Date(item.date).toDateString(),
    );
    return !selectedDates.includes(date.toDateString());
  };
  useEffect(() => {
    if (isEditing && availability.length > 0) {
      const formattedAvailability = availability.map((dateObj) => ({
        date: new Date(dateObj.date).toISOString(),
        slots: dateObj.slots.map((slot) => ({
          start_time: slot.start_time,
          end_time: slot.end_time,
        })),
      }));
      console.log("edit", formattedAvailability);
      setAvailability(formattedAvailability);
    }
  }, [isEditing]);

  const isTimeValid = (startTime, endTime) => {
    if (startTime === "" || endTime === "") return true;
    const convertToMinutes = (timeString) => {
      const [time, period] = timeString.split(" ");
      let [hours, minutes] = time.split(":").map(Number);
      if (period === "PM" && hours !== 12) hours += 12;
      if (period === "AM" && hours === 12) hours = 0;
      return hours * 60 + minutes;
    };
    return convertToMinutes(endTime) > convertToMinutes(startTime);
  };

  const isValidNewSlot = (existingSlots, newSlot) => {
    if (!newSlot.start_time || !newSlot.end_time) return true;

    const newStart = timeToMinutes(newSlot.start_time);
    const newEnd = timeToMinutes(newSlot.end_time);

    if (newStart >= newEnd) return false;

    for (const slot of existingSlots) {
      if (!slot.start_time || !slot.end_time) continue;

      const existingStart = timeToMinutes(slot.start_time);
      const existingEnd = timeToMinutes(slot.end_time);

      // Check for overlap
      if (
        (newStart >= existingStart && newStart < existingEnd) ||
        (newEnd > existingStart && newEnd <= existingEnd) ||
        (newStart <= existingStart && newEnd >= existingEnd)
      ) {
        return false;
      }

      // Check for duplication
      if (newStart === existingStart && newEnd === existingEnd) {
        return false;
      }
    }

    return true;
  };
  const formatDateTime = (dateTime, format) => {
    return moment(dateTime).tz(timeZone).format(format);
  };
  const formatTime = (dateTime) => {
    const time = moment(dateTime).tz(timeZone);
    return time.format("hh:mm A"); // This ensures 12-hour format with AM/PM
  };
  return (
    <div className="mx-auto p-4 flex flex-col-reverse lg:flex-row gap-4  ">
      <form onSubmit={handleSubmit} className="space-y-4 w-full md:w-3/4">
        <div className="flex flex-col gap-8">
          {availability?.map((dateObj, index) => (
            <div key={index}>
              <div className="flex flex-col md:flex-row space-x-4 md:gap-8 items-start md:items-start px-2 md:px-4">
                <div className="w-fit">
                  <div className="flex flex-row items-center mb-2 gap-4 w-fit">
                    <ToggleButton
                      isEnabled={dateObj.slots.length > 0}
                      toggle={() => handleToggleDay(index)}
                      className="mr-4 w-[50px]"
                    />
                    <div className="relative">
                      <DatePicker
                        minDate={new Date()}
                        id="customDatePicker"
                        selected={dateObj?.date ? new Date(dateObj.date) : null}
                        filterDate={getAvailableDates}
                        dateFormat="EEEE, dd MMMM"
                        className={`
                          h-[28px] md:h-[32px] 
                          border border-gray-400 
                          p-2 
                          rounded-lg 
                          text-sm md:text-base 
                          shadow-[0px_2px_0px_rgba(0,0,0,0.04)]  
                          placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]
                          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                          transition-shadow duration-150 ease-in-out
                        `}
                        onChange={(e) => {
                          const updatedAvailability = [...availability];
                          updatedAvailability[index].date = e.toISOString();
                          setAvailability(updatedAvailability);
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex flex-col items-center gap-4 mt-2 md:mt-0">
                  {dateObj?.slots?.map((slot, slotIndex) => (
                    <div
                      key={slotIndex}
                      className="flex space-x-4 items-start gap-4"
                    >
                      <div>
                        <div className="flex space-x-4 items-center gap-4">
                          <div className="relative">
                            <CustomSelect
                              options={timeOptions}
                              value={slot.start_time}
                              onChange={(value) =>
                                handleTimeChange(
                                  index,
                                  slotIndex,
                                  "start_time",
                                  value,
                                )
                              }
                              placeholder="Start Time"
                              className={
                                "w-[90px] md:w-[100px] lg:w-[120px] text-xs md:text-base"
                              }
                            />
                          </div>
                          <div className="flex flex-col ">
                            <div className="relative">
                              <CustomSelect
                                options={getFilteredTimeOptions(
                                  slot.start_time,
                                  slot.start_time !== "",
                                )}
                                value={slot.end_time}
                                onChange={(value) =>
                                  handleTimeChange(
                                    index,
                                    slotIndex,
                                    "end_time",
                                    value,
                                  )
                                }
                                placeholder="End Time"
                                className={
                                  "w-[90px] md:w-[100px] lg:w-[120px]  text-xs md:text-base"
                                }
                              />
                            </div>
                          </div>
                        </div>
                        {warnings[`${index}-${slotIndex}`] && (
                          // <div className="text-red-900 text-xs mt-2">
                          // {warnings[`${index}-${slotIndex}`]}
                          // </div>
                          <div className="flex items-center justify-center bg-yellow-50 border-l-2 md:border-l-4 gap-2 md:gap-4 border-yellow-400 px-2 py-1 mt-2 rounded-r-md">
                            <svg
                              className="h-5 w-5 text-yellow-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <p className="text-xs text-yellow-700 m-0">
                              {warnings[`${index}-${slotIndex}`]}
                            </p>
                          </div>
                        )}
                      </div>
                      <button
                        type="button"
                        onClick={() => deleteTimeSlot(index, slotIndex)}
                        className=" w-[32px] h-[36px] ant-dropdown-trigger candidate-other-status-button border-button rounded-lg border  aspect-square border-gray-500 bg-white  flex items-center justify-center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </button>
                      <div className="ml-auto">
                        <button
                          type="button"
                          onClick={() => addTimeSlot(index)}
                          className="h-[36px]  text-xs md:text-base ant-dropdown-trigger candidate-other-status-button border-button rounded-lg border  px-[6px] md:px-[8px] md:py-1 border-gray-500 bg-white  "
                        >
                          + time slot
                        </button>
                      </div>
                      {warnings[`${index}-new`] && (
                        <div className="text-red-900 text-xs mt-2">
                          {warnings[`${index}-new`]}
                        </div>
                      )}
                    </div>
                  ))}
                </div>

                {/* <button
                                    type="button"
                                    onClick={() => setAvailability(availability.filter((_, i) => i !== index))}
                                    className="bg-red-500 text-white px-4 py-2 rounded"
                                >
                                    Delete Date
                                </button> */}
              </div>
            </div>
          ))}
        </div>
        <button
          type="button"
          onClick={addDate}
          className="bg-[#1369E9] mt-4 text-white px-2 md:px-4 py-2 rounded h-[32px] md:h-[36px] text-xs md:text-base"
        >
          + Add more
        </button>
        {/* <button
                    type="submit"
                    className="bg-[#1369E9] text-white px-4 py-2 rounded"
                >
                    Submit Availability
                </button> */}
      </form>
      <div className="p-4 h-fit bg-white mb-2 border-2 border-gray-300 rounded-lg shadow-xl w-full sm:w-3/4 md:w-2/3 lg:w-1/2 xl:w-1/4 mx-auto">
        <h1 className="text-lg font-semibold text-gray-800 mb-4">
          Interviewer Availability
        </h1>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            Start Date
          </label>
          <InputBox
            type="text"
            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none bg-gray-200 cursor-not-allowed"
            value={formatDateTime(Timings?.startDateTime, "MMM D, YYYY")}
            readOnly={true}
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            End Date
          </label>
          <InputBox
            type="text"
            className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none bg-gray-200 cursor-not-allowed"
            value={formatDateTime(Timings?.endDateTime, "MMM D, YYYY")}
            readOnly={true}
          />
        </div>

        <div className="flex flex-col sm:flex-row sm:space-x-4 mb-4">
          <div className="w-full sm:w-1/2">
            <label className="block text-sm font-medium text-gray-700">
              Start Time
            </label>
            <InputBox
              type="text"
              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none bg-gray-200 cursor-not-allowed"
              value={formatTime(Timings?.startDateTime)}
              readOnly={true}
            />
          </div>

          <div className="w-full sm:w-1/2 mt-4 sm:mt-0">
            <label className="block text-sm font-medium text-gray-700">
              End Time
            </label>
            <InputBox
              type="text"
              className="mt-1 w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none bg-gray-200 cursor-not-allowed"
              value={formatTime(Timings?.endDateTime)}
              readOnly={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailabilityForm;
