import { createContext } from "react";

export const AuthStageContext = createContext(null);
export const ForgotPasswordContext = createContext(null);

export const JobPageContext = createContext(null);
export const ScreeningQuestionContext = createContext(null);
export const CandidatePageContext = createContext(null);

export const CandidateFeedbackContext = createContext(null);
