import React, { useEffect } from "react";
import moment from "moment";
import { useState } from "react";

const core = require("../../lib/core");

export default function Admin2() {
  const [data, setRecruiters] = useState([]);
  const getRecruiters = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.ADMIN_API.GET_RECRUITER_FOR_APPROVE,
      1,
    );
    if (json?.data) {
      console.log(json.data);
      setRecruiters(json.data);
    }
  };

  const handleReject = async (email) => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.ADMIN_API.APPROVE_RECRUITER,
      1,
      { email: email, rejected: true },
    );
    if (json?.data) {
      console.log(json.data);
      getRecruiters();
    }
  };

  const handleApprove = async (email) => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.ADMIN_API.APPROVE_RECRUITER,
      1,
      { email: email },
    );
    if (json?.data) {
      console.log(json.data);
      getRecruiters();
    }
  };

  useEffect(() => {
    getRecruiters();
  }, []);

  return (
    <>
      <div className="overflow-x-auto h-[calc(100vh-200px)] overflow-y-scroll">
        <div className="px-3 py-4 flex justify-center">
          <table className="min-w-full">
            <thead>
              <tr className="border-b">
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Joined At
                </th>
                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
                <th />
              </tr>
            </thead>
            <tbody className=" divide-y divide-gray-200">
              {data?.map((item) => (
                <>
                  <tr className=" ">
                    <td className=" px-6 py-4 whitespace-no-wrap">
                      {item?.email}
                    </td>
                    <td className="p-3 px-5">
                      <p>
                        {" "}
                        {moment(item.createdAt).format("DD/MM/YYYY hh:mm:ss A")}
                      </p>
                    </td>

                    <td className="p-3 px-5 flex">
                      <button
                        onClick={() => handleApprove(item?.email)}
                        type="button"
                        className="mr-3 text-sm bg-blue-500 hover:bg-blue-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      >
                        Approve
                      </button>
                      <button
                        onClick={() => handleReject(item?.email)}
                        type="button"
                        className="text-sm bg-red-500 hover:bg-red-700 text-white py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                      >
                        Reject
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
