import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  resumeUploadStatusService,
  resumeUpdateStatusService,
  questionUploadStatusService,
} from "../../services";

const core = require("../../../lib/core");

const initialState = {
  resumes: null,
  update: null,
  questions: null,
  isLoading: false,
};

const resumeUploadStatusSlice = createSlice({
  name: "resumeUploadStatus",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(resumeUploadStatusService.pending, (state, action) => {
        state.isLoading = true;
        state.resumes = null;
      })
      .addCase(resumeUploadStatusService.fulfilled, (state, action) => {
        state.resumes = action.payload;
        state.isLoading = false;
      })
      .addCase(resumeUploadStatusService.rejected, (state, action) => {
        state.isLoading = false;
        state.isTokenValid = false;
      })
      .addCase(resumeUpdateStatusService.pending, (state, action) => {
        state.isLoading = true;
        state.update = null;
      })
      .addCase(resumeUpdateStatusService.fulfilled, (state, action) => {
        state.update = action.payload;
        state.isLoading = false;
      })
      .addCase(resumeUpdateStatusService.rejected, (state, action) => {
        state.isLoading = false;
        state.update = action.payload;
      })
      .addCase(questionUploadStatusService.pending, (state, action) => {
        state.isLoading = true;
        state.questions = null;
      })
      .addCase(questionUploadStatusService.fulfilled, (state, action) => {
        state.questions = action.payload;
        state.isLoading = false;
      })
      .addCase(questionUploadStatusService.rejected, (state, action) => {
        state.isLoading = false;
        state.isTokenValid = false;
      });
  },
});

// const resumeUpdateStatusSlice = createSlice({
//   name: 'resumeUpdateStatus',
//   initialState,
//   reducers: {
//     // Add any other reducers you may need to manage the state
//   },
//   extraReducers: (builder) => {
//     builder
//     .addCase(resumeUpdateStatusService.pending, (state, action) => {
//       state.isLoading = true
//       state.update = null;
//     })
//       .addCase(resumeUpdateStatusService.fulfilled, (state, action) => {
//         state.update = action.payload
//         state.isLoading = false;
//       })
//       .addCase(resumeUpdateStatusService.rejected, (state, action) => {
//         state.isLoading = false;
//         state.update =  action.payload;
//       })
//   },
// });

// Export actions
export const {} = resumeUploadStatusSlice.actions;
// export const { } = resumeUpdateStatusSlice.actions;

export default resumeUploadStatusSlice.reducer;
