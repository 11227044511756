import { useState, useEffect, useRef } from "react";
import { PropagateLoader } from "react-spinners";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

export default function TemplateDropdownAutocomplete({
  value,
  generate_template,
  template,
  onSelectOption,
  selectionFunction,
  ...props
}) {
  const menuRef = useRef(null);
  const [hide, setHide] = useState(true);
  const [data, setData] = useState([]);
  console.log("data", data);

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) setHide(true);
    };
    document.addEventListener("click", handleDocumentClick);
    return () => document.removeEventListener("click", handleDocumentClick);
  }, []);

  const searchListJobs = (term) => {
    try {
      if (term.trim() == "") return;
      if (term?.length >= 1) {
        // setData(["loader"]);
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST + "?name=" + term,
            1,
            {},
          );
          if (response?.data?.content.length > 0) {
            if (typeof response?.data?.content[0] === "string") {
              setData(response?.data?.content);
            } else {
              setData(
                response?.data?.content?.map((d) => ({
                  name: d?.template_name,
                  id: d?._id,
                  source: d?.mode,
                })),
              );
            }
          } else {
            setData([]);
          }
        }, 400);
      }
    } catch (err) {
      setData([]);
      console.error(err);
    }
  };

  return (
    <div ref={menuRef} className={`w-full relative`}>
      <InputBox
        className={"w-full auto-complete-input "}
        type={"text"}
        name={"search_template"}
        value={value}
        required={true}
        placeholder={"Search template"}
        onKeyPress={(e) => {
          if (e.key === "Enter") e.preventDefault();
        }}
        onClick={(e) => {
          searchListJobs(e.target.value);
          setHide(false);
        }}
        onChange={(e) => {
          searchListJobs(e.target.value);
          selectionFunction(e.target.value);
        }}
      />
      {!hide && (
        <ul className="absolute bg-white no-list-style max-h-[240px] overflow-y-auto py-5 rounded-lg shadow-menuOptionShadow left-0 w-full z-50">
          {data[0] === "loader" ? (
            <span className="flex justify-center items-center">
              <PropagateLoader color="blue" />
            </span>
          ) : (
            <>
              <p className="text-gray-700 text-base font-semibold underline pb-2 px-5">
                Personal templates
              </p>
              {data
                ?.filter((dt) => dt?.source == "personal")
                ?.map((item, i) => (
                  <li
                    key={i}
                    className="match-value px-5 py-2 hover:bg-blue-100 cursor-pointer"
                    onClick={() => {
                      selectionFunction(item?.name);
                      onSelectOption(item?.name, item?.id);
                      setHide(true);
                    }}
                  >
                    {item?.name ? item?.name : item?.email ? item?.email : item}
                  </li>
                ))}
              <p className="text-gray-700 text-base font-semibold underline px-5 py-2 mt-2">
                Global templates
              </p>
              {data
                ?.filter((dt) => dt?.source == "global")
                ?.map((item, i) => (
                  <li
                    key={i}
                    className="match-value px-5 py-2 hover:bg-blue-100 cursor-pointer"
                    onClick={() => {
                      selectionFunction(item?.name);
                      onSelectOption(item?.name, item?.id);
                      setHide(true);
                    }}
                  >
                    {item?.name ? item?.name : item?.email ? item?.email : item}
                  </li>
                ))}

              <li
                className="pl-5 py-2 cursor-pointer"
                onClick={() => {
                  // generate_template(value);
                  selectionFunction(value);
                  setHide(true);
                }}
              >
                <span className="flex items-center text-base">
                  <svg
                    className="mr-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g id="Group 427319278">
                      <path
                        id="Star 8"
                        d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
                        fill="#5F6989"
                      ></path>
                      <path
                        id="Star 9"
                        d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
                        fill="#5F6989"
                      ></path>
                      <path
                        id="Star 10"
                        d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
                        fill="#5F6989"
                      ></path>
                    </g>
                  </svg>
                  Fetch template for ({" "}
                  <b className="text-[#1369E9] ml-1 mr-1 ">{value}</b> )
                </span>
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
}
