import PercentDonut from "../../../analytics/PercentDonut";
import InitialsLogo from "../../../all/InitialsLogo";
import { Link } from "react-router-dom";
import { useState } from "react";

export default function InterviewReport({ reports }) {
  const [dataIdx, setDataIdx] = useState([]);
  function getInterviewScore(arr) {
    return (
      arr.reduce((accumulator, currentValue) => {
        return accumulator + currentValue;
      }, 0) / arr.length
    );
  }

  return (
    <>
      <div className="w-full flex flex-row px-[18px]">
        <h5 className="w-1/2">Candidate name</h5>
        <h5 className="w-1/4">Interview score</h5>
        <h5
          className="w-1/4 text-right"
          onClick={() => {
            if (dataIdx?.length == reports?.length) {
              setDataIdx([]);
            } else {
              setDataIdx([...Array(reports?.length).keys()]);
            }
          }}
        >
          {dataIdx?.length == reports?.length ? "Collapse all" : "Expand all "}
          <svg
            className="inline-block ml-2"
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.375 6.75L9.5 10.8633L13.625 6.75"
              stroke="#1369E9"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </h5>
      </div>
      <div className="w-full mt-4 bg-white rounded-[12px] shadow flex-col justify-start items-start inline-flex">
        {reports?.map((iter, idx) => (
          <div
            className="w-full bg-white rounded-[12px] border-b border-gray-100 py-[16px] px-[18px]"
            key={idx}
          >
            <div className="w-full justify-start items-center inline-flex">
              <div className="w-1/2 flex items-center justify-start">
                <InitialsLogo
                  styleClass="mr-[16px]"
                  str={iter?.name}
                  bgRounded={"50%"}
                />
                <div className="w-2/3 min-h-[40px] flex flex-col justify-center ">
                  <h4 className="w-full name">{iter?.name}</h4>
                  {iter?.title && (
                    <h6 className="w-full title">{iter?.title}</h6>
                  )}

                  {iter?.email && <p className="w-full email">{iter?.email}</p>}
                </div>
              </div>
              <div className="w-1/4 flex items-center justify-start">
                <div className="w-full flex justify-start items-center">
                  {getInterviewScore(
                    iter?.interviews?.map((d) => d?.overallScore),
                  ) ? (
                    <>
                      <PercentDonut
                        id={"score_" + iter?.id}
                        chartSize={26}
                        radius={9}
                        width={4}
                        cornerRadius={1}
                        hidePercent={true}
                        percentData={
                          Math.round(
                            getInterviewScore(
                              iter?.interviews?.map((d) => d?.overallScore),
                            ),
                          ) * 10
                        }
                        fontSize={8}
                        customTooltipText={
                          "Interview overall score : " +
                          Math.round(
                            getInterviewScore(
                              iter?.interviews?.map((d) => d?.overallScore),
                            ),
                          ) *
                            10 +
                          "%"
                        }
                      />
                      <span className="ml-[16px] text-slate-800 text-sm font-medium">
                        {`${
                          Math.round(
                            getInterviewScore(
                              iter?.interviews?.map((d) => d?.overallScore),
                            ),
                          ) * 10
                        }%  ${
                          Math.round(
                            getInterviewScore(
                              iter?.interviews?.map((d) => d?.overallScore),
                            ),
                          ) *
                            10 >=
                          90
                            ? "Excellent score"
                            : Math.round(
                                  getInterviewScore(
                                    iter?.interviews?.map(
                                      (d) => d?.overallScore,
                                    ),
                                  ),
                                ) *
                                  10 >=
                                75
                              ? "Good score"
                              : Math.round(
                                    getInterviewScore(
                                      iter?.interviews?.map(
                                        (d) => d?.overallScore,
                                      ),
                                    ),
                                  ) *
                                    10 >=
                                  50
                                ? "Average score"
                                : Math.round(
                                      getInterviewScore(
                                        iter?.interviews?.map(
                                          (d) => d?.overallScore,
                                        ),
                                      ),
                                    ) *
                                      10 >=
                                    25
                                  ? "Weak score"
                                  : "Poor score"
                        }`}
                      </span>
                    </>
                  ) : (
                    <>
                      <PercentDonut
                        id={"match_" + iter?.id}
                        chartSize={26}
                        radius={9}
                        width={4}
                        cornerRadius={1}
                        hidePercent={true} // percentData={iter?.val?.length>0 ?  matchScore(iter.matchAnalytics, iter?.matchPercent, iter?.eval): matchScore(iter.matchAnalytics, iter?.matchPercent, iter?.eval)}
                        percentData={Math.floor(iter?.matchPercent * 100)}
                        fontSize={8}
                      />
                      <span className="">
                        {`${Math.floor(iter?.matchPercent * 100)}%  ${
                          iter?.matchPercent * 100 >= 90
                            ? "Excellent score"
                            : iter?.matchPercent * 100 >= 75
                              ? "Good score"
                              : iter?.matchPercent * 100 >= 50
                                ? "Average score"
                                : iter?.matchPercent * 100 >= 25
                                  ? "Weak score"
                                  : "Poor score"
                        }`}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="w-1/4 flex items-center justify-between">
                <Link to={`/evaluation/${iter.id}`} target="_blank">
                  <button>
                    <svg
                      className="inline-block mr-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.55982 5.08067C9.22792 4.90326 8.8693 4.78435 8.49967 4.72786V1.35156C9.42259 1.42098 10.3229 1.68197 11.1423 2.11998C12.1092 2.63679 12.9337 3.38408 13.5428 4.29565C14.1519 5.20723 14.5268 6.25495 14.6343 7.34601C14.6393 7.39714 14.6437 7.44829 14.6476 7.49946H11.2713C11.1982 7.02127 11.0211 6.56416 10.7515 6.16075C10.4491 5.70821 10.0398 5.33723 9.55982 5.08067ZM4.29588 2.45633C5.25314 1.8167 6.35724 1.43748 7.49967 1.35156V4.72786C7.02277 4.80075 6.56547 4.97737 6.16097 5.24764C5.61672 5.6113 5.19252 6.12819 4.94202 6.73293C4.69153 7.33768 4.62599 8.00313 4.75369 8.64512C4.8486 9.12226 5.04709 9.57052 5.33278 9.95928L2.94537 12.3467C2.19829 11.4781 1.68572 10.4292 1.46111 9.30006C1.20387 8.00685 1.3359 6.66641 1.84048 5.44823C2.34506 4.23006 3.19955 3.18887 4.29588 2.45633ZM3.65248 13.0538C4.35416 13.6573 5.17531 14.1093 6.06446 14.3791C7.11359 14.6973 8.22502 14.7519 9.30029 14.538C10.3656 14.3261 11.3628 13.8572 12.2053 13.1722L9.20058 11.0835C9.0212 11.1533 8.83546 11.2076 8.64536 11.2454C8.11155 11.3516 7.5598 11.3245 7.03897 11.1665C6.67885 11.0573 6.3412 10.8878 6.03989 10.6664L3.65248 13.0538ZM11.0573 9.26598C11.1596 9.01907 11.2312 8.76166 11.2713 8.49946H14.6476C14.5947 9.20237 14.4305 9.89493 14.1589 10.5507C13.8663 11.2571 13.4553 11.9055 12.946 12.4692L10.1434 10.521C10.543 10.1812 10.8564 9.75111 11.0573 9.26598Z"
                        fill="#1369E9"
                      />
                    </svg>
                    {/* <span>View full report</span> */}
                  </button>
                </Link>
                <button
                  onClick={() => {
                    if ([...dataIdx].includes(idx)) {
                      setDataIdx((prev) => {
                        const arr = [...prev].filter((d) => d != idx);
                        return arr;
                      });
                    } else {
                      setDataIdx([...dataIdx, idx]);
                    }
                  }}
                >
                  {[...dataIdx].includes(idx) ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.5 9L12 14.4844L17.5 9"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
            {iter?.interviews?.length > 0 && [...dataIdx].includes(idx) && (
              <div id="accordion" className="mt-[10px]">
                {iter?.interviews?.map((inter, ix) => (
                  <div
                    className="w-full interview rounded-[16px] py-[16px] px-[20px] mt-[6px] flex bg-[#eff0f266]"
                    key={idx + "-" + ix}
                  >
                    <div className="w-1/2 flex flex-row flex-wrap justify-between">
                      <span className="w-[calc(100%-180px)]">
                        <h2 className="mb-[8px]">{inter?.title}</h2>
                        <span className="flex">
                          <InitialsLogo
                            styleClass="inline-block mr-[16px]"
                            str={inter?.recruiter?.name}
                            width={22}
                            height={22}
                            fontSize={12}
                          />
                          <span className="inline-block text-[14px] font-medium text-gray-800 mr-[16px]">
                            {inter?.recruiter?.name}
                          </span>
                          <span className="inline-block text-[14px] font-medium text-gray-700">
                            Interviewer
                          </span>
                        </span>
                      </span>
                      {true && (
                        <div className="rounded-[16px] bg-white px-[16px] py-[10px] w-[150px] mr-[20px] self-end">
                          <span className="text-[#16182f] text-[24px] font-bold tracking-[-0.96px] block">
                            {Math.round(inter?.overallScore)}
                          </span>
                          <span className="text-[16px] text-[#252e4b]">
                            Interview score
                          </span>
                        </div>
                      )}
                      <span className="w-[calc(100%-180px)]">
                        <span className="inline-block">
                          <div className="text-[#5f6989] text-[14px] pb-[6px]">
                            Interview Date
                          </div>
                          <div className="text-[16px] font-medium text-[#252e4b]">
                            {new Date(inter?.date).toDateString()}
                          </div>
                        </span>
                      </span>
                    </div>
                    <div className="w-1/2 criteria-score">
                      {inter?.eval?.map((ev, x) => (
                        <div className="w-full criteria-row">
                          <div className="score">
                            <PercentDonut
                              id={"eval_" + idx + ix + x}
                              chartSize={26}
                              radius={9}
                              width={4}
                              cornerRadius={1}
                              hidePercent={true}
                              percentData={Math.round(ev?.value * 10)}
                              fontSize={8}
                              customTooltipText={
                                "Score : " + Math.round(ev?.value)
                              }
                            />
                            <span>{Math.round(ev?.value)}</span>
                          </div>
                          <div className="criteria">{ev?.label}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
