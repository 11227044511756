import { useEffect } from "react";
import Avatar from "../../../assets/candidate/Avatar.png";

export default function PersonalForm({
  input_function,
  form_state,
  image_link,
  image_function,
}) {
  return (
    <form className="col-xs-12 no-padding" id="personal">
      <div className="col-md-3 image-section no-padding">
        <img
          className=""
          src={Avatar}
          width="156"
          height="156"
          style={{ borderRadius: "50%" }}
        />
        <input
          type="file"
          id="upload-pic"
          name="image"
          hidden
          onChange={(e) => {
            image_function(e.target.files[0]);
          }}
        />
        {/* <label htmlFor="upload-pic" className="replace-button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487V4.487Z"
              stroke="#0B4FD2"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
          Replace
        </label> */}
      </div>
      <div className="col-md-9 no-padding text-section">
        <div className="col-xs-12 no-padding">
          <label htmlFor="" className="col-xs-12 no-padding required">
            Name
          </label>
          <input
            className="col-xs-12 no-padding input_text name"
            type="text"
            name="name"
            required="required"
            defaultValue={form_state?.name}
            onChange={(event) => {
              input_function("name", event.target.value);
            }}
          />
        </div>
        <div className="col-xs-12 no-padding">
          <label htmlFor="" className="col-xs-12 no-padding required">
            Occupation
          </label>
          <input
            className="col-xs-12 no-padding input_text"
            type="text"
            name="title"
            required="required"
            defaultValue={form_state?.title}
            onChange={(event) => {
              input_function("title", event.target.value);
            }}
          />
        </div>
        {/* <div className="col-xs-12 no-padding">
          <label htmlFor="" className="col-xs-12 no-padding ">
            Gender
          </label>

          <select
            className="col-xs-12 no-padding input_text"
            type="text"
            name="gender"
            // required="required"
            defaultValue={form_state?.gender}
            onChange={(event) => {
              input_function("gender", event.target.value);
            }}
          >
            <option value="" disabled></option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select>
        </div> */}
        {/* <div className="col-xs-12 no-padding">
          <label htmlFor="" className="col-xs-12 no-padding ">
            Location
          </label>
          <input
            className="col-xs-12 no-padding input_text"
            type="text"
            name="location"
            required="required"
            defaultValue={form_state?.location}
            onChange={(event) => {
              input_function("location", event.target.value);
            }}
          />
        </div> */}

        <div className="col-xs-12 no-padding">
          <label htmlFor="" className="col-xs-12 no-padding">
            Your bio
          </label>
          <textarea
            className="col-xs-12 no-padding input_text resize-none"
            name="bio"
            rows="10"
            defaultValue={form_state?.bio}
            onChange={(event) => {
              input_function("bio", event.target.value);
            }}
          ></textarea>
        </div>
      </div>
    </form>
  );
}
