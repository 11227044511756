export const isArrayEmpty = (array) => {
  return !array || array.length === 0;
};
export const isObjectEmpty = (obj) => {
  return !obj || Object.keys(obj).length === 0;
};

export const fieldMap = {
  activeJobs: {
    jobName: "Job Name",
    prospects: "Prospects",
    candidates: "Candidates",
    strongMatch: "Strong Match",
    goodMatch: "Good Match",
    sproutsaiCand: "SproutsAI Candidates",
    atsCand: "ATS Candidates",
  },
  newApplicants: {
    name: "Name",
    appliedFor: "Applied For",
    source: "Source",
    overallMatch: "Overall Match",
    candidate_profile: "Candidate Profile",
    quickLinks: "Quick Links",
  },
  pendingTasks: {
    task: "Task",
    dueDate: "Due Date",
    assignee: "Assignee",
    priority: "Priority",
  },
  upcomingEvents: {
    event: "Event",
    date: "Date",
    time: "Time",
    duration: "Duration",
    location: "Location",
  },
};

export const validCards = [
  "activeJobs",
  "newApplicants",
  "pendingTasks",
  "upcomingEvents",
];
