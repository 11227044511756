/**
 * btnList = [
 *  {
 *    id: "String" - tags used in
 *    name : "String",
 *  }, ...
 * ]
 *
 * state and setState => state responsible for tab switch
 * className => extra style for tablist
 * button => custom style for buttons
 *
 * **/

import React from "react";

const TabLayout = ({ btnList, state, setState, className = "" }) => {
  return (
    <div
      className={`bg-customColor h-11 w-auto rounded-lg py-px px-px inline-flex items-center ${className}`}
    >
      {btnList?.map((btn, i) => (
        <button
          key={i}
          onClick={() => setState(btn?.id)}
          className={`px-4 py-2 flex items-center justify-center text-base transition-all ease-in-out rounded-lg ${
            state === btn?.id
              ? "bg-white font-medium text-blue"
              : "bg-transparent font-normal text-gray-800"
          } `}
        >
          {btn?.name}
        </button>
      ))}
    </div>
  );
};

export default TabLayout;
