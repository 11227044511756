export const PERMISSION_DETAILS = {
  // Job permissions
  Job_create: {
    name: "Job", // high level resource name
    type: "Job",
    action: "Create Job", // label
    description: "You need permission to create a new job.", // description to show in modal
  },
  Job_read: {
    name: "Job",
    type: "Job",
    action: "Read Job",
    description: "You need permission to view job details.",
  },
  Job_edit: {
    name: "Job",
    type: "Job",
    action: "Edit Job",
    description: "You need permission to edit job details.",
  },
  Job_clone: {
    name: "Job",
    type: "Job",
    action: "Clone Job",
    description: "You need permission to clone an existing job.",
  },
  Job_share: {
    name: "Job",
    type: "Job",
    action: "Share Job",
    description: "You need permission to share job information.",
  },
  Job_close: {
    name: "Job",
    type: "Job",
    action: "Close Job",
    description: "You need permission to close a job.",
  },
  Job_sequence: {
    name: "Job",
    type: "Job",
    action: "Sequence Job",
    description: "You need permission to manage job sequences.",
  },
  Job_publish: {
    name: "Job",
    type: "Job",
    action: "Publish Job",
    description: "You need permission to publish a job.",
  },
  Job_delete: {
    name: "Job",
    type: "Job",
    action: "Delete Job",
    description: "You need permission to delete a job.",
  },
  Job_parse: {
    name: "Job",
    type: "Job",
    action: "Parse Job",
    description: "You need permission to parse job information.",
  },
  Job_unpublish: {
    name: "Job",
    type: "Job",
    action: "Unpublish Job",
    description: "You need permission to unpublish a job.",
  },
  Job_archive: {
    name: "Job",
    type: "Job",
    action: "Archive Job",
    description: "You need permission to archive a job.",
  },
  Job_interview_plan: {
    name: "Job",
    type: "Job",
    action: "Interview Plan",
    description:
      "You need permission to create or modify interview plans for a job.",
  },
  Job_access_page: {
    name: "Job",
    type: "Job",
    action: "Access Job Page",
    description: "You need permission to access the job page.",
  },

  // Candidate permissions
  Candidate_create: {
    name: "Candidate",
    type: "Candidate",
    action: "Create Candidate",
    description: "You need permission to create a new candidate profile.",
  },
  Candidate_read: {
    name: "Candidate",
    type: "Candidate",
    action: "Read Candidate",
    description: "You need permission to view candidate details.",
  },
  Candidate_update: {
    name: "Candidate",
    type: "Candidate",
    action: "Update Candidate",
    description: "You need permission to update candidate details.",
  },
  Candidate_add: {
    name: "Candidate",
    type: "Candidate",
    action: "Add Candidates",
    description: "You need permission to add candidates.",
  },
  Candidate_source: {
    name: "Candidate",
    type: "Candidate",
    action: "Source Candidate",
    description: "You need permission to source candidates.",
  },
  Candidate_show_status: {
    name: "Candidate",
    type: "Candidate",
    action: "Show Status",
    description: "You need permission to view a candidate's status.",
  },
  Candidate_rescore: {
    name: "Candidate",
    type: "Candidate",
    action: "Re-score Candidate",
    description: "You need permission to re-score a candidate.",
  },
  Candidate_general_feedback_write: {
    name: "Candidate",
    type: "Candidate",
    action: "Write General Feedback",
    description:
      "You need permission to give general feedback for a candidate.",
  },
  Candidate_interview_feedback_write: {
    name: "Candidate",
    type: "Candidate",
    action: "Write Interview Feedback",
    description:
      "You need permission to give interview feedback for a candidate.",
  },
  Candidate_interview_feedback_read: {
    name: "Candidate",
    type: "Candidate",
    action: "Read Interview Feedback",
    description:
      "You need permission to read interview feedback for a candidate.",
  },
  Candidate_pipeline_write: {
    name: "Candidate",
    type: "Candidate",
    action: "Modify Candidate Stage",
    description:
      "You need permission to modify a candidate's stage in the pipeline.",
  },
  Candidate_aggregate_feedback_read: {
    name: "Candidate",
    type: "Candidate",
    action: "Read Aggregate Feedback",
    description:
      "You need permission to read aggregate feedback for a candidate.",
  },
  Candidate_invite: {
    name: "Candidate",
    type: "Candidate",
    action: "Invite Candidate ",
    description:
      "You need permission to invite a candidate for interview and assessment.",
  },
  // Questions permissions
  Questions_create: {
    name: "Questions",
    type: "Questions",
    action: "Create Questions",
    description: "You need permission to create new questions.",
  },
  Questions_read: {
    name: "Questions",
    type: "Questions",
    action: "Read Questions",
    description: "You need permission to view questions.",
  },
  Questions_generate: {
    name: "Questions",
    type: "Questions",
    action: "Generate Questions",
    description: "You need permission to generate questions.",
  },
  Questions_evaluation: {
    name: "Questions",
    type: "Questions",
    action: "Evaluate Questions",
    description: "You need permission to evaluate questions.",
  },

  // Communications permissions
  Communications_create: {
    name: "Communications",
    type: "Communications",
    action: "Create Communications",
    description: "You need permission to create new communications.",
  },
  Communications_read: {
    name: "Communications",
    type: "Communications",
    action: "Read Communications",
    description: "You need permission to view communications.",
  },
  Communications_send: {
    name: "Communications",
    type: "Communications",
    action: "Send Communications",
    description: "You need permission to send communications.",
  },
  Communications_edit_templates: {
    name: "Communications",
    type: "Communications",
    action: "Edit Templates",
    description: "You need permission to edit communication templates.",
  },

  // Interview permissions
  Interview_create: {
    name: "Interview",
    type: "Interview",
    action: "Create Interview",
    description: "You need permission to create new interviews.",
  },
  Interview_read: {
    name: "Interview",
    type: "Interview",
    action: "Read Interview",
    description: "You need permission to view interview details.",
  },
  Interview_cancel: {
    name: "Interview",
    type: "Interview",
    action: "Cancel Interview",
    description: "You need permission to cancel interviews.",
  },

  // Assessment permissions
  Assessment_create: {
    name: "Assessment",
    type: "Assessment",
    action: "Create Assessment",
    description: "You need permission to create new assessments.",
  },
  Assessment_read: {
    name: "Assessment",
    type: "Assessment",
    action: "Read Assessment",
    description: "You need permission to view assessment details.",
  },
  Assessment_invite_candidate: {
    name: "Assessment",
    type: "Assessment",
    action: "Invite Candidate",
    description: "You need permission to invite candidates to an assessment.",
  },
  Assessment_update: {
    name: "Assessment",
    type: "Assessment",
    action: "Update Assessment",
    description: "You need permission to update an assessment.",
  },

  // Greenhouse permissions
  Greenhouse_read: {
    name: "Greenhouse",
    type: "Greenhouse",
    action: "Read Greenhouse",
    description: "You need permission to view Greenhouse data.",
  },
  Greenhouse_sync: {
    name: "Greenhouse",
    type: "Greenhouse",
    action: "Sync Greenhouse",
    description: "You need permission to synchronize data with Greenhouse.",
  },

  // CandidateBrowser permissions
  CandidateBrowser_read: {
    name: "CandidateBrowser",
    type: "CandidateBrowser",
    action: "Read Candidate Browser",
    description: "You need permission to access the Candidate Browser.",
  },

  // Analytics permissions
  Analytics_read: {
    name: "Analytics",
    type: "Analytics",
    action: "Read Analytics",
    description: "You need permission to view analytics data.",
  },
};

export const getPermissionDescription = (code) => {
  return (
    PERMISSION_DETAILS[code]?.description ||
    "You don't have permission to perform this action."
  );
};

export const getPermissionAction = (code) => {
  return PERMISSION_DETAILS[code]?.action || "Perform Action";
};
