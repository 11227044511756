import { useContext, useEffect, useMemo, useState, useCallback } from "react";
import InitialsLogo from "../../../all/InitialsLogo";
import PercentDonut from "../../../analytics/PercentDonut";
import { useNavigate } from "react-router";
import Table from "../../../table/Table";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchCandidatesList,
  fetchAssessment,
  fetchReport,
} from "../../../../redux/services";
import usePrevious from "../../../../hooks/use-previous";
import { ScaleLoader } from "react-spinners";
import CandidateDrawer from "../../candidate/CandidateDrawer";
import { JobPageContext } from "../../../../lib/context";
import LockedComponent from "../../../../layouts/LockedComponent";
import RejectedReasonModal from "../../applicant/RejectedReasonModal";
import AddNotes from "../../applicant/AddNotes";
import { FaSortAmountDown } from "react-icons/fa";
import { BsSortAlphaDown } from "react-icons/bs";
import { BsSortAlphaDownAlt } from "react-icons/bs";
import { RiSortNumberAsc } from "react-icons/ri";
import { BsSortNumericDown } from "react-icons/bs";
import { BsSortNumericDownAlt } from "react-icons/bs";
import { IoMdRefresh } from "react-icons/io";
const core = require("../../../../lib/core");

export default function AssessmentScores({ jobId, ...props }) {
  const dispatch = useDispatch();
  const [message, setMessage] = useState({});
  const { setContentReload } = useContext(JobPageContext);
  const { user, resources } = useContext(core.UserContext);
  const [showModal, setShowModal] = useState("");
  const modalClose = () => {
    setShowModal("");
  };
  const { assessmentReport, assessment } = useSelector((state) => {
    return {
      assessmentReport: state?.JobExpandSlice?.report,
      assessment: state?.JobExpandSlice?.assessment?.assessments,
    };
  });

  const { job_Id, candidateListData } = useSelector((state) => {
    console.log(state);
    return {
      job_Id: state?.JobExpandSlice?.profile?.id,
      candidateListData: state?.JobExpandSlice?.candidates,
    };
  });

  const [sortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [currentSort, setCurrentSort] = useState({ field: "", order: "" });

  const handleSort = (field, order) => {
    setCurrentSort({ field, order });
    // setSortDropdownVisible(false);
  };

  const [reloadKey, setReloadKey] = useState(0);

  const [candidateDetail, setCandidateDetail] = useState({});

  const [candidates, setCandidates] = useState([]);

  const [sendText, setSendText] = useState("");

  const [checkedItems, setCheckedItems] = useState([]);

  useEffect(() => {
    setCandidates(candidateListData?.data?.content);
  }, [candidateListData]);

  const updateBulkStatus = (status) => {
    setCandidates((prevData) =>
      prevData?.map((item) => {
        if (checkedItems?.includes(item)) {
          return { ...item, current_stage: status };
        }
        return item;
      }),
    );
  };

  const updateLocalStatus = (id, status) => {
    setCandidates((prevData) =>
      prevData.map((item) => {
        if (item._id === id) {
          return { ...item, current_stage: status };
        }
        return item;
      }),
    );
  };

  const [stateChangeLoader, setStateChangeLoader] = useState(false);

  async function updateStatus(
    cid,
    status,
    reason,
    email_template_id,
    rejection_reason_id,
    onBehalfId,
    when,
    send_email,
    subject,
  ) {
    setStateChangeLoader(true);
    setSendText("Sending...");
    await core
      .API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_UPDATE_APPLICANT_STATUS,
        1,
        {
          matchprofileIds: cid ? [cid] : checkedItems,
          jobId,
          current_stage: status,
          rejectionNotes: reason,
          email_template_id: email_template_id,
          rejection_reason_id: rejection_reason_id,
          onBehalfId: onBehalfId,
          when: when,
          send_email: send_email,
          subject: subject,
        },
      )
      .then((json) => {
        if (cid) {
          updateLocalStatus(cid, status);
        } else {
          updateBulkStatus(status);
        }
        if (json.data) {
          console.log("Response From Updated 101", json);
          setSendText("");
          modalClose();

          if (!reason && candidateDetail?.stage === "Application Review") {
            setShowModal("add-notes-modal");
          }
        } else {
        }
        setStateChangeLoader(false);
        setReloadKey((prevKey) => prevKey + 1);
      });
  }

  const fetchCandidates = async () => {
    if (!job_Id) {
      return;
    }

    const queryString = `/job/active/${job_Id}/candidates?&page=${assessmentReport?.page?.currentPage || 1}`;

    try {
      dispatch(fetchCandidatesList(jobId + "?" + "&page=1"));
    } catch (error) {
      console.error("Error fetching candidates:", error);
    }
  };

  useEffect(() => {
    fetchCandidates();
  }, [job_Id, assessmentReport?.page?.currentPage]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);

  const [id, setId] = useState("");
  const [menuShow, setMenuShow] = useState(false);

  const openDrawer = (candidateId) => {
    setSelectedCandidate(candidateId);
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = (isOpen) => {
    setIsDrawerOpen(isOpen);
    if (!isOpen) {
      setSelectedCandidate(null);
    }
  };

  async function removeCandidate({ id }) {
    await core
      .API(
        core.API_METHODS.DELETE,
        core.RECRUITER_API.DELETE_MATCH_PROFILE + id,
        1,
        {},
      )
      .then((json) => {
        if (json.data) {
          modalClose();
          setContentReload((prev) => prev + 1);
        } else {
          setMessage({
            type: "error",
            message: json?.error?.message,
          });
        }
      });
  }

  const [loading, setLoading] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [queryParams, setQueryParams] = useState({
    page: 1,
    pageSize: 10,
    search: "",
    sortBy: "name",
    filter: "",
    orderBy: "ascend",
  });

  function dateFormat(date) {
    return (
      new Date(date).toString().split(" ")[2] +
      " " +
      new Date(date).toString().split(" ")[1] +
      ", " +
      new Date(date).toString().split(" ")[3]
    );
  }

  const getReports = async () => {
    if (!jobId) {
      return;
    }
    setLoading(true);
    try {
      await dispatch(fetchReport({ jobId, params: queryParams }));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchAssessmentlist = async () => {
    if (!jobId) return;
    dispatch(fetchAssessment(jobId + "?status=active"));
  };

  useEffect(() => {
    fetchAssessmentlist();
  }, [jobId]);

  useEffect(() => {
    getReports();
  }, [jobId, queryParams, reloadKey]);

  const [pipeline, setPipeline] = useState([]);

  useEffect(() => {
    if (candidateListData) {
      console.log("candidatelistdata", candidateListData);
      if (candidateListData?.data?.pipeline?.length > 0) {
        setPipeline([
          ...candidateListData?.data?.pipeline?.flatMap((item) => {
            if (item?.subStage?.length > 0) {
              return item?.subStage?.map((sub) => `${sub}`);
            } else {
              return item?.stage;
            }
          }),
        ]);
      } else {
        setPipeline([
          "Application Review",
          "Screening",
          "Interview",
          "Hired",
          "To Be Declined",
          "Rejected",
        ]);
      }
    }
  }, [candidateListData, jobId]);

  const [chatDetail, setChatDetail] = useState({});
  const [reload, setReload] = useState(0);

  const columnData = [
    {
      title: "Candidate name",
      dataIndex: "name",
      key: "name",
      sorter: true,
      render: (text, record) => {
        console.log(record); // Log the record details
        return (
          <div
            className="flex flex-row justify-start items-center cursor-pointer"
            onClick={() => openDrawer(record?.matchprofileId)}
          >
            <InitialsLogo
              styleClass="img-responsive !inline-flex mr-[16px]"
              str={record?.name ?? record?.email}
              width={40}
              height={40}
              bgRounded="50%"
              fontSize={14}
            />
            <div
              style={{ width: "calc(100% - 60px)" }}
              className="personal-info"
            >
              <p className="w-full">{record?.name ?? "Not Mentioned"}</p>
            </div>
          </div>
        );
      },
    },
    {
      title: "Assessment",
      dataIndex: "assessment",
      key: "assessment",
      sorter: true,
      render: (text, record) => (
        <span className="text-truncate">
          {record?.assessment || "Unable to fetch"}
        </span>
      ),
      filterMultiple: false,
      filters: assessment?.map((item) => {
        return {
          text: item.name,
          value: item.name,
        };
      }),
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      sorter: true,
      render: (text, record) => (
        <span className="text-truncate">
          {Number(record?.overallScore?.totalPercentage).toFixed(2) + "%" ||
            "Unable to fetch"}
        </span>
      ),
    },
    {
      title: "Applied date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text, record) => <span>{dateFormat(record?.appliedAt)}</span>,
    },
    {
      title: "Stage",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <>
          <LockedComponent
            isLocked={
              resources?.filter((item) => item === "Candidate_create")?.length >
                0
                ? false
                : true
            }
          >
            <button
              className="border-button flex items-center rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5   justify-center max-w-[160px] min-w-[140px] "
              onClick={() => {
                setMenuShow(!menuShow);
                setId(record?.appliedAt);
                // removeCandidate({
                //   name: record?.notes,
                //   id: record?.matchprofileId,
                //   vote: true,
                //   stage:
                //     record?.current_stage ??
                //     record?.applicant?.current_stage,
                // });
                setCandidateDetail({
                  name: record?.name,
                  email: record?.email,
                  notes: record?.notes,
                  id: record?.matchprofileId,
                  stage: record?.current_stage,
                  _id: record?.applicant_id,
                });
              }}
              data-tooltip-id="my-tooltip-4"
              data-tooltip-content={"Change stage"}
            >
              <span className="text-gray-800 text-sm font-normal not-italic hover:text-blue-900 focus:text-blue-900 max-w-[140px] truncate">
                {record?.current_stage ?? record?.applicant?.current_stage}{" "}
              </span>
              <span className="ml-8px">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M6.71838 8.30751L6.71753 8.30657L5.18845 6.62649C5.17672 6.6136 5.17066 6.60014 5.16811 6.58407C5.1652 6.56574 5.16722 6.54403 5.17472 6.5241C5.17827 6.51464 5.18239 6.50742 5.18595 6.50247C5.18662 6.50154 5.18724 6.50072 5.18782 6.5L8.14833 6.5L10.8114 6.5C10.8151 6.50456 10.8205 6.51266 10.8253 6.52534C10.8331 6.54588 10.8351 6.56776 10.8323 6.58575C10.8299 6.60139 10.8241 6.61416 10.8129 6.62649L8.34541 9.33765C8.14851 9.55399 7.84854 9.55412 7.65152 9.33803C7.6514 9.33791 7.65128 9.33778 7.65116 9.33765L6.71838 8.30751Z"
                    fill="#252E4B"
                    stroke="#252E4B"
                  />
                </svg>
              </span>
            </button>
          </LockedComponent>
          {menuShow && record?.appliedAt === id && (
            <div className="absolute w-[160px] top-[40px] bg-white  right-[70px] rounded-lg p-1 shadow-menuOptionShadow z-10">
              <ul>
                {pipeline
                  ?.filter((item) => item != record?.current_stage)
                  ?.map((item, index) => (
                    <li
                      className="p-2 text-gray-800 text-sm font-normal  not-italic rounded-lg cursor-pointer hover:bg-bg1"
                      onClick={() => {
                        if (item === "Rejected") {
                          setChatDetail({
                            name: record?.name,
                            email: record?.email,
                            id: record?.matchprofileId,
                            stage: record?.current_stage,
                          });
                          setShowModal("reject-reason-status");
                          // updateStatus(record?.matchprofileId, item, "");
                          setMenuShow(false);
                        } else {
                          setReload(reload + 1);
                          updateStatus(record?.matchprofileId, item, "");
                          setMenuShow(false);
                        }
                      }}
                    >
                      {item?.split("/")?.at(-1)}
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <div className="flex items-center justify-between ">
          Report
          {/* <div className="flex  items-center ml-auto cursor-pointer mr-2 hover:scale-125 transition-all duration-300  rounded-md"
            onClick={() => setQueryParams({
              page: 1,
              pageSize: 10,
              search: "",
              sortBy: "name",
              filter: "",
              orderBy: "ascend",
            })}
          >
            <IoMdRefresh className="text-gray-700 text-lg m-2 pt-[2px]" />
          </div> */}
        </div>
      ),
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <button
          className="inline-flex flex-row gap-[10px] items-center justify-start  pl-2.5 pr-3 py-1 bg-white rounded-lg shadow border border-gray-300 text-center text-slate-800 text-[14px] font-normal leading-[24px]"
          onClick={() =>
            window.open("/assessment-report/" + record?._id, "_blank")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.5603 5.08116C9.22841 4.90375 8.86979 4.78484 8.50016 4.72835V1.35205C9.42308 1.42146 10.3234 1.68246 11.1428 2.12047C12.1097 2.63728 12.9342 3.38457 13.5433 4.29614C14.1524 5.20772 14.5273 6.25544 14.6347 7.3465C14.6398 7.39763 14.6442 7.44878 14.6481 7.49994H11.2718C11.1987 7.02176 11.0215 6.56465 10.752 6.16124C10.4496 5.7087 10.0403 5.33772 9.5603 5.08116ZM4.29636 2.45681C5.25362 1.81719 6.35772 1.43797 7.50016 1.35205V4.72835C7.02326 4.80124 6.56595 4.97786 6.16146 5.24813C5.6172 5.61179 5.19301 6.12868 4.94251 6.73342C4.69202 7.33817 4.62648 8.00362 4.75418 8.64561C4.84909 9.12275 5.04758 9.57101 5.33327 9.95977L2.94586 12.3472C2.19877 11.4786 1.6862 10.4297 1.4616 9.30055C1.20436 8.00734 1.33638 6.6669 1.84097 5.44872C2.34555 4.23055 3.20004 3.18936 4.29636 2.45681ZM3.65297 13.0543C4.35465 13.6578 5.1758 14.1098 6.06495 14.3795C7.11408 14.6978 8.22551 14.7524 9.30078 14.5385C10.3661 14.3266 11.3633 13.8577 12.2058 13.1727L9.20106 11.084C9.02169 11.1538 8.83595 11.2081 8.64585 11.2459C8.11204 11.3521 7.56029 11.325 7.03946 11.167C6.67934 11.0578 6.34168 10.8883 6.04038 10.6669L3.65297 13.0543ZM11.0578 9.26647C11.1601 9.01956 11.2317 8.76214 11.2718 8.49994H14.6481C14.5952 9.20286 14.431 9.89542 14.1594 10.5512C13.8668 11.2576 13.4558 11.906 12.9465 12.4697L10.1438 10.5215C10.5435 10.1817 10.8569 9.7516 11.0578 9.26647Z"
              fill="#1369E9"
            />
          </svg>
          See full report
        </button>
      ),
    },
  ];

  return (
    <div key={reloadKey} className="relative w-full h-full overflow-y-auto">
      {(loading || stateChangeLoader) && (
        <div className="absolute top-0 left-0 w-full h-full bg-white bg-opacity-30 flex justify-center items-center z-50">
          <div className="">
            <ScaleLoader
              color="#1369E9"
              loading={loading || stateChangeLoader}
            />
          </div>
        </div>
      )}
      <Table
        columns={columnData}
        dataSource={assessmentReport?.data || []}
        pageSize={queryParams?.pageSize || 10}
        current={assessmentReport?.page?.currentPage || 1}
        totalPage={assessmentReport?.page?.totalCount}
        onChange={(p, f, s, extra) => {
          console.log(extra, f, s);
          if (extra.action === "filter") {
            if (f?.assessment?.length > 0) {
              setQueryParams({
                ...queryParams,
                filter: f?.assessment[0],
              });
              return;
            }
            setQueryParams({
              ...queryParams,
              filter: "",
            });
          } else if (extra.action === "sort") {
            setQueryParams({
              ...queryParams,
              orderBy: s.order,
              sortBy: s.field,
            });
          }
        }}
        onPageChange={(e, size) => {
          console.log(e);
          setQueryParams({
            ...queryParams,
            page: e,
            pageSize: size,
          });
        }}
      />
      <div className="relative">
        {isDrawerOpen && (
          <div className="fixed inset-0 overflow-hidden z-50">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 max-w-full flex">
                <CandidateDrawer
                  candId={selectedCandidate}
                  setShowCandidate={handleDrawerClose}
                />
              </div>
            </div>
          </div>
        )}
        {showModal === "add-notes-modal" && (
          <AddNotes
            candidateDetail={candidateDetail}
            jobId={jobId}
            modalDismiss={modalClose}
            setContentReload={(newObj) => {
              setCandidates((prevData) =>
                prevData?.map((candidate) =>
                  candidate._id === newObj._id ? newObj : candidate,
                ),
              );
            }}
          />
        )}

        {showModal === "reject-reason-status" && (
          <RejectedReasonModal
            updateRejectedStatus={updateStatus}
            modalDismiss={modalClose}
            chatDetail={chatDetail}
            jobId={jobId}
            candidateId={candidateDetail?.id}
            sendText={sendText}
          />
        )}
      </div>
    </div>
  );
}
