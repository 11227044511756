import React, { useContext, useState } from "react";
import Modal from "../../../modal/Modal";
import { CandidatePageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { fetchCandidateActivity } from "../../../../redux/services";
const core = require("../../../../lib/core");

const NotesModal = ({ edit = false, data, noteid }) => {
  const dispatch = useDispatch();
  const { setShowModal } = useContext(CandidatePageContext);
  const { id, appId } = useSelector((state) => {
    return {
      id: state?.jobCandidateMatch?.profile?.matchProfile?._id,
      appId: state?.jobCandidateMatch?.profile?.applicant_id,
    };
  });

  const [content, setContent] = useState(edit ? data : "");
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const createNotes = async () => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_CANDIDATE_NOTES,
        1,
        {
          note: content,
          matchprofile: id,
        },
      );
      console.log(json);
      if (json?.data) {
        setLoading(false);
        setMessage({ type: "success", message: "Note added successfully" });
        dispatch(fetchCandidateActivity({ appId: appId }));
        setTimeout(() => {
          setMessage({});
          setShowModal(false);
        }, 1000);
      } else {
        console.log(json);
        setLoading(false);
        setMessage({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
    }
  };

  const updateNotes = async () => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_CANDIDATE_NOTES + noteid,
        1,
        {
          note: content,
          matchprofile: id,
        },
      );
      setLoading(false);
      if (json?.message == "OK") {
        setMessage({ type: "success", message: "Note updated successfully" });
        dispatch(fetchCandidateActivity({ appId: appId }));
        setTimeout(() => {
          setMessage({});
          setShowModal(false);
        }, 1000);
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
    }
  };

  return (
    <Modal
      heading={"Add new note"}
      subheading={"Enter your notes referring to the candidate"}
      modalDismiss={() => setShowModal(false)}
      onSave={edit ? updateNotes : createNotes}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
        primaryDisabled: loading,
      }}
    >
      <textarea
        value={content}
        onChange={(e) => setContent(e.target.value)}
        className="w-full h-40 p-4 border border-gray-300 rounded-md"
        placeholder="Enter your notes here"
      ></textarea>
      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
};

export default NotesModal;
