import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import parse from "html-react-parser";
import { FcApproval } from "react-icons/fc";
import ScreeningQuestionForm from "../screening/ScreeningQuestionForm";

const core = require("../../../lib/core");

const JobDescBody = ({
  orgDetails,
  jobDetails,
  criteria,
  departmentName,
  description,
  education,
  buttonBgColor,
  isTemplateUsed,
  scrollToForm,
}) => {
  const navigate = useNavigate();
  const formRef = useRef(null);

  function parseHtml(html) {
    const parsedHtml = parse(html.split("&lt;").join("<"));
    return parsedHtml;
  }

  if (!jobDetails) {
    return <p>Loading...</p>;
  }
  console.log("Organisation details are : ", orgDetails);
  const theme = orgDetails?.theme_color;

  const { roles, location, job_type, salary, experience } = jobDetails ?? {};
  const benefits = jobDetails?.benefits?.filter((benefit) => benefit.status);
  const bonus = jobDetails?.bonus?.filter((benefit) => benefit.status);

  const [resume, setResume] = useState(null);
  const [coverLetterText, setCoverLetterText] = useState("");
  const [coverLetterFile, setCoverLetterFile] = useState(null);
  const [isManualEntry, setIsManualEntry] = useState(false);

  const [perlocation, setperLocation] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [linkedInProfile, setLinkedInProfile] = useState("");
  const [currentCompany, setCurrentCompany] = useState("");
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState({});
  const [orgDetail, setOrgDetail] = useState(orgDetails);
  const [jobDetail, setJobDetail] = useState(jobDetails);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const textareaRef = useRef(null);
  // const [screenresponse, setScreenresponse] = useState([]);

  const loc = useLocation();

  // const handleLinkedInChange = (e) => {
  //   const { value } = e.target;
  //   setLinkedInProfile(value);
  //   // validateAndShowLinkedInError(value);
  // };

  const handleFileChange = (e) => {
    setCoverLetterFile(e.target.files[0]);
    setCoverLetterText(""); // clear text input if file is selected
    setIsManualEntry(false); // reset manual entry if file is selected
  };

  const handleManualEntry = () => {
    setIsManualEntry(true);
    setCoverLetterFile(null); // clear file input if manual entry is selected
    setTimeout(() => {
      textareaRef.current?.focus(); // Focus the textarea after it's rendered
    }, 0);
  };

  const handleTextChange = (e) => {
    setIsManualEntry(true);
    setCoverLetterText(e.target.value);
  };

  const handleResumeChange = (event) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.pdf|\.doc|\.docx|\.txt)$/i;

    if (!allowedExtensions.exec(file.name)) {
      setError((prev) => ({
        ...prev,
        resume: "Invalid file type. Please select a PDF, DOC, DOCX or TXT.",
      }));
      event.target.value = "";
    } else {
      setError((prev) => ({ ...prev, resume: "" }));
      setResume(file);
      setFileName("(" + file.name + ")");
    }
  };

  const validatePhone = (value) => {
    const phoneRegex = /^\d{10,14}$/;
    if (!phoneRegex.test(value)) {
      setError((prev) => ({
        ...prev,
        phone:
          "Phone number must be 10 to 14 digits long and contain only numbers",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, phone: "" }));
      return true;
    }
  };

  const validateLinkedIn = (value) => {
    if (!value.includes("linkedin")) {
      setError((prev) => ({
        ...prev,
        linkedInProfile: "Enter a valid LinkedIn profile",
      }));
      return false;
    } else {
      setError((prev) => ({ ...prev, linkedInProfile: "" }));
      return true;
    }
  };

  const validateAndShowPhoneError = (value) => {
    if (value && !validatePhone(value)) {
      setError((prev) => ({
        ...prev,
        phone:
          "Phone number must be 10 to 12 digits long and contain only numbers",
      }));
    } else {
      setError((prev) => ({ ...prev, phone: "" }));
    }
  };

  const validateAndShowLinkedInError = (value) => {
    if (value && !validateLinkedIn(value)) {
      setError((prev) => ({
        ...prev,
        linkedInProfile: "Enter a valid LinkedIn profile",
      }));
    } else {
      setError((prev) => ({ ...prev, linkedInProfile: "" }));
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value;
    // Allow only numbers, spaces, hyphens, parentheses, and plus signs
    const regex = /^[0-9+\-()\s]*$/;

    if (regex.test(input)) {
      setPhone(input);
    }
    validateAndShowPhoneError(input);
  };

  const handleLinkedInChange = (e) => {
    const { value } = e.target;
    setLinkedInProfile(value);
    validateAndShowLinkedInError(value);
  };

  useEffect(() => {
    if (phone) {
      validateAndShowPhoneError(phone);
    }
  }, [phone]);

  useEffect(() => {
    if (linkedInProfile) {
      validateAndShowLinkedInError(linkedInProfile);
    }
  }, [linkedInProfile]);

  const validateForm = () => {
    let valid = true;
    let newError = {};

    if (!resume) {
      newError.resume = "Resume is required.";
      valid = false;
    }
    if (!firstName) {
      newError.firstName = "First name is required.";
      valid = false;
    }
    if (!email) {
      newError.email = "Email is required.";
      valid = false;
    }

    if (!location) {
      newError.location = "Location is required.";
      valid = false;
    }
    if (!linkedInProfile) {
      newError.linkedInProfile = "Enter LinkedIn Profile.";
      valid = false;
    }

    const isLinkedInValid = validateLinkedIn(linkedInProfile);

    if (!isLinkedInValid) {
      valid = false;
    }

    setError(newError);
    return valid;
  };

  // const handleCoverChange = (event) => {
  //   const file = event.target.files[0];

  //   setCoverLetter(file);
  // };
  useEffect(() => {
    if (scrollToForm) {
      formRef.current.scrollIntoView({
        behavior: "smooth", // Ensures smooth scrolling
        block: "start", // Aligns the element at the start of the visible area of the scrollable ancestor
      });
    }
  }, [scrollToForm]);

  const fetchJob = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.ORGANIZATION_API.GET_SCREENING_QUESTION +
          loc.pathname.split("/").at(-1),
        1,
        {},
      );
      if (json && json.data) {
        // setOrgDetail(json?.data?.organization);
        // setJobDetail(json?.data);
        setQuestions(json?.data?.screeningQuestion);
      }
    } catch (err) {
      console.log("Error in fetching data from API:", err);
    }
  };

  useEffect(() => {
    fetchJob();
  }, []);

  // setScreenresponse(response);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    const response = questions.map((question) => ({
      _id: question._id,
      candidateResponse: question.candidateResponse,
    }));
    // console.log("")
    try {
      let formData = new FormData();
      formData.append("files", resume);
      // formData.append("coverLetter", coverLetter);
      if (!isManualEntry) {
        formData.append("cover_letter_files", coverLetterFile); // if manual entry
      } else {
        formData.append("cover_letter", coverLetterText); // if file upload
      }
      formData.append("jobId", loc.pathname.split("/").at(-1));
      formData.append("location", perlocation);
      formData.append("firstName", firstName);
      formData.append("lastName", lastName);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("linkedInProfile", linkedInProfile);
      formData.append("currentCompany", currentCompany);
      formData.append("screeningQuestion", JSON.stringify(response));

      await fetch(core.ORGANIZATION_API.POST_CANDIDATE_PROFILE, {
        method: "POST",
        headers: {},
        body: formData,
      })
        .then((res) => res.json())
        .then((result) => {
          if (result.data === "Duplicate found") {
            navigate("/job-post/submit", {
              state: {
                orgDetail,
                jobDetail,
                message: result.data,
                isTemplateUsed,
              },
            });
          } else {
            navigate("/job-post/submit", {
              state: { orgDetail, jobDetail, isTemplateUsed },
            });
          }
        });

      setLoading(false);
    } catch (err) {
      console.error("Error submitting the form", err);
      setError((prev) => ({
        ...prev,
        form: "An error occurred while submitting the form.",
      }));
      setLoading(false);
    }
  };
  console.log("The theme is : ", theme);
  return (
    <>
      <div
        className={`${isTemplateUsed ? " max-w-5xl w-full mx-auto " : " max-w-5xl mx-auto"}`}
      >
        <div className="inline-flex items-center mb-4 bg-green-200 px-3 py-2 rounded-md">
          <svg
            viewBox="0 -1 32 32"
            className="w-5 h-5 text-green-900 mr-2"
            fill="currentColor"
          >
            <g id="SVGRepo_iconCarrier">
              <g
                id="Page-1"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
              >
                <g
                  id="Icon-Set-Filled"
                  transform="translate(-206.000000, -206.000000)"
                  fill="#4CA"
                >
                  <path
                    d="M224,222 C224,221.634 223.895,221.296 223.723,221 L220.277,221 C220.105,221.296 220,221.634 220,222 C220,223.104 220.896,224 222,224 C223.104,224 224,223.104 224,222 L224,222 Z M226,222 C226,224.209 224.209,226 222,226 C219.791,226 218,224.209 218,222 C218,221.652 218.054,221.319 218.141,221 L206,221 L206,232 C206,234.209 207.791,236 210,236 L234,236 C236.209,236 238,234.209 238,232 L238,221 L225.859,221 C225.946,221.319 226,221.652 226,222 L226,222 Z M226,210 L218,210 L218,209 C218,208.448 218.448,208 219,208 L225,208 C225.552,208 226,208.448 226,209 L226,210 L226,210 Z M234,210 L228,210 L228,208 C228,206.896 227.104,206 226,206 L218,206 C216.896,206 216,206.896 216,208 L216,210 L210,210 C207.791,210 206,211.791 206,214 L206,219 L238,219 L238,214 C238,211.791 236.209,210 234,210 L234,210 Z"
                    id="briefcase"
                  ></path>
                </g>
              </g>
            </g>
          </svg>

          <span className="text-green-700 font-semibold">Actively hiring</span>
        </div>

        {/* {isTemplateUsed ? null : (
          <>
            <h1 className="text-2xl font-bold mb-2">{jobDetails?.name}</h1>
            <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-1 mb-4 pb-5">
              <span className="text-gray-700 pr-2">{location?.[0]?.name}</span>
            </div>
          </>
        )} */}

        <div
          className={`grid grid-cols-1 ${isTemplateUsed ? "my-5" : ""} sm:grid-cols-2 gap-4 mt-5 mb-4 pb-3`}
        >
          {job_type?.some((type) => type.status) && (
            <div className="flex">
              <div className="font-semibold">Job type : </div>
              <div className="pl-3">
                {job_type
                  ?.filter((type) => type.status)
                  ?.map(
                    (type) =>
                      type.type.charAt(0).toUpperCase() + type.type.slice(1),
                  )
                  ?.join(", ")}
              </div>
            </div>
          )}

          {departmentName && (
            <div className="flex">
              <div className="font-semibold">Department : </div>
              <div className="pl-4"> {departmentName} </div>
            </div>
          )}
          {/* <div className="flex">
            <div className="font-semibold">Notice period : </div>
            <div className="pl-4"> Immediate</div>
          </div> */}
          {jobDetails?.workplace && (
            <div className="flex">
              <div className="font-semibold">Workplace type : </div>
              <div className="pl-4"> {jobDetails?.workplace}</div>
            </div>
          )}
          {salary?.salvisibility !== "Hide" && (
            <>
              {salary?.min !== undefined && (
                <div className="flex">
                  <div className="font-semibold">Expected salary :</div>
                  <div className="pl-4">
                    {salary.currency ? salary.currency : "$"}
                    {salary.min}
                    {salary.max !== undefined
                      ? ` - ${salary.currency ? salary.currency : "$"}${salary.max}`
                      : ""}
                    {salary.duration ? `  ${salary.duration}` : ""}
                  </div>
                </div>
              )}
            </>
          )}
          {/* <div className="flex">
            <div className="font-semibold">Expected Equity : </div>
            <div className="pl-4"> 2% - 5%</div>
          </div> */}
          {/* {experience?.max && (
            <div className="flex">
              <div className="font-semibold">Experience : </div>
              <div className="pl-4"> {experience?.min} - {experience?.max} years</div>
            </div>
          )} */}
          {experience?.min !== undefined && (
            <div className="flex">
              <div className="font-semibold">Experience :</div>
              <div className="pl-4">
                {experience.min}
                {experience.max !== undefined
                  ? ` - ${experience.max}`
                  : ""}{" "}
                years
              </div>
            </div>
          )}
        </div>

        <hr className="border-gray-400 " />
        <div className=" text-justify text-sm">
          {description && (
            <div className="mb-4 pt-4">
              <h2 className="text-lg font-semibold mb-2">Job Details</h2>
              <p className="mb-2 text-gray-700 prose !min-w-full">
                {description}
              </p>
            </div>
          )}
          {/* {roles && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2 pt-4">
                Roles And Responsibilities
              </h2>
              <p className="mb-2 text-gray-700">{roles}</p>
            </div>
          )} */}
          {/* {education && (
            <div className="mb-4">
              <h2 className="text-lg font-semibold mb-2 pt-4">
                Experience And Education
              </h2>
              <p className="mb-2 text-gray-700">{education}</p>
            </div>
          )} */}
        </div>
        {criteria?.length > 0 && (
          <div className="mb-4">
            <h2 className="text-lg pt-3 font-semibold mb-2">Skills</h2>
            <div className="flex text-gray-700 flex-wrap gap-2 pt-2">
              {criteria?.map((criteriaValue, index) => (
                <div key={index} className="mb-4">
                  <span className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg mr-2 mb-2">
                    {criteriaValue}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        {bonus?.length > 0 && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2 pt-3">
              Do you offer any other compensation pay?
            </h2>
            <div className="flex text-gray-700 flex-wrap gap-2 pt-2">
              {bonus?.map((bonusItem, index) => (
                <span
                  key={index}
                  className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg"
                >
                  {bonusItem?.benifits}
                </span>
              ))}
            </div>
          </div>
        )}
        {benefits?.length > 0 && (
          <div className="mb-4">
            <h2 className="text-lg font-semibold mb-2 pt-3">
              Benefits offered
            </h2>
            <div className="flex text-gray-700 flex-wrap gap-2 py-2">
              {benefits?.map((benefit, index) => (
                <span
                  key={index}
                  className="bg-gray-200 text-gray-700 px-3 py-1 rounded-lg"
                >
                  {benefit?.benifits}
                </span>
              ))}
            </div>
          </div>
        )}

        {isTemplateUsed ? (
          <section className="my-10  w-full">
            <form
              className="w-full bg-defaultBg p-8 rounded-lg shadow-md"
              onSubmit={handleSubmit}
              ref={formRef}
            >
              <h2 className="text-md text-[#002332] font-semibold mb-6">
                Apply for this Job
              </h2>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 required"
                    htmlFor="firstName"
                  >
                    First Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700 border-2 border-transparent rounded py-3 px-4 mb-3 leading-tight focus:bg-white focus:border-gray-500"
                    id="firstName"
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                  />
                  {error.firstName && (
                    <p className="text-red-900 mt-2">{error.firstName}</p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 required"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700  border-2 border-transparent rounded py-3 px-4 mb-3 leading-tight focus:bg-white focus:border-gray-500"
                    id="lastName"
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                  />
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 required"
                    htmlFor="email"
                  >
                    Email
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700  border-2 border-transparent rounded py-3 px-4 mb-3 leading-tight focus:bg-white focus:border-gray-500"
                    id="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                  {error.email && (
                    <p className="text-red-900 mt-2">{error.email}</p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2 "
                    htmlFor="phone"
                  >
                    Phone
                  </label>
                  <input
                    className="appearance-none block w-full bg-white text-gray-700  border-2 border-transparent rounded py-3 px-4 mb-3 leading-tight focus:bg-white focus:border-gray-500"
                    id="phone"
                    type="text"
                    value={phone}
                    onChange={handlePhoneChange}
                  />
                  {error.phone && (
                    <p className="text-red-900 mt-2">{error.phone}</p>
                  )}
                </div>
              </div>

              {/* <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold required" htmlFor="resume">
                Resume/CV
              </label>
              <a href="#" className="text-teal-400 hover:no-underline mr-4 hover:text-teal-500">Attach, Dropbox, Google Drive, Paste</a>
              <p className="text-gray-600 text-xs font-medium italic ">File types: pdf, doc, docx, txt, rtf</p>
            </div>


            <div className="mb-6">
              <label className="block text-gray-700 text-sm font-bold " htmlFor="coverLetter">
                Cover Letter
              </label>
              <a href="#" className="text-teal-400 hover:no-underline mr-4 hover:text-teal-500">Attach, Dropbox, Google Drive, Paste</a>

              <p className="text-gray-600 text-xs font-medium italic ">File types: pdf, doc, docx, txt, rtf</p>
            </div> */}
              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold required"
                  htmlFor="resume"
                >
                  Resume/CV
                </label>
                <a
                  // href="#"
                  className="flex text-teal-400 hover:no-underline mr-4 hover:text-teal-500 no-underline hover:cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    document.getElementById("resumeInput").click();
                  }}
                >
                  Browse and Attach{" "}
                  {fileName ? <FcApproval className="text-lg ml-2" /> : null}
                </a>
                <input
                  type="file"
                  id="resumeInput"
                  onChange={handleResumeChange}
                  accept=".pdf, .doc, .docx, .txt, .rtf"
                  style={{ display: "none" }}
                />
                <p
                  className={` text-xs font-medium ${fileName ? "text-gray-700 font-extrabold" : "italic text-gray-600"}`}
                >
                  {fileName ? fileName : "File types: pdf, doc, docx, txt, rtf"}
                </p>
                {error.resume && (
                  <p className="text-red-900 mt-2">{error.resume}</p>
                )}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-sm font-bold mb-1">
                  Cover Letter
                </label>

                {/* Buttons for attach and manual entry */}
                <div className="flex items-center gap-2">
                  <label className="cursor-pointer">
                    <input
                      type="file"
                      accept=".pdf,.doc,.docx,.txt,.rtf"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                    <span className="px-3 py-1 font-normal text-gray-700 bg-white border-2 border-gray-500 rounded-lg cursor-pointer">
                      Browse and Attach
                    </span>
                  </label>
                  <span className="text-teal-400">/</span>
                  <label className="cursor-pointer">
                    <span
                      className="px-3 py-1 font-normal text-gray-700 bg-white border-2 border-gray-500 rounded-lg cursor-pointer"
                      onClick={handleManualEntry}
                    >
                      or enter manually
                    </span>
                  </label>
                  {coverLetterFile ? (
                    <FcApproval className="text-lg ml-2" />
                  ) : null}
                </div>
                {coverLetterFile ? (
                  <p className="text-xs  text-gray-700 font-medium mt-1">
                    {"(" + coverLetterFile.name + ")"}
                  </p>
                ) : (
                  <p className="text-xs mt-1 font-medium italic text-gray-600">
                    File types: pdf, doc, docx, txt, rtf
                  </p>
                )}

                {/* Conditional rendering for text area if manual entry is selected */}
                {/* {isManualEntry && ( */}
                {coverLetterFile ? null : (
                  <textarea
                    rows={5}
                    ref={textareaRef}
                    value={coverLetterText}
                    className={`mt-2 p-3 bg-white border-2 border-transparent rounded-lg w-full placeholder-gray-600 focus:outline-none transition-colors focus:bg-white focus:border-gray-500 h-28 resize-none `}
                    onChange={handleTextChange}
                  />
                )}
                {/* )} */}
              </div>

              <hr className="my-4 border-gray-500" />

              <div className="my-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required"
                  htmlFor="linkedin"
                >
                  LinkedIn Profile
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700  border-2 border-transparent rounded py-3 px-4 mb-3 leading-tight focus:bg-white focus:border-gray-500"
                  id="linkedin"
                  type="text"
                  value={linkedInProfile}
                  required={true}
                  onChange={handleLinkedInChange}
                />
                {error.linkedInProfile && (
                  <p className="text-red-900 mt-2">{error.linkedInProfile}</p>
                )}
              </div>

              <div className="mb-6">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2 required"
                  htmlFor="location"
                >
                  Location
                </label>
                <input
                  className="appearance-none block w-full bg-white text-gray-700  border-2 border-transparent rounded py-3 px-4 mb-3 leading-tight focus:bg-white focus:border-gray-500"
                  id="location"
                  type="text"
                  value={perlocation}
                  required={true}
                  onChange={(e) => setperLocation(e.target.value)}
                />
                {error.location && (
                  <p className="text-red-900 mt-2">{error.location}</p>
                )}
              </div>

              {/* {jobDetail?.screeningQuestion?.length > 0 && (
                <ScreeningQuestionForm
                  questions={questions}
                  setQuestions={setQuestions}
                // theme={theme}
                />
              )} */}
              {/* {jobDetail?.screeningQuestion && ( */}
              <ScreeningQuestionForm
                questions={questions}
                setQuestions={setQuestions}
                theme={theme}
                isTemplateUsed={isTemplateUsed}
              />
              {/* )} */}

              <div className="flex justify-end mt-4 mb-2">
                <button
                  style={{ backgroundColor: theme }}
                  className="text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline hover:scale-105 transition-all duration-300"
                  type="submit"
                >
                  {loading ? (
                    <ScaleLoader height={14} color="#ffffff" loading={true} />
                  ) : (
                    "Submit Application"
                  )}
                </button>
              </div>
            </form>
          </section>
        ) : (
          <div className="flex flex-col items-center justify-center mt-4 py-4 md:pt-10 space-y-4 ">
            <button
              onClick={() =>
                navigate(`/job-post/form/${jobDetails?._id}`, {
                  state: {
                    name: jobDetails?.name,
                    locationName: location?.[0]?.name,
                  },
                })
              }
              className={` text-white gap-2 font-semibold hover:font-bold px-4 py-2 rounded-md flex items-center justify-center transition duration-200 ease-in-out transform  hover:scale-105 w-full md:w-[10vw]`}
              style={{ backgroundColor: buttonBgColor }}
            >
              Apply
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13 7l5 5m0 0l-5 5m5-5H6"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default JobDescBody;

// isTemplateUsed ? (
//   <div className="w-full mx-auto bg-white flex flex-col items-center justify-center">
//     <div className="max-w-4xl p-4 flex flex-col justify-center items-center">
//       <section className="my-10">
//         <h2 className="text-md text-[#002332] font-bold mb-2">{aboutCompanyTitle}</h2>
//         <p className="text-xs font-medium text-[#264350] text-justify">{aboutCompanyText1}</p>
//         {/* <p className="text-xs font-medium text-[#264350] text-justify">{aboutCompanyText2}</p>
//         <p className="text-xs font-medium text-[#264350] text-justify">{aboutCompanyText3}</p> */}
//       </section>

//       <section className="mb-10">
//         <h2 className="text-md text-[#002332] font-bold mb-2">{aboutRoleTitle}</h2>
//         <p className="text-xs font-medium text-[#264350] text-justify">{aboutRoleText1}</p>
//         {/* <p className="text-xs font-medium text-[#264350] text-justify">{aboutRoleText2}</p>
//         <p className="text-xs font-medium text-[#264350] text-justify italic">{aboutRoleText3}</p> */}
//       </section>

//       <section className="mb-10 w-full text-left" id="know">
//         <h2 className="text-md text-[#002332] font-bold mb-2">{youllGetToTitle}</h2>
//         <p className="text-xs font-medium text-[#264350] text-justify">
//           {youllGetToList}
//         </p>
//       </section>

//       <section className="mb-16 w-full text-left">
//         <h2 className="text-md text-[#002332] font-bold mb-4">{wereLookingForTitle}</h2>
//         <ul className="list-disc list-inside space-y-1 text-[#264350] text-xs font-medium">
//           {wereLookingForList.map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
//       </section>

//       <section className="mb-6 w-full text-left">
//         <h2 className="text-md text-[#002332] font-bold mb-4">{whyTailorBirdTitle}</h2>
//         <p className="text-[#264350] mb-4 text-xs font-medium">{whyTailorBirdText1}</p>
//         <p className="mb-4 text-xs text-[#264350] font-medium">{whyTailorBirdText2}</p>
//         <ul className="list-disc space-y-1 pl-5 text-xs font-medium text-[#264350]">
//           {valuesList.map((item, index) => (
//             <li key={index} >
//               <span className="font-bold text-[#002332] mr-1">{item.title}:</span> {item.description}
//             </li>
//           ))}
//         </ul>
//       </section>

//       <section className="w-full text-left mb-2">
//         <h2 className="text-base text-[#002332] font-bold mb-2">{benefitsAndPerksTitle}</h2>
//         <ul className="list-disc space-y-1 pl-5 text-xs font-medium text-[#264350]">
//           {benefitsList.map((item, index) => (
//             <li key={index}>{item}</li>
//           ))}
//         </ul>
//       </section>

//       <p className="text-[#264350] text-xs font-medium my-10 italic">{equalOpportunityText}</p>
//       <section className="mb-10  w-full">
//         <form className="w-full bg-defaultBg p-8 rounded-lg shadow-md">
//           <h2 className="text-md text-[#002332] font-semibold mb-6">Apply for this Job</h2>

//           <div className="flex flex-wrap -mx-3 mb-6">
//             <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
//               <label className="block text-gray-700 text-sm font-bold mb-2 required" htmlFor="firstName">
//                 First Name
//               </label>
//               <input
//                 className="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white "
//                 id="firstName"
//                 type="text"
//                 required
//               />
//             </div>
//             <div className="w-full md:w-1/2 px-3">
//               <label className="block text-gray-700 text-sm font-bold mb-2 required" htmlFor="lastName">
//                 Last Name
//               </label>
//               <input
//                 className="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
//                 id="lastName"
//                 type="text"
//                 required
//               />
//             </div>
//           </div>

//           <div className="flex flex-wrap -mx-3 mb-6">
//             <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
//               <label className="block text-gray-700 text-sm font-bold mb-2 required" htmlFor="email">
//                 Email
//               </label>
//               <input
//                 className="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
//                 id="email"
//                 type="email"
//                 required
//               />
//             </div>
//             <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
//               <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phone">
//                 Phone
//               </label>
//               <input
//                 className="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
//                 id="phone"
//                 type="text"
//               />
//             </div>
//           </div>

//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold required" htmlFor="resume">
//               Resume/CV
//             </label>
//             <a href="#" className="text-teal-400 hover:no-underline mr-4 hover:text-teal-500">Attach, Dropbox, Google Drive, Paste</a>
//             <p className="text-gray-600 text-xs font-medium italic ">File types: pdf, doc, docx, txt, rtf</p>
//           </div>

//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold " htmlFor="coverLetter">
//               Cover Letter
//             </label>
//             <a href="#" className="text-teal-400 hover:no-underline mr-4 hover:text-teal-500">Attach, Dropbox, Google Drive, Paste</a>
//             {/* <a href="#" className="text-teal-600 hover:underline mr-4">Dropbox</a>,
//               <a href="#" className="text-teal-600 hover:underline mr-4">Google Drive</a>,
//               <a href="#" className="text-teal-600 hover:underline">Paste</a> */}
//             <p className="text-gray-600 text-xs font-medium italic ">File types: pdf, doc, docx, txt, rtf</p>
//           </div>

//           <hr className="my-4 border-gray-500" />

//           <div className="my-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2 required" htmlFor="linkedin">
//               LinkedIn Profile
//             </label>
//             <input
//               className="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
//               id="linkedin"
//               type="text"
//               required
//             />
//           </div>

//           <div className="mb-6">
//             <label className="block text-gray-700 text-sm font-bold mb-2 required" htmlFor="location">
//               Location
//             </label>
//             <input
//               className="appearance-none block w-full bg-white text-gray-700 border rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
//               id="location"
//               type="text"
//               required
//             />
//           </div>

//           <div className="flex justify-end my-2">
//             <button
//               className="bg-[#004d71] hover:bg-blue-700 text-white font-medium py-2 px-4 rounded focus:outline-none focus:shadow-outline"
//               type="submit"
//             >
//               Submit Application
//             </button>
//           </div>
//         </form>

//       </section>
//     </div>
//   </div>

// ) :
