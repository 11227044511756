import { toast } from "react-toastify";
import Dashboard from "../../screens/candidate/dashboard/Dashboard";

const core = require("../../lib/core");

export default function Bookmark({ bookmarked, job_id, reload_function }) {
  async function bookmarkJob(job_id) {
    try {
      let response = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_SAVE_JOB + String(job_id),
        1,
        {},
      );
      reload_function();
      toast.success(response.data.message);
    } catch (err) {
      console.log(err);
    }
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 32 32"
      fill="none"
      id={job_id}
      className="unmarked"
      onClick={(e) => {
        e.preventDefault();
        bookmarkJob(job_id);
      }}
    >
      <path
        d="M5 28V11.3333C5 10.2287 5.89543 9.33331 7 9.33331H17.6671C18.7716 9.33331 19.6671 10.2287 19.6671 11.3333V28L12.3335 20.6464L5 28Z"
        stroke="#001B4B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill={bookmarked ? "#85ADF9" : "#ffffff"}
      />
      <path
        d="M12.332 7.11111V6C12.332 4.89543 13.2275 4 14.332 4H24.9991C26.1037 4 26.9991 4.89543 26.9991 6V22.6667"
        stroke="#001B4B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.9988 22.6667L21.957 17.7778"
        stroke="#001B4B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
