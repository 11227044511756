import React, { useEffect, useState } from "react";
const core = require("../../../src/lib/core");
import Header from "./JobPostHeader";
import JobList from "./JobList";
// import Footer from "./JobPostFooter";
import { ScaleLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import NotFound from "./PageNotFound";
import Sprouts from "../../components/assets/sprouts.png";

import TailorLogo from "../../../src/components/assets/tailorlogo.jpg";
import MipsLogo from "../../../src/components/assets/MipsLogo.png";

const Jobs = () => {
  const [jobList, setJobList] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const fetchJob = async () => {
    setLoading(true);

    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_ORG_JOB_LIST +
          `/${location?.pathname?.split("/")?.at(-1)}`,
        1,
        {}
      );
      setJobList(json.data.content);
      setOrgDetails(json.data.organization);
      console.log("Organisation Details are :", json.data.organization);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJob();
  }, [location]);

  const isTemplateUsed = true;
  const url = String(orgDetails?.domain_url).startsWith("http")
    ? orgDetails?.domain_url
    : `http://${orgDetails?.domain_url}`;

  return (
    <>
      {loading ? (
        <div className="w-screen h-screen flex items-center justify-center">
          <ScaleLoader height={36} color="blue" loading={true} />
        </div>
      ) : (
        <>
          {orgDetails.careers_page_integration ? (
            <div
              className={`h-screen flex w-screen flex-col overflow-auto gap-12 `}
            >
              {orgDetails?.template ? (
                <div
                  className="flex justify-center"
                  style={{
                    backgroundColor: orgDetails?.theme_color ?? "#002332",
                  }}
                >
                  <div
                    className={`
                    flex-grow py-5 
                    ${orgDetails?.template ? "max-w-[80%] pl-10" : "w-full"} 
                    flex justify-between items-center 
                    border-b border-b-gray-400 
                    sm:px-[5%] gap-8 
                    h-auto mb-0 
                    mt-5
                  `}
                  >
                    <div className="flex flex-col ml-4">
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        {orgDetails?.name === "Tailorbird" ? (
                          <img
                            src={TailorLogo}
                            alt="Logo"
                            className="max-w-[60%] h-auto object-contain"
                          />
                        ) : (
                          <>
                            <img
                              src={orgDetails?.logo}
                              alt="Logo"
                              className="max-w-[60%] min-w-[12rem] h-auto object-contain"
                            />
                          </>
                        )}
                      </a>

                      <p className="text-white text-lg font-bold mt-4">
                        {orgDetails?.name}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                // <Header orgDetails={orgDetails} />
                <div className="bg-defaultBg w-full ">
                  <div className="max-w-6xl w-full mx-auto md:px-6 lg:px-0">
                    <div className="max-w-6xl w-full mx-auto md:px-6 lg:px-0">
                      <div className="py-10 flex justify-between items-center border-b border-gray-400 px-4 sm:px-32 lg:px-10 gap-8">
                        <a href={url} target="_blank" rel="noopener noreferrer">
                          <img
                            src={orgDetails?.logo}
                            alt="Logo"
                            className="ml-4 sm:ml-32 md:ml-20 lg:ml-0 min-w-[12rem] max-w-[50%] sm:max-w-[30%] md:max-w-[25%] h-auto object-contain"
                          />
                        </a>
                        {/* <div
      className={`max-w-[50%] text-center text-white font-medium px-6 py-2 rounded-md hover:scale-105 transition duration-300`}
      style={{
        backgroundColor: orgDetails?.theme_color ?? "#1369E9",
      }}
    >
      <a
        href={url}
        className="hover:no-underline no-underline text-white"
        target="_blank"
        rel="noopener noreferrer"
      >
        Company Website
      </a>
    </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {orgDetails?.template ? (
                <div
                  className="flex justify-center bg-defaultBg"
                  // style={{
                  //   backgroundColor: defaultBg,
                  // }}
                >
                  <div
                    className={`
                    flex-grow py-5 
                    ${orgDetails?.template ? "max-w-[80%] pl-10" : "w-full"} 
                    flex justify-between items-center 
                    border-b border-b-gray-400 
                    sm:px-[5%] gap-8 
                    h-auto mb-0 
                    mt-5
                  `}
                  >
                    <div className="flex flex-col -ml-6 mb-2">
                      <img
                        src={MipsLogo}
                        alt="Logo"
                        className="max-w-[40%] h-auto object-contain"
                      />

                      {/* <p className="text-white text-lg font-bold mt-4">MIPS</p> */}
              {/* </div>
                  </div>
                </div>
              ) : (
                <Header orgDetails={orgDetails} />
              )} */}
              <JobList
                isTemplateUsed={orgDetails?.template}
                jobList={jobList}
                orgName={orgDetails?.names}
                orgDetails={orgDetails}
              />

              {/* Footer */}

              <footer className="bg-gray-200 py-6">
                <div className="container mx-auto flex items-center justify-center">
                  <p className="text-gray-600 mr-1">Powered By</p>
                  <a
                    href="https://www.sproutsai.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Sprouts}
                      alt="Sprouts Logo"
                      className="w-28 h-auto ml-2 mb-1 pt-1"
                    />
                  </a>
                </div>
                <div className="text-center mt-2">
                  {/* <p className="text-sm no-underline">
                    Read our{" "}
                    <a
                      href="https://www.sproutsai.com/privacy"
                      className="text-blue-600 hover:no-underline no-underline hover:text-blue-900 hover:font-medium transition-all duration-300"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </p> */}
                </div>
              </footer>
            </div>
          ) : (
            <NotFound />
          )}
        </>
      )}
    </>
  );
};

export default Jobs;
