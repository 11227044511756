import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import SelectBox from "../../layouts/SelectBox";
import InputBox from "../../layouts/InputBox";

const core = require("../../lib/core");

function AddChatUserModal({
  modalDismiss,
  addNewsmsUser,
  setNewUserName,
  newUserNumber,
  setNewUserNumber,
}) {
  const [selectUser, setSelectUser] = useState("");
  const [teamMember, setTeamMember] = useState("");
  const [team, setTeam] = useState([]);
  console.log("new", newUserNumber);

  const fetchTeamMembers = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USERS_WITH_ROLE,
        1,
      );
      if (json.data) {
        setTeam(json?.data?.content);
        console.log("recruiter", json.data);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchTeamMembers();
  }, []);

  return (
    <Modal
      heading={"Add new chat user"}
      modalDismiss={modalDismiss}
      onSave={() => {
        if (selectUser === "From team") {
          addNewsmsUser(teamMember);
        } else if (selectUser === "External") {
          addNewsmsUser("");
        }
      }}
      defaultButtons={{
        // primaryDisabled: {},
        primaryLabel: "Save",
        //   primaryLabel: loading ? (
        //     <ScaleLoader height={14} color="#ffffff" loading={true} />
        //   ) : (
        //     "Save"
        //   ),
      }}
    >
      <div className="">
        <form action="" onSubmit={(e) => {}}>
          <div className="mt-[20px]">
            <label className="text-sm font-normal  text-gray-800 not-italic required">
              Select user type
            </label>
            <SelectBox
              value={selectUser}
              onChange={(e) => setSelectUser(e.target.value)}
              options={["From team", "External"]}
              defaultValue={""}
            />
          </div>

          {selectUser === "From team" ? (
            <div className="mt-[20px]">
              <label className="text-sm font-normal  text-gray-800 not-italic required">
                Select team member
              </label>
              <select
                name=""
                id=""
                value={teamMember}
                onChange={(e) => setTeamMember(e.target.value)}
                className="w-[100%]  h-[40px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
              >
                <option value="" disabled>
                  Select
                </option>
                {team?.map((member) => (
                  <option value={member?.id} key={member?.id}>
                    {member?.email}
                  </option>
                ))}
              </select>
            </div>
          ) : selectUser === "External" ? (
            <>
              <div className="mt-[20px]">
                <label className="text-sm font-normal  text-gray-800 not-italic required">
                  Name
                </label>
                <InputBox
                  type="text"
                  required
                  onChange={(e) => setNewUserName(e.target.value)}
                  placeholder="name"
                />
              </div>
              <div className="mt-[20px]">
                <label className="text-sm font-normal  text-gray-800 not-italic required">
                  Phone
                </label>
                <PhoneInput
                  placeholder="Enter phone number"
                  containerStyle={{
                    width: "100%",
                    height: "40px",
                    border: "none",
                  }}
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                    backgroundColor: "#fff",
                    border: "solid #EFF0F2",
                    borderWidth: "1px",
                    borderRadius: "8px",
                    height: "40px",
                  }}
                  buttonStyle={{
                    backgroundColor: "#F4F8FF",
                    borderRight: "solid #EFF0F2",
                    borderWidth: "1px",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                    height: "40px",
                  }}
                  country={"us"}
                  value={newUserNumber}
                  onChange={setNewUserNumber}
                />
              </div>
            </>
          ) : null}
        </form>
      </div>
    </Modal>
  );
}

export default AddChatUserModal;
