import { useState, useRef } from "react";
import { useEffect } from "react";
import Header from "../../../components/all/header/Header";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import { Link } from "react-router-dom";
import AppliedJobPost from "../../../components/candidate/dashboard/AppliedJobPost";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
import InitialsLogo from "../../../components/all/InitialsLogo";
import { IoMdTime } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa6";
import parse from "html-react-parser";
import { FcApproval } from "react-icons/fc";
import { MdOutlineWorkOutline } from "react-icons/md";
import { MdLocationCity } from "react-icons/md";
import { ScaleLoader } from "react-spinners";
const core = require("../../../lib/core");

const jobPosts = [
  {
    id: 1,
    company: {
      name: "Tech Solutions",
      establishedYear: 2010,
      employees: "200-500",
    },
    position: "Software Engineer",
    location: ["New York, NY"],
    jobType: "Full-time",
    description:
      "We are looking for a skilled software engineer to join our team and work on cutting-edge projects.",
    skills: ["JavaScript", "React", "Node.js"],
    createdAt: "2023-07-25",
  },
  {
    id: 2,
    company: {
      name: "Innovatech",
      establishedYear: 2015,
      employees: "50-200",
    },
    position: "Frontend Developer",
    location: ["San Francisco, CA"],
    jobType: "Part-time",
    description:
      "Seeking a talented frontend developer to create beautiful and responsive web applications.",
    skills: ["HTML", "CSS", "JavaScript", "Vue.js"],
    createdAt: "2023-07-20",
  },
  {
    id: 3,
    company: {
      name: "NextGen Solutions",
      establishedYear: 2012,
      employees: "100-300",
    },
    position: "Backend Developer",
    location: ["Austin, TX"],
    jobType: "Full-time",
    description:
      "Join our team as a backend developer and help us build scalable and efficient server-side applications.",
    skills: ["Node.js", "Express", "MongoDB"],
    createdAt: "2023-07-18",
  },
  {
    id: 4,
    company: {
      name: "Cloud Innovators",
      establishedYear: 2008,
      employees: "500-1000",
    },
    position: "DevOps Engineer",
    location: ["Seattle, WA"],
    jobType: "Full-time",
    description:
      "Looking for a DevOps engineer to automate and streamline our operations and processes.",
    skills: ["AWS", "Docker", "Kubernetes"],
    createdAt: "2023-07-22",
  },
  {
    id: 5,
    company: {
      name: "CyberTech",
      establishedYear: 2016,
      employees: "30-100",
    },
    position: "Security Analyst",
    location: ["Boston, MA"],
    jobType: "Contract",
    description:
      "We need a security analyst to help protect our systems and data from cyber threats.",
    skills: ["Cybersecurity", "Network Security", "SIEM"],
    createdAt: "2023-07-27",
  },
];

export default function AppliedJobs() {
  // const [appliedJobs, setAppliedJobs] = useState([]);

  // useEffect(() => {
  //   fetchAppliedJobs();
  // }, []);

  // const fetchAppliedJobs = async () => {
  //   const jobList = await core.API(
  //     core.API_METHODS.GET,
  //     core.CANDIDATE_API.GET_APPLIED_JOBS,
  //     1,
  //     {},
  //   );
  //   console.log(jobList);
  //   if (jobList.data) {
  //     setAppliedJobs(jobList.data.jobs);
  //   } else {
  //     console.log(jobList.error);
  //   }
  // };

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        {/* <CandidateSidebar active="Applied" open={false} /> */}
        <div className="w-full">
          <HeaderNew />
          <JobContent
          // appliedJobs={appliedJobs}
          // fetchAppliedJobs={fetchAppliedJobs}
          />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="page candidate-pages" id="applied-jobs">
  //     <Header showOnlyLogo={false} menu={<Menu />} />
  //     <SideBar />
  //     <main className="main-content">
  //       <div className="page-container col-xs-12 ">
  //         <h1 className="headingText">Applied Jobs</h1>
  //         {appliedJobs.length != 0 && (
  //           <div>
  //             {appliedJobs.map((job, i) => (
  //               <AppliedJobPost
  //                 key={i}
  //                 job={job}
  //                 reload_function={fetchAppliedJobs}
  //               />
  //             ))}
  //           </div>
  //         )}
  //       </div>
  //     </main>
  //   </div>
  // );
}

const JobContent = () => {
  const [scaleLoading, setScaleLoading] = useState(false);
  const timeDifference = (difference) => {
    if (difference < 1000) {
      return "Just now";
    } else if (difference < 60000) {
      return `${Math.floor(difference / 1000)} seconds ago`;
    } else if (difference < 3600000) {
      return `${Math.floor(difference / 60000)} minutes ago`;
    } else if (difference < 86400000) {
      return `${Math.floor(difference / 3600000)} hours ago`;
    } else if (difference < 2592000000) {
      return `${Math.floor(difference / 86400000)} days ago`;
    } else if (difference < 31536000000) {
      return `${Math.floor(difference / 2592000000)} months ago`;
    } else {
      return `${Math.floor(difference / 31536000000)} years ago`;
    }
  };

  function parseHtml(html) {
    const parsedHtml = parse(html.split("&lt;").join("<"));
    return parsedHtml;
  }

  const [appliedJobs, setAppliedJobs] = useState([]);

  useEffect(() => {
    fetchAppliedJobs();
  }, []);

  const fetchAppliedJobs = async () => {
    try {
      setScaleLoading(true);
      const jobList = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_APPLIED_JOBS,
        1,
        {},
      );
      console.log(jobList);
      if (jobList.data) {
        setAppliedJobs(jobList?.data);
      } else {
        console.log(jobList.error);
      }
      setScaleLoading(false);
    } catch (err) {
      console.log("Error is :", err);
      setScaleLoading(false);
    }
  };

  console.log("Applied Jobs are : ", appliedJobs);

  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
      <span className="font-semibold text-[28px] not-italic text-[#16182F]">
        Applied Jobs
      </span>

      {/* <div className="page candidate-pages mt-3 rounded-md" id="applied-jobs">
        <main className="main-content">
          <div className="page-container col-xs-12 ">
            {appliedJobs && (
              <div>
                {appliedJobs.map((job, i) => (
                  <AppliedJobPost
                    key={i}
                    job={job}
                    reload_function={fetchAppliedJobs}
                  />
                ))}
              </div>
            )}
          </div>
        </main>
      </div> */}
      {scaleLoading ? (
        <div className="w-full min-h-full flex items-center justify-center">
          <div className="auto-complete-list mt-10 absolute no-list-style w-[97%] rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
            <ScaleLoader height={14} color="#1369E9" loading={true} />
          </div>
        </div>
      ) : (
        <div className="page candidate-pages mt-3 rounded-md" id="applied-jobs">
          <main className="main-content">
            <div className="page-container col-xs-12">
              {appliedJobs && appliedJobs.length > 0 ? (
                appliedJobs.map((job, i) => (
                  <div
                    key={i}
                    className="block text-black bg-[#FFFFFF] hover:bg-gray-100"
                  >
                    <table className="w-full table-fixed border-collapse">
                      <tbody>
                        <tr className="border-b  border-gray-300">
                          <td className="p-4 w-full align-top">
                            <div className="flex justify-between p-4">
                              <div className="flex w-[85%]">
                                <div className="w-1/10">
                                  <InitialsLogo
                                    className="w-16 h-16 rounded-md overflow-hidden"
                                    str={job?.company?.name || job?.position}
                                    width={64}
                                    height={64}
                                    bgRounded="8px"
                                    fontSize={28}
                                  />
                                </div>
                                <div className="ml-4 w-full" id="main">
                                  <div className="flex justify-between">
                                    <h2 className="flex text-xl font-semibold text-gray-700">
                                      {job?.company?.name}
                                      {job?.position && job?.company?.name
                                        ? " - "
                                        : null}
                                      {job?.position}
                                      {/* {job?.saved ? (
                                      <FaBookmark
                                        className="ml-2 mt-1 text-lime-600 hover:cursor-pointer hover:scale-105 transition-all duration-300"
                                        onClick={() => {
                                          bookmarkJob(job?.id);
                                          handleFilterSubmit();
                                        }}
                                      />
                                    ) : (
                                      <FaRegBookmark
                                        className="ml-2 mt-1 text-lime-600 hover:cursor-pointer hover:scale-105 transition-all duration-300"
                                        onClick={() => {
                                          bookmarkJob(job?.id);
                                          handleFilterSubmit();
                                        }}
                                      />
                                    )} */}
                                    </h2>
                                    <div className="flex text-gray-700 font-semibold text-sm">
                                      <FcApproval className="text-md mr-1 mt-[2px]" />{" "}
                                      Actively Hiring
                                    </div>
                                  </div>
                                  {job?.location?.[0] && (
                                    <p className="flex text-gray-600">
                                      <FaLocationDot className="mt-[3px] mr-2 text-gray-600" />
                                      {typeof job.location[0] === "string"
                                        ? job.location[0]
                                        : job.location[0]?.name}
                                    </p>
                                  )}
                                  {job?.experienceLevel && (
                                    <p className="flex text-gray-600">
                                      <FaBusinessTime className="mt-[3px] mr-2 text-gray-600" />
                                      Experience:{" "}
                                      {typeof job.experienceLevel === "string"
                                        ? job.experienceLevel
                                        : job.experienceLevel.min
                                          ? `${job.experienceLevel.min}+ years`
                                          : "Not specified"}
                                    </p>
                                  )}

                                  {job?.description && (
                                    <div className="text-gray-700 my-2">
                                      {(() => {
                                        const sentences =
                                          job.description?.split(
                                            /(?<=\.)\s+/,
                                          ) || [];
                                        const firstThreeSentences = sentences
                                          .slice(0, 3)
                                          .join(" ");
                                        if (firstThreeSentences.length <= 300) {
                                          return parseHtml(firstThreeSentences);
                                        } else {
                                          let truncated = job.description.slice(
                                            0,
                                            300,
                                          );
                                          if (
                                            truncated.charAt(
                                              truncated.length - 1,
                                            ) !== " "
                                          ) {
                                            const lastSpaceIndex =
                                              truncated.lastIndexOf(" ");
                                            truncated = job.description.slice(
                                              0,
                                              lastSpaceIndex,
                                            );
                                          }
                                          return parseHtml(truncated + ".");
                                        }
                                      })()}
                                    </div>
                                  )}
                                  <div className="flex flex-wrap items-center gap-2 mt-3">
                                    {job?.criterias
                                      ?.flatMap((criteria) => {
                                        if (criteria?.keySkills) {
                                          // Case where keySkills is present
                                          return criteria?.keySkills.map(
                                            (skill) => skill?.label,
                                          );
                                        } else if (criteria?.skills) {
                                          // Case where skills is an array of objects with labels or an array of strings
                                          return criteria?.skills.map(
                                            (skill) =>
                                              typeof skill === "string"
                                                ? skill
                                                : skill?.label,
                                          );
                                        }
                                        return [];
                                      })
                                      .slice(0, 10)
                                      .map((skill) => (
                                        <span
                                          key={`${skill}-${Date.now()}-${Math.random()}`}
                                          className="bg-defaultBg text-gray-700 text-sm font-semibold px-2 py-1 rounded"
                                        >
                                          {skill}
                                        </span>
                                      ))}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-[15%] flex flex-col justify-between"
                                id="ending"
                              >
                                <div className="flex flex-col items-end justify-end">
                                  {job?.appliedDate && (
                                    <p className="flex text-gray-600 mb-2 items-center text-right">
                                      <IoMdTime className="text-lg mr-1 text-gray-600" />
                                      {timeDifference(
                                        new Date() - new Date(job?.appliedDate),
                                      )}
                                    </p>
                                  )}
                                </div>
                                {job?.jobType?.length > 0 &&
                                  !(
                                    job.jobType.length === 1 &&
                                    job.jobType[0].trim() === ""
                                  ) && (
                                    <p className="flex items-end justify-end text-gray-600 mb-4 text-right">
                                      <MdOutlineWorkOutline className="text-lg mr-1 text-gray-600" />
                                      Job:{" "}
                                      {job.jobType
                                        .filter((type) =>
                                          typeof type === "string"
                                            ? true
                                            : type.status,
                                        )
                                        .map((type) =>
                                          typeof type === "string"
                                            ? type
                                            : type.type,
                                        )
                                        .join(", ")}
                                    </p>
                                  )}

                                {job?.workplace && job?.workplace != "" && (
                                  <p className="flex items-end justify-end text-gray-600">
                                    <MdLocationCity className="text-gray-600 mr-1 text-lg mb-[2px]" />{" "}
                                    Workplace : {job?.workplace}
                                  </p>
                                )}
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <p>No applied jobs found.</p>
              )}
            </div>
          </main>
        </div>
      )}
    </main>
  );
};
