import Skeleton from "react-loading-skeleton";
import { ScaleLoader } from "react-spinners";
import { useState, useEffect, useCallback } from "react";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobPostList } from "../../../../redux/services";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import { FaSortAmountDown } from "react-icons/fa";
import { BsSortAlphaDown } from "react-icons/bs";
import { BsSortAlphaDownAlt } from "react-icons/bs";
import { RiSortNumberAsc } from "react-icons/ri";
import { BsSortNumericDown } from "react-icons/bs";
import { BsSortNumericDownAlt } from "react-icons/bs";

import { FaStar } from "react-icons/fa";
import { CiStar } from "react-icons/ci";

const core = require("../../../../lib/core");

function JobListSidebar({
  selectJob,
  params,
  setParams,
  isInitialLoad,
  ...props
}) {
  const dispatch = useDispatch();

  const {
    jobList,
    activeCount,
    unprocessedCount,
    draftCount,
    archivedCount,
    closedCount,
    sortField,
    sortOrder,
  } = useSelector((state) => {
    return {
      jobList: state?.JobExpandSlice?.jobList?.content,
      activeCount: state?.JobExpandSlice?.jobList?.active,
      unprocessedCount: state?.JobExpandSlice?.jobList?.unprocessed,
      draftCount: state?.JobExpandSlice?.jobList?.draft,
      archivedCount: state?.JobExpandSlice?.jobList?.archived,
      closedCount: state?.JobExpandSlice?.jobList?.closed,
      sortField: state?.JobExpandSlice?.jobList?.sortField,
      sortOrder: state?.JobExpandSlice?.jobList?.sortOrder,
    };
  });

  const [jobType, setJobType] = useState("active");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [openMoveMenu, setOpenMoveMenu] = useState(false);
  const [showCandidates, setShowCandidates] = useState({});

  // List of jobs
  const [jobs, setJobs] = useState([]);
  const [unprocessedJobs, setUnprocessedJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  // Has more jobs
  const [activeHasMore, setActiveHasMore] = useState(true);
  const [unprocessedHasMore, setUnprocessedHasMore] = useState(true);
  const [draftHasMore, setDraftHasMore] = useState(true);
  const [archivedHasMore, setArchivedHasMore] = useState(true);
  const [closedHasMore, setClosedHasMore] = useState(true);
  // Loading state while pagination
  const [loading, setLoading] = useState("");
  const [reload, setReload] = useState(0);
  // Action - append or replace
  const [replace, setReplace] = useState(props?.replace ?? false);
  // Current sort state
  const [currentSortField, setCurrentSortField] = useState("");
  const [currentSortOrder, setCurrentSortOrder] = useState("");
  const [isSorting, setIsSorting] = useState(false);
  const [currentSort, setCurrentSort] = useState({ field: "", order: "" });

  const barBelowItems = [
    {
      name: "Active",
      list: jobs,
      count: activeCount ?? 0,
      hasMore: activeHasMore,
      loading: loading == "active",
    },
    {
      name: "Closed",
      list: closedJobs,
      count: closedCount ?? 0,
      hasMore: closedHasMore,
      loading: loading == "closed",
    },
    {
      name: "Unprocessed",
      list: unprocessedJobs,
      count: unprocessedCount ?? 0,
      hasMore: unprocessedHasMore,
      loading: loading == "unprocessed",
    },
    {
      name: "Draft",
      list: draftJobs,
      count: draftCount ?? 0,
      hasMore: draftHasMore,
      loading: loading == "draft",
    },
    {
      name: "Archived",
      list: archivedJobs,
      count: archivedCount ?? 0,
      hasMore: archivedHasMore,
      loading: loading == "archived",
    },
  ];

  const jobActions =
    // actions for draft
    jobType == "private" || jobType == "draft"
      ? [
          { name: "Publish job", tag: "active" },
          { name: "Close job", tag: "closed" },
          { name: "Archive job", tag: "archived" },
          { name: "Delete job", tag: "deleted" },
        ]
      : // actions for archived
        jobType == "closed"
        ? [
            { name: "Publish job", tag: "active" },
            { name: "Unpublish job", tag: "draft" },
            { name: "Archive job", tag: "archived" },
            { name: "Delete job", tag: "deleted" },
          ]
        : // actions for unprocessed
          jobType == "archived"
          ? [
              { name: "Publish job", tag: "active" },
              { name: "Unpublish job", tag: "draft" },
              { name: "Close job", tag: "closed" },
              { name: "Delete job", tag: "deleted" },
            ]
          : // actions for unprocessed
            jobType == "unprocessed"
            ? [
                { name: "Activate job", tag: "active" },
                { name: "Unpublish job", tag: "draft" },
                { name: "Close job", tag: "closed" },
                { name: "Delete job", tag: "deleted" },
              ]
            : // actions for active
              [
                { name: "Unpublish job", tag: "draft" },
                { name: "Archive job", tag: "archived" },
                { name: "Close job", tag: "closed" },
                { name: "Delete job", tag: "deleted" },
              ];

  const handleSort = (sortField, sortOrder) => {
    setCurrentSortField(sortField);
    setCurrentSortOrder(sortOrder);

    setReplace(true);
    setIsSorting(true); // Set sorting state to true

    setCurrentSort({ field: sortField, order: sortOrder });

    dispatch(
      fetchJobPostList({
        status: jobType,
        page: 1, // Reset to first page when sorting
        sortField,
        sortOrder,
      }),
    ).then(() => {
      setIsSorting(false); // Reset sorting state when data is received
    });
    setSortDropdownIndex(null);
  };

  const appendJobList = (newJobs) => {
    if (!replace) {
      const updateJobList = (prevJobs) => {
        // Create a Set of existing job IDs for quick lookup
        const existingIds = new Set(prevJobs.map((job) => job.id));

        // Filter out any new jobs that already exist in the list
        const uniqueNewJobs = newJobs.filter((job) => !existingIds.has(job.id));

        const updatedJobs = [...prevJobs, ...uniqueNewJobs];

        // Sort the combined list if a sort option is active
        if (currentSortField) {
          updatedJobs.sort((a, b) => {
            if (currentSortField === "name") {
              return currentSortOrder === "asc"
                ? a.position.localeCompare(b.position)
                : b.position.localeCompare(a.position);
            } else if (currentSortField === "createdAt") {
              return currentSortOrder === "desc"
                ? new Date(a.createdAt) - new Date(b.createdAt)
                : new Date(b.createdAt) - new Date(a.createdAt);
            }
            return 0;
          });
        }
        return updatedJobs;
      };

      if (newJobs?.length > 0) {
        switch (newJobs[0]?.status) {
          case "active":
            setJobs(updateJobList);
            break;
          case "unprocessed":
            setUnprocessedJobs(updateJobList);
            break;
          case "draft":
            setDraftJobs(updateJobList);
            break;
          case "archived":
            setArchivedJobs(updateJobList);
            break;
          case "closed":
            setClosedJobs(updateJobList);
            break;
        }
      }
    }
  };

  const replaceJobList = (newJobs) => {
    if (newJobs.length > 0) {
      // Ensure uniqueness when replacing
      const uniqueJobs = Array.from(
        new Map(newJobs.map((job) => [job.id, job])).values(),
      );

      switch (uniqueJobs[0]?.status) {
        case "active":
          setJobs(uniqueJobs);
          break;
        case "unprocessed":
          setUnprocessedJobs(uniqueJobs);
          break;
        case "draft":
          setDraftJobs(uniqueJobs);
          break;
        case "archived":
          setArchivedJobs(uniqueJobs);
          break;
        case "closed":
          setClosedJobs(uniqueJobs);
          break;
      }
    }
  };

  useEffect(() => {
    if (jobList) {
      if (jobList?.length > 0) {
        if (replace) {
          replaceJobList(jobList);
        } else {
          appendJobList(jobList);
        }
        setReplace(false);
        setIsSorting(false); // Reset sorting state
      } else if (jobList?.length === 0) {
        setHasMore(false);
        setIsSorting(false); // Reset sorting state
      }
    }

    setLoading("");

    if (!params.jobId && jobList != null) {
      setParams({
        jobType: jobList[0]?.status ?? "active",
        jobId: jobList[0]?.id,
        tab: "description",
      });
      selectJob(jobList[0]?.id, jobList[0]?.status);
      $("#job_details_page .list-item")[0]?.classList?.add("active");
    }
  }, [jobList]);

  useEffect(() => {
    setJobType(params?.jobType);
    getActiveJobsbyPage(params?.jobType ?? "active");
  }, [reload]);

  const getActiveJobsbyPage = async (status) => {
    setLoading(status);
    try {
      let jobsLength = getJobsLength(status);
      if (jobsLength % 20 != 0) {
        setLoading("");
        setHasMore(false);
        return;
      }
      dispatch(
        fetchJobPostList({
          status: status,
          page: Math.round(jobsLength / 20) + 1,
          sortField: currentSortField,
          sortOrder: currentSortOrder,
        }),
      );
    } catch (error) {
      console.error(error);
      setLoading("");
    }
  };

  const getJobsLength = (status) => {
    switch (status) {
      case "active":
        return jobs.length;
      case "closed":
        return closedJobs.length;
      case "unprocessed":
        return unprocessedJobs.length;
      case "draft":
        return draftJobs.length;
      case "archived":
        return archivedJobs.length;
      default:
        return 0;
    }
  };

  const setHasMore = (value) => {
    switch (jobType) {
      case "active":
        setActiveHasMore(value);
        break;
      case "closed":
        setClosedHasMore(value);
        break;
      case "unprocessed":
        setUnprocessedHasMore(value);
        break;
      case "draft":
        setDraftHasMore(value);
        break;
      case "archived":
        setArchivedHasMore(value);
        break;
    }
  };

  const jobSelection = (id) => {
    setSelectedJobs((prev) =>
      prev.includes(id) ? prev.filter((jobId) => jobId != id) : [...prev, id],
    );
  };

  const changeStatus = async (status) => {
    setJobs([]);
    setUnprocessedJobs([]);
    setDraftJobs([]);
    setClosedJobs([]);
    setArchivedJobs([]);
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_JOB_STATUS,
        1,
        {
          jobIds: selectedJobs,
          status: status,
        },
      );
      if (json.data) {
        setReplace(true);
        setSelectedJobs([]);
        setOpenMoveMenu(false);
        dispatch(
          fetchJobPostList({
            status: jobType,
            page: 1,
            sortField: currentSortField,
            sortOrder: currentSortOrder,
          }),
        );

        setReload((prev) => prev + 1);
        toast.success(getSuccessMessage(status));
        selectJob(jobList[0]?.id, status);
      }
    } catch (error) {
      setOpenMoveMenu(false);
      setReload((prev) => prev + 1);
      console.error(error);
    }
  };

  const getSuccessMessage = (status) => {
    switch (status) {
      case "deleted":
        return "Job(s) is successfully deleted";
      case "archived":
        return "Job(s) now successfully moved to archive";
      case "closed":
        return "Job(s) now successfully closed";
      case "active":
        return "Job(s) now successfully actively published";
      default:
        return "Job(s) now made private and moved to drafts";
    }
  };

  const fetchJobs = useCallback(() => {
    dispatch(
      fetchJobPostList({
        status: jobType,
        page: 1,
        sortField: currentSortField,
        sortOrder: currentSortOrder,
      }),
    );
  }, [dispatch, jobType, currentSortField, currentSortOrder]);

  // useEffect(() => {
  //   fetchJobs();
  // }, [jobType, currentSortField, currentSortOrder]);

  const toggleFavorite = async (jobId, currentSavedStatus) => {
    try {
      const newSavedStatus = currentSavedStatus === 1 ? 0 : 1;

      // Optimistic update
      const updateJobLists = (updateFn) => {
        setJobs(updateFn);
        setUnprocessedJobs(updateFn);
        setDraftJobs(updateFn);
        setArchivedJobs(updateFn);
        setClosedJobs(updateFn);
      };

      updateJobLists((jobList) =>
        jobList?.map((job) =>
          job.id === jobId ? { ...job, isSaved: newSavedStatus } : job,
        ),
      );

      const response = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_TOGGLE_FAVORITE + jobId,
        1,
        { jobId, isSaved: newSavedStatus },
      );

      if (response.data) {
        toast.success("Favorite status updated successfully");
        fetchJobs(); // Fetch updated data after successful toggle
      } else {
        throw new Error("Failed to update favorite status");
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
      toast.error("Failed to update favorite status");

      // Revert the optimistic update
      updateJobLists((jobList) =>
        jobList.map((job) =>
          job.id === jobId ? { ...job, isSaved: currentSavedStatus } : job,
        ),
      );
    }
  };

  const [sortDropdownIndex, setSortDropdownIndex] = useState(null);

  useEffect(() => {
    const handleClickOutside = () => setSortDropdownIndex(null);
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  console.log(jobs);

  return (
    <div className="mt-4 h-[calc(100vh - 104px)] grow flex flex-col">
      <ul
        id="menu-list"
        className={`relative block w-full left-0 divide-y grow`}
      >
        {barBelowItems?.map((item, i) => (
          <>
            <li
              key={`item-${i}`}
              style={{ bottom: 42 * (4 - i) - 21 }}
              className={`block sticky border-gray-400 bg-gray-100`}
            >
              <button
                className={`py-[4px] px-[8px] min-h-[42px] w-full flex items-center text-left justify-between`}
              >
                <span className="flex items-center gap-2 w-full justify-between">
                  <div className="flex gap-2">
                    <span
                      className={`inline-block text-[14px] not-italic font-normal text-gray-800`}
                    >
                      {item?.name}
                    </span>
                    <span
                      className={`inline-block text-[14px] not-italic font-normal text-gray-800`}
                    >
                      {"(" + item.count + ")"}
                    </span>
                  </div>
                  <div
                    id="sorticon"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (jobType === String(item?.name).toLowerCase()) {
                        setSortDropdownIndex(
                          sortDropdownIndex === i ? null : i,
                        );
                      }
                    }}
                    className="flex ml-auto mr-2 hover:scale-125 transition-all duration-300 hover:bg-gray-300 rounded-md"
                  >
                    <FaSortAmountDown className="text-gray-700 text-base m-2 pb-[2px]" />
                  </div>
                </span>

                {sortDropdownIndex === i && (
                  <div className="absolute top-full mt-1 bg-white shadow-lg border border-gray-200 rounded-md">
                    <ul className="py-1 space-y-1">
                      <li className="flex items-center px-4 py-2 hover:bg-gray-300 cursor-pointer border-b-2">
                        Sort by Name{" "}
                        <div
                          className={`flex items-center justify-center ml-5 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300  ${
                            currentSort.field === "name" &&
                            currentSort.order === "asc"
                              ? "bg-blue-100"
                              : ""
                          }`}
                          onClick={() => handleSort("name", "asc")}
                        >
                          <BsSortAlphaDown className="font-bold text-gray-700 text-lg m-1 " />
                        </div>
                        <div
                          className={`flex items-center justify-center ml-4 mr-2 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                            currentSort.field === "name" &&
                            currentSort.order === "desc"
                              ? "bg-blue-100"
                              : ""
                          }`}
                          onClick={() => handleSort("name", "desc")}
                        >
                          <BsSortAlphaDownAlt className="font-bold text-gray-700 text-lg m-1 " />
                        </div>
                      </li>
                      <li className="flex items-center px-4 py-2 hover:bg-gray-300 cursor-pointer">
                        Sort by Date{" "}
                        <div
                          className={`flex items-center justify-center ml-7 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                            currentSort.field === "createdAt" &&
                            currentSort.order === "asc"
                              ? "bg-blue-100"
                              : ""
                          }`}
                          onClick={() => handleSort("createdAt", "asc")}
                        >
                          <BsSortNumericDown className="font-bold text-gray-700 text-lg m-1 " />
                        </div>
                        <div
                          className={`flex items-center justify-center ml-4 mr-2 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                            currentSort.field === "createdAt" &&
                            currentSort.order === "desc"
                              ? "bg-blue-100"
                              : ""
                          }`}
                          onClick={() => handleSort("createdAt", "desc")}
                        >
                          <BsSortNumericDownAlt className="font-bold text-gray-700 text-lg m-1 " />
                        </div>
                      </li>
                    </ul>
                  </div>
                )}

                <span
                  className={
                    jobType === String(item?.name).toLowerCase()
                      ? "-scale-y-100"
                      : ""
                  }
                  onClick={() => {
                    setSelectedJobs([]);
                    if (jobType === String(item?.name).toLowerCase()) {
                      setJobType(null);
                    } else {
                      setJobType(String(item?.name).toLowerCase());
                      getActiveJobsbyPage(String(item?.name).toLowerCase());
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M6.02346 8.70379L7.9348 10.8039L9.10185 12.0927C9.59606 12.6358 10.3999 12.6358 10.8941 12.0927L13.9784 8.7038C14.3833 8.25891 14.0916 7.5 13.5259 7.5L10.1855 7.5L6.47599 7.5C5.90438 7.5 5.61857 8.25891 6.02346 8.70379Z"
                      fill="#8D98B1"
                    />
                  </svg>
                </span>
              </button>
            </li>
            <div
              className={`transition-all duration-300 ${
                jobType === String(item?.name).toLowerCase()
                  ? selectedJobs?.length > 0
                    ? "h-auto max-h-[calc(100vh-320px)]"
                    : "h-auto max-h-[calc(100vh-260px)]"
                  : "h-0"
              } scrollbar-hide overflow-y-scroll divide-y`}
            >
              {(jobType === String(item?.name).toLowerCase() &&
                item?.list?.length < 10 &&
                loading == String(item?.name).toLowerCase()) ||
              (jobType === String(item?.name).toLowerCase() && isSorting) ? (
                [...Array(isSorting ? 5 : 2)].map((_, index) => (
                  <li
                    key={`skeleton-${index}`}
                    className="grid grid-rows-2 grid-cols-[40px_1fr] py-[6px] mb-2"
                  >
                    <span className="row-span-2 w-8 h-8">
                      <Skeleton width={"32px"} height={"32px"} />
                    </span>
                    <span className="w-full h-3">
                      <Skeleton width={"200px"} height={10} />
                    </span>
                    <span className="w-full h-3">
                      <Skeleton width={"100px"} height={10} />
                    </span>
                  </li>
                ))
              ) : item?.list?.length == 0 ? (
                <h2 className="text-base text-center mt-2">No jobs found</h2>
              ) : (
                <>
                  {item?.list?.map((job, i) => (
                    <li
                      key={`job-${i}`}
                      className={`flex items-start cursor-pointer py-[6px] hover:bg-[#F2F4F8] px-2 border-gray-300 group gap-2 ${
                        params?.jobId === job?.id ? "bg-[#F2F4F8]" : ""
                      }`}
                      onClick={() =>
                        selectJob(job?.id, String(item?.name).toLowerCase())
                      }
                    >
                      <input
                        id={`moveSelect-${job.id}`}
                        name="moveSelect"
                        type="checkbox"
                        value={job?.id}
                        checked={selectedJobs.includes(job?.id)}
                        onChange={(e) => {
                          e.preventDefault();
                          jobSelection(job?.id);
                        }}
                        className={`w-4 h-4 group-hover:!block ${
                          selectedJobs.includes(job?.id) ? "!block" : "hidden"
                        }`}
                      />
                      <div className="flex flex-col w-[200px] grow">
                        <span
                          className={`inline-block text-sm not-italic text-gray-800 truncate ${
                            params?.jobId === job?.id
                              ? "font-semibold"
                              : "font-medium"
                          }`}
                          title={job?.position}
                        >
                          {job?.position}
                        </span>
                        <span className="items-center gap-2 justify-start">
                          <a
                            onClick={() =>
                              selectJob(
                                job?.id,
                                String(item?.name).toLowerCase(),
                                "pipeline",
                              )
                            }
                            className="inline-block text-xs text-[#5F6989] truncate"
                            data-tooltip-id={
                              job?.stages?.reduce(
                                (total, stage) => total + stage.value,
                                0,
                              ) > 0
                                ? "candidateListTooltip"
                                : ""
                            }
                            data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                              <div>
                                {job?.stages?.map(
                                  (stage, ix) =>
                                    stage.value != 0 && (
                                      <span
                                        className="w-full block"
                                        key={`${i}_${ix}`}
                                      >
                                        {stage.name} - <b>{stage.value}</b>
                                      </span>
                                    ),
                                )}
                              </div>,
                            )}
                          >
                            {`Prospects : ${job?.totalProspects ?? 0} | Candidates : ${job?.candidates}`}
                          </a>
                        </span>
                      </div>

                      <Tooltip
                        id="candidateListTooltip"
                        noArrow={false}
                        style={{
                          zIndex: 9999,
                          background: "#5F6989",
                          fontSize: "12px",
                          padding: "4px 8px",
                          fontWeight: "400",
                          color: "white",
                        }}
                        place="bottom"
                      />

                      <button
                        onClick={(e) => {
                          toggleFavorite(job.id, job.isSaved);
                        }}
                        className="ml-auto"
                        aria-label={
                          job.isSaved === 1
                            ? "Remove from favorites"
                            : "Add to favorites"
                        }
                      >
                        {job.isSaved === 1 ? (
                          <FaStar className="text-[#5F6989]" />
                        ) : (
                          <CiStar className="text-slate-900" />
                        )}
                      </button>
                    </li>
                  ))}

                  {item?.hasMore &&
                    item?.list?.length % 10 == 0 &&
                    (item?.loading ? (
                      <div className="flex items-center justify-center">
                        <ScaleLoader color="#1369e9" loading={true} />
                      </div>
                    ) : (
                      <button
                        className="px-[16px] mt-[20px] mb-[20px] flex items-center"
                        onClick={() => {
                          setReplace(false);
                          getActiveJobsbyPage(String(item?.name).toLowerCase());
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M5.41797 7.5L10.0013 12.0704L14.5846 7.5"
                            stroke="#252E4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="text-gray-800 ml-[8px] leading-[28px] text-[14px] not-italic font-normal">
                          See more
                        </span>
                      </button>
                    ))}
                </>
              )}
            </div>
          </>
        ))}
      </ul>
      {selectedJobs?.length > 0 && (
        <>
          <button
            onClick={() => setOpenMoveMenu((prev) => !prev)}
            className="btn-primary btn-sm h-10 relative w-full z-20 right-0"
          >
            Move to
          </button>

          {openMoveMenu && (
            <ul className="absolute right-4 rounded-lg w-[240px] h-auto bg-white shadow-md bottom-[66px] border border-gray-300 z-20 list-none">
              {jobActions?.map((action, idx) => (
                <li
                  key={`action-${idx}`}
                  className={`px-[8px] py-[7px] text-gray-800 text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] ${
                    action?.name === "Delete job"
                      ? "text-[#FF475D] border-t border-t-[#EFF0F2]"
                      : ""
                  }`}
                  onClick={() => changeStatus(action.tag)}
                >
                  {action.name}
                </li>
              ))}
            </ul>
          )}
        </>
      )}
    </div>
  );
}

export default JobListSidebar;
