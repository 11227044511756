import React, { useEffect, useState } from "react";
const core = require("../../../../../lib/core");
import { useParams } from "react-router-dom";
import InitialsLogo from "../../../../all/InitialsLogo";
import FeedbackIndividual from "../FeedbackIndividual";
import ProgressBar from "../../../../analytics/ProgressBar";
import moment from "moment";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

function ExpandableText({ children, maxChar = 150, classes }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {}, [expand]);
  return (
    children && (
      <p className={`${classes} w-11/12 break-words `}>
        {children?.length <= maxChar ? (
          children
        ) : expand ? (
          <>
            <span>{children}</span>
            <button
              className="ml-2 p-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(false)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M5 14H10V19M19 10H14V5"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <span>{String(children).substring(0, maxChar)} ...</span>
            <button
              className="ml-2 px-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(true)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M10 19H5V14M14 5H19V10"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        )}
      </p>
    )
  );
}

const TruncatedDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 200; // Adjust this value to get roughly 3-4 lines

  if (description.length <= maxLength) {
    return <p className="mt-2 text-gray-700 text-justify">{description}</p>;
  }

  return (
    <div className="mt-2">
      <p className="text-gray-700">
        {isExpanded ? description : `${description.slice(0, maxLength)}...`}
      </p>
      <button
        className="text-blue-600 hover:text-blue-800 text-justify text-sm mt-1 focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? "Read less" : "Read more"}
      </button>
    </div>
  );
};
const InvitedFeedback = () => {
  const { jobId, appId } = useParams();
  const [candidate, setCandidateData] = useState({});
  const [activeTabName, setAvtiveTabName] = useState("Feedback");
  const btnList = [{ ButtonName: "Resume" }, { ButtonName: "Feedback" }];

  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;
  useEffect(() => {
    const GetDetails = async () => {
      try {
        const json = await core.API(
          core.API_METHODS.GET,
          core.RECRUITER_API.GET_JOB_MATCHPROFILE_DETAILS + appId,
          1,
        );

        if (json) {
          setCandidateData(json.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (jobId && appId) {
      GetDetails();
    }
  }, [jobId, appId]);

  console.log("Candidate", candidate);
  const criterias = candidate?.matchProfile?.criteriaLevel;
  const pipelineStages = candidate?.matchProfile?.pipeline;
  return (
    <div className="p-2 bg-white">
      <div className="w-full h-screen overflow-y-auto bg-gray-300 flex flex-row items-start rounded-md">
        <div className="flex flex-col items-start p-4 rounded-lg shadow-md bg-white mx-2 my-2 w-[40%] h-screen overflow-y-auto">
          <div className="flex items-center">
            <InitialsLogo
              str={candidate?.matchProfile?.first_name}
              bgRounded={"50%"}
            />
            <div className="mx-2 p-2 flex flex-col items-start">
              <div className="flex items-center gap-2">
                <h2 className="text-xl font-semibold">
                  {candidate?.matchProfile?.first_name}{" "}
                  {candidate?.matchProfile?.last_name}{" "}
                </h2>
                <div className="flex items-center gap-2 ">
                  <span className="inline cursor-pointer  py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[12px] not-italic font-normal text-blue-700">
                    {candidate?.matchProfile?.current_source
                      ? candidate?.matchProfile?.current_source
                      : null}
                  </span>
                  <span className="inline cursor-pointer py-[2px] px-[6px] bg-gray-300 rounded-[4px]  text-[12px] not-italic font-normal text-gray-700">
                    {candidate?.matchProfile?.current_stage
                      ? candidate?.matchProfile?.current_stage
                      : null}
                  </span>
                  <div className="flex ">
                    {candidate?.matchProfile?.linkedin?.length > 0 && (
                      <>
                        {candidate?.matchProfile?.linkedin?.toLowerCase() !=
                          "not specified" && (
                          <a
                            className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                            href={candidate?.matchProfile?.linkedin}
                            target="_blank"
                            rel="noreferrer"
                            data-tooltip-id="my-tooltip-4"
                            data-tooltip-content={"Linkedin"}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                                fill="#5F6989"
                              />
                            </svg>
                          </a>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <p className="text-gray-600">
                {candidate?.matchProfile?.title || "No title"}
              </p>
            </div>
          </div>

          <div className="flex flex-col justify-between items-start  bg-white rounded-md mx-2 my-1">
            {candidate?.matchProfile?.summary.length > 0 && (
              <div>
                <h2 className="text-base my-2 font-medium text-[#16182f]">
                  Summary
                </h2>
                <p className="text-sm text-justify">
                  {candidate?.matchProfile?.summary}
                </p>
              </div>
            )}
            <div className="">
              {candidate?.matchProfile?.education.length > 0 ? (
                <>
                  <div className="flex justify-start items-stretch flex-col">
                    <h2 className="text-base my-2 font-medium text-[#16182f]">
                      Education
                    </h2>

                    {candidate?.matchProfile?.education?.map((education, i) => (
                      <div
                        className="grid grid-cols-[50px_1fr]  gap-x-2 gap-y-1 mt-3"
                        key={"education" + i}
                      >
                        <InitialsLogo
                          width="50px"
                          height="50px"
                          fontSize="24px"
                          styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                          str={education?.school}
                        />
                        <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                          {education?.degree}
                        </p>
                        <p className="col-start-2 text-sm text-[#1369e9]">
                          {education?.school}
                        </p>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}

              {candidate?.matchProfile?.experience?.length > 0 ? (
                <div className="flex justify-start items-stretch flex-col mb-6 gap-5">
                  <h2 className="text-base my-2 font-medium text-[#16182f]">
                    Work Experience
                  </h2>
                  {/* Loop starts */}
                  {candidate?.matchProfile?.experience?.map((exp, index) => (
                    // <div
                    //     className="flex flex-row gap-2 items-start"
                    //     key={"experience" + i}
                    // >
                    //     <InitialsLogo
                    //         width="50px"
                    //         height="50px"
                    //         fontSize="24px"
                    //         styleClass="rounded-xl border border-[#e1e1ee] "
                    //         str={experience?.company}
                    //     />
                    //     <div className='flex flex-col gap-2 w-11/12'>
                    //         <p className="text-base font-semibold text-[#252e4b]">
                    //             {experience?.position}
                    //         </p>
                    //         <p className="text-sm text-[#1369e9]">
                    //             {experience?.company}
                    //         </p>
                    //         <div className="w-fullflex flex-row gap-1">
                    //             <p className="text-sm text-[#252e4b]">
                    //                 {moment(experience?.start).format("MMM YYYY")} -{" "}
                    //                 {experience?.ongoing
                    //                     ? "Present"
                    //                     : moment(experience?.end).format("MMM YYYY")}
                    //             </p>

                    //         </div>
                    //         <ExpandableText
                    //             maxChar={150}
                    //             classes="text-sm leading-[22px] text-[#5f6989] text-wrap"
                    //         >
                    //             {experience?.description}
                    //         </ExpandableText>
                    //     </div>
                    // </div>
                    <div key={index} className="mb-8 flex">
                      <div className="flex flex-col items-center mr-6">
                        <div className="w-4 h-4 bg-gray-600 rounded-full shadow-sm"></div>
                        {index !==
                          (candidate?.matchProfile?.experience).length - 1 && (
                          <div className="w-0.5 h-full bg-gray-300 transform translate-x-1/2"></div>
                        )}
                      </div>
                      <div className="bg-gray-50 p-5 rounded-lg shadow-md flex-grow transition-all duration-300 hover:shadow-lg">
                        <div className="flex justify-between items-start mb-3">
                          <div>
                            <h4 className="font-semibold text-base ">
                              {exp?.position}
                            </h4>
                            <p className="text-gray-700 font-medium">
                              {exp?.company}
                            </p>
                          </div>
                          <p className="text-sm text-gray-600 bg-gray-200 px-3 py-1 rounded-full">
                            {moment(exp?.start).format("MMM YYYY")} -
                            {exp?.ongoing
                              ? "Present"
                              : moment(exp.end).format("MMM YYYY")}
                          </p>
                        </div>
                        {exp?.description && (
                          <TruncatedDescription
                            description={exp?.description}
                          />
                        )}
                        {exp?.skills && exp?.skills.length > 0 && (
                          <div className="mt-4">
                            <p className="font-semibold text-gray-700 mb-2">
                              Skills:
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {exp.skills.map((skill, skillIndex) => (
                                <span
                                  key={skillIndex}
                                  className="bg-gray-300 text-gray-800 text-sm px-3 py-1 rounded-lg"
                                >
                                  {skill}
                                </span>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>

            <div className="h-full overflow-y-auto ">
              {candidate?.matchProfile?.skills?.length > 0 && (
                <div
                  className="w-full w-full mb-4 relative bg-gray-300 p-4 rounded-md"
                  id="candidateAnalytics"
                >
                  <h4 className="text-[#16182F] my-2 text-[16px] not-italic font-medium">
                    Skills
                  </h4>
                  <div className="w-full">
                    {candidate?.matchProfile?.skills?.length > 0 &&
                      candidate?.matchProfile?.skills?.map((data, it) => (
                        <div
                          className="w-full mb-4 bg-white p-4 rounded-md"
                          key={it}
                        >
                          <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                            {checkLabel(data?.criteria, data?.label)}
                          </h4>
                          <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                            {checkLabel(data?.skills, data?.keySkills)?.map(
                              (skill, i) => (
                                <div
                                  className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                                  key={i}
                                >
                                  <span
                                    className="w-[45%] text-[#252E4B] text-[14px] not-italic leading-7 truncate overflow-hidden  mr-[10px]"
                                    onMouseOver={(e) => {
                                      var overflowed =
                                        e.target.scrollWidth >
                                        e.target.clientWidth;
                                      e.target.title = overflowed
                                        ? e.target.textContent
                                        : "";
                                    }}
                                    onMouseOut={(e) => (e.target.title = "")}
                                  >
                                    {skill?.skills}
                                  </span>
                                  <span className="w-[45%]  mr-[10px]">
                                    <ProgressBar
                                      id={"skills-bar" + it + i}
                                      style_classes="w-full"
                                      data={Math.abs(skill?.score)}
                                      height={8}
                                      barColor={"teal"}
                                      bgColor="#c3c5c7"
                                      width={100 * 0.45 - 10}
                                      fromRight={skill?.score < 0}
                                    />
                                  </span>
                                  <span style={{ width: 20 }}>
                                    {Math.floor(skill?.score)}
                                  </span>
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="flex-1 flex flex-col rounded-lg shadow-md bg-white p-4 my-2 ">
          <div className="flex gap-8">
            {btnList?.map((btn) => (
              <button
                onClick={() => {
                  setAvtiveTabName(btn.ButtonName);
                }}
                className={`text-md font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 ${
                  btn.ButtonName == activeTabName
                    ? "border-[#1369e9] text-[#16182f]"
                    : "border-transparent text-[#252e4b]"
                } border-solid hover:border-blue-600`}
              >
                {btn?.ButtonName}
              </button>
            ))}
          </div>
          <div className="p-4">
            {activeTabName === "Resume" && (
              <div
                key={candidate?.applicant?._id}
                className="w-full  relative "
                style={{ height: "calc(100vh - 130px)" }}
              >
                <DocViewer
                  className="scrollbar-candidate"
                  style={{
                    borderColor: "lightgray",
                    overflowY: "scroll",
                    borderRadius: 10,
                    borderWidth: 1,
                    // minHeight: 500,
                  }}
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                      retainURLParams: true,
                    },
                    noRenderer: {
                      overrideComponent: () => (
                        <div className="w-full h-full flex justify-center items-center">
                          Failed to load
                        </div>
                      ),
                    },
                    pdfZoom: {
                      defaultZoom: 1, // 1 as default,
                    },
                  }}
                  documents={[
                    {
                      uri: candidate?.matchProfile?.resume_link,
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                />
              </div>
            )}
            {activeTabName == "Feedback" && (
              <FeedbackIndividual
                jobId={jobId}
                appId={appId}
                criterias={criterias}
                pipelineStages={pipelineStages}
                invited={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvitedFeedback;
