import { convertToHTML } from "draft-convert";

export const getCharacterCountWithoutHTML = (editorState) => {
  // Convert EditorState to HTML
  const html = convertToHTML(editorState?.getCurrentContent());

  // Create a new DOM parser
  const parser = new DOMParser();

  // Parse the HTML
  const doc = parser?.parseFromString(html, "text/html");

  // Get the text content and return its length
  const textContent = doc?.body?.textContent;
  const trimmedTextContent = textContent?.trim().replace(/\s\s+/g, " ");

  return trimmedTextContent?.length;
};
