import React, { useState } from "react";
import InitialsLogo from "../../../all/InitialsLogo";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tooltip } from "antd";
import { FaMapMarkerAlt } from "react-icons/fa";
import ApplicantAccordionV3 from "./ApplicantAccordionV3";

function PreProspectApplicantList({
  checkedItems = [],
  idx,
  iter,
  addtoProspect,
}) {
  const [likeIcon, setLikeIcon] = useState(-1);
  const [iconId, setIconId] = useState(-1);
  const [dataIdx, setDataIdx] = useState(-1);

  function formatLink(link) {
    // Check if the link starts with "https://" or "http://"
    if (!link.startsWith("https://") && !link.startsWith("http://")) {
      // If it doesn't, add "https://"
      link = "https://" + link;
    }

    return link;
  }

  return (
    <>
      <tr
        className={`candidate-detail-row-1 pt-4 pb-4  relative hover:bg-[#f4f8ff] ${checkedItems?.includes(iter?._id) && "bg-[#f4f8ff]"} `}
        onMouseEnter={() => {
          setLikeIcon(idx);
        }}
        onMouseLeave={() => setLikeIcon(-1)}
      >
        <ReactTooltip
          id="my-tooltip-4"
          noArrow={true}
          style={{
            zIndex: 9999,
            // background: "white",
            background: "#5F6989",
            maxWidth: "500px",
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: "400",
            // color: "black",
          }}
          place="top"
        />

        {/* <td
          className=" px-4 pb-4 pt-8  flex w-full  "
          style={{
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        > */}
        <td className=" px-4 pb-4 pt-8  flex overflow-x-hidden">
          <div
            className="w-[40px] h-[40px] flex justify-center items-center rounded-[6px] hover:bg-[#FFFFFF]"
            onMouseEnter={() => setIconId(idx)}
            onMouseLeave={() => setIconId(null)}
          >
            <span className="">
              <InitialsLogo str={iter?.first_name} bgRounded={"50%"} />
            </span>
            {/* )} */}
          </div>

          <div className="col-span-8 ml-[14px] relative">
            <div className="flex  flex-row items-start gap-x-2  flex-wrap">
              <h4 className=" text-base not-italic cursor-pointer font-medium text-gray-800 ">
                {iter?.first_name}{" "}
                {iter?.last_name?.length > 0 ? iter?.last_name : ""}
              </h4>
              <div className="flex flex-row items-start gap-x-2 ">
                {iter?.applicant?.resume_link?.length > 0 && (
                  <>
                    {(iter?.applicant?.resume_link?.toLowerCase() !=
                      "not specified" ||
                      formatLink(iter?.applicant?.resume_link) !=
                        "https://sproutsai-staging.s3.amazonaws.com/undefined") && (
                      <button className="candidate-email-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7 px-2 py-1 mr-2 relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M6.9987 6.99935C8.60953 6.99935 9.91537 5.69351 9.91537 4.08268C9.91537 2.47185 8.60953 1.16602 6.9987 1.16602C5.38787 1.16602 4.08203 2.47185 4.08203 4.08268C4.08203 5.69351 5.38787 6.99935 6.9987 6.99935Z"
                            fill="#5F6989"
                          />
                          <path
                            d="M7.00172 8.45898C4.07922 8.45898 1.69922 10.419 1.69922 12.834C1.69922 12.9973 1.82755 13.1257 1.99089 13.1257H12.0126C12.1759 13.1257 12.3042 12.9973 12.3042 12.834C12.3042 10.419 9.92422 8.45898 7.00172 8.45898Z"
                            fill="#5F6989"
                          />
                        </svg>
                      </button>
                    )}
                  </>
                )}

                {iter?.linkedin?.length > 0 && (
                  <>
                    {iter?.linkedin?.toLowerCase() != "not specified" && (
                      <a
                        className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                        href={formatLink(iter?.linkedin)}
                        target="_blank"
                        rel="noreferrer"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"Linkedin"}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                            fill="#5F6989"
                          />
                        </svg>
                      </a>
                    )}
                  </>
                )}
              </div>

              {iter?.current_source && (
                <span className="cursor-pointer ml-[8px] py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[10px] not-italic font-normal text-blue-700">
                  {iter?.current_source === "Linkedin"
                    ? "Sprouts"
                    : iter?.current_source}
                </span>
              )}
              {iter?.remote_source && (
                <span className="ml-[8px] py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[10px] not-italic font-normal text-blue-700">
                  {iter?.remote_source ?? ""}
                </span>
              )}
            </div>

            {/* <span className="text-sm not-italic font-normal text-gray-700  max-w-full text-wrap">
              {iter?.title || iter?.bio || ""} agsdgs s s fg dfgd fgdfg test test test test test 
            </span> */}

            <div className="flex flex-wrap">
              {/* <span className="text-sm text-gray-700 flex-1"> */}
              <span className="text-sm not-italic font-normal  text-gray-700 ">
                {iter?.title || iter?.bio || ""}
              </span>
            </div>

            {/* <div className="max-w-full  text-gray-600 text-sm mt-1 flex items-center gap-1 whitespace-normal">
              <FaMapMarkerAlt className="w-4 h-4" />
              <span
                data-tooltip-id={`my-tooltip-4`}
                className="truncate"
                data-tooltip-html={`${iter?.location ?? ""}`}
              >
                {`${iter?.location ?? ""}`}
              </span>
            </div> */}
            {/* <div className="max-w-full text-gray-600 text-sm mt-1 flex items-center gap-1">
  <FaMapMarkerAlt className="flex-shrink-0 w-4 h-4" />
  <span
    data-tooltip-id={`my-tooltip-4`}
    className="overflow-hidden text-ellipsis inline-block max-w-[calc(100%-24px)]"
    style={{
      display: '-webkit-box',
      WebkitLineClamp: '1',
      WebkitBoxOrient: 'vertical'
    }}
    data-tooltip-html={`${iter?.location ?? ""}`}
  >
    {`${iter?.location ?? ""}`}
  </span>
</div> */}

            <div className="max-w-full text-gray-600 text-sm mt-1 grid grid-cols-[16px_1fr] items-center gap-1">
              <FaMapMarkerAlt className="w-4 h-4" />
              <span
                data-tooltip-id={`my-tooltip-4`}
                className="overflow-hidden text-ellipsis whitespace-nowrap"
                data-tooltip-html={`${iter?.location ?? ""}`}
              >
                {`${iter?.location ?? ""}`}
              </span>
            </div>

            {/* {dummy[0]?.skills?.length > 0 ? (
              <div className="flex items-center gap-3 mt-1">
                {dummy[0]?.skills?.slice(0, 3)?.map((skill, index) => (
                  <span
                    className="border-gray-300 border px-2 py-0.5 rounded-md max-w-[150px] truncate text-[12px]"
                    data-tooltip-id={`my-tooltip-4`}
                    data-tooltip-content={skill}
                  >
                    {skill}
                  </span>
                ))}
                {dummy[0]?.skills?.length > 2 && (
                  <Tooltip
                    color="white"
                    overlayInnerStyle={{
                      width: "400px",
                      padding: "0px",
                    }}
                    title={
                      <div className="flex flex-col gap-2 max-h-[400px] overflow-y-auto p-3">
                        <div className="flex flex-wrap items-center gap-1">
                          {dummy[0]?.skills?.map((skill, index) => (
                            <span
                              key={index}
                              className="bg-gray-300 text-black px-2 py-1 rounded-md max-w-[150px] truncate text-[12px]"
                              data-tooltip-id={`my-tooltip-4`}
                              data-tooltip-content={skill}
                            >
                              {skill}
                            </span>
                          ))}
                        </div>
                      </div>
                    }
                  >
                    <span className="bg-gray-300 px-2 py-0.5 rounded-md w-fit text-[12px]">
                      +{dummy[0]?.skills?.length - 2}
                    </span>
                  </Tooltip>
                )}
              </div>
            ) : null} */}
          </div>
        </td>

        <td
          className="px-4 pb-4 "
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-start">
              <CandidateExperience
                exp={iter?.experience[0]}
                position={
                  iter?.experience[0]?.positions?.length > 0 ? true : false
                }
              />
            </div>
          </div>
        </td>

        {/* third colummn */}
        <td
          className="px-4 pb-4 "
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <div className="flex flex-col gap-1 mt-5">
            <div className="flex flex-row justify-start">
              <CandidateEducation edu={iter?.education[0]} />
            </div>
            <div className="flex flex-col gap-1 mt-2">
              {iter?.summary ? (
                <div className="flex items-center gap-3 whitespace-normal">
                  <span
                    data-tooltip-id={`my-tooltip-4`}
                    className="text-[14px] truncate border border-gray-500 px-1 py-0.5 rounded-md"
                    data-tooltip-html={`${iter?.summary?.replace(/<\/?[^>]+(>|$)/g, "") ?? ""}`}
                  >
                    {`${iter?.summary?.replace(/<\/?[^>]+(>|$)/g, "") ?? ""}`}
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </td>

        {/* last column */}
        <td className=" px-4 pb-4 ">
          <div className="flex flex-col gap-1 justify-end items-end">
            <button
              onClick={() => {
                if (idx != dataIdx) {
                  setDataIdx(idx);
                } else setDataIdx(-1);
              }}
              style={{ border: "none", background: "none" }}
            >
              {idx == dataIdx ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                    stroke="#252E4B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5 9L12 14.4844L17.5 9"
                    stroke="#252E4B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
            <div className=" flex items-start justify-end ">
              <>
                <div className="flex items-center gap-3">
                  <span
                    className="btn-sec btn-md p-2 group flex items-center text-blue-800 cursor-pointer"
                    onClick={() => {
                      if (!(iter?.thumbs_up == 1)) {
                        addtoProspect(1, iter?._id);
                      }
                    }}
                  >
                    {iter?.thumbs_up === 1 ? (
                      <IoMdThumbsUp
                        color="#5F6989"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"like"}
                        className="candidate-like-icon w-6 h-6"
                      />
                    ) : (
                      <LuThumbsUp
                        color="#5F6989"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"like"}
                        className="candidate-like-icon w-5 h-5"
                      />
                    )}
                  </span>
                  <span
                    className="btn-sec btn-md p-2 group flex items-center text-blue-800 cursor-pointer"
                    onClick={() => {
                      if (!(iter?.thumbs_up == 2)) {
                        addtoProspect(2, iter?._id);
                      } else {
                        addtoProspect(0, iter?._id);
                      }
                    }}
                  >
                    {iter?.thumbs_up === 2 ? (
                      <IoMdThumbsDown
                        color="#5F6989"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"Dislike"}
                        className="candidate-unlinke-icon w-6 h-6"
                      />
                    ) : (
                      <LuThumbsDown
                        color="#5F6989"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"Dislike"}
                        className="candidate-unlinke-icon w-5 h-5"
                      />
                    )}
                  </span>
                </div>
              </>
            </div>
          </div>
        </td>
      </tr>

      <tr className="max-w-full candidate-detail-row-2 border-b-gray-700">
        <td colSpan="4" className="relative w-full">
          {idx == dataIdx && <ApplicantAccordionV3 allData={iter} idx={idx} />}
        </td>
      </tr>
    </>
  );
}

export default PreProspectApplicantList;

const CandidateExperience = ({ exp, position }) => {
  const isPositionValid =
    exp?.positions?.[0]?.title &&
    exp?.positions?.[0]?.start_date &&
    exp?.positions?.[0]?.end_date;
  const isExpValid =
    exp?.title && exp?.company && exp?.start_date && exp?.end_date;

  if (position) {
    if (!isPositionValid || !exp?.company) {
      return <p className="text-gray-800">Experience not found</p>;
    }

    return (
      <div className="flex  flex-col justify-start items-start gap-y-2  whitespace-normal">
        <h4 className=" text-base not-italic cursor-pointer font-medium text-gray-800 ">
          {exp?.positions[0]?.title}
        </h4>
        <span className="text-sm not-italic font-normal  text-gray-700 ">
          {exp?.company}
        </span>
        {exp?.positions[0]?.start_date && exp?.positions[0]?.end_date ? (
          <>
            {exp.positions[0].start_date} - {exp.positions[0].end_date}
          </>
        ) : null}
        )
      </div>
    );
  }

  if (!isExpValid) {
    return <p className="text-gray-800">Experience not found</p>;
  }

  return (
    <div className="flex  flex-col justify-start items-start gap-y-2  whitespace-normal">
      <h4 className=" text-base not-italic cursor-pointer font-medium text-gray-800 ">
        {exp?.title}
      </h4>
      <span className="text-sm not-italic font-normal  text-gray-700">
        {exp?.company}
      </span>
      <p className="text-xs text-gray-700">
        {exp?.start_date} - {exp?.end_date}
      </p>
    </div>
  );
};

const CandidateEducation = ({ edu }) => {
  // Check if 'edu' exists and has all required fields
  if (!edu || !edu.degree || !edu.title) {
    return <p className="text-gray-800">Education Not found</p>;
  }
  return (
    <div className="flex  flex-col justify-start items-start gap-y-2  whitespace-normal">
      <h4 className=" text-base not-italic cursor-pointer font-medium text-gray-800 ">
        {edu?.degree || "Not Specified"}
      </h4>
      <span className="text-sm not-italic font-normal  text-gray-700">
        {edu?.title || "Not Specified"}
      </span>
      <p className="text-sm text-[#252e4b]">
        {edu?.start_year &&
          edu?.end_year &&
          `${edu.start_year} - ${edu.end_year}`}
      </p>
    </div>
  );
};
