import React, { useState, useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDashboardData } from "../../redux/services";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import DashboardPipeline from "../../components/recruiter/dashBoard/DashboardPipeline";
import DashboardInterviewSection from "../../components/recruiter/dashBoard/DashboardInterviewSection";
import DashboardTask from "../../components/recruiter/dashBoard/DashboardTask";
import InstantMeeting from "../../components/recruiter/dashBoard/InstantMeet";
import "../../style/DashboardIframe.scss";
import DashboardGoal from "../../components/recruiter/dashBoard/DashboardGoal";
const core = require("../../lib/core");

function DashboardRecruiter() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1); // Initialize page to 1
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = useSelector(
    (state) => state?.dashboardSlice?.dashboardData,
  );

  const dashData = useMemo(() => fetchData, [fetchData]);

  useEffect(() => {
    if (dashData?.length === 0) {
      setHasMore(false);
    } else {
      setData(dashData?.pipeline ?? dashData);
      setHasMore(true);
    }
  }, [dashData]);

  useEffect(() => {
    if (page === 1) {
      getDashboard();
    }
  }, []); // Fetch data when page changes

  const dispatch = useDispatch();

  const getDashboard = async () => {
    setPage(page + 1);
    try {
      await dispatch(fetchDashboardData(page)); // Use the current page value
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  const getRecruiterDashboard = async () => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_DASHBOARD + "?page=" + page,
        1,
      );

      if (list?.data?.content?.length > 0) {
        setData((prevData) => [...prevData, ...list?.data?.content]);
        setLoading(true);
        setPage((prevPage) => prevPage + 1);
      } else {
        setHasMore(false);
        setLoading(true);
      }
    } catch (error) {}
  };

  const dashboardContentRef = useRef(null);

  const handleScroll = () => {
    if (!dashboardContentRef.current) return;
    const { scrollTop, clientHeight, scrollHeight } =
      dashboardContentRef.current;
    if (scrollTop + clientHeight >= scrollHeight - 10 && loading && hasMore) {
      setLoading(false);
      getRecruiterDashboard();
    }
  };

  const sortByAlphabate = () => {
    let sortData = data.sort((a, b) => a.position.localeCompare(b.position));
    setData(sortData);
  };

  const sortByDate = () => {
    let sortData = data.sort((a, b) => {
      if (a.name === b.name) {
        return new Date(a.date) - new Date(b.date);
      }
      return 0;
    });
    setData(sortData);
  };

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar active="Dashboard" />
        <div className="w-full">
          <HeaderNew />
          <DashboardContent
            data={data}
            handleScroll={handleScroll}
            dashboardContentRef={dashboardContentRef}
            loading={loading}
            sortByAlphabate={sortByAlphabate}
            sortByDate={sortByDate}
          />
          <InstantMeeting />
        </div>
      </div>
    </div>
  );
}

export default DashboardRecruiter;

const DashboardContent = ({
  data,
  jobId,
  params,
  setParams,
  handleScroll,
  reload,
  dashboardContentRef,
  setReload,
  sortByAlphabate,
  sortByDate,
  loading,
  ...props
}) => {
  const [org, setOrg] = useState();

  useEffect(() => {
    let org = core.getData(core.data.ORGANIZATION);
    setOrg(org);
  }, []);

  console.log(org);
  return (
    <main
      className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate"
      onScroll={handleScroll}
      ref={dashboardContentRef}
    >
      <div className="flex">
        {/* <span className="font-semibold text-[25px] not-italic text-[#1369E9] mx-2"> {org?.name ? org?.name+"'s" : <></>}</span> */}
        <span className="font-semibold text-[25px] not-italic text-[#16182F]">
          Dashboard
        </span>
      </div>

      <div className="grid grid-cols-4 gap-5 mt-[18px] ">
        <div className="col-span-3 ...">
          {/* <div
            className="justify-between grid grid-cols-4 gap-[20px]
mb-[20px]"
          >
            <div
              className="col-span-1 flex flex-col rounded-[12px]
px-[16px] py-[12px] bg-[#FFF] shadow-PipelineCardsShadow h-[120px]"
            >
              <span
                className="flex text-[#5F6989] text-[14px]
font-normal leading-[22px] not-italic"
              >
                Active Jobs
              </span>
              <span
                className=" mt-[15px] text-[#252E4B] text-[24px]
font-semibold leading-[32px] not-italic flex items-center"
              >
                9
                <span
                  className="ml-[5px] bg-[#E6F8F5] flex
items-center rounded-[14px] py-[3px] px-[6px] text-[#00B998]
text-[12px] "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                  >
                    <path
                      d="M2.47812 6.27344L5.19479 3.55677C5.51563
3.23594 6.04062 3.23594 6.36146 3.55677L9.07812 6.27344"
                      fill="#00B998"
                    />
                  </svg>
                  57%
                </span>
              </span>
              <span
                className="mt-[6px] text-[#5F6989] text-[12px]
font-normal leading-[22px] not-italic"
              >
                vs last month : 2233
              </span>
            </div>
            <div
              className="col-span-1 flex flex-col rounded-[12px]
px-[16px] py-[12px] bg-[#FFF] shadow-PipelineCardsShadow h-[120px]"
            >
              <span
                className="flex text-[#5F6989] text-[14px]
font-normal leading-[22px] not-italic"
              >
                New Applicants
              </span>{" "}
              <span
                className=" mt-[15px]
text-[#252E4B] text-[24px] font-semibold leading-[32px] not-italic
flex items-center"
              >
                199
                <span
                  className="ml-[5px] bg-[#E6F8F5] flex
items-center rounded-[14px] py-[3px] px-[6px] text-[#00B998]
text-[12px] "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                  >
                    <path
                      d="M2.47812 6.27344L5.19479 3.55677C5.51563
3.23594 6.04062 3.23594 6.36146 3.55677L9.07812 6.27344"
                      fill="#00B998"
                    />
                  </svg>
                  57%
                </span>
              </span>{" "}
              <span
                className="mt-[6px] text-[#5F6989]
text-[12px] font-normal leading-[22px] not-italic"
              >
                vs last month : 2233
              </span>
            </div>
            <div
              className="col-span-1 flex flex-col rounded-[12px]
px-[16px] py-[12px] bg-[#FFF] shadow-PipelineCardsShadow h-[120px]"
            >
              <span
                className="flex text-[#5F6989] text-[14px]
font-normal leading-[22px] not-italic"
              >
                Pending Tasks
              </span>{" "}
              <span
                className="
mt-[15px] text-[#252E4B] text-[24px] font-semibold leading-[32px]
not-italic flex items-center"
              >
                17
                <span
                  className="ml-[5px] bg-[#E6F8F5] flex
items-center rounded-[14px] py-[3px] px-[6px] text-[#00B998]
text-[12px] "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                  >
                    <path
                      d="M2.47812 6.27344L5.19479 3.55677C5.51563
3.23594 6.04062 3.23594 6.36146 3.55677L9.07812 6.27344"
                      fill="#00B998"
                    />
                  </svg>
                  57%
                </span>
              </span>{" "}
              <span
                className="mt-[6px] text-[#5F6989]
text-[12px] font-normal leading-[22px] not-italic"
              >
                vs last month : 2233
              </span>
            </div>
            <div
              className="col-span-1 flex flex-col rounded-[12px]
px-[16px] py-[12px] bg-[#FFF] shadow-PipelineCardsShadow h-[120px]"
            >
              <span
                className="flex text-[#5F6989] text-[14px]
font-normal leading-[22px] not-italic"
              >
                Upcoming Events
              </span>{" "}
              <span
                className="
mt-[15px] text-[#252E4B] text-[24px] font-semibold leading-[32px]
not-italic flex items-center"
              >
                5
                <span
                  className="ml-[5px] bg-[#E6F8F5] flex
items-center rounded-[14px] py-[3px] px-[6px] text-[#00B998]
text-[12px] "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="11"
                    height="10"
                    viewBox="0 0 11 10"
                    fill="none"
                  >
                    <path
                      d="M2.47812 6.27344L5.19479 3.55677C5.51563
3.23594 6.04062 3.23594 6.36146 3.55677L9.07812 6.27344"
                      fill="#00B998"
                    />
                  </svg>
                  57%
                </span>
              </span>{" "}
              <span
                className="mt-[6px] text-[#5F6989]
text-[12px] font-normal leading-[22px] not-italic"
              >
                vs last month : 2233
              </span>
            </div>
          </div> */}

          <DashboardPipeline
            data={data}
            loading={loading}
            sortByDate={sortByDate}
            sortByAlphabate={sortByAlphabate}
          />
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-y-5">
            <DashboardInterviewSection />
            <DashboardTask data={data} />
            {/* <DashboardGoal data={data} /> */}
          </div>
        </div>
      </div>
    </main>
  );
};
