import React, { useContext, useEffect, useState } from "react";
import DeleteAccount from "./DeleteAccount";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifyEmailModal from "./VerifyEmailModal";
import Modal from "../../../components/modal/Modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { IoIosWarning } from "react-icons/io";
import InputBox from "../../../layouts/InputBox";

const core = require("../../../lib/core");

const updateObj = {
  email: "",
  phone1: "",
  phone2: "",
  gender: "",
  language: "",
  name: "",
  company: "",
};

export default function General() {
  const nav = useNavigate();
  const [account, setAccount] = useState("hidden");
  const [general, setGeneral] = useState("");
  const [formJobdetails, setFormJobdetails] = useState(updateObj);
  const [valuePhone, setValuePhone] = useState();
  const [valueAlt, setValueAlt] = useState();
  const [showModal, setShowModal] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [emailErrorModal, setEmailErrorModal] = useState(false);

  const getDeletePage = () => {
    setAccount("");
    setGeneral("hidden");
  };
  const general_page = () => {
    setAccount("hidden");
    setGeneral("");
  };

  function setJobdetailsInput(key, value) {
    setFormJobdetails({ ...formJobdetails, [key]: value });
  }

  useEffect(() => {
    getProfile();
    setValuePhone(formJobdetails?.phone1);
    setValueAlt(formJobdetails?.phone2);
  }, [formJobdetails.phone1, formJobdetails.phone2]);

  const { user, setUser } = useContext(core.UserContext);

  useEffect(() => {
    if (user) {
      formJobdetails.email = user?.email;
      formJobdetails.phone1 = user?.phone1;
      formJobdetails.phone2 = user?.phone2;
      formJobdetails.gender = user?.gender;
      formJobdetails.name = user?.name;
    }
  }, [user]);

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) setUser(json?.data?.user);
      else {
        console.error("There is no user with these credentials");
        core.logout();
      }
    } catch (err) {
      console.error(err);
    }
  }

  async function getProfile() {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_PROFILE_DETAILS,
      1,
      {},
    );
    if (json?.data) {
      formJobdetails.company = json?.data?.company;
      formJobdetails.name = json?.data?.name;
    }
  }

  async function handleSubmit(event) {
    if (event) event.preventDefault();
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_USER,
        1,
        {
          email: formJobdetails.email,
          phone1: valuePhone,
          phone2: valueAlt,
          gender: formJobdetails.gender,
          language: formJobdetails.language,
          name: formJobdetails.name,
        },
      );
      if (json?.data) {
        handleCreate();
        toast.success("Profile updated successfully", {
          position: "bottom-right",
          autoClose: 2000,
        });
        getUser();
      }
      if (json?.code === 400) {
        toast.error(json?.message || "Unable to update profile", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data?.message || "Unable to update profile", {
        position: "bottom-right",
        autoClose: 2000,
      });
    } finally {
      setEmailErrorModal(false);
    }
  }
  async function handleCreate() {
    const json = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.POST_CREATE_PROFILE,
      1,
      {
        // .API(core.API_METHODS.PUT, core.RECRUITER_API.PUT_UPDATE_PROFILE, 1, {
        name: formJobdetails.name,
        company: formJobdetails?.company,
      },
    );
    if (json?.data) {
    } else {
      handleProfileUpdate();
    }
  }
  async function handleProfileUpdate() {
    const json = await core
      // .API(core.API_METHODS.POST, core.RECRUITER_API.POST_CREATE_PROFILE, 1, {
      .API(core.API_METHODS.PUT, core.RECRUITER_API.PUT_UPDATE_PROFILE, 1, {
        name: formJobdetails?.name,
        company: formJobdetails?.company,
      });
    if (json?.data) {
    } else {
    }
  }

  const isInitialMount = React.useRef(true);
  useEffect(() => {
    if (
      String(window?.location?.search)?.length > 0 &&
      isInitialMount.current
    ) {
      let outlook_token = sessionStorage.getItem("outlook_token");

      let newToken = window?.location?.search?.split("?")[1]?.split("=")[1];
      // console.log(outlook_token, newToken);
      if (outlook_token != newToken) {
        isInitialMount.current = false;
        sendCallback();
      } else {
        nav("/settings");
      }
    }
  }, []);

  const sendCallback = async () => {
    // console.log(window.location.search);
    sessionStorage.setItem(
      "outlook_token",
      window?.location?.search?.split("?")[1]?.split("=")[1],
    );
    const type = localStorage.getItem("email_auth_type");
    isInitialMount.current = false;
    let json;
    if (type == "outlook") {
      try {
        json = await core.API(
          core.API_METHODS.POST,
          core.OUTLOOK_API.POST_OUTLOOK_CALLBACK +
            window.location.search +
            "&redirect_uri=" +
            window.location.href,
          1,
        );
        if (json.status == 400) {
          toast.error(json?.message || "Something went wrong", {
            position: "bottom-right",
            autoClose: 2000,
          });
        }
        toast.success("Authentication successful", {
          position: "bottom-right",
          autoClose: 2000,
        });
      } catch (err) {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
    } else {
      json = await core.API(
        core.API_METHODS.POST,
        core.GOOGLE_API.POST_EMAIL_CALLBACK +
          window.location.search +
          "&redirect_uri=" +
          window.location.href,
        1,
      );
      if (json?.status == 400) {
        toast.error(json?.message || "Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
      toast.success("Authentication successful", {
        position: "bottom-right",
        autoClose: 2000,
      });
    }
    if (String(json?.message)?.toLowerCase() == "success") {
      getUser();
      nav("/settings/integration");
    }
  };

  return (
    <>
      {emailErrorModal ? (
        <Modal
          onSave={handleSubmit}
          heading="Update Email"
          onCancel={() => setEmailErrorModal(false)}
          modalDismiss={() => setEmailErrorModal(false)}
          defaultButtons={{
            primaryLabel: "Continue",
          }}
        >
          <div>
            {/* Show message showing change in email will lead to remove the access of all the integrations */}
            <div className="text-sm text-textColor1 font-normal leading-[22px] py-4">
              <div className="flex justify-center w-full">
                <IoIosWarning className="text-red-800 w-12 h-12" />
              </div>
              <p className="text-red-500 text-center text-sm mt-2">
                Change in Email Address will lead to remove your access from all
                the integrations.
              </p>
            </div>
          </div>
        </Modal>
      ) : null}
      <div className="w-full " id="general-setting" hidden={general}>
        <div className="col-xs-12  sub-sections py-[22px] rounded-[12px]">
          <div className=" w-full flex flex-col">
            <span className="text-lg ">Account information</span>
            <span className="text-sm leading-[22px]">
              Update your basic information and contact details.
            </span>
          </div>
          <div className="general-info w-full">
            <div className="w-full flex">
              <div className="w-1/3 input-left-sec">
                <label className="w-full label-input" htmlFor="gender">
                  Name
                </label>
                <InputBox
                  className="w-full"
                  size="medium"
                  type="text"
                  name="name"
                  value={formJobdetails.name}
                  onChange={(event) => {
                    setJobdetailsInput("name", event.target.value);
                  }}
                />
              </div>
              <div className="w-1/3 input-left-sec  input-right-sec">
                <label className="w-full label-input" htmlFor="email">
                  Company
                </label>
                <InputBox
                  className="w-full"
                  type="text"
                  name="comapny"
                  value={formJobdetails.company}
                  onChange={(event) => {
                    setJobdetailsInput("company", event.target.value);
                  }}
                />
              </div>
              <div className="w-1/3  input-right-sec ">
                {/* <label className='w-full label-input' htmlFor="gender">Gender</label>
                                <select className='w-full' type="text"
                                    name='gender'
                                    value={formJobdetails.gender}
                                    onChange={(event) => {
                                        setJobdetailsInput("gender", event.target.value);
                                    }}
                                >
                                    <option disabled value="">Select gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Prefer not to say</option>
                                </select> */}
              </div>
            </div>
            <div className="w-full flex">
              <div className="w-1/3 input-left-sec">
                <label className="w-full label-input" htmlFor="email">
                  Email
                </label>
                <InputBox
                  className="w-full"
                  type="email"
                  name="email"
                  value={formJobdetails.email}
                  onChange={(event) => {
                    setJobdetailsInput("email", event.target.value);
                  }}
                />
              </div>
              <div className="w-1/3 input-left-sec input-right-sec">
                <label className="w-full label-input" htmlFor="phone">
                  Phone
                </label>

                <PhoneInput
                  country={"us"}
                  value={valuePhone}
                  onChange={setValuePhone}
                />
              </div>
              <div className="w-1/3 input-right-sec">
                <label className="w-full label-input" htmlFor="altnum">
                  Alternate phone
                </label>
                <PhoneInput
                  country={"us"}
                  value={valueAlt}
                  onChange={setValueAlt}
                />
              </div>
            </div>
            <button
              className="border border-[#DADAE4] py-[8px] px-[24px] mt-[18px] bg-[#1369E9] rounded-[8px] hover:bg-[#1369e9e4]"
              onClick={(e) => {
                if (user?.email != formJobdetails.email) {
                  setEmailErrorModal(true);
                  return;
                } else {
                  handleSubmit(e);
                }
              }}
            >
              <span className="text-base text-white font-normal not-italic">
                Save
              </span>
            </button>
          </div>
          {/* {!user?.refresh_token?.length > 0 && (
            <div className="w-full">
              <button
                onClick={() => setShowModal(true)}
                className="btn-md btn-primary mt-4"
              >
                Verify email
              </button>
            </div>
          )} */}
          {/* <div className="w-full">
            <div className="w-full">
              <div className="w-full input-left-sec">
                <label className="w-full label-input" htmlFor="language">
                  Language:
                </label>
                <span className="text-gray-900">English (EN)</span>
              </div>
            </div>
          </div> */}
        </div>

        <div className="col-xs-12 sub-sections py-[22px] rounded-[12px] account-setting">
          <div className=" w-full flex justify-between items-center">
            <div className="flex flex-col">
              <span className="text-base text-gray-900 font-medium not-italic">
                Account settings
              </span>
              <span className="text-sm text-textColor1 font-normal not-italic leading-[22px]">
                Manage Your Profile and Preferences
              </span>
            </div>
            <div>
              <span
                className="text-base text-[#FF475D] font-normal not-italic cursor-pointer flex items-center"
                onClick={getDeletePage}
              >
                Delete account
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                >
                  <path
                    d="M10 16.56L14.5172 12.03L10 7.5"
                    stroke="#FF475D"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </div>
          </div>
          {/* <div className='delete-account w-full'>
                        <span>Delete account</span>
                        <button onClick={getDeletePage}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M7.99074 4.50228L15.2987 11.9993L7.99074 19.4963C7.8599 19.6302 7.78666 19.81 7.78666 19.9973C7.78666 20.1845 7.8599 20.3643 7.99074 20.4983C8.05428 20.5631 8.13013 20.6147 8.21384 20.6498C8.29755 20.685 8.38744 20.7031 8.47824 20.7031C8.56903 20.7031 8.65892 20.685 8.74263 20.6498C8.82634 20.6147 8.90219 20.5631 8.96574 20.4983L16.7417 12.5228C16.8783 12.3827 16.9547 12.1949 16.9547 11.9993C16.9547 11.8037 16.8783 11.6158 16.7417 11.4758L8.96724 3.50027C8.90364 3.43496 8.82761 3.38305 8.74362 3.3476C8.65963 3.31215 8.5694 3.29389 8.47823 3.29389C8.38707 3.29389 8.29684 3.31215 8.21285 3.3476C8.12886 3.38305 8.05283 3.43496 7.98923 3.50027C7.8584 3.63422 7.78516 3.81404 7.78516 4.00128C7.78516 4.18852 7.8584 4.36833 7.98923 4.50228L7.99074 4.50228Z" fill="#001B4B" stroke="#001B4B" />
                        </svg></button>
                    </div> */}
        </div>
      </div>
      <div className="w-full" hidden={account}>
        <DeleteAccount props={general_page} user={user} />
      </div>
      {showModal && (
        <Modal
          heading="Verify email"
          noBtns={true}
          modalDismiss={() => setShowModal(false)}
        >
          <VerifyEmailModal />
        </Modal>
      )}
    </>
  );
}
