import { useMemo } from "react";
import { useContext } from "react";
import { UserContext } from "../lib/core"; // Adjust the import path as needed

export const usePermission = () => {
  const { user } = useContext(UserContext);

  const resourceSet = useMemo(
    () => new Set(user?.resources),
    [user?.resources],
  );

  const checkPermission = (code) => {
    return resourceSet.has(code);
  };

  return checkPermission;
};
