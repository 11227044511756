import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
const core = require("../../lib/core");

function CreateNewUser({ modalDismiss, allRoles }) {
  const [inputEmail, setInputEmail] = useState("");
  const [password, setInputPassword] = useState("");
  const [done, setDone] = useState({
    text: "",
    success: "",
  });

  const onCancel = () => {
    modalDismiss();
  };

  const createUser = async (email, password) => {
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.CREATE_NEW_USER_BYPASS,
      1,
      {
        email: email,
        password: password,
      },
    );
    if (json?.data) {
      setDone({
        text: "User created successfully",
        success: true,
      });
    } else {
      setDone({
        text: json?.message,
        success: false,
      });
      console.log(json);
    }
  };

  const modalElements = {
    heading: "Create new user",
    subheading: "",
    modalDismiss: () => onCancel(),
    onSave: () => {
      createUser(inputEmail, password);
    },

    defaultButtons: {
      primaryDisabled: !(String(inputEmail)?.length > 0),
    },
  };
  return (
    <Modal {...modalElements}>
      <div className="w-full">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          email
        </h3>
        <input
          type="email"
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          required
          value={inputEmail}
          placeholder="Press enter to insert email"
          onChange={(e) => {
            setInputEmail(e.target.value);
          }}
        />

        <h3
          className="w-full text-black text-sm font-normal mt-4"
          htmlFor="assessment-dropdown"
        >
          password
        </h3>
        <input
          type="text"
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          required
          value={password}
          placeholder="Press enter password"
          onChange={(e) => {
            setInputPassword(e.target.value);
          }}
        />

        {done?.success == true ? (
          <p className="mt-4 text-green-900">{done?.text}</p>
        ) : null}
        {done?.success === false ? (
          <p className="mt-4 text-red-900">{done?.text}</p>
        ) : null}
      </div>
    </Modal>
  );
}

export default CreateNewUser;
