import React from "react";
import Modal from "../../modal/Modal";
import { useState } from "react";
import { useEffect } from "react";
import FormAutocomplete from "../createJob/Autocomplete";
const core = require("../../../lib/core");

function ShareJobModal({ jobId, modalDismiss }) {
  const [allUser, setAllUser] = useState([]);
  const [sharedID, setSharedID] = useState([]);
  const [SearchusersData, setSearchusersData] = useState([]);
  const [value, setvalue] = useState();
  const [message, setMessage] = useState({});

  const GET_ALL_RECRUITER = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_RECRUITER,
      1,
    );
    if (json.data) setAllUser(json?.data);
    else console.log("error");
  };

  const GET_SHARED_JOB_IDS = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_SHARE_JOBS_ID + "/" + jobId,
      1,
    );
    if (json?.data?.data) setSharedID(json?.data?.data);
    else console.log("error");
  };

  const UPDATE_SHARED_JOB_IDS = async () => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.RECRUITER_API.GET_SHARE_JOBS_ID,
      1,
      {
        jobId: jobId,
        shareIds: sharedID?.map((item) => item?.id),
        hostname: window.location.hostname,
      },
    );
    if (json?.data) {
      setMessage({ type: "success", message: "Job shared successfully" });
      setTimeout(() => {
        setMessage({});
        modalDismiss();
      }, 2000);
    } else console.log("error");
  };

  useEffect(() => {
    GET_SHARED_JOB_IDS();
  }, [jobId]);

  useEffect(() => {
    GET_ALL_RECRUITER();
  }, []);

  const modalElements = {
    heading: "Share job ",
    subheading: "Add emails to share the job with ",
    modalDismiss: () => modalDismiss(),
    onSave: () => UPDATE_SHARED_JOB_IDS(),
    defaultButtons: {
      primaryLabel: "Share",
      primaryDisabled: !(sharedID?.length > 0),
    },
  };

  function searchListJobs(keyword) {
    // Empty array to store results
    let results = [];

    // Iterate through the array
    allUser.forEach((item) => {
      // Check if the keyword is present in the item (case insensitive)
      if (item?.email?.toLowerCase()?.includes(keyword.toLowerCase())) {
        results.push(item);
      }
    });
    setSearchusersData(results);
  }

  return (
    <Modal {...modalElements}>
      {sharedID?.length > 0 && (
        <div className="w-full tags flex flex-wrap gap-2">
          {sharedID?.map((item) => (
            <span
              className="flex items-center bg-[#00AC85] px-2 py-1 rounded-lg"
              key={item?.id}
            >
              <span className="text-white text-sm font-normal not-italic">
                {item?.email}
              </span>
              <button
                className="remove"
                onClick={(e) => {
                  e.preventDefault();
                  setSharedID(sharedID?.filter((d) => d?.id != item?.id));
                }}
              >
                <svg
                  className="ml-1 cursor-pointer"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M4.66797 4.66669L11.3346 11.3334"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M4.66927 11.3334L11.3359 4.66669"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </span>
          ))}
        </div>
      )}

      <div className="w-full flex flex-col gap-2">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Email
        </h3>
        <FormAutocomplete
          type="text"
          listClass="relative top-0"
          inputClassName={
            "w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          }
          idName="position"
          name="position"
          value={value}
          data={SearchusersData}
          required={true}
          searchFunction={searchListJobs}
          selectionFunction={(name, value) => {
            setvalue(value);
          }}
          onSelectOption={(e) => {
            setSharedID((prevSharedID) => [...prevSharedID, e]);
            setvalue("");
          }}
        />
      </div>
      {Object?.entries(message)?.length > 0 && (
        <p
          style={
            message?.type == "error" ? { color: "red" } : { color: "green" }
          }
          className="block text-sm font-medium mt-2"
        >
          {message?.message}
        </p>
      )}
    </Modal>
  );
}

export default ShareJobModal;
