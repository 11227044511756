import TestGif from "../../assets/candidate/TestGif.gif";
import Start from "../../assets/candidate/Start.png";
export default function InterviewStart({
  startMeeting,
  sequence,
  quLen,
  qsnCount,
  setShowCamera,
}) {
  return (
    <section id="startPage" className="col-xs-12 no-padding landing-page">
      <div className="col-xs-6 gif-section no-padding">
        <img src={TestGif} alt="" />
        {/* <img  src="https://sproutsai-staging.s3.amazonaws.com/assets/test_gif.gif" alt="" /> */}
      </div>

      <div className="col-xs-6 no-padding startpage-data-section">
        <span>
          <img
            src={
              Start
              // "https://sproutsai-staging.s3.amazonaws.com/assets/assignment_info.png"
            }
            alt=""
          />
        </span>
        <h1 className="mt-[15px]">Your assessment is about to start</h1>
        <div className="col-xs-12 no-padding details mt-[15px]">
          <div className="section-data col-xs-12 no-padding">
            <h4>Sections : </h4>

            <div className="data-table col-xs-12 no-padding mt-[5px]">
              <div className="data-row col-xs-12 no-padding">
                <div className="col-xs-4 no-padding data-col border-mid">
                  <span className="header">MCQ</span>
                  <span className="text">{qsnCount?.mcqCount} Questions</span>
                </div>
                <div className="col-xs-4 no-padding data-col border-mid">
                  <span className="header"> Coding</span>
                  <span className="text">
                    {qsnCount?.codingCount} Questions
                  </span>
                </div>
                <div className="col-xs-4 no-padding data-col">
                  <span className="header">Interview</span>
                  <span className="text">
                    {qsnCount?.interviewCount} Questions
                  </span>
                </div>
              </div>
              <div className="col-xs-12 no-padding duration-raw">
                <span className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M18.3337 10.0003C18.3337 14.6003 14.6003 18.3337 10.0003 18.3337C5.40033 18.3337 1.66699 14.6003 1.66699 10.0003C1.66699 5.40033 5.40033 1.66699 10.0003 1.66699C14.6003 1.66699 18.3337 5.40033 18.3337 10.0003Z"
                      stroke="#5F6989"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.0924 12.6495L10.509 11.1078C10.059 10.8411 9.69238 10.1995 9.69238 9.67448V6.25781"
                      stroke="#5F6989"
                      strokeWidth="1.2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                <span className="duration-text">Duration</span>
                <span className="duration-time">{qsnCount?.duration} min</span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 no-padding instructions">
          <h4>Instructions :</h4>
          <ol className="section-data col-xs-12 no-padding">
            <li className="text">
              The assessment is timed. A timer is shown per test or per
              question.
            </li>
            <li className="text">
              Please ensure that you enable full-screen mode only during the
              assessment.
            </li>
            <li className="text">
              Avoid minimizing or resizing the screen as it may invalidate the
              interview process.
            </li>
            <li className="text">
              It is mandatory to share your screen throughout the interview
              process. We will capture the screen to assess your performance and
              interactions.
            </li>
            <li className="text">
              If you encounter any technical issues or need assistance, please
              communicate with the designated support contact immediately.
            </li>
          </ol>
        </div>
        {/* <button onClick={e=>{document.documentElement.requestFullscreen()}} >ab</button> */}
        <button
          className="start-interview-btn"
          data-dismiss="modal"
          // onClick={() => {
          //   document.documentElement.requestFullscreen();
          //   setTimeout(function () {
          //     startMeeting();
          //   }, 1000);
          // }}
          onClick={() => {
            setShowCamera(true);
          }}
        >
          Start Meeting
        </button>
      </div>
    </section>
  );
}
