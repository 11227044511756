const core = require("./../lib/core");

export const EditorUploadCallback = (file) => {
    return new Promise((resolve, reject) => {
        const formData = new FormData();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
            // make axios request
            formData.append("files", file)
            const token = await core.getData(core.data.ACCESS_TOKEN);
            await fetch(core.S3_API.POST_UPLOAD_FILE, {
                method: "POST",
                headers: {
                    Authorization: "Bearer " + token
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((data) => {
                    const responseData = {
                        link: data?.data?.[0]?.url,
                        name: data?.data?.[0]?.name
                    }
                    console.log(data, responseData);
                    resolve({
                        data: responseData
                    });
                })
                .catch((err) => {
                    reject(err);
                });

        };
        reader.onerror = (error) => reject(error);
    });
};
