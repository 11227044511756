import React from "react";
import Header from "../../../components/all/header/Header";
import Menu from "../../../components/all/header/Menu";
import SideBar from "../../../components/all/SideBar";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import ConfirmModal from "../../../components/candidate/assessment/ConfirmModal";
import InitialsLogo from "../../../components/all/InitialsLogo";
import { useRef } from "react";
import FileUploader from "../../../components/all/FileUploader";
import BeatLoader from "react-spinners/BeatLoader";
import HeaderNew from "../../../components/all/header/HeaderNew";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
import CustomButton from "../../../layouts/Button";

const core = require("../../../lib/core");

function AssessmentDashboard() {
  const nav = useNavigate();
  const [dashboardData, setDashboardData] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    getIntDashboard();
  }, []);

  async function createNewInterview(intId, testId) {
    setCookie(intId, 1);
    nav("/assessment?code=xuGuOVfJMaXoPCYtG1sQByIyCsVUu7Ysg" + intId);
  }
  const [loading, setLoading] = useState(true);

  async function getIntDashboard() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_ASSESSMENT_DASHBOARD,
        1,
      );
      if (json?.data) {
        setDashboardData(json.data.assessments);
        setLoading(false);
      } else {
        setError(json.error.message);
        setLoading(false);
      }
    } catch (err) {}
  }

  function setCookie(value, days) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = "assessmentId=" + value + expires;
  }

  const [showUploader, setShowUploader] = useState(false);
  const [resumeName, setResumeName] = useState();
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [resumeFile, setResumeFile] = useState("Upload Resume");
  const [showfoldermenu, setShowfoldermenu] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [applicantData, setApplicantData] = useState();
  const [resumeData, setResumeData] = useState();
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [inputdata, setInputdata] = useState();

  function setInput(key, value) {
    setInputdata({ ...inputdata, [key]: value });
  }

  const fileInputRef = useRef(null);

  function onFileChange(file) {
    try {
      setResumeFile(Array.from(file));
      setResumeFileName(Array.from(file));
      setShowUploader(true);

      const selectedFile = file;
      fileInputRef.current.value = ""; // Clear the input value
      const event = new CustomEvent("change", { detail: selectedFile });
      fileInputRef.current.dispatchEvent(event);
    } catch (err) {
      // setResumeBtnName("Upload Resume");
    }
  }

  // Your Python code string with indentation
  // const codeString = `def find_common_elements(list1, list2):\n    common_elements = []\n    for element in list1:\n        if element in list2:\n            \n            common_elements.append(element)\n    common_elements = list(set(common_elements))\n    return common_elements\nundefined`;
  const start = 100;
  const end = 300;
  const codeString = `#include <stdio.h>\n#include <stdbool.h>\n\nbool is_prime(int n) {\n    if (n <= 1) {\n        return false;\n    }\n    for (int i = 2; i * i <= n; i++) {\n        if (n % i == 0) {\n            return false;\n        }\n    }\n    return true;\n} \n\nvoid print_primes(int start, int end) {\n    printf(\"Prime numbers between %d and %d:\\n\", start, end);\n    for (int i = start; i <= end; i++) {\n        if (is_prime(i)) {\n            printf(\"%d \", i);\n        }\n    }\n    printf(\"\\n\");\n}\n\nint main() {\n    int start = ${start};\n    int end = ${end};\n    print_primes(start, end);\n    return 0;\n}\n  `;
  // Create a Blob from the code string
  const blob = new Blob([codeString], { type: "text/plain" });

  // Create FormData and append the Blob as a file
  const formData = new FormData();
  formData.append("file", blob, "code.cpp"); // Use '.py' extension for Python file

  const callcode = () => {
    // Perform the API request with fetch
    fetch("http://compiler.sproutsai.com/execute_script", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle API response
        // console.log(data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  };

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        {/* <CandidateSidebar active="Assessment" open={false} /> */}
        <div className="w-full">
          {/* <HeaderNew /> */}
          <AssessmentContent
            loading={loading}
            fileInputRef={fileInputRef}
            onFileChange={onFileChange}
            showUploader={showUploader}
            setApplicantData={setApplicantData}
            setInput={setInput}
            setResumeData={setResumeData}
            applicantData={applicantData}
            resumeFile={resumeFile}
            loadingAdd={loadingAdd}
            resumeFileName={resumeFileName}
            setShowUploader={setShowUploader}
            getIntDashboard={getIntDashboard}
            dashboardData={dashboardData}
            createNewInterview={createNewInterview}
            callcode={callcode}
          />
          {/* <InstantMeeting /> */}
        </div>
      </div>
    </div>
  );
}

export default AssessmentDashboard;

const AssessmentContent = ({
  loading,
  fileInputRef,
  onFileChange,
  showUploader,
  setApplicantData,
  setInput,
  setResumeData,
  applicantData,
  resumeFile,
  loadingAdd,
  resumeFileName,
  setShowUploader,
  getIntDashboard,
  dashboardData,
  createNewInterview,
  callcode,

  ...props
}) => {
  const nav = useNavigate();
  const dateFormatter = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
  });
  const timeFormatter = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
      <div className="flex justify-between items-center">
        <span className="font-semibold text-[28px] not-italic text-[#16182F]">
          Assessment
        </span>
        <div
          className="flex items-center gap-2 cursor-pointer"
          onClick={() => {
            core.logout();
            nav("/login");
          }}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.90039 7.56023C9.21039 3.96023 11.0604 2.49023 15.1104 2.49023H15.2404C19.7104 2.49023 21.5004 4.28023 21.5004 8.75023V15.2702C21.5004 19.7402 19.7104 21.5302 15.2404 21.5302H15.1104C11.0904 21.5302 9.24039 20.0802 8.91039 16.5402"
              stroke="#252E4B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 12H14.88"
              stroke="#252E4B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.6504 8.65039L16.0004 12.0004L12.6504 15.3504"
              stroke="#252E4B"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Logout
        </div>
      </div>
      <div className="flex mt-[20px]">
        {loading ? (
          <div className="w-[100%] h-[100%] flex justify-center items-center">
            <BeatLoader
              color="blue"
              loading={loading}
              //cssOverride={override}
              size={20}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <>
            {dashboardData.length > 0 ? (
              <>
                <div className="">
                  <input
                    type="file"
                    id="logo"
                    name="logo"
                    className="hide"
                    ref={fileInputRef}
                    hidden
                    onChange={(e) => {
                      onFileChange(e.target.files);
                    }}
                  />

                  {showUploader && (
                    <FileUploader
                      setApplicantData={setApplicantData}
                      setInput={setInput}
                      setResumeData={setResumeData}
                      applicantData={applicantData}
                      resumeFile={resumeFile}
                      loadingAdd={loadingAdd}
                      resumeFileName={resumeFileName}
                      setShowUploader={setShowUploader}
                      singleUpload={true}
                      getIntDashboard={getIntDashboard}
                    />
                  )}

                  {dashboardData[0]?.hasResume && (
                    <label htmlFor="logo">Upload resume</label>
                  )}
                </div>

                {!dashboardData[0]?.hasResume && (
                  <div className="flex flex-col w-full gap-8">
                    {dashboardData?.map((iter, index) => (
                      <div
                        className="bg-[#FFF] flex justify-between items-center rounded-[12px] p-[8px]"
                        key={index}
                      >
                        <div className="flex items-center ">
                          <InitialsLogo
                            className="img-responsive"
                            str={iter?.name}
                            width={160}
                            height={100}
                            bgRounded="15px"
                            fontSize={50}
                          />
                          <div className="flex flex-col ml-[12px]">
                            <span className="text-[#252e4b] text-[18px] font-semibold leading-[22px] not-italic flex items-center">
                              {iter?.name}{" "}
                              <span className="text-[#FFFFFF] text-[12px] font-normal  not-italic bg-[#efb353] py-[4px] px-[8px] rounded-lg ml-[8px]">
                                {"Expire at : " +
                                  dateFormatter.format(
                                    new Date(iter?.expiredAt),
                                  ) +
                                  " - " +
                                  timeFormatter.format(
                                    new Date(iter?.expiredAt),
                                  )}
                              </span>
                            </span>
                            <span className="text-[#5f6989] text-[14px] font-normal leading-[22px] not-italic mt-[6px]">
                              {iter?.topics}
                            </span>

                            <div className="flex text-[#5f6989] text-[14px] font-normal leading-[22px] not-italic mt-[6px]">
                              <span className="flex items-center">
                                <svg
                                  className="mr-[8px]"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M3.00167 13.5V13.4982H3V13.5L3.00167 13.5Z"
                                    fill="#252E4B"
                                  />
                                  <path
                                    d="M3.00167 9.0009V8.9991H3V9.00086L3.00167 9.0009Z"
                                    fill="#252E4B"
                                  />
                                  <path
                                    d="M3.00167 4.5018V4.5H3V4.50176L3.00167 4.5018Z"
                                    fill="#252E4B"
                                  />
                                  <path
                                    d="M5.67857 13.4982H15M5.67857 8.9991H15M5.67857 4.5H15M3.00167 13.4982V13.5L3 13.5V13.4982H3.00167ZM3.00167 8.9991V9.0009L3 9.00086V8.9991H3.00167ZM3.00167 4.5V4.5018L3 4.50176V4.5H3.00167Z"
                                    stroke="#252E4B"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>{" "}
                                {/* {iter?.totalQuestions} Questions */}
                                {iter?.codingCount +
                                  iter?.mcqCount +
                                  iter?.interviewCount}{" "}
                                Questions
                              </span>
                              <span className="flex items-center ml-[23px]">
                                <svg
                                  className="mr-[8px]"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M14.6663 8.00004C14.6663 11.68 11.6797 14.6667 7.99967 14.6667C4.31967 14.6667 1.33301 11.68 1.33301 8.00004C1.33301 4.32004 4.31967 1.33337 7.99967 1.33337C11.6797 1.33337 14.6663 4.32004 14.6663 8.00004Z"
                                    stroke="#252E4B"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M10.4739 10.1198L8.40724 8.88647C8.04724 8.67314 7.75391 8.1598 7.75391 7.7398V5.00647"
                                    stroke="#252E4B"
                                    strokeWidth="1.2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                                {`${iter?.duration} minutes`}
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="">
                          {new Date(iter?.expiredAt) > new Date() && (
                            <>
                              {iter.status === "pending" ? (
                                <CustomButton
                                  buttonType="primary"
                                  text={
                                    <span
                                      className="flex items-center cursor-pointer"
                                      onClick={(e) => {
                                        createNewInterview(iter.id, index);
                                        document.cookie =
                                          "timer=" + iter?.duration;
                                      }}
                                    >
                                      Start assessment
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                      >
                                        <path
                                          d="M12.0244 4.94141L17.0827 9.99974L12.0244 15.0581"
                                          stroke="#FFFFFF"
                                          strokeWidth="1.2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                        <path
                                          d="M2.91699 10L16.942 10"
                                          stroke="#FFFFFF"
                                          strokeWidth="1.2"
                                          strokeMiterlimit="10"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  }
                                  onClick={(e) => {
                                    createNewInterview(iter.id, index);
                                    document.cookie = "timer=" + iter?.duration;
                                  }}

                                  //  , onClick, buttonType, marginLeft, marginRight, marginTop, marginBottom, width, height, type
                                />
                              ) : (
                                <span className="flex items-center text-[green] mr-[20px] font-bold text-[20px]">
                                  Completed
                                </span>
                              )}
                            </>
                          )}

                          {/* <button
                                className="flex items-center justify-center bg-[#F3F5F9] rounded-[8px] text-[#252E4B] text-[14px] font-normal leading-[22px] not-italic mt-[6px] px-[12px] py-[8px]"
                                onClick={(e) => {
                                  createNewInterview(iter.job.id, index);
                                  document.cookie = "timer=" + iter?.duration;
                                }}
                              >
                                Start assessment
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M12.0244 4.94141L17.0827 9.99974L12.0244 15.0581"
                                    stroke="#252E4B"
                                    strokeWidth="1.2"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                  <path
                                    d="M2.91699 10L16.942 10"
                                    stroke="#252E4B"
                                    strokeWidth="1.2"
                                    strokeMiterlimit="10"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </button> */}
                        </div>
                        {false && (
                          <ConfirmModal
                            createNewInterview={createNewInterview}
                            id={index}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            ) : (
              <main className="empty-content no-padding col-xs-12">
                <h1>There are no assessments available for you right now.</h1>
              </main>
            )}
          </>
        )}
      </div>
    </main>
  );
};
