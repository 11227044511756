import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");
// Async thunk action creators

export const validateAtsTokenService = createAsyncThunk(
  "atsIntegration/validateAtsToken",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.VALIDATE_ATS_TOKEN,
      1,
      payload,
    );
    // Async logic for validate ats token
  },
);

export const getWebhooksAtsTokenService = createAsyncThunk(
  "getWebhooksAtsIntegration/GetWebhooksAtsToken",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GETWEBHOOKS_ATS_TOKEN,
      1,
      payload,
    );
    // Async logic for GetWebhooks ats token
  },
);

export const reparseJobService = createAsyncThunk(
  "atsIntegration/reparseJobService",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.POST,
      core.JOB_API.REPARSE_GREENHOUSE_JOB,
      1,
      payload,
    );
    // Async logic for GetWebhooks ats token
  },
);
