import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from "react";
import Mcq from "../../../components/candidate/assessment/Mcq";
import AutonomusInterview from "../../../components/candidate/assessment/AutonomusInterview";
import CodingSection from "../../../components/candidate/assessment/CodingIDE";
import $ from "jquery";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer } from "react-toastify";
import InterviewStart from "../../../components/candidate/assessment/InterviewStart";
import InterviewEnd from "../../../components/candidate/assessment/InterviewEnd";
import ConfirmPopUp from "../../../components/recruiter/job/assessment/ConfirmPopUp";
import VideoRecording from "../../../components/recruiter/job/assessment/VideoRecording";
import CameraAssess from "./CameraAssess";
import HelpModal from "./HelpModal";
import { Timer } from "./Timer";

const core = require("../../../lib/core");

function Assessment() {
  const nav = useNavigate();
  const loc = useLocation();

  const { user } = useContext(core.UserContext);
  const client = ZoomMtgEmbedded.createClient();

  const [interviewRunning, setInterviewRunning] = useState(false);
  const [popUp, setPopUp] = useState();
  const [sequence, setSequence] = useState([
    "start",
    "mcq",
    "coding",
    "interview",
    "end",
  ]);
  // const [sequence, setSequence] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [mcQuestions, setMcQuestions] = useState([]);
  const [codeQsn, setCodeQsn] = useState([]);
  const [descriptiveQsn, setDescriptiveQsn] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeRemaining, setTimeRemaining] = useState(
    +getCookie("timer")?.split(" ")[0] * 60,
  );

  // console.log(getCookie("timer"), timeRemaining)
  const [meetingUrl, setMeetingUrl] = useState("");
  var page;
  // On page load => load only once
  // Get interview details and questions
  useEffect(() => {
    if (interviewRunning) {
      // startMeeting();
      handleStartRecording();
      updateInterviewStatus();
      getIntdeatails(getCookie("assessmentId"));
    }
    page = document.getElementById("assessment");

    // Disable right-click menu
    page.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, [interviewRunning]);

  async function updateInterviewStatus() {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT__UPDATE_INTERVIEW_STATUS +
          getCookie("assessmentId"),
        1,
        { status: "inprogress" },
      );
    } catch (e) {
      console.log(e);
    }
  }

  async function startMeeting() {
    try {
      let meetingSDKElement = document.getElementById("ZoomMeet");
      let json = await core.API(
        core.API_METHODS.POST,
        core.BOT_API.CREATE_ZOOM_MEET,
        1,
        {},
        // code: loc?.search?.split("code=")?.at(-1),
      );
      // console.log(json.data);
      client.init({
        debug: true,
        zoomAppRoot: meetingSDKElement,
        language: "en-US",
        disableCOEP: false,
        customize: {
          meetingInfo: [
            "topic",
            "host",
            "mn",
            "pwd",
            "telPwd",
            // "invite",
            "participant",
            "dc",
            "enctype",
          ],
          video: {
            popper: {
              disableDraggable: true,
              anchorElement: meetingSDKElement,
              placement: "top",
            },
            isResizable: false,
            viewSizes: {
              default: {
                width: 233,
                height: window.innerHeight - 174 - 80,
              },
              ribbon: {
                width: 233,
                height: window.innerHeight - 174 - 80,
              },
            },
          },
          toolbar: {
            buttons: [],
          },
        },
      });
      client.join({
        signature: json.signature,
        sdkKey: "fzbCTlQTR6eM4Rog8GMqfg",
        meetingNumber: json.data.id,
        password: json.data.encrypted_password,
        userName: "Niraj",
        // userEmail: user.email,
        userEmail: "kr.niraj@sproutsai.com",
        //   tk: registrantToken,
        zak: json.zak,
        success: function () {
          ZoomMtgEmbedded.getInstance().addListen(
            "userAudioStatusChange",
            function (payload) {
              console.log("Audio status changed:", payload);
            },
          );

          ZoomMtgEmbedded.getInstance().addListen(
            "userVideoStatusChange",
            function (payload) {
              console.log("Video status changed:", payload);
            },
          );
        },
      });

      enterBot(json.data.join_url);
      setMeetingUrl(json.data.join_url);
      // setInterviewRunning(true);

      // Get meeting object
      let meetingObj = client.getMeetingObj();

      // Disable active speaker view and enable gallery view
      meetingObj.activeSpeakerView = false; // Disable active speaker view
      meetingObj.showGalleryView();

      // Show host's video by default
      let hostVideo = meetingObj
        .getAttendees()
        .find((attendee) => attendee.isHost);
      if (hostVideo) {
        hostVideo.videoOn = true;
      }
    } catch (err) {
      console.log(err);
      // setInterviewRunning(true);
    }
  }

  async function enterBot(url) {
    // console.log(url);
    // console.log(getCookie("assessmentId"));

    try {
      const token = "Token aa71aa7bf0dbc53aa62c212206f7df3f95b21962";
      await fetch(core.BOT_API.POST_JOIN_ASSESSMENT_MEETING, {
        method: "POST",
        headers: { Authorization: token, "Content-Type": "application/json" },
        body: JSON.stringify({
          meeting_url: url,
          token: "aa71aa7bf0dbc53aa62c212206f7df3f95b21962",
          interviewId: getCookie("assessmentId"),
        }),
      }).then((response) =>
        response
          .json()
          .then((data) => ({
            data: data,
            status: response.status,
          }))
          .then((res) => {
            console.log("Bot Enterred", res.data);
          }),
      );
    } catch (err) {
      console.log(err);
    }
  }

  async function getIntdeatails(id) {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_CANDIDATE_INTERVIEW_DETAILS + id,
        1,
      );
      if (json?.data) {
        console.log("GetIntDeatails is called.");
        console.log(
          "Data in the candidate interview details is : ",
          json?.data,
        );
        setMcQuestions(json?.data?.interview.mcqs);
        setCodeQsn(json?.data?.interview?.codingChallenges);
        // console.log(...Array(json?.data?.interview?.mcqs?.length)?.fill([]));
        setDescriptiveQsn(json?.data?.interview?.descriptive_questions ?? []);
        setSelectedAnswers({
          coding: [
            ...Array(json.data.interview.codingChallenges.length ?? []).fill(
              "",
            ),
          ],
          mcq: [...Array(json?.data?.interview?.mcqs?.length)?.fill([])],

          testCases: [
            ...Array(json.data.interview.codingChallenges.length ?? []).fill(
              "",
            ),
          ],
        });
        // console.log("Descriptive questions are : ", descriptiveQsn);
        // setSequence(["start", ...json.data.interview.sequence, "end"])
        // setCurrentPage(json.data.interview.sequence[0])
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function endTest(status) {
    try {
      exitFullscreenRef.current = true;
      handleStopSharingRedirect();
      setInterviewRunning(false);
      document.exitFullscreen();
      if ($("#confirmPopup").hasClass("in")) {
        closePopUp();
      }

      handleStopRecording();
      // ZoomMtgEmbedded.destroyClient();
      // await core
      //   .API(
      //     core.API_METHODS.POST,
      //     core.BOT_API.POST_LEAVE_MEETING + getCookie("assessmentId"),
      //     1,
      //   )
      //   .then((json) => {
      //   });

      let json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_CANDIDATE_INTERVIEW + getCookie("assessmentId"),
        1,
        { status: "completed" },
      );
      page?.removeEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
      setCurrentPage(sequence?.length - 1);
    } catch (e) {
      console.log(e);
      setInterviewRunning(false);
      setCurrentPage(sequence?.length - 1);
    }
  }

  function getCookie(name) {
    var cookieName = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(";");

    for (var i = 0; i < cookieArray.length; i++) {
      var cookie = cookieArray[i];
      while (cookie.charAt(0) === " ") {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return "";
  }

  async function UpdateTestState(status) {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_CANDIDATE_INTERVIEW + loc.state.id,
        1,
        { status: "completed", response: selectedAnswers },
      );
    } catch (e) {
      console.log(e);
    }
  }

  function closePopUp() {
    $("#confirmPopup").hide();
    $("#confirmPopup").css("display", "none");
    $("#confirmPopup").removeClass("in");
    $(".modal-backdrop.fade.in").remove();
    $("body").removeClass("modal-open");
    setPopUp(false);
  }

  // Disable keyboard shortcuts - Reload, Copy-cut-paste, Open Dev tools

  document.onkeydown = (e) => {
    if (
      interviewRunning &&
      (e.key == 123 ||
        e.key == "F5" ||
        (e.ctrlKey &&
          e.shiftKey &&
          (e.key == "I" || e.key == "C" || e.key == "J" || e.key == "T")) ||
        (e.altKey && e.key == "Tab") ||
        (e.ctrlKey &&
          (e.key == "U" ||
            e.key == "u" ||
            e.key == "C" ||
            e.key == "c" ||
            e.key == "X" ||
            e.key == "x" ||
            e.key == "v" ||
            e.key == "V" ||
            e.key == "R" ||
            e.key == "r")))
    ) {
      e.preventDefault();
    }
  };

  // Detecting Full screen change
  const [exitFullscreenCnt, setExitFullscreenCnt] = useState(5);
  const exitFullscreenRef = useRef(false);
  function ifExitFullScreen() {
    if (
      interviewRunning &&
      window.screen.width != window.innerWidth &&
      !(
        document.fullscreenElement /* Standard syntax */ ||
        document.webkitFullscreenElement /* Safari and Opera syntax */ ||
        document.msFullscreenElement
      ) &&
      !$("#confirmPopup").hasClass("in")
    ) {
      setPopUp(true);
      $("#confirmPopup").show();
      $("#confirmPopup").css("display", "block");
      $("#confirmPopup").addClass("in");
      if ($(".modal-backdrop").length == 0) {
        $("#assessment").append(
          '<div className="modal-backdrop fade in"></div>',
        );
      }
      $("body").addClass("modal-open");
      if (!exitFullscreenRef.current) {
        setExitFullscreenCnt((prev) => prev - 1);
        exitFullscreenRef.current = true;
      }
    }
  }

  // Detecting tab, window or desktop switch
  document.addEventListener("visibilitychange", () => {
    if (interviewRunning) {
      if (document.visibilityState == "hidden") {
        setPopUp(
          // <ConfirmPopUp
          //   textDiv={
          //     <div>
          //       <h4>Switching from this window will end this test</h4>
          //     </div>
          //   }
          //   idTxt="tabSwitchExitWarn"
          //   onSave={() => {
          //     closePopUp();
          //     document.documentElement.requestFullscreen();
          //   }}
          //   saveTxt="Continue test "
          //   onReset={() => {
          //     closePopUp();
          //     endTest("tabSwitchExit");
          //   }}
          //   resetTxt="End test"
          // />
          true,
        );
        $("#confirmPopup").show();
        $("#confirmPopup").css("display", "block");
        $("#confirmPopup").addClass("in");
        if ($(".modal-backdrop").length == 0) {
          $("#assessment").append(
            '<div className="modal-backdrop fade in"></div>',
          );
        }
        $("body").addClass("modal-open");
      }
    }
  });

  useEffect(() => {
    if (interviewRunning) {
      // Add event listener for fullscreen changes
      document.addEventListener("fullscreenchange", handleFullscreenChange);
      document.addEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange,
      );
      document.addEventListener("mozfullscreenchange", handleFullscreenChange);
      document.addEventListener("MSFullscreenChange", handleFullscreenChange);

      // Cleanup function
      return () => {
        document.removeEventListener(
          "fullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "webkitfullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "mozfullscreenchange",
          handleFullscreenChange,
        );
        document.removeEventListener(
          "MSFullscreenChange",
          handleFullscreenChange,
        );
      };
    }
  }, [interviewRunning]);

  // Handle fullscreen change
  const handleFullscreenChange = () => {
    if (interviewRunning) {
      const isFullscreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (!isFullscreen && !$("#confirmPopup").hasClass("in")) {
        setPopUp(true);
        $("#confirmPopup").show();
        $("#confirmPopup").css("display", "block");
        $("#confirmPopup").addClass("in");
        if ($(".modal-backdrop").length == 0) {
          $("#assessment").append(
            '<div className="modal-backdrop fade in"></div>',
          );
        }
        $("body").addClass("modal-open");

        // Decrease attempt count only when exiting fullscreen
        setExitFullscreenCnt((prevCount) => {
          const newCount = prevCount - 1;
          if (newCount <= 0) {
            // If no attempts left, end the test
            closePopUp();
            endTest("fullscreenExitLimit");
          }
          return newCount;
        });
      }
    }
  };

  const [isSharing, setIsSharing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [secOrder, setSecOrder] = useState([]);
  const mediaStreamRef = useRef(null);
  let mediaRecorder;

  const handleStartSharing = async () => {
    try {
      const mediaStream = await navigator.mediaDevices.getDisplayMedia({
        video: {
          displaySurface: "monitor",
        },
      });
      mediaStreamRef.current = mediaStream;
      setIsSharing(true);
      // console.log(mediaStream);
      const sharingOption = mediaStream
        .getVideoTracks()[0]
        .getSettings().displaySurface;

      mediaRecorder = new MediaRecorder(mediaStream);
      const chunks = [];

      // console.log(mediaRecorder);

      mediaRecorder.ondataavailable = (e) => {
        chunks.push(e.data);
      };

      // console.log(sharingOption);

      if (sharingOption != "monitor") {
        const confirmed = window.confirm(
          "Choose the entire screen sharing option and try again",
        );
        if (confirmed) {
          handleStopSharingRedirect();
        } else {
          handleStopSharingRedirect();
          nav("/assessment-dashboard");
        }
      } else {
      }

      mediaRecorder.onstop = () => {
        // console.log("called");
        const blob = new Blob(chunks, { type: "video/mp4" });
        const url = URL.createObjectURL(blob);

        if (sharingOption != "monitor") {
        } else {
          VideoStoreS3(blob);
          if (interviewRunning) {
            endTest("stopRecordExit");
          } else {
            handleStopSharingRedirect();
            // handleStartSharing()
          }
        }
      };

      mediaRecorder.start();
      setRecordedChunks(chunks);
    } catch (error) {
      console.error(error);
      const confirmed = window.confirm(
        "You are not able to take the test without recording the screen. Please try again",
      );
      if (confirmed) {
        handleStartSharing();
      } else {
        nav("/assessment-dashboard");
      }
    }
  };

  const handleStopSharing = () => {
    const confirmed = window.confirm(
      "This action will take you out from assessment",
    );
    if (confirmed) {
      if (mediaStreamRef.current) {
        const tracks = mediaStreamRef.current.getTracks();
        tracks.forEach((track) => track.stop());
        mediaStreamRef.current = null;
      }
      setIsSharing(false);
    } else {
    }
  };

  const handleStopSharingRedirect = () => {
    // if (mediaRecorder && mediaRecorder.state !== 'inactive') {
    //   mediaRecorder.stop();
    //   setIsSharing(false);
    //   // Additional cleanup logic (e.g., revoke object URLs, etc.)
    // }

    if (mediaStreamRef.current) {
      const tracks = mediaStreamRef.current.getTracks();
      tracks.forEach((track) => track.stop());
      mediaStreamRef.current = null;
    }
    setIsSharing(false);
  };

  async function VideoStoreS3(blob) {
    try {
      const formData = new FormData();
      formData.append("file", blob, "recorded-video.mp4");
      const token = await core.getData(core.data.ACCESS_TOKEN);
      await fetch(
        core.CANDIDATE_API.POST_VIDEO_STORAGE + getCookie("assessmentId"),
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: formData,
        },
      )
        .then((res) => res.json())
        .then((data) => {
          // console.log(data);
        });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleStartSharing();
  }, []);

  const [HelpReq, setHelpReq] = useState(true);

  const needHelpAPI = async () => {
    try {
      if (HelpReq) {
        const json = await core.API(
          core.API_METHODS.POST,
          core.CANDIDATE_API.POST_NEED_HELP,
          1,
          { meetingLink: meetingUrl },
        );
        if (json) {
          setHelpReq(false);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const [reload, setReload] = useState(0);

  async function afterMeeting(rating, feedback) {
    const json = await core.API(
      core.API_METHODS.POST,
      core.CANDIDATE_API.POST_FEEDBACK + getCookie("assessmentId"),
      1,
      {
        rating: rating,
        feedback: feedback,
      },
    );
  }

  const [countSkipQsn, setCountSkipQsn] = useState(0);

  const trackResponse = async (question_id, ans, type) => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_TRACK_RESPONSE +
          getCookie("assessmentId") +
          "/track_response",
        1,
        {
          question_id: question_id,
          answer: ans,
          type: type,
        },
      );
    } catch (e) {
      console.log(e);
    }
  };

  // const GET_QUESTION_COUNT = async () => {
  //   try {
  //     let json = await core.API(
  //       core.API_METHODS.GET,
  //       core.CANDIDATE_API.GET_QUESTION_COUNT + getCookie("assessmentId"),
  //       1
  //     );
  //     if (json?.data) {
  //       console.log(json);
  //       setQsnCount(json?.data);
  //       setJobId(json?.data?.job);
  //       setSecOrder(json?.data?.sectionOrder);
  //       console.log("Interview data is :", json?.data);
  //       if (json?.data?.statusOfInterview === "completed") {
  //         nav("/assessment-dashboard");
  //       }
  //     } else {
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  // useEffect(() => {
  //   GET_QUESTION_COUNT();
  // }, []);

  const [qsnCount, setQsnCount] = useState(0);
  const [jobId, setJobId] = useState(0);
  const [quesData, setQuesData] = useState(null);
  const [firstSec, setFirstSec] = useState(null);

  useEffect(() => {
    const GET_QUESTION_COUNT = async () => {
      try {
        let json = await core.API(
          core.API_METHODS.GET,
          core.CANDIDATE_API.GET_QUESTION_COUNT + getCookie("assessmentId"),
          1,
        );
        if (json?.data) {
          setQsnCount(json?.data);
          setJobId(json?.data?.job);
          setSecOrder(json?.data?.sectionOrder);
          setQuesData(json?.data);
          // const firstSection = getFirstAvailableSection(qsnCount);
          // console.log("Starting with first section:", firstSection);
          // setFirstSec(firstSection);
          // setCurrentPage(sequence.indexOf(firstSection));
          const updatedSequence = ["start", ...json?.data?.sectionOrder, "end"];
          setSequence(updatedSequence);
          if (json?.data?.statusOfInterview === "completed") {
            nav("/assessment-dashboard");
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    GET_QUESTION_COUNT();
  }, []);

  useEffect(() => {
    if (secOrder && qsnCount && interviewRunning) {
      const firstAvailableSection = getFirstAvailableSection(qsnCount);
      console.log("First available section:", firstAvailableSection);

      if (firstAvailableSection === "end") {
        setCurrentPage(sequence.indexOf("end"));
      } else {
        setCurrentPage(sequence.indexOf(firstAvailableSection));
      }
    }
  }, [secOrder, qsnCount, interviewRunning]);

  useEffect(() => {
    if (exitFullscreenCnt <= 0) {
      closePopUp();
      endTest("tabSwitchExit");
    }
  }, [exitFullscreenCnt]);

  const [showCamera, setShowCamera] = useState(false);
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);

  const mediaRecorderRef = useRef(null);
  const [isInterviewSection, setIsInterviewSection] = useState(false);
  const uploadChunkRef = useRef(null);
  const streamRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [recordedDataChunks, setRecordedDataChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);

  // const { startRecording, stopRecording } = useVideoRecorder({
  //   jobId,
  //   userId: user?.id,
  //   onError: (error) => {
  //     console.error("Video recording error:", error);
  //   },
  // });

  const handleStartRecording = useCallback(() => {
    if (!stream) return;

    streamRef.current = stream;
    const options = {
      videoBitsPerSecond: 2500000,
      audioBitsPerSecond: 128000,
    };

    const mediaRecorder = new MediaRecorder(streamRef.current, options);
    mediaRecorderRef.current = mediaRecorder;

    // Create a stable callback for ondataavailable
    const handleDataAvailable = (event) => {
      if (event.data.size > 0) {
        uploadChunkRef.current?.(event.data);
      }
    };

    mediaRecorder.ondataavailable = handleDataAvailable;
    mediaRecorder.start(30000);
    setIsRecording(true);
  }, [stream]);

  useEffect(() => {
    setIsInterviewSection(sequence[currentPage] === "interview");
  }, [currentPage, sequence]);

  // Modify handleStopRecording to handle cleanup
  const handleStopRecording = useCallback(() => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);

      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
    }
  }, []);

  uploadChunkRef.current = useCallback(
    async (chunk) => {
      //   if (isInterviewSection) return;

      const formData = new FormData();
      formData.append("video", chunk, "chunk.webm");
      formData.append("invitedId", getCookie("assessmentId"));
      formData.append("jobId", jobId);

      try {
        const token = await core.getData(core.data.ACCESS_TOKEN);
        await fetch(core.CANDIDATE_API.POST_UPLOAD_VIDEO_STREAM, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            uid: user?.id,
          },
          body: formData,
        }).then((res) => res.json());
      } catch (error) {
        console.error("Error uploading chunk:", error);
      }
    },
    [jobId, user?.id],
  );

  const isSectionAvailable = (section, qsnData) => {
    if (!qsnData) return false;

    switch (section) {
      case "mcq":
        return qsnData.hasMCQ && qsnData.mcqCount > 0;
      case "coding":
        return qsnData.hasCoding && qsnData.codingCount > 0;
      case "interview":
        return qsnData.hasInterviewQuestions && qsnData.interviewCount > 0;
      default:
        return false;
    }
  };

  const getNextAvailableSection = (currentSection, qsnData) => {
    if (!secOrder || !qsnData) return "end";

    const currentIndex = secOrder.indexOf(currentSection);

    // If current section isn't found or is last, return end
    if (currentIndex === -1 || currentIndex === secOrder.length - 1) {
      return "end";
    }

    // Check remaining sections
    for (let i = currentIndex + 1; i < secOrder.length; i++) {
      if (isSectionAvailable(secOrder[i], qsnData)) {
        return secOrder[i];
      }
    }
    return "end";
  };

  // const [firstsection, setFirstSection] = useState(null);
  // useEffect(() => {
  //   setFirstSection(getFirstAvailableSection(qsnCount));
  // }, []);
  // console.log("Firstsection is ", firstsection);

  const getFirstAvailableSection = (qsnData) => {
    if (!secOrder || !qsnData) return "end";

    // Check each section in order
    for (const section of secOrder) {
      if (isSectionAvailable(section, qsnData)) {
        return section;
      }
    }
    return "end";
  };

  const changePage = (currentSection) => {
    if (!secOrder || !qsnCount) return;

    const nextSection = getNextAvailableSection(currentSection, qsnCount);
    console.log("Changing to next section:", nextSection);
    setCurrentPage(sequence.indexOf(nextSection));
  };

  const renderSection = () => {
    const currentSection = sequence[currentPage];

    if (currentSection === "mcq" && isSectionAvailable("mcq", qsnCount)) {
      return (
        <Mcq
          questions={mcQuestions}
          qsnData={qsnCount}
          trackResponse={trackResponse}
          currentQuestion={currentQuestion}
          setCurrentQuestion={setCurrentQuestion}
          UpdateTestState={UpdateTestState}
          selectedAnswers={selectedAnswers.mcq}
          setSelectedAnswers={(qId, oId, checked) => {
            selectedAnswers.mcq[qId] = checked
              ? [oId]
              : selectedAnswers.mcq[qId].filter((d) => d !== oId);
            setReload(reload + 1);
          }}
          endTest={lastSectionWithQuestions === "mcq" ? endTest : undefined}
          navigation={{
            currentPage,
            allPages: sequence,
            changePage: () => changePage("mcq"),
          }}
        />
      );
    }

    if (currentSection === "coding" && isSectionAvailable("coding", qsnCount)) {
      return (
        <CodingSection
          interviewId={getCookie("assessmentId")}
          codeQsn={codeQsn}
          qsnData={qsnCount}
          UpdateTestState={UpdateTestState}
          endTest={lastSectionWithQuestions === "coding" ? endTest : undefined}
          selectedAnswers={selectedAnswers.coding}
          testCases={selectedAnswers.testCases}
          firstSectionwithQuestions={firstSectionwithQuestions}
          setAnswers={(val, qId) => {
            selectedAnswers.coding[qId] = val;
          }}
          setPassedCases={(val, qId) => {
            selectedAnswers.testCases[qId] = val;
          }}
          navigation={{
            currentPage,
            allPages: sequence,
            changePage: () => changePage("coding"),
          }}
        />
      );
    }

    if (
      currentSection === "interview" &&
      isSectionAvailable("interview", qsnCount)
    ) {
      return (
        <AutonomusInterview
          questions={descriptiveQsn}
          endTest={
            lastSectionWithQuestions === "interview" ? endTest : undefined
          }
          currentQuestion={currentQuestion}
          selectedAnswers={selectedAnswers}
          UpdateTestState={UpdateTestState}
          setCountSkipQsn={setCountSkipQsn}
          countSkipQsn={countSkipQsn}
          // navigation={{
          //   currentPage,
          //   allPages: sequence,
          //   changePage: () => setCurrentPage(sequence.indexOf("end")),
          // }}
          navigation={{
            currentPage,
            allPages: sequence,
            changePage: () => {
              // Find the previous available section
              const currentSectionIndex = sequence.indexOf("interview");
              let previousSection = null;

              // Loop backwards through sections until we find an available one
              for (let i = currentSectionIndex - 1; i >= 0; i--) {
                const section = sequence[i];
                if (section === "start" || section === "end") continue;

                if (isSectionAvailable(section, qsnCount)) {
                  previousSection = section;
                  break;
                }
              }

              if (previousSection) {
                setCurrentPage(sequence.indexOf(previousSection));
              } else {
                // No previous section available
                // You could either:
                // 1. Do nothing
                // 2. Show a message to the user
                // 3. Disable the back button entirely
                console.log("No previous section available");
              }
            },
          }}
        />
      );
    }

    return null; // Default return if no matching section
  };

  // const SectionWrapper = ({
  //   currentSection,
  //   qsnData,
  //   setCurrentPage,
  //   sequence,
  //   children,
  // }) => {
  //   useEffect(() => {
  //     if (!isSectionAvailable(currentSection, qsnData)) {
  //       const nextSection = getNextAvailableSection(currentSection, qsnData);
  //       if (nextSection === "end") {
  //         setCurrentPage(sequence.indexOf("end"));
  //       } else {
  //         setCurrentPage(sequence.indexOf(nextSection));
  //       }
  //     }
  //   }, [currentSection, qsnData, setCurrentPage, sequence]);

  //   return isSectionAvailable(currentSection, qsnData) ? children : null;
  // };

  const [showModal, setShowModal] = useState(null);
  console.log(
    "This text shows that the component got re redered due to video upload",
  );

  const handleTimeEnd = useCallback(() => {
    endTest("timeoutExit");
  }, []);

  // Helper function to find the last section with questions
  console.log("Section order is :", secOrder);
  console.log("Question count is :", qsnCount);

  const getLastSectionWithQuestions = (secOrder) => {
    for (let i = secOrder.length - 1; i >= 0; i--) {
      const section = secOrder[i];
      if (isSectionAvailable(section, qsnCount)) {
        return section;
      }
    }
    return null;
  };

  const lastSectionWithQuestions = getLastSectionWithQuestions(secOrder);
  console.log("Last section with questions is :", lastSectionWithQuestions);
  const firstSectionwithQuestions = getFirstAvailableSection(qsnCount);
  console.log("First section with questions is :", firstSectionwithQuestions);

  console.log("Attempted INterview question count is :", countSkipQsn);

  return (
    <div className="page candidate-pages overflow-auto" id="assessment">
      <main
        className={`main-content no-padding col-xs-12 ${sequence[currentPage]}`}
      >
        <div className="col-xs-12 header-section no-padding">
          <div className="jobPosition-section">
            <span className="text">Live assessment</span>
          </div>

          <div className={`flex items-center gap-4 justify-end`}>
            <span className="flex justify-center items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="23"
                viewBox="0 0 22 23"
                fill="none"
              >
                <path
                  d="M20.1663 11.5C20.1663 16.56 16.0597 20.6666 10.9997 20.6666C5.93967 20.6666 1.83301 16.56 1.83301 11.5C1.83301 6.43998 5.93967 2.33331 10.9997 2.33331C16.0597 2.33331 20.1663 6.43998 20.1663 11.5Z"
                  stroke="#5F6989"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M14.4021 14.4148L11.5604 12.719C11.0654 12.4256 10.6621 11.7198 10.6621 11.1423V7.38397"
                  stroke="#5F6989"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-2">Time left</span>
            </span>
            <Timer
              initialTime={timeRemaining}
              onTimeEnd={handleTimeEnd}
              interviewRunning={interviewRunning}
            />

            <button
              className={`
                 px-8 py-2 font-medium rounded-md border border-gray-500 hover:border-[#252f4a] shadow-md text-gray-800 bg-white hover:bg-[#252f4a] hover:text-white hover:scale-105 transition-all duration-300`}
              onClick={() => {
                endTest("buttonExit");
              }}
            >
              End Test
            </button>
          </div>
        </div>

        {interviewRunning ? (
          renderSection()
        ) : (
          <>
            <div className="backdrop" style={{ background: "#F3F5F9" }} />
            {sequence[currentPage] === "start" ? (
              <InterviewStart
                qsnCount={qsnCount}
                setShowCamera={setShowCamera}
                startMeeting={() => {
                  const firstAvailableSection =
                    getFirstAvailableSection(qsnCount);
                  console.log("Starting with section:", firstAvailableSection);

                  setInterviewRunning(true);
                  if (firstAvailableSection === "end") {
                    setCurrentPage(sequence.indexOf("end"));
                  } else {
                    setCurrentPage(sequence.indexOf(firstAvailableSection));
                  }
                }}
                sequence={sequence}
                quLen={[mcQuestions, codeQsn]}
              />
            ) : sequence[currentPage] === "end" ? (
              <InterviewEnd
                afterMeeting={afterMeeting}
                selectedAnswers={selectedAnswers}
                countSkipQsn={countSkipQsn}
                timeTaken={
                  +getCookie("timer")?.split(" ")[0] * 60 - timeRemaining
                }
                questionsAttempted={selectedAnswers}
              />
            ) : null}
          </>
        )}

        {/* <div id="ZoomMeet" className="interview" /> */}

        {sequence[currentPage] != "end" && (
          <div className="absolute right-0 top-[80px] w-[240px] h-[80%] rounded-lg px-[8px] shadow-md flex items-center justify-center bg-[black]">
            <div className="absolute top-[8px] left-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="20"
                viewBox="0 0 100 20"
                fill="none"
              >
                <path
                  d="M0 13.2562L1.44973 11.8618C2.21156 13.2562 3.49361 13.9633 4.8496 13.9633C6.26236 13.9633 7.19056 13.1266 7.19056 12.0851C7.19056 11.3965 6.74428 10.8018 5.83457 10.318C5.61143 10.207 5.14668 10.0022 4.44162 9.70478C3.75372 9.38889 3.21502 9.12852 2.86249 8.92366C1.46954 8.08703 0.78164 6.9702 0.78164 5.59432C0.78164 4.51583 1.17114 3.60386 1.97126 2.87825C2.77007 2.15265 3.81049 1.78125 5.09254 1.78125C6.82086 1.78125 8.21382 2.43284 9.27405 3.75321L7.95503 5.22293C7.1932 4.27396 6.2452 3.80872 5.11235 3.80872C3.92272 3.80872 3.06846 4.46032 3.06846 5.4833C3.06846 6.1349 3.45928 6.69265 4.22112 7.12088C4.46274 7.2504 4.96447 7.51077 5.76327 7.88349C6.56208 8.23638 7.11926 8.51658 7.45463 8.70161C8.84758 9.46423 9.55396 10.5612 9.55396 11.9754C9.55396 13.1478 9.10769 14.1139 8.23494 14.8765C7.3622 15.6207 6.22803 15.9921 4.85356 15.9921C2.84401 15.9894 0.984972 15.0219 0 13.2562Z"
                  fill="white"
                />
                <path
                  d="M20.6268 7.02391C21.5748 8.0469 22.0581 9.29325 22.0581 10.763C22.0581 12.2327 21.5748 13.4605 20.6268 14.4835C19.6788 15.5065 18.4905 16.0088 17.0593 16.0088C15.6663 16.0088 14.5136 15.4695 13.6396 14.3725V19.8972H11.5957V5.74187H13.6396V7.19309C14.5506 6.05908 15.702 5.5 17.0777 5.5C18.509 5.49868 19.6801 6.00092 20.6268 7.02391ZM19.1031 8.36278C18.509 7.71119 17.7287 7.3768 16.7807 7.3768C15.8327 7.3768 15.0523 7.71119 14.4582 8.36278C13.8825 9.01437 13.5855 9.79549 13.5855 10.7431C13.5855 11.6921 13.8825 12.5102 14.4582 13.1618C15.0523 13.8134 15.8327 14.1293 16.7807 14.1293C17.7287 14.1293 18.509 13.8134 19.1031 13.1618C19.6973 12.5102 19.9944 11.6921 19.9944 10.7431C19.9957 9.79549 19.6986 9.01437 19.1031 8.36278Z"
                  fill="white"
                />
                <path
                  d="M24.1016 15.7652V5.73889H26.053V7.1716C26.6472 6.03759 27.5952 5.47852 28.8957 5.47852C29.3235 5.47852 29.713 5.53403 30.0853 5.64637L29.8992 7.63683C29.5466 7.50731 29.1743 7.45048 28.8033 7.45048C27.2426 7.45048 26.1454 8.52897 26.1454 10.6318V15.7652H24.1016Z"
                  fill="white"
                />
                <path
                  d="M99.2364 7.41797V16.0235H96.8535V9.05686L99.2364 7.41797Z"
                  fill="white"
                />
                <path
                  d="M98.0457 5.98484C97.3103 5.98484 96.7148 5.38876 96.7148 4.65258C96.7148 3.91639 97.3103 3.32031 98.0457 3.32031C98.7812 3.32031 99.3766 3.91639 99.3766 4.65258C99.3766 5.38743 98.7798 5.98484 98.0457 5.98484Z"
                  fill="#80BB01"
                />
                <path
                  d="M91.6715 6.82616H89.4732L91.3758 11.6411H91.3719L92.6103 14.7695H92.6037L93.1174 16.0502H95.4662L91.6715 6.82616ZM90.1308 2.87695H87.7674L82.6035 16.0476H84.9484L85.9862 13.5126L86.7032 11.6398H86.6992L86.9435 11.0067L90.1308 2.87695Z"
                  fill="white"
                />
                <path
                  d="M50.0664 11.6698V5.75391H52.1103V11.65C52.1103 13.1567 52.9844 14.1242 54.4513 14.1242C55.938 14.1242 56.7922 13.1937 56.7922 11.65V5.75391H58.8546V11.6698C58.8546 14.4044 57.2002 16.0221 54.4513 16.0221C51.7749 16.0221 50.0664 14.3489 50.0664 11.6698Z"
                  fill="white"
                />
                <path
                  d="M66.1013 14.3388C65.7487 14.3388 65.4424 14.2133 65.1902 13.9674C64.9341 13.7216 64.8047 13.4137 64.8047 13.0594V4.79492H62.8453V8.16259H61.5078V9.71425H62.8453V12.7621C62.8453 13.2075 62.9311 13.6291 63.1028 14.0163C63.2744 14.4062 63.5108 14.7525 63.8012 15.0459C64.0917 15.3367 64.4403 15.568 64.8351 15.7345C65.2285 15.9011 65.6537 15.9843 66.0999 15.9843H67.6474V14.3388H66.1013ZM65.8121 8.16259L66.6783 9.71425H67.6487V8.16259H65.8121Z"
                  fill="white"
                />
                <path
                  d="M70 13.8449L71.319 12.654C71.9317 13.64 72.9351 14.1792 73.8831 14.1792C74.8496 14.1792 75.5375 13.6215 75.5375 12.9329C75.5375 12.4492 75.2589 12.0593 74.6819 11.7791C74.5142 11.6865 74.1063 11.5187 73.4751 11.2768C72.8612 11.0164 72.4347 10.8301 72.2116 10.7006C71.1527 10.1428 70.614 9.32468 70.614 8.22635C70.614 7.38973 70.9295 6.70112 71.5435 6.16188C72.1746 5.62263 73.0117 5.34375 74.0336 5.34375C75.4649 5.34375 76.5608 5.8645 77.3226 6.88748L76.133 8.15234C75.5388 7.42673 74.814 7.05534 73.9399 7.05534C73.1041 7.05534 72.6024 7.53908 72.6024 8.15234C72.6024 8.63608 72.881 9.00747 73.4382 9.24934L74.4601 9.67757C75.0925 9.93794 75.556 10.1428 75.8729 10.3093C77.0057 10.9041 77.5827 11.742 77.5827 12.839C77.5827 13.714 77.2302 14.4568 76.5423 15.0529C75.8544 15.6476 74.9447 15.945 73.8475 15.945C72.304 15.9463 70.8358 15.2406 70 13.8449Z"
                  fill="white"
                />
                <path
                  d="M89.3192 13.5337C88.5838 13.5337 87.9883 12.9376 87.9883 12.2014C87.9883 11.4652 88.5838 10.8691 89.3192 10.8691C90.0546 10.8691 90.6501 11.4652 90.6501 12.2014C90.6501 12.9376 90.0546 13.5337 89.3192 13.5337Z"
                  fill="#FF7324"
                />
                <path
                  d="M33.193 6.6864L34.9677 7.70682C35.0642 7.76234 35.1869 7.73453 35.2541 7.64566C36.0166 6.63671 37.0946 5.96657 38.2669 5.69219C38.3818 5.6653 38.4581 5.55424 38.4369 5.4382L38.0665 3.41631C38.0461 3.30669 37.9383 3.23237 37.8287 3.25652C35.9744 3.65971 34.2725 4.73735 33.1205 6.38355C33.0536 6.48388 33.087 6.62509 33.193 6.6864Z"
                  fill="#FF7324"
                />
                <path
                  d="M31.8517 10.1271L34.1352 9.80385C34.2169 9.7927 34.2838 9.73325 34.308 9.65521L34.5049 8.98261C34.5328 8.88971 34.4919 8.78938 34.4083 8.74107L32.606 7.7043C32.4908 7.63742 32.3441 7.69316 32.3013 7.8195L31.6213 9.85216C31.5748 10.0008 31.6975 10.1494 31.8517 10.1271Z"
                  fill="#FF7324"
                />
                <path
                  d="M45.1182 3.78687C44.6649 3.38182 44.6277 2.68507 45.0328 2.23172L46.705 0.366275C47.11 -0.0870788 47.8068 -0.124239 48.2601 0.280807C48.7135 0.685853 48.7506 1.38261 48.3456 1.83596L46.6734 3.7014C46.2665 4.15475 45.5716 4.19377 45.1182 3.78687Z"
                  fill="#80BB01"
                />
                <path
                  d="M39.4858 12.7373C38.4268 12.7373 37.5684 11.8789 37.5684 10.8198C37.5684 9.76074 38.4268 8.90234 39.4858 8.90234C40.5449 8.90234 41.4033 9.76074 41.4033 10.8198C41.4033 11.8789 40.5449 12.7373 39.4858 12.7373Z"
                  fill="#80BB01"
                />
                <path
                  d="M36.8252 15.6574C36.879 15.5539 36.84 15.4267 36.7401 15.3664C35.2163 14.4477 34.3171 12.8709 34.1997 11.2087C34.1904 11.0861 34.0826 10.995 33.9619 11.0117L31.9273 11.3035C31.8177 11.3202 31.736 11.4187 31.7471 11.5301C31.9665 13.9591 33.3227 16.2432 35.5932 17.5543C35.6972 17.6144 35.8295 17.5737 35.8849 17.4671L36.8252 15.6574Z"
                  fill="#FF7324"
                />
                <path
                  d="M39.2478 3.32918L39.6269 5.39157C39.6436 5.48633 39.7253 5.55507 39.8219 5.5625C40.6042 5.61267 41.3864 5.83749 42.1092 6.25368C43.232 6.89957 44.0297 7.89885 44.4393 9.03522C44.55 9.39564 44.7329 10.1058 44.758 10.7473C44.7793 11.8402 44.4615 12.955 43.762 13.9291C42.5586 15.6089 40.4745 16.4015 38.5022 16.0367C38.4067 16.0191 38.3098 16.064 38.2651 16.1502L37.2938 18.0194C37.2321 18.1383 37.2929 18.2843 37.4221 18.3197C40.5586 19.18 44.0124 17.9639 45.882 15.2074C46.8105 13.8369 47.2426 12.2978 47.2311 10.7844C47.2317 10.784 47.2323 10.7836 47.2329 10.7833C47.2329 10.2311 47.2329 9.30896 46.7211 8.08509C46.1072 6.46839 44.9527 5.04663 43.3354 4.11697C42.1092 3.41093 40.7677 3.07835 39.4485 3.08206C39.3221 3.08206 39.2255 3.20097 39.2478 3.32918Z"
                  fill="#80BB01"
                />
              </svg>
            </div>
            {/* abc */}
            <VideoRecording
              isCameraOn={isCameraOn}
              setIsCameraOn={setIsCameraOn}
              isMicOn={isMicOn}
              setIsMicOn={setIsMicOn}
              defaultcamera={true}
              defaultmic={true}
              icons={false}
              handleStartRecording={handleStartRecording}
              mediaRecorderRef={mediaRecorderRef}
              stream={stream}
              setStream={setStream}
              recordedChunks={recordedDataChunks}
              setRecordedChunks={setRecordedDataChunks}
              isRecording={isRecording}
              setIsRecording={setIsRecording}
            />
          </div>
        )}

        <a
          className="cursor-pointer"
          onClick={() => {
            setShowModal("help");
            needHelpAPI();
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g clipPath="url(#clip0_1034_105539)">
              <path
                d="M10.2623 7.56059L10.7669 7.88527L10.2623 7.56059ZM6.9 6.75C6.9 7.08137 7.16863 7.35 7.5 7.35C7.83137 7.35 8.1 7.08137 8.1 6.75H6.9ZM8.4 10.125C8.4 10.4564 8.66863 10.725 9 10.725C9.33137 10.725 9.6 10.4564 9.6 10.125H8.4ZM9.6 12C9.6 11.6686 9.33137 11.4 9 11.4C8.66863 11.4 8.4 11.6686 8.4 12H9.6ZM8.4 12.75C8.4 13.0814 8.66863 13.35 9 13.35C9.33137 13.35 9.6 13.0814 9.6 12.75H8.4ZM15.9 9C15.9 12.8108 12.8108 15.9 9 15.9V17.1C13.4735 17.1 17.1 13.4735 17.1 9H15.9ZM9 15.9C5.18924 15.9 2.1 12.8108 2.1 9H0.9C0.9 13.4735 4.52649 17.1 9 17.1V15.9ZM2.1 9C2.1 5.18924 5.18924 2.1 9 2.1V0.9C4.52649 0.9 0.9 4.52649 0.9 9H2.1ZM9 2.1C12.8108 2.1 15.9 5.18924 15.9 9H17.1C17.1 4.52649 13.4735 0.9 9 0.9V2.1ZM9.9 6.75C9.9 6.93006 9.84774 7.09609 9.75777 7.23592L10.7669 7.88527C10.9777 7.5576 11.1 7.16716 11.1 6.75H9.9ZM8.1 6.75C8.1 6.25294 8.50294 5.85 9 5.85V4.65C7.8402 4.65 6.9 5.5902 6.9 6.75H8.1ZM9 5.85C9.49706 5.85 9.9 6.25294 9.9 6.75H11.1C11.1 5.5902 10.1598 4.65 9 4.65V5.85ZM8.4 9.75V10.125H9.6V9.75H8.4ZM9.75777 7.23592C9.6645 7.38087 9.54738 7.53138 9.40851 7.70396C9.27593 7.86873 9.11878 8.05925 8.9779 8.25288C8.70036 8.63431 8.4 9.1382 8.4 9.75H9.6C9.6 9.53337 9.70667 9.29089 9.94823 8.95889C10.0669 8.7958 10.1973 8.63784 10.3434 8.45623C10.4833 8.28243 10.636 8.08865 10.7669 7.88527L9.75777 7.23592ZM8.4 12V12.75H9.6V12H8.4Z"
                fill="#5F6989"
              />
            </g>
            <defs>
              <clipPath id="clip0_1034_105539">
                <rect width="18" height="18" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Need help
        </a>
      </main>
      {popUp && interviewRunning ? (
        <ConfirmPopUp
          attemptsLeft={exitFullscreenCnt}
          textDiv={
            <div>
              <h4>Switching from this window will end this test.</h4>
            </div>
          }
          idTxt="tabSwitchExitWarn"
          onSave={() => {
            closePopUp();
            document.documentElement.requestFullscreen();
            setTimeout(() => {
              exitFullscreenRef.current = false;
            }, 1000);
          }}
          saveTxt="Continue test "
          onReset={() => {
            closePopUp();
            endTest("tabSwitchExit");
          }}
          resetTxt="End test"
          modalDismiss={() => {
            closePopUp();
          }}
          endTest={() => {
            endTest("tabSwitchExit");
            closePopUp();
          }}
        ></ConfirmPopUp>
      ) : null}
      <ToastContainer />

      {showCamera && (
        <CameraAssess
          startMeeting={() => {
            setInterviewRunning(true);
            setCurrentPage(currentPage + 1);
            setShowCamera(false);
          }}
          videoElement={<VideoRecording />}
          modalDismiss={() => {
            setShowCamera(false);
          }}
        />
      )}

      {showModal === "help" && (
        <HelpModal
          modalDismiss={() => {
            setShowModal(null);
          }}
        />
      )}
    </div>
  );
}

export default Assessment;
