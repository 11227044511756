import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getWebhooksAtsTokenService,
  reparseJobService,
  validateAtsTokenService,
} from "../services";

const core = require("../../lib/core");

const initialState = {
  isLoading: true,
  error: null,
  isTokenValid: false,
  atsToken: null,
  jobParsed: null,
};

const atsIntegrationSlice = createSlice({
  name: "atsIntegration",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(validateAtsTokenService.pending, (state, action) => {
        state.isLoading = false;
        state.isTokenValid = false;
      })
      .addCase(validateAtsTokenService.fulfilled, (state, action) => {
        state.isTokenValid = action.payload;
        state.isLoading = false;
      })
      .addCase(validateAtsTokenService.rejected, (state, action) => {
        state.isLoading = false;
        state.isTokenValid = false;
      });
  },
});

const getWebhooksAtsIntegrationSlice = createSlice({
  name: "getWebhooksAtsIntegration",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWebhooksAtsTokenService.pending, (state, action) => {
        state.isLoading = true;
        state.atsToken = null;
      })
      .addCase(getWebhooksAtsTokenService.fulfilled, (state, action) => {
        state.atsToken = action.payload;
        state.isLoading = false;
      })
      .addCase(getWebhooksAtsTokenService.rejected, (state, action) => {
        state.isLoading = false;
        state.atsToken = null;
      })

      .addCase(reparseJobService.pending, (state, action) => {
        state.isLoading = true;
        state.jobParsed = null;
      })
      .addCase(reparseJobService.fulfilled, (state, action) => {
        state.atsToken = action.payload;
        state.jobParsed = false;
      })
      .addCase(reparseJobService.rejected, (state, action) => {
        state.isLoading = false;
        state.jobParsed = null;
      });
  },
});

// Export actions
export const {} = atsIntegrationSlice.actions;
export const {} = getWebhooksAtsIntegrationSlice.actions;

export const atsIntegrationReducer = atsIntegrationSlice.reducer;
export const getWebhooksAtsIntegrationSliceReducer =
  getWebhooksAtsIntegrationSlice.reducer;
