import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import EditButton from "../../assets/Edit";
import Avatar from "../../assets/candidate/Avatar.png";
const core = require("../../../lib/core");

export default function ConfirmProfile({
  educationData,
  experienceData,
  persionalData,
  skill,
  tool,
  image_link,
}) {
  // Var for User
  const { user } = useContext(core.UserContext);
  // Var for Profile data
  const [profile, setProfile] = useState();
  const [resumeFile, setResumeFile] = useState("Upload Resume");
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [resumeBtnName, setResumeBtnName] = useState("Upload Resume");
  const [imgFile, setImgFile] = useState();
  const [img, setImg] = useState(user?.pic);

  //  Var for CRUD operation

  const [formState, setFormState] = useState({});

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    let response = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_PROFILE_DETAILS,
      1,
      {},
    );
    setProfile(response?.data);
    setFormState(response?.data);
  }

  const [resumeLink, setResumeLink] = useState("");
  const [resumeData, setResumeData] = useState("");

  async function upload_resume() {
    var formData = new FormData();
    formData.append("file", resumeFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);

    await fetch(core.CANDIDATE_API.POST_UPLOAD_RESUME, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        uid: user?.id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        setResumeLink(data?.data?.link);
      });

    await fetch("https://parser.sproutsai.com/resume_parser", {
      method: "POST",
      body: formData,
      enctype: "multipart/form-data",
    })
      .then((res) => res.json())
      .then((data) => {
        setResumeData(data);
        upload_resume_data(resumeLink, data);
      });
  }

  async function upload_resume_data(resumeLink, resumeData) {
    await core
      .API(core.API_METHODS.POST, core.CANDIDATE_API.POST_UPDATE_RESUME, 1, {
        link: resumeLink,
        resume: resumeData,
      })
      .then((res) => res.json())
      .then((data) => {});
  }
  return (
    <div id="edit-profile">
      <div className=" col-xs-12 no-padding personal-section data-section">
        <div className="col-xs-2 no-padding profile-pic">
          {image_link && <img src={Avatar} className="img-responsive" />}
        </div>
        <div className="col-xs-10 no-padding personal-info">
          <div className="col-xs-12 no-padding">
            <span className="profile-name">
              {persionalData?.name ?? user?.name}
            </span>
            <span
              className="edit"
              data-tooltip="Edit"
              data-target="#createCarousel"
              data-slide-to="1"
            >
              <EditButton />
            </span>
          </div>
          <span className="profile-position">{persionalData?.title}</span>
          {/* <button
                        className="resume-modal-button no-padding"
                        data-toggle="modal"
                        data-target="#upload-resume"
                    >
                        {profile?.resume ?? "Upload Resume"}
                    </button> */}
          <div id="upload-resume" className="modal fade " role={"dialog"}></div>
          <span className="profile-about col-xs-11 no-padding">
            {persionalData?.bio}
          </span>
        </div>
      </div>
      <div className="col-xs-12 no-padding skills-section data-section">
        <div className="col-xs-12 section-header no-padding">
          <h4>Skills</h4>

          {skill?.length > 0 && (
            <span
              className="add-button"
              data-target="#createCarousel"
              data-slide-to="3"
              data-tooltip="Add"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 20V12M12 12V4M12 12H20M12 12H4"
                  stroke="#363636"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          )}
        </div>
        <div className="section-data col-xs-12 no-padding">
          {skill?.length > 0 ? (
            <>
              <div className="col-xs-12 col-md-12 no-padding skills">
                {skill?.map((item, itr) => (
                  <>
                    <h5>{item?.label}</h5>
                    {item?.keySkills?.map((skill, index) => (
                      <div key={index} className="tag">
                        <span className="text">{skill?.label}</span>
                      </div>
                    ))}
                  </>
                ))}
              </div>

              {tool != null && (
                <div className="col-xs-12 col-md-6 no-padding tools">
                  <h6>Tool</h6>
                  {tool.map((item, itr) => (
                    <div key={itr} className="tag">
                      <span className="text">{item.name}</span>
                    </div>
                  ))}
                </div>
              )}
            </>
          ) : (
            // <EmptyState text={"No skills or tools added yet"} />
            <div className="no-data">
              <span data-target="#createCarousel" data-slide-to="3">
                {"+ "} {" Add skill & tool"}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="col-xs-12 no-padding experience-section data-section">
        <div className="col-xs-12 section-header no-padding ">
          <h4>Work Experience</h4>
          {experienceData?.filter(
            (obj) => !Object.values(obj).every((val) => val === ""),
          ).length > 0 && (
            <span
              className="add-button"
              data-target="#createCarousel"
              data-slide-to="2"
              data-tooltip="Add"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 20V12M12 12V4M12 12H20M12 12H4"
                  stroke="#363636"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          )}
        </div>
        {experienceData != null &&
        experienceData?.filter(
          (obj) => !Object.values(obj).every((val) => val === ""),
        ).length > 0 ? (
          <div className="section-data no-padding col-xs-12 ">
            {experienceData
              ?.filter((obj) => !Object.values(obj).every((val) => val === ""))
              .map((item, i) => (
                <div className="col-xs-12 data-row no-padding" key={i}>
                  <div className="col-xs-12 no-padding">
                    <div className="col-xs-12 no-padding date-row">
                      <span className="title">{item["position"]}</span>
                      <span className=" duration">
                        {new Date(item.start).toString().split(" ")[1]}{" "}
                        {new Date(item.start).toString().split(" ")[3]}
                        {" - "}
                        {new Date(item.end).toString().split(" ")[1]}{" "}
                        {new Date(item.end).toString().split(" ")[3]}
                        {/* {item.Duration} */}
                      </span>
                    </div>
                    <div className="col-xs-12 no-padding sub-title">
                      {item?.company}
                    </div>
                    <div className="col-xs-12 no-padding edit-row">
                      <span className="col-xs-10 no-padding description">
                        {item.description}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <div className="section-data no-padding col-xs-12 ">
            {/* <EmptyState text={"No work experience added yet"} /> */}
            <div className="no-data">
              <span data-target="#createCarousel" data-slide-to="2">
                + Add work experience
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="col-xs-12 no-padding education-section data-section">
        <div className="col-xs-12 section-header no-padding">
          <h4>Education</h4>
          {educationData?.filter(
            (obj) => !Object.values(obj).every((val) => val === ""),
          ).length > 0 && (
            <span
              className="add-button"
              data-target="#createCarousel"
              data-slide-to="4"
              data-tooltip="Add"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 20V12M12 12V4M12 12H20M12 12H4"
                  stroke="#363636"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          )}
        </div>
        {educationData != null &&
        educationData?.filter(
          (obj) => !Object.values(obj).every((val) => val === ""),
        ).length > 0 ? (
          <div className="section-data no-padding col-xs-12 ">
            {educationData
              ?.filter((obj) => !Object.values(obj).every((val) => val === ""))
              ?.map((item, i) => (
                <div className="col-md-12 data-row" key={i}>
                  <div className="col-xs-12 no-padding ">
                    <div className="col-xs-12 no-padding date-row">
                      <span className="title">
                        {item.degree}
                        {" | "}
                        {item.major}
                      </span>
                      <span className=" duration">
                        {new Date(item.start).toString().split(" ")[1]}{" "}
                        {new Date(item.start).toString().split(" ")[3]}
                        {" - "}
                        {new Date(item.end).toString().split(" ")[1]}{" "}
                        {new Date(item.end).toString().split(" ")[3]}
                      </span>
                    </div>
                    <span className="col-xs-10 no-padding sub-title">
                      {item.school}
                    </span>
                    <span className="col-xs-10 no-padding description"></span>
                    <div className="col-xs-12 no-padding edit-row">
                      <span className="col-xs-10 no-padding grade">
                        Grade: {item.gpa}
                      </span>
                    </div>
                  </div>
                  <div className="line"></div>
                </div>
              ))}
          </div>
        ) : (
          <div className="section-data no-padding col-xs-12 ">
            {/* <EmptyState text={"No educational qualifications added yet"} /> */}
            <div className="no-data">
              <span data-target="#createCarousel" data-slide-to="4">
                + Add education
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
