import { useEffect } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router";

const core = require("../../../lib/core");

export default function InviteAuth() {
  const { user } = useContext(core.UserContext);
  const loc = useLocation();
  const nav = useNavigate();
  useEffect(() => {
    if (core.checkAuth()) {
      if (user?.email.trim() == loc.search.split("=")[1].trim()) {
        nav("/assessment-dashboard", { replace: true });
      } else {
        core.logout();
        nav("/register", { state: loc.search });
      }
    } else {
      nav("/register", { state: loc.search });
    }
  }, []);
}
