import React from "react";
import { useNavigate } from "react-router-dom";
import MipsLogo from "../../../src/components/assets/MipsLogo.png";

const Header = ({ orgDetails, jobDetails, isTemplateUsed, onScrollToForm }) => {
  const navigate = useNavigate();
  console.log(jobDetails);

  const title = orgDetails?.name || "TAILORBIRD";
  const viewAllJobsText = "View All Jobs";
  const jobTitle = jobDetails?.name || "Business Development Manager";
  const jobLocation =
    jobDetails?.location?.[0]?.name ||
    "New Jersey, New York, San Francisco - Remote";
  const jobworkplace = jobDetails?.workplace;

  const url = String(orgDetails?.domain_url).startsWith("http")
    ? orgDetails?.domain_url
    : `http://${orgDetails?.domain_url}`;

  return isTemplateUsed ? (
    <div
      className="w-full p-4 text-white flex flex-col"
      style={{ backgroundColor: orgDetails?.theme_color ?? "#002332" }}
    >
      <div className="max-w-5xl w-full mx-auto md:px-6 lg:px-0">
        <div className="mb-4 mt-5 flex flex-col">
          <h1 className="text-white text-4xl font-bold mb-2 text-center md:text-left">
            {title}
          </h1>
          <div
            className="text-[#55b3b0] uppercase text-sm tracking-wide hover:cursor-pointer text-center md:text-left"
            onClick={() =>
              (window.location.href = `/job-post/${orgDetails?.search_name} `)
            }
          >
            {viewAllJobsText}
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center mt-6">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h1 className="text-2xl md:text-3xl font-bold">{jobTitle}</h1>

            {/* <p className="mt-2 text-sm text-gray-700  ">
            //           {jobLocation} - {jobworkplace}
            //         </p> */}
            <p className="mt-2 text-sm text-gray-100">
              {jobLocation && jobworkplace
                ? `${jobLocation} - ${jobworkplace}`
                : jobLocation
                  ? jobLocation
                  : jobworkplace}
            </p>
          </div>
          <button
            className="bg-[#004d71] hover:shadow-lg hover:scale-105 transition-all duration-300 text-white font-medium py-2 md:py-3 px-4 rounded"
            onClick={onScrollToForm}
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  ) : (
    // <div
    //   className="w-full p-4 text-white flex flex-col bg-defaultBg"
    //   // style={{ backgroundColor: "#002332" }}
    // >
    //   <div className="max-w-5xl w-full mx-auto md:px-6 lg:px-0">
    //     <div className="mb-4 mt-5 flex flex-col">
    //       {/* <h1 className="text-white text-4xl font-bold mb-2 text-center md:text-left">
    //         {title}
    //       </h1> */}
    //       <div className="flex flex-col -ml-8 mb-4">
    //         <img
    //           src={MipsLogo}
    //           alt="Logo"
    //           className="max-w-[30%] h-auto object-contain"
    //         />
    //       </div>
    //       <div
    //         className="text-[#e00099] uppercase text-sm tracking-wide hover:cursor-pointer text-center md:text-left"
    //         onClick={() =>
    //           (window.location.href = `/job-post/${orgDetails?.search_name} `)
    //         }
    //       >
    //         {viewAllJobsText}
    //       </div>
    //     </div>
    //     <div className="flex flex-col md:flex-row justify-between items-center mt-6">
    //       <div className="text-center md:text-left mb-4 md:mb-0">
    //         <h1 className="text-2xl md:text-3xl font-bold text-gray-700">
    //           {jobTitle}
    //         </h1>

    //         {/* <p className="mt-2 text-sm text-gray-700  ">
    //           {jobLocation} - {jobworkplace}
    //         </p> */}
    //         <p className="mt-2 text-sm text-gray-700">
    //           {jobLocation && jobworkplace
    //             ? `${jobLocation} - ${jobworkplace}`
    //             : jobLocation
    //               ? jobLocation
    //               : jobworkplace}
    //         </p>
    //       </div>
    //       <button
    //         className="bg-[#e00099] hover:shadow-lg hover:scale-105 transition-all duration-300 text-white font-medium py-2 md:py-3 px-4 rounded"
    //         onClick={onScrollToForm}
    //       >
    //         Apply Now
    //       </button>
    //     </div>
    //   </div>
    // </div>
    <div className="bg-defaultBg w-full p-4 flex flex-col">
      <div className="max-w-5xl w-full mx-auto md:px-6 lg:px-0">
        <div className="mb-4 mt-5 flex flex-col">
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="flex flex-col mb-2"
          >
            <img
              src={orgDetails?.logo}
              alt="Logo"
              className="min-w-[12rem] max-w-[25%]  h-auto object-contain mx-auto sm:mx-0 md:mx-0"
            />
          </a>
          <div
            className="text-violet-800 hover:font-semibold transition-all duration-100 text-sm tracking-wide hover:cursor-pointer text-center md:text-left"
            onClick={() =>
              (window.location.href = `/job-post/${orgDetails?.search_name}`)
            }
          >
            View All Jobs
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center mt-6">
          <div className="text-center md:text-left mb-4 md:mb-0">
            <h1 className="text-2xl md:text-3xl font-bold text-gray-700">
              {jobTitle}
            </h1>

            {/* <p className="mt-2 text-sm text-gray-700  ">
            //           {jobLocation} - {jobworkplace}
            //         </p> */}
            <p className="mt-2 text-sm text-gray-700">
              {jobLocation && jobworkplace
                ? `${jobLocation} - ${jobworkplace}`
                : jobLocation
                  ? jobLocation
                  : jobworkplace}
            </p>
          </div>
          <button
            className="hover:shadow-lg hover:scale-105 transition-all duration-300 text-white font-medium py-2 md:py-3 px-4 rounded"
            style={{ backgroundColor: orgDetails?.theme_color ?? "#002332" }}
            onClick={() =>
              navigate(`/job-post/form/${jobDetails?._id}`, {
                state: {
                  name: jobDetails?.name,
                  locationName: jobDetails?.location?.[0]?.name,
                },
              })
            }
          >
            Apply Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
