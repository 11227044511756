import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import $ from "jquery";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { async } from "q";
import { ScaleLoader } from "react-spinners";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

const updateObj = {
  password: "",
};

const initialState = {
  email: "",
  code: "",
  password: "",
  confirmPassword: "",
};
export default function Password() {
  const location = useLocation();
  const { user } = useContext(core.UserContext);

  console.log("user", user);
  const [formInput, setFormInput] = useState(initialState);

  console.log("user", formInput);

  const [pwd, setPwd] = useState("");
  const [oldpwd, setOldpwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [message, setMessage] = useState({});
  const [show, setShow] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  function setInput(key, value) {
    setFormInput({ ...formInput, [key]: value });
  }

  async function changePswd(event) {
    try {
      event.preventDefault();
      setLoading(true);
      if (pwd.length >= 8 && pwd == confirmPwd) {
        const json = await core.API(
          core.API_METHODS.PUT,
          core.USER_API.PUT_UPDATE_USER + "/password",
          1,
          {
            newPassword: pwd,
            oldPassword: oldpwd,
          },
        );
        if (json.data) {
          setMessage({
            type: "success",
            message: "Password changed successfully",
          });
        } else {
          setMessage({
            type: "error",
            message:
              "Old password does not match. Please re-enter your password",
          });
        }
      } else {
        if (pwd.length < 8) {
          setMessage({
            type: "error",
            message: "Password is not secure enough, please try again",
          });
        }
        if (pwd != confirmPwd) {
          setMessage({
            type: "error",
            message: "Passwords do not match, please try again",
          });
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("error confirming sign up", error);
    }
  }

  useEffect(() => {
    setInput("email", user?.email);
  }, []);

  async function sendOtp() {
    setDisabled(false);
    setLoading(true);
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_RESET_PSWD_SENDCODE,
      1,
      {
        email: formInput.email,
      },
    );
    if (json.data) {
      setMessage({
        type: "success",
        message: "OTP sent successfully",
      });
    }
    setLoading(false);
  }

  async function forgetpswd() {
    if (formInput.password === formInput.confirmPassword) {
      setLoading(true);
      try {
        const json = await core.API(
          core.API_METHODS.POST,
          core.USER_API.POST_RESET_PSWD,
          1,
          {
            email: formInput.email,
            password: formInput.password,
            code: formInput.code,
          },
        );
        if (json.data && json.data.message == "Password Reset Successfully") {
          setMessage({
            type: "success",
            message: "Password changed successfully",
          });
          setDisabled(true);
        } else {
          setMessage({
            type: "error",
            message: json.error?.message,
          });
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setMessage({
          type: "error",
          message: "OTP is invalid",
        });
      }
    } else {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Password do not matched",
      });
    }
  }

  return (
    <div className="col-xs-12 no-padding" id="general-setting">
      <div className="col-xs-12 py-[22px] sub-sections rounded-[12px]">
        <div className=" col-xs-12 no-padding flex flex-col">
          <span className="text-[16px] text-[#16182F] font-medium not-italic">
            Change password
          </span>
          <span className="text-[14px] text-textColor1 font-normal not-italic leading-[22px]">
            Secure your account with a new password
          </span>
        </div>

        {show ? (
          <div className="col-xs-12 no-padding code-button">
            {!showOtp ? (
              <p className="col-xs-12 no-padding">
                <a
                  onClick={(e) => {
                    sendOtp();
                    setShowOtp(!showOtp);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                  >
                    <path
                      d="M5.44199 3.6474C5.90865 3.5074 6.42198 3.41406 7.00065 3.41406C9.57665 3.41406 11.6673 5.50473 11.6673 8.08073C11.6673 10.6567 9.57665 12.7474 7.00065 12.7474C4.42465 12.7474 2.33398 10.6567 2.33398 8.08073C2.33398 7.1194 2.62331 6.23273 3.11798 5.48606"
                      stroke="#1369E9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.17767 3.5775L6.97398 1.87506"
                      stroke="#1369E9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.17773 3.57812L6.88017 5.37443"
                      stroke="#1369E9"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>{" "}
                  <span className="text-[#1369E9] text-[14px] font-normal not-italic">
                    {" "}
                    Send code
                  </span>
                </a>
              </p>
            ) : (
              <p className="col-xs-12 no-padding">
                {`Sent code to ${formInput.email} Didn't get the code`}
                <a
                // onClick={resendConfirmationCode}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M16.2718 6.71476H14.1038C13.3409 6.71476 12.7246 7.35309 12.7246 8.14318C12.7246 8.93327 13.3409 9.57159 14.1038 9.57159H19.6208C20.3837 9.57159 21 8.93327 21 8.14318V2.42953C21 1.63944 20.3837 1.00112 19.6208 1.00112C18.8579 1.00112 18.2415 1.63944 18.2415 2.42953V4.71499L17.4829 3.92936C13.7116 0.0235465 7.59986 0.0235465 3.82851 3.92936C0.0571628 7.83517 0.0571628 14.1648 3.82851 18.0706C7.59986 21.9765 13.7116 21.9765 17.4829 18.0706C18.0217 17.5127 18.0217 16.6065 17.4829 16.0485C16.9442 15.4906 16.0692 15.4906 15.5305 16.0485C12.8366 18.8384 8.4705 18.8384 5.77668 16.0485C3.08286 13.2587 3.08286 8.73686 5.77668 5.94699C8.4705 3.15713 12.8366 3.15713 15.5305 5.94699L16.2718 6.71476Z"
                      fill="#043DAB"
                      stroke="white"
                      strokeWidth="0.6"
                    />
                  </svg>{" "}
                  <span> Send Again</span>
                </a>
              </p>
            )}
            <form>
              <div className="general-info col-xs-12 no-padding">
                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 no-padding input-left-sec">
                    <label
                      className="col-xs-12 no-padding label-input"
                      htmlFor=""
                    >
                      Code
                    </label>
                    <InputBox
                      className="col-xs-12 no-padding"
                      name="otp"
                      type="text"
                      minLength={6}
                      maxLength={6}
                      required
                      onChange={(e) => {
                        setInput("code", e.target.value);
                      }}
                    />
                  </div>
                </div>

                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 no-padding input-left-sec">
                    <label
                      className="col-xs-12 no-padding label-input"
                      htmlFor=""
                    >
                      New password
                    </label>
                    <InputBox
                      className="col-xs-12 no-padding"
                      name="new"
                      type={showPwd ? "text" : "password"}
                      minLength={8}
                      required
                      onChange={(e) => {
                        setInput("password", e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 no-padding input-left-sec">
                    <label
                      className="col-xs-12 no-padding label-input"
                      htmlFor=""
                    >
                      Confirm new password
                    </label>
                    <InputBox
                      className="col-xs-12 no-padding"
                      name="confirm-password"
                      type={showPwd ? "text" : "password"}
                      minLength={8}
                      required
                      onChange={(e) => {
                        setInput("confirmPassword", e.target.value);
                      }}
                    />
                  </div>
                  {Object?.entries(message)?.length > 0 && (
                    <p
                      style={
                        message?.type == "error"
                          ? { color: "red" }
                          : { color: "green" }
                      }
                      className="block text-sm font-medium mt-2"
                    >
                      {message?.message}
                    </p>
                  )}
                </div>
                <div className="col-xs-12 no-padding">
                  <button
                    type="button"
                    disabled={disabled}
                    className="border border-button border-[#DADAE4] py-[8px] px-[14px] mt-[18px] bg-[#FFF] rounded-[8px] shadow-jobPostButtonShadow disabled:pointer-events-none disabled:opacity-50"
                    onClick={forgetpswd}
                  >
                    {loading ? (
                      <ScaleLoader height={14} color="#1369E9" loading={true} />
                    ) : (
                      <span className="text-[16px] text-[#1369E9] font-normal not-italic">
                        Save password
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div className="col-xs-12 no-padding">
            <form onSubmit={changePswd}>
              <div className="general-info col-xs-12 no-padding">
                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 no-padding input-left-sec">
                    <label
                      className="col-xs-12 no-padding label-input"
                      htmlFor=""
                    >
                      Current password
                    </label>
                    <InputBox
                      className="col-xs-12 no-padding"
                      name="new-password"
                      type={showPwd ? "text" : "password"}
                      //  className="password input"
                      minLength={8}
                      required
                      value={oldpwd}
                      onChange={(e) => {
                        setOldpwd(() => e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 no-padding input-left-sec">
                    <label
                      className="col-xs-12 no-padding label-input"
                      htmlFor=""
                    >
                      New password
                    </label>
                    <InputBox
                      className="col-xs-12 no-padding"
                      name="new-password"
                      type={showPwd ? "text" : "password"}
                      minLength={8}
                      required
                      value={pwd}
                      onChange={(e) => {
                        setPwd(() => e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-xs-12 no-padding">
                  <div className="col-xs-6 no-padding input-left-sec">
                    <label
                      className="col-xs-12 no-padding label-input"
                      htmlFor=""
                    >
                      Confirm new password
                    </label>
                    <InputBox
                      className="col-xs-12 no-padding"
                      name="confirm-password"
                      type={showPwd ? "text" : "password"}
                      minLength={8}
                      required
                      value={confirmPwd}
                      onChange={(e) => {
                        setConfirmPwd(() => e.target.value);
                      }}
                    />
                  </div>
                </div>
                {Object?.entries(message)?.length > 0 && (
                  <p
                    style={
                      message?.type == "error"
                        ? { color: "red" }
                        : { color: "green" }
                    }
                    className="block text-sm font-medium mt-2"
                  >
                    {message?.message}
                  </p>
                )}
                <div className="col-xs-12 no-padding">
                  <button
                    disabled={loading}
                    type="submit"
                    className="border border-button border-[#DADAE4] py-[8px] px-[14px] mt-[18px] bg-[#FFF] rounded-[8px] shadow-jobPostButtonShadow disabled:pointer-events-none disabled:opacity-50"
                  >
                    {loading ? (
                      <ScaleLoader height={14} color="#1369E9" loading={true} />
                    ) : (
                      <span className="text-[16px] text-[#1369E9] font-normal not-italic">
                        Save password
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}

        <div className="col-xs-12 no-padding">
          {show ? (
            <button className="forget-password" onClick={(e) => setShow(!show)}>
              Change password ?{" "}
            </button>
          ) : (
            <button className="forget-password" onClick={(e) => setShow(!show)}>
              Forgot password ?{" "}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}
