import React, { useEffect, useMemo, useState } from "react";
import InitialsLogo from "../all/InitialsLogo";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const core = require("../../lib/core");

function SmsPopup({ setHidechatbar, chatDetail, jobId, company }) {
  const [open, setOpen] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [toUser, setTouser] = useState("");
  const [userDetail, setUserDetail] = useState({});

  const [twilioNo, setTwilioNo] = useState();

  useEffect(() => {
    getUser();
  }, []);

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
        {},
      );
      if (json) {
        setTwilioNo(json?.data?.user?.phone2);
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getChatdetail(chatDetail?.applicant?.phone[0]);
  }, []);
  console.log(chatDetail);

  useEffect(() => {
    const interval = setInterval(() => {
      getChatdetail(chatDetail?.applicant?.phone[0]); // Call the function here
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, [toUser, chatDetail]);

  const groupedMessagesfunction = (messages) => {
    // Create an object to group messages by date
    const groupedMessages = {};

    messages.forEach((message) => {
      // Extract the date from the message (assuming dateCreated contains the date)
      const messageDate = new Date(message.date_created).toDateString();

      // Check if a group for this date exists, or create one
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }

      // Add the message to the corresponding group
      groupedMessages[messageDate].push(message);
    });
    return groupedMessages;
  };

  const [loading, setLoading] = useState(true);
  const [loadSendMessages, setLoadSendMessages] = useState(false);

  const getChatdetail = async (tonumber) => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.GET_MESSAGE_LIST,
        1,
        {
          toPhone: tonumber,
        },
      );

      if (json) {
        setReceivedMessages(groupedMessagesfunction(json?.data?.messages));
        setLoadSendMessages(false);
        setLoading(false);
      } else {
        setLoadSendMessages(false);
      }
    } catch {
      setLoadSendMessages(false);
      setLoading(false);
    }
  };

  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");

  const sendMessage = async () => {
    setLoadSendMessages(true);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.GET_SEND_MESSAGE,
        1,

        {
          body: message,
          toPhone: chatDetail?.applicant?.phone[0],
          name: chatDetail?.applicant?.first_name,
          matchProfile: chatDetail?._id,
          jobId: jobId,
        },
      );
      if (json) {
        setMessage("");
        getChatdetail(chatDetail?.applicant?.phone[0]);
      }
    } catch {
      setLoadSendMessages(false);
    }
  };

  function formatDateTime(dateCreated) {
    const options = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const dateTime = new Date(dateCreated);
    return dateTime.toLocaleDateString(undefined, options);
  }

  function formatDate(dateStr) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateStr);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in days between the input date and the current date
    const timeDifference = (currentDate - inputDate) / (1000 * 3600 * 24);

    if (timeDifference < 1) {
      return "Today";
    } else if (timeDifference < 2) {
      return "Yesterday";
    } else if (timeDifference < 7) {
      // Format the input date as a day of the week (e.g., Sun, Mon, etc.)
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[inputDate.getDay()];
    } else {
      // Format the input date in a custom way (e.g., 'Fri Sep 01 2023')
      const options = { year: "numeric", month: "short", day: "numeric" };
      return inputDate.toLocaleDateString("en-US", options);
    }
  }

  function isInternationalPhoneNumber(phoneNumber) {
    // Define a regular expression for the international format
    const internationalPhoneRegex = /^\+\d{11}$/;

    // Test the phoneNumber against the regular expression
    return internationalPhoneRegex.test(phoneNumber);
  }

  const [showText, setShowText] = useState(false);

  useEffect(() => {
    setShowText(true);
    // if (isInternationalPhoneNumber(chatDetail?.applicant?.phone[0])) {
    //     setShowText(true)
    // }
  }, [chatDetail]);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Call your function here
      if (message.length > 0) {
        sendMessage();
      }
      // Optionally, you can prevent the default behavior (form submission)
      e.preventDefault();
    }
  };

  return (
    <main className="relative block w-[calc(100%-16px)] h-[100%] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      <button
        onClick={(e) => setHidechatbar(false)}
        className="absolute right-[10px] cursor-pointer mt-[-10px] z-20"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M8.75195 8.75L21.252 21.25"
            stroke="#252E4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8.75 21.25L21.25 8.75"
            stroke="#252E4B"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
      {showText ? (
        <>
          <div className="relative flex flex-col h-[100%] ">
            <div className="pb-[15px] flex items-center justify-between border-b-[2px] border-b-[#E1E1EE]">
              <span className="flex items-center">
                {chatDetail?.applicant?.first_name ? (
                  <InitialsLogo str={chatDetail?.applicant?.first_name} />
                ) : (
                  <Skeleton width={"32px"} height={"32px"} />
                )}

                {chatDetail?.applicant?.first_name ? (
                  <div className="flex flex-col">
                    <span className="ml-[8px] ">
                      {" "}
                      <span className="ml-[8px] text-[#252E4B] text-[18px] font-semibold not-italic leading-[24px]">
                        {chatDetail?.applicant?.first_name}
                      </span>
                    </span>
                    <span className="ml-[8px] text-[#5F6989] text-[14px] font-normal not-italic ">
                      {chatDetail?.applicant?.phone[0]}
                    </span>
                  </div>
                ) : (
                  <div className="flex flex-col">
                    <span className="ml-[8px] ">
                      {" "}
                      <span className="ml-[8px] text-[#252E4B] text-[18px] font-semibold not-italic leading-[24px]">
                        <Skeleton width={"200px"} height={"16px"} />
                      </span>
                    </span>
                    <span className="ml-[8px] text-[#5F6989] text-[14px] font-normal not-italic ">
                      <Skeleton width={"150px"} height={"14px"} />
                    </span>
                  </div>
                )}
              </span>
            </div>
            {loading ? ( // Check if there are no messages
              <div className="flex-grow p-4 overflow-y-scroll overflow-x-hidden scrollbar-candidate">
                <div>
                  <div className={` w-[100%]   mb-2 flex `}>
                    <div
                      className={`  p-2  max-w-[60%] min-w-[30%]  flex rounded-t-[10px] rounded-br-[10px] `}
                    >
                      <span className={`flex flex-col w-full`}>
                        <span
                          className={`font-normal not-italic leading-[18px] py-[10px] rounded-[12px] ${message?.direction === "inbound-api" ? "text-[#FFF] " : "text-[#252E4B]"} ${message?.direction === "inbound-api" ? "" : "flex-row-reverse  "}`}
                        >
                          <Skeleton width={"300px"} height={"32px"} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className={` w-[100%]   mb-2 flex flex-row-reverse`}>
                    <div
                      className={`  p-2  max-w-[60%] min-w-[30%]  flex   rounded-t-[10px] rounded-br-[10px] `}
                    >
                      <span className={`flex flex-col w-full`}>
                        <span
                          className={`font-normal not-italic leading-[18px] py-[10px] rounded-[12px] ${message?.direction === "inbound-api" ? "text-[#FFF] " : "text-[#252E4B]"} ${message?.direction === "inbound-api" ? "" : "flex-row-reverse  "}`}
                        >
                          <Skeleton width={"300px"} height={"32px"} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className={` w-[100%]   mb-2 flex `}>
                    <div
                      className={`  p-2  max-w-[60%] min-w-[30%]  flex rounded-t-[10px] rounded-br-[10px] `}
                    >
                      <span className={`flex flex-col w-full`}>
                        <span
                          className={`font-normal not-italic leading-[18px] py-[10px] rounded-[12px] ${message?.direction === "inbound-api" ? "text-[#FFF] " : "text-[#252E4B]"} ${message?.direction === "inbound-api" ? "" : "flex-row-reverse  "}`}
                        >
                          <Skeleton width={"300px"} height={"32px"} />
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className={` w-[100%]   mb-2 flex flex-row-reverse`}>
                    <div
                      className={`  p-2  max-w-[60%] min-w-[30%]  flex   rounded-t-[10px] rounded-br-[10px] `}
                    >
                      <span className={`flex flex-col w-full`}>
                        <span
                          className={`font-normal not-italic leading-[18px] py-[10px] rounded-[12px] ${message?.direction === "inbound-api" ? "text-[#FFF] " : "text-[#252E4B]"} ${message?.direction === "inbound-api" ? "" : "flex-row-reverse  "}`}
                        >
                          <Skeleton width={"300px"} height={"32px"} />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              // <div className="flex justify-center items-center h-full">
              //     <Skeleton width={"32px"} height={"32px"} />{/* You can replace this with your loading animation or skeleton */}
              // </div>
              <div className="flex-grow p-4 overflow-y-scroll overflow-x-hidden scrollbar-candidate">
                {Object.keys(receivedMessages).map((date) => (
                  <div key={date}>
                    <div className="flex justify-center">
                      <span className="rounded-[8px] border border-[#E1E1EE] bg-[#E1E1EE] py-[4px] px-[9px] text-[#252E4B] text-[13px] font-normal not-italic">
                        {formatDate(date)}
                      </span>
                    </div>
                    {receivedMessages[date]?.map?.((message, index) => (
                      <div
                        className={` w-[100%]   mb-2 flex ${message?.direction === "inbound-api" ? " " : "flex-row-reverse"}`}
                      >
                        <div
                          key={index}
                          className={`  p-2  max-w-[60%] min-w-[30%]  flex ${message?.direction === "inbound-api" ? "rounded-t-[10px] rounded-br-[10px] " : "flex-row-reverse rounded-t-[10px] rounded-bl-[10px] "}`}
                        >
                          <span className={`flex flex-col w-full`}>
                            {message?.body || message?.text ? (
                              <span
                                className={`shadow px-[12px] text-[14px] font-normal not-italic leading-[18px] py-[10px] rounded-[12px] ${message?.direction === "inbound-api" ? "text-[#FFF] " : "text-[#252E4B]"} ${message?.direction === "inbound-api" ? " bg-[#1369E9]" : "flex-row-reverse  bg-[#fff]"}`}
                              >
                                {message?.body ?? message?.text}
                              </span>
                            ) : (
                              <>
                                <span
                                  className={`flex shadow px-[12px] text-[14px] font-normal not-italic leading-[18px] py-[10px] rounded-[12px] ${message?.direction === "inbound-api" ? "text-[#FFF] " : "text-[#252E4B]"} ${message?.direction === "inbound-api" ? " " : "  bg-[#fff]"}`}
                                >
                                  <div className="flex mr-[8px] items-center justify-center rounded-[12px] bg-bg1 h-[40px] w-[40px]">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M13.9844 7.40448C13.833 7.40448 13.6816 7.3491 13.5621 7.23041L11.9682 5.64791C11.7371 5.41845 11.7371 5.03865 11.9682 4.80918C12.1993 4.57972 12.5819 4.57972 12.813 4.80918L13.9844 5.97232L16.7497 3.2346C16.9808 3.00513 17.3633 3.00513 17.5944 3.2346C17.8255 3.46406 17.8255 3.84386 17.5944 4.07332L14.4068 7.23832C14.2872 7.34118 14.1358 7.40448 13.9844 7.40448Z"
                                        fill="#5F6989"
                                      />
                                      <path
                                        d="M9.24321 12.3404L7.76894 13.8042C7.45815 14.1128 6.96407 14.1128 6.64531 13.8122C6.55765 13.7251 6.46999 13.646 6.38233 13.559C5.56153 12.7361 4.82041 11.8736 4.15898 10.9716C3.50552 10.0695 2.97956 9.16752 2.59705 8.27341C2.22251 7.37139 2.03125 6.50892 2.03125 5.68602C2.03125 5.14797 2.12688 4.63366 2.31813 4.15891C2.50939 3.67625 2.81221 3.23315 3.23457 2.83752C3.74459 2.33904 4.30242 2.09375 4.89213 2.09375C5.11526 2.09375 5.33839 2.14123 5.53762 2.23618C5.74481 2.33113 5.9281 2.47355 6.07154 2.67927L7.92035 5.26666C8.0638 5.46447 8.16739 5.64646 8.23912 5.82054C8.31084 5.9867 8.35068 6.15286 8.35068 6.3032C8.35068 6.4931 8.2949 6.683 8.18333 6.86499C8.07974 7.04697 7.92832 7.23687 7.73707 7.42677L7.13142 8.05186C7.04376 8.1389 7.00392 8.24176 7.00392 8.36836C7.00392 8.43166 7.01189 8.48705 7.02782 8.55035C7.05173 8.61365 7.07564 8.66112 7.09158 8.7086C7.23502 8.96971 7.48206 9.30995 7.8327 9.7214C8.1913 10.1328 8.57381 10.5522 8.9882 10.9716C9.06789 11.0507 9.15555 11.1298 9.23524 11.2089C9.554 11.5175 9.56197 12.0239 9.24321 12.3404Z"
                                        fill="#5F6989"
                                      />
                                      <path
                                        d="M17.9456 15.0116C17.9456 15.2332 17.9057 15.4627 17.826 15.6842C17.8021 15.7475 17.7782 15.8108 17.7463 15.8741C17.6109 16.159 17.4355 16.428 17.2044 16.6812C16.814 17.1085 16.3836 17.4171 15.8975 17.6149C15.8896 17.6149 15.8816 17.6228 15.8736 17.6228C15.4035 17.8127 14.8934 17.9155 14.3436 17.9155C13.5307 17.9155 12.6621 17.7256 11.7457 17.3379C10.8292 16.9502 9.9128 16.428 9.00433 15.7713C8.69354 15.5418 8.38274 15.3123 8.08789 15.067L10.6938 12.4797C10.9169 12.6458 11.1161 12.7724 11.2835 12.8594C11.3233 12.8753 11.3711 12.899 11.4269 12.9227C11.4907 12.9465 11.5544 12.9544 11.6261 12.9544C11.7616 12.9544 11.8652 12.9069 11.9529 12.8199L12.5585 12.2265C12.7577 12.0286 12.949 11.8783 13.1323 11.7834C13.3156 11.6726 13.4989 11.6172 13.6981 11.6172C13.8495 11.6172 14.0089 11.6488 14.1842 11.7201C14.3595 11.7913 14.5428 11.8941 14.742 12.0286L17.3798 13.8881C17.587 14.0305 17.7304 14.1967 17.8181 14.3945C17.8978 14.5923 17.9456 14.7901 17.9456 15.0116Z"
                                        fill="#5F6989"
                                      />
                                    </svg>
                                  </div>
                                  <div className="flex flex-col">
                                    <span className="text-[14px] text-[#16182F] font-medium leading-[18px] not-italic">
                                      {userDetail?.name}
                                    </span>
                                    <span className="text-[13px] text-[#5F6989] font-normal leading-[18px] not-italic">
                                      {message?.status}
                                    </span>
                                  </div>
                                </span>
                                {message?.status === "completed" && (
                                  <audio
                                    className="bg-[none]"
                                    controls
                                    src="https://api.twilio.com/2010-04-01/Accounts/ACc6de85618a2972db84f4727bbd8354b5/Recordings/RE1ba9290e98db6658bea65c34891fd85f.mp3"
                                  >
                                    {/* <source src="horse.ogg" type="audio/ogg"> */}
                                    {/* <source src="horse.mp3" type="audio/mpeg"> */}
                                    Your browser does not support the audio tag.
                                  </audio>
                                )}
                              </>
                            )}

                            <span className="flex  mt-[8px] w-full">
                              <span
                                className={`text-[13px] font-normal ${message?.direction === "inbound-api" ? "text-[#5F6989] " : "text-[#5F6989]"}`}
                              >
                                {formatDateTime(message?.date_created)
                                  .split(",")
                                  .at(-1)}
                              </span>{" "}
                              {/* <span className={`text-[13px] font-normal ${message?.success ? "text-[#5F6989] " : "text-[red]"} ml-[5px]`}>{message?.success ? "Delevered" : "Failed"}</span> */}
                            </span>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            )}

            <div className="bg-[#fff] mb-[12px]  px-[12px] rounded-[8px] shadow-searchboxShadow">
              <div className="flex items-center py-[9px]">
                <span className="w-[18px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g id="Group 427319278">
                      <path
                        id="Star 8"
                        d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
                        fill="#5F6989"
                      />
                      <path
                        id="Star 9"
                        d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
                        fill="#5F6989"
                      />
                      <path
                        id="Star 10"
                        d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
                        fill="#5F6989"
                      />
                    </g>
                  </svg>
                </span>
                <textarea
                  className=" w-[calc(100%-35px)] min-h-[20px] max-h-[80px] py-[9px] resize-none overflow-hidden bg-transparent p-[6px]  outline-none auto-complete-input "
                  name=""
                  id=""
                  rows={1}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter your message..."
                ></textarea>
                <span
                  className="cursor-pointer"
                  disabled={loadSendMessages}
                  onClick={(e) => {
                    if (message.length > 0) {
                      sendMessage();
                    }
                  }}
                >
                  {loadSendMessages ? (
                    <>Sending...</>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.92668 3.52389L15.06 7.09056C18.26 8.69056 18.26 11.3072 15.06 12.9072L7.92668 16.4739C3.12668 18.8739 1.16835 16.9072 3.56835 12.1156L4.29335 10.6739C4.47668 10.3072 4.47668 9.69889 4.29335 9.33222L3.56835 7.88223C1.16835 3.09056 3.13502 1.12389 7.92668 3.52389Z"
                        fill="#1369E9"
                      />
                      <path
                        d="M10 9.98571L3 8.5V11.1L10 9.98571Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="w-[100%] h-[100%] flex justify-center items-center">
          <span>Can't send sms to this number</span>
        </div>
      )}
    </main>
  );
}

export default SmsPopup;
