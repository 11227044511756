import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import JobListSidebar from "../../../components/recruiter/job/list/JobListSidebar";
import JobContent from "../../../components/recruiter/job/JobPageContent";
import RecruiterLayout from "../../../layouts/page-layout/RecruiterLayout";

export default function JobDetailPage() {
  // To check the parameters
  const [params, setParams] = useState(useParams());

  // Reload page on change
  const [reload, setReload] = useState(0);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const selectJob = (id, status, tab = params?.tab) => {
    // setJob()
    setParams((prev) => {
      let temp = prev;
      temp.jobId = id;
      temp.jobType = status;
      temp.tab = tab;
      return temp;
    });
    // fetchJob()
    window.history.replaceState(
      {},
      "",
      "/job/" + status + "/" + id + "/" + tab,
    );
    setReload((prev) => prev + 1);
  };
  useEffect(() => {
    setIsInitialLoad(false);
  }, []);
  // *********************************************

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <RecruiterLayout
        active="Job"
        sidebarMenu={
          <JobListSidebar
            selectJob={selectJob}
            params={params}
            setParams={setParams}
            replace={true}
            isInitialLoad={isInitialLoad}
          />
        }
      >
        <JobContent
          jobId={params?.jobId}
          params={params}
          setParams={setParams}
          reload={reload}
          setReload={setReload}
          // setJobType={setJobType}
        />
      </RecruiterLayout>
      <Outlet />
    </div>
  );
}
