import React from "react";
import { useContext } from "react";
import { UserContext, logout } from "../../lib/core";
import { Link, useNavigate } from "react-router-dom";
import { AuthStageContext } from "../../lib/context";
import { authStages } from "../../lib/constants";
import { useEffect } from "react";

const ActiveStatus = ({ rejected }) => {
  const nav = useNavigate();
  const { setStage, email, setEmail } = useContext(AuthStageContext);
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    const handleTabClose = (event) => {
      event.preventDefault();

      console.log("beforeunload event triggered");
      handleLogOut();
      return;
    };
    window.addEventListener("beforeunload", handleTabClose);

    return () => {
      window.removeEventListener("beforeunload", handleTabClose);
    };
  }, []);
  const handleLogOut = () => {
    setEmail("");
    setStage("NEWUSER");
    setUser({});
    logout();
    nav("/login");
  };
  return (
    <form className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
      <h1 className="w-full text-black text-4xl font-semibold">
        Account registration status
      </h1>
      {rejected || user?.stage == "REJECTED" ? (
        <>
          <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
            Your request for registration has been rejected.
          </h4>
          <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
            Please try using another email{" "}
            <a className="text-blue hover:underline" onClick={handleLogOut}>
              here
            </a>
            .
          </h4>
        </>
      ) : (
        <>
          <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
            Thank you for registering for a SproutsAI account. Your account is
            currently under review and will be approved shortly. Once approved,
            you will receive an email notification with details on how to log in
            and start using our services.
          </h4>
          <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
            In the meantime, you can explore our website and learn more about
            how SproutsAI can help you automate critical business processes like
            hiring and achieve your goals.
          </h4>
          <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
            We appreciate your patience and look forward to welcoming you to the
            SproutsAI community!
          </h4>
          <h4 className="text-gray-700 text-sm w-full mt-6">
            Not <b>{user?.email}</b>? Login using another email{" "}
            <a onClick={handleLogOut} className="text-blue-800 cursor-pointer">
              here
            </a>
          </h4>
        </>
      )}
    </form>
  );
};

export default ActiveStatus;
