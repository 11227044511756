import React, { useContext, useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { TbCloudDownload } from "react-icons/tb";

function ResumeModal({ resume_link, modalDismiss, setContentReload }) {
  const docs = [{ uri: resume_link }];

  setTimeout(() => {
    const style = document.createElement("style");
    style.textContent = `
        #pdf-zoom-in.disabled, #pdf-zoom-out.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      `;
    document.head.appendChild(style);

    const pdfPage = document.querySelector(".react-pdf__Page");
    const zoomInButton = document.getElementById("pdf-zoom-in");
    const zoomOutButton = document.getElementById("pdf-zoom-out");

    if (pdfPage && zoomInButton && zoomOutButton) {
      const checkScaleFactor = () => {
        const style = window.getComputedStyle(pdfPage);
        const scaleFactor = parseFloat(
          style.getPropertyValue("--scale-factor"),
        );

        if (scaleFactor >= 1.5) {
          zoomInButton.disabled = true;
          zoomInButton.classList.add("disabled");
        } else {
          zoomInButton.disabled = false;
          zoomInButton.classList.remove("disabled");
        }

        if (scaleFactor < 1) {
          zoomOutButton.disabled = true;
          zoomOutButton.classList.add("disabled");
        } else {
          zoomOutButton.disabled = false;
          zoomOutButton.classList.remove("disabled");
        }
      };

      // Initial check
      checkScaleFactor();

      // Set up a MutationObserver to watch for changes in the style attribute
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            checkScaleFactor();
          }
        });
      });

      observer.observe(pdfPage, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, 2000);

  return (
    <Modal
      heading={
        <a
          href={resume_link}
          rel="noreferrer"
          target="_blank"
          className="text-lg font-bold"
        >
          <TbCloudDownload color="gray" size={30} />
        </a>
      }
      noBtns={true}
      className={"md:max-w-[60%] h-[80vh]"}
      modalDismiss={modalDismiss}
    >
      <DocViewer
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
            retainURLParams: true,
          },
        }}
        theme={{
          primary: "#f9f9f9",
          secondary: "#ffffff",
          tertiary: "#f9f9f9",
          textPrimary: "#000",
          textSecondary: "#000",
          textTertiary: "#00000099",
          disableThemeScrollbar: false,
        }}
        documents={docs}
        pluginRenderers={DocViewerRenderers}
      />
    </Modal>
  );
}

export default ResumeModal;
