import { useState, useEffect } from "react";
export default function ProjectForm({
  formState,
  input_function,
  fileName,
  uploadProjectfile,
}) {
  useEffect(() => {}, [formState]);

  console.log(formState, input_function);
  return (
    <form className="col-xs-12 no-padding" id="project">
      <div className="col-xs-12">
        <label className="col-xs-12 no-padding required" htmlFor="name">
          Project Title
        </label>
        <input
          type="text"
          className="input col-xs-12 col-md-12"
          name="name"
          value={formState?.name}
          required="required"
          placeholder="Type to search... "
          onChange={(event) => {
            input_function("name", event.target.value);
          }}
        />
      </div>
      <div className="col-xs-12">
        <label className="col-xs-12 no-padding required" htmlFor="name">
          Project link
        </label>
        <input
          type="text"
          className="input col-xs-12 col-md-12"
          name="name"
          value={formState?.link}
          required="required"
          placeholder="Type to search... "
          onChange={(event) => {
            input_function("link", event.target.value);
          }}
        />
      </div>
      <div className="col-xs-12">
        <label className="col-xs-12 no-padding" htmlFor="description">
          Project description
        </label>
        <textarea
          type="text"
          className="input col-xs-12 col-md-12"
          name="description"
          value={formState?.description}
          placeholder="Type to search... "
          onChange={(event) => {
            input_function("description", event.target.value);
          }}
        ></textarea>
      </div>

      {/* <div className="col-xs-12">
        <label htmlFor="" className="col-xs-12 no-padding">
          Upload project file
        </label>
        <div className="col-xs-12 no-padding file-upload-section ">
          <input
            type="file"
            id="upload"
            name="resume"
            hidden
            onChange={uploadProjectfile}
          />
          <label className="" htmlFor="upload">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="52"
              height="52"
              viewBox="0 0 52 52"
              fill="none"
            >
              <path
                d="M17.9115 31.8066C17.5128 31.8066 17.2441 31.8456 17.1055 31.8846V34.437C17.2701 34.476 17.476 34.4868 17.7598 34.4868C18.7976 34.4868 19.4368 33.9625 19.4368 33.0763C19.4368 32.2833 18.8865 31.8066 17.9115 31.8066ZM25.4666 31.8326C25.0333 31.8326 24.7516 31.8716 24.5848 31.9106V37.5656C24.7516 37.6046 25.0203 37.6046 25.263 37.6046C27.0331 37.6176 28.1858 36.6426 28.1858 34.58C28.1988 32.7816 27.148 31.8326 25.4666 31.8326Z"
                fill="#1A64F3"
              />
              <path
                d="M30.3346 4.33325H13.0013C11.852 4.33325 10.7498 4.7898 9.93717 5.60246C9.12451 6.41511 8.66797 7.51731 8.66797 8.66659V43.3333C8.66797 44.4825 9.12451 45.5847 9.93717 46.3974C10.7498 47.21 11.852 47.6666 13.0013 47.6666H39.0013C40.1506 47.6666 41.2528 47.21 42.0654 46.3974C42.8781 45.5847 43.3346 44.4825 43.3346 43.3333V17.3333L30.3346 4.33325ZM20.5803 35.0783C19.9108 35.7066 18.9228 35.9883 17.7723 35.9883C17.5492 35.9907 17.3262 35.9776 17.105 35.9493V39.0389H15.168V30.5109C16.0421 30.3805 16.9254 30.3211 17.8091 30.3333C19.016 30.3333 19.874 30.5629 20.4525 31.0244C21.0028 31.4621 21.3755 32.1793 21.3755 33.0243C21.3733 33.8736 21.0916 34.5908 20.5803 35.0783ZM28.8288 38.0141C27.9188 38.7703 26.5343 39.1299 24.8421 39.1299C23.8281 39.1299 23.111 39.0649 22.6235 38.9999V30.5131C23.4979 30.3855 24.3809 30.3253 25.2646 30.3333C26.9048 30.3333 27.9708 30.6279 28.8028 31.2563C29.702 31.9236 30.2653 32.9874 30.2653 34.5149C30.2653 36.1681 29.6608 37.3099 28.8288 38.0141ZM36.8346 32.0016H33.5153V33.9754H36.618V35.5658H33.5153V39.0411H31.5523V30.3983H36.8346V32.0016ZM30.3346 19.4999H28.168V8.66659L39.0013 19.4999H30.3346Z"
                fill="#1A64F3"
              />
            </svg>
            <span className="file_name">{fileName ?? 'Upload Resume'}</span>
            <span className="format_txt">
              Supported formats: doc, docs, pdf, rtf, upto 2 MB
            </span>
          </label>
        </div>
      </div> */}
    </form>
  );
}
