import * as d3 from "d3";
import { useEffect } from "react";
import { ellipsis } from "./TextWrap";

export default function GanttChart({
  id,
  chartWidth,
  colors,
  chartMargin,
  experienceRange,
  totalExperience,
  dataList,
  barSize = 25,
  dualAxis = true,
  yearWise = false,
}) {
  const barGap = 2;
  const fullExperience = totalExperience ?? 6;
  const data = dataList.sort((a, b) => a.type - b.type);
  var svg, tooltip, innerHeight, innerWidth, colorScale;
  var types = [...data.map((d) => d.type)].reduce(
    (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
    {},
  );
  var categories = Object.keys(types);
  var range = experienceRange ?? [0, fullExperience];

  useEffect(() => {
    try {
      if (chartWidth != undefined) {
        innerWidth = dualAxis
          ? chartMargin?.right
            ? chartWidth - chartMargin?.right - chartMargin.left - 10
            : chartWidth - 2 * chartMargin.left - 10
          : chartWidth - chartMargin.left - 20;
        innerHeight = data?.length * (barSize + barGap);

        svg = d3
          .select("svg.timelineChart#" + id)
          .attr("width", chartWidth)
          .attr("height", innerHeight + chartMargin.bottom);
        colorScale = d3
          .scaleLinear()
          .domain([0, categories.length])
          .range(colors ?? ["#0096ff", "#FF1E6F"])
          .interpolate(d3.interpolateHcl);
        svg.selectAll("g").remove();
        svg.selectAll("rect").remove();

        var xScale = axis();
        graph(xScale);
        leftAxis();
        if (dualAxis) rightAxis();
      }
    } catch (err) {
      console.error(err);
    }
  });

  function graph(xScale) {
    var categories = Object.keys(types);

    var outerGraph = svg
      .append("g")
      .classed("outerGraph", true)
      .attr("width", innerWidth)
      .attr("height", innerHeight);

    outerGraph
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .attr("id", (d) => String(d.type).replace(" ", "_").toLowerCase())
      .attr("x", chartMargin.left)
      .attr("y", (_, i) => (barSize + barGap) * i)
      .attr("width", innerWidth + 4)
      .attr("height", barSize + barGap)
      .attr("fill-opacity", 0.2)
      .attr("fill", function (d) {
        for (var i = 0; i < categories.length; i++) {
          if (d.type == categories[i]) {
            return d3.rgb(colorScale(i));
          }
        }
      });
    var innerGraph = svg
      .append("g")
      .classed("graph", true)
      .attr("width", innerWidth)
      .attr("height", innerHeight);

    innerGraph
      .selectAll("rect")
      .data(data)
      .enter()
      .append("rect")
      .classed("bar", true)
      .attr("id", (d) => String(d.name).replace(" ", "_").toLowerCase())
      .attr("x", (d) => {
        // console.log;
        return xScale(d.minvalue) + chartMargin.left;
      })
      .attr("y", (_, i) => i * (barSize + barGap) + barGap)
      .attr("rx", 4)
      .attr("ry", 4)
      .attr("width", (d) =>
        xScale((d.maxvalue ?? d3.max(range)) - d.minvalue + d3.min(range)),
      )
      .attr("height", barSize - 2)
      .attr("fill", function (d) {
        for (var i = 0; i < categories.length; i++) {
          if (d.type == categories[i]) {
            return d3.rgb(colorScale(i));
          }
        }
      })
      .on("mouseover", (e, it) => {
        tooltip = d3
          .select("body")
          .append("div")
          .classed("tooltip", true)
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
        tooltip.append("p").text(it?.type + ":- ");
        tooltip.append("p").text(() =>
          yearWise
            ? `${it?.name && it?.name + " : "} ${it?.minvalue} - ${
                it?.maxvalue
              }`
            : `${it?.name} : 
                ${it?.minvalue} - 
                ${it?.maxvalue ?? fullExperience} yrs`,
        );
      })
      .on("mousemove", (e) => {
        tooltip
          .style("display", "block")
          .style("top", e.y + "px")
          .style("left", e.x + 25 + "px");
      })
      .on("mouseout", function () {
        d3.selectAll(".tooltip").remove();
      });
  }

  function axis() {
    var xScale = d3
      .scaleTime()
      .domain(range)
      .range([0, innerWidth + 4]);
    // Add axes to chart
    // Set axes, scaled to chart dimensions
    var xAxis = d3
      .axisBottom()
      .scale(xScale)
      .tickFormat((d) => d3.format(".4")(d));

    // Remove previous/necessary g tags
    svg
      .append("g")
      .classed("xAxis", true)
      .call(xAxis)
      .attr(
        "transform",
        "translate(" + chartMargin?.left + "," + innerHeight + ")",
      );
    return xScale;
  }

  function leftAxis() {
    var gap = 0;
    var categories = Object.keys(types);
    var leftScale = d3
      .scaleBand()
      .domain([...new Set(data.map((d) => d.type))])
      .range([0, innerHeight]);
    var leftAxis = d3.axisLeft().scale(leftScale);
    svg
      .append("g")
      .call(leftAxis)
      .classed("leftAxis", true)
      .attr("transform", "translate(" + chartMargin?.left + "," + 0 + ")")
      .selectAll(".tick")
      .remove();

    svg
      .append("g") //without doing this, impossible to put grid lines behind text
      .selectAll("text")
      .data(categories)
      .enter()
      .append("text")
      .text((d) => d)
      .attr("x", chartMargin?.left - 10)
      .attr("y", function (d, i) {
        if (i > 0) {
          for (var j = 0; j < i; j++) {
            gap += types[categories[i - 1]];
            return (types[d] * barSize) / 2 + gap * (barSize + barGap) + 5;
          }
        } else {
          return (types[d] * barSize) / 2 + 5 + barGap;
        }
      })
      .attr("font-size", 14)
      .attr("text-anchor", "end")
      .attr("text-height", barSize / 2 + 4)
      // .attr("fill", function (d) {
      //   for (var i = 0; i < categories.length; i++) {
      //     if (d == categories[i]) {
      //       return d3.rgb(colorScale(i)).darker();
      //     }
      //   }
      // })
      .attr("class", "cursor-pointer text-medium")
      .on("mouseover", (e, it) => {
        if (e.target.innerHTML.endsWith("...")) {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .attr("class", "cursor-pointer tooltip")
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
          tooltip.append("p").text(it);
        }
      })
      .on("mousemove", (e) => {
        if (e.target.innerHTML.endsWith("...")) {
          tooltip
            .attr("class", "cursor-pointer tooltip")
            .style("display", "block")
            .style("top", e.y + "px")
            .style("left", e.x + 25 + "px");
        }
      })
      .on("mouseout", function () {
        d3.selectAll(".tooltip").remove();
      })
      .call(ellipsis, chartMargin?.left, 1);
  }

  function rightAxis() {
    var rightScale = d3.scaleBand().domain(data).range([0, innerHeight]);
    // Set axes, scaled to chart dimensions
    var rightAxis = d3
      .axisRight()
      .scale(rightScale)
      .tickFormat((d) => d.name);

    svg
      .append("g")
      .call(rightAxis)
      .classed("rightAxis", true)
      .attr(
        "transform",
        "translate(" + (innerWidth + chartMargin.left + 4) + "," + 0 + ")",
      )
      .selectAll(".tick line")
      .remove();

    svg
      .selectAll(".rightAxis text")
      .attr("class", "cursor-pointer text-medium")
      .attr("font-size", 14)
      .attr("text-anchor", "start")
      .attr("text-height", barSize / 2 + 4)
      .attr("title", (d) => d.name)
      // .attr("fill", function (d) {
      //   for (var i = 0; i < categories.length; i++) {
      //     if (d.type == categories[i]) {
      //       return d3.rgb(colorScale(i)).darker();
      //     }
      //   }
      // })
      .on("mouseover", (e, it) => {
        if (e.target.innerHTML.endsWith("...")) {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .attr("class", "cursor-pointer tooltip")
            .style("display", "block")
            .style("top", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.y - 25 + "px";
              } else {
                return e.y + "px";
              }
            })
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 100 + "px";
              }
              return e.x + 25 + "px";
            });
          tooltip.append("p").text(it.name);
        }
      })
      .on("mousemove", (e) => {
        if (e.target.innerHTML.endsWith("...")) {
          tooltip
            .style("display", "block")
            .attr("class", "cursor-pointer tooltip")
            .style("top", e.y + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        }
      })
      .on("mouseout", function (e) {
        d3.selectAll(".tooltip").remove();
      })
      .call(ellipsis, chartMargin?.right ?? chartMargin?.left, 1);
  }

  return <svg id={id} className="timelineChart"></svg>;
}
