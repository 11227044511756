import * as d3 from "d3";
import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const MatchPercentChart = ({
  value,
  children,
  piewidth = 150,
  showNumericValue = false,
  showDatainPercent = true,
  textsize = 16,
}) => {
  const matchScoreThreshold = useSelector(
    (state) => state?.JobExpandSlice?.candidates?.data?.match_criteria ?? 75,
  );
  const svgRef = useRef();
  const chartMargin = 15;
  const data = Math.min(99.9, value);
  const width = 10;
  const cornerRadius = 5;
  const colors = (score) => {
    let colorArr = [
      "#b80000",
      "#b80000",
      "#cc540a",
      "#de862b",
      "#efb353",
      "#ffdf82",
      "#d2cb5c",
      "#a0b83a",
      "#a0b83a",
      "#a0b83a",
      "#a0b83a",
    ];
    if (score >= 90) {
      return "#009107";
    }
    if (score >= matchScoreThreshold) {
      return "#67a51d";
    } else return colorArr[Math.floor(score / 10)];
  };

  useEffect(() => {
    try {
      // Select SVG and set dimensions
      const chartWidth = piewidth;
      const innerWidth = chartWidth - 2 * chartMargin;
      const radius = innerWidth / 2;

      const svg = d3
        .select(svgRef?.current)
        .attr("width", chartWidth)
        .attr("height", chartWidth);

      svg.selectAll("g").remove();

      const pie = d3.pie();

      const color = d3
        .scaleOrdinal()
        .range(["rgba(51, 52, 142, 0.05)", colors(Math.floor(data))]);

      const arc = d3
        .arc()
        .outerRadius(radius)
        .innerRadius(radius - width)
        .cornerRadius(cornerRadius);

      // Group with transforms
      const g1 = svg.append("g").classed("donutGraph", true);

      g1.attr(
        "style",
        "transform : translate(" +
          chartWidth / 2 +
          "px, " +
          chartWidth / 2 +
          "px)",
      );
      const pieData = data <= 100 ? [100 - data, data] : [100, 0];
      // Group with data
      const g2 = g1
        .selectAll(".arc")
        .data(pie(pieData))
        .enter()
        .append("g")
        .attr("class", "arc");

      //   Path with color
      g2.append("path")
        .attr("d", arc)
        .attr("style", () => {
          if (data > 50) return "transform : scaleX(-1)";
        })
        .attr("fill", (d) => color(d));
    } catch (err) {
      console.error(err);
    }
  });

  return (
    <svg ref={svgRef} className="w-full mb-5 text-center">
      {showNumericValue ? (
        data > 0 && data < 10 ? (
          <text
            x={piewidth / 4 + 16}
            y={piewidth / 2 + 10}
            style={{ fontSize: textsize, fontWeight: 700 }}
          >
            {showDatainPercent
              ? Math.floor(data) + "%"
              : Math.floor(data) / 10 + "/10"}
          </text>
        ) : data >= 11 && data <= 99 ? (
          <text
            x={piewidth / 4 + 10}
            y={piewidth / 2 + 10}
            style={{ fontSize: textsize, fontWeight: 700 }}
          >
            {showDatainPercent
              ? Math.floor(data) + "%"
              : Math.floor(data) / 10 + "/10"}
          </text>
        ) : (
          <text
            x={piewidth / 4 + 2}
            y={piewidth / 2 + 10}
            style={{ fontSize: textsize, fontWeight: 700 }}
          >
            {showDatainPercent ? "100%" : "10/10"}
          </text>
        )
      ) : data >= 80 ? (
        <text
          x={150 / 2 - 16}
          y={150 / 2 - 4}
          style={{ fontSize: textsize, fontWeight: 700 }}
        >
          <tspan x={150 / 2 - 25}>Strong</tspan>
          <tspan x={150 / 2 - 25} dy="1.2em">
            Match
          </tspan>
        </text>
      ) : data >= matchScoreThreshold ? (
        <text
          x={150 / 2 - 16}
          y={150 / 2 - 4}
          style={{ fontSize: textsize, fontWeight: 700 }}
        >
          <tspan x={150 / 2 - 25}>Good</tspan>
          <tspan x={150 / 2 - 25} dy="1.2em">
            Match
          </tspan>
        </text>
      ) : data < matchScoreThreshold ? (
        <text
          x={150 / 2 - 16}
          y={150 / 2 - 12}
          style={{ fontSize: textsize, fontWeight: 700 }}
        >
          <tspan x={150 / 2 - 25}>Not a </tspan>
          <tspan x={150 / 2 - 25} dy="1.2em">
            Good
          </tspan>
          <tspan x={150 / 2 - 25} dy="1.2em">
            Match
          </tspan>
        </text>
      ) : (
        ""
      )}
    </svg>
  );
};

export default MatchPercentChart;
