import React from "react";
import Modal from "../../../modal/Modal";
import { ScaleLoader } from "react-spinners";

const ConfirmPopUp = ({
  onSave,
  modalDismiss,
  loading,
  attemptsLeft,
  endTest,
}) => {
  return (
    <Modal
      heading={"Assessment Confirmation"}
      subheading={""}
      onSave={onSave}
      modalDismiss={modalDismiss}
      onCancel={endTest}
      defaultButtons={{
        primaryDisabled: loading,
        secLabel: "Cancel Test",
        primaryLabel: "Continue Test",
      }}
    >
      {/* <textarea
        placeholder="Type here..."
        value={notes}
        name=""
        id=""
        className="w-full h-[200px] bg-white border border-[#E1E1EE]
rounded-lg resize-none"
        onChange={(e) => setNotes(e?.target?.value)}
      ></textarea> */}

      <div className="">
        <div>
          You cannot access the assessment without fullscreen mode. Do you want
          to continue?
        </div>
        <p> {attemptsLeft} attempts left</p>
      </div>
    </Modal>
  );
};

export default ConfirmPopUp;
