import React from "react";
import JobSidebar from "../../../components/all/JobSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InitialsLogo from "../../../components/all/InitialsLogo";
import DashboardPipeline from "../../../components/recruiter/dashBoard/DashboardPipeline";
import CreateCompany2 from "../CreateCompany2";

var parse = require("html-react-parser");
const core = require("../../../lib/core");

function CompanyList() {
  const [open, setOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const [company, setCompany] = useState([]);
  const [status, setStatus] = useState("");
  const [id, setId] = useState("");
  const nav = useNavigate();
  const [companyId, setCompanyId] = useState("");
  const loc = useLocation();

  useEffect(() => {
    getCompanies("");
  }, []);

  const getCompanies = async (e) => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.COMPANY_API.GET_COMPANIES_CREATED,
      1,
    );

    if (json?.data) {
      if (e === "alphabet") {
        setCompany(
          json?.data?.companies.sort(function (a, b) {
            console.log("called");
            let nameA = a.name.toLowerCase();
            let nameB = b.name.toLowerCase();
            if (nameA < nameB) {
              return -1;
            } else if (nameA > nameB) {
              return 1;
            } else {
              return 0;
            }
          }),
        );
      }
      if (e === "desc") {
        setCompany(json?.data?.companies.reverse());
      } else {
        setCompany(json?.data?.companies);

        if (loc.pathname.split("/").at(-1) === "company-profile") {
          setCompanyId(json?.data?.companies[0]?.id);
        } else {
          setCompanyId(loc.pathname.split("/").at(-1));
        }
      }
    } else {
    }
  };

  const [companyDetail, setCompanyDetail] = useState({});
  const [data, setData] = useState([]);

  const fetchcompanydetail = async (id) => {
    setId(id);
    try {
      var jobapi = await core.API(
        core.API_METHODS.GET,
        core.COMPANY_API.GET_COMPANY_PROFILE + String(id),
        1,
        {},
      );
      setCompanyDetail(jobapi?.data?.company);
      setData(jobapi?.data?.pipeline);
      console.log(data);
    } catch (error) {
      nav("/");
    }
  };

  useEffect(() => {
    fetchcompanydetail(companyId);
  }, [companyId]);

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="Companies"
          open={open}
          setOpen={setOpen}
          CandidateFilter={
            <CompaniesColumn
              company={company}
              companyDetail={companyDetail}
              setCompanyId={setCompanyId}
              getCompanies={getCompanies}
              setStatus={setStatus}
              setShowModal={setShowModal}
            />
          }
        />

        <div className="w-full">
          <HeaderNew />
          <SmsContent
            companyDetail={companyDetail}
            data={data}
            company={company}
            fetchcompanydetail={fetchcompanydetail}
            setStatus={setStatus}
            status={status}
            id={id}
            getCompanies={getCompanies}
            setShowModal={setShowModal}
          />
          {/* <smsList /> */}
          {showModal && (
            <CreateCompany2
              getCompanies={getCompanies}
              fetchcompanydetail={fetchcompanydetail}
              companyDetail={companyDetail}
              status={status}
              id={id}
              modalDismiss={() => setShowModal(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default CompanyList;

function CompaniesColumn({
  companyDetail,
  company,
  setCompanyId,
  setStatus,
  setShowModal,
}) {
  return (
    <div className="mt-[26px] relative  h-[calc(100vh-134px)]  ">
      <div className="relative w-[240px] flex items-center justify-between">
        <button
          className="border-[2px] w-full  rounded-[8px] flex justify-center items-center h-[32px] bg-[#1369E9] "
          onClick={() => {
            setStatus("new");
            setShowModal(true);
          }}
        >
          <span className="text-[14px] text-[#FFF] font-normal not-italic ">
            + Add new company
          </span>
        </button>
      </div>

      <div className="overflow-y-scroll h-[calc(100vh-180px)] mt-[15px] scrollbar-candidate">
        {company?.map((item, idx) => (
          <div
            className={`flex py-[6px] pl-[6px] ${
              item?.name === companyDetail?.name
                ? "bg-[#F3F5F9] rounded-[8px] "
                : ""
            }  cursor-pointer`}
            key={idx}
            onClick={(e) => {
              setCompanyId(item?.id);
              window.history.replaceState(
                "",
                "",
                "/company-profile/" + item?.id,
              );
            }}
          >
            <InitialsLogo str={item?.name} />
            <div className="flex flex-col ml-[8px] w-[190px]">
              <span className="inline-block text-[14px]  not-italic text-[#252E4B] font-medium truncate">
                {item?.name}
              </span>
              <span className="inline-block w-[150px] text-[13px] not-italic font-normal text-[#5F6989] truncate">
                {item?.lastMessage}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function SmsContent({
  companyDetail,
  data,
  company,
  getCompanies,
  setStatus,
  status,
  id,
  fetchcompanydetail,
  setShowModal,
}) {
  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      {/* {receivedMessages?.length>0 && */}
      {company?.length > 0 && (
        <div className="relative flex flex-col h-[calc(100vh-100px)]">
          <div className="flex justify-between">
            <div className="flex ">
              <InitialsLogo str={companyDetail?.name} />
              <div className="flex flex-col ml-[12px]">
                <span className="text-[#252E4B] text-[18px] font-semibold not-italic leading-[24px]">
                  {companyDetail?.name}
                </span>
                {companyDetail?.links?.length > 0 && (
                  <a
                    className="text-[#1369E9] text-[14px] font-normal not-italic "
                    href={companyDetail?.links[0]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {companyDetail?.links[0]}
                  </a>
                )}
              </div>
            </div>
            <button
              className="py-[8px] px-[12px] flex items-center rounded-[8px] border border-[#DADAE4] bg-[#FFF] shadow-jobPostButtonShadow"
              data-toggle="modal"
              data-target="#company-modal"
              onClick={() => {
                setStatus("update");
                setShowModal(true);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M11.9887 4.85928L15.1408 8.01137M2.5 17.5L5.56065 16.9174C6.2096 16.7938 6.80641 16.478 7.27353 16.0109L16.8198 6.46461C17.7267 5.55765 17.7267 4.08718 16.8198 3.18022C15.9128 2.27326 14.4423 2.27326 13.5354 3.18022L3.98913 12.7265C3.52201 13.1936 3.20617 13.7904 3.08263 14.4393L2.5 17.5Z"
                  stroke="#252E4B"
                  strokeWidth="1.2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span className="ml-[6px] text-[#252E4B] text-[16px] font-normal not-italic">
                {" "}
                Edit info
              </span>
            </button>
          </div>

          <div className="bg-[#FFF] rounded-[12px] shadow-searchboxShadow p-[22px] flex flex-col mt-[25px]">
            <span className="text-[#16182F] text-[16px] font-medium not-italic ">
              About
            </span>
            <p className="text-textColor1 text-[14px] not-italic font-normal ">
              {companyDetail?.about ? (
                companyDetail?.about?.includes("<body>") ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: companyDetail?.about }}
                  ></div>
                ) : (
                  parse(getText(companyDetail?.about))
                )
              ) : (
                "No Content"
              )}
            </p>
          </div>
          <div className=" mt-[25px] overflow-y-scroll">
            <DashboardPipeline data={data} loading={true} />
          </div>
        </div>
      )}
    </main>
  );
}
