export const MetricCard = ({
  title,
  id,
  value,
  change,
  last_30_days,
  onClick,
  isActive,
  active = false,
}) => {
  return (
    <div
      className={`col-span-1  flex flex-col rounded-[12px] px-[16px] py-[12px] bg-[#FFF] shadow-PipelineCardsShadow h-[120px] ${isActive ? "ring-2 ring-blue-500" : ""} ${active && "cursor-pointer"} `}
      onClick={() => (active ? onClick(id) : null)}
    >
      <span className="flex text-[#5F6989] text-[14px] font-normal leading-[22px] not-italic">
        {title}
      </span>
      <span className="mt-[15px] text-[#252E4B] text-[24px] font-semibold leading-[32px] not-italic flex items-center">
        {value}
        {change !== null && (
          <span
            className={`ml-[5px] bg-[#E6F8F5] flex items-center rounded-[14px] py-[3px] px-[6px] text-[12px] ${
              typeof change === "string" && change.startsWith("-")
                ? "text-red-500"
                : "text-[#00B998]"
            }`}
          >
            {typeof change === "string" && change.startsWith("-") ? (
              <svg
                width="11"
                height="10"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.9197 8.17969H11.6897H6.07975C5.11975 8.17969 4.63975 9.33969 5.31975 10.0197L10.4997 15.1997C11.3297 16.0297 12.6797 16.0297 13.5097 15.1997L15.4797 13.2297L18.6897 10.0197C19.3597 9.33969 18.8797 8.17969 17.9197 8.17969Z"
                  fill="#252E4B"
                />
              </svg>
            ) : (
              <svg
                width="11"
                height="10"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.6796 13.9802L15.4696 10.7702L13.5096 8.80023C12.6796 7.97023 11.3296 7.97023 10.4996 8.80023L5.31958 13.9802C4.63958 14.6602 5.12958 15.8202 6.07958 15.8202H11.6896H17.9196C18.8796 15.8202 19.3596 14.6602 18.6796 13.9802Z"
                  fill="#252E4B"
                />
              </svg>
            )}
            {typeof change === "string" ? change.replace(/^[+-]/, "") : change}
          </span>
        )}
      </span>
      {change !== null && (
        <span className="mt-[6px] text-[#5F6989] text-[12px] font-normal leading-[22px] not-italic">
          vs last month: {last_30_days}
        </span>
      )}
    </div>
  );
};
