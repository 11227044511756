import React from "react";

const IconInvite = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
    >
      <g clipPath="url(#clip0_1324_121084)">
        <path
          d="M4.15397 14.6666C5.39896 14.6666 6.44468 13.8135 6.73835 12.6599C6.82919 12.3031 7.11911 12 7.4873 12H12.154M4.15397 14.6666C2.68121 14.6666 1.4873 13.4727 1.4873 12V3.33331C1.4873 2.22874 2.38274 1.33331 3.4873 1.33331H10.154C11.2585 1.33331 12.154 2.22874 12.154 3.33331V12M4.15397 14.6666H12.154C13.399 14.6666 14.4447 13.8135 14.7384 12.6599C14.8292 12.3031 14.5222 12 14.154 12H12.154"
          stroke="#252E4B"
          strokeLinecap="round"
        />
        <path
          d="M8.96944 9.5C8.96944 8.78408 8.04084 8.2037 6.89536 8.2037C5.74988 8.2037 4.82129 8.78408 4.82129 9.5M6.89507 6.59259C6.17915 6.59259 5.59878 6.01222 5.59878 5.2963C5.59878 4.58037 6.17915 4 6.89507 4C7.611 4 8.19137 4.58037 8.19137 5.2963C8.19137 6.01222 7.611 6.59259 6.89507 6.59259Z"
          stroke="#252E4B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1324_121084">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0.154297)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconInvite;
