import React from "react";

/*
  Types - (preference)
    -2 = must not have
    -1 = not preferred
    1 = preferred
    2 = must have 
  */

const getTypeName = (
  num,
  statements = [
    "Must not have",
    "Is not preferred",
    "Is preferred",
    "Must have",
  ],
) => {
  switch (num) {
    case -2: {
      return statements[0];
    }
    case -1: {
      return statements[1];
    }
    case 1: {
      return statements[2];
    }
    case 2: {
      return statements[3];
    }
  }
};
const getTypeColor = (num) => {
  switch (num) {
    case -2: {
      return "#FF6C7E";
    }
    case -1: {
      return "#FFAA33";
    }
    case 1: {
      return "#1369E9";
    }
    case 2: {
      return "teal";
    }
  }
};

const TypesButtons = ({ options = [2, 1, -1, -2], data, clickFunction }) => {
  return options.map((type, i) => (
    <button
      key={i}
      onClick={(e) => {
        e.preventDefault();
        clickFunction(e, type);
      }}
      style={{ color: getTypeColor(type) }}
      title={getTypeName(type)}
      className={`inline-block cursor-pointer mr-2 rounded-full`}
    >
      <svg width={14} height={14} viewBox="0 0 14 14">
        <circle
          cx={7}
          cy={7}
          r={6}
          stroke={getTypeColor(type)}
          strokeWidth={1}
          fill="transparent"
          fillOpacity={0.25}
          className="hover:fill-current hover:bg-opacity-50"
        />
        {data == type && (
          <circle cx={7} cy={7} r={4} fill={getTypeColor(type)} />
        )}
      </svg>
    </button>
  ));
};

export { TypesButtons, getTypeColor, getTypeName };
