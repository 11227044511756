import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchPostedJobs,
  fetchJDProcessedAnalytics,
  fetchResumeProcessedAnalytics,
  fetchAllNewApplicantAnalytics,
} from "../services/postedJobsAnalyticsService";

const initialState = {
  isLoading: true,
  jobs: [],
  totalPages: 1,
  activeJobsCount: null,
  activeJobsLast30DaysCount: null,
  activeJobspercentageIncrease: null,
  applicationReviewSum: 0,
  resumeProcessedData: [],
  jdProcessedData: [],
  NewApplicantsProfiles: [],
  NewApplicantCountCached: 0,
  NewApplicantLast30DaysCountCached: null,
  NewApplicantpercentageIncreaseCached: null,
  applicationSource: {},
  error: null,
  isFetched: false,
};

const postedJobsAnalyticsSlice = createSlice({
  name: "postedJobsAnalytics",
  initialState,
  reducers: {
    clearState: (state) => {
      state.NewApplicantsProfiles = [];
      state.NewApplicantLast30DaysCount = 0;
      state.NewApplicantpercentageIncrease = 0;
      state.pagination = {};
      // Clear other state properties as needed
    },
    updateNewApplicantsData: (state, action) => {
      state.NewApplicantCountCached = action.payload.newApplicantCount;
      state.NewApplicantLast30DaysCountCached =
        action.payload.newApplicantLast30Days;
      state.NewApplicantpercentageIncreaseCached =
        action.payload.percentageChange;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchPostedJobs actions
      .addCase(fetchPostedJobs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPostedJobs.fulfilled, (state, action) => {
        const {
          jobDetails,
          totalPages,
          activeJobCount,
          activeJobsLast30Days,
          percentageIncrease,
          totalSourceCountsPercentage,
        } = action.payload;
        state.jobs = [...jobDetails];
        state.totalPages = totalPages;
        state.activeJobsCount = activeJobCount;
        state.activeJobsLast30DaysCount = activeJobsLast30Days;
        state.activeJobspercentageIncrease = percentageIncrease;
        state.applicationSource = totalSourceCountsPercentage;
        const sum =
          jobDetails &&
          jobDetails.length > 0 &&
          jobDetails.reduce((total, job) => {
            const applicationReviewStage = job?.stages?.find(
              (stage) => stage.name === "Application Review",
            );
            return (
              total +
              (applicationReviewStage ? applicationReviewStage.value : 0)
            );
          }, 0);
        state.applicationReviewSum = sum;
      })
      .addCase(fetchPostedJobs.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      // Handle fetchJDProcessedAnalytics actions
      .addCase(fetchJDProcessedAnalytics.fulfilled, (state, action) => {
        state.jdProcessedData = action.payload.map((item) => ({
          date: `${item.year}-${item.month.toString().padStart(2, "0")}`,
          job_count: item.job_count,
        }));
      })
      .addCase(fetchJDProcessedAnalytics.rejected, (state, action) => {
        state.error = action.payload;
      })

      // Handle fetchResumeProcessedAnalytics actions
      .addCase(fetchResumeProcessedAnalytics.fulfilled, (state, action) => {
        state.isFetched = true;

        state.resumeProcessedData = action.payload.map((item) => ({
          date: `${item.year}-${String(item.month).padStart(2, "0")}`,
          resumeCount: item.resumeCount,
        }));
      })
      .addCase(fetchResumeProcessedAnalytics.rejected, (state, action) => {
        state.error = action.payload;
        state.isFetched = true;
      });
  },
});

export const { clearState, updateNewApplicantsData } =
  postedJobsAnalyticsSlice.actions;

export default postedJobsAnalyticsSlice.reducer;
