import React, { useContext, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Modal from "../../../modal/Modal";
import InitialsLogo from "../../../all/InitialsLogo";
import SearchUser from "../../user/SearchEmails";
import InputBox from "../../../../layouts/InputBox";
const core = require("../../../../lib/core");

function FeedbackInviteEmailModal({
  chatDetail,
  companydata,
  positionData,
  modalDismiss,
  job_Id,
  appId,
}) {
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState([]);
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile,
  );
  function generateCandidateHTML(candidates) {
    return candidates
      .map(
        (candidate) => `
      <div class="candidate">
        <div class="candidate-info">
          <div class="candidate-name">${candidate.name}</div>
          <div class="candidate-title">${candidate.title}</div>
        </div>
        <a href="${candidate.viewLink}" class="view-button">View Candidate</a>
      </div>
    `,
      )
      .join("");
  }

  function sendCandidateEmail(candidates) {
    const candidateListHTML = generateCandidateHTML(candidates);

    const htmlContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Candidate List</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              line-height: 1.6;
              color: #333;
              max-width: 600px;
              margin: 0 auto;
              padding: 20px;
          }
          .header {
              text-align: center;
              margin-bottom: 20px;
          }
          .logo {
              max-width: 150px;
              height: auto;
          }
          .company-name {
              font-size: 24px;
              font-weight: bold;
              margin-top: 10px;
          }
          .candidate {
              background-color: #f9f9f9;
              border: 1px solid #ddd;
              padding: 15px;
              margin-bottom: 15px;
              border-radius: 5px;
              display: flex;
              justify-content: space-between;
              align-items: center;
          }
          .candidate-info {
              flex-grow: 1;
          }
          .candidate-name {
              font-size: 18px;
              font-weight: bold;
              margin-bottom: 5px;
          }
          .candidate-title {
              font-style: italic;
              color: #666;
          }
          .view-button {
              display: inline-block;
              background-color: #4CAF50;
              color: white;
              padding: 10px 20px;
              text-decoration: none;
              border-radius: 3px;
              font-weight: bold;
              text-align: center;
              min-width: 120px;
          }
      </style>
  </head>
  <body>
      <div class="header">
          <img src="${""}" alt="${"Sproutsai"} Logo" class="logo">
          <div class="company-name">${"Sproutsai"}</div>
      </div>
  
      <h1>Candidate List</h1>
  
      <p>Here are the latest candidates for your review:</p>
  
      ${candidateListHTML}
  
      <p>If you have any questions, please don't hesitate to contact us.</p>
  
      <p>Best regards,<br>Your Recruitment Team</p>
  </body>
  </html>
    `;

    return htmlContent;
  }

  // Example usage
  const candidates = [
    {
      name: "John Doe",
      title: "Senior Software Engineer",
      viewLink: "https://www.example.com/view/john-doe",
    },
    {
      name: "Jane Smith",
      title: "UX Designer",
      viewLink: "https://www.example.com/view/jane-smith",
    },
    {
      name: "Mike Johnson",
      title: "Product Manager",
      viewLink: "https://www.example.com/view/mike-johnson",
    },
  ];

  // sendCandidateEmail(candidates);

  const { position, jobId } = useSelector((state) => {
    return {
      position: state?.JobExpandSlice?.profile?.position,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (chatDetail?.email?.length > 0) {
      setTo(chatDetail?.email[0]);
    }
  }, [chatDetail]);

  const [emailSuccess, setEmailSuccess] = useState(false);

  useEffect(() => {
    setEditorValue(convertToHTML(editorStateDesc?.getCurrentContent()));
  }, [editorStateDesc]);

  const [sendText, setSendingText] = useState("Send email");

  console.log(sendCandidateEmail(candidates));
  async function sendEmail() {
    setSendingText("Sending...");
    const json = await core.API(
      core.API_METHODS.POST,
      core.RECRUITER_API.POST_EMAIL_CANDIDATE,
      1,
      {
        to: emails,
        cc: selectedOptions,
        body: editorValue,
        subject: subject,
        matchprofile: profile?._id,
        applicant: appId,
        job: jobId,
      },
    );

    if (json) {
      setEmailSuccess(true);
      setSubject();
      setSendingText("Send email");
    }
  }

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setSelectedOptions([...selectedOptions, json?.data?.user?.email]);
      } else {
      }
    } catch (err) {}
  }

  const { user } = useContext(core.UserContext);
  const loc = window.location;
  const [url, setUrl] = useState(`${loc.origin}/feedback/${job_Id}/${appId}`);
  const getTemplate = async () => {
    setSubject("Request for Feedback on Candidate Profile");
    setEditorStateDesc(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(
            `<div class="container">
                        <div class="header">
                            <h1>Feedback Request</h1>
                        </div>
                        <div class="content">
                            <p>Dear Team Member,</p>
                            <p>We hope this email finds you well.</p>
                            <p>We would like to invite you to provide feedback on the profile or interview of our candidate, ${profile?.first_name} ${profile?.last_name}. Your insights and professional evaluation are highly valuable to us.</p>
                            <p>Please click the link below to access the candidate's profile </p>
                            <p>If you have any questions or need further information, please do not hesitate to contact us.</p>
                            <a href="${url}">${url}</a>
                            <p>Thank you for your time and assistance.</p>
                            <p>Best regards,</p>
                            <p>${user?.name}<br></p>
                        </div>
                    </div>`,
          ),
        ),
      ),
    );
  };

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    getTemplate();
    setEmailSuccess(false);
  }, [chatDetail]);

  const [emails, setEmails] = useState([]);

  const addInterviewer = (email) => {
    setEmails([...emails, email]);
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      noBtns={true}
      heading={"Invite for feedback"}
      subheading={"Invite your team member or share this URL for feedback."}
      className={"md:max-w-[60%]"}
    >
      {!emailSuccess ? (
        <>
          <div className="">
            {/* <div className=' mt-[20px]'>
                          <label className=' text-right text-[14px] font-normal not-italic required' htmlFor="">To</label>
                          <input value={to} required className='border  py-[11px] border-[#E1E1EE] bg-[#FFF] px-[20px] rounded-[8px] w-full' type="email"
                              onChange={e => setTo(e.target.value)}
                          />
                      </div> */}
            <div className="  mt-[20px]">
              <label
                className="  text-[14px] font-normal not-italic required"
                htmlFor=""
              >
                Subject
              </label>
              <InputBox
                className="border  border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px]"
                type="text"
                width="100%"
                value={subject}
                onChange={(e) => setSubject(e?.target?.value)}
              />
            </div>
            {/* <div className=" mt-[20px]">
              <label className=" text-[14px] font-normal not-italic" htmlFor="">
                Cc
              </label>
              <div className="relative border flex flex-wrap py-[11px]  border-[#E1E1EE] bg-[#FFF]  rounded-[8px] w-full px-[20px] ">
                {selectedOptions.map((option, index) => (
                  <span
                    className="h-[20px] flex items-center mr-[10px] rounded-[4px] bg-[#F3F5F9] px-[10px]"
                    key={index}
                  >
                    {option}
                    <svg
                      className="cursor-pointer"
                      onClick={(e) => handleRemoveOption(index)}
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M7 7L17 17"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7 17L17 7"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                ))}
                <div className="relative">
                  <input
                    type="email"
                    className="w-auto"
                    value={inputValue}
                    onChange={handleInputChange}
                  />

                  {inputValue?.length > 2 && (
                    <ul className="absolute bg-[#F3F5F9] px-[10px] py-[5px] rounded-[5px] top-[24px]">
                      <li onClick={() => handleOptionClick(inputValue)}>
                        {inputValue}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </div> */}

            {/* <div className="w-full   mt-[20px]">
              <label className=" text-[14px] font-normal not-italic" htmlFor="">
                To
              </label>
              <div className="flex flex-wrap items-center border border-gray-300 rounded-md p-2 focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500">
                {emails.map((email, index) => (
                  <div
                    key={index}
                    className="flex items-center bg-gray-100 rounded-full gap-2 pr-2  m-1 border-2 border-gray-300"
                  >
                    <InitialsLogo
                      str={email}
                      fontSize="12px"
                      width="20px"
                      height="20px"
                      borderRadius="50%"
                    />
                    <span className="text-sm">{email}</span>
                    <button
                      onClick={() => removeEmail(index)}
                      className=" text-gray-500 hover:text-gray-700 focus:outline-none"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <path
                          d="M24 24L8 8M24 8L8 24"
                          stroke="#001B4B"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                      </svg>
                    </button>
                  </div>
                ))}
                <input
                  ref={inputRef}
                  id="email-input"
                  type="text"
                  className="flex-grow outline-none text-sm p-1"
                  placeholder={
                    emails.length === 0 ? "Enter email addresses" : ""
                  }
                  value={inputValue}
                  onChange={handleInputChanges}
                  onKeyDown={handleKeyDown}
                />
              </div>

              {suggestions.length > 0 && (
                <ul className="mt-1 border border-gray-200 rounded-md shadow-sm bg-white">
                  {suggestions.map((suggestion, index) => (
                    <li
                      key={index}
                      className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm"
                      onClick={() => addEmail(suggestion)}
                    >
                      {suggestion}
                    </li>
                  ))}
                </ul>
              )}
            </div> */}

            <SearchUser label={"To"} emails={emails} setEmails={setEmails} />

            <div className="mt-[20px]" id="description">
              <label
                className=" text-right text-[14px] mt-[15px] font-normal not-italic"
                htmlFor=""
              >
                Body
              </label>
              <Editor
                editorState={editorStateDesc}
                onEditorStateChange={setEditorStateDesc}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
              />
            </div>
          </div>

          <div className="flex justify-end mt-[20px]">
            <button
              disabled={
                sendText === "Sending..." ||
                editorValue === "Generating message..." ||
                subject?.length === 0 ||
                emails.length === 0
                  ? true
                  : false
              }
              onClick={sendEmail}
              className={` px-[20px] h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal ${
                sendText === "Sending..." ||
                editorValue === "Generating message..." ||
                subject?.length === 0 ||
                emails.length === 0
                  ? "opacity-50 "
                  : "solid-button-blue"
              }`}
            >
              {sendText}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col items-center justify-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
            >
              <rect width="60" height="60" rx="20" fill="#F3F9E6" />
              <rect
                x="7"
                y="7"
                width="45.3659"
                height="45.3659"
                rx="12"
                fill="#80BB01"
              />
              <path
                d="M29.1061 31.9281L30.0096 32.8359C30.4005 33.2288 31.0363 33.2288 31.4272 32.8359L39.5742 24.6493C40.0216 24.1998 40.7492 24.1998 41.1966 24.6493C41.6409 25.0958 41.6409 25.8174 41.1966 26.2639L31.7652 35.7412C31.1879 36.3213 30.2489 36.3213 29.6716 35.7412L24.2549 30.2982C23.8106 29.8517 23.8106 29.1301 24.2549 28.6836C24.7023 28.2341 25.43 28.2341 25.8774 28.6836L27.4926 30.3068L29.1061 31.9281ZM29.9094 29.4881C29.4663 29.0429 29.4663 28.3234 29.9093 27.8782L33.9539 23.813C34.4 23.3647 35.1257 23.3646 35.5719 23.8129C36.0149 24.2581 36.0149 24.9776 35.5719 25.4229L31.5273 29.488C31.0812 29.9364 30.3555 29.9364 29.9094 29.4881ZM25.0775 34.3651C25.5215 34.8113 25.5215 35.5323 25.0775 35.9785C24.6304 36.4278 23.9033 36.4278 23.4562 35.9785L17.8034 30.2982C17.3591 29.8517 17.3594 29.1298 17.8038 28.6833C18.2507 28.2341 18.9781 28.2338 19.4251 28.6829L20.2275 29.4892C20.2278 29.4895 20.2278 29.49 20.2275 29.4904C20.2272 29.4907 20.2272 29.4912 20.2275 29.4915L25.0775 34.3651Z"
                fill="white"
                stroke="white"
                strokeWidth="0.2"
              />
            </svg>
            <span className="text-[#16182F] text-24 font-semibold not-italic leading-[32px]">
              Email successfully sent to {chatDetail?.first_name}
            </span>
          </div>
          <div className="mt-[28px] flex justify-center">
            <button
              className="bg-bg1 rounded-[8px] py-[12px] px-[16px]"
              onClick={(e) => {
                setEmailSuccess(false);
                modalDismiss();
              }}
              data-dismiss="modal"
            >
              <span className="text-[#252E4B] text-[16px] font-normal not-italic flex items-center">
                <svg
                  className="mr-[5px]"
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M7.97508 5.65625L2.91675 10.7146L7.97508 15.7729"
                    stroke="#252E4B"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M17.0834 10.7188H3.05835"
                    stroke="#252E4B"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
                Back to job{" "}
              </span>
            </button>
          </div>
        </>
      )}
    </Modal>
  );
}

export default FeedbackInviteEmailModal;
