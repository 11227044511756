import { createSlice } from "@reduxjs/toolkit";
import { generateVoiceTokenService } from "../services/voiceService";

const initialState = {
  token: null,
  loading: false,
  error: null,
};

export const voiceSlice = createSlice({
  name: "voiceSlice",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateVoiceTokenService.pending, (state) => {
        state.loading = true;
      })
      .addCase(generateVoiceTokenService.fulfilled, (state, action) => {
        state.loading = false;
        state.token = action.payload;
      })
      .addCase(generateVoiceTokenService.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default voiceSlice.reducer;
