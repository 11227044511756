import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router-dom";
import TabButtons from "./sidebar/TabButtons";
const core = require("../../../lib/core");

const TemplateList = ({
  templates,
  showTabsItem,
  setShowTabsItem,
  setTemplates,
  loading,
  fetchMoreData,
  loadingMore,
}) => {
  const nav = useNavigate();
  const openId = useParams().id;

  const [hoverId, setHoverId] = useState(null);

  const addfvt = async (id) => {
    setTemplates((prev) =>
      prev.map((item) =>
        item._id === id ? { ...item, favorite: !item.favorite } : item,
      ),
    );
  };

  const update_template = async (id, value) => {
    try {
      const list = await core.API(
        core.API_METHODS.PUT,
        core.TEMPLATE_API.PUT_UPDATE_JOB_TEMPLATE + id,
        1,
        {
          favourite: value,
        },
      );

      if (list?.data) {
        addfvt(id);
      }
    } catch (error) {}
  };

  function dateFormat(date) {
    return (
      new Date(date).toString().split(" ")[2] +
      " " +
      new Date(date).toString().split(" ")[1] +
      ", " +
      new Date(date).toString().split(" ")[3]
    );
  }

  console.log(showTabsItem);
  const AccordianItem = ({ title, children }) => {
    return (
      <>
        <div className="accordion">
          <div
            className={`w-full px-[5px] py-[8.5px] flex items-center justify-between bg-white rounded-lg h-10 hover:border-blue-800 cursor-pointer ${
              showTabsItem === "Personal" ? "border-blue-800" : ""
            }`}
            onClick={() =>
              setShowTabsItem(showTabsItem === title ? null : title)
            }
          >
            <span>{title}</span>
            <span className={showTabsItem === title ? "-scale-y-100" : ""}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.02346 8.70379L7.9348 10.8039L9.10185 12.0927C9.59606 12.6358 10.3999 12.6358 10.8941 12.0927L13.9784 8.7038C14.3833 8.25891 14.0916 7.5 13.5259 7.5L10.1855 7.5L6.47599 7.5C5.90438 7.5 5.61857 8.25891 6.02346 8.70379Z"
                  fill="#8D98B1"
                />
              </svg>
            </span>
          </div>
          {showTabsItem === title && (
            <div className="accordion-content">
              <div className="w-full grow divide-y overflow-y-scroll scrollbar-hide">
                {loading ? (
                  <div>
                    <div className="flex items-center justify-center h-full">
                      <li
                        className={`grid grid-rows-2 grid-cols-[40px_1fr] py-[6px] mb-2`}
                      >
                        <span className="row-span-2 w-8 h-8">
                          <Skeleton width={"32px"} height={"32px"} />
                        </span>

                        <span className="w-full h-3">
                          <Skeleton width={"200px"} height={10} />
                        </span>
                        <span className="w-full h-3">
                          <Skeleton width={"100px"} height={10} />
                        </span>
                      </li>
                    </div>
                    <div className="flex items-center justify-center h-full">
                      <li
                        className={`grid grid-rows-2 grid-cols-[40px_1fr] py-[6px] mb-2`}
                      >
                        <span className="row-span-2 w-8 h-8">
                          <Skeleton width={"32px"} height={"32px"} />
                        </span>

                        <span className="w-full h-3">
                          <Skeleton width={"200px"} height={10} />
                        </span>
                        <span className="w-full h-3">
                          <Skeleton width={"100px"} height={10} />
                        </span>
                      </li>
                    </div>
                  </div>
                ) : (
                  <>
                    {templates &&
                      templates.map((template, index) => (
                        <div
                          onMouseEnter={() => setHoverId(template?._id)}
                          onMouseLeave={() => setHoverId(null)}
                          key={index}
                          onClick={() => nav(template?._id)}
                          style={
                            template?._id === openId
                              ? { backgroundColor: "#F2F4F8" }
                              : { backgroundColor: "white" }
                          }
                          className="w-[254px] grow h-auto flex flex-col cursor-pointer p-2 bg-white hover:bg-gray-300 border-gray-500"
                        >
                          <div className="flex items-center justify-between">
                            <span
                              className="text-sm w-[200px] not-italic text-gray-800 truncate font-semibold"
                              title={template?.template_name}
                            >
                              {template?.template_name}
                            </span>
                            {!template?.favorite ? (
                              <>
                                {hoverId === template?._id && (
                                  <button
                                    className="z-[20]"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      update_template(
                                        template?._id,
                                        !template?.favorite,
                                      );
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="15"
                                      height="15"
                                      viewBox="0 0 22 22"
                                      fill="none"
                                    >
                                      <path
                                        d="M9.03281 2.27141C9.8375 0.576198 12.1625 0.576195 12.9672 2.27141L14.3579 5.20118C14.6774 5.87435 15.2951 6.34094 16.0096 6.44888L19.1193 6.91869C20.9187 7.19053 21.6371 9.48954 20.3351 10.8091L18.0849 13.0896C17.5679 13.6136 17.332 14.3685 17.454 15.1084L17.9852 18.3285C18.2926 20.1918 16.4116 21.6126 14.8022 20.7329L12.0208 19.2126C11.3817 18.8633 10.6183 18.8633 9.97917 19.2126L7.19776 20.7329C5.58839 21.6126 3.70742 20.1918 4.01479 18.3286L4.54599 15.1084C4.66804 14.3685 4.43211 13.6136 3.91508 13.0896L1.66488 10.8091C0.362866 9.48954 1.08133 7.19053 2.88066 6.91869L5.99037 6.44888C6.70489 6.34094 7.32257 5.87435 7.64211 5.20118L9.03281 2.27141Z"
                                        stroke="#252E4B"
                                        strokeWidth="1.5"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </button>
                                )}
                              </>
                            ) : (
                              <button
                                className="z-[20]"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  update_template(
                                    template?._id,
                                    !template?.favorite,
                                  );
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  stroke="#5F6989"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                >
                                  <path
                                    d="M8.03281 1.27141C8.8375 -0.423802 11.1625 -0.423805 11.9672 1.27141L13.3579 4.20118C13.6774 4.87435 14.2951 5.34094 15.0096 5.44888L18.1193 5.91869C19.9187 6.19053 20.6371 8.48954 19.3351 9.80908L17.0849 12.0896C16.5679 12.6136 16.332 13.3685 16.454 14.1084L16.9852 17.3285C17.2926 19.1918 15.4116 20.6126 13.8022 19.7329L11.0208 18.2126C10.3817 17.8633 9.61825 17.8633 8.97917 18.2126L6.19776 19.7329C4.58839 20.6126 2.70742 19.1918 3.01479 17.3286L3.54599 14.1084C3.66804 13.3685 3.43211 12.6136 2.91508 12.0896L0.664875 9.80908C-0.637134 8.48954 0.0813282 6.19053 1.88066 5.91869L4.99037 5.44888C5.70489 5.34094 6.32257 4.87435 6.64211 4.20118L8.03281 1.27141Z"
                                    fill="#5F6989"
                                  />
                                </svg>
                              </button>
                            )}
                          </div>
                          <span className="inline-block text-sm text-[#5F6989] truncate">
                            {"Updated : " + dateFormat(template?.updatedAt)}
                          </span>
                        </div>
                      ))}
                    {templates.length >= 20 && (
                      <div className="flex ">
                        <button
                          onClick={fetchMoreData}
                          className="px-[18px] mt-[20px] mb-[20px] flex items-center"
                          disabled={loadingMore}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.41797 7.5L10.0013 12.0704L14.5846 7.5"
                              stroke="#252E4B"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="text-gray-800 ml-[8px] leading-[28px] text-[14px] not-italic font-normal">
                            See more
                          </span>
                        </button>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  };

  let templateCategories = [
    { label: "Personal" },
    { label: "Organization" },
    { label: "Shared" },
    { label: "Archived" },
  ];

  return (
    <>
      <div className="w-full relative mt-[16px] block grow overflow-y-scroll scrollbar-hide">
        {templateCategories?.map((tab, index) => {
          return <AccordianItem key={index} title={tab.label}></AccordianItem>;
        })}
      </div>
      <TabButtons />
    </>
  );
};

export default TemplateList;
