import React, { useState } from "react";
import Modal from "../../modal/Modal";
import InputBox from "../../../layouts/InputBox";

function InterviewEvaluationModal({ modalDismiss }) {
  const onCancel = () => {
    modalDismiss();
  };
  const modalElements = {
    heading: "Interview evaluation plan",
    // subheading: "Duplicate an existing job for easy replication and modification",
    modalDismiss: () => onCancel(),
    // defaultButtons:{
    //     primaryLabel: loading ? (
    //         <ScaleLoader height={14} color="#ffffff" loading={true} />
    //       ) : (
    //         "Clone job"
    //       ),
    //     secLabel: "Cancel",
    // },
    onSave: () => {
      clonesimilerjob();
    },
  };

  const data = [
    {
      "Phone Interview": {},
    },
    {
      "Online Test": {
        evaluationPlan: {
          start: "Medium",
          highLevel: 8,
          lowerLevel: 4,
        },
        jobSkills: [
          {
            SkillCategory: "Data Science, Machine Learning",
            Skills: {
              Pandas: 3,
              TensorFlow: 3,
              "Python Programming": 4,
            },
            Questions: 10,
          },
          {
            SkillCategory: "Web Development",
            Skills: {
              "Node.js": 3,
              "React.js": 3,
              JavaScript: 4,
            },
            Questions: 10,
          },
        ],
        totalDuration: 20,
      },
    },
    {
      "Coding Assessment": {},
    },
    {
      "Technical Round": {
        evaluationPlan: {
          start: "Medium",
          highLevel: 8,
          lowerLevel: 4,
        },
        jobSkills: [
          {
            SkillCategory: "Data Science, Machine Learning",
            Skills: {
              Pandas: 3,
              TensorFlow: 3,
              "Python Programming": 4,
            },
            Questions: 10,
          },
          {
            SkillCategory: "Web Development",
            Skills: {
              "Node.js": 3,
              "React.js": 3,
              JavaScript: 4,
            },
            Questions: 10,
          },
        ],
      },
    },
    {
      "System Design": {
        number_of_question: 2,
      },
    },
    {
      "Hiring Manager Interview": {},
    },
  ];

  const [editedData, setEditedData] = useState(data);

  const handleInputChange = (round, category, skill, value) => {
    setEditedData((prevData) => {
      const updatedData = prevData?.map((roundItem) => {
        if (Object?.keys(roundItem)[0] === round) {
          const roundData = roundItem[round];
          const updatedSkills = roundData?.jobSkills?.map((skillItem) => {
            if (skillItem.SkillCategory === category) {
              const updatedSkillsObj = {
                ...skillItem.Skills,
                [skill]: parseInt(value, 10),
              };
              return { ...skillItem, Skills: updatedSkillsObj };
            }
            return skillItem;
          });
          return {
            ...roundItem,
            [round]: { ...roundData, jobSkills: updatedSkills },
          };
        }
        return roundItem;
      });
      return updatedData;
    });
  };

  const handleUpdateSkills = () => {
    // onUpdateSkills(editedData);
  };

  const handleDeleteCriteria = (round, category) => {
    setEditedData((prevData) => {
      const updatedData = prevData?.map((roundItem) => {
        if (Object.keys(roundItem)[0] === round) {
          const roundData = roundItem[round];
          const updatedSkills = roundData?.jobSkills.filter(
            (skillItem) => skillItem.SkillCategory !== category,
          );
          return {
            ...roundItem,
            [round]: { ...roundData, jobSkills: updatedSkills },
          };
        }
        return roundItem;
      });
      return updatedData;
    });
  };

  const handleDeleteSkill = (round, category, skill) => {
    setEditedData((prevData) => {
      const updatedData = prevData?.map((roundItem) => {
        if (Object.keys(roundItem)[0] === round) {
          const roundData = roundItem[round];
          const updatedSkills = roundData?.jobSkills?.map((skillItem) => {
            if (skillItem.SkillCategory === category) {
              const updatedSkillsObj = { ...skillItem.Skills };
              delete updatedSkillsObj[skill];
              return { ...skillItem, Skills: updatedSkillsObj };
            }
            return skillItem;
          });
          return {
            ...roundItem,
            [round]: { ...roundData, jobSkills: updatedSkills },
          };
        }
        return roundItem;
      });
      return updatedData;
    });
  };

  const handleDeleteRound = (round) => {
    setEditedData((prevData) =>
      prevData.filter((roundItem) => Object.keys(roundItem)[0] !== round),
    );
  };

  const [showItems, setShowItems] = useState(false);

  return (
    <Modal {...modalElements} className={"md:max-w-[60%]"}>
      <div>
        {editedData?.map((roundItem) => {
          const round = Object.keys(roundItem)[0];
          const roundData = roundItem[round];
          return (
            <div
              key={round}
              className="border-b-[2px] border-b-[#EFF0F2] py-[20px]"
            >
              <h3
                className="text-black text-base cursor-pointer flex items-center font-medium not-italic"
                onClick={() => {
                  if (showItems === round) {
                    setShowItems(false);
                  } else {
                    setShowItems(round);
                  }
                }}
              >
                {round}
                <svg
                  className="cursor-pointer ml-[10px]"
                  onClick={() => handleDeleteRound(round)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="14"
                  viewBox="0 0 12 14"
                  fill="none"
                >
                  <path
                    d="M10.0833 5.25033L9.66572 10.6793C9.57221 11.8949 8.55851 12.8337 7.33926 12.8337H4.66074C3.44149 12.8337 2.42779 11.8949 2.33428 10.6793L1.91667 5.25033M11.25 4.08366C9.73455 3.34515 7.93296 2.91699 6 2.91699C4.06704 2.91699 2.26545 3.34515 0.75 4.08366M4.83333 2.91699V2.33366C4.83333 1.68933 5.35567 1.16699 6 1.16699C6.64433 1.16699 7.16667 1.68933 7.16667 2.33366V2.91699M4.83333 6.41699V9.91699M7.16667 6.41699V9.91699"
                    stroke="#FF475D"
                    strokeLinecap="round"
                  />
                </svg>
                {/* <svg className='cursor-pointer ml-[10px]' onClick={() => 
                  setShowItems(round)} xmlns="http://www.w3.org/2000/svg" width="12" height="14" viewBox="0 0 12 14" fill="none">
                  <path d="M1.5 4.5L6 9M10.5 4.5L6 9" stroke="#000" stroke-linecap="round" />
                </svg> */}
              </h3>

              {showItems === round && (
                <>
                  {roundData?.jobSkills?.map((skillItem) => (
                    <div key={skillItem?.SkillCategory}>
                      <h4 className="text-black text-sm font-normal flex items-center not-italic mt-[20px] mb-[5px]">
                        {skillItem.SkillCategory}
                        <svg
                          className="cursor-pointer ml-[10px]"
                          onClick={() =>
                            handleDeleteCriteria(round, skillItem.SkillCategory)
                          }
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="14"
                          viewBox="0 0 12 14"
                          fill="none"
                        >
                          <path
                            d="M10.0833 5.25033L9.66572 10.6793C9.57221 11.8949 8.55851 12.8337 7.33926 12.8337H4.66074C3.44149 12.8337 2.42779 11.8949 2.33428 10.6793L1.91667 5.25033M11.25 4.08366C9.73455 3.34515 7.93296 2.91699 6 2.91699C4.06704 2.91699 2.26545 3.34515 0.75 4.08366M4.83333 2.91699V2.33366C4.83333 1.68933 5.35567 1.16699 6 1.16699C6.64433 1.16699 7.16667 1.68933 7.16667 2.33366V2.91699M4.83333 6.41699V9.91699M7.16667 6.41699V9.91699"
                            stroke="#FF475D"
                            strokeLinecap="round"
                          />
                        </svg>
                      </h4>
                      <div className="grid grid-cols-2 gap-4 border border-[#EFF0F2] p-[20px] rounded-lg">
                        {Object.entries(skillItem?.Skills)?.map(
                          ([skill, value]) => (
                            <div key={skill}>
                              <label className="text-sm font-normal flex items-center text-gray-800 not-italic">
                                {skill}
                                <button
                                  className="ml-[10px]"
                                  onClick={() =>
                                    handleDeleteSkill(
                                      round,
                                      skillItem.SkillCategory,
                                      skill,
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="14"
                                    viewBox="0 0 12 14"
                                    fill="none"
                                  >
                                    <path
                                      d="M10.0833 5.25033L9.66572 10.6793C9.57221 11.8949 8.55851 12.8337 7.33926 12.8337H4.66074C3.44149 12.8337 2.42779 11.8949 2.33428 10.6793L1.91667 5.25033M11.25 4.08366C9.73455 3.34515 7.93296 2.91699 6 2.91699C4.06704 2.91699 2.26545 3.34515 0.75 4.08366M4.83333 2.91699V2.33366C4.83333 1.68933 5.35567 1.16699 6 1.16699C6.64433 1.16699 7.16667 1.68933 7.16667 2.33366V2.91699M4.83333 6.41699V9.91699M7.16667 6.41699V9.91699"
                                      stroke="#FF475D"
                                      strokeLinecap="round"
                                    />
                                  </svg>
                                </button>
                              </label>
                              <InputBox
                                type="number"
                                size="small"
                                value={value}
                                onChange={(e) =>
                                  handleInputChange(
                                    round,
                                    skillItem.SkillCategory,
                                    skill,
                                    e.target.value,
                                  )
                                }
                              />
                            </div>
                          ),
                        )}
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          );
        })}
      </div>
    </Modal>
  );
}

export default InterviewEvaluationModal;
