import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");
// Async thunk action creators

export const generateVoiceTokenService = createAsyncThunk(
  "voice/generateVoiceToken",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.POST,
      core.VOICE_API.GENERATE_VOICE_TOKEN,
      1,
      payload,
    );
    // Async logic for leaving a Chime meeting
  },
);
