import { useState, useEffect } from "react";

export default function ExperienceForm({ input_function, formState }) {
  const [checked, setChecked] = useState(formState?.ongoing ?? false);

  const date = new Date();
  const [todaysDate, setTodaysDate] = useState("");
  useEffect(() => {
    setTodaysDate(
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()),
    );
  }, [todaysDate, formState]);

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  return (
    <form className="col-xs-12 no-padding" id="experience">
      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding required" htmlFor="company">
          Company name
        </label>
        <input
          type="text"
          className="input col-xs-12 col-md-12"
          name="company"
          value={formState?.company}
          required="required"
          placeholder="Type to search... "
          onChange={(event) => {
            input_function("company", event.target.value);
          }}
        />
      </div>
      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding required" htmlFor="position">
          Occupation
        </label>
        <input
          type="text"
          className="input col-xs-12 col-md-12"
          name="position"
          required="required"
          placeholder="Title"
          value={formState?.position}
          onChange={(event) => {
            input_function("position", event.target.value);
          }}
        />
      </div>
      <div className="col-md-6 no-padding">
        <label htmlFor="start" className="col-xs-12 no-padding required">
          Start date
        </label>
        <input
          className="col-md-12"
          type="date"
          name="start"
          required="required"
          max={todaysDate}
          value={formState?.start?.split("T")[0]}
          onChange={(event) => {
            if (new Date(event.target.value) < new Date()) {
              input_function("start", event.target.value);
            }
          }}
        />
      </div>
      <div className="col-md-6 no-padding">
        <label htmlFor="end" className="col-xs-12 no-padding">
          End date
        </label>
        <input
          className="col-xs-12 no-padding"
          type="date"
          name="final"
          required={!checked}
          disabled={checked}
          min={formState?.start}
          max={todaysDate}
          value={
            formState?.end?.split("T")[0] ?? formState?.eend?.split("T")[0]
          }
          onChange={(event) => {
            if (new Date(event.target.value) < new Date()) {
              input_function("eend", event.target.value);
              input_function("end", event.target.value);
            }
          }}
        />
        <div className="col-xs-12 no-padding checkbox-input">
          <input
            type="checkbox"
            value={formState?.ongoing}
            onChange={(event) => {
              setChecked(event.target.checked);
              input_function("ongoing", event.target.checked);
            }}
          />
          <label htmlFor="checkbox">I currently work here</label>
        </div>
      </div>

      <div className="col-xs-12 no-padding">
        <label className="col-xs-12 no-padding" htmlFor="description">
          Description
        </label>
        <textarea
          name="description"
          value={formState?.description}
          className="description col-xs-12 no-padding"
          cols="30"
          rows="2"
          placeholder="Description..."
          onChange={(event) => {
            input_function("description", event.target.value);
          }}
        ></textarea>
      </div>
    </form>
  );
}
