import { useContext } from "react";
import CreateCandidateProfile from "../candidate/CreateCandidate";
// import CreateRecruiterProfile from '../recruiter/CreateRecruiterProfile';
import Persional from "../../components/auth/Personal";

const core = require("../../lib/core");

export default function CreateProfile() {
  const { user, setUser } = useContext(core.UserContext);
  if (user?.account === 1) {
    // return <CreateRecruiterProfile/>
    return <Persional />;
  } else {
    return <CreateCandidateProfile />;
  }
}
