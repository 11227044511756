import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");

export const fetchPostedJobs = createAsyncThunk(
  "postedJobsAnalytics/fetchPostedJobs",
  async (_, { rejectWithValue }) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_ACTIVE_JOBS_Analytics,
        // `http://localhost:3333/recruiter/active_job_details_analytics`,
      );
      const data = response?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchJDProcessedAnalytics = createAsyncThunk(
  "postedJobsAnalytics/fetchJDProcessedAnalytics",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_JD_PROCESSED_ANALYTICS + "/" + payload?.organizationId,
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchResumeProcessedAnalytics = createAsyncThunk(
  "postedJobsAnalytics/fetchResumeProcessedAnalytics",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_RESUME_PROCESSED_ANALYTICS + payload?.organizationId,
      );
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

export const fetchAllNewApplicantAnalytics = createAsyncThunk(
  "postedJobsAnalytics/fetchAllNewApplicantAnalytics",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_ALL_NEW_APPLICANT_ANALYTICS +
          "?page=" +
          (payload.page ?? 1),
      );
      const data = response?.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
