import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchJobListByCompany } from "../services/JobBoardService";

const initialState = {
  jobs: [],
};

const jobExpandSlice = createSlice({
  name: "jobList",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobListByCompany.pending, (state, action) => {
        state.jobs = [];
        state.isLoading = true;
      })
      .addCase(fetchJobListByCompany.fulfilled, (state, action) => {
        state.jobs = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchJobListByCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.jobs = [];
      });
  },
});

// Export actions
export const {} = jobExpandSlice.actions;

export default jobExpandSlice.reducer;
