import CheckGreen from "./CheckGreen";
import IconInvite from "./IconInvite";
import UndefinedRed from "./UndefinedRed";
import VideoIcons from "./VideoIcons";
import Plus from "./Plus";
import Minus from "./Minus";
import Delete from "./Delete";
import Stars from "./Stars";
import Copy from "./Copy";
import ChevronUp from "./ChevronUp";
import ChevronDown from "./ChevronDown";

export const ICONS = {
  invite: IconInvite,
  VideoIcon: VideoIcons,
  CheckGreen: CheckGreen,
  UndefinedRed: UndefinedRed,
  Plus: Plus,
  Minus: Minus,
  Delete: Delete,
  Stars: Stars,
  Copy: Copy,
  ChevronUp: ChevronUp,
  ChevronDown: ChevronDown,
};
