import React, { useRef, useState } from "react";
import Modal from "../../../components/modal/Modal";
import VideoRecording from "../../../components/recruiter/job/assessment/VideoRecording";

function CameraAssess({ startMeeting, modalDismiss }) {
  const [isCameraOn, setIsCameraOn] = useState(false);
  const [isMicOn, setIsMicOn] = useState(false);

  const mediaRecorderRef = useRef(null);
  const [stream, setStream] = useState(null);
  const [recordedDataChunks, setRecordedDataChunks] = useState([]);
  const [isRecording, setIsRecording] = useState(false);

  return (
    <Modal
      heading={"Camera and Mic Test"}
      subheading={
        "Please ensure that your camera and microphone are working properly."
      }
      onSave={(e) => {
        document.documentElement.requestFullscreen();
        setTimeout(function () {
          startMeeting();
        }, 1000);
      }}
      defaultButtons={{
        primaryDisabled: isCameraOn && isMicOn ? false : true,
        primaryLabel: "Start Meeting",
      }}
      modalDismiss={modalDismiss}
    >
      <div>
        <VideoRecording
          isCameraOn={isCameraOn}
          setIsCameraOn={setIsCameraOn}
          isMicOn={isMicOn}
          setIsMicOn={setIsMicOn}
          defaultcamera={false}
          defaultmic={false}
          icons={true}
          mediaRecorderRef={mediaRecorderRef}
          stream={stream}
          setStream={setStream}
          recordedChunks={recordedDataChunks}
          setRecordedChunks={setRecordedDataChunks}
          isRecording={isRecording}
          setIsRecording={setIsRecording}
        />
      </div>
    </Modal>
  );
}

export default CameraAssess;
