import React, { useContext, useEffect, useMemo, useState } from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import { toast } from "react-toastify";
import CreateRoleModal from "./CreatRoleModal";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
const core = require("../../lib/core");

function Admin({ user }) {
  const nav = useNavigate();

  const { role } = useContext(core.UserContext);

  useEffect(() => {
    if (!(role?.includes("Super Admin") || role?.includes("Admin"))) {
      nav("/");
    }
  }, []);

  const [open, setOpen] = useState(true);
  const [allRoles, setAllRoles] = useState([]);
  const [selectRole, setSelectRole] = useState();
  const [tab, setTab] = useState("role");

  const loc = useLocation();
  const [loading, setLoading] = useState(false);

  const Get_all_roles = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_ALL_ROLES,
        1,
      );
      if (json.data) {
        setAllRoles(json?.data);
        setSelectRole(json?.data[0]);
        setLoading(false);
        setShowModal(false);
        if (loc.pathname.split("/").at(-1) === "systemadmin") {
          nav(`/systemadmin/role/${json?.data[0]?.id}`);
        }
        // nav(`/systemadmin/role/${json?.data[0]?.id}`)
      } else {
        setLoading(false);
        setShowModal(false);
      }
    } catch (error) {
      setLoading(false);
      setShowModal(false);
    }
  };

  const [users, setUsers] = useState([]);

  const Get_users_with_role = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_USERS_WITH_ROLE,
      1,
    );
    if (json.data) {
      setUsers(json?.data);
    } else {
    }
  };

  useEffect(() => {
    Get_resources(selectRole?.id);
  }, [selectRole]);

  const [resources, setResources] = useState([]);

  const Get_resources = async (id) => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_ALL_RESOURCES + id,
      1,
    );
    if (json.data) {
      setResources(json?.data);
    } else {
    }
  };

  const Update_resources = async (id) => {
    const json = await core.API(
      core.API_METHODS.PUT,
      core.USER_API.GET_ALL_RESOURCES,
      1,
      {
        roleId: selectRole?.id,
        resourceIds: resources
          ?.filter((item) => item?.checked === true)
          .map((iter) => iter?.id),
      },
    );
    if (json.data) {
      toast.success("Role updated successfully");
    } else {
    }
  };

  useEffect(() => {
    Get_all_roles();
    Get_users_with_role();
  }, []);

  const handleCheckboxChange = (id) => {
    setResources((prevState) =>
      prevState.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, checked: !checkbox.checked }
          : checkbox,
      ),
    );
  };

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="">
      {showModal && (
        <CreateRoleModal
          modalDismiss={() => {
            setShowModal(false);
          }}
          Get_all_roles={Get_all_roles}
          loading={loading}
          setLoading={setLoading}
        />
      )}
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="systemadmin"
          open={open}
          setOpen={setOpen}
          CandidateFilter={
            <RoleList
              setShowModal={setShowModal}
              allRoles={allRoles}
              role={selectRole}
              setRole={setSelectRole}
              tab={tab}
              setTab={setTab}
              user={user}
            />
          }
        />
        <div className="w-full">
          <HeaderNew />
          <AdminContent
            allRoles={allRoles}
            resources={resources}
            role={selectRole}
            handleCheckboxChange={handleCheckboxChange}
            tab={tab}
            setTab={setTab}
            users={users}
            Update_resources={Update_resources}
          />
        </div>
      </div>
    </div>
  );
}

export default Admin;

function AdminContent({}) {
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      <div className="bg-[#FFF] rounded-[16px] p-[18px] h-[calc(100vh-130px)] relative">
        <Outlet />
      </div>
    </main>
  );
}

function RoleList({ allRoles, user, setRole, tab, setShowModal }) {
  const [open, setOpen] = useState(true);
  const nav = useNavigate();
  const loc = useLocation();

  return (
    <div className="mt-[26px] relative  h-[calc(100vh-134px)] ">
      <div className="relative w-[240px] flex   flex-col ">
        {/* {user?.roles?.includes("Super Admin") && ( */}
        <button className="solid-button-blue w-full  h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center">
          <span
            className="text-[#FFF] text-[16px] not-italic font-normal"
            onClick={(e) => setShowModal(true)}
          >
            Create new role
          </span>
        </button>
        {/* )} */}

        <div className=" h-[calc(100vh-194px)] overflow-scroll scrollbar-candidate">
          {/* {user?.roles?.includes("Super Admin") && ( */}
          <>
            <div
              className="flex justify-between items-center mt-[20px] px-[12px] "
              onClick={(e) => {
                setOpen(!open);
              }}
            >
              <h1 className="inline-block text-[14px] not-italic font-normal text-gray-800">
                Roles
              </h1>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M6.02346 8.70379L7.9348 10.8039L9.10185 12.0927C9.59606 12.6358 10.3999 12.6358 10.8941 12.0927L13.9784 8.7038C14.3833 8.25891 14.0916 7.5 13.5259 7.5L10.1855 7.5L6.47599 7.5C5.90438 7.5 5.61857 8.25891 6.02346 8.70379Z"
                  fill="#8D98B1"
                />
              </svg>
            </div>
            {open && (
              <div className="flex flex-col w-full ">
                {allRoles?.map((item) => (
                  <button
                    key={item?.id}
                    onClick={(e) => {
                      setRole(item);
                      nav(`/systemadmin/role/${item?.id}`);
                    }}
                    className={`px-[12px] py-[12px] rounded-[8px] w-full ${item?.id === loc.pathname.split("/").at(-1) ? "bg-[#F2F4F8] font-semibold" : "font-medium"} hover:bg-[#F2F4F8] mt-[10px] inline-block text-sm not-italic  text-gray-800 truncate  text-left`}
                  >
                    {item?.name}
                    {item?.access === false ? (
                      <span className="ml-2 text-xs font-regular text-emerald-500">
                        {" "}
                        SproutsAI
                      </span>
                    ) : (
                      <></>
                    )}
                  </button>
                ))}
              </div>
            )}
            <div className="border border-gray-400 mt-[10px]"></div>
          </>
          {/* // )} */}

          <button
            onClick={(e) => nav("/systemadmin/users")}
            className={`px-[12px] rounded-lg w-full  hover:bg-[#F2F4F8] mt-[10px]  ${loc.pathname.split("/").at(-1) === "users" ? "bg-[#F2F4F8]" : ""} py-[8px] inline-block text-[14px] not-italic font-normal text-gray-800  text-left`}
          >
            {"Users"}
          </button>
          {user?.roles?.includes("Super Admin") && (
            <>
              <div className="border border-gray-400 mt-[10px]"></div>
              <button
                onClick={(e) => nav("/systemadmin/new-appearence")}
                className={`px-[12px] rounded-[8px] w-full  hover:bg-[#F2F4F8] mt-[10px]  font-normal ${loc.pathname.split("/").at(-1) === "new-appearence" ? "bg-[#F2F4F8]" : ""} py-[8px] inline-block text-[14px] not-italic font-normal text-gray-800  text-left`}
              >
                {"New appearence"}
              </button>
              <div className="border border-gray-400 mt-[10px]"></div>
            </>
          )}
          {/* <button
                        onClick={e => nav("/systemadmin/template-manager")}
                        className={`px-[12px] rounded-[8px] w-full  hover:bg-[#F2F4F8] mt-[10px]  font-normal ${loc.pathname.split("/").at(-1) === "template-manager" ? "bg-[#F2F4F8]" : ""} py-[8px] inline-block text-[14px] not-italic font-normal text-gray-800  text-left`}>{"Template manager"}
                    </button> */}
        </div>
      </div>
    </div>
  );
}
