import InputBox from "../../../../layouts/InputBox";
import Modal from "../../../modal/Modal";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { fetchJobDetails } from "../../../../redux/services";
import { toast } from "react-toastify";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");
const IconClock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="w-4 h-4"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
  >
    <circle cx="12" cy="12" r="10" />
    <polyline points="12 6 12 12 16 14" />
  </svg>
);

const EditAssessment = ({ data, modalDismiss, miniReload, edit }) => {
  const [assessmentPlan, setAssessmentPlan] = useState({
    name: "",
    duration: "",
    totalQuestions: "",
    mcq: { topics: {}, score: 0, selected: false },
    descriptive: { topics: {}, score: 0, selected: false },
    coding: {
      topics: {},
      score: 0,
      level: "",
      selected: false,
      totalQuestions: 0,
    },
  });

  const { jobId } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (edit) {
      autofillData();
    }
  }, [data, edit, jobId]);

  const autofillData = () => {
    setAssessmentPlan((prev) => ({
      ...prev,
      name: data.name || "",
      duration: data.duration || "",
      totalQuestions: data.totalQuestions || "",
      mcq: {
        topics: data.mcqConfig?.topics || {},
        score: Number(data.mcqConfig?.score) || 0,
        selected: data.mcqConfig?.selected || false,
      },
      coding: {
        topics: data.codingConfig?.topics || {},
        score: Number(data.codingConfig?.score) || 0,
        level: data.codingConfig?.level || "",
        selected: data.codingConfig?.selected || false,
        totalQuestions: Number(data.codingConfig?.totalQuestions) || 0,
      },
      descriptive: {
        topics: data.interviewConfig?.topics || {},
        score: Number(data.interviewConfig?.score) || 0,
        selected: data.interviewConfig?.selected || false,
      },
    }));
  };

  const extractTopics = (topics) => {
    const trueStatusSkills = topics
      .flatMap((item) => item.skills)
      .filter((skill) => skill.status === true)
      .map((skill) => skill.label);
    return trueStatusSkills;
  };

  const [availableTopics, setAvailableTopics] = useState([]);

  const fetchSkillsGroup = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_MCQ_QUESTION_STATUS + jobId,
        1,
        {},
      );
      if (response?.data) {
        if (response?.data?.mcq_criteria_status) {
          setAvailableTopics(
            extractTopics(response?.data?.mcq_criteria_status),
          );
          // setSkillGroups(response?.data?.mcq_criteria_status);
        }
      } else {
        console.error("Failed to fetch skills group", response);
      }
    } catch (error) {
      console.error("Failed to fetch skills group", error);
    }
  };

  useEffect(() => {
    fetchSkillsGroup();
  }, []);

  const [showSection, setShowSection] = useState(null);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAssessmentPlan((prev) => ({ ...prev, [name]: value }));
  };

  // const handleTopicChange = (section, topic, field, value) => {
  //   setAssessmentPlan((prev) => ({
  //     ...prev,
  //     [section]: {
  //       ...prev[section],
  //       topics: {
  //         ...prev[section].topics,
  //         [topic]: {
  //           ...(prev[section].topics[topic] || {
  //             selected: false,
  //             questions: 0,
  //             difficulty: "",
  //           }),
  //           [field]: value,
  //         },
  //       },
  //     },
  //   }));
  // };
  const handleTopicChange = (section, topic, field, value) => {
    setAssessmentPlan((prev) => {
      const newTopics = { ...prev[section].topics };

      if (field === "questions" && section === "descriptive") {
        // Apply limit only for descriptive questions
        if (value > 10) {
          newTopics[topic] = {
            ...newTopics[topic],
            questions: value,
            error: "You can't give more than 10 questions for any topic", // Add error message
          };
        } else {
          newTopics[topic] = {
            ...newTopics[topic],
            questions: value,
            error: "", // Clear error message if valid
          };
        }
      } else {
        // Handle changes for other sections without limits
        newTopics[topic] = {
          ...newTopics[topic],
          [field]: value,
        };
      }

      return {
        ...prev,
        [section]: {
          ...prev[section],
          topics: newTopics,
        },
      };
    });
  };

  const handleSectionScoreChange = (section, value) => {
    setAssessmentPlan((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        score: value,
      },
    }));
  };

  const handleCodeLevelChange = (value) => {
    setAssessmentPlan((prev) => ({
      ...prev,
      coding: { ...prev.coding, level: value },
    }));
  };

  // const renderTopicSelector = (section) => (
  //   <div className="space-y-4">
  //     {availableTopics.map((topic) => (
  //       <div
  //         key={topic}
  //         className="bg-white flex item-center justify-between rounded-lg p-2 hover:shadow-md transition-all duration-200"
  //       >
  //         <div className="flex items-center justify-between w-1/2">
  //           <div className="flex items-center space-x-2">
  //             <input
  //               type="checkbox"
  //               id={`${section}-${topic}`}
  //               checked={
  //                 assessmentPlan[section].topics[topic]?.selected || false
  //               }
  //               onChange={(e) =>
  //                 handleTopicChange(
  //                   section,
  //                   topic,
  //                   "selected",
  //                   e.target.checked
  //                 )
  //               }
  //               className="w-4 h-4 rounded border-gray-300"
  //             />
  //             <label
  //               htmlFor={`${section}-${topic}`}
  //               className="text-sm font-medium mt-[5px]"
  //             >
  //               {topic}
  //             </label>
  //           </div>
  //         </div>
  //         {assessmentPlan[section].topics[topic]?.selected && (
  //           <div className="grid grid-cols-2 gap-4  w-1/2">
  //             <div>
  //               <label
  //                 className=" text-right text-[12px] font-normal not-italic required"
  //                 htmlFor="noOfQusn"
  //               >
  //                 No. of questions
  //               </label>
  //               <InputBox
  //                 size="medium"
  //                 type="number"
  //                 min={0}
  //                 value={assessmentPlan[section].topics[topic]?.questions || 0}
  //                 onChange={(e) =>
  //                   handleTopicChange(
  //                     section,
  //                     topic,
  //                     "questions",
  //                     e.target.value ?? 0
  //                   )
  //                 }
  //                 className="w-full px-3 py-2 border rounded-md"
  //               />
  //             </div>
  //             <div>
  //               <label
  //                 className=" text-right text-[12px] font-normal not-italic required"
  //                 htmlFor="difficulty"
  //               >
  //                 Difficulty
  //               </label>
  //               <select
  //                 value={
  //                   assessmentPlan[section].topics[topic]?.difficulty || ""
  //                 }
  //                 onChange={(e) =>
  //                   handleTopicChange(
  //                     section,
  //                     topic,
  //                     "difficulty",
  //                     e.target.value
  //                   )
  //                 }
  //                 className="w-full px-3 h-[40px] border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC] "
  //               >
  //                 <option value="">Select</option>
  //                 <option value="EASY">Easy</option>
  //                 <option value="MEDIUM">Medium</option>
  //                 <option value="HARD">Hard</option>
  //               </select>
  //             </div>
  //           </div>
  //         )}
  //       </div>
  //     ))}
  //   </div>
  // );
  const renderTopicSelector = (section) => (
    <div className="space-y-4">
      {availableTopics.map((topic) => (
        <div
          key={topic}
          className="bg-white flex item-center justify-between rounded-lg p-2 hover:shadow-md transition-all duration-200"
        >
          <div className="flex items-center justify-between w-1/2">
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                id={`${section}-${topic}`}
                checked={
                  assessmentPlan[section].topics[topic]?.selected || false
                }
                onChange={(e) =>
                  handleTopicChange(
                    section,
                    topic,
                    "selected",
                    e.target.checked,
                  )
                }
                className="w-4 h-4 rounded border-gray-300"
              />
              <label
                htmlFor={`${section}-${topic}`}
                className="text-sm font-medium mt-[5px]"
              >
                {topic}
              </label>
            </div>
          </div>
          {assessmentPlan[section].topics[topic]?.selected && (
            <div className="grid grid-cols-2 gap-4  w-1/2">
              <div>
                <label
                  className=" text-right text-[12px] font-normal not-italic required"
                  htmlFor="noOfQusn"
                >
                  No. of questions
                </label>
                <InputBox
                  size="medium"
                  type="number"
                  min={0}
                  max={section === "descriptive" ? 10 : undefined} // Apply max limit only for descriptive
                  value={assessmentPlan[section].topics[topic]?.questions || 0}
                  onChange={(e) =>
                    handleTopicChange(
                      section,
                      topic,
                      "questions",
                      e.target.value ?? 0,
                    )
                  }
                  className="w-full px-3 py-2 border rounded-md"
                />
                {/* Error message */}
                {section === "descriptive" &&
                  assessmentPlan[section].topics[topic]?.error && (
                    <p className="text-red-600 text-xs mt-1">
                      {assessmentPlan[section].topics[topic].error}
                    </p>
                  )}
              </div>
              <div>
                <label
                  className=" text-right text-[12px] font-normal not-italic required"
                  htmlFor="difficulty"
                >
                  Difficulty
                </label>
                <select
                  value={
                    assessmentPlan[section].topics[topic]?.difficulty || ""
                  }
                  onChange={(e) =>
                    handleTopicChange(
                      section,
                      topic,
                      "difficulty",
                      e.target.value,
                    )
                  }
                  className="w-full px-3 h-[40px] border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC] "
                >
                  <option value="">Select</option>
                  <option value="EASY">Easy</option>
                  <option value="MEDIUM">Medium</option>
                  <option value="HARD">Hard</option>
                </select>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );

  const mcqQuestions = assessmentPlan.mcq.selected
    ? Object.keys(assessmentPlan.mcq.topics).reduce(
        (acc, topic) =>
          assessmentPlan.mcq.topics[topic].selected
            ? acc + +assessmentPlan.mcq.topics[topic].questions
            : acc,
        0,
      )
    : 0;

  const descriptiveQuestions = assessmentPlan.descriptive.selected
    ? Object.keys(assessmentPlan.descriptive.topics).reduce(
        (acc, topic) =>
          assessmentPlan.descriptive.topics[topic].selected
            ? acc + +assessmentPlan.descriptive.topics[topic].questions
            : acc,
        0,
      )
    : 0;

  const codingQuestions = assessmentPlan.coding.selected
    ? parseInt(assessmentPlan.coding.totalQuestions, 10)
    : 0;

  function validateRequiredFields(assessmentPlan) {
    // Basic required fields check
    if (!assessmentPlan.name || !assessmentPlan.duration) {
      toast.error(
        "Please fill in all required fields: Assessment Name and Duration.",
      );
      return false;
    }

    // Check MCQ section if selected
    if (assessmentPlan.mcq.selected) {
      if (
        !assessmentPlan.mcq.score ||
        !Object.keys(assessmentPlan.mcq.topics).some(
          (topic) => assessmentPlan.mcq.topics[topic].selected,
        )
      ) {
        toast.error(
          "Please complete the MCQ section: ensure questions are selected and a score is provided.",
        );
        return false;
      }
    }

    // Check Descriptive section if selected
    if (assessmentPlan.descriptive.selected) {
      if (
        !assessmentPlan.descriptive.score ||
        !Object.keys(assessmentPlan.descriptive.topics).some(
          (topic) => assessmentPlan.descriptive.topics[topic].selected,
        )
      ) {
        toast.error(
          "Please complete the Descriptive section: ensure questions are selected and a score is provided.",
        );
        return false;
      }
    }

    // Check Coding section if selected
    if (assessmentPlan.coding.selected) {
      if (!assessmentPlan.coding.score) {
        toast.error(
          "Please complete the Coding section: provide a score and select a programming language.",
        );
        return false;
      }
    }

    // All required fields are filled
    return true;
  }

  const [loading, setLoading] = useState(false);

  async function createAssessment() {
    // add the checks for all the required field
    setLoading(true);

    if (!validateRequiredFields(assessmentPlan)) {
      setLoading(false);
      return console.log("fill all required field ");
    }

    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_ASSESSMENT,
        1,
        {
          name: assessmentPlan.name,
          job: jobId,
          duration: assessmentPlan.duration,
          totalQuestions: mcqQuestions + descriptiveQuestions + codingQuestions,
          // sectionOrder: formDetails?.sequence,
          topics: [],
          interviewCount: Object.keys(assessmentPlan.descriptive.topics).reduce(
            (acc, topic) =>
              assessmentPlan.descriptive.topics[topic].selected
                ? acc + +assessmentPlan.descriptive.topics[topic].questions
                : acc,
            0,
          ),
          sectionOrder: ["mcq", "coding", "interview"],
          codingCount: assessmentPlan?.coding?.totalQuestions,
          mcqCount: Object.keys(assessmentPlan.mcq.topics).reduce(
            (acc, topic) =>
              assessmentPlan.mcq.topics[topic].selected
                ? acc + +assessmentPlan.mcq.topics[topic].questions
                : acc,
            0,
          ),
          hasMCQ: assessmentPlan.mcq.selected,
          hasCoding: assessmentPlan.coding.selected,
          hasInterviewQuestions: assessmentPlan.descriptive.selected,
          mcqScore: assessmentPlan.mcq.score,
          codingScore: assessmentPlan.coding.score,
          interviewScore: assessmentPlan.descriptive.score,
          canGoBack: true,
          mcqConfig: assessmentPlan.mcq,
          codingConfig: assessmentPlan.coding,
          interviewConfig: assessmentPlan.descriptive,
        },
      );
      if (json?.data) {
        miniReload();
        setAsAdded(true);
        fetchJobDetails();
        setLoading(false);
      } else {
        console.log(json.error);
        toast.error("Something went wrong");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }

  async function editAssessment() {
    let totalQuestions =
      Object.keys(assessmentPlan.mcq.topics).reduce(
        (acc, topic) =>
          assessmentPlan.mcq.topics[topic].selected
            ? acc + Number(assessmentPlan.mcq.topics[topic].questions)
            : acc,
        0,
      ) +
      Number(assessmentPlan?.coding?.totalQuestions || 0) +
      Object.keys(assessmentPlan.descriptive.topics).reduce(
        (acc, topic) =>
          assessmentPlan.descriptive.topics[topic].selected
            ? acc + Number(assessmentPlan.descriptive.topics[topic].questions)
            : acc,
        0,
      );

    let codingCount = assessmentPlan?.coding?.totalQuestions;
    let interviewCount = Object.keys(assessmentPlan.descriptive.topics).reduce(
      (acc, topic) =>
        assessmentPlan.descriptive.topics[topic].selected
          ? acc + +assessmentPlan.descriptive.topics[topic].questions
          : acc,
      0,
    );
    let mcqCount = Object.keys(assessmentPlan.mcq.topics).reduce(
      (acc, topic) =>
        assessmentPlan.mcq.topics[topic].selected
          ? acc + +assessmentPlan.mcq.topics[topic].questions
          : acc,
      0,
    );

    let json = await core.API(
      core.API_METHODS.PUT,
      core.JOB_API.PUT_ASSESSMENT + (data?.id ?? data?._id),
      1,
      {
        name: assessmentPlan?.name,
        duration: assessmentPlan?.duration,
        totalQuestions: totalQuestions,
        topics: assessmentPlan?.topics ?? [],
        sectionOrder: ["mcq", "coding", "interview"],
        mcqConfig: assessmentPlan.mcq,
        codingConfig: assessmentPlan.coding,
        interviewConfig: assessmentPlan.descriptive,
        codingCount: codingCount,
        interviewCount: interviewCount,
        mcqCount: mcqCount,
        hasMCQ: assessmentPlan.mcq.selected,
        hasCoding: assessmentPlan.coding.selected,
        hasInterviewQuestions: assessmentPlan.descriptive.selected,
        mcqScore: assessmentPlan.mcq.score,
        codingScore: assessmentPlan.coding.score,
        interviewScore: assessmentPlan.descriptive.score,
        canGoBack: assessmentPlan?.canGoBack,
      },
    );

    if (json.data) {
      toast.success("Assessment updated successfully");
      fetchJobDetails();
      modalDismiss();
    } else {
      console.error(json.error);
      toast.error("Failed to update assessment");
    }
  }

  const [asAdded, setAsAdded] = useState(false);

  console.log(
    mcqQuestions,
    descriptiveQuestions,
    codingQuestions,
    "total = ",
    mcqQuestions + descriptiveQuestions + codingQuestions,
  );
  console.log(assessmentPlan);

  return asAdded ? (
    <Modal
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryLabel: "OK",
      }}
      dismissOnSave={false}
      onSave={modalDismiss}
      onCancel={modalDismiss}
    >
      <div className="w-[calc(100%-32px)] grid grid-cols-[60px_1fr]  gap-y-2 gap-x-4">
        <svg
          className="row-span-2"
          xmlns="http://www.w3.org/2000/svg"
          width="60"
          height="60"
          viewBox="0 0 72 72"
          fill="none"
        >
          <path
            d="M34.1841 1.38145C35.2573 0.565137 36.7433 0.565137 37.8165 1.38145L42.9371 5.27629C43.5634 5.75265 44.3517 5.96389 45.1323 5.86449L51.5142 5.05174C52.8518 4.88139 54.1387 5.62438 54.66 6.86794L57.1471 12.8012C57.4513 13.5269 58.0284 14.104 58.7541 14.4082L64.6874 16.8954C65.9309 17.4166 66.6739 18.7035 66.5036 20.0411L65.6908 26.423C65.5914 27.2036 65.8027 27.992 66.279 28.6182L70.1739 33.7388C70.9902 34.812 70.9902 36.298 70.1739 37.3712L66.279 42.4918C65.8027 43.118 65.5914 43.9064 65.6908 44.687L66.5036 51.0689C66.6739 52.4065 65.9309 53.6934 64.6874 54.2146L58.7541 56.7018C58.0284 57.006 57.4513 57.5831 57.1471 58.3088L54.66 64.2421C54.1387 65.4856 52.8518 66.2286 51.5142 66.0583L45.1323 65.2455C44.3517 65.1461 43.5634 65.3574 42.9371 65.8337L37.8165 69.7286C36.7433 70.5449 35.2573 70.5449 34.1841 69.7286L29.0636 65.8337C28.4373 65.3574 27.6489 65.1461 26.8684 65.2455L20.4864 66.0583C19.1488 66.2286 17.8619 65.4856 17.3407 64.2421L14.8535 58.3088C14.5494 57.5831 13.9722 57.006 13.2466 56.7018L7.31325 54.2146C6.0697 53.6934 5.32671 52.4065 5.49705 51.0689L6.3098 44.6869C6.4092 43.9064 6.19796 43.118 5.7216 42.4918L1.82676 37.3712C1.01045 36.298 1.01045 34.812 1.82676 33.7388L5.7216 28.6182C6.19796 27.992 6.4092 27.2036 6.3098 26.4231L5.49705 20.0411C5.32671 18.7035 6.0697 17.4166 7.31325 16.8954L13.2466 14.4082C13.9722 14.104 14.5494 13.5269 14.8535 12.8012L17.3407 6.86794C17.8619 5.62438 19.1488 4.88139 20.4864 5.05174L26.8684 5.86449C27.6489 5.96389 28.4373 5.75265 29.0636 5.27629L34.1841 1.38145Z"
            fill="#00AC85"
          />
          <path
            d="M35.9642 37.2984L37.4437 38.8041C37.8356 39.203 38.4784 39.203 38.8703 38.8041L50.1869 27.2862C50.8007 26.6615 51.8076 26.6615 52.4214 27.2862C53.0202 27.8956 53.0202 28.8724 52.4214 29.4818L39.5836 42.548C38.7998 43.3457 37.5142 43.3457 36.7304 42.548L29.3527 35.039C28.7539 34.4296 28.7539 33.4529 29.3527 32.8435C29.9665 32.2187 30.9734 32.2187 31.5872 32.8435L33.7699 35.0651L35.9642 37.2984ZM37.0427 33.9231C36.4458 33.3155 36.4457 32.3416 37.0426 31.7339L42.5434 26.1342C43.1555 25.5111 44.1596 25.511 44.7717 26.1341C45.3687 26.7417 45.3688 27.7156 44.7718 28.3232L39.2711 33.923C38.659 34.5461 37.6549 34.5461 37.0427 33.9231ZM30.4994 40.6697C31.0977 41.2786 31.0977 42.2547 30.4994 42.8636C29.886 43.488 28.8798 43.488 28.2664 42.8636L20.5786 35.039C19.9798 34.4296 19.9803 33.4524 20.5791 32.843C21.1924 32.2188 22.1988 32.2183 22.8121 32.8425L23.8894 33.9389C23.8898 33.9393 23.8898 33.94 23.8894 33.9405C23.8889 33.9409 23.8889 33.9416 23.8894 33.9421L30.4994 40.6697Z"
            fill="white"
            stroke="white"
            strokeWidth="0.2"
          />
        </svg>
        <h1 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
          Your assessment was successfully added.
        </h1>
        <span className="text-gray-700 text-sm -mt-3 not-italic font-normal">
          You will see them on assessment list
        </span>
      </div>
    </Modal>
  ) : (
    <Modal
      modalDismiss={modalDismiss}
      heading={"Add new assessment"}
      subheading={"Select the options and create a assessment for candidates"}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          <>{edit ? "Edit assessment" : "Add assessment"}</>
        ),
      }}
      dismissOnSave={false}
      onSave={(e) => {
        if (edit) editAssessment();
        else createAssessment();
        // modalDismiss();
        miniReload();
      }}
      onCancel={modalDismiss}
      // noBtns={true}
      className={"md:max-w-[60%] "}
    >
      {availableTopics?.length > 0 ? (
        <div className="">
          <div className="w-full  ">
            <div className="">
              <div className="">
                <label
                  className="block text-sm font-medium text-gray-700 required"
                  htmlFor="AssessmentName"
                >
                  Assessment Name
                </label>
                <InputBox
                  id="name"
                  size="medium"
                  name="name"
                  required
                  value={assessmentPlan.name}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 border rounded-md"
                />
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
                <div className="">
                  <label
                    className=" block text-sm font-medium text-gray-700 required"
                    htmlFor="Duration"
                  >
                    Duration (minutes)
                  </label>
                  <div className="relative">
                    <InputBox
                      id="duration"
                      name="duration"
                      size="medium"
                      type="number"
                      value={assessmentPlan.duration}
                      onChange={handleInputChange}
                      className="w-full pl-8 px-3 py-2 border rounded-md"
                    />

                    <span className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400">
                      <IconClock />
                    </span>
                  </div>
                </div>
                <div className="">
                  <label
                    className=" block text-sm font-medium text-gray-700"
                    htmlFor="totalQuestions"
                  >
                    Total Questions
                  </label>
                  <InputBox
                    id="totalQuestions"
                    name="totalQuestions"
                    type="number"
                    size="medium"
                    disabled={true}
                    value={
                      mcqQuestions + descriptiveQuestions + codingQuestions
                    }
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border rounded-md"
                  />
                </div>
              </div>

              <div className="space-y-8">
                {/* available topics  */}
                <span>
                  <label
                    className=" block text-sm font-medium text-gray-700"
                    htmlFor="AssessmentName"
                  >
                    Available topics
                  </label>
                  <div className="flex flex-wrap gap-2">
                    {availableTopics?.map((topic) => (
                      <span
                        key={topic}
                        className="flex items-center bg-gray-300 text-gray-800 m-1 px-3 py-2 rounded-lg text-sm font-medium"
                      >
                        {topic}
                      </span>
                    ))}
                  </div>
                </span>
              </div>

              <label
                className=" block text-sm font-medium text-gray-700 mt-4"
                htmlFor="AssessmentName"
              >
                Sections
              </label>
              <div className="space-y-8">
                {/* MCQ Section */}
                <div className="border rounded-lg overflow-hidden ">
                  <div
                    className="bg-blue-50 p-2 flex justify-between items-center"
                    onClick={() => setShowSection("mcq")}
                  >
                    <h2 className="text-md font-semibold flex items-center w-1/2">
                      {/* <IconListChecks /> */}
                      <input
                        type="checkbox"
                        className="w-5 h-5 mb-1"
                        value={assessmentPlan?.mcq?.selected}
                        onChange={(e) =>
                          setAssessmentPlan((prev) => ({
                            ...prev,
                            mcq: { ...prev.mcq, selected: e.target.checked },
                          }))
                        }
                      />
                      <span className="ml-2">MCQ Section</span>
                    </h2>
                    <span className="flex gap-4 item-center w-1/2">
                      <div>
                        <label
                          className=" block text-sm font-medium text-gray-700"
                          htmlFor="AssessmentName"
                        >
                          No. of questions
                        </label>
                        <InputBox
                          min={0}
                          size={"small"}
                          type="number"
                          placeholder="Total questions"
                          disabled
                          // Value is the total number of questions in this section
                          value={Object.keys(assessmentPlan.mcq.topics).reduce(
                            (acc, topic) =>
                              assessmentPlan.mcq.topics[topic].selected
                                ? acc +
                                  +assessmentPlan.mcq.topics[topic].questions
                                : acc,
                            0,
                          )}
                        />
                      </div>
                      <div>
                        <label
                          className=" block text-sm font-medium text-gray-700"
                          htmlFor="AssessmentName"
                        >
                          Score (per question)
                        </label>

                        <InputBox
                          size={"small"}
                          type="number"
                          disabled={!assessmentPlan.mcq.selected}
                          value={assessmentPlan.mcq.score}
                          onChange={(e) =>
                            handleSectionScoreChange("mcq", e.target.value)
                          }
                          placeholder="score for each question"
                        />
                      </div>
                    </span>
                  </div>
                  {assessmentPlan.mcq.selected && (
                    <div className="p-4">{renderTopicSelector("mcq")}</div>
                  )}
                </div>

                {/* Descriptive Section */}
                <div className="border rounded-lg overflow-hidden">
                  <div
                    className="bg-green-50 p-2 flex justify-between items-center"
                    onClick={() => setShowSection("descriptive")}
                  >
                    <h2 className="text-md font-semibold flex items-center w-1/2">
                      {/* <IconBook /> */}
                      <input
                        type="checkbox"
                        className="w-5 h-5 mb-1"
                        value={assessmentPlan?.descriptive?.selected}
                        onChange={(e) =>
                          setAssessmentPlan((prev) => ({
                            ...prev,
                            descriptive: {
                              ...prev.descriptive,
                              selected: e.target.checked,
                            },
                          }))
                        }
                      />
                      <span className="ml-2">Descriptive Section</span>
                    </h2>
                    <span className="flex gap-4 item-center w-1/2">
                      <div>
                        <label
                          className=" block text-sm font-medium text-gray-700"
                          htmlFor="AssessmentName"
                        >
                          No. of questions
                        </label>
                        <InputBox
                          size={"small"}
                          type="number"
                          disabled
                          placeholder="Total questions"
                          value={Object.keys(
                            assessmentPlan.descriptive.topics,
                          ).reduce(
                            (acc, topic) =>
                              assessmentPlan.descriptive.topics[topic].selected
                                ? acc +
                                  +assessmentPlan.descriptive.topics[topic]
                                    .questions
                                : acc,
                            0,
                          )}
                        />
                      </div>
                      <div>
                        <label
                          className=" block text-sm font-medium text-gray-700"
                          htmlFor="AssessmentName"
                        >
                          Score (per question)
                        </label>

                        <InputBox
                          size={"small"}
                          type="number"
                          disabled={!assessmentPlan.descriptive.selected}
                          value={assessmentPlan.descriptive.score}
                          onChange={(e) =>
                            handleSectionScoreChange(
                              "descriptive",
                              e.target.value,
                            )
                          }
                          placeholder="score for each question"
                        />
                      </div>
                    </span>
                  </div>
                  {assessmentPlan.descriptive.selected && (
                    <div className="p-4">
                      {renderTopicSelector("descriptive")}
                    </div>
                  )}
                </div>

                {/* Coding Section */}
                <div className="border rounded-lg overflow-hidden">
                  <div className="bg-purple-50 p-2 flex justify-between items-center">
                    <h2 className="text-md font-semibold flex items-center w-1/2">
                      <input
                        type="checkbox"
                        className="w-5 h-5 mb-1"
                        value={assessmentPlan?.coding?.selected}
                        onChange={(e) =>
                          setAssessmentPlan((prev) => ({
                            ...prev,
                            coding: {
                              ...prev.coding,
                              selected: e.target.checked,
                            },
                          }))
                        }
                      />
                      <span className="ml-2">Coding Section</span>
                    </h2>
                    <span className="flex gap-4 item-center w-1/2">
                      <div>
                        <label
                          className=" block text-sm font-medium text-gray-700"
                          htmlFor="AssessmentName"
                        >
                          No. of questions
                        </label>

                        <InputBox
                          size={"small"}
                          type="number"
                          disabled={!assessmentPlan.coding.selected}
                          placeholder="Total questions"
                          min={0}
                          value={assessmentPlan.coding.totalQuestions}
                          onChange={(e) =>
                            setAssessmentPlan((prev) => ({
                              ...prev,
                              coding: {
                                ...prev.coding,
                                totalQuestions: e.target.value,
                              },
                            }))
                          }
                        />
                      </div>
                      <div>
                        <label
                          className=" block text-sm font-medium text-gray-700"
                          htmlFor="AssessmentName"
                        >
                          Score (per question)
                        </label>
                        <InputBox
                          size={"small"}
                          disabled={!assessmentPlan.coding.selected}
                          type="number"
                          value={assessmentPlan?.coding?.score}
                          onChange={(e) =>
                            handleSectionScoreChange("coding", e.target.value)
                          }
                          placeholder="score for each question"
                        />
                      </div>
                    </span>
                  </div>
                  {assessmentPlan?.coding?.selected && (
                    <div className="p-4">
                      <div className="mt-4 space-y-4">
                        <div>
                          <label className="text-sm font-medium text-gray-700 mb-2 block">
                            Difficulty level
                          </label>

                          <select
                            value={assessmentPlan.coding.level}
                            onChange={(e) =>
                              handleCodeLevelChange(e.target.value)
                            }
                            className="w-full px-3 h-[40px] border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[14px] placeholder:font-normal active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[#A9A9AC] "
                          >
                            <option value="">Select</option>
                            <option value="EASY">Easy</option>
                            <option value="MEDIUM">Medium</option>
                            <option value="HARD">Hard</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-96">
          <div className="flex flex-col items-center justify-center">
            <p className="text-gray-600 text-lg mt-4">
              No topics available for the assessment,
            </p>
            <p className="text-gray-600 text-lg mt-4">
              generate mcq and descriptive questions to add the assessment ,
            </p>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default EditAssessment;
