import GanttChart from "../../components/analytics/GanttChart";
import InlineGanttChart from "../../components/analytics/InlineGanttChart";
import SpiderChart from "../../components/analytics/SpiderChart";

export default function ChartsTemplate() {
  const timeline = [];
  const timelineColors = [
    "#26E45B",
    "#FF1E6F",
    "#4CD4FF",
    "#741EFF",
    "#C51EFF",
    "#ECC703",
    "#EC7303",
    "#1C2D87",
    "#FF2E00",
  ];
  return (
    <main className="w-screen h-screen">
      <div className="w-full px-10 mt-12">
        <h1 className="text-[26px] mt-[40px] mb-[18px] font-bold">
          Dual Axis Gantt Chart - with no of years as duration
        </h1>
        <GanttChart
          id="experienceChart"
          chartWidth={1050}
          totalExperience={6}
          experienceRange={[0, 6]}
          dataList={[
            {
              name: "SDE",
              type: "Position",
              minvalue: 1,
              maxvalue: 4,
            },
            {
              name: "Sr. SDE",
              type: "Position",
              minvalue: 4,
              maxvalue: 6,
            },
            {
              type: "Industry",
              name: "Fintech",
              minvalue: 2,
              maxvalue: 4,
            },
            {
              type: "Company",
              name: "Startup",
              minvalue: 0,
              maxvalue: 2,
            },
          ]}
          yearWise={false}
          chartMargin={{ left: 90, bottom: 50, right: 90 }}
          barGap={2}
          barSize={30}
          dualAxis={true}
          colors={["#80BB01", "#1369E9"]}
        />
      </div>
      <div className="w-full px-10 mt-12">
        <h1 className="text-[26px] mt-[40px] mb-[18px] font-bold">
          Single Axis (left) Gantt Chart - with no of years as duration and text
          inside the rect
        </h1>
        <InlineGanttChart
          id="inlineChart"
          chartWidth={850}
          totalExperience={6}
          experienceRange={[0, 6]}
          dataList={[
            {
              name: "Software Development Engineer",
              type: "Position",
              minvalue: 1,
              maxvalue: 4,
            },
            {
              name: "Senior Software Development Engineer",
              type: "Position",
              minvalue: 4,
              maxvalue: 6,
            },
            {
              type: "Industry",
              name: "Fintech",
              minvalue: 2,
              maxvalue: 4,
            },
            {
              type: "Company",
              name: "Startup",
              minvalue: 0,
              maxvalue: 2,
            },
          ]}
          yearWise={false}
          chartMargin={{ left: 90, bottom: 50, right: 90 }}
          barGap={2}
          barSize={30}
          dualAxis={true}
          colors={["#80BB01", "#1369E9"]}
        />
      </div>
      <div className="w-full px-10">
        <h1 className="text-[26px] mt-[40px] mb-[18px] font-bold">
          Spider chart to compare multiple areas across multiple categories
        </h1>
        <SpiderChart id={"spider"} chartSize={500} chartMargin={50} />
      </div>
    </main>
  );
}
