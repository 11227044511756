const core = require("../../../../lib/core");
export default function LinksForm({ websiteUrl, setWebsiteUrl }) {
  async function onSave(e) {
    e.preventDefault();
    await core
      .API(core.API_METHODS.PUT, core.CANDIDATE_API.PUT_UPDATE_PROFILE, 1, {
        urls: websiteUrl,
      })
      .then((data) => {
        console.log(data);
        window.location.reload();
      });
    window.location.reload();
  }
  function add_link(e) {
    e.preventDefault();
    setWebsiteUrl([...websiteUrl, "https://"]);
  }
  async function reset() {
    setWebsiteUrl(websiteUrl.filter((d) => d == websiteUrl));
  }

  return (
    <form className="col-xs-12 no-padding" id="links">
      {websiteUrl.length > 0 ? (
        websiteUrl.map((item, i) => (
          <div key={i} className="col-xs-12 no-padding row">
            <div className="col-xs-9 no-padding">
              <label htmlFor="" className="col-xs-12">
                {i == 0 ? "Personal Website" : "Others"}
              </label>
              <input
                className="col-xs-12 input_text"
                type="text"
                name={i}
                defaultValue={item}
                onChange={(event) => {
                  websiteUrl[i] = event.target.value;
                }}
              />
            </div>
            <button className="col-xs-1 add button" onClick={add_link}>
              Add
            </button>
            <button
              className="col-xs-1 cancel-btn"
              disabled={i == 0 ? true : false}
              onClick={(e) => {
                e.preventDefault();
                setWebsiteUrl(websiteUrl.filter((_, idx) => idx !== i));
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M24 24L8 8M24 8L8 24"
                  stroke="#001B4B"
                  strokeWidth="2.8"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        ))
      ) : (
        <div className="col-xs-12 no-padding row">
          <div className="col-xs-9 no-padding">
            <label htmlFor="" className="col-xs-12 no-padding">
              Personal Website
            </label>
            <input
              className="col-xs-12 input_text"
              type="text"
              onChange={(event) => {
                websiteUrl[0] = event.target.value;
              }}
            />
          </div>
          <button className="col-xs-1 add button" onClick={add_link}>
            Add
          </button>
          <button className="col-xs-1 cancel-btn" disabled>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M24 24L8 8M24 8L8 24"
                stroke="#001B4B"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
            </svg>
          </button>
        </div>
      )}
      <div className="col-xs-12">
        <button className="col-xs-2 button" onClick={onSave}>
          Save
        </button>
        <button className="col-xs-2 button reset" onClick={reset}>
          Reset
        </button>
      </div>
    </form>
  );
}
