/***
 * The validations for strong password
 * Minimum 8 characters
 * Atleast 1 alphabet
 * Atleast 1 numeric character
 * Atleast 1 special character
 * **/

import { useContext, useState } from "react";
import { ScaleLoader } from "react-spinners";
import { AuthStageContext } from "../../../lib/context";
import { useNavigate } from "react-router-dom";
import { authStages } from "../../../lib/constants";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

export default function SetPassword() {
  const { setStage, email, setEmail } = useContext(AuthStageContext);
  const { user, setUser } = useContext(core.UserContext);
  const nav = useNavigate();

  const [pwd, setPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");
  const [showPwd, setShowPwd] = useState(false);
  const [loginText, setLoginText] = useState("Continue");
  const [loading, setLoading] = useState(false);
  const [pwdStrength, setPwdStrength] = useState(-1);

  const errorMessage =
    "Your password must be 8 or more characters long & must contain atleast 1 alphabet, number & special character.";

  const handleChange = (e) => {
    setPwd(e.target.value);
    validatePassword(e.target.value);
  };

  const validatePassword = (value) => {
    const hasMinimumLength = value.length >= 8;
    const hasAlphabet = /[a-zA-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);

    const conditions = [
      hasMinimumLength,
      hasAlphabet,
      hasNumber,
      hasSpecialCharacter,
    ];

    const fulfilledConditions = conditions.filter((condition) => condition);
    setPwdStrength(fulfilledConditions?.length);
  };

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      setLoading(true);
      setLoginText(<ScaleLoader height={14} color="#ffffff" loading={true} />);
      let json = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_USER,
        1,
        {
          password: pwd,
          stage: authStages.SECURED,
        },
      );
      setLoginText("Next");
      setLoading(false);
      if (json) {
        setUser({ ...user, stage: authStages.SECURED });
        setStage(authStages.SECURED);
      }
    } catch (error) {
      setLoading(false);
      console.error("error confirming sign up", error);
    }
  }

  const handleLogOut = () => {
    setEmail("");
    setStage("NEWUSER");
    setUser({});
    core.logout();
    nav("/login");
  };

  return (
    <form className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
      <h1 className="w-full text-black text-4xl font-semibold">
        Create your password
      </h1>
      <h4 className="w-full text-slate-500 text-base">
        Create a new, strong password that you don’t use for other websites.
      </h4>
      <InputBox
        name="password"
        type={showPwd ? "text" : "password"}
        placeholder="Set password"
        className="w-full border border-gray-400 px-4 py-3 rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600 text-gray-700 placeholder:text-base text-lg tracking-widest placeholder:tracking-normal"
        minLength={8}
        required
        value={pwd}
        onChange={handleChange}
      />
      <InputBox
        name="confirm"
        type={showPwd ? "text" : "password"}
        placeholder="Confirm password"
        className="w-full border border-gray-400 px-4 py-3 rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600 text-gray-700 placeholder:text-base text-lg tracking-widest placeholder:tracking-normal"
        minLength={8}
        required
        value={confirmPwd}
        onChange={(e) => setConfirmPwd(e.target.value)}
      />
      <div className="w-full flex flex-row gap-2 items-center">
        <input
          className="!mt-px"
          type="checkbox"
          id="show-pwd"
          value={showPwd}
          checked={showPwd}
          onChange={() => setShowPwd((prev) => !prev)}
        />
        <span htmlFor="show-pwd" className="text-slate-500 text-sm">
          Show password
        </span>
      </div>
      {pwdStrength < 4 && (
        <p className={`w-full flex text-red-900 text-sm`}>{errorMessage}</p>
      )}
      <div className="w-full mb-2">
        <h4 className="w-full text-slate-500 text-sm mb-2">
          Password strength
        </h4>
        <div className="w-full flex gap-4">
          {[...Array(4)].map((_, index) => (
            <span
              className={`grow h-1.5 rounded ${
                pwdStrength < index + 1 ? "bg-gray-300" : "bg-bluegreen-800"
              }`}
            />
          ))}
        </div>
      </div>
      <button
        disabled={pwd != confirmPwd || pwdStrength != 4 || loading}
        onClick={handleSubmit}
        className="btn-primary btn-md h-12 min-w-[120px] disabled:opacity-60 disabled:pointer-events-none"
      >
        {loginText}
      </button>
      <h4 className="text-gray-700 text-sm w-full">
        Not {email}? Login using another email
        <a onClick={handleLogOut} className="text-blue-800 ml-2">
          here
        </a>
      </h4>
    </form>
  );
}
