import { useContext, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Range, getTrackBackground } from "react-range";
import { toast } from "react-toastify";
import errorIcon from "../../assets/error.svg";
import HamburgerSvg from "../../assets/recruiter/DragIcon.svg";

// import Modal from "../../candidate/profile/Modal";

const core = require("../../../lib/core");

export default function MtchingParameter({
  values,
  setValues,
  matchvalues,
  setMatchValues,
  parameters,
  setParameters,
}) {
  const { errorList, setErrorList } = useContext(core.JobFormContext);

  const handleRangeChange = (index, newValues) => {
    setParameters((prevParameters) => {
      const updatedParameters = [...prevParameters];
      updatedParameters[index].value = newValues;
      return updatedParameters;
    });
  };

  useEffect(() => {
    const sum = parameters.reduce((acc, curr) => {
      return acc + curr.value[0]; // Assuming each value is an array with one element
    }, 0);

    if (sum === 100) {
      setErrorList((current) => {
        const { sumParms, ...rest } = current;
        return rest;
      });
    } else {
      setErrorList((current) => {
        return { ...current, sumParms: "Sum of all parameters should be 100" };
      });
    }
  }, [parameters]);

  return (
    <>
      <section id="Hiring-pipeline-section assessment-invitees ">
        <div className=" data-section w-full px-[16px] flex items-center mt-[30px]  justify-between ">
          <div className="w-[40%]">Top candidates</div>
          <div className="w-[45%] flex items-center">
            <Range
              step={1}
              min={0}
              max={100}
              values={values}
              onChange={(newValues) => setValues(newValues)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "8px",
                    width: "calc(100% - 30px)",
                    borderRadius: "4px",
                    right: 0,
                    position: "relative",
                    background: getTrackBackground({
                      values: [values],
                      colors: ["teal", "#f2f2f2"],
                      min: 0,
                      max: 100,
                    }),
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "19px",
                    width: "19px",
                    borderRadius: "33.043px",
                    background: "#FFF",
                    boxShadow:
                      "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                  }}
                />
              )}
            />
            <span className="inline-block w-4 ml-[20px]">{values}</span>
          </div>
        </div>
        <span className="px-[16px] text-[12px] text-[#8D98B1] font-normal not-italic">
          These candidates will show up in the top of the list of candidates
        </span>
        <div className=" data-section w-full px-[16px] flex items-center mt-[30px]  justify-between ">
          <div className="w-[40%]">Matching score threshold</div>
          <div className="w-[45%] flex items-center">
            <Range
              step={1}
              min={0}
              max={100}
              values={matchvalues}
              onChange={(newValues) => setMatchValues(newValues)}
              renderTrack={({ props, children }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "8px",
                    width: "calc(100% - 30px)",
                    borderRadius: "4px",
                    right: 0,
                    position: "relative",
                    background: getTrackBackground({
                      values: [matchvalues],
                      colors: ["teal", "#f2f2f2"],
                      min: 0,
                      max: 100,
                    }),
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: "19px",
                    width: "19px",
                    borderRadius: "33.043px",
                    background: "#FFF",
                    boxShadow:
                      "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                  }}
                />
              )}
            />
            <span className="inline-block w-4 ml-[20px]">{matchvalues}</span>
          </div>
        </div>
        <span className="px-[16px]  text-[12px] text-[#8D98B1] font-normal not-italic">
          This is the minimum matching score a candidate must have to be
          considered a good match
        </span>

        <div className="mt-[60px]">
          <div className="flex flex-col">
            <span className="px-[16px] text-[16px] text-[#8D98B1] font-medium not-italic ">
              These Paramenter will use for calculate the matching score
            </span>

            {Object.keys(errorList).includes("sumParms") ? (
              <span className=" flex items-center px-[16px]">
                <img className=" mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.sumParms}
                </span>
              </span>
            ) : (
              <span className="text-[12px] px-[16px] text-[#8D98B1] font-normal not-italic">
                {"Sum of all parameters should be 100"}
              </span>
            )}
          </div>

          {parameters?.map((parameter, index) => (
            <div
              className="data-section w-full px-[16px] flex items-center mt-[30px] justify-between"
              key={index}
            >
              <div className="w-[40%]">{parameter.label}</div>
              <div className="w-[45%] flex items-center">
                <Range
                  step={1}
                  min={0}
                  max={100}
                  values={parameter?.value}
                  onChange={(newValues) => handleRangeChange(index, newValues)}
                  renderTrack={({ props, children }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "8px",
                        width: "calc(100% - 30px)",
                        borderRadius: "4px",
                        right: 0,
                        position: "relative",
                        background: getTrackBackground({
                          values: parameter?.value,
                          colors: ["teal", "#f2f2f2"],
                          min: 0,
                          max: 100,
                        }),
                      }}
                    >
                      {children}
                    </div>
                  )}
                  renderThumb={({ props }) => (
                    <div
                      {...props}
                      style={{
                        ...props.style,
                        height: "19px",
                        width: "19px",
                        borderRadius: "33.043px",
                        background: "#FFF",
                        boxShadow:
                          "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                      }}
                    />
                  )}
                />
                <span className="inline-block w-4 ml-[20px]">
                  {parameter?.value}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
