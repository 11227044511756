import React, { useEffect } from "react";
const core = require("../../../lib/core");

export default function Notification() {
  return (
    <div className="col-xs-12 no-padding" id="general-setting">
      <div className="col-xs-12 notification-setting sub-sections py-[22px] rounded-[12px]">
        <div className="flex flex-col pb-[18px]">
          <span className="text-[16px] text-[#16182F] font-medium not-italic">
            Notification
          </span>
          <span className="text-[14px] text-textColor1 font-normal not-italic leading-[22px]">
            Stay informed: manage your notifications
          </span>
        </div>
        <div className="general-info col-xs-12 no-padding border-t border-b border-t-[#EFF0F2] border-b-[#EFF0F2] py-[18px]">
          <div className="col-xs-12 no-padding sub-heading-sec">
            <span>Job alerts & reminders</span>
            <label className="switch">
              <input disabled type="checkbox" defaultChecked="checked" />
              <span className="slider round"></span>
            </label>
          </div>
          <span className="col-xs-12 no-padding info">
            Updates and quick reminders related to jobs you've viewed, saved,
            and matched with.
          </span>

          <div className="col-xs-12 no-padding sub-heading-sec job-recomand">
            <span className="">Job recommendations</span>
            <label className="switch">
              <input type="checkbox" defaultChecked="checked" />
              <span className="slider round"></span>
            </label>
          </div>
          <span className="col-xs-10 no-padding info">
            A personalized selection of jobs we think you'd love including
            recommendations based on your saved searches, sent to your inbox on
            a weekly cadence. We only send you jobs when you are ready to
            interview or open to offers.
          </span>
        </div>
        <div className="general-info col-xs-12 no-padding mt-[12px]">
          <div className="col-xs-12 no-padding sub-heading-sec">
            <span>Profile alerts</span>
            <label className="switch">
              <input type="checkbox" defaultChecked="checked" />
              <span className="slider round"></span>
            </label>
          </div>
          <span className="col-xs-12 no-padding info">
            See who viewed your profile.
          </span>
        </div>
        <div className="general-info col-xs-12 no-padding mt-[12px]">
          <div className="col-xs-12 no-padding sub-heading-sec">
            <span>All direct messages</span>
            <label className="switch">
              <input type="checkbox" defaultChecked="checked" />
              <span className="slider round"></span>
            </label>
          </div>
          <span className="col-xs-10 no-padding info">
            Direct messages from founders & recruiters, as well as job
            application matches, confirmations, and rejections. If toggled off,
            you will still receive emails related to account security and error
            messages. If you would like to opt out of these emails as well,
            please reach out to our support team at team@sproutsai.com
          </span>
        </div>
      </div>
    </div>
  );
}
