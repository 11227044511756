import React, { useMemo, useState } from "react";
import { Pagination, Table as TableAnt } from "antd";
import { IoMdRefresh } from "react-icons/io";
import "./table.scss";

const Table = ({
  dataSource,
  columns,
  pageSize,
  current,
  onPageChange,
  totalPage,
  ...props
}) => {
  const revampedColumns = columns.map((column) => {
    if (column.sorter) {
      return {
        ...column,
        sortIcon: (order) => {
          if (order.sortOrder === "ascend") {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                className="inline-block ml-4"
              >
                <path
                  d="M18 8L15 4L12 8"
                  stroke="#bbbfc9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 16L15 20L12 16"
                  stroke="#000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          } else if (order.sortOrder === "descend") {
            console.log("descend");
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                className="inline-block ml-4"
              >
                <path
                  d="M18 8L15 4L12 8"
                  stroke="#000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18 16L15 20L12 16"
                  stroke="#bbbfc9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          } else {
            return (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="20"
                viewBox="0 0 24 20"
                fill="none"
                className="inline-block ml-4"
              >
                <path
                  d="M15 8.5L12 4.5L9 8.5"
                  stroke="#bbbfc9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M15 13.5L12 17.5L9 13.5"
                  stroke="#bbbfc9"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            );
          }
        },
      };
    }
    return column;
  });
  return (
    <div id="custom-ant-table">
      <TableAnt
        // scroll={{
        //   y: "800px",
        // }}
        sticky={true}
        dataSource={dataSource}
        columns={revampedColumns}
        showSorterTooltip={false}
        rowHoverable={false}
        bordered={false}
        pagination={false}
        {...props}
      />
      <div className="flex justify-end mt-4">
        <Pagination
          pageSize={pageSize}
          current={current}
          onChange={onPageChange}
          total={totalPage}
          showSizeChanger={true}
          showQuickJumper
        />
      </div>
    </div>
  );
};

export default Table;
