import React from "react";
const core = require("../../../lib/core");
import Google from "../../../components/assets/recruiter/google.png";
import Microsoft from "../../../components/assets/recruiter/microsoft.png";

const VerifyEmailModal = () => {
  const verifyEmail = async () => {
    localStorage.setItem("email_auth_type", "gmail");
    const json = await core.API(
      core.API_METHODS.POST,
      core.GOOGLE_API.GET_EMAIL_AUTHENTICATION +
        "?redirect_uri=" +
        window.location.href,
      1,
      {},
    );
    if (json?.url) window.open(json?.url, "_blank");
  };

  const verifyOutlookEmail = async () => {
    localStorage.setItem("email_auth_type", "outlook");
    const json = await core.API(
      core.API_METHODS.POST,
      core.OUTLOOK_API.POST_OUTLOOK_AUTH +
        "?redirect_uri=" +
        window.location.href,
      1,
      {},
    );
    if (json?.url) window.open(json?.url, "_blank");
  };

  return (
    <div className="w-full">
      <ul className="list-disc text-sm text-gray-800 font-normal px-4 ">
        <li>Send and schedule emails</li>
        <li>Log email replies automatically</li>
        <li>
          Suggest follow up tasks and capture contact details from your email
        </li>
      </ul>
      <hr className="h-px w-full bg-gray-300 my-4" />
      <div className="flex justify-center gap-10">
        <button className="" onClick={() => verifyEmail()}>
          <img src={Google} className="w-10 h-10" alt="" />
        </button>
        <button className="" onClick={() => verifyOutlookEmail()}>
          <img src={Microsoft} className="w-10 h-10" alt="" />
        </button>
      </div>
    </div>
  );
};

export default VerifyEmailModal;
