import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  fetchDashboardData,
  fetchDashboardTask,
  fetchAllGoals,
  createGoal,
  fetchAllGoalsActivity,
  fetchAllTasks, // All tasks counts
} from "../services";

const core = require("../../lib/core");

const initialState = {
  isLoading: false,
  dashboardData: [],
  tasksList: [],
  istaskListLoading: false,
  goalsList: [], // Added to store goals data
  goalActivityOptions: [],
  goalCreationStatus: "idle", // New state for goal creation status
};

const dashboardSlice = createSlice({
  name: "dashboardData",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.dashboardData = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDashboardData.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true;
      })

      .addCase(fetchDashboardTask.fulfilled, (state, action) => {
        state.tasksList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchDashboardTask.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchDashboardTask.pending, (state) => {
        state.isLoading = true;
      })

      // Fetch all goals cases
      .addCase(fetchAllGoals.fulfilled, (state, action) => {
        state.goalsList = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllGoals.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllGoals.pending, (state) => {
        state.isLoading = true;
      })
      // Fetch all goals activity options
      .addCase(fetchAllGoalsActivity.fulfilled, (state, action) => {
        state.goalActivityOptions = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchAllGoalsActivity.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(fetchAllGoalsActivity.pending, (state) => {
        state.isLoading = true;
      })

      // Create goal cases
      .addCase(createGoal.fulfilled, (state, action) => {
        state.goalsList.push(action.payload); // Add new goal to goalsList
        state.goalCreationStatus = "succeeded"; // Update status
        state.isLoading = false;
      })
      .addCase(createGoal.pending, (state) => {
        state.goalCreationStatus = "loading"; // Track loading status
        state.isLoading = true;
      })
      .addCase(createGoal.rejected, (state, action) => {
        state.goalCreationStatus = "failed";
        state.error = action.payload; // Store error if goal creation fails
        state.isLoading = false;
      })

      // Handle fetching all tasks (Newly added)
      .addCase(fetchAllTasks.fulfilled, (state, action) => {
        state.tasksList = action.payload; // Store the tasks data
        state.isLoading = false;
        state.istaskListLoading = false;
      })
      .addCase(fetchAllTasks.rejected, (state, action) => {
        state.isLoading = false;
        state.istaskListLoading = false;
        state.error = action.payload; // Capture any errors
      })
      .addCase(fetchAllTasks.pending, (state) => {
        state.isLoading = true; // Set loading state when the request is pending
        state.istaskListLoading = true;
      });
  },
});

// Export actions
export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
