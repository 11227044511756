import React, { useContext, useMemo } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "../lib/core";

const ProtectedRouteComponent = ({
  code = "no_code",
  children,
  fallbackPath = "/dashboard",
}) => {
  const { user } = useContext(UserContext);
  const location = useLocation();
  // Create a memoized Set of resources
  const resourceSet = useMemo(
    () => new Set(user?.resources),
    [user?.resources],
  );

  // Check permission using the Set
  const hasPermission = useMemo(
    () => resourceSet.has(code),
    [resourceSet, code],
  );

  if (!hasPermission) {
    return <Navigate to={fallbackPath} state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRouteComponent;
