import { useLocation, useNavigate, useParams } from "react-router-dom";
const core = require("../../../lib/core");
import { GoPlus } from "react-icons/go";
import { FiInbox } from "react-icons/fi";
import { MdOutlineAllInbox } from "react-icons/md";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const trackSteps = ["active", "completed", "draft"];

const SequenceSidebar = ({
  sequences,
  setSelectedTab,
  selectedTab,
  sequenceTrack,
  loading,
}) => {
  const nav = useNavigate();
  const openId = useParams().id;
  const [trackStep, setTrackStep] = useState("active");
  const location = useLocation();

  const calculateSteps = (steps) =>
    steps?.filter((s) => s?.action != "WAIT")?.length;

  return (
    <>
      {selectedTab == "track_sequence" ? (
        <div className="w-full mt-4 grow divide-y overflow-y-scroll scrollbar-hide">
          {!loading ? (
            trackSteps.map((step, index) => (
              <div key={index} className="">
                <button
                  onClick={() => {
                    setTrackStep(step);
                  }}
                  className="flex items-center justify-between px-2 py-2 border-b w-full"
                >
                  <span className="capitalize flex items-center justify-between w-full">
                    <span>
                      {step} ({sequenceTrack[step]?.length})
                    </span>
                    <span className={step == trackStep ? "rotate-180" : ""}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M6.02346 8.70379L7.9348 10.8039L9.10185 12.0927C9.59606 12.6358 10.3999 12.6358 10.8941 12.0927L13.9784 8.7038C14.3833 8.25891 14.0916 7.5 13.5259 7.5L10.1855 7.5L6.47599 7.5C5.90438 7.5 5.61857 8.25891 6.02346 8.70379Z"
                          fill="#8D98B1"
                        />
                      </svg>
                    </span>
                  </span>
                </button>
                {step == trackStep &&
                  sequenceTrack[step]?.map((sequence, index) => (
                    <button
                      onClick={() => nav(sequence?._id)}
                      key={index}
                      style={
                        sequence?._id == openId
                          ? { backgroundColor: "#F2F4F8" }
                          : { backgroundColor: "white" }
                      }
                      className="w-full h-auto flex flex-col p-2 bg-white hover:bg-gray-300 border-gray-500"
                    >
                      <h2 className="inline-block text-sm not-italic text-gray-800 truncate font-semibold">
                        {sequence?.name}
                      </h2>
                      {sequence?.steps?.length > 0 && (
                        <h3 className="inline-block text-sm text-[#5F6989] truncate">
                          Steps - {calculateSteps(sequence?.steps)}
                        </h3>
                      )}
                    </button>
                  ))}
              </div>
            ))
          ) : (
            <div className="flex flex-col gap-2">
              <Skeleton height={40} className="w-full" />
              <Skeleton height={40} className="w-full" />
              <Skeleton height={40} className="w-full" />
            </div>
          )}
        </div>
      ) : (
        <div className="w-full mt-4 grow divide-y overflow-y-scroll scrollbar-hide">
          {!loading ? (
            sequences?.map((sequence, index) => (
              <button
                onClick={() => nav(sequence?._id)}
                key={index}
                style={
                  sequence?._id == openId
                    ? { backgroundColor: "#F2F4F8" }
                    : { backgroundColor: "white" }
                }
                className="w-full h-auto flex flex-col p-2 bg-white hover:bg-gray-300 border-gray-500"
              >
                <h2 className="inline-block text-sm not-italic text-gray-800 truncate font-semibold">
                  {sequence?.name}
                </h2>
                {sequence?.steps?.length > 0 && (
                  <h3 className="inline-block text-sm text-[#5F6989] truncate">
                    Steps - {calculateSteps(sequence?.steps)}
                  </h3>
                )}
              </button>
            ))
          ) : (
            <div className="flex flex-col gap-2">
              <Skeleton height={40} className="w-full" />
              <Skeleton height={40} className="w-full" />
              <Skeleton height={40} className="w-full" />
            </div>
          )}
        </div>
      )}
      <div className="flex justify-between pt-2">
        <span
          onClick={() => {
            nav("");
            setSelectedTab("sequences");
          }}
          data-tooltip-id="tooltip"
          data-tooltip-content="Sequences"
          className="cursor-pointer aspect-sqaure w-8 h-8 flex items-center justify-center hover:border hover:border-blue-800 rounded-lg"
        >
          <FiInbox
            className={`w-5 h-5 ${selectedTab == "sequences" && !location.pathname.includes("new") ? "text-blue-800" : ""}`}
          />
        </span>
        <span
          onClick={() => {
            nav("");
            setSelectedTab("track_sequence");
          }}
          data-tooltip-id="tooltip"
          data-tooltip-content="Track Sequence"
          className="cursor-pointer aspect-sqaure w-8 h-8 flex items-center justify-center hover:border hover:border-blue-800 rounded-lg"
        >
          <MdOutlineAllInbox
            className={`w-5 h-5 ${selectedTab == "track_sequence" && !location.pathname.includes("new") ? "text-blue-800" : ""}`}
          />
        </span>
        <span
          onClick={() => {
            nav("new");
          }}
          data-tooltip-id="tooltip"
          data-tooltip-content="Add Sequence"
          className="cursor-pointer aspect-sqaure w-8 h-8 flex items-center justify-center hover:border hover:border-blue-800 rounded-lg"
        >
          <GoPlus
            className={`w-5 h-5 ${location.pathname.includes("new") ? "text-blue-800" : ""}`}
          />
        </span>
      </div>
    </>
  );
};

export default SequenceSidebar;
