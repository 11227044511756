import { useContext } from "react";
import { PropagateLoader } from "react-spinners";
import CandidateDashboard from "../candidate/dashboard/Dashboard";
import NewUserDashboard from "../recruiter/NewUserDashboard";
import DashboardRecruiter from "../recruiter/DashboardRecruiter";
import AssessmentDashboard from "../candidate/assessment/Assessmentdashboard";
const core = require("../../lib/core");

export default function Dashboard() {
  const { user, profile } = useContext(core.UserContext);

  const getRecruiterDashboard = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_DASHBOARD,
        1
      );
      if (json?.error) return false;
      return json?.data;
    } catch (err) {
      console.error(err);
    }
  };

  if (user?.account === 1) {
    let data = getRecruiterDashboard();
    if (!profile && !data) return <NewUserDashboard jobs={data} />;
    return <DashboardRecruiter />;
    // } else if (user?.account === 0) return <CandidateDashboard />;
  } else if (user?.account === 0) return <AssessmentDashboard />;
  else return <PropagateLoader className="loader" color="#0b53dd" />;
}
