import React from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import { List } from "immutable";
import ListItems from "./ListItems";

function LeadList() {
  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar active="Dashboard" />
        <div className="w-full">
          <HeaderNew />
          <LeadListContent />
        </div>
      </div>
    </div>
  );
}

export default LeadList;

const LeadListContent = () => {
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
      <ListItems />
    </main>
  );
};
