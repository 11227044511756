import { useState, useEffect } from "react";
import SavedJobPost from "../../../components/candidate/dashboard/SavedJobPost";
import Header from "../../../components/all/header/Header";
import SideBar from "../../../components/all/SideBar";
import Menu from "../../../components/all/header/Menu";
import HeaderNew from "../../../components/all/header/HeaderNew";
import CandidateSidebar from "../../../components/all/CandidateSidebar";
import InitialsLogo from "../../../components/all/InitialsLogo";
import { IoMdTime } from "react-icons/io";
import { IoSend } from "react-icons/io5";
import { FaBookmark } from "react-icons/fa";
import { FaRegBookmark } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { FaBusinessTime } from "react-icons/fa6";
import parse from "html-react-parser";
import { MdLocationCity } from "react-icons/md";
import { FcApproval } from "react-icons/fc";
import { MdDelete } from "react-icons/md";
import { MdOutlineWorkOutline } from "react-icons/md";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
// import { FaBookmark } from "react-icons/fa";

const core = require("../../../lib/core");

function SavedJobs() {
  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        {/* <CandidateSidebar active="Saved" open={false} /> */}
        <div className="w-full">
          <HeaderNew />
          <JobContent />
        </div>
      </div>
    </div>
  );
}

export default SavedJobs;

const JobContent = () => {
  const [scaleLoading, setScaleLoading] = useState(false);
  const [jobIndex, setJobIndex] = useState([]);

  const ApplyBtn = ({ applied, job_id, onApply }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isApplied, setIsApplied] = useState(applied);

    useEffect(() => {
      setIsApplied(applied);
    }, [applied]);

    async function applyJob(e) {
      try {
        e.preventDefault();
        setIsLoading(true);
        let response = await core.API(
          core.API_METHODS.POST,
          core.CANDIDATE_API.POST_APPLY_JOB + String(job_id),
          1,
          {},
        );
        console.log(response);
        toast.success(response.data.message);
        setIsApplied(true);
        onApply(job_id); // Notify parent component about the change
      } catch (err) {
        console.log(err);
        toast.error("Failed to apply for the job");
      } finally {
        setIsLoading(false);
      }
    }

    return isApplied ? (
      <h4 style={{ color: "green" }}>Applied</h4>
    ) : (
      <button
        className="flex font-semibold bg-blue-800 hover:bg-blue-900 hover:scale-105 transition-all duration-300 text-white px-4 py-2 rounded-md"
        onClick={applyJob}
        disabled={isLoading}
      >
        {isLoading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          <>
            Apply
            <IoSend className="text-white ml-[6px] mt-[3px] text-[16px]" />
          </>
        )}
      </button>
    );
  };

  // async function applyJob(e, job_id) {
  //   try {
  //     e.preventDefault();
  //     let response = await core.API(
  //       core.API_METHODS.POST,
  //       core.CANDIDATE_API.POST_APPLY_JOB + String(job_id),
  //       1,
  //       {},
  //     );
  //     console.log(response);
  //     toast.success(response.data.message);
  //     setJobIndex([...jobIndex, job_id]);
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  const timeDifference = (difference) => {
    if (difference < 1000) {
      return "Just now";
    } else if (difference < 60000) {
      return `${Math.floor(difference / 1000)} seconds ago`;
    } else if (difference < 3600000) {
      return `${Math.floor(difference / 60000)} minutes ago`;
    } else if (difference < 86400000) {
      return `${Math.floor(difference / 3600000)} hours ago`;
    } else if (difference < 2592000000) {
      return `${Math.floor(difference / 86400000)} days ago`;
    } else if (difference < 31536000000) {
      return `${Math.floor(difference / 2592000000)} months ago`;
    } else {
      return `${Math.floor(difference / 31536000000)} years ago`;
    }
  };

  function parseHtml(html) {
    const parsedHtml = parse(html.split("&lt;").join("<"));
    return parsedHtml;
  }

  const [saveJobs, setSaveJobs] = useState([]);
  const headerList = ["Company", "Location", "Saved on", "", ""];
  useEffect(() => {
    fetchSavedJobs();
  }, []);

  const fetchSavedJobs = async () => {
    try {
      setScaleLoading(true);
      const jobList = await core.API(
        core.API_METHODS.GET,
        core.CANDIDATE_API.GET_SAVED_JOBS,
        1,
        {},
      );
      console.log(jobList);
      if (jobList?.data) {
        setSaveJobs(jobList?.data);
        // console.log("Saved Job list is ",jobList?.data);
      } else {
        console.log(jobList.error);
      }
      setScaleLoading(false);
    } catch (err) {
      console.log("Error in Fetching the Saved Jobs :", err);
      setScaleLoading(false);
    }
  };

  const removeJobFromList = (jobId) => {
    setSaveJobs((prevJobs) => prevJobs.filter((job) => job?.jobId !== jobId));
  };

  const [appliedJobs, setAppliedJobs] = useState(new Set());

  useEffect(() => {
    // Initialize appliedJobs with jobs that are already applied
    const initialAppliedJobs = new Set(
      saveJobs.filter((job) => job.applied).map((job) => job.id),
    );
    setAppliedJobs(initialAppliedJobs);
  }, [saveJobs]);

  const handleJobApply = (jobId) => {
    setAppliedJobs((prev) => new Set(prev).add(jobId));
  };

  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
      <span className="font-semibold text-[28px] not-italic text-[#16182F]">
        Saved jobs
      </span>

      {scaleLoading ? (
        <div className="w-full min-h-full flex items-center justify-center">
          <div className="auto-complete-list mt-10 absolute no-list-style w-[97%] rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
            <ScaleLoader height={14} color="#1369E9" loading={true} />
          </div>
        </div>
      ) : (
        <div className="page candidate-pages mt-3 rounded-md" id="applied-jobs">
          <main className="main-content">
            <div className="page-container col-xs-12">
              {saveJobs && saveJobs.length > 0 ? (
                saveJobs.map((job, i) => (
                  <div
                    key={i}
                    className="block text-black bg-[#FFFFFF] hover:bg-gray-100"
                  >
                    <table className="w-full table-fixed border-collapse">
                      <tbody>
                        <tr className="border-b  border-gray-300">
                          <td className="p-4 w-full align-top">
                            <div className="flex justify-between p-4">
                              <div className="flex w-[85%]">
                                <div className="w-1/10">
                                  <InitialsLogo
                                    className="w-16 h-16 rounded-md overflow-hidden"
                                    str={job?.company?.name || "N/A"}
                                    width={64}
                                    height={64}
                                    bgRounded="8px"
                                    fontSize={28}
                                  />
                                </div>
                                <div className="ml-4 w-full" id="main">
                                  <div className="flex justify-between">
                                    <h2 className="flex text-xl font-semibold text-gray-700">
                                      {job?.company?.name}
                                      {job?.position && job?.company?.name
                                        ? " - "
                                        : null}
                                      {job?.position}
                                    </h2>
                                    <FaBookmark
                                      className="ml-2  text-teal-600 text-3xl hover:cursor-pointer hover:scale-110 transition-all duration-300"
                                      onClick={() => {
                                        bookmarkJob(job?.jobId);
                                        removeJobFromList(job?.jobId);
                                      }}
                                    />
                                  </div>
                                  {job?.location?.[0] && (
                                    <p className="flex text-gray-600">
                                      <FaLocationDot className="mt-[3px] mr-2 text-gray-600" />
                                      {typeof job.location[0] === "string"
                                        ? job.location[0]
                                        : job.location[0]?.name}
                                    </p>
                                  )}
                                  {job?.experienceLevel && (
                                    <p className="flex text-gray-600">
                                      <FaBusinessTime className="mt-[3px] mr-2 text-gray-600" />
                                      Experience:{" "}
                                      {typeof job.experienceLevel === "string"
                                        ? job.experienceLevel
                                        : job.experienceLevel.min
                                          ? `${job.experienceLevel.min}+ years`
                                          : "Not specified"}
                                    </p>
                                  )}

                                  {job?.description && (
                                    <div className="text-gray-700 my-2">
                                      {(() => {
                                        const sentences =
                                          job.description?.split(
                                            /(?<=\.)\s+/,
                                          ) || [];
                                        const firstThreeSentences = sentences
                                          .slice(0, 3)
                                          .join(" ");
                                        if (firstThreeSentences.length <= 300) {
                                          return parseHtml(firstThreeSentences);
                                        } else {
                                          let truncated = job.description.slice(
                                            0,
                                            300,
                                          );
                                          if (
                                            truncated.charAt(
                                              truncated.length - 1,
                                            ) !== " "
                                          ) {
                                            const lastSpaceIndex =
                                              truncated.lastIndexOf(" ");
                                            truncated = job.description.slice(
                                              0,
                                              lastSpaceIndex,
                                            );
                                          }
                                          return parseHtml(truncated + ".");
                                        }
                                      })()}
                                    </div>
                                  )}

                                  {/* The below code for listing down the skills has become complicated because the data for the skills was very incosistent and was coming in more than 3 different ways, so to handle all of those cases it has become complicated */}
                                  <div className="flex flex-wrap items-center gap-2 mt-2">
                                    {job?.criterias
                                      ?.flatMap((criteria) => {
                                        if (criteria?.keySkills) {
                                          // Case where keySkills is present
                                          return criteria?.keySkills.map(
                                            (skill) => skill?.label,
                                          );
                                        } else if (criteria?.skills) {
                                          // Case where skills is an array of objects with labels or an array of strings
                                          return criteria?.skills.map(
                                            (skill) =>
                                              typeof skill === "string"
                                                ? skill
                                                : skill?.label,
                                          );
                                        }
                                        return [];
                                      })
                                      .slice(0, 10)
                                      .map((skill) => (
                                        <span
                                          key={`${skill}-${Date.now()}-${Math.random()}`}
                                          className="bg-defaultBg text-gray-700 text-sm font-semibold px-2 py-1 rounded"
                                        >
                                          {skill}
                                        </span>
                                      ))}
                                  </div>
                                </div>
                              </div>
                              <div
                                className="w-[15%] flex flex-col justify-between"
                                id="ending"
                              >
                                <div className="flex flex-col items-end justify-end">
                                  {job?.appliedDate && (
                                    <p className="flex text-gray-600 mb-2 items-center text-right">
                                      <IoMdTime className="text-lg mr-1 text-gray-600" />
                                      {timeDifference(
                                        new Date() - new Date(job?.appliedDate),
                                      )}
                                    </p>
                                  )}

                                  {job?.jobType?.length > 0 &&
                                    !(
                                      job.jobType.length === 1 &&
                                      job.jobType[0].trim() === ""
                                    ) && (
                                      <p className="flex items-end justify-end text-gray-600 mb-2 text-right">
                                        <MdOutlineWorkOutline className="text-lg mr-1 text-gray-600" />
                                        Job:{" "}
                                        {job.jobType
                                          .filter((type) =>
                                            typeof type === "string"
                                              ? true
                                              : type.status,
                                          )
                                          .map((type) =>
                                            typeof type === "string"
                                              ? type
                                              : type.type,
                                          )
                                          .join(", ")}
                                      </p>
                                    )}

                                  {job?.workplace && job?.workplace != "" && (
                                    <p className="flex items-end justify-end text-gray-600">
                                      <MdLocationCity className="text-gray-600 mr-1 text-lg mb-[2px]" />{" "}
                                      Workplace : {job?.workplace}
                                    </p>
                                  )}
                                </div>
                                <div className="flex justify-end mt-auto">
                                  <ApplyBtn
                                    applied={appliedJobs.has(job?.jobId)}
                                    job_id={job?.jobId}
                                    onApply={handleJobApply}
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ))
              ) : (
                <p>No Saved jobs found.</p>
              )}
            </div>
          </main>
        </div>
      )}
    </main>
  );
};
