/*
props structure 
props = {
    dropdownText : String
    dropdownData : List
}
*/
import { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import InitialsLogo from "../InitialsLogo";
const core = require("../../../lib/core");

export default function RecruiterSearchBar(props) {
  const [list, setList] = useState([]);
  const [hide, setHide] = useState([]);
  useEffect(() => {}, []);

  $(document).on("click", function (e) {
    if (
      $(e.target).is("#" + "recruiter_searchbar" + " .auto-complete-input") ===
      false
    ) {
      setHide(true);
    } else {
      setHide(false);
    }
  });

  // const [searchItem, setSearchItem] = useState()
  const nav = useNavigate();

  const getSearchResult = (searchItem) => {
    // e.preventDefault()
    // console.log(searchItem)
    if (searchItem?.type == "Job") {
      // console.log("job")
      nav(`/job/${searchItem?.id}&tab=description`);
    }
    if (searchItem?.type == "Candidate") {
      // console.log("Candidate")
      nav(`/job/${searchItem?.jobId}/candidate/${searchItem?.id}`);
    }
    if (searchItem?.type == "Company") {
      // console.log("Company")
      nav(`/company-details/${searchItem?.id}`);
    } else {
      console.log("not respond");
    }
  };

  function searchList(term) {
    try {
      setHide(false);
      if (term?.length < 1 && false) {
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.RECRUITER_API.GET_UNIVERSAL_SEARCH + "?key=" + term,
            1,
            {},
          );
          if (response?.data.results.length > 0) {
            setList(response?.data?.results);
          } else {
            setList([{ name: "No Option" }]);
          }
        }, 0);
      }
    } catch (err) {}
  }
  console.log(list);
  return (
    <form className="col-xs-12 no-padding" id="recruiter_searchbar">
      <input
        className="col-xs-10 job-candidate-search auto-complete-input"
        type="text"
        placeholder="Search jobs, companies, candidates..."
        onChange={(e) => {
          searchList(e.target.value);
        }}
      />
      {list?.length > 0 && (
        <ul className={hide ? "auto-complete-list hide" : "auto-complete-list"}>
          {list?.map((item, i) => (
            <li
              key={i}
              className="match-value"
              onClick={() => {
                $("#" + "recruiter_searchbar" + " .auto-complete-input").val(
                  item.name,
                );
                // console.log($("#" + "recruiter_searchbar" + " .auto-complete-input"))
                getSearchResult(item);
                // setSearchItem(item)
                // getDirectdata()
              }}
            >
              <div style={{ display: "flex" }}>
                {item.pic ? (
                  <img src={item.pic} alt="logo" />
                ) : (
                  <InitialsLogo
                    width={60}
                    height={40}
                    style={{ display: "inline-block" }}
                    str={item?.name}
                  />
                )}

                <span> {item?.name}</span>
              </div>
              <span className="searchType"> {item?.type}</span>
            </li>
          ))}
        </ul>
      )}

      <button
        className="col-xs-2 searchButton recruiter-search"
        onClick={getSearchResult}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M18.375 17.5L14.4497 13.755M16.625 8.74996C16.625 10.6286 15.8414 12.4303 14.4466 13.7586C13.0518 15.087 11.16 15.8333 9.1875 15.8333C7.21495 15.8333 5.3232 15.087 3.92839 13.7586C2.53359 12.4303 1.75 10.6286 1.75 8.74996C1.75 6.87134 2.53359 5.06967 3.92839 3.74129C5.3232 2.4129 7.21495 1.66663 9.1875 1.66663C11.16 1.66663 13.0518 2.4129 14.4466 3.74129C15.8414 5.06967 16.625 6.87134 16.625 8.74996V8.74996Z"
            stroke="#0B53DD"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </button>
    </form>
  );
}
