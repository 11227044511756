import React from "react";

const SvgIcon9 = ({ className }) => {
  return (
    <div
      className={`grow-0 shrink-0 basis-auto box-border w-8 h-8 flex ${className}`}
    >
      <svg
        fill="none"
        viewBox="0 0 33 33"
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
      >
        <g id="button_2" xmlns="http://www.w3.org/2000/svg">
          <rect height="32" width="32" fill="white" rx="8" x="0.5" y="0.5" />
          <rect height="31" width="31" rx="7.5" stroke="#E1E1EE" x="1" y="1" />
          <path
            d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
            fill="#5F6989"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            transform="translate(5, 5) scale(0.85)"
          />
        </g>
      </svg>
    </div>
  );
};

export default SvgIcon9;
