/*eslint-disable*/
// import React, { useEffect, useState } from "react";

// export const Timer = React.memo(
//   ({ initialTime, onTimeEnd, interviewRunning }) => {
//     const [timeRemaining, setTimeRemaining] = useState(initialTime);

//     useEffect(() => {
//       if (interviewRunning) {
//         const timer = setInterval(() => {
//           setTimeRemaining((prevTime) => {
//             if (prevTime > 0) {
//               // Update color based on time remaining
//               if (prevTime <= +(initialTime / 5) && prevTime % 2 === 0) {
//                 document.querySelector("#assessment .time-text").style.color =
//                   "red";
//               } else {
//                 document.querySelector("#assessment .time-text").style.color =
//                   "#252e4b";
//               }
//               return prevTime - 1;
//             } else {
//               onTimeEnd();
//               return 0;
//             }
//           });
//         }, 1000);

//         return () => clearInterval(timer);
//       }
//     }, [interviewRunning, onTimeEnd, initialTime]);

//     const minutes = Math.floor(timeRemaining / 60);
//     const seconds = timeRemaining % 60;

//     return (
//       <div className="timer">
//         <div className="time-text">{String(minutes).padStart(2, "0")}</div>
//         <span>:</span>
//         <div className="time-text">{String(seconds).padStart(2, "0")}</div>
//       </div>
//     );
//   }
// );

import React, { useEffect, useState } from "react";

export const Timer = React.memo(
  ({ initialTime, onTimeEnd, interviewRunning }) => {
    const [timeRemaining, setTimeRemaining] = useState(initialTime);
    const [flag, setFlag] = useState(false);

    useEffect(() => {
      if (interviewRunning) {
        const timer = setInterval(() => {
          setTimeRemaining((prevTime) => {
            if (prevTime > 0) {
              // Update color based on time remaining
              if (prevTime <= +(initialTime / 5) && prevTime % 2 === 0) {
                setFlag(true);
              } else {
                // document.querySelector("#assessment .time-text").style.color =
                //   "#252e4b";
              }
              return prevTime - 1;
            } else {
              onTimeEnd();
              return 0;
            }
          });
        }, 1000);

        return () => clearInterval(timer);
      }
    }, [interviewRunning, onTimeEnd, initialTime]);

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    return (
      <div className="flex items-center space-x-2">
        <div className="flex items-center space-x-2">
          <div
            className={`w-10 h-10 flex items-center justify-center rounded-lg bg-gray-400 text-2xl font-semibold ${flag ? " text-red-900" : "text-gray-800"}`}
          >
            {String(minutes).padStart(2, "0")}
          </div>
          <span className="text-2xl font-semibold">:</span>
          <div
            className={`w-10 h-10 flex items-center justify-center rounded-lg bg-gray-400 text-2xl font-semibold ${flag ? " text-red-900" : "text-gray-800"}`}
          >
            {String(seconds).padStart(2, "0")}
          </div>
        </div>
      </div>
    );
  }
);
