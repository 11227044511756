import React, { useState } from "react";
import Modal from "../../components/modal/Modal";
import { ScaleLoader } from "react-spinners";
import InputBox from "../../layouts/InputBox";

const core = require("../../lib/core");

function CreateLeadModal({ modalDismiss }) {
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [email, setEmail] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [name, setName] = useState("");

  const createLead = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_ADD_LEAD,
        1,
        {
          name: name,
          email: email,
          linkedin: linkedin,
        },
      );
      if (json.data) {
        setLoading(false);
        modalDismiss();
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
    }
  };

  return (
    <Modal
      heading={"Add leads"}
      subheading={""}
      onSave={createLead}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryDisabled: loading,
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
      }}
    >
      <div className="flex flex-col gap-2">
        {/* Create a form for adding leads which take name email and linkedin url as input */}

        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Name
          </label>
          <InputBox
            placeholder={"Enter name"}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <InputBox
            placeholder={"Enter email"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="">
          <label className="block text-sm font-medium text-gray-700">
            Linkedin
          </label>
          <InputBox
            placeholder={"Enter linkedin url"}
            value={linkedin}
            onChange={(e) => setLinkedin(e.target.value)}
          />
        </div>
      </div>
    </Modal>
  );
}

export default CreateLeadModal;
