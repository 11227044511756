import { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Header from "../../../components/all/header/Header";
import Menu from "../../../components/all/header/Menu";
import SideBar from "../../../components/all/SideBar";
import HeaderNew from "../../../components/all/header/HeaderNew";
import JobSidebar from "../../../components/all/JobSidebar";
import InitialsLogo from "../../../components/all/InitialsLogo";
import TabLayout from "../../../layouts/TabLayout";
import InputBox from "../../../layouts/InputBox";
import moment from "moment";
const core = require("../../../lib/core");

export default function MeetingNotes() {
  const loc = useLocation();
  const [meetDetails, setMeetDetails] = useState();
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getAllMeetingDetails();
    if (loc.pathname.split("/").at(-1) != "instant-meet") {
      getMeetingDetails();
    }
  }, []);

  const nav = useNavigate();

  async function getAllMeetingDetails() {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_ALL_INSTANT_MEETINGS,
      1,
    );

    if (json.data) {
      setDataList(json.data.meetings);
      setCandidateId(json.data.meetings[0]?.id);
    }
  }

  const [videoUrl, setVideoUrl] = useState();
  const [videoStatus, setVideoStatus] = useState(false);
  const [botId, setBotId] = useState();

  async function getMeetingDetails(canId) {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_INSTANT_MEETING_BY_ID + canId,
      1,
    );
    console.log(json.data);
    if (json.data) {
      setMeetDetails(json.data);
      setVideoUrl(json.data?.videoUrl);
      setBotId(json.data?.botId);
      if (json.data?.status === "completed") {
        setVideoStatus(true);
      }
    } else {
    }
  }

  const [open, setOpen] = useState(false);
  const [candidateId, setCandidateId] = useState();

  useEffect(() => {
    getMeetingDetails(candidateId);
  }, [candidateId]);

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          open={open}
          setOpen={setOpen}
          CandidateFilter={
            <ConversationList
              dataList={dataList}
              candidateId={candidateId}
              setCandidateId={setCandidateId}
            />
          }
        />
        <div className="w-full">
          <HeaderNew />
          <DashboardContent
            meetDetails={meetDetails}
            candidateId={candidateId}
            dataList={dataList}
            videoUrl={videoUrl}
            videoStatus={videoStatus}
            botId={botId}
          />
        </div>
      </div>
    </div>
  );
}

const DashboardContent = ({
  meetDetails,
  candidateId,
  dataList,
  videoUrl,
  botId,
  videoStatus,
}) => {
  const btnList = [
    {
      name: "Details",
      id: "Details",
    },
    {
      name: "Transcription",
      id: "Transcription",
    },
    {
      name: "Summarization",
      id: "Summarization",
    },
    {
      name: "Video",
      id: "video",
    },
  ];

  const [view, setView] = useState("Details");
  const [summary, setSummary] = useState("");

  async function getSummary() {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_TRANSCRIPTION_SUMMARY + candidateId + "/summarize",
      1,
    );

    if (json.data) {
      setSummary(json?.data?.summary);
    } else {
      setSummary("No summarization found");
    }
  }

  useEffect(() => {
    getSummary();
  }, [candidateId]);

  const videoRef = useRef(null);

  const handleTimeUpdate = () => {
    // Update any additional UI components based on video progress if needed
  };

  const dateFormatter = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const timeFormatter = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const [meetingNotes, setMeetingNotes] = useState([]);

  useEffect(() => {
    setMeetingNotes(meetDetails?.notes);
  }, [meetDetails]);

  function searchItems(keyword) {
    // Convert the keyword to lowercase for case-insensitive search
    const lowerCaseKeyword = keyword?.toLowerCase();

    // Use the filter() method to find items that match the keyword

    const filteredItems = meetDetails?.notes?.filter(
      (item) =>
        item?.paragraph?.toLowerCase().includes(lowerCaseKeyword) ||
        item?.speaker?.toLowerCase().includes(lowerCaseKeyword),
    );
    setMeetingNotes(filteredItems);
  }

  const isDateBefore24Hours = (createdAt) => {
    // Assuming createdAt is a valid date string or a Date object
    const createdAtMoment = moment(createdAt);
    const currentMoment = moment();

    // Check if the difference in hours is less than 24
    const isBefore24Hours = currentMoment.diff(createdAtMoment, "hours") < 24;

    return isBefore24Hours;
  };

  const [getvideoStatus, setGetVideoStatus] = useState(false);

  const getVideo = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_SAVE_VIDEO_URL,
        1,
        {
          botId: botId,
        },
      );

      if (json.data) {
        setGetVideoStatus(true);
      } else {
      }
    } catch (error) {}
  };

  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      {dataList && dataList?.length > 0 ? (
        <>
          {/* <div className="pb-[15px] flex items-center justify-between border-b-[2px] border-b-[#E1E1EE]">
            <span className="flex items-center">
              <InitialsLogo str={meetDetails?.candidateName} />
              <div className="flex flex-col">
                <span className="ml-[8px] text-[#252E4B] text-[18px] font-semibold not-italic leading-[24px]">
                  {meetDetails?.candidateName}
                </span>
                <span className="ml-[8px] text-[#5F6989] text-[14px] font-normal not-italic ">
                  {meetDetails?.candidateEmail}
                </span>
              </div>
            </span>
          </div> */}

          <div className=" flex justify-between items-center">
            <TabLayout
              btnList={btnList}
              className="my-4"
              state={view}
              setState={setView}
            />

            {view === "Transcription" && (
              <InputBox
                placeholder={"Search by keyword..."}
                width={"300px"}
                onChange={(e) => searchItems(e.target.value)}
              />
            )}
          </div>

          <div className="bg-[#FFF] p-[20px] rounded-lg h-[calc(100vh-190px)] overflow-scroll scrollbar-candidate">
            {view === "Transcription" ? (
              <>
                {meetingNotes?.map((para, i) => (
                  <div className="mt-[20px] flex">
                    <span className="mt-[20px]">
                      <InitialsLogo
                        str={para.speaker}
                        width="30px"
                        height="30px"
                        borderRadius="50%"
                      />
                    </span>
                    <div className="flex flex-col ml-[20px]">
                      <span className="text-[#5F6989] text-[10px] font-bold">
                        {para?.speaker}
                      </span>
                      <span className="shadow px-[12px]  text-[14px] mt-[5px] font-normal not-italic leading-[18px] py-[10px] rounded-[12px] text-[#252E4B]   bg-[#F2F4F8]">
                        {para?.paragraph}
                      </span>
                    </div>
                  </div>
                ))}
              </>
            ) : view === "Summarization" ? (
              <>
                <span>{summary}</span>
              </>
            ) : view === "Details" ? (
              <>
                <div className="flex justify-between">
                  <div className="w-[50%] border border-[#DADAE4] rounded-lg p-[16px] gap-4">
                    {/* <h1 className="text-md not-italic font-medium text-[#252E4B]">SproutsAI - HR interview</h1> */}
                    <div className="flex justify-between mt-[16px]">
                      <span className="text-sm not-italic font-normal text-[#5F6989]">
                        date
                      </span>
                      <span className=" text-sm not-italic font-normal text-[#252E4B]">
                        {meetDetails?.createdAt &&
                          dateFormatter?.format(
                            new Date(meetDetails?.createdAt),
                          )}
                      </span>
                    </div>
                    <div className="flex justify-between mt-[16px]">
                      <span className="text-sm not-italic font-normal text-[#5F6989]">
                        Time
                      </span>
                      <span className=" text-sm not-italic font-normal text-[#252E4B]">
                        {meetDetails?.createdAt &&
                          timeFormatter.format(
                            new Date(meetDetails?.createdAt),
                          )}
                      </span>
                    </div>
                    <div className="flex justify-between mt-[16px]">
                      <span className="text-sm not-italic font-normal text-[#5F6989]">
                        Duration
                      </span>
                      <span className=" text-sm not-italic font-normal text-[#252E4B]">
                        {meetDetails?.duration}
                      </span>
                    </div>
                    <div className="flex justify-between mt-[16px]">
                      <span className="text-sm not-italic font-normal text-[#5F6989]">
                        Meeting link
                      </span>
                      <span className=" text-sm not-italic font-normal text-[#252E4B]">
                        {meetDetails?.meetingLink}
                      </span>
                    </div>
                    <div className="border border-[#E1E1EE] mt-[16px]"></div>
                    <div className="flex  flex-col  mt-[16px]">
                      <span className="text-sm not-italic font-normal text-[#5F6989]">
                        participants{" "}
                      </span>
                      <div className="flex">
                        <div className="flex flex-col mt-[16px]">
                          {meetDetails?.participantNames?.map((name, i) => (
                            <div className="flex items-center mt-[8px]">
                              <InitialsLogo
                                str={name}
                                width="30px"
                                height="30px"
                                borderRadius="50%"
                              />
                              <span
                                className="text-sm not-italic font-medium text-[#5F6989] ml-[8px]"
                                key={i}
                              >
                                {name}
                              </span>
                            </div>
                          ))}
                        </div>
                      </div>
                      {/* <span className=" text-sm not-italic font-normal text-[#252E4B]">November 7, 2023</span> */}
                    </div>
                  </div>
                </div>
              </>
            ) : view === "video" ? (
              <>
                <div className="flex justify-center items-center w-full h-full">
                  {!videoUrl ? (
                    <div className="flex items-start flex-col">
                      <span className="text-md font-bold my-4">
                        Video preparation is underway and will be accessible
                        shortly.
                      </span>
                      <p className="text-zinc-500">
                        If the video is not available within the next 10
                        minutes, <br />
                        please use the button below to request the video. Please
                        note that the video will be available for retrieval
                        until 24 hours from now.
                      </p>

                      {isDateBefore24Hours(meetDetails?.createdAt) ? (
                        <>
                          {!getvideoStatus ? (
                            <button
                              onClick={() => {
                                console.log("get video");
                                getVideo();
                              }}
                              className="my-4 text-center w-[120px] solid-button-blue mr-[25px] h-[40px] rounded-[8px] bg-[#1369E9] shadow-jobPostButtonShadow flex items-center justify-center"
                            >
                              <span className="text-[#FFF] text-[16px] not-italic font-normal">
                                Get Video
                              </span>
                            </button>
                          ) : (
                            <span className="text-[#5F6989] text-[14px] font-normal not-italic ">
                              "Video request sent. Please wait for a few minutes
                              to"
                            </span>
                          )}
                        </>
                      ) : (
                        <button
                          disabled
                          onClick={() => {}}
                          className="my-4 text-center w-[120px] solid-button-red mr-[25px] h-[40px] rounded-[8px] bg-rose-500 shadow-jobPostButtonShadow flex items-center justify-center"
                        >
                          <span className="text-[#FFF] text-[16px] not-italic font-normal">
                            Expired
                          </span>
                        </button>
                      )}
                    </div>
                  ) : (
                    <video
                      ref={videoRef}
                      src={videoUrl}
                      controls
                      onTimeUpdate={handleTimeUpdate}
                      style={{
                        width: "100%",
                        maxWidth: "600px",
                        borderRadius: "16px",
                      }}
                    >
                      Your browser does not support the video tag.
                    </video>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </>
      ) : (
        <div className="w-full h-full flex justify-center items-center">
          No interview details found
        </div>
      )}
    </main>
  );
};

function ConversationList({ dataList, candidateId, setCandidateId }) {
  const dateFormatter = new Intl.DateTimeFormat("en", {
    day: "2-digit",
    month: "short",
  });
  const timeFormatter = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  const nav = useNavigate();
  const [filterChatList, setFilterChatlist] = useState([]);

  useEffect(() => {
    setFilterChatlist(dataList);
  }, [dataList]);

  function searchItems(keyword) {
    // Convert the keyword to lowercase for case-insensitive search
    const lowerCaseKeyword = keyword?.toLowerCase();

    // Use the filter() method to find items that match the keyword

    const filteredItems = dataList?.filter((item) =>
      item?.candidateName?.toLowerCase().includes(lowerCaseKeyword),
    );
    setFilterChatlist(filteredItems);
  }

  return (
    <div className="mt-[26px] relative  h-[calc(100vh-134px)]  ">
      <div className="overflow-y-scroll h-[calc(100vh-180px)] scrollbar-candidate ">
        <div className="mb-[20px]">
          <InputBox
            placeholder={"Search..."}
            size={"small"}
            onChange={(e) => searchItems(e.target.value)}
          />
        </div>

        {filterChatList &&
          filterChatList?.map((data, i) => (
            <li
              key={`job${i}`}
              onClick={(e) => setCandidateId(data?.id)}
              className={`flex items-start cursor-pointer py-[6px] hover:bg-[#F2F4F8] px-2 border-b border-gray-300 group gap-2 ${data?.id === candidateId && "bg-[#F2F4F8]"}`}
            >
              <div className="flex flex-col w-[200px] grow">
                <span
                  className={`inline-block text-sm not-italic  text-gray-800 truncate ${
                    true ? "font-semibold" : "font-medium"
                  }`}
                  title={data?.candidateName}
                >
                  {data?.candidateName}
                </span>
                <span className=" items-center gap-2 justify-start">
                  <span
                    className={`inline-block text-sm text-[#5F6989] truncate`}
                  >
                    {data?.createdAt &&
                      dateFormatter.format(new Date(data?.createdAt)) +
                        " " +
                        timeFormatter.format(new Date(data?.createdAt))}
                  </span>
                </span>
              </div>
            </li>
          ))}
      </div>
    </div>
  );
}
