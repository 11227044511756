import React, { useContext, useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";

const ConvertProspectModal = ({
  candidateDetail,
  jobId,
  pipeline,
  modalDismiss,
  onSave,
  loading,
}) => {
  const [selectPipeline, setSelectPipeline] = useState("Application Review");

  return (
    <Modal
      heading={"Convert prospect to candidate"}
      subheading={"Select the stage in which you want to move the candidate to"}
      onSave={(e) => onSave(candidateDetail.id, selectPipeline)}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryDisabled: loading,
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
      }}
    >
      <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
        {pipeline?.map((item) => (
          <li
            className={` bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer  ${selectPipeline === item ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 text-black"}`}
            onClick={() => {
              if (selectPipeline === item) {
                setSelectPipeline("");
              } else {
                setSelectPipeline(item);
              }
            }}
          >
            {item}
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default ConvertProspectModal;
