import React from "react";

const AvailabilityHeader = ({ orgDetails }) => {
  const url = String(orgDetails?.domain_url).startsWith("http")
    ? orgDetails?.domain_url
    : `http://${orgDetails?.domain_url}`;

  return (
    <div className="bg-gray-100 py-5 w-full flex justify-between items-center border-b border-b-gray-400 px-4 sm:px-[5%] gap-8">
      <a href={url}>
        <img
          src={orgDetails?.logo}
          alt="Logo"
          className="max-w-[50%] min-w-[10rem] h-auto max-h-[2.25rem] object-contain"
        />
      </a>
      <div
        className={`max-w-[50%] text-center text-white hover:text-white hover:no-underline no-underline font-medium px-6 py-2 rounded-md hover:scale-105 transition duration-300`}
        style={{ backgroundColor: orgDetails?.theme_color ?? "#1369E9" }}
      >
        <a
          href={url}
          className="hover:no-underline no-underline text-white hover:text-white"
          target="_blank"
          rel="noopener noreferrer"
        >
          Company Website
        </a>
      </div>
    </div>
  );
};

export default AvailabilityHeader;
