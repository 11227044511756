import React, { useContext } from "react";
import { CandidatePageContext } from "../../../../lib/context";

const NotesContent = ({ data }) => {
  const { setShowModal } = useContext(CandidatePageContext);
  return (
    <>
      {data?.body && String(data?.body)?.length > 0 && (
        <p className="text-gray-800 text-sm not-italic font-normal w-full whitespace-pre-line	">
          {data?.body}
        </p>
      )}
      <div className="flex !hidden w-full  justify-end gap-2 pt-1">
        <button
          onClick={() =>
            setShowModal({
              type: "notes",
              edit: true,
              data: data?.body,
              id: data?.remote_id,
            })
          }
          data-tooltip-id="tooltip"
          data-tooltip-content={"Edit note"}
          className="btn-sm btn-sec border-button rounded-lg text-sm font-semibold text-gray-900 border border-gray-500 bg-white px-2"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
            />
          </svg>
        </button>
        <button
          onClick={() =>
            setShowModal({ type: "deleteNotes", id: data?.remote_id })
          }
          data-tooltip-id="tooltip"
          data-tooltip-content={"Delete note"}
          className="btn-sm btn-sec border-button rounded-lg text-sm font-semibold text-gray-900 border border-gray-500 bg-white px-2 hover:border-red-800 hover:text-red-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="red"
            className="w-4 h-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default NotesContent;
