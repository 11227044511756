import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import { toast } from "react-toastify";
import Header from "../../components/all/header/Header";
import SideBar from "../../components/all/SideBar";
import PercentDonut from "../../components/analytics/PercentDonut";
const core = require("../../lib/core");

export default function SubmitCandidateList() {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState();
  useEffect(() => {
    getEvalList();
  }, []);
  async function getEvalList() {
    try {
      let api_data = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_SUBMITTED_CANDIDATES + params.jobId,
        1,
      );
      console.log(api_data?.data);
      setDataList(api_data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  }

  async function setAction(candId, state) {
    try {
      let api_data = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_UPDATE_SUBMITTED_APPLICANT_STATUS +
          candId +
          "/" +
          params.jobId,
        1,
        {
          status: state,
        },
      );
      console.log(api_data);
      if (api_data.data) {
        toast.success(api_data?.data.message);
      } else {
        toast.error(api_data.error.message);
      }
    } catch (err) {
      console.log(err);
      toast.error(err);
    }
  }

  return (
    <div className="page col-xs-12" id="submittedList">
      <div className="component nav-bar col-xs-12" id="header">
        <div className="col-xs-3 no-padding left">
          <img src={""} className="img-responsive" />
        </div>
        <h3 className="col-xs-9">
          <Link to={dataList?.jobUrl} target="_blank">
            {dataList?.position} @ {dataList?.company?.name}
          </Link>
        </h3>
      </div>
      {isLoading ? (
        <div className="loader">
          <PropagateLoader color="#0b53dd" />
        </div>
      ) : (
        <main className="main-content col-xs-12 no-padding">
          <div className="content-table col-xs-12 no-padding">
            <div className="header-row col-xs-12 no-padding">
              <h5 className="col-xs-2 name">Candidate Name</h5>
              <h5 className="col-xs-2 education">Education</h5>
              <h5 className="col-xs-2 experience">Experience</h5>
              <h5 className="col-xs-2 skills">Skills</h5>
              <h5 className="col-xs-2 links">Links</h5>
              <h5 className="col-xs-2 actions">Actions</h5>
            </div>
            <div className="content-data col-xs-12 no-padding">
              {dataList?.candidates?.map((item, idx) => (
                <div className="data-row col-xs-12 no-padding" key={idx}>
                  <div className="col-xs-2 name">
                    <PercentDonut
                      id={"match_" + item?.id}
                      chartSize={74}
                      radius={(74 - 10) / 2}
                      width={5}
                      percentData={item?.matchScore * 100}
                      fontSize={16}
                    />
                    <span className="name_txt"> {item.name}</span>
                  </div>
                  <div className="col-xs-2 education">
                    {item.education.map((edu, id) => (
                      <div className="col-xs-12 no-padding" key={id}>
                        <span className="degree">{edu.Degree}</span> from{" "}
                        <span className="institute">{edu.Institute}</span>
                      </div>
                    ))}
                  </div>
                  <div className="col-xs-2 experience">
                    {item.experience.map((edu, id) => (
                      <div className="col-xs-12 no-padding" key={id}>
                        <span className="position">{edu["Job Position"]}</span>{" "}
                        at{" "}
                        <span className="company">{edu["Company Name"]}</span>
                      </div>
                    ))}
                  </div>
                  <div className="col-xs-2 skills">
                    {item?.skills[0]?.keySkills?.slice(0, 3)?.map((itx, x) => (
                      <span className="skill" key={x}>
                        {itx.label}
                      </span>
                    ))}
                  </div>
                  <div className="col-xs-2 links">
                    <a
                      href={item.resume}
                      target="_blank"
                      className="resume"
                      data-tooltip="Resume"
                      rel="noreferrer"
                    >
                      <img src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/resume.svg" />
                    </a>
                    <a
                      href={item.evaluationUrl}
                      target="_blank"
                      className="eval"
                      data-tooltip="Evaluation Report"
                      rel="noreferrer"
                    >
                      <img src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/eval.svg" />
                    </a>
                    <a
                      href=""
                      target="_blank"
                      className="recording"
                      data-tooltip="Interview Recording"
                    >
                      <img src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/video.svg" />
                    </a>
                  </div>
                  <div className="col-xs-2 actions">
                    <a
                      onClick={() => setAction(item.id, "Accepted")}
                      className="approve"
                      data-tooltip="Accept Candidate"
                    >
                      <img src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/accept_candidate.svg" />
                    </a>
                    <a
                      onClick={() => setAction(item.id, "Rejected")}
                      className="reject"
                      data-tooltip="Reject Candidate"
                    >
                      <img src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/reject_candidate.svg" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </main>
      )}
    </div>
  );
}
