import React, { useEffect, useState } from "react";
import CreateLeadModal from "./CreateLeadModal";
import { MdOutlineFileUpload } from "react-icons/md";
import { ScaleLoader } from "react-spinners";
const core = require("../../lib/core");

const ListItems = () => {
  const clients = [
    {
      name: "John Doe",
      email: "john.doe@example.com",
      linkedin: "https://www.linkedin.com/in/johndoe",
      sequenceStatus: "Active",
      paymentStatus: "Paid",
      creditRemaining: "$500",
    },
    {
      name: "Jane Smith",
      email: "jane.smith@example.com",
      linkedin: "https://www.linkedin.com/in/janesmith",
      sequenceStatus: "Paused",
      paymentStatus: "Pending",
      creditRemaining: "$250",
    },
    {
      name: "Bob Johnson",
      email: "bob.johnson@example.com",
      linkedin: "https://www.linkedin.com/in/bobjohnson",
      sequenceStatus: "Completed",
      paymentStatus: "Paid",
      creditRemaining: "$0",
    },
  ];

  const [showModal, setShowModal] = useState(false);

  const onModalDismiss = () => {
    setShowModal(false);
  };

  const [leads, setLeads] = useState([]);

  const fetchLeads = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_ALL_LEADS,
        1,
      );
      if (json.data) {
        setLeads(json.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  const [csvFile, setCsvFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const attachResume = async (file) => {
    let formData = new FormData();
    formData.append("files", file);

    setLoading(true);
    try {
      const token = await core.getData(core.data.ACCESS_TOKEN);
      let res = await fetch(core.USER_API.POST_BULK_LEAD, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      if (res.status === 200) {
        setLoading(false);
        fetchLeads();
      }
    } catch (error) {
      setLoading(false);
      fetchLeads();
      console.log("error", error);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      attachResume(file);
      setCsvFile(file);
    }
  };

  return (
    <div className="bg-gray-100 p-2 ">
      <div className=" mx-auto">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">Leads Details</h1>

          <div className="mb-6 flex space-x-4">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowModal("addLead")}
            >
              Manual Entry
            </button>
            <button
              onClick={() => document.getElementById("fileInput").click()}
              className="flex items-center bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
              {loading ? (
                <ScaleLoader height={14} color="#ffffff" loading={true} />
              ) : (
                <>
                  <MdOutlineFileUpload className="mr-2 h-4 w-4" /> Upload CSV
                </>
              )}
            </button>
            <input
              id="fileInput"
              type="file"
              accept=".csv"
              onChange={handleFileUpload}
              style={{ display: "none" }}
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-gray-200">
              <tr>
                <th className="px-4 py-2 text-left">Name</th>
                <th className="px-4 py-2 text-left">Email</th>
                <th className="px-4 py-2 text-left">LinkedIn</th>
                <th className="px-4 py-2 text-left">Sequence Status</th>
                <th className="px-4 py-2 text-left">Payment Status</th>
                <th className="px-4 py-2 text-left">Credit Remaining</th>
                <th className="px-4 py-2 text-left">Account status</th>
              </tr>
            </thead>
            <tbody>
              {leads?.map((client, index) => (
                <tr key={index} className="border-b hover:bg-gray-50">
                  <td className="px-4 py-2">{client?.name}</td>
                  <td className="px-4 py-2">{client?.email?.[0]}</td>
                  <td className="px-4 py-2">
                    <a
                      href={client?.linkedin}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      LinkedIn Profile
                    </a>
                  </td>
                  <td className="px-4 py-2">{client?.sequenceStatus ?? "-"}</td>
                  <td className="px-4 py-2">
                    {client?.payment_done ? "Paid" : "Pending"}
                  </td>
                  <td className="px-4 py-2">{client?.credit_left ?? "-"}</td>
                  <td className="px-4 py-2">
                    {client?.account_enabled ? "Active" : "Inactive"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {showModal === "addLead" ? (
        <CreateLeadModal modalDismiss={onModalDismiss} />
      ) : null}
    </div>
  );
};

export default ListItems;
