import { useNavigate, useParams } from "react-router-dom";
import TabLayout from "../../../layouts/TabLayout";
import TabButtons from "./sidebar/TabButtons";
import { FaGoogle, FaLinkedin } from "react-icons/fa";
import { BiLogoGmail } from "react-icons/bi";
import { BsChatLeftTextFill } from "react-icons/bs";
import { Tooltip as ReactTooltip } from "react-tooltip";

const TemplateSidebar = ({ templates }) => {
  const nav = useNavigate();
  const openId = useParams().id;
  const btnList = [
    {
      name: "Job",
      id: "Job",
    },
    {
      name: "Email",
      id: "Email",
    },
  ];
  return (
    <>
      <ReactTooltip
        id="my-tooltip-template-sidebar-1"
        noArrow={true}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: "400",
        }}
        place="top"
      />
      <div className="w-full mt-8 grow divide-y overflow-y-scroll scrollbar-hide">
        {templates?.map((template, index) => (
          <button
            onClick={() => nav(template?._id)}
            key={template._id}
            style={
              template?._id == openId
                ? { backgroundColor: "#F2F4F8" }
                : { backgroundColor: "white" }
            }
            className="w-full h-auto flex flex-col p-2 bg-white hover:bg-gray-300 border-gray-500"
          >
            <h2 className="text-sm not-italic text-gray-800 truncate font-semibold flex items-center gap-2">
              <span
                data-tooltip-id="my-tooltip-template-sidebar-1"
                data-tooltip-content={template?.template_type}
              >
                {template?.template_type === "Linkedin" ? (
                  <FaLinkedin className="text-blue-800" />
                ) : template?.template_type === "Email" ? (
                  <BiLogoGmail className="text-red-600" />
                ) : (
                  <BsChatLeftTextFill className="text-slate-600" />
                )}
              </span>
              {template?.template_name}
            </h2>
            <h3 className="inline-block text-sm text-[#5F6989] truncate">
              {template?.subject}
            </h3>
          </button>
        ))}
      </div>
      <TabButtons />
    </>
  );
};

export default TemplateSidebar;
