import React, { useState } from "react";
import Modal from "../../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import TextAreaBox from "../../../../layouts/TextAreaBox";
const core = require("../../../../lib/core");

function Notes({ modalDismiss, id }) {
  const [content, setContent] = useState("");
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const createNotes = async () => {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_CANDIDATE_NOTES,
        1,
        {
          note: content,
          matchprofile: id,
        },
      );
      if (json?.data) {
        setLoading(false);
        setMessage({ type: "success", message: "Note added successfully" });
        setTimeout(() => {
          setMessage({});
          modalDismiss();
        }, 1000);
      } else {
        setLoading(false);
        setMessage({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
    }
  };
  return (
    <Modal
      heading={"Add new note"}
      subheading={"Enter your notes referring to the candidate"}
      modalDismiss={() => modalDismiss()}
      onSave={createNotes}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Save"
        ),
        primaryDisabled: loading,
      }}
    >
      <TextAreaBox
        value={content}
        size="200px"
        onChange={(e) => setContent(e.target.value)}
        placeholder="Enter your notes here"
      />
      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
}

export default Notes;
