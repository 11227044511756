import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { IoMdArrowDropdown } from "react-icons/io";
import InputBox from "./InputBox";

function SelectAndSearch({
  placeholder = "Search...",
  data = [],
  setSelectedTemplate,
  defalultValue,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    if (data) {
      setResults(data);
    }
  }, [data]);

  useEffect(() => {
    if (defalultValue) {
      setSearchTerm(defalultValue);
    }
  }, [defalultValue]);

  const dropdownref = useRef(null);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);

    let filteredResults = data.filter((item) =>
      item?.name.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    setResults(filteredResults);
  };

  const clearSearch = () => {
    setSearchTerm("");
    setShowDropdown(false);
    setSelectedTemplate("");
  };

  const handleClickOutside = (e) => {
    if (dropdownref.current && !dropdownref.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="relative w-full " ref={dropdownref}>
      <div className="relative">
        <InputBox
          type="text"
          placeholder={placeholder}
          value={searchTerm}
          size={"medium"}
          onChange={handleInputChange}
          onFocus={(e) => setShowDropdown(true)}
          //   onBlur={e => setShowDropdown(false)}
        />
        {/* <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} /> */}
        {/* {searchTerm && (
          <button
            onClick={clearSearch}
            className="absolute right-8 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-700"
          >
            <IoCloseSharp size={20} />
          </button>
        )} */}
        <button
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-gray-700"
          onClick={() => setShowDropdown(!showDropdown)}
        >
          <IoMdArrowDropdown size={20} />
        </button>
      </div>
      {showDropdown && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-60 overflow-auto">
          {results.length > 0 ? (
            results?.map((item, index) => (
              <div
                key={index}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                onClick={() => {
                  setSearchTerm(item?.name);
                  setSelectedTemplate(item?.id);
                  setShowDropdown(false);
                  setResults(data);
                }}
              >
                {item?.name}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500 italic">
              No results found
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default SelectAndSearch;
