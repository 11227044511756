import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

const core = require("../../../lib/core");

const initialState = {
  reason: "",
  feedback: "",
};
export default function DeleteAccount(props) {
  const nav = useNavigate();

  const [formInput, setFormInput] = useState(initialState);

  function setInput(key, value) {
    setFormInput({ ...formInput, [key]: value });
  }

  const [user, setUser] = useState([]);
  useEffect(() => {
    setUser(props.user);
  });

  async function deleteUserAccount() {
    let json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_DELETE_USER,
      1,
      formInput,
    );
    if (json?.data) {
      core.logout();
      nav("/");
    }
  }

  console.log(formInput);
  return (
    <div className="col-xs-12" id="general-setting">
      <div className="col-xs-12  sub-sections">
        <div className="heading-section-delete col-xs-12 no-padding">
          <button className="back-button" onClick={props.props}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M16.0093 19.4977L8.70126 12.0007L16.0093 4.50372C16.1401 4.36978 16.2133 4.18997 16.2133 4.00272C16.2133 3.81548 16.1401 3.63567 16.0093 3.50172C15.9457 3.43687 15.8699 3.38534 15.7862 3.35017C15.7025 3.31499 15.6126 3.29688 15.5218 3.29688C15.431 3.29688 15.3411 3.31499 15.2574 3.35017C15.1737 3.38534 15.0978 3.43687 15.0343 3.50172L7.25826 11.4772C7.12174 11.6173 7.04534 11.8051 7.04534 12.0007C7.04534 12.1963 7.12174 12.3842 7.25826 12.5242L15.0328 20.4997C15.0964 20.565 15.1724 20.617 15.2564 20.6524C15.3404 20.6878 15.4306 20.7061 15.5218 20.7061C15.6129 20.7061 15.7032 20.6878 15.7871 20.6524C15.8711 20.617 15.9472 20.565 16.0108 20.4997C16.1416 20.3658 16.2148 20.186 16.2148 19.9987C16.2148 19.8115 16.1416 19.6317 16.0108 19.4977L16.0093 19.4977Z"
                fill="#001B4B"
                stroke="#001B4B"
              />
            </svg>
          </button>
          <span>Delete account</span>
        </div>
        <span className="col-xs-12 no-padding top-heading">
          {user?.name}, we’re sorry to see you go.
        </span>
        <label className="label-input">
          Just a quick reminder, deleting your account means you’ll lose
          opportunity with your job selections. You’ll also lose any
          recommendations and job suggestions in future.
        </label>

        <span className="col-xs-12 no-padding servey-input">
          Can you tell us why you’re deleting your account?
        </span>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input
            type="radio"
            name="reason"
            onChange={(e) => setInput("reason", "I have found the job.")}
          />
          <label htmlFor="">I have found the job.</label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input
            type="radio"
            name="reason"
            onChange={(e) => setInput("reason", "I have privacy issues.")}
          />
          <label htmlFor="">I have privacy issues.</label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input
            type="radio"
            name="reason"
            onChange={(e) => setInput("reason", "I have duplicate account.")}
          />
          <label htmlFor="">I have duplicate account.</label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input
            type="radio"
            name="reason"
            onChange={(e) =>
              setInput(
                "reason",
                "I'm receiving spam or other unwanted interactions",
              )
            }
          />
          <label htmlFor="">
            I'm receiving spam or other unwanted interactions
          </label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input
            type="radio"
            name="reason"
            onChange={(e) => setInput("reason", "Other")}
          />
          <label htmlFor="">Other</label>
        </div>
        <span className="col-xs-12 no-padding servey-input">
          Your feedback is important. Anything else you'd like us to know?
        </span>
        <textarea
          className="feedback-textarea"
          name=""
          id=""
          cols="30"
          rows="3"
          onChange={(e) => setInput("feedback", e.target.value)}
        ></textarea>

        <div className="col-xs-12 no-padding checkbox-sec delete-button">
          <button
            className="border border-button border-[#DADAE4] py-[8px] px-[14px] mt-[18px] bg-[#FFF] rounded-[8px] shadow-jobPostButtonShadow"
            onClick={deleteUserAccount}
          >
            <span className="text-[16px] text-[#1369E9] font-normal not-italic">
              Save
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
