import React from "react";

const colorArray = [
  "#61A3BA",
  "#A9A9A9",
  "#0C356A",
  "#04364A",
  "#99A98F",
  "#245953",
  "#8785A2",
  "#8D7B68",
];

function stringToColor(string) {
  if (!colorArray || colorArray.length === 0) {
    return null;
  }

  let hash = 0;
  for (let i = 0; i < string?.length; i += 1) {
    hash = string?.charCodeAt(i) + ((hash << 5) - hash);
  }

  const index = Math.abs(hash) % colorArray.length;
  return colorArray[index];
}

function stringAvatar(name, width, height, borderRadius, fontSize, company) {
  const bgColor = company ? "#04364A" : stringToColor(name);
  const initials =
    name?.split(" ").length > 1
      ? `${String(name?.split(" ")[0][0])?.toUpperCase()}${String(name?.split(" ")[1][0])?.toUpperCase()}`
      : `${String(name?.split(" ")[0][0]).toUpperCase()}`;

  return {
    style: {
      backgroundColor: bgColor,
      width,
      height,
      borderRadius,
      fontSize,
      fontWeight: "bold",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    children: initials,
  };
}

export default function InitialsLogo({
  id = "initialsLogo",
  width = "40px",
  height = "40px",
  str = "",
  styleClass = "",
  borderRadius = "6px",
  fontSize = "16px",
  variant = "",
  company = "",
}) {
  const styles = str
    ? stringAvatar(str, width, height, borderRadius, fontSize, company).style
    : {
        backgroundColor: "#04364A",
        width,
        height,
        borderRadius,
        fontSize,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      };
  const children = str
    ? stringAvatar(str, width, height, borderRadius, fontSize, company).children
    : "?";

  return (
    <div
      id={id}
      className={`flex items-center justify-center text-white ${styleClass}`}
      style={styles}
      alt={str}
    >
      {children?.length > 1 ? children[0] : children}
    </div>
  );
}
