import React, { useEffect, useState } from "react";
import InputBox from "../../../layouts/InputBox";
import Modal from "../../../components/modal/Modal";
import { toast } from "react-toastify";

const FilterModal = ({
  heading,
  placeholder,
  data,
  setData,
  visibleFilter,
  setVisibleFilter,
  filterFunction, // Function for filtering logic
  clearSearchTerm,
  setClearSearchTerm,
}) => {
  const [searchName, setSearchName] = useState("");

  const handleFilter = () => {
    if (searchName === "") return;
    const filteredData = filterFunction(data, searchName);
    if (filteredData.length === 0) {
      toast.info("No results found for the applied filter");
      setSearchName("");
      setData(data); // Reset to original data when no results found
      setVisibleFilter(false); // Close the modal
      return;
    }
    setClearSearchTerm(false);
    setData(filteredData);
    setVisibleFilter(false); // Close the modal after filtering
  };

  const handleFilterCancel = () => {
    setVisibleFilter(false); // Correctly close the modal
  };

  const ClearFilter = () => {
    setSearchName("");
    setData(data); // Reset to original data
  };

  useEffect(() => {
    if (clearSearchTerm && searchName !== "") {
      ClearFilter();
    }
  }, [clearSearchTerm]);

  if (!visibleFilter) return null; // Render nothing if not visible

  return (
    <Modal
      heading={heading}
      noBtns={true}
      message={placeholder}
      modalDismiss={handleFilterCancel} // Ensure modalDismiss prop works correctly
      style={{ maxWidth: "410px" }}
    >
      <div className="flex flex-row items-center">
        <InputBox
          placeholder={placeholder}
          size="medium"
          value={searchName}
          onChange={(e) => setSearchName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              handleFilter();
            }
          }}
          width={"300px"}
        />

        <button
          onClick={handleFilter} // Filter Button
          className="ml-2 p-1 h-[34px] py-[8px] px-[10px] rounded-[8px] bg-[#1369E9] shadow-jobPostButtonShadow flex items-center justify-center"
        >
          <span className="text-[#FFF] text-[16px] not-italic font-normal">
            Filter
          </span>
        </button>
      </div>
    </Modal>
  );
};

export default FilterModal;
