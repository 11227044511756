import React, { useState, useEffect } from "react";
import { MdSearch } from "react-icons/md";
import { MdDelete } from "react-icons/md";
const JobFilter = ({
  onFilter,
  locations,
  positions,
  workplaces,
  jobTypes,
  orgDetails,
}) => {
  const [location, setLocation] = useState("");
  const [position, setPosition] = useState("");
  const [workplace, setWorkplace] = useState("");
  const [jobType, setJobType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredPositions, setFilteredPositions] = useState([]);

  useEffect(() => {
    onFilter({ location, position, workplace, jobType });
  }, [location, position, workplace, jobType]);

  useEffect(() => {
    setFilteredPositions(
      positions?.filter((pos) =>
        pos?.toLowerCase()?.includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, positions]);

  const [theme, setTheme] = useState("bg-blue-800");

  useEffect(() => {
    if (orgDetails?.theme_color) setTheme(orgDetails?.theme_color);
  }, [orgDetails]);

  const clearFilters = () => {
    setLocation("");
    setPosition("");
    setWorkplace("");
    setJobType("");
    setSearchTerm("");
  };

  return (
    <div className="flex flex-col justify-center py-2 items-center w-full lg:gap-6 gap-4">
      <div className="relative w-full">
        <label htmlFor="" className="block text-gray-800 font-semibold mb-2">
          Search Position
        </label>
        <div className="relative">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search Position..."
            className="p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline border-gray-400 rounded-lg w-full placeholder-gray-800 focus:!outline-1"
            style={{
              borderColor: theme,
              outlineColor: theme,
              transitionProperty: "outline-width",
              transitionTimingFunction: "ease-in-out",
            }}
          />
          <MdSearch className="absolute right-3 text-lg top-1/2 transform -translate-y-1/2 text-gray-800" />
        </div>
        {searchTerm && (
          <div className="absolute top-full left-0 w-full bg-white border border-gray-600 rounded-md max-h-40 overflow-y-auto z-10">
            {filteredPositions.length > 0 ? (
              filteredPositions.map((pos, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setPosition(pos);
                    setSearchTerm("");
                  }}
                  className="p-2 cursor-pointer hover:bg-gray-200"
                >
                  {pos}
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-600">No Matches</div>
            )}
          </div>
        )}
      </div>

      <div className="w-full relative">
        <label htmlFor="" className="block text-gray-800 font-semibold mb-2">
          Select location
        </label>
        <select
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          className="p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline border-gray-400 rounded-lg w-full placeholder-gray-600 focus:!outline-1 "
          style={{
            borderColor: theme,
            outlineColor: theme,
            transitionProperty: "outline-width",
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <option value="">Select Location</option>
          {locations.map((loc, index) => (
            <option key={index} value={loc}>
              {loc}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full relative">
        <label htmlFor="" className="block text-gray-800 font-semibold mb-2">
          Select workplace
        </label>
        <select
          value={workplace}
          onChange={(e) => setWorkplace(e.target.value)}
          className="p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline border-gray-400 rounded-lg w-full placeholder-gray-600 focus:!outline-1 "
          style={{
            borderColor: theme,
            outlineColor: theme,
            transitionProperty: "outline-width",
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <option value="">Select Workplace</option>
          {workplaces.map((work, index) => (
            <option key={index} value={work}>
              {work}
            </option>
          ))}
        </select>
      </div>

      <div className="w-full relative">
        <label htmlFor="" className="block text-gray-800 font-semibold mb-2">
          Select job type
        </label>
        <select
          value={jobType}
          onChange={(e) => setJobType(e.target.value)}
          className="p-3 border hover:shadow-md hover:!outline-1 outline-0 !outline border-gray-400 rounded-lg w-full placeholder-gray-600 focus:!outline-1"
          style={{
            borderColor: theme,
            outlineColor: theme,
            transitionProperty: "outline-width",
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <option value="">Select Job Type</option>
          {jobTypes.map((type, index) => (
            <option key={index} value={type}>
              {type}
            </option>
          ))}
        </select>
      </div>
      <button
        onClick={clearFilters}
        className="p-2 mt-2 gap-3 flex justify-center items-center w-full rounded-md text-white text-base font-semibold border border-defaultBg outline outline-0 hover:outline-1"
        style={{ backgroundColor: theme, outlineColor: theme }}
      >
        <MdDelete className="font-bold text-lg" />
        Clear All
      </button>
    </div>
  );
};

export default JobFilter;
