import React, { useEffect, useState } from "react";
import InputBox from "../../../../layouts/InputBox";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
function ApplicantsFilter({
  setCandidates,
  filterData,
  setShowFilter,
  searchName,
  setSearchName,
  setSearchTitle,
  searchTitle,
  sortby,
  setSortby,
  thumbsUpValue,
  setThumbsUpValue,
  sortBytime,
  setSortByTime,
  minValue,
  set_minValue,
  maxValue,
  set_maxValue,
  filterLocation,
  setFilterLocation,
  filterLocationMatch,
  setFilterLocationMatch,
  fetchCandidates,
  favouriteFilter,
  setFavouriteFilter,
  setFilterCount,
}) {
  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };

  const [resetFlag, setResetFlag] = useState(false);

  const handleReset = () => {
    setSearchName("");
    setSearchTitle("");
    setSortby("");
    setThumbsUpValue(0);
    setSortByTime("");
    setFavouriteFilter(false);
    set_minValue(0);
    set_maxValue(100);
    setFilterLocation("");
    setFilterLocationMatch("");

    // Set reset flag to true after resetting all values
    setResetFlag(true);
  };

  useEffect(() => {
    // Call fetchCandidates if all values are reset
    if (resetFlag) {
      fetchCandidates();
      setShowFilter(false);
      setFilterCount(0);
      // Reset the flag after calling fetchCandidates
      setResetFlag(false);
    }
  }, [resetFlag]); // useEffect dependency
  return (
    <section
      id="candidateDrawer"
      className="relative block z-50 w-screen"
      aria-labelledby="slide-over-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="pointer-events-none fixed inset-y-0 right-0 flex w-1/3 max-w-full pl-10 rounded-[18px] ">
        <div className="pointer-events-auto relative w-full">
          <div className="flex h-full rounded-[18px] flex-col  bg-white py-[24px] px-[5px] shadow-xl">
            <h5 className="text-[22px] text-[#16182F] font-semibold not-italic leading-[34px] absolute px-[22px]">
              Filters
            </h5>

            <div className="h-[calc(100%-84px)] mt-[42px] overflow-y-auto scrollbar-candidate px-[22px]">
              <div className="">
                <InputBox
                  placeholder={"Search candidates"}
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      fetchCandidates();
                      setShowFilter(false);
                    }
                  }}
                />
              </div>

              {/* Sort */}
              <div className="mt-[16px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                  Sortby
                </h6>
                <div className="mt-[12px] flex justify-between">
                  <div className="flex justify-between w-full">
                    <div className="flex items-center w-1/2">
                      <input
                        type="radio"
                        id="recentsort"
                        name="sort"
                        checked={sortby === "mostRecent"}
                        onChange={(e) => {
                          setSortby("mostRecent");
                        }}
                      />
                      <label
                        className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                        htmlFor="recentsort"
                      >
                        Most recent
                      </label>
                    </div>
                    <div className="flex items-center w-1/2">
                      <input
                        type="radio"
                        name="sort"
                        id="relevantsort"
                        checked={sortby === "mostRelevant"}
                        onChange={(e) => {
                          setSortby("mostRelevant");
                        }}
                      />
                      <label
                        className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                        htmlFor="relevantsort"
                      >
                        Most relevant
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* Feedback Type */}
              <div className="mt-[16px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                  Feedback Type
                </h6>
                <div className=" mt-[16px] flex justify-between w-full">
                  <div className="flex items-center w-1/2" id="thumbs_up_radio">
                    <input
                      type="radio"
                      id="thumbs_up" // Add this line
                      name="thumbs" // Change this to be the same for both
                      value={1}
                      checked={thumbsUpValue === 1}
                      onChange={() => setThumbsUpValue(1)}
                      className="cursor-pointer"
                    />
                    <label
                      className="flex flex-row gap-x-1 items-center mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2 cursor-pointer"
                      htmlFor="thumbs_up"
                    >
                      <LuThumbsUp
                        color="#5F6989"
                        className="candidate-like-icon w-5 h-5"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"Positive"}
                      />
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      id="thumbs_down" // Add this line
                      name="thumbs" // Change this to be the same for both
                      value={2}
                      checked={thumbsUpValue === 2}
                      onChange={() => setThumbsUpValue(2)}
                      className="cursor-pointer"
                    />
                    <label
                      className="flex flex-row gap-x-1 items-center mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2 cursor-pointer"
                      htmlFor="thumbs_down"
                    >
                      <LuThumbsDown
                        color="#5F6989"
                        className="candidate-unlinke-icon w-5 h-5"
                        data-tooltip-id="my-tooltip-4"
                        data-tooltip-content={"Negative"}
                      />
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-[16px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800"></h6>
                <div className="mt-[12px] flex justify-between">
                  <div className="flex justify-between w-full">
                    <div className="flex items-center ">
                      <input
                        type="checkbox"
                        className=""
                        id="savedCandidates"
                        name="sort"
                        checked={favouriteFilter}
                        onChange={(e) => {
                          setFavouriteFilter(e.target.checked);
                        }}
                      />
                      <label
                        className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                        htmlFor="savedCandidates"
                      >
                        Saved candidates
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-[24px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                  Anytime
                </h6>

                <div className="flex justify-between">
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="anytime"
                      checked={sortBytime === "anyTime"}
                      onChange={(e) => {
                        setSortByTime("anyTime");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="anytime"
                    >
                      Any time
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="pastMonth"
                      checked={sortBytime === "pastMonth"}
                      onChange={(e) => {
                        setSortByTime("pastMonth");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="pastMonth"
                    >
                      Past month
                    </label>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="pastWeek"
                      checked={sortBytime === "pastWeek"}
                      onChange={(e) => {
                        setSortByTime("pastWeek");
                      }}
                    />
                    <label
                      className="text-gray-800 mt-[9px] text-sm font-normal not-italic ml-2"
                      htmlFor="pastWeek"
                    >
                      Past week
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="past2Hours"
                      checked={sortBytime === "past2Hours"}
                      onChange={(e) => {
                        setSortByTime("past2Hours");
                      }}
                    />
                    <label
                      className="text-gray-800 mt-[9px] text-sm font-normal not-italic ml-2"
                      htmlFor="past2Hours"
                    >
                      Past 2 hours
                    </label>
                  </div>
                </div>
              </div>

              {/* <div className="mt-[24px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                  Sequence staus
                </h6>

                <div className="flex justify-between">
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="anytime"
                      checked={sortBytime === "anyTime"}
                      onChange={(e) => {
                        setSortByTime("anyTime");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="anytime"
                    >
                      Not sent yet
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="pastMonth"
                      checked={sortBytime === "pastMonth"}
                      onChange={(e) => {
                        setSortByTime("pastMonth");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="pastMonth"
                    >
                      Pending
                    </label>
                  </div>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="pastWeek"
                      checked={sortBytime === "pastWeek"}
                      onChange={(e) => {
                        setSortByTime("pastWeek");
                      }}
                    />
                    <label
                      className="text-gray-800 mt-[9px] text-sm font-normal not-italic ml-2"
                      htmlFor="pastWeek"
                    >
                      Responded
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="duration"
                      id="past2Hours"
                      checked={sortBytime === "past2Hours"}
                      onChange={(e) => {
                        setSortByTime("past2Hours");
                      }}
                    />
                    <label
                      className="text-gray-800 mt-[9px] text-sm font-normal not-italic ml-2"
                      htmlFor="past2Hours"
                    >
                      Completed
                    </label>
                  </div>
                </div>
              </div> */}

              <div className="mt-[24px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800">
                  Location match
                </h6>

                <div className="flex justify-between">
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="locationMatch"
                      id="yes"
                      checked={filterLocationMatch === "yes"}
                      onChange={(e) => {
                        setFilterLocationMatch("yes");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="yes"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex items-center w-1/2">
                    <input
                      type="radio"
                      name="locationMatch"
                      id="Not Provided"
                      checked={filterLocationMatch === "Not Provided"}
                      onChange={(e) => {
                        setFilterLocationMatch("Not Provided");
                      }}
                    />
                    <label
                      className="mt-[9px] text-gray-800 text-sm font-normal not-italic ml-2"
                      htmlFor="Not Provided"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="mt-[24px] py-[12px] border-b border-b-[#E1E1EE]">
                <h6 className="text-base font-medium not-italic leading-[22px] text-gray-800 mb-[5px]">
                  Location
                </h6>
                <InputBox
                  placeholder={"Search location"}
                  value={filterLocation}
                  onChange={(e) => setFilterLocation(e.target.value)}
                />
              </div>
            </div>

            <div className="flex justify-end py-[16px] px-[22px]">
              <button
                className="text-sm font-normal not-italic leading-[22px] text-gray-800 mr-[30px]"
                onClick={(e) => {
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                className="py-[4px] px-[8px] rounded-[8px]   shadow-jobPostButtonShadow bg-[#1369E9] border border-[#1369E9] text-sm font-normal not-italic leading-[24px] text-[#FFF]"
                onClick={(e) => {
                  fetchCandidates();
                  setShowFilter(false);
                }}
              >
                Show results
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ApplicantsFilter;
