import { Link } from "react-router-dom";
import SavedJobs from "../../../screens/candidate/dashboard/SavedJobs";
import Bookmark from "../../assets/Bookmark";
import ApplyBtn from "./ApplyBtn";
import LocationSvg from "../../assets/candidate/Location.svg";
import TimerSvg from "../../assets/candidate/TimerSvg.svg";
import ActiveJobSvg from "../../assets/candidate/ActiveJob.svg";
import InitialsLogo from "../../all/InitialsLogo";
import { useState } from "react";

const moment = require("moment");
const now = moment();

export default function JobPost({ job, reload_function }) {
  const timeDifference = (difference) => {
    // Format the time difference
    if (difference < 1000) {
      return "Just now";
    } else if (difference < 60000) {
      return `${Math.floor(difference / 1000)} seconds ago`;
    } else if (difference < 3600000) {
      return `${Math.floor(difference / 60000)} minutes ago`;
    } else if (difference < 86400000) {
      return `${Math.floor(difference / 3600000)} hours ago`;
    } else if (difference < 2592000000) {
      return `${Math.floor(difference / 86400000)} days ago`;
    } else if (difference < 31536000000) {
      return `${Math.floor(difference / 2592000000)} months ago`;
    } else {
      return `${Math.floor(difference / 31536000000)} years ago`;
    }
  };

  return (
    <div className="col-xs-12 job-post no-padding">
      <Link to={`/jobpost/${job?.id}`}>
        <div className="section-1 no-padding col-xs-12">
          <span className="company_name">{job?.company?.name}</span>
          <span className="right">
            <span className="status">
              <img
                src={ActiveJobSvg}
                className="img-responsive"
                alt="actively hiring"
              />
              {/* <img src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/actively_hiring.svg" className="img-responsive" alt="actively hiring"/> */}
              <span className="status-text"> {"Actively Hiring"}</span>
            </span>
            <span className="bookmark">
              <Bookmark
                bookmarked={job?.saved}
                job_id={job?.id}
                reload_function={reload_function}
              />
            </span>
          </span>
        </div>
        <div className="col-xs-12 section-2 no-padding">
          <div className="col-xs-9 no-padding">
            <div className="col-xs-3 imageLogo no-padding">
              <InitialsLogo
                className="img-responsive"
                str={job.company?.name}
                width={60}
                height={60}
                bgRounded="12px"
                fontSize={28}
              />
            </div>
            <div className="col-xs-9 text-section ">
              <h4 className="col-xs-12 no-padding position">{job?.position}</h4>
              <h5 className="col-xs-12 no-padding location">
                <img
                  src={LocationSvg}
                  // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/location.svg"
                  alt="location"
                  className="img-responsive"
                />
                {job?.location?.map((loc, i) => loc?.name)?.join(", ")}
              </h5>
              <h5 className="col-xs-12 no-padding datePosted">
                <img
                  // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/posted.svg"
                  src={TimerSvg}
                  alt="posted on"
                  className="img-responsive"
                />

                {timeDifference(new Date() - new Date(job?.createdAt))}
              </h5>
            </div>
          </div>
          <div className="col-xs-3 button-section no-padding ">
            <ApplyBtn
              applied={false}
              job_id={job?.id}
              reload_function={reload_function}
            />
          </div>
        </div>
      </Link>
    </div>
  );
}
