import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";
import { ScaleLoader } from "react-spinners";

const core = require("../../lib/core");

function EditRoleModal({
  modalDismiss,
  allRoles,
  selectedRoles,
  loading,
  setLoading,
  Get_users_with_role,
}) {
  const onCancel = () => {
    modalDismiss();
  };
  const [assignedRole, setAssignedRole] = useState([]);
  const [userAccStatus, setUserAccstatus] = useState(true);

  useEffect(() => {
    setAssignedRole(
      selectedRoles?.role?.map((item) => ({ id: item?.id, name: item?.name })),
    );
    setUserAccstatus(selectedRoles?.active);
  }, [selectedRoles]);

  console.log(selectedRoles);

  const Update_use_role = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_USER_ROLE,
        1,
        {
          userId: selectedRoles?.id,
          roles: assignedRole?.map((item) => item?.id), // array of
        },
      );
      if (json?.data) {
        Get_users_with_role();
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const change_account_status = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.PUT_UPDATE_USER_ACCOUNT,
        1,
        {
          active: userAccStatus,
          userId: selectedRoles?.id,
        },
      );
      if (json?.data) {
      } else {
      }
    } catch (error) {}
  };

  const modalElements = {
    heading: "Edit permission level for team member",
    // subheading: "Invited team members receive an email from SproutsAI. Invites expires after 7 days.",
    modalDismiss: () => onCancel(),
    onSave: () => {
      Update_use_role();
      if (selectedRoles.active !== userAccStatus) {
        change_account_status();
      }
    },

    defaultButtons: {
      primaryLabel: loading ? (
        <ScaleLoader height={14} color="#ffffff" loading={true} />
      ) : (
        "Save"
      ),
    },
  };

  return (
    <Modal {...modalElements}>
      <div>
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Account status
        </h3>
        <select
          name="assessment-dropdown"
          required
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] disabled:pointer-events-none"
          value={userAccStatus}
          style={{
            appearance: "none", // Remove default arrow icon
            paddingRight: "24px", // Adjust padding to make space for the placeholder
            backgroundImage:
              "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
            backgroundRepeat: "no-repeat",
            backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
            backgroundSize: "16px", // Adjust size of the arrow icon
          }}
          onChange={(e) => setUserAccstatus(e.target.value)}
        >
          <option value={true}>Active</option>
          <option value={false}>Inactive</option>
        </select>
      </div>
      <div className="w-full mt-2">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Permission access level
        </h3>
        <select
          name="assessment-dropdown"
          required
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] disabled:pointer-events-none"
          onChange={(e) => {
            setAssignedRole((prev) => [
              ...prev,
              {
                id: e.target.value,
                name: allRoles?.find((item) => item?.id === e.target.value)
                  ?.name,
              },
            ]);
          }}
          defaultValue={""}
          style={{
            appearance: "none", // Remove default arrow icon
            paddingRight: "24px", // Adjust padding to make space for the placeholder
            backgroundImage:
              "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
            backgroundRepeat: "no-repeat",
            backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
            backgroundSize: "16px", // Adjust size of the arrow icon
          }}
        >
          <option disabled value={""}>
            Add role
          </option>
          {allRoles?.map((role) => (
            <option key={role?.id} value={role?.id}>
              {role?.name}
              {role?.access === false ? (
                <span className="ml-2 text-xs font-regular text-emerald-500">
                  {" "}
                  - SproutsAI
                </span>
              ) : (
                <></>
              )}
            </option>
          ))}
        </select>
      </div>
      <div className="flex flex-wrap gap-2 mt-2">
        {assignedRole?.map((item) => (
          <span className="bg-[#F2F4F8] p-[3px] rounded-sm flex items-center ">
            {item?.name}
            <svg
              onClick={() =>
                setAssignedRole((prev) => prev?.filter((i) => i.id !== item.id))
              }
              className="ml-[8px] cursor-pointer"
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                d="M24 24L8 8M24 8L8 24"
                stroke="#001B4B"
                strokeWidth="2.8"
                strokeLinecap="round"
              />
            </svg>
          </span>
        ))}
      </div>
    </Modal>
  );
}

export default EditRoleModal;
