// src/MediaCapture.js
import React, { useEffect, useRef, useState } from "react";

function VideoRecording({
  icons,
  isCameraOn,
  setIsCameraOn,
  isMicOn,
  setIsMicOn,
  defaultcamera = true,
  defaultmic = true,
  mediaRecorderRef,
  setRecordedChunks,
  recordedChunks,
  setStream,
  stream,
}) {
  const videoRef = useRef(null);
  // const mediaRecorderRef = useRef(null);
  // const [stream, setStream] = useState(null);
  // const [recordedChunks, setRecordedChunks] = useState([]);
  // const [isRecording, setIsRecording] = useState(false);
  // const [isCameraOn, setIsCameraOn] = useState(false);
  // const [isMicOn, setIsMicOn] = useState(false);

  const [error, setError] = useState(null);
  const [showInstructions, setShowInstructions] = useState(false);

  const startMedia = async (e) => {
    setShowInstructions(false);
    setError(null);
    try {
      // Request access to media devices
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { width: { ideal: 640 }, height: { ideal: 360 } }, // Lower resolution
        audio: true,
      });

      setStream(stream);
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.muted = true; // Mute the video element
      }

      // Disable camera and mic initially based on default settings
      stream.getVideoTracks()[0].enabled = defaultcamera;
      stream.getAudioTracks()[0].enabled = defaultmic;
    } catch (err) {
      if (err.name === "NotAllowedError") {
        setError(
          "Camera and microphone access blocked. Please allow access to continue.",
        );
        if (e === "retry") {
          setShowInstructions(true);
        }
      } else if (err.name === "NotFoundError") {
        setError(
          "No camera or microphone found. Please ensure they are connected.",
        );
      } else if (err.name === "NotReadableError") {
        setError(
          "Camera or microphone is already in use by another application. Please close other apps that are using them and try again.",
        );
      } else {
        setError("An error occurred while accessing media devices.", err);
      }
      console.error("Error accessing media devices:", err);
    }
  };

  useEffect(() => {
    startMedia();
  }, []);

  console.log("error", error);

  const handleToggleCamera = () => {
    if (stream) {
      const videoTrack = stream.getVideoTracks()[0];
      videoTrack.enabled = !videoTrack.enabled;
      setIsCameraOn(videoTrack.enabled);
    }
  };

  const handleToggleMic = () => {
    if (stream) {
      const audioTrack = stream.getAudioTracks()[0];
      audioTrack.enabled = !audioTrack.enabled;
      setIsMicOn(audioTrack.enabled);
    }
  };

  // const handleStartRecording = () => {
  //   if (stream) {
  //     const options = { mimeType: 'video/webm; codecs=vp8', videoBitsPerSecond: 2500000 }; // Lower bitrate
  //     const mediaRecorder = new MediaRecorder(stream, options);
  //     mediaRecorderRef.current = mediaRecorder;

  //     mediaRecorder.ondataavailable = (event) => {
  //       if (event.data.size > 0) {
  //         setRecordedChunks((prev) => [...prev, event.data]);
  //         uploadChunk(event.data);
  //       }
  //     };

  //     mediaRecorder.start(30000); // Record in 30-second intervals
  //     setIsRecording(true);
  //   }
  // };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleSaveRecording = () => {
    const blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style = "display: none";
    a.href = url;
    a.download = "recording.webm";
    a.click();
    window.URL.revokeObjectURL(url);
    setRecordedChunks([]);
  };

  const handleRetry = () => {
    startMedia();
  };

  return (
    <>
      <div className="flex flex-col  relative">
        {showInstructions ? (
          <div>
            {error ===
            "No camera or microphone found. Please ensure they are connected." ? (
              <span className="text-[16px] font-medium text-[red]">
                No camera or microphone found. Please ensure they are connected.
              </span>
            ) : (
              <>
                <span className="text-[16px] font-semibold ">
                  SproutsAI is blocked from your microphone and camera.
                </span>
                <div className="mt-[20px] flex flex-col">
                  <span className="text-sm font-normal ">
                    1. Click on the page info icon on the left side of the
                    address bar.
                  </span>
                  <span className="text-sm font-normal mt-[8px] ">
                    2. Turn on camera and microphone.
                  </span>
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <video
              ref={videoRef}
              autoPlay
              className="w-full  rounded-lg shadow-md bg-black mb-4 transform scale-x-[-1]"
            />
            {icons && (
              <div className="flex absolute z-10 bottom-6">
                <button
                  onClick={handleToggleCamera}
                  className={`rounded-[50%] w-[60px] h-[60px] flex justify-center items-center shadow-md ${
                    isCameraOn ? "border border-[#ffffff]" : "bg-red-900"
                  } text-white`}
                >
                  {isCameraOn ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="17"
                      viewBox="0 0 22 17"
                      fill="none"
                    >
                      <rect
                        x="1"
                        y="1.5"
                        width="14"
                        height="14"
                        rx="4"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                      />
                      <path
                        d="M15 5.83333L17.6713 3.45887C18.9611 2.31238 21 3.22799 21 4.95369V12.0463C21 13.772 18.9611 14.6876 17.6713 13.5411L15 11.1667V5.83333Z"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                      />
                      <circle
                        cx="10"
                        cy="6.5"
                        r="2"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M1 1L21 21"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <path
                        d="M1 1L4.10135 4.10135M15 11V8.33333L17.6713 5.95886C18.9611 4.81237 21 5.72798 21 7.45368V14.5463C21 15.251 20.66 15.8206 20.1699 16.1699M15 9V8C15 5.79086 13.2091 4 11 4H8M4.10135 4.10135C2.32493 4.50915 1 6.09985 1 8V14C1 16.2091 2.79086 18 5 18H11C12.9002 18 14.4909 16.6751 14.8987 14.8987L4.10135 4.10135Z"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  )}
                </button>
                <button
                  onClick={handleToggleMic}
                  className={`w-[60px] h-[60px] flex justify-center items-center rounded-[50%] ml-4 shadow-md ${
                    isMicOn ? "border border-[#ffffff]" : "bg-red-900"
                  } text-white`}
                >
                  {isMicOn ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="22"
                      viewBox="0 0 16 22"
                      fill="none"
                    >
                      <path
                        d="M15 10V11C15 14.866 11.866 18 8 18M1 10V11C1 14.866 4.13401 18 8 18M8 18V21M8 21H11M8 21H5M8 15C5.79086 15 4 13.2091 4 11V5C4 2.79086 5.79086 1 8 1C10.2091 1 12 2.79086 12 5V11C12 13.2091 10.2091 15 8 15Z"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                    >
                      <path
                        d="M10 18V21M10 18C6.13401 18 3 14.866 3 11V10M10 18C12.0327 18 13.863 17.1336 15.1418 15.75M10 21H13M10 21H7M1 1.75L19 19.75M17 10V11C17 11.8232 16.8579 12.6132 16.5969 13.3469M14 10.75V5C14 2.79086 12.2091 1 10 1C8.44442 1 7.09624 1.88797 6.43469 3.18469M6 7V11C6 13.2091 7.79086 15 10 15C11.1484 15 12.1838 14.516 12.9133 13.741"
                        stroke="#ffffff"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
              </div>
            )}
            {error && (
              <button
                onClick={(e) => startMedia("retry")}
                className="solid-button absolute top-4 btn-md btn-primary disabled:opacity-60  !bg-blue "
              >
                Allow microphone and camera
              </button>
            )}
          </div>
        )}
      </div>

      <div className="flex space-x-4">
        {error && (
          <span className="text-red-500 text-sm font-medium">{error}</span>
        )}
      </div>
    </>
  );
}

export default VideoRecording;
