import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader, PropagateLoader } from "react-spinners";

import Logo from "../../../components/assets/Logo";

const core = require("../../../lib/core");

export default function LiveMeeting() {
  const nav = useNavigate();
  const loc = useLocation();
  const messagesEndRef = useRef(null);
  const [meetingStarted, setMeetingStarted] = useState(false);
  const [buttonTxt, setButtonTxt] = useState("Start Meeting");
  const [transcription, setTranscription] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    scrollToBottom();
    // getLiveTranscription();
  }, [transcription]);

  window.onbeforeunload = function (e) {
    console.log(e);
    var dialogText = "Dialog text here";
    e.returnValue = dialogText;
    return dialogText;
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const getLiveTranscription = async () => {
    let json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_INSTANT_MEETING_BY_ID +
        loc.pathname.split("/").at(-1),
      1,
    );
    //   console.log(json.data);
    setTranscription(json.data.data);
    // getLiveTranscription();
    // clearTimeout(timeout)
    // }, 5000);
  };

  const [timeOut, setTimeOut] = useState(true);

  useEffect(() => {
    // Call the function every 5 seconds
    if (timeOut) {
      const intervalId = setInterval(getLiveTranscription, 5000);

      // Cleanup the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, []);

  const [btnDisable, setBtnDisable] = useState(false);

  async function buttonClick() {
    try {
      setBtnDisable(true);
      setLoading(true);
      setTimeOut(false);
      let json = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_END_MEETING_BY_ID +
          loc.pathname.split("/").at(-1),
        1,
      );
      toast(json?.message);
      nav("/instant-meet/" + loc.pathname.split("/").at(-1));
    } catch (e) {
      setBtnDisable(false);
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen w-screen p-0" id="instantMeet">
      <div
        className={`height-[68px] items-center bg-white top-0 left-0 w-full flex gap-8 px-5 py-4`}
        id="header"
      >
        <Logo />
        <button
          className="btn btn-primary btn-md"
          onClick={buttonClick}
          disabled={btnDisable}
        >
          {loading ? (
            <PropagateLoader
              color="white"
              loading={loading}
              size={15}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          ) : (
            " End Meeting"
          )}
        </button>
      </div>
      <main className="main-content w-full pl-5 py-5 overflow-scroll max-h-[calc(100vh-74px)]">
        <div className="text-lg">
          <span>Live transcription</span>
        </div>
        {transcription?.map((data, i) => (
          <div
            key={i}
            className="text-base w-full"
            style={{ marginBottom: 16, marginTop: 10 }}
          >
            <span className="font-semibold">{data.speaker}</span>
            <span className="bg-gray-300 px-1 rounded ml-2">{data.start}</span>
            <span>{data.sentence}</span>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </main>
    </div>
  );
}
