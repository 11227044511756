import { useContext } from "react";
import Calender from "../../components/assets/recruiter/Calendar.png";
import DashboardTask from "../../components/recruiter/dashBoard/DashboardTask";
import InstantMeeting from "../../components/recruiter/dashBoard/InstantMeet";
import HeaderNew from "../../components/all/header/HeaderNew";
const core = require("../../lib/core");

const checked = (
  <svg
    className="w-5 h-5 bg-emerald-500 rounded-[46px]"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91797 7.58594L5.83464 10.0912C7.70086 8.22496 9.34496 6.06919 11.0846 4.08594"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const unchecked = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" fill="white" />
    <rect x="0.5" y="0.5" width="19" height="19" rx="9.5" stroke="#DADAE4" />
  </svg>
);

export default function NewUserDashboard({ jobs }) {
  const { profile } = useContext(core.UserContext);

  return (
    <div className="w-screen h-screen">
      <div className="w-full px-[68px]">
        <HeaderNew />
      </div>
      <main className="relative block w-[calc(100%-138px)] h-[calc(100vh-92px)] top-4 p-[18px] mx-[68px] rounded-[16px] bg-[#F3F5F9] overflow-hidden">
        <h1 className="text-slate-900 text-[28px] font-semibold leading-[34px]">
          Dashboard
        </h1>
        <h2 className="text-slate-800 text-sm font-normal leading-snug mb-6 mt-1">
          Welcome
        </h2>
        <div className="w-full h-full grid justify-items-stretch grid-cols-3 gap-5">
          <div className="col-span-2">
            <div className="w-full relative bg-white rounded-xl shadow p-[18px] mb-5">
              <h3 className="text-slate-900 text-lg font-semibold">
                2 more steps to get started
              </h3>
              <h4 className="text-slate-500 text-sm font-normal leading-snug">
                Take these easy steps to hire faster with SproutsAI
              </h4>
              <div className="w-full flex gap-5 mt-4">
                <div
                  className={`w-1/3 block h-1.5 bg-emerald-500 rounded-[100px]`}
                />
                <div
                  className={`w-1/3 block h-1.5 ${
                    !profile ? "bg-emerald-500" : "bg-gray-100"
                  } rounded-[100px]`}
                />
                <div
                  className={`w-1/3 block h-1.5 ${
                    !jobs ? "bg-emerald-500" : "bg-gray-100"
                  } rounded-[100px]`}
                />
              </div>
              <div className="w-full mt-5">
                <div className="w-full h-12 py-[18px] justify-start items-center gap-2 inline-flex">
                  {checked}
                  <p
                    className={`text-slate-500 text-base font-normal line-through`}
                  >
                    Create your account
                  </p>
                </div>
                <hr className="w-full h-px bg-gray-100" />
                <div className="w-full h-12 py-[18px] justify-start items-center gap-2 inline-flex">
                  {profile ? checked : unchecked}
                  <p
                    className={`text-slate-500 text-base font-normal ${
                      profile && "line-through"
                    }`}
                  >
                    Complete your profile
                  </p>
                </div>
                <hr className="w-full h-px bg-gray-100" />
                <div className="w-full h-12 py-[18px] justify-start items-center gap-2 inline-flex">
                  {jobs ? checked : unchecked}
                  <p
                    className={`text-slate-500 text-base font-normal ${
                      jobs && "line-through"
                    }`}
                  >
                    Create your first job post
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <div className="w-full relative bg-white rounded-xl shadow p-[18px] mb-5">
              <h3 className="text-slate-800 text-base font-medium">
                Today’s interviews
              </h3>
              <img className="w-full" src={Calender} alt="no meetings today" />
              <h4 className="text-slate-800 text-sm font-normal leading-[18px] w-full text-center mt-4">
                Enjoy your no meeting day!
              </h4>
            </div>
            <DashboardTask />
          </div>
        </div>
        <InstantMeeting />
      </main>
    </div>
  );
}
