import React, { useMemo, useState, useEffect, useRef } from "react";
import { AlertCircle, CheckCircle, CircleX } from "lucide-react";

const RatingBar = ({ score, maxRating = 5 }) => {
  const percentage = (score / maxRating) * 100;

  return (
    <div className="flex items-center justify-start gap-4">
      <div className="relative w-64 h-2 bg-gray-300 rounded">
        <div
          className="absolute top-0 left-0 h-full bg-green-900 rounded"
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
      <div className="text-gray-900 bg-yellow-100 w-16 rounded-md px-2 py-1 flex items-center">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="#eed42b"
          stroke="#eed42b"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="lucide lucide-star"
        >
          <polygon
            fill="#eed42b"
            points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
          />
        </svg>
        <span className="ml-2 text-sm font-medium">{score}</span>
      </div>
    </div>
  );
};

const NoDecisionIcon = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);

const FeedbackSummary = ({ feedbackList }) => {
  const [FinalDecision, setFinalDecision] = useState("");
  function calculateWeightedScoreForStage(feedbackGroup) {
    let totalScore = 0;
    let validRatings = 0;

    feedbackGroup.forEach((feedback) => {
      feedback.QuickFeedback.forEach((item) => {
        if (item.rating > 0) {
          totalScore += item.rating;
          validRatings++;
        }
      });
    });

    if (validRatings === 0) return 0;

    const averageScore = totalScore / validRatings;
    const normalizedScore = ((averageScore - 1) / 3) * 4 + 1;

    return Math.round(normalizedScore * 10) / 10;
  }

  const stages = useMemo(() => {
    const uniqueStages = [
      ...new Set(feedbackList.map((f) => f.interviewStage)),
    ];
    return uniqueStages.map((stage) => {
      const stageFeedback = feedbackList.filter(
        (f) => f.interviewStage === stage,
      );
      const avgScore = calculateWeightedScoreForStage(stageFeedback);

      return { name: stage, score: avgScore };
    });
  }, [feedbackList]);

  const contributors = useMemo(() => {
    return [...new Set(feedbackList.map((f) => f.externalInterviewer))];
  }, [feedbackList]);

  const decisions = useMemo(() => {
    const counts = feedbackList.reduce((acc, f) => {
      acc[f.recomandation] = (acc[f.recomandation] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(counts).map(([key, value]) => ({
      name: key,
      count: value,
    }));
  }, [feedbackList]);

  const finalDecision = useMemo(() => {
    const yesCount = decisions
      .filter((d) => d.name === "Yes" || d.name === "Definitely Yes")
      .reduce((sum, d) => sum + d.count, 0);
    const noCount = decisions
      .filter((d) => d.name === "No" || d.name === "Definitely No")
      .reduce((sum, d) => sum + d.count, 0);
    const noDecisionCount = decisions
      .filter((d) => d.name === "No Decision")
      .reduce((sum, d) => sum + d.count, 0);

    if (noDecisionCount > yesCount && noDecisionCount > noCount) {
      setFinalDecision("No");
    } else if (yesCount > noCount) {
      setFinalDecision("Yes");
    } else if (noCount > yesCount) {
      setFinalDecision("No");
    } else {
      setFinalDecision("No");
    }
  }, [decisions]);

  const AllDecisions = ["Yes", "No"];
  const [DecisionDropdown, setDecisionDropdown] = useState(false);
  const DropdownRef = useRef(null);
  const getDecisionIcon = (decision) => {
    switch (decision) {
      case "Yes":
        return <CheckCircle className="mr-2 text-[#4CAF50]" />;
      case "No":
        return <CircleX className="mr-2 text-[#FF0000]" />;
      case "No decision":
        return <NoDecisionIcon className="mr-2 text-gray-700" />;
      default:
        return null;
    }
  };
  const handleCloseDropdown = (event) => {
    if (DropdownRef.current && !DropdownRef.current.contains(event.target)) {
      setDecisionDropdown(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleCloseDropdown);
    return () => {
      document.addEventListener("mousedown", handleCloseDropdown);
    };
  }, []);
  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      <div className="flex items-start justify-between my-2">
        <h2 className="text-md font-bold">Feedback Summary</h2>
        {feedbackList.length >= 2 ? (
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-4 bg-gray-300 px-4 py-2 rounded-md w-fit h-fit">
              <h3 className="text-base font-semibold ">Overall Decision</h3>
              <div
                className={`flex items-center ${FinalDecision === "Yes" ? "text-[#4CAF50]" : FinalDecision == "No" ? "text-[#FF0000]" : "text-gray-700"}`}
              >
                {getDecisionIcon(FinalDecision)}
                <span className="text-md font-medium">{FinalDecision}</span>
              </div>
            </div>
            {/* <div className='relative'>
            <button className='btn-md btn-sec' onClick={() => setDecisionDropdown(!DecisionDropdown)}>
              <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"><path d="M4 18a2 2 0 1 0 4 0a2 2 0 1 0-4 0m12 0a2 2 0 1 0 4 0a2 2 0 1 0-4 0M6 12v-2a6 6 0 1 1 12 0v2" /><path d="m15 9l3 3l3-3" /></g></svg>
            </button>
            {DecisionDropdown && <div ref={DropdownRef}
              className='absolute top-10 z-[100] rounded-md shadow-md bg-white right-0 p-2 w-32'>
              {AllDecisions.map((decision, index) => [
                <div className='hover:bg-gray-300 rounded-md p-2 text-base'
                  onClick={() => {
                    setFinalDecision(AllDecisions[index])
                    setDecisionDropdown(false)
                  }}
                >{decision}</div>
              ])}

            </div>
            }
          </div> */}
          </div>
        ) : null}
      </div>

      <div className="mb-6">
        <h3 className="text-base font-semibold mb-2 ">Interview Stages</h3>
        <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
          {stages.map((stage, index) => (
            <div
              className="flex items-center justify-between py-2 border-b last:border-b-0 "
              key={index}
            >
              <span
                className="w-1/2 text-[#252E4B] text-[14px] truncate"
                title={stage.name}
              >
                {stage.name}
              </span>
              <RatingBar score={stage.score} />
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row gap-8 justify-start ">
        <div className="mb-6 w-[30%]">
          <h3 className="text-base font-semibold mb-2">Decision Breakdown</h3>
          <div className="space-y-2">
            {decisions.map((decision, index) => (
              <div
                key={index}
                className="flex items-center justify-between w-[80%]"
              >
                <span>{decision.name}</span>
                <span className="bg-gray-300 px-2 py-1 rounded">
                  {decision.count}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="mb- w-[40%]">
          <h3 className="text-base font-semibold mb-2">Interviewers</h3>
          <div className="flex gap-2 flex-row items-center flex-wrap ">
            {contributors.map((contributor, index) => (
              <span key={index} className="px-2 py-1 bg-gray-300 rounded-md ">
                {contributor}
              </span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackSummary;
