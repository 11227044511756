import Modal from "../../modal/Modal";
import JobPreference from "./forms/JobPreference";
import { useEffect, useState } from "react";

var core = require("../../../lib/core");

export default function Preferences({ profile }) {
  const [jobPreference, setJobPreference] = useState([]);

  useEffect(() => {
    getProfile();
  }, []);

  async function getProfile() {
    let response = await core.API(
      core.API_METHODS.GET,
      core.CANDIDATE_API.GET_PROFILE_DETAILS,
      1,
      {},
    );
    setJobPreference(response?.data?.preferences);
  }
  return (
    <div className="col-xs-12 no-padding" id="job-preferences">
      <button
        className="edit-button"
        data-toggle="modal"
        data-target="#preferences-modal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.862 4.487L19.5 7.125M18 14V18.75C18 19.3467 17.7629 19.919 17.341 20.341C16.919 20.763 16.3467 21 15.75 21H5.25C4.65326 21 4.08097 20.763 3.65901 20.341C3.23705 19.919 3 19.3467 3 18.75V8.25C3 7.65327 3.23705 7.08097 3.65901 6.65901C4.08097 6.23706 4.65326 6 5.25 6H10M16.862 4.487L18.549 2.799C18.9007 2.44733 19.3777 2.24976 19.875 2.24976C20.3723 2.24976 20.8493 2.44733 21.201 2.799C21.5527 3.15068 21.7502 3.62766 21.7502 4.125C21.7502 4.62235 21.5527 5.09933 21.201 5.451L10.582 16.07C10.0533 16.5984 9.40137 16.9867 8.685 17.2L6 18L6.8 15.315C7.01328 14.5986 7.40163 13.9467 7.93 13.418L16.862 4.487V4.487Z"
            stroke="#474747"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>{" "}
        Edit
      </button>
      <div id="preferences-modal" className="modal fade" role={"dialog"}>
        <Modal
          form={
            <JobPreference
              // fileName={profile?.resume ?? resumeFileName}
              // upload_function={onFileChange}
              getProfile={getProfile}
              data={jobPreference}
            />
          }
          hideButtons={true}
        />
      </div>
      <div className="col-xs-12 no-padding data-section">
        <div className="col-xs-12 no-padding top-section">
          <div className="section-1 col-xs-12">
            <h4 className="col-xs-12">Company expectations</h4>
            <div className="col-xs-6 no-padding">
              <h5 className="header col-xs-12">Company type</h5>
              <p className="data col-xs-12">
                {"a)"} {jobPreference?.companyType}
              </p>
            </div>
            <div className="col-xs-6 no-padding">
              <h5 className="header col-xs-12">Company size</h5>
              <p className="data col-xs-12">
                {"a)"} {jobPreference?.companySize}
              </p>
            </div>
          </div>
          <div className="section-2 col-xs-12">
            <h4 className="col-xs-12">Workplace expectations</h4>
            <div className="col-xs-6 no-padding">
              <h5 className="header col-xs-12">Workplace type</h5>
              {jobPreference?.workplace?.map((item, index) => (
                <p className="data col-xs-12">
                  {`${index + 1})`} {item}
                </p>
              ))}
            </div>
            <div className="col-xs-6 no-padding">
              <h5 className="header col-xs-12">Job type</h5>
              {jobPreference?.jobType?.map((item, index) => (
                <p className="data col-xs-12">
                  {`${index + 1})`} {item}
                </p>
              ))}
            </div>
          </div>
          <div className="section-3 col-xs-12">
            <h4 className="col-xs-12">Job expectations</h4>
            <div className="col-xs-6 no-padding">
              <h5 className="header col-xs-12">Job positions</h5>
              {jobPreference?.interests?.map((item, index) => (
                <p className="data col-xs-12">
                  {`${index + 1})`} {item}
                </p>
              ))}
            </div>
            <div className="col-xs-6 no-padding">
              <h5 className="header col-xs-12">Locations</h5>
              {jobPreference?.location?.map((item, index) => (
                <p className="data col-xs-12" key={index}>
                  {`${index + 1})`} {item}
                </p>
              ))}
            </div>
          </div>
        </div>
        <div className="col-xs-12 no-padding bottom-section"></div>
      </div>
    </div>
  );
}
