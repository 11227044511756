import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/all/header/Header";
import Menu from "../../components/all/header/Menu";
import SideBar from "../../components/all/SideBar";

const initialState = {
  companylogo: "",
  companyName: "",
  founder: "",
  companySize: "",
  achievements: "",
  executiveTeam: "",
  board: "",
  aboutCompany: "",
};

export default function EditCompany(props) {
  const userEmail = window.sessionStorage.getItem("userId");
  const [formState, setFormState] = useState(initialState);
  const [companyLogo, setCompanyLogo] = useState();
  var user_data;
  let isCreate;

  useEffect(() => {
    // console.log(userEmail)
    // fetchUser()
  }, []);

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value });
  }

  // async function update_company() {
  //   try {
  //   delete formState['createdAt']
  //   delete formState['updatedAt']
  //   // console.log(formState)
  //   await API.graphql(graphqlOperation(updateCompany, { input: formState }))
  //   } catch (err) {
  //      console.log(err)
  //   }
  // }

  const onSave = (event) => {
    event.preventDefault();
    console.log(isCreate);
    if (isCreate) {
      // create_company();
    } else {
      console.log(formState);
      // update_company()
    }
  };

  async function uploadCompanyLogo(image) {
    const imageName = Math.random() * 10 + "-companyLogo";
    try {
      const profileResult = await Storage.put(imageName, image, {
        level: "private",
        progressCallback(progress) {
          // console.log(`Uploaded: ${progress.loaded}/${progress.total}`)
        },
      });

      setFormState({ ...formState, companyLogo: imageName });
    } catch (err) {
      // console.log('Error uploading file: ', err)
    }
  }

  return (
    <>
      <div className="page recruiter-pages" id="edit-profile">
        <Header category="recruiter" showOnlyLogo={false} menu={<Menu />} />
        <SideBar />
        <div className="container">
          <h1 className="headingText">Edit Company Profile</h1>
          <form onSubmit={onSave}>
            <div className="section col-xs-12 ">
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="resume">
                  Company Logo{" "}
                </label>
                <input
                  type="file"
                  className="input col-xs-12 col-md-9"
                  name="resume"
                />
              </div>
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="companyName">
                  Company Name <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="input col-xs-12 col-md-9"
                  name="companyName"
                  value={formState.companyName}
                  required="required"
                  onChange={(event) => {
                    setInput("companyName", event.target.value);
                  }}
                />
              </div>
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="founded">
                  Founder <span className="required">*</span>
                </label>
                <input
                  type="text"
                  className="input col-xs-12 col-md-9"
                  name="founded"
                  value={formState.founder}
                  required="required"
                  onChange={(event) => {
                    setInput("founder", event.target.value);
                  }}
                />
              </div>

              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="employees">
                  Number of Employees <span className="required">*</span>
                </label>
                <select
                  className="input col-xs-12 col-md-9"
                  name="companysize"
                  required="required"
                  value={formState.companySize}
                  onChange={(event) => {
                    setInput("companySize", event.target.value);
                  }}
                >
                  <option value=""></option>
                  <option value="1-10 employees">1-10 employees</option>
                  <option value="11-50 employees">11-50 employees</option>
                  <option value="50-200 employees">50-200 employees</option>
                  <option value="201-500 employees">201-500 employees</option>
                  <option value="501-1000 employees">501-1000 employees</option>
                  <option value="1000+ employees">1000+ employees</option>
                </select>
              </div>
            </div>

            <div className="section col-xs-12 ">
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="achievements">
                  Achievements
                </label>
                <textarea
                  name="achievements"
                  className="input col-xs-12 col-md-9"
                  value={formState.achievements}
                  cols="30"
                  rows="10"
                  onChange={(event) => {
                    setInput("achievements", event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="section col-xs-12 ">
              {/* <h3 className="section-header col-xs-12">Executive Team</h3> */}
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="executiveTeam">
                  Executive Team<span className="required">*</span>
                </label>
                <textarea
                  name="executiveTeam"
                  className="input col-xs-12 col-md-9"
                  value={formState.executiveTeam}
                  cols="30"
                  rows="10"
                  onChange={(event) => {
                    setInput("executiveTeam", event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="section col-xs-12 ">
              {/* <h3 className="section-header col-xs-12">Executive Team</h3> */}
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="board">
                  Board
                </label>
                <textarea
                  name="board"
                  className="input col-xs-12 col-md-9"
                  value={formState.board}
                  cols="30"
                  rows="10"
                  onChange={(event) => {
                    setInput("board", event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div className="section col-xs-12 ">
              {/* <h3 className="section-header col-xs-12">Executive Team</h3> */}
              <div className="col-xs-12">
                <label className="col-xs-12 col-md-3" htmlFor="aboutCompany">
                  About the Company<span className="required">*</span>
                </label>
                <textarea
                  name="aboutCompany"
                  className="input col-xs-12 col-md-9"
                  value={formState.aboutCompany}
                  cols="30"
                  rows="10"
                  onChange={(event) => {
                    setInput("aboutCompany", event.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <button type="submit" className="input button col-xs-12">
              Next
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
