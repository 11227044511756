import React from "react";
import ProgressBar from "../../../../analytics/ProgressBar";

function Progressbar({
  skills = [],
  profile = {},
  width = 0.45,
  checkLabel,
  getTypeColor,
}) {
  return (
    <div>
      <div className="w-full data-section no-padding p-4">
        <div className="w-full input-data-section">
          <div className={`w-full`} id="create-skills-section">
            <div className="w-full">
              {skills
                ?.filter((item) => item?.value > 1 || item?.comment?.length > 1)
                ?.map((skill, it) => (
                  <div className="section-skills w-full">
                    <div className="w-full section-header no-padding">
                      <div className="w-[30%] no-padding ">
                        <span className="text-sm font-medium">
                          {skill?.criteria}
                        </span>
                      </div>

                      <div className="w-[40%] flex justify-between gap-3 items-center">
                        <ProgressBar
                          id={"skills-bar" + it + skill?.criteria}
                          style_classes="w-full"
                          data={Math.abs(skill?.value)}
                          height={8}
                          barColor={"teal"}
                          bgColor="#ffffff"
                          width={width - 10}
                          fromRight={skill?.value < 0}
                        />

                        <span className="inline-block w-[75px] text-ellipsis grow-0 shrink-0">
                          {Math.floor(skill?.value)}
                        </span>
                      </div>
                    </div>
                    {skill?.comment?.length > 0 && (
                      <div>
                        <p className="bg-[#fff] px-4 py-1 rounded-lg">
                          {skill?.comment}
                        </p>
                      </div>
                    )}
                    {skill?.skills?.length > 0 && (
                      <div className="w-full flex justify-between items-center mt-4 text-center text-sm text-gray-700 font-semibold">
                        <span className="w-[30%]  flex ">Label</span>
                        <span className="w-[40%]">Score</span>
                      </div>
                    )}
                    <div className="list-section w-full divide-y">
                      {skill?.skills?.map((ks, i) => (
                        <>
                          <div className="w-full mb-2 flex justify-between items-center">
                            <div className="w-[30%] name flex justify-between items-center relative">
                              {ks?.label}
                            </div>
                            <div className="w-[40%] flex justify-between gap-3 items-center">
                              <ProgressBar
                                id={"skills-bar" + it + skill?.criteria}
                                style_classes="w-full"
                                data={Math.abs(ks?.value)}
                                height={8}
                                barColor={"teal"}
                                bgColor="#ffffff"
                                width={width - 10}
                                fromRight={ks?.value < 0}
                              />
                              <span className="inline-block w-[75px] text-ellipsis grow-0 shrink-0">
                                {Math.floor(ks?.value)}
                              </span>
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Progressbar;
