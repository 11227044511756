import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../lib/core";

const LockedPage = ({
  page,
  children,
  message = "This page is currently not accessible",
  className = "",
}) => {
  const nav = useNavigate();
  const navigateToDashboard = () => nav("/dashboard", { replace: true });
  const navigateBack = () => nav(-1, { replace: true });

  console.log("LockedPage", page);

  const { user, resources } = useContext(UserContext);

  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    setIsLocked(
      resources?.filter((item) => item === page)?.length > 0 ? false : true,
    );
  }, []);

  return user?.locked ? (
    <>
      {!isLocked ? (
        children
      ) : (
        <div
          className={
            "w-screen h-screen fixed overflow-hidden flex " + className
          }
        >
          <div className="fixed inset-0 bg-gray-800 bg-opacity-50 transition-opacity z-[19]" />
          <div className="pointer-events-none opacity-70 ">{children}</div>
          <div className="absolute self-center left-[25%] w-1/2 min-h-[20vh] px-4 py-2 bg-white rounded-lg z-20 border border-gray-500 shadow-md flex flex-col justify-evenly">
            <h1 className="flex gap-4 items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="#252E4B"
                className="inline-block w-12 h-12 p-2 rounded-full bg-gray-200 fill-gray-200 border border-gray-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
                />
              </svg>
              <span className="text-2xl text-gray-800 font-semibold">
                This page is currently unavailable to this user
              </span>
            </h1>
            <div className="flex gap-2">
              <button onClick={navigateBack} className="btn-primary btn-md ">
                Go Back
              </button>
              <button onClick={navigateToDashboard} className="btn-sec btn-md ">
                Go to dashboard
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <> {children}</>
  );
};

export default LockedPage;
