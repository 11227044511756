import React from "react";
import { Outlet, useOutletContext, useNavigate } from "react-router-dom";

const TemplatePageContent = () => {
  const { reloadList } = useOutletContext();

  //   const location = useLocation()?.pathname?.split("/").slice(2);
  const nav = useNavigate();

  const tabs = [
    // {
    //   name: "Structure",
    //   path: "structure",
    //   activeCondition: location?.length == 1 || location[1] == "structure",
    // },
    // {
    //   name: "Status",
    //   path: "status",
    //   activeCondition: location?.length > 1 && location[1] == "status",
    // },
  ];

  console.log(reloadList);

  return (
    <>
      <div className="w-full p-0 flex justify-start items-center">
        {tabs?.map((btn) => (
          <button
            key={btn?.path}
            onClick={() => nav(btn?.path)}
            className={
              btn.activeCondition
                ? "h-10 px-3 py-2 text-base hover:border-blue-700 border-b-[3px] hover:text-slate-900 text-slate-900 font-medium border-blue-800"
                : "h-10 px-3 py-2 text-base hover:border-blue-500 border-b-[3px] hover:text-slate-900 text-slate-800 text-opacity-80 font-normal border-b-gray-400 disabled:opacity-60 disabled:pointer-events-none"
            }
          >
            {btn?.name}
          </button>
        ))}
      </div>
      <Outlet context={{ reloadList: reloadList }} />
    </>
  );
};

export default TemplatePageContent;
