import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
} from "recharts";
import { Card, CardContent, CardHeader } from "./Cards";
import {
  applicantLocations,
  applicantsPerRole,
  applicationSourceData,
  candidatePipeline,
  diversityMetrics,
  genderDiversity,
  interviewsPerHire,
  newApplications,
  referralConversionRatesByQuarter,
  timeToFill,
  totalCostPerHire,
} from "./analyticsChartsData";
import { COLORS, ROLES } from "./analyticsChartsData";
export const Dashboard = () => {
  return (
    <div className="p-2 " style={{ backgroundColor: "rgb(243, 245, 249)" }}>
      <div className="grid grid-cols-2 gap-4">
        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Applicants per Job Posting
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="99%" height={300}>
              <BarChart data={applicantsPerRole}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="role"
                  angle={-45}
                  textAnchor="end"
                  tick={(props) => (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        transform="rotate(-45)"
                        style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                      >
                        {props.payload.value.length > 6
                          ? `${props.payload.value.slice(0, 6)}...`
                          : props.payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="applicants" fill="#0088FE" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Application Source Breakdown
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={applicationSourceData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {applicationSourceData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Candidates in Pipeline Funnel
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={candidatePipeline} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={62} />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="applied"
                  name="Applied"
                  stackId="stack"
                  fill="#8884d8"
                />
                <Bar
                  dataKey="phoneScreen"
                  name="Phone Screen"
                  stackId="stack"
                  fill="#00C49F"
                />
                <Bar
                  dataKey="interview"
                  name="Interview"
                  stackId="stack"
                  fill="#ffc658"
                />
                <Bar
                  dataKey="offer"
                  name="Offer"
                  stackId="stack"
                  fill="#ff7f50"
                />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Referral Conversion Rate (Per Job Posting by Quarter){" "}
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart
                data={referralConversionRatesByQuarter}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                {ROLES.map((role, index) => (
                  <Area
                    key={role}
                    type="monotone"
                    dataKey={role}
                    stackId="1"
                    stroke={COLORS[index]}
                    fill={COLORS[index]}
                    fillOpacity={0.3}
                  />
                ))}
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">Time to Fill (Days)</h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={timeToFill}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="role"
                  angle={-45}
                  textAnchor="end"
                  height={30}
                  tick={(props) => (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        transform="rotate(-45)"
                        style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                      >
                        {props.payload.value.length > 6
                          ? `${props.payload.value.slice(0, 6)}...`
                          : props.payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis />
                <Legend />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="days"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Number of Interviews per Hire
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={interviewsPerHire}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="role" />
                <YAxis />
                <Tooltip />
                <Bar dataKey="interviews" fill="#00C49F" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              New Applications (to be reviewed)
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={newApplications}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 70,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="role"
                  angle={-45}
                  textAnchor="end"
                  height={30}
                  tick={(props) => (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        transform="rotate(-45)"
                        style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                      >
                        {props.payload.value.length > 6
                          ? `${props.payload.value.slice(0, 6)}...`
                          : props.payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar
                  dataKey="applications"
                  fill="#00C49F"
                  name="New Applications"
                />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Avg Cost Per Hire by Job Position
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={totalCostPerHire}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="role" />
                <YAxis />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="cost" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>
      <div className="grid grid-cols-3 gap-4 mt-4">
        <div className="w-[100%]">
          <Card>
            <CardHeader>
              <h2 className="text-lg font-semibold">Gender Diversity</h2>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={genderDiversity}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="percentage"
                    label={({ gender, percentage }) =>
                      `${gender} ${percentage}%`
                    }
                  >
                    {genderDiversity.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>

        <div className="w-[100%]">
          <Card>
            <CardHeader>
              <h2 className="text-lg font-semibold">
                Diversity Metrics in Applicant Pool
              </h2>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={diversityMetrics}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="percentage"
                    nameKey="race"
                  >
                    {diversityMetrics.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend verticalAlign="bottom" align="center" />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>{" "}
        </div>
        <div className="w-[100%]">
          {/* Last bar chart */}
          <Card>
            <CardHeader>
              <h2 className="text-lg font-semibold">Applicant Locations</h2>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={applicantLocations}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="location" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="applicants" fill="#0088FE" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};
