import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");

export const fetchUserResources = createAsyncThunk(
  "userData/fetchUserData",
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER_RESOURCES,
        1,
      );
      const data = list?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchUserRole = createAsyncThunk(
  "userRole/fetchUserRole",
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_MY_ROLE,
        1,
      );
      const data = list?.data; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);
