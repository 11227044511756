import React, { useContext, useEffect, useState } from "react";
import JobSidebar from "../../../components/all/JobSidebar";
import HeaderNew from "../../../components/all/header/HeaderNew";
import SequenceSidebar from "../../../components/recruiter/sequencing/SequenceSidebar";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
const core = require("../../../lib/core");

const SequencePage = () => {
  const [selectedTab, setSelectedTab] = useState("sequences");
  const nav = useNavigate();
  const loc = useLocation();
  const { user } = useContext(core.UserContext);
  const [sequences, setSequences] = useState([]);
  const [sequenceTrack, setSequenceTrack] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchSequences();
    getAllData();
  }, []);

  useEffect(() => {
    if (
      loc.pathname.split("/").filter((path) => path != "")?.length == 1 &&
      sequences?.length > 0
    )
      if (selectedTab == "sequences") nav(sequences[0]?._id);
      else {
        nav(sequenceTrack?.active[0]?._id);
      }
  }, [loc, sequences, selectedTab]);

  const getAllData = async () => {
    const list = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_ALL_TEMPLATE + user?.id,
      1,
    );
    if (list?.data) setTemplates(list?.data);
  };

  const fetchSequences = async () => {
    setLoading(true);
    const [json, sequenceTrack] = await Promise.all([
      core.API(core.API_METHODS.GET, core.SEQUENCE_API.GET_ALL_SEQUENCES, 1),
      core.API(core.API_METHODS.GET, core.SEQUENCE_API.GET_SEQUENCE_TRACK, 1),
    ]);
    if (json?.response) {
      setSequences(json?.response);
    }
    if (sequenceTrack?.data) {
      console.log(sequenceTrack?.data);
      setSequenceTrack(sequenceTrack?.data);
    }
    setLoading(false);
  };

  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="sequence_page">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="Sequences"
          CandidateFilter={
            <SequenceSidebar
              sequences={sequences}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              sequenceTrack={sequenceTrack}
              loading={loading}
            />
          }
        />
        <div className="w-[calc(100%-350px)] grow h-screen flex flex-col gap-4 pb-4">
          <HeaderNew />
          <main className="w-[calc(100%-16px)] relative grow flex flex-col gap-4 mx-2 p-4 rounded-lg bg-[#F3F5F9] overflow-y-scroll scrollbar-candidate">
            <Outlet
              context={{ templates: templates, reloadList: fetchSequences }}
            />
          </main>
        </div>
      </div>
    </div>
  );
};

export default SequencePage;
