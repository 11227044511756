import React, { useState, useEffect } from "react";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

function CompanyModal({ modalDismiss, data, templateData, tempid }) {
  const nav = useNavigate();
  console.log(data, templateData);

  const [loading, setLoading] = useState(false);

  const [companyData, setCompanyData] = useState({
    ...data,
    benefits: "",
    culture: "",
  });

  useEffect(() => {
    if (templateData.length > 0) {
      const benefits = templateData[0]?.benefits
        ? templateData[0].benefits.join(", ")
        : "";
      const culture = templateData[0]?.culture || "";
      setCompanyData((prevData) => ({
        ...prevData,
        benefits,
        culture,
      }));
      setLoading(false);
    }
  }, [templateData]);

  const handleInputChange = (fieldKey, value) => {
    setCompanyData({ ...companyData, [fieldKey]: value });
  };

  const EditableField = ({ label, value, fieldKey, textarea, disabled }) => {
    return (
      <div className="mb-2">
        <label className="w-32 font-semibold">{label}</label>
        {textarea ? (
          <textarea
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={value}
            onChange={(e) => handleInputChange(fieldKey, e.target.value)}
          />
        ) : (
          <input
            type="text"
            className="w-full border border-gray-300 rounded p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={value}
            onChange={(e) => handleInputChange(fieldKey, e.target.value)}
          />
        )}
      </div>
    );
  };

  return (
    <Modal
      className={"w-full max-w-full"} // Adjusted class to make modal full width
      noBtns={true}
      onSave={(e) => {
        const url = `/templates/job/${tempid}`;
        const newTab = window.open(url, "_blank");
        newTab.focus();
      }}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryClass: "bg-[#1369E9]",
        primaryLabel: <span className="flex items-center gap-4">Save</span>,
      }}
    >
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ScaleLoader
            height={35}
            width={4}
            radius={2}
            margin={2}
            color="#1369E9"
            loading={true}
          />
        </div>
      ) : (
        <div className="p-8 w-full max-w-full mx-auto">
          <h3 className="mb-2 text-gray-800">{data?.name}</h3>
          <div className="space-y-4 w-full">
            <EditableField
              disabled={true}
              label="About"
              value={companyData?.about}
              textarea
              fieldKey="about"
            />

            <EditableField
              label="Industry"
              value={
                typeof companyData?.industry != "string"
                  ? companyData?.industry?.join(", ")
                  : companyData?.industry
              }
              fieldKey="industry"
            />

            <EditableField
              disabled={true}
              label="Domain URL"
              value={companyData?.domain_url}
              fieldKey="domain_url"
            />
            <EditableField
              disabled={true}
              label="Year Founded"
              value={companyData?.year_founded}
              fieldKey="year_founded"
            />
            <EditableField
              disabled={true}
              label="Location"
              value={companyData?.location}
              fieldKey="location"
            />
            <EditableField
              disabled={true}
              label="Country"
              value={companyData?.country}
              fieldKey="country"
            />
            <EditableField
              disabled={true}
              label="LinkedIn"
              value={companyData?.linkedin}
              fieldKey="linkedin"
            />
            <EditableField
              disabled={true}
              label="Benefits"
              value={companyData?.benefits}
              fieldKey="benefits"
            />
            <EditableField
              disabled={true}
              label="Culture"
              value={companyData?.culture}
              fieldKey="culture"
            />
          </div>
        </div>
      )}
    </Modal>
  );
}

export default CompanyModal;
