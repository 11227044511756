import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PdfView(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { pdf } = props;
  useEffect(() => {
    // console.log(pdf);
  }, [pdf]);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function next(params) {
    if (pageNumber + 1 <= numPages) {
      setPageNumber(pageNumber + 1);
      //   console.log(pageNumber, numPages);
    }
  }
  function previous(params) {
    if (pageNumber - 1 > 0) {
      setPageNumber(pageNumber - 1);
      //   console.log(pageNumber);
    }
  }

  return (
    <div id="pdfViewer">
      <Document
        //   file={"https://portal-storage-19fd1431184217-dev.s3.us-east-2.amazonaws.com/public/rohitkumarjangir79%40gmail_resume.pdf"}
        file={pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      {numPages > 1 && (
        <div className="button-section-page">
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <div>
            <button onClick={previous}>Back</button>
            <button onClick={next}>next</button>
          </div>
        </div>
      )}
    </div>
  );
}
