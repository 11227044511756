import { useState } from "react";
import { Bell, ChevronLeft, Search, Settings, User } from "lucide-react";

export default function BrowsingAgent() {
  const [activeTab, setActiveTab] = useState("template");
  const [selectedJob, setSelectedJob] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [messageBody, setMessageBody] = useState("");

  // Mock job data
  const jobs = [
    { id: "1", title: "Software Engineer" },
    { id: "2", title: "Product Manager" },
    { id: "3", title: "UX Designer" },
    { id: "4", title: "Data Scientist" },
  ];

  const handleSaveTemplate = () => {
    console.log("Saving template:", { selectedJob, messageTitle, messageBody });
    // Implement save logic here
  };

  return (
    <div className="flex flex-col min-h-screen bg-white rounded-lg shadow-lg">
      <main className="flex-1 p-4 md:p-8">
        <h1 className="text-2xl font-bold mb-6">Admin Panel</h1>

        {/* Tab navigation */}
        <div className="flex space-x-4 mb-4">
          <button
            className={`py-2 px-4 ${activeTab === "template" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"}`}
            onClick={() => setActiveTab("template")}
          >
            Template Message Editor
          </button>
          <button
            className={`py-2 px-4 ${activeTab === "dashboard" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"}`}
            onClick={() => setActiveTab("dashboard")}
          >
            Outreach Progress
          </button>
          <button
            className={`py-2 px-4 ${activeTab === "controls" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"}`}
            onClick={() => setActiveTab("controls")}
          >
            Outreach Controls
          </button>
          <button
            className={`py-2 px-4 ${activeTab === "log" ? "border-b-2 border-blue-500 text-blue-500" : "text-gray-600"}`}
            onClick={() => setActiveTab("log")}
          >
            Action Log
          </button>
        </div>

        {/* Tab content */}
        {activeTab === "template" && (
          <div className="space-y-4">
            <div className="space-y-2">
              <label
                htmlFor="job-select"
                className="block text-sm font-medium text-gray-700"
              >
                Select Job
              </label>
              <select
                id="job-select"
                value={selectedJob}
                onChange={(e) => setSelectedJob(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
              >
                <option value="" disabled>
                  Select a job
                </option>
                {jobs.map((job) => (
                  <option key={job.id} value={job.id}>
                    {job.title}
                  </option>
                ))}
              </select>
            </div>
            <input
              type="text"
              placeholder="Message Title"
              value={messageTitle}
              onChange={(e) => setMessageTitle(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md"
            />
            <textarea
              placeholder="Message Body"
              value={messageBody}
              onChange={(e) => setMessageBody(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-md h-32"
            />
            <button
              onClick={handleSaveTemplate}
              className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
            >
              Save Template
            </button>
          </div>
        )}

        {activeTab === "dashboard" && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-semibold mb-2">Connection Requests Sent</h3>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                <div
                  className="bg-blue-500 h-2 rounded-full"
                  style={{ width: "33%" }}
                ></div>
              </div>
              <p className="text-sm text-gray-600">150 / 450</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-semibold mb-2">Connection Requests Sent</h3>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                <div
                  className="bg-blue-500 h-2 rounded-full"
                  style={{ width: "33%" }}
                ></div>
              </div>
              <p className="text-sm text-gray-600">150 / 450</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-semibold mb-2">Connection Requests Sent</h3>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                <div
                  className="bg-blue-500 h-2 rounded-full"
                  style={{ width: "33%" }}
                ></div>
              </div>
              <p className="text-sm text-gray-600">150 / 450</p>
            </div>
            <div className="bg-white p-4 rounded-lg shadow">
              <h3 className="font-semibold mb-2">Connection Requests Sent</h3>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                <div
                  className="bg-blue-500 h-2 rounded-full"
                  style={{ width: "33%" }}
                ></div>
              </div>
              <p className="text-sm text-gray-600">150 / 450</p>
            </div>
          </div>
        )}

        {activeTab === "controls" && (
          <div className="space-y-4">
            <div className="bg-white p-4 rounded-lg shadow flex justify-between items-center">
              <span className="font-medium">
                Send connection requests to all candidates
              </span>
              <input type="checkbox" className="toggle" />
            </div>
            <div className="bg-white p-4 rounded-lg shadow flex justify-between items-center">
              <span className="font-medium">
                Send connection requests to liked candidates only
              </span>
              <input type="checkbox" className="toggle" />
            </div>
            <button className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600">
              Send Invitations
            </button>
          </div>
        )}

        {activeTab === "log" && (
          <div className="bg-white p-4 rounded-lg shadow">
            <div className="h-64 overflow-y-auto">
              {[...Array(10)].map((_, i) => (
                <div
                  key={i}
                  className="flex items-center text-sm py-2 border-b last:border-b-0"
                >
                  <span className="font-medium w-24">Action {i + 1}:</span>
                  <span className="text-gray-600 flex-1">
                    {i % 2 === 0
                      ? "Connection request sent"
                      : "Message delivered"}
                  </span>
                  <span className="text-gray-400">
                    {new Date().toLocaleTimeString()}
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
      </main>
    </div>
  );
}
