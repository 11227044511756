import React, { useEffect, useRef, useState } from "react";
import JobSidebar from "../../components/all/JobSidebar";
import "../../style/admin/_admin.scss";
import HeaderNew from "../../components/all/header/HeaderNew";
import { tableData } from "./AnalyticsDashBoard/analyticsChartsData";
import { MetricCard } from "./AnalyticsDashBoard/MetricCard";
import CXOChart from "./AnalyticsDashBoard/CXOChart";
import RecruiterChart from "./AnalyticsDashBoard/RecruiterChart";
import { ScaleLoader } from "react-spinners";
import { Table } from "./AnalyticsDashBoard/Table";
import { useContext } from "react";
import AllCharts from "./AnalyticsDashBoard/AllCharts";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  fetchJDProcessedAnalytics,
  fetchPostedJobs,
  fetchResumeProcessedAnalytics,
} from "../../redux/services";
import { Dashboard } from "./AnalyticsDashBoard/Dashboard";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  clearState,
  updateNewApplicantsData,
} from "../../redux/slices/postedJobsAnalyticsSlice";
import InputBox from "../../layouts/InputBox";
import {
  SearchNewApplicants,
  SearchActiveJobs,
} from "./AnalyticsDashBoard/service";
import { validCards } from "./AnalyticsDashBoard/helper";
const core = require("../../lib/core");

const AdminDashboard = ({ active }) => {
  const menuRef = useRef();
  // const [tab, setTab] = useState("all");
  const [tab, setTab] = useState("CXO");
  const [menu, setMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(core.UserContext);
  const [tablesData, setTablesData] = useState(tableData);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeCard, setActiveCard] = useState(
    searchParams.get("activeCard") || null,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const [pagination, setPagination] = useState({ totalPages: 1 });
  const [error, setError] = useState(null);
  const [activeJobsData, setActiveJobsData] = useState([]);
  const [newApplicantData, setNewApplicantData] = useState([]);
  const [newApplicantsLoading, setNewApplicantsLoading] = useState(false);
  const [activeJobsLoading, setActiveJobsLoading] = useState(false);

  const [searchName, setSearchName] = useState("");
  const [isQuery, setIsQuery] = useState(null);
  const [hasNext, setHasNext] = useState(false);
  const [totalPages, setTotalPages] = useState(1);

  const fetchAllNewApplicantAnalytics = async (page = 1) => {
    try {
      // Make API call
      const response = await core.API(
        core.API_METHODS.GET,
        `${core.RECRUITER_API.GET_ALL_NEW_APPLICANT_ANALYTICS}?page=${page}`,
      );

      // Extract data from response
      const data = response?.data;

      // Return the data
      return data;
    } catch (error) {
      // Handle errors
    }
  };

  const updateActiveCard = (cardId) => {
    if (cardId && validCards.includes(cardId)) {
      setActiveCard(cardId);
      setSearchParams({ activeCard: cardId });
    } else {
      setActiveCard(null);
      setSearchParams({});
    }
  };

  useEffect(() => {
    const cardFromParams = searchParams.get("activeCard");
    updateActiveCard(cardFromParams);
  }, [searchParams]);

  const {
    isFetched,
    jobs,
    activeJobsCount,
    activeJobsLast30DaysCount,
    activeJobspercentageIncrease,
    resumeProcessedData,
    jdProcessedData,
    applicationSource,
    NewApplicantCountCached,
    NewApplicantLast30DaysCountCached,
    NewApplicantpercentageIncreaseCached,
  } = useSelector((state) => {
    return {
      isFetched: state?.postedJobsAnalyticsSlice?.isFetched,
      jobs: state?.postedJobsAnalyticsSlice?.jobs,
      totalPages: state?.postedJobsAnalyticsSlice?.totalPages,
      activeJobsCount: state?.postedJobsAnalyticsSlice?.activeJobsCount,
      activeJobsLast30DaysCount:
        state?.postedJobsAnalyticsSlice?.activeJobsLast30DaysCount,
      activeJobspercentageIncrease:
        state?.postedJobsAnalyticsSlice?.activeJobspercentageIncrease,
      applicationReviewSum:
        state?.postedJobsAnalyticsSlice?.applicationReviewSum,
      resumeProcessedData: state?.postedJobsAnalyticsSlice?.resumeProcessedData,
      jdProcessedData: state?.postedJobsAnalyticsSlice?.jdProcessedData,
      error: state?.postedJobsAnalyticsSlice?.error,
      applicationSource: state?.postedJobsAnalyticsSlice?.applicationSource,
      NewApplicantCountCached:
        state?.postedJobsAnalyticsSlice?.NewApplicantCountCached,
      NewApplicantLast30DaysCountCached:
        state?.postedJobsAnalyticsSlice?.NewApplicantLast30DaysCountCached,
      NewApplicantpercentageIncreaseCached:
        state?.postedJobsAnalyticsSlice?.NewApplicantpercentageIncreaseCached,
    };
  });

  const [NewApplicantCount, setNewApplicantCount] = useState(
    NewApplicantCountCached,
  );
  const [NewApplicantLast30DaysCount, setNewApplicantLast30DaysCount] =
    useState(NewApplicantLast30DaysCountCached);
  const [NewApplicantpercentageIncrease, setNewApplicantpercentageIncrease] =
    useState(NewApplicantpercentageIncreaseCached);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchPostedJobs());
    dispatch(
      fetchJDProcessedAnalytics({ organizationId: user?.organization._id }),
    );
    dispatch(
      fetchResumeProcessedAnalytics({ organizationId: user?.organization._id }),
    );
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setNewApplicantsLoading(true);
        const result = await fetchAllNewApplicantAnalytics(currentPage);
        const matchProfiles = result.matchProfiles || [];
        const newData = [...newApplicantData, ...matchProfiles];
        setNewApplicantData(newData);
        const newApplicantsTableData =
          transformedNewApplicantTableData(newData);
        setTablesData((prev) => ({
          ...prev,
          newApplicants: [...newApplicantsTableData],
        }));

        setPagination(result.pagination || { totalPages: 1 });
        setPagination(result.pagination); // Adjust based on your API response structure
        setNewApplicantCount(result.newApplicantCount);
        setNewApplicantLast30DaysCount(result.newApplicantLast30Days);
        setNewApplicantpercentageIncrease(result.percentageChange);
        setNewApplicantsLoading(false);
        dispatch(updateNewApplicantsData(result));
      } catch (error) {
        setError(error.message);
        setNewApplicantsLoading(false);
      }
    };

    fetchData();
  }, []); // Fetch data when on first  render

  useEffect(() => {
    const fetchActiveJobsTableData = async () => {
      try {
        setActiveJobsLoading(true);
        const results = await SearchActiveJobs(searchName, 1);
        const newData = results.jobDetails || [];
        setActiveJobsData([...newData]);
        const newActiveTableData = ActiveJobsTable(newData);
        setTablesData((prev) => ({
          ...prev,
          activeJobs: newActiveTableData,
        }));
        setActiveJobsLoading(false);
      } catch (error) {
        setError(error.message);
        setActiveJobsLoading(false);
      }
    };

    fetchActiveJobsTableData();
  }, []); // Fetch activejobs table data when on first  render

  const fetchData = async (page) => {
    try {
      setNewApplicantsLoading(true);
      const result = await fetchAllNewApplicantAnalytics(page);
      const matchProfiles = result.matchProfiles || [];
      const newData = [...newApplicantData, ...matchProfiles];
      setCurrentPage((prevPage) => prevPage + 1);
      setNewApplicantData(newData);

      const newApplicantsTableData = transformedNewApplicantTableData(newData);
      setTablesData((prev) => ({
        ...prev,
        newApplicants: [...newApplicantsTableData],
      }));

      setPagination(result.pagination); // Adjust based on your API response structure
      setNewApplicantCount(result.newApplicantCount);
      setNewApplicantLast30DaysCount(result.newApplicantLast30Days);
      setNewApplicantpercentageIncrease(result.percentageChange);
      setNewApplicantsLoading(false);
    } catch (error) {
      setError(error.message);
      setNewApplicantsLoading(false);
    }
  };
  useEffect(() => {
    if (isFetched) {
      setLoading(false); // Set loading to false when data is fetched
    }
  }, [isFetched]);

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (menuRef.current && !menuRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setMenu(false);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const metricData = [
    {
      id: "activeJobs",
      title: "Active Jobs",
      value: activeJobsCount ?? 0,
      change: activeJobspercentageIncrease ?? 0,
      last_30_days: activeJobsLast30DaysCount ?? 0,
      active: true,
    },
    {
      id: "newApplicants",
      title: "New Applicants",
      value: NewApplicantCount ?? 0,
      change: NewApplicantpercentageIncrease ?? 0,
      last_30_days: NewApplicantLast30DaysCount ?? 0,
      active: true,
    },
    { id: "pendingTasks", title: "Pending Tasks", value: 0, change: null },
    {
      id: "upcomingEvents",
      title: "Upcoming Events",
      value: 0,
      change: null,
    },
  ];
  const handleSearch = () => {
    if (searchName.trim().length == 0) return;
    switch (activeCard) {
      case "activeJobs":
        handleActiveJobsSearch();
        break;
      case "newApplicants":
        handleNewApplicantsSearch();
        break;
      default:
    }
  };

  const handleActiveJobsSearch = async () => {
    setActiveJobsLoading(true);
    setActiveJobsData([]);
    setTablesData((prev) => ({
      ...prev,
      activeJobs: [],
    }));
    setIsQuery("activeJobs");

    try {
      const results = await SearchActiveJobs(searchName, 1);
      const newData = results.jobDetails || [];
      const newActiveTableData = ActiveJobsTable(newData);

      setActiveJobsData(newData);
      setTablesData((prev) => ({
        ...prev,
        activeJobs: newActiveTableData,
      }));
    } catch (error) {
    } finally {
      setActiveJobsLoading(false);
    }
  };

  const handleNewApplicantsSearch = async (e) => {
    if (searchName.trim().length === 0) return;
    setNewApplicantsLoading(true);
    setNewApplicantData([]);
    setTablesData((prev) => ({
      ...prev,
      newApplicants: [],
    }));
    setIsQuery("newApplicants");

    try {
      const results = await SearchNewApplicants(searchName, 1);
      const {
        pagination,
        newApplicantCount,
        newApplicantLast30Days,
        percentageChange,
      } = results;

      const matchProfiles = results.matchProfiles || [];
      const newApplicantsTableData =
        transformedNewApplicantTableData(matchProfiles);

      setNewApplicantData(matchProfiles);
      setTablesData((prev) => ({
        ...prev,
        newApplicants: newApplicantsTableData,
      }));

      setCurrentPage(pagination.currentPage);
      setTotalPages(pagination.totalPages);
    } catch (error) {
    } finally {
      setNewApplicantsLoading(false);
    }
  };

  const ClearActiveJobsSearch = async () => {
    setActiveJobsLoading(true);
    setSearchName("");
    setActiveJobsData([]);
    setIsQuery(null);

    try {
      setActiveJobsData(jobs);
      const newActiveTableData = ActiveJobsTable(jobs);
      setTablesData((prev) => ({
        ...prev,
        activeJobs: newActiveTableData,
      }));
    } catch (error) {
      // Handle error
    } finally {
      setActiveJobsLoading(false);
    }
  };

  const ClearNewApplicantsSearch = async () => {
    setNewApplicantsLoading(true);
    setSearchName("");
    setNewApplicantData([]);
    setIsQuery(null);

    try {
      const result = await fetchAllNewApplicantAnalytics(1);
      const matchProfiles = result.matchProfiles || [];
      const newApplicantsTableData =
        transformedNewApplicantTableData(matchProfiles);

      setNewApplicantData(matchProfiles);
      setTablesData((prev) => ({
        ...prev,
        newApplicants: newApplicantsTableData,
      }));
      setCurrentPage(1);
    } catch (error) {
      // Handle error
    } finally {
      setNewApplicantsLoading(false);
    }
  };

  const handleClearSearch = () => {
    if (isQuery === null && searchName.trim().length !== 0) {
      setSearchName("");
      return;
    }

    if (isQuery === null) return;

    switch (activeCard) {
      case "activeJobs":
        ClearActiveJobsSearch();
        break;
      case "newApplicants":
        ClearNewApplicantsSearch();
        break;
      default:
    }
  };

  const resetCardData = async () => {
    setSearchName("");
    setIsQuery(null);

    switch (activeCard) {
      case "activeJobs":
        await resetActiveJobs();
        break;
      case "newApplicants":
        await resetNewApplicants();
        break;
      default:
    }
  };

  const resetActiveJobs = async () => {
    setActiveJobsLoading(true);
    setActiveJobsData([]);

    try {
      const newActiveTableData = ActiveJobsTable(jobs);
      setActiveJobsData(jobs);
      setTablesData((prev) => ({
        ...prev,
        activeJobs: newActiveTableData,
      }));
    } catch (error) {
    } finally {
      setActiveJobsLoading(false);
    }
  };

  const resetNewApplicants = async () => {
    setNewApplicantsLoading(true);
    setNewApplicantData([]);

    try {
      const result = await fetchAllNewApplicantAnalytics(1);
      const matchProfiles = result.matchProfiles || [];
      const newApplicantsTableData =
        transformedNewApplicantTableData(matchProfiles);

      setNewApplicantData(matchProfiles);
      setTablesData((prev) => ({
        ...prev,
        newApplicants: newApplicantsTableData,
      }));
      setCurrentPage(1);
    } catch (error) {
      // Handle error
    } finally {
      setNewApplicantsLoading(false);
    }
  };

  //to fetch more new applicants on search api

  const fetchMoreNewApplicants = async () => {
    try {
      if (currentPage >= totalPages) {
        // No more pages to fetch, return
        return;
      }

      setNewApplicantsLoading(true);

      const nextPage = currentPage + 1;
      const results = await SearchNewApplicants(searchName, nextPage);

      const {
        matchProfiles,
        pagination,
        newApplicantCount,
        newApplicantLast30Days,
        percentageChange,
      } = results;

      const newData = [...newApplicantData, ...matchProfiles];
      const newApplicantsTableData = transformedNewApplicantTableData(newData);

      setNewApplicantData(newData);
      setTablesData((prev) => ({
        ...prev,
        newApplicants: newApplicantsTableData,
      }));

      setCurrentPage(pagination.currentPage);
      setTotalPages(pagination.totalPages);
    } catch (error) {
    } finally {
      setNewApplicantsLoading(false);
    }
  };

  const onMetricCardClick = (id) => {
    updateActiveCard(activeCard === id ? null : id);
  };
  const transformApplicantsPerRoleData = (data) => {
    return data?.map((job) => ({
      role: job.name,
      applicants: job.candidatesCount,
    }));
  };
  const ActiveJobsTable = (apiResponse) => {
    return apiResponse?.map((job) => ({
      jobName: job.name,
      prospects: job.prospectCount,
      candidates: job.candidatesCount,
      strongMatch: job.strongMatchCount,
      goodMatch: job.goodMatchCount,
      sproutsaiCand: job.sproutsCandidateCount,
      atsCand: job.atsCandidateCount,
      jobId: job._id,
    }));
  };

  const transformedNewApplicantTableData = (data) => {
    return data?.map((profile) => ({
      name: profile.Name,
      appliedFor: profile.appliedFor,
      source: profile.source !== "Not Available" ? profile.source : null,
      // linkedinId:
      //   profile.Linkedin !== "Not Available" ? profile.Linkedin : null,
      overallMatch:
        profile.overallMatch !== "Not Available" ? profile.overallMatch : null,
      candidate_profile:
        profile.applicant_id !== "Not Available" ? profile.applicant_id : null,
      // resumeLink:
      // profile.resumeLink !== "Not Available" ? profile.resumeLink : null,
      quickLinks: [
        profile.resumeLink !== "Not Available" &&
        profile.resumeLink !== undefined
          ? profile.resumeLink
          : null,
        profile.Linkedin !== "Not Available" && profile.Linkedin !== undefined
          ? profile.Linkedin
          : null,
        profile.email !== "Not Available" && profile.email !== undefined
          ? profile.email
          : null,
        profile.phone !== "Not Available" && profile.phone !== undefined
          ? profile.phone
          : null,
      ],
    }));
  };
  const transformDataForNewApplications = (data) => {
    return data
      .map((job) => {
        const role = job.name;
        const applicationReviewStage = job.stages.find(
          (stage) => stage.name === "Application Review",
        );
        const applications = applicationReviewStage
          ? applicationReviewStage.value
          : 0;

        // Only include jobs with a non-zero application review value
        if (applications > 0) {
          return {
            role,
            applications,
          };
        }
        return null;
      })
      .filter((job) => job !== null);
  };

  useEffect(() => {
    resetCardData();
  }, [activeCard]);

  const handleNextPage = () => {
    if (currentPage < pagination.totalPages) {
      const newPage = currentPage + 1;
      fetchData(newPage);
    }
  };

  return (
    <div className="w-screen h-screen p-0 overflow-hidden" id="adminDashboard">
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar active="Analytics Dashboard" open={false} />
        <div className="w-full">
          <HeaderNew />
          {loading ? (
            <main className="relative flex w-[calc(100%-16px)] h-[calc(100vh-92px)] top-[15px] mx-[8px] rounded-[16px] p-20 bg-[#F3F5F9] overflow-hidden items-center justify-center">
              <ScaleLoader height={40} color="#1569EB" loading={true} />
            </main>
          ) : (
            <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] overflow-scroll scrollbar-candidate">
              <>
                <span className="font-semibold text-[25px] not-italic text-[#16182F]">
                  Dashboard
                </span>
                {/* Top cards */}
                <div className="flex flex-col gap-5 mt-[18px">
                  <div
                    className="justify-between grid grid-cols-4 gap-[20px]
mb-[20px]  mt-[18px]"
                  >
                    {metricData.map((metric) => (
                      <MetricCard
                        key={metric.id}
                        id={metric.id}
                        title={metric.title}
                        value={metric.value}
                        change={metric.change}
                        last_30_days={metric.last_30_days}
                        onClick={onMetricCardClick}
                        isActive={activeCard === metric.id}
                        active={metric.active}
                      />
                    ))}
                  </div>
                </div>

                {/* tables based on active card */}
                <div className="w-full ">
                  {activeCard && (
                    <div className="mb-6">
                      <div className="flex flex-row gap-x-2 items-center w-full justify-between">
                        <h2 className="text-xl font-semibold mb-4">
                          {/* {newApplicantsLoading ? <p>true</p> : <p>false</p>} */}
                          {metricData.find((m) => m.id === activeCard)?.title}
                          Details
                          {/* {activeJobsLoading ? "true" : "false"} */}
                        </h2>

                        <div className="flex flex-row items-center">
                          <InputBox
                            placeholder={
                              activeCard === "activeJobs"
                                ? "Search jobs"
                                : activeCard === "newApplicants"
                                  ? "Search candidates"
                                  : ""
                            }
                            size="medium"
                            value={searchName}
                            onChange={(e) => setSearchName(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                e.preventDefault();
                                handleSearch();
                              }
                            }}
                            width={"300px"}
                          />

                          <button
                            onClick={handleClearSearch}
                            className="ml-2 p-1  h-[34px] py-[8px] px-[10px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center"
                          >
                            <span className="text-[#FFF] text-[16px] not-italic font-normal">
                              Clear
                            </span>
                          </button>
                        </div>
                      </div>
                      <Table
                        data={tablesData[activeCard]}
                        type={activeCard}
                        section={activeCard}
                        fetchMoreData={handleNextPage}
                        loading={newApplicantsLoading}
                        setLoading={setNewApplicantsLoading}
                        activeCard={activeCard}
                        isQuery={isQuery}
                        fetchMoreNewApplicants={fetchMoreNewApplicants}
                        activeloading={activeJobsLoading}
                        setActiveLoading={setActiveJobsLoading}
                      />
                    </div>
                  )}
                </div>
                <h1
                  className="relative leading-10 text-2xl pt-4 flex items-center mb-8"
                  ref={menuRef}
                >
                  Comprehensive Analytics
                  <svg
                    onClick={() => setMenu((prev) => !prev)}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 ml-4 cursor-pointer"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25L12 15.75 4.5 8.25"
                    />
                  </svg>
                  {menu && (
                    <ul className="w-[240px] z-[333] max-h-[400px] overflow-auto scrollbar-hide p-1 list-style-none bg-white shadow-menuOptionShadow rounded-lg absolute origin-top-right ring-1 ring-black ring-opacity-5 focus:outline-none top-full left-[280px] mt-2">
                      <li
                        onClick={() => {
                          setTab("CXO");
                          setMenu(false);
                        }}
                        className={`w-full p-2 flex gap-2 items-center hover:bg-blue-100 hover:bg-opacity-40 rounded-lg cursor-pointer text-sm to-gray-800 hover:font-medium mb-0.5 group ${
                          tab === "CXO"
                            ? "font-medium bg-blue-100 selected"
                            : "font-normal bg-transparent"
                        }`}
                      >
                        CXOs
                      </li>
                      <li className="w-full p-2 flex gap-2 items-center rounded-lg cursor-not-allowed text-black text-sm mb-0.5 group font-normal bg-gray-200 border border-gray-300">
                        {" "}
                        + Build Your Own Dashboard
                      </li>
                    </ul>
                  )}
                </h1>

                <div className="w-full">
                  {tab === "all" ? (
                    <Dashboard />
                  ) : tab === "CXO" ? (
                    // <CXOChart
                    //   applicantsPerRole={transformApplicantsPerRoleData(jobs)}
                    //   jobData={jobData}
                    //     />
                    <AllCharts
                      applicantsPerRole={transformApplicantsPerRoleData(jobs)}
                      jdProcessedData={jdProcessedData}
                      ActiveJobsResponse={jobs}
                      resumeProcessedData={resumeProcessedData}
                      applicationSource={applicationSource}
                      newapplicantsReviewed={transformDataForNewApplications(
                        jobs,
                      )}
                    />
                  ) : tab === "Recruiter" ? (
                    <RecruiterChart ActiveJobsResponse={jobs} />
                  ) : tab === "Hiring-Manager" ? (
                    <h1>Hiring-Manager</h1>
                  ) : null}
                </div>
              </>
            </main>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
