import React from "react";
import { useState } from "react";
import Email from "../../components/auth/forgotPassword/Email";
import { ForgotPasswordContext } from "../../lib/context";
import LandingText from "../../components/auth/LandingText";
import LoginPageImg from "../../components/assets/candidate/LogInPageImg.png";

const ForgotPassword = () => {
  const [step, setStep] = useState(<Email />);
  const [email, setEmail] = useState("");
  return (
    <main className="w-screen md:h-screen min-h-screen h-full flex md:flex-row flex-col py-5 md:py-0 bg-defaultBg overflow-scroll scrollbar-hide">
      <div className="w-full md:w-7/12 h-auto md:h-full flex items-center justify-center flex-col">
        <LandingText />
        <img
          src={LoginPageImg}
          className="max-w-full h-auto -mt-16"
          alt="AI based decision engine"
        />
      </div>
      <div className="shadow-[0px_4px_32px_#043dab14] w-full md:w-5/12 h-auto md:h-full rounded-none md:rounded-l-lg md:bg-white bg-defaultBg px-4 md:px-0 mt-4 md:mt-0">
        <ForgotPasswordContext.Provider
          value={{ step, setStep, email, setEmail }}
        >
          {step}
        </ForgotPasswordContext.Provider>
      </div>
    </main>
  );
};

export default ForgotPassword;
