import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { logout } from "../../../lib/core";

const SignOut = ({ hideLogout, showCancel }) => {
  const [show, setShow] = useState(true);
  const nav = useNavigate();
  useEffect(() => {
    if (showCancel == false) {
      setShow(showCancel);
    }
  }, [showCancel]);

  return (
    <a
      className="signout no-padding"
      onClick={() => {
        logout();
        nav("/login", { replace: true });
      }}
    >
      <h5 hidden={hideLogout}>Log Out</h5>
      <h5 className="cancel" hidden={show}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
        >
          <path
            d="M24 24L8 8M24 8L8 24"
            stroke="#001B4B"
            strokeWidth="2.8"
            strokeLinecap="round"
          />
        </svg>
      </h5>
    </a>
  );
};
export default SignOut;
