import * as d3 from "d3";
import { useEffect, useRef } from "react";

export default function MultiLineChart({ data, chartHeight, color }) {
  const ref = useRef();
  const radius = 4;
  var chartMargin = {
      top: 10,
      bottom: 100,
      left: 50,
      right: 20,
    },
    lineWidth = 2,
    innerWidth,
    outerHeight = chartHeight + chartMargin.top + chartMargin.bottom;

  useEffect(() => {
    try {
      innerWidth = Math.max(
        data?.length * 75,
        d3?.select(ref?.current?.parentNode)?.node()?.getBoundingClientRect()
          ?.width,
      );
      // On every page load
      //  Inner dimensions of the chart => excluding the axes
      // Select SVG and set dimensions
      var svg = d3
        .select(ref?.current)
        .attr("width", innerWidth + chartMargin?.left)
        .attr("height", outerHeight);

      // Enable scaling to fit data to chart dimensions
      var xScale = d3
        .scalePoint()
        .domain(data.map((d) => d?.criteria))
        .range([0, innerWidth])
        .padding(0.5);
      var yScale = d3.scaleLinear().domain([10, 0]).range([0, chartHeight]);

      // Add axes to chart
      // Set axes, scaled to chart dimensions
      var xAxis = d3.axisBottom().scale(xScale);
      var yAxis = d3.axisLeft().scale(yScale).ticks(5);
      // Remove previous/necessary g tags
      svg.selectAll("g").remove();
      // Add the axis into grpup and add it to svg
      svg
        .append("g")
        .classed("xAxis", true)
        .call(xAxis)
        .attr(
          "transform",
          "translate(" +
            chartMargin.left +
            "," +
            String(chartHeight + chartMargin.top) +
            ")",
        )
        .selectAll(".tick text")
        .call(wrap, 100)
        .on("mouseover", (e, i) => {
          tooltip = d3
            .select("body")
            .append("div")
            .classed("tooltip", true)
            .text((a) => {
              return i;
            })
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mousemove", (e) => {
          tooltip
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mouseout", function (e) {
          d3.selectAll(".tooltip").remove();
        })
        .attr(
          "style",
          "transform: translate(-10px, 8px) rotate(-45deg); text-align: right; text-anchor:end; max-width:50px; overflow:hidden; text-overflow: ellipsis",
        );

      svg
        .append("g")
        .call(yAxis)
        .classed("yAxis", true)
        .attr(
          "transform",
          "translate(" + chartMargin.left + "," + chartMargin.top + ")",
        );

      // Y axis criteria:
      d3.selectAll("text.criteria").remove();
      svg
        .append("text")
        .classed("criteria", true)
        .attr("text-anchor", "end")
        .attr("y", -120)
        .attr("x", -8)
        .text("SCORES");

      // Tooltip defination
      d3.selectAll(".tooltip").remove();
      var tooltip;
      // The line graph
      // Line group
      var cand_line = svg
        .append("g")
        .classed("cand_line", true)
        .attr("width", innerWidth)
        .attr("height", chartHeight);
      // Adding data per data in group
      cand_line
        .append("path")
        .datum(data)
        .attr("class", "line")
        .attr(
          "d",
          d3
            .line()
            .x((d) => {
              return xScale(d?.criteria) + chartMargin.left;
            })
            .y((d) => {
              return yScale(d?.criteriaScore) + chartMargin.top;
            }),
        )
        .style("fill", "none")
        .style("stroke", color)
        .style("trokeWidth", lineWidth);

      // Pointer/Circles at every data point
      // Line group
      var job_line = svg
        .append("g")
        .classed("job_line", true)
        .attr("width", innerWidth)
        .attr("height", chartHeight);
      // Adding data per data in group
      job_line
        .append("path")
        .datum(data)
        .attr("class", "line")
        .attr(
          "d",
          d3
            .line()
            .x((d) => {
              return xScale(d?.criteria) + chartMargin.left;
            })
            .y((d) => {
              return yScale(d?.jobRequirement) + chartMargin.top;
            }),
        )
        .style("fill", "none")
        .style("stroke", "#FF475D")
        .style("trokeWidth", lineWidth);

      // Pointer/Circles at every data point
      // Circle group
      var job_circle = svg
        .append("g")
        .classed("job_circle", true)
        .attr("width", innerWidth)
        .attr("height", chartHeight);
      // Circles added to group (inner smaller circle)
      job_circle
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d, i) {
          return xScale(d?.criteria) + chartMargin.left;
        })
        .attr("cy", function (d) {
          return yScale(d?.jobRequirement) + chartMargin.top;
        })
        .attr("r", radius + 2)
        .style("fill", "white")
        .style("stroke", "#FF7324")
        .style("trokeWidth", "1");
      // Circles outline feature added to group
      job_circle
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "data-point")
        .attr("id", (_, i) => {
          return "p" + i;
        })
        .attr("cx", function (d, i) {
          return xScale(d?.criteria) + chartMargin.left;
        })
        .attr("cy", function (d) {
          return yScale(d?.jobRequirement) + chartMargin.top;
        })
        .attr("r", radius)
        .style("fill", "#FF7324");
      // .on("mouseover", (event, data) => {
      //   tooltip = d3
      //     .select("body")
      //     .append("div")
      //     .classed("tooltip", true)
      //     .text(() => {
      //       return (
      //         "Job requirement: " +
      //         Math.round(data?.jobRequirement * 100) / 100 +
      //         "/10"
      //       );
      //     })
      //     .style("display", "block")
      //     .style("top", event.y + 20 + "px")
      //     .style("left", () => {
      //       if (event.x + 250 > window.innerWidth) {
      //         return event.x - 75 + "px";
      //       }
      //       return event.x + 25 + "px";
      //     });
      // })
      // .on("mousemove", (e) => {
      //   tooltip
      //     .style("display", "block")
      //     .style("top", e.y + 20 + "px")
      //     .style("left", () => {
      //       if (e.x + 250 > window.innerWidth) {
      //         return e.x - 75 + "px";
      //       }
      //       return e.x + 25 + "px";
      //     });
      // })
      // .on("mouseout", function (e) {
      //   d3.selectAll(".tooltip").remove();
      // });

      // Circle group
      var cand_circle = svg
        .append("g")
        .classed("cand_circle", true)
        .attr("width", innerWidth)
        .attr("height", chartHeight);
      // Circles added to group (inner smaller circle)
      cand_circle
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d, i) {
          return xScale(d?.criteria) + chartMargin.left;
        })
        .attr("cy", function (d) {
          return yScale(d?.criteriaScore) + chartMargin.top;
        })
        .attr("r", radius + 2)
        .style("fill", "white")
        .style("stroke", color)
        .style("trokeWidth", "1");
      // Circles outline feature added to group
      cand_circle
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "data-point hover:fill-green")
        .attr("id", (_, i) => {
          return "p" + i;
        })
        .attr("cx", function (d, i) {
          return xScale(d?.criteria) + chartMargin.left;
        })
        .attr("cy", function (d) {
          return yScale(d?.criteriaScore) + chartMargin.top;
        })
        .attr("r", radius)
        .style("fill", color)
        .on("mouseover", (e, data) => {
          tooltip = d3
            .select("body")
            .append("div")
            .classed(
              "tooltip absolute h-auto max-w-[" +
                innerWidth +
                "px] bg-white shadow z-[1020] rounded-lg border border-gray-500 ",
              true,
            )

            .style("display", "block")
            .style("opacity", "1")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
          const dummydata = data?.candidateSkill ?? [];
          const tooltipTable = `<div class="w-full py-1 flex flex-col divide-y">
          ${dummydata
            ?.map(
              (dt) =>
                `<div class="w-full px-4 py-1 gap-2 flex justify-between border-gray-500"><span class="inline-block grow text-sm font-medium">${dt?.skill} </span> <span class="text-right w-4 text-sm font-medium inline-block">${dt?.value}</span></div>`,
            )
            .join("")}
            </div>`;
          tooltip.html(tooltipTable);
        })
        .on("mousemove", (e) => {
          tooltip
            .style("display", "block")
            .style("top", e.y + 20 + "px")
            .style("left", () => {
              if (e.x + 250 > window.innerWidth) {
                return e.x - 75 + "px";
              }
              return e.x + 25 + "px";
            });
        })
        .on("mouseout", function (e) {
          d3.selectAll(".tooltip").remove();
        });
    } catch (err) {
      console.error(err);
    }
  });

  function wrap(text, maxWidth) {
    text.each(function () {
      const element = d3.select(this);
      const words = element.text().split(/\s+/).reverse();
      const lineHeight = 1.2; // Set your line height here
      const fontSize = 14;
      const ellipsis = "...";

      let line = [];
      let lineNumber = 0;
      let word = words.pop();
      let tspan = element
        .text(null)
        .append("tspan")
        .attr("x", -2)
        .attr("dy", 0 + "px");

      while (word) {
        if (lineNumber > 1) return;
        line.push(word);
        tspan.text(line.join(" "));
        if (
          tspan.node().getComputedTextLength() > maxWidth &&
          lineNumber <= 1
        ) {
          // remove extra word
          line.pop();
          tspan.text(() =>
            lineNumber == 1 ? line.join(" ") + ellipsis : line.join(" "),
          );
          if (lineNumber == 0) {
            tspan = element
              .append("tspan")
              .attr("x", (lineNumber + 1) * 8)
              .attr("dy", ++lineNumber * lineHeight + "em")
              .text(() => (lineNumber == 1 ? word + "..." : word));
            line = [word];
          } else {
            lineNumber++;
          }
        }
        word = words.pop();
      }
    });
  }
  return data?.length > 0 && <svg ref={ref} className="min-w-full"></svg>;
}
