import React, { useState, useEffect } from "react";
import { FaQuestionCircle } from "react-icons/fa";
import { FiHelpCircle, FiCheckSquare, FiLayers } from "react-icons/fi";
import { MdOutlineFeedback } from "react-icons/md";
import {
  JobIcon,
  InterviewIcon,
  AnalyticsIcon,
  CommunicationsIcon,
  CandidateIcon,
  DropdownIcon,
} from "./icons";
import greenhouseIcon from "../../components/assets/greenhouse_green.png";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
const core = require("../../lib/core");

const Role = () => {
  const loc = useLocation();
  const roleId = loc.pathname.split("/").pop();
  const [resources, setResources] = useState({});
  const [openAccordions, setOpenAccordions] = useState({});

  useEffect(() => {
    getResources(roleId);
  }, [roleId]);

  const iconMap = {
    Job: JobIcon,
    Candidate: CandidateIcon,
    Questions: FiHelpCircle,
    Communications: CommunicationsIcon,
    Interview: InterviewIcon,
    Assessment: FiCheckSquare,
    Greenhouse: () => (
      <img src={greenhouseIcon} alt="Greenhouse" className="h-5 w-5" />
    ),
    Analytics: AnalyticsIcon,
    Pipeline: FiLayers,
    Feedback: MdOutlineFeedback,
    CandidateBrowser: CandidateIcon,
  };

  const getResources = async (id) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        `${core.USER_API.GET_ALL_RESOURCES}${id}`,
        1,
      );
      if (json.data) {
        const formattedResources = formatResources(json.data);
        setResources(formattedResources);
      } else {
      }
    } catch (error) {
      toast.error("Error fetching resources");
    }
  };

  const formatResources = (data) => {
    const formattedResources = {};
    Object.keys(data).forEach((resourceName) => {
      formattedResources[resourceName] = {
        name: resourceName,
        icon: iconMap[resourceName] || FaQuestionCircle,
        permissions: data[resourceName].map((permission) => ({
          id: permission.id,
          name: permission.action,
          checked: permission.checked,
          code: permission.code,
        })),
      };
    });
    return formattedResources;
  };

  const togglePermission = (resourceName, permissionId) => {
    setResources((prev) => ({
      ...prev,
      [resourceName]: {
        ...prev[resourceName],
        permissions: prev[resourceName].permissions.map((permission) =>
          permission.id === permissionId
            ? { ...permission, checked: !permission.checked }
            : permission,
        ),
      },
    }));
  };

  const toggleAccordion = (resourceName) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [resourceName]: !prev[resourceName],
    }));
  };

  const handleSave = async () => {
    const payload = {
      roleId: roleId,
      resourceIds: Object.values(resources).flatMap((resource) =>
        resource.permissions
          .filter((permission) => permission.checked)
          .map((permission) => permission.id),
      ),
    };
    console.log("Payload to be sent:", payload);

    try {
      const response = await core.API(
        core.API_METHODS.PUT,
        core.USER_API.GET_ALL_RESOURCES,
        1,
        payload,
      );
      if (response.data) {
        toast.success("Role updated successfully");
      } else {
        toast.error("Error updating role");
      }
    } catch (error) {
      toast.error("Error updating role");
    }
    // Here you would send the payload to your API
  };

  const toggleAllPermissions = (resourceName) => {
    setResources((prev) => {
      const allChecked = prev[resourceName].permissions.every(
        (permission) => permission.checked,
      );
      return {
        ...prev,
        [resourceName]: {
          ...prev[resourceName],
          permissions: prev[resourceName].permissions.map((permission) => ({
            ...permission,
            checked: !allChecked,
          })),
        },
      };
    });
  };

  return (
    <div className="flex flex-col h-full bg-gray-100">
      <div className="sticky top-0 bg-white p-4 z-10">
        <div className="flex justify-between items-center">
          <div>
            <h1 className="text-[#252E4B] font-medium text-[18px] py-[8px] not-italic leading-[22px]">
              Role
            </h1>
            <span className="text-[#252E4B] font-normal text-[14px]  not-italic">
              Add permissions/resources for this role
            </span>
          </div>
          <button
            onClick={handleSave}
            className="solid-button-blue px-[12px] h-[40px] rounded-[8px] bg-[#1369E9] shadow-jobPostButtonShadow flex items-center justify-center"
          >
            <span className="text-[#FFF] text-[16px] not-italic font-normal">
              Save
            </span>
          </button>
        </div>
      </div>
      <div className="flex-1 overflow-auto p-4">
        <div className="space-y-4">
          {Object.entries(resources).map(([resourceName, resource]) => (
            <div
              key={resourceName}
              className="border border-blue-300 rounded-lg overflow-hidden"
            >
              <div className="w-full px-4 py-2 flex items-center justify-between hover:bg-gray-50">
                <div className="flex items-center space-x-4">
                  <div className="w-8 flex items-center justify-center">
                    {typeof resource.icon === "function" ? (
                      <resource.icon className="h-5 w-5" />
                    ) : (
                      <span>{resource.icon}</span>
                    )}
                  </div>
                  <span className="font-semibold">{resourceName}</span>
                </div>

                <div className="flex items-center space-x-4">
                  <label className="relative inline-block w-10 h-6 cursor-pointer">
                    <input
                      type="checkbox"
                      checked={resource.permissions.every(
                        (permission) => permission.checked,
                      )}
                      onChange={() => toggleAllPermissions(resourceName)}
                      className="sr-only"
                    />
                    <div
                      className={`absolute inset-0 rounded-full transition-colors duration-200 ease-in-out ${
                        resource.permissions.every(
                          (permission) => permission.checked,
                        )
                          ? "bg-[#1563E9]"
                          : "bg-[#E5E7EB]"
                      }`}
                    ></div>
                    <div
                      className={`absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${
                        resource.permissions.every(
                          (permission) => permission.checked,
                        )
                          ? "transform translate-x-4"
                          : ""
                      }`}
                    ></div>
                  </label>
                  <button
                    onClick={() => toggleAccordion(resourceName)}
                    className="focus:outline-none w-6 h-6 flex items-center justify-center"
                  >
                    <span
                      className={`transform transition-transform ${
                        openAccordions[resourceName] ? "rotate-180" : ""
                      }`}
                    >
                      <DropdownIcon />
                    </span>
                  </button>
                </div>
              </div>
              {openAccordions[resourceName] && (
                <div className="px-4 py-2">
                  <h3 className="font-semibold mb-4">Permissions include:</h3>
                  <div className="grid grid-cols-2 gap-4">
                    {resource.permissions.map((permission) => (
                      <div
                        key={permission.id}
                        className="flex items-start justify-between max-w-[300px]"
                      >
                        <span className="text-sm max-w-[200px] text-wrap">
                          {permission.name.charAt(0).toUpperCase() +
                            permission.name.slice(1)}
                        </span>
                        <label className="relative inline-block w-10 h-6 cursor-pointer">
                          <input
                            type="checkbox"
                            checked={permission.checked}
                            onChange={() =>
                              togglePermission(resourceName, permission.id)
                            }
                            className="sr-only"
                          />
                          <div
                            className={`absolute inset-0 rounded-full transition-colors duration-200 ease-in-out ${
                              permission.checked
                                ? "bg-[#1563E9]"
                                : "bg-[#E5E7EB]"
                            }`}
                          ></div>
                          <div
                            className={`absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full transition-transform duration-200 ease-in-out ${
                              permission.checked
                                ? "transform translate-x-4"
                                : ""
                            }`}
                          ></div>
                        </label>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Role;
