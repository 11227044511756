import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import JobFilter from "./JobFilter";
import { FaMapMarkerAlt, FaBriefcase } from "react-icons/fa";
import InitialsLogo from "./../../components/all/InitialsLogo";
import { IoMdTime } from "react-icons/io";
import { GrMoney } from "react-icons/gr";
const core = require("../../lib/core");

const JobList = ({ jobList, orgName, orgDetails, isTemplateUsed }) => {
  const [jobListed, setJobListed] = useState([]);
  const [filteredJobList, setFilteredJobList] = useState([]);
  const [locations, setLocations] = useState([]);
  const [positions, setPositions] = useState([]);
  const [workplaces, setWorkplaces] = useState([]);
  const [jobTypes, setJobTypes] = useState([]);

  const nav = useNavigate();

  useEffect(() => {
    if (jobList && jobList.length > 0) {
      setJobListed(jobList);
      setFilteredJobList(jobList);
      const uniqueLocations = Array.from(
        new Set(jobList.flatMap((job) => job.location.map((loc) => loc.name))),
      );
      const uniquePositions = Array.from(
        new Set(jobList.map((job) => job.position)),
      );
      const uniqueWorkplaces = Array.from(
        new Set(jobList.map((job) => job.workplace)),
      );
      const uniqueJobTypes = Array.from(
        new Set(jobList.flatMap((job) => job.job_type.map((jt) => jt.type))),
      );
      setLocations(uniqueLocations);
      setPositions(uniquePositions);
      setWorkplaces(uniqueWorkplaces);
      setJobTypes(uniqueJobTypes);
    }
  }, [jobList]);

  const handleFilter = (filters) => {
    const { location, position, workplace, jobType } = filters;
    const filtered = jobListed.filter((job) => {
      const locationMatch = location
        ? job.location.some((loc) => loc.name === location)
        : true;
      const positionMatch = position ? job.position === position : true;
      const workplaceMatch = workplace ? job.workplace === workplace : true;
      const jobTypeMatch = jobType
        ? job.job_type.some((jt) => jt.type === jobType)
        : true;
      return locationMatch && positionMatch && workplaceMatch && jobTypeMatch;
    });
    setFilteredJobList(filtered);
  };

  const timeDifference = (difference) => {
    if (difference < 1000) {
      return "Just now";
    } else if (difference < 60000) {
      return `${Math.floor(difference / 1000)} seconds ago`;
    } else if (difference < 3600000) {
      return `${Math.floor(difference / 60000)} minutes ago`;
    } else if (difference < 86400000) {
      return `${Math.floor(difference / 3600000)} hours ago`;
    } else if (difference < 2592000000) {
      return `${Math.floor(difference / 86400000)} days ago`;
    } else if (difference < 31536000000) {
      return `${Math.floor(difference / 2592000000)} months ago`;
    } else {
      return `${Math.floor(difference / 31536000000)} years ago`;
    }
  };

  return (
    <div className="flex justify-center items-center ">
      <div
        className={`bg-gray-100 w-[80%] flex flex-col lg:flex-row justify-between items-start  sm:px-[5%] gap-8`}
      >
        {/* <div
          className={
            orgDetails?.showFilter
              ? "order-2 lg:order-1 w-full lg:w-2/3 grid grid-cols-1 gap-6"
              : "w-full order-2 grid grid-cols-1 gap-6"
          }
        >
          {filteredJobList.map((job) => (
            <React.Fragment key={job.id}>
              <div className="bg-white rounded-lg overflow-hidden shadow-PipelineCardsShadow flex items-center justify-between px-5 py-4 hover:scale-105 hover:shadow-md hover:cursor-pointer transition-all duration-300">
                <div className="flex flex-col w-[82%] gap-2 items-start">
                  <h3 className="flex space-x-4 text-gray-600 text-base font-bold">
                    <InitialsLogo
                      className="w-16 h-16 rounded-md overflow-hidden"
                      str={job?.position}
                      width={52}
                      height={52}
                      bgRounded="10px"
                      fontSize={28}
                    />
                    <div className="flex flex-col space-y-1 text-gray-700">
                      <span>{job?.position}</span>
                      <span>
                        {job?.job_type.length > 0 || job?.workplace ? (
                          <>
                            <p className="text-sm flex flex-row gap-2 text-gray-600 font-medium">
                              <FaBriefcase className="mt-1 text-gray-600" />
                              {job?.job_type.length > 0
                                ? job.job_type
                                    .map(
                                      (item) =>
                                        item?.type.charAt(0).toUpperCase() +
                                        item?.type.slice(1)
                                    )
                                    .join(", ")
                                : ""}
                              {job?.job_type.length > 0 && job.workplace
                                ? " • "
                                : ""}
                              {job?.workplace
                                ? job.workplace.charAt(0).toUpperCase() +
                                  job.workplace.slice(1)
                                : ""}
                            </p>
                          </>
                        ) : null}
                      </span>
                    </div>
                  </h3>

                  {job?.location?.length > 0 ? (
                    <p className="text-sm flex flex-row gap-2 text-gray-600">
                      <FaMapMarkerAlt className="mt-1 text-gray-600" />
                      {job?.location?.length > 0
                        ? job?.location
                            ?.map((item) => item?.name ?? item)
                            ?.join(",")
                        : ""}
                    </p>
                  ) : null}

                  {job?.salary?.salvisibility === "Display" ? (
                    <p className="text-sm flex flex-row gap-2 text-gray-600">
                      <GrMoney className="mt-1 text-gray-600 mr-[2px]" />
                      {job?.salary?.currency ? job.salary.currency : "₹"}
                      {job?.salary?.min && job?.salary?.max
                        ? ` ${job.salary.min.toLocaleString()} - ${job.salary.max.toLocaleString()}`
                        : ""}
                    </p>
                  ) : null}
                  {job?.skills && Object.keys(job.skills).length > 0 ? (
                    <div className="flex flex-wrap gap-2 mt-2">
                      {Object.keys(job.skills)
                        .filter((skill) => skill.split(" ").length <= 2) // Only include skills with one or two words
                        .slice(0, 10) // Limit to the first 10 skills
                        .map((skill) => (
                          <span
                            key={`${skill}-${Date.now()}-${Math.random()}`}
                            className="bg-defaultBg text-gray-700 text-sm font-semibold px-2 py-1 rounded"
                          >
                            {skill}
                          </span>
                        ))}
                    </div>
                  ) : null}
                </div>
                <div className="w-[18%] h-full flex flex-col justify-between">
                  <div className="flex flex-col items-end justify-end">
                    {job?.createdAt && (
                      <p className="flex text-gray-600 mb-4 items-center pr-2">
                        <IoMdTime className="text-lg mr-1 text-gray-600" />
                        {timeDifference(new Date() - new Date(job?.createdAt))}
                      </p>
                    )}
                  </div>
                  <button
                    onClick={() => nav(`/job-post/details/${job?.id}`)}
                    className="flex justify-center items-center flex-row gap-2 mb-4 text-white hover:text-white hover:no-underline border border-white  outline-0  font-medium px-4 py-2 rounded-md hover:scale-105 transition-all duration-300"
                    style={{
                      backgroundColor: orgDetails?.theme_color,
                      outlineColor: orgDetails?.theme_color,
                    }}
                  >
                    APPLY
                  </button>
                </div>
              </div>
            </React.Fragment>
          ))}
        </div> */}
        <div
          className={
            orgDetails?.showFilter
              ? "order-2 lg:order-1 w-full lg:w-2/3 grid grid-cols-1 gap-6"
              : "w-full order-2 grid grid-cols-1 gap-6"
          }
        >
          {filteredJobList.map((job) => (
            <React.Fragment key={job.id}>
              <div className="bg-white rounded-lg overflow-hidden shadow-PipelineCardsShadow flex items-center justify-between px-5 py-4 hover:scale-105 hover:shadow-md hover:cursor-pointer transition-all duration-300">
                {/* Wrap job details in an anchor tag for right-click options */}
                <a
                  href={`/job-post/details/${job?.id}`}
                  // target="_blank"
                  // rel="noopener noreferrer"
                  className="flex flex-col gap-2 items-start hover:no-underline no-underline w-[81%]"
                >
                  <h3 className="text-gray-700 text-base font-bold hover:no-underline no-underline">
                    {job?.position}
                  </h3>
                  {/* {job?.job_type.length > 0 || job?.workplace ? (
                    <p className="text-sm flex flex-row gap-2 text-gray-700 hover:no-underline no-underline">
                      <FaBriefcase className="mt-1 text-gray-700" />
                      {job?.job_type.length > 0
                        ? job?.job_type.map((item) => item?.type).join(", ")
                        : ""}
                      {job?.job_type.length > 0 && job.workplace ? " • " : ""}
                      {job?.workplace ?? ""}
                    </p>
                  ) : null} */}
                  {job?.job_type.length > 0 || job?.workplace ? (
                    <>
                      <p className="text-sm flex flex-row gap-2 text-gray-600 font-medium hover:no-underline no-underline">
                        <FaBriefcase className="mt-1 text-gray-600" />
                        {job?.job_type.length > 0
                          ? job.job_type
                              .map(
                                (item) =>
                                  item?.type.charAt(0).toUpperCase() +
                                  item?.type.slice(1),
                              )
                              .join(", ")
                          : ""}
                        {job?.job_type.length > 0 && job.workplace ? " • " : ""}
                        {job?.workplace
                          ? job.workplace.charAt(0).toUpperCase() +
                            job.workplace.slice(1)
                          : ""}
                      </p>
                    </>
                  ) : null}
                  {job?.location?.length > 0 ? (
                    <p className="text-sm flex flex-row gap-2 text-gray-600 font-medium hover:no-underline no-underline">
                      <FaMapMarkerAlt className="mt-1 text-gray-600" />
                      {job?.location
                        ?.map((item) => item?.name ?? item)
                        ?.join(", ")}
                    </p>
                  ) : null}
                </a>

                {/* Apply button */}
                <button
                  onClick={() => nav(`/job-post/details/${job?.id}`)}
                  className="flex flex-row gap-2 text-white hover:text-white hover:no-underline border border-white outline-0 font-medium px-6 py-2 rounded-md hover:scale-105 transition-all duration-300 "
                  style={{
                    backgroundColor: orgDetails?.theme_color,
                    outlineColor: orgDetails?.theme_color,
                  }}
                >
                  APPLY
                </button>
              </div>
            </React.Fragment>
          ))}
        </div>

        {/* Job filters */}
        {orgDetails?.showFilter ? (
          <div className="order-1 lg:order-2 w-full lg:w-1/3">
            <div
              className="p-4 border mb-6 shadow-md bg-defaultBg rounded-lg"
              style={{ boxShadow: orgDetails?.theme_color }}
            >
              <JobFilter
                onFilter={handleFilter}
                locations={locations}
                positions={positions}
                workplaces={workplaces}
                jobTypes={jobTypes}
                orgDetails={orgDetails}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default JobList;
