import { Link } from "react-router-dom";
import Bookmark from "../../assets/Bookmark";
import ApplyBtn from "./ApplyBtn";
import InitialsLogo from "../../all/InitialsLogo";
import locationSvg from "../../assets/recruiter/LocationSvg.svg";
import PercentDonut from "../../analytics/PercentDonut";

const moment = require("moment");
const now = moment();

const timeDifference = (difference) => {
  // Format the time difference
  if (difference < 1000) {
    return "Just now";
  } else if (difference < 60000) {
    return `${Math.floor(difference / 1000)} seconds ago`;
  } else if (difference < 3600000) {
    return `${Math.floor(difference / 60000)} minutes ago`;
  } else if (difference < 86400000) {
    return `${Math.floor(difference / 3600000)} hours ago`;
  } else if (difference < 2592000000) {
    return `${Math.floor(difference / 86400000)} days ago`;
  } else if (difference < 31536000000) {
    return `${Math.floor(difference / 2592000000)} months ago`;
  } else {
    return `${Math.floor(difference / 31536000000)} years ago`;
  }
};

export default function SavedJobPost({ job, reload_function }) {
  return (
    <div
      to={`/jobpost/${job?.id}`}
      className="block  text-black bg-[#FFFFFF] hover:bg-gray-100"
    >
      <table className="w-full table-fixed border-collapse">
        <tbody>
          <tr className="border-b border-gray-200">
            <td className="p-4 w-1/4 align-top">
              <div className="flex items-center">
                <div className="mr-4">
                  <InitialsLogo
                    className="w-16 h-16"
                    str={job.company?.name}
                    width={60}
                    height={60}
                    bgRounded="12px"
                    fontSize={28}
                  />
                </div>
                <div>
                  <h4 className="font-semibold text-lg">{job?.position}</h4>
                  <span className="text-gray-500">{job?.company?.name}</span>
                </div>
              </div>
            </td>
            <td className="p-4 w-1/4 text-center align-center">
              <div className="flex items-center">
                <img src={locationSvg} alt="location" />
                <h5 className="">
                  {" "}
                  {job?.location?.map((loc, i) => loc?.name)?.join(", ")}
                </h5>
              </div>
            </td>
            <td className="p-4 w-1/4 text-center ">
              <h5 className="text-gray-500">
                {timeDifference(new Date() - new Date(job?.createdAt))}
              </h5>
            </td>
            <td className="p-4 w-1/4 text-center ">
              <div className="flex justify-center space-x-4">
                <ApplyBtn
                  applied={false}
                  job_id={job?.id}
                  reload_function={reload_function}
                />
                <Bookmark
                  bookmarked={true}
                  job_id={job?.id}
                  reload_function={reload_function}
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
