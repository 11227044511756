import React, { useEffect, useRef, useState } from "react";

const SelectMatchScore = ({ matchScore, onClick }) => {
  const ref = useRef(null);

  const [showTabsMenu, setShowTabsMenu] = useState(false);
  const [showTabsItem, setShowTabsItem] = useState("Select match");

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setShowTabsMenu(false);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (matchScore) {
      setShowTabsItem(matchScore);
    }
  }, [matchScore]);

  const TabsMenu = [
    {
      name: "Strong Match",
      filter: "strongMatch",
    },
    {
      name: "Good Match",
      filter: "goodMatch",
    },
    {
      name: "Weak Match",
      filter: "notGoodMatch",
    },
  ];

  return (
    <div ref={ref} className="relative flex items-center ">
      <button
        data-tooltip-id="my-tooltip-4"
        data-tooltip-content={"Review and Rate Profile"}
        onClick={() => {
          setShowTabsMenu(!showTabsMenu);
        }}
        className={`w-[8.5rem] flex items-center justify-between gap-2 group border-button rounded-lg border h-7 text-[14px] border-gray-500  ${showTabsItem === "Strong Match" ? "text-[#009107] " : showTabsItem === "Good Match" ? "text-[#a0b83a] " : showTabsItem === "Weak Match" ? "text-[#efb353]" : "border-gray-500 bg-white"} py-2 px-2.5   flex items-center justify-center`}
      >
        {showTabsItem}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            className=" group-hover:stroke-[#1369E9] group-hover:fill-[#1369E9]"
            d="M6.71789 8.30751L6.71704 8.30657L5.18797 6.62649C5.17624 6.6136 5.17017 6.60014 5.16762 6.58407C5.16471 6.56574 5.16673 6.54403 5.17423 6.5241C5.17779 6.51464 5.18191 6.50742 5.18546 6.50247C5.18613 6.50154 5.18675 6.50072 5.18733 6.5L8.14785 6.5L10.8109 6.5C10.8146 6.50456 10.82 6.51266 10.8248 6.52534C10.8326 6.54588 10.8346 6.56776 10.8318 6.58575C10.8294 6.60139 10.8236 6.61416 10.8124 6.62649L8.34492 9.33765C8.14802 9.55399 7.84805 9.55412 7.65103 9.33803C7.65091 9.33791 7.65079 9.33778 7.65068 9.33765L6.71789 8.30751Z"
            fill="#1369E9"
            stroke="#1369E9"
          />
        </svg>
      </button>
      {showTabsMenu && (
        <div className=" absolute bg-[#FFF] mt-[5px] top-[30px] no-list-style text-left rounded-[8px] p-[6px] w-[167px] shadow-menuOptionShadow z-[20]">
          <>
            {TabsMenu?.filter((iter) => iter?.name != showTabsItem)?.map(
              (item, idx) => (
                <li
                  key={idx}
                  className={`flex py-[8px] rounded-[8px] items-center px-[8px] w-[100%] text-[#252E4B] text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px]"
              }`}
                  onClick={() => {
                    onClick(
                      item?.name === "Select match"
                        ? ""
                        : item?.name === "Strong Match"
                          ? true
                          : item?.name === "Good Match"
                            ? true
                            : false,
                      item?.name,
                    );
                    setShowTabsMenu(false);
                    // setShowTabsItem(item?.name);
                  }}
                >
                  {item?.name}
                </li>
              ),
            )}
          </>
        </div>
      )}
      {/* {checkedItems?.length > 0 && (
                <button
                    className=" text-blue-800 text-sm not-italic   min-w-[136px] font-medium border-blue-800 text-center py-2 px-3 bg-[#E8F0FD] rounded-lg shadow border"
                    onClick={() => setShowModal("bulk-action-modal")}
                >{`Edit selected (${checkedItems?.length})`}</button>
            )} */}
    </div>
  );
};

export default SelectMatchScore;
