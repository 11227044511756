import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import LoginPageLogo from "../../../components/assets/candidate/SproutsaiLogo.svg";
import VerifyCode from "./EnterOTP";
import { ScaleLoader } from "react-spinners";
import { useContext } from "react";
import { ForgotPasswordContext } from "../../../lib/context";
import InputBox from "../../../layouts/InputBox";

const core = require("../../../lib/core");

function Email() {
  const { email, setEmail, setStep } = useContext(ForgotPasswordContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginText, setLoginText] = useState("Continue");

  const handleSignUp = async (e) => {
    e.preventDefault();
    setLoginText(<ScaleLoader height={14} color="#ffffff" loading={true} />);
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_FORGOT_PSWD_SEND_OTP,
      1,
      {
        email: email,
      },
    );
    if (json?.data) setStep(<VerifyCode />);
    else setErrorMessage("Email doesn't exist. Please Signup");
    setLoginText("Continue");
  };

  return (
    <form
      autoComplete="false"
      onSubmit={handleSignUp}
      className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5"
    >
      <img
        src={LoginPageLogo}
        className="max-w-full h-8 mb-5"
        alt="SproutsAI"
      />
      <h1 className="w-full text-black text-4xl font-semibold">
        Forgot password?
      </h1>
      <h4 className="w-full text-slate-500 text-base">
        No worries, we’ll send you reset instructions.
      </h4>
      <InputBox
        type="email"
        placeholder="Enter your email"
        className="w-full border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
        value={email}
        required
        onChange={(event) => setEmail(() => event.target.value)}
      />
      {String(errorMessage)?.length > 0 && (
        <p className={`w-full flex text-red-900 text-sm`}>{errorMessage}</p>
      )}
      <button className="btn-primary btn-md h-12 min-w-[120px]">
        {loginText}
      </button>
      <Link
        to="/login"
        className="text-gray-700 text-sm w-full mt-4 flex items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M8.47533 4.94141L3.41699 9.99974L8.47533 15.0581"
            stroke="#252E4B"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.5836 10H3.55859"
            stroke="#252E4B"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        Back to login
      </Link>
    </form>
  );
}

export default Email;
