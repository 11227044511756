import React, { useEffect, useRef, useState } from "react";
import InitialsLogo from "../../all/InitialsLogo";
const core = require("../../../lib/core");

function SearchUser({ label, emails, setEmails, removerEmail }) {
  //   const [inputValues, setInputValues] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");

  // Mock email suggestions (replace with your actual suggestion logic)

  const [mockSuggestions, setMockSuggestions] = useState([]);

  useEffect(() => {
    if (inputValue.length > 0) {
      const filtered = mockSuggestions?.filter((item) =>
        item?.email?.toLowerCase().includes(inputValue.toLowerCase()),
      );
      setSuggestions(filtered);
    } else {
      setSuggestions([]);
    }
  }, [inputValue]);

  const addEmail = (email) => {
    const isDuplicate = emails.some((item) => item === email);
    if (!isDuplicate) {
      setEmails([...emails, email]);
      setInputValue("");
      setSuggestions([]);
    }
  };

  const removeEmail = (index) => {
    const updatedEmails = emails.filter((_, i) => i !== index);
    console.log("Em", updatedEmails);
    setEmails(updatedEmails);
  };

  const handleInputChanges = (e) => {
    setInputValue(e.target.value);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const exactMatch = suggestions.find(
        (item) => item.email.toLowerCase() === inputValue.toLowerCase(),
      );

      if (exactMatch) {
        addEmail(exactMatch);
      } else if (isValidEmail(inputValue)) {
        addEmail(inputValue);
      }
    }
  };
  const get_user_list = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER_LIST_ORG,
        1,
      );
      if (json?.data) {
        console.log(json);
        setMockSuggestions(json?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    get_user_list();
  }, []);

  console.log("Suggesstions", emails);
  return (
    <div className="w-full   mt-[20px]">
      <label
        className=" text-right text-[14px] font-normal not-italic "
        htmlFor=""
      >
        {label}
      </label>
      <div className="flex flex-wrap items-center  rounded-md p-2 focus-within:ring-2    border border-gray-400   text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[16px] placeholder:font-normal active-within:border-[#1369E9] focus-within:border-[#1369E9] focus-within:shadow-inputShadow placeholder:text-[#5F6989] ">
        {emails?.map((email, index) => (
          <div
            key={index}
            className="flex items-center bg-gray-100 rounded-full gap-2 pr-2  m-1 border-2 border-gray-300"
          >
            <InitialsLogo
              str={email?.email}
              fontSize="12px"
              width="20px"
              height="20px"
              borderRadius="50%"
            />
            <span className="text-sm">{email}</span>
            <button
              onClick={() => removeEmail(index)}
              className=" text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 32 32"
                fill="none"
              >
                <path
                  d="M24 24L8 8M24 8L8 24"
                  stroke="#001B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </button>
          </div>
        ))}
        <input
          ref={inputRef}
          id="email-input"
          type="text"
          className="flex-grow outline-none text-sm p-1"
          placeholder={emails.length === 0 ? "Enter email addresses" : ""}
          value={inputValue}
          onChange={handleInputChanges}
          onKeyDown={handleKeyDown}
        />
      </div>

      {suggestions.length > 0 && (
        <ul className="mt-1 border border-gray-200 rounded-md shadow-sm bg-white">
          {suggestions?.map((suggestion, index) => (
            <li
              key={index}
              className="px-3 py-2 hover:bg-gray-100 cursor-pointer text-sm"
              onClick={() => addEmail(suggestion?.email)}
            >
              {suggestion?.email}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default SearchUser;
