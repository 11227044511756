import React, { useEffect, useState } from "react";
import Modal from "../../modal/Modal";

function CandidateFeedbackModal({ modalDismiss, feedback }) {
  const [aggFeedback, setAggFeedback] = useState({});

  useEffect(() => {
    if (feedback) setAggFeedback(feedback);
  }, [feedback]);

  const feedbacks = {
    education: [],
    experience: [
      "Product Manager or QA experience",
      "construction experience",
      "early-stage startup experience in a product role",
    ],
    skills: ["Product Management"],
    job_title: ["Product Manager", "QA"],
  };
  return (
    <Modal
      heading={"Aggregate Candidate Feedback"}
      subheading={
        "Insights from Recruiter Reviews to Enhance Sourcing Strategies"
      }
      modalDismiss={() => modalDismiss(false)}
      noBtns={true}
    >
      <div className="">
        <div className="mb-4">
          <h2 className="text-sm font-semibold mb-2">Experience</h2>
          {aggFeedback?.experience?.length > 0 ? (
            <ul className="list-disc pl-5">
              {aggFeedback?.experience?.map((item, index) => (
                <li key={index} className="text-gray-800">
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No feedback on experience</p>
          )}
        </div>

        <div className="mb-4">
          <h2 className="text-sm font-semibold mb-2">Education</h2>
          {aggFeedback?.education?.length > 0 ? (
            <ul className="list-disc pl-5">
              {aggFeedback?.education?.map((item, index) => (
                <li key={index} className="text-gray-800">
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No feedback on education</p>
          )}
        </div>

        <div className="mb-4">
          <h2 className="text-sm font-semibold mb-2">Skills</h2>
          {aggFeedback?.skills?.length > 0 ? (
            <ul className="list-disc pl-5">
              {aggFeedback?.skills?.map((item, index) => (
                <li key={index} className="text-gray-800">
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No feedback on skills</p>
          )}
        </div>

        <div>
          <h2 className="text-sm font-semibold mb-2">Job Titles</h2>
          {aggFeedback?.job_title?.length > 0 ? (
            <ul className="list-disc pl-5">
              {aggFeedback?.job_title?.map((item, index) => (
                <li key={index} className="text-gray-800">
                  {item}
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-gray-600">No feedback on job titles</p>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default CandidateFeedbackModal;
