import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { AuthStageContext } from "../../../lib/context";
import { authStages } from "../../../lib/constants";
const core = require("../../../lib/core");

export default function VerifyCode() {
  const { setUser } = useContext(core.UserContext);
  const { setStage, email } = useContext(AuthStageContext);
  const [errorMessage, setErrorMessage] = useState("");

  const inputs = useRef([]);
  const loc = useLocation();

  useEffect(() => {
    if (inputs.current.length > 0) {
      inputs.current[0].focus();
    }

    function handleKeyDown(event, index) {
      if (event.key === "Backspace") {
        inputs.current[index].value = "";
        if (index !== 0) inputs.current[index - 1].focus();
      } else if (event.key === "Delete") {
        inputs.current[index].value = "";
        if (index !== inputs.current.length - 1)
          inputs.current[index + 1].focus();
      } else {
        if (
          index === inputs.current.length - 1 &&
          inputs.current[index].value !== ""
        )
          return true;
        else if (event.key.match(/^[0-9]$/)) {
          inputs.current[index].value = event.key;
          if (index !== inputs.current.length - 1)
            inputs.current[index + 1].focus();
          event.preventDefault();
        } else {
          inputs.current[index].value = "";
        }
      }
    }
    function handlePaste(event) {
      try {
        event.preventDefault();
        const pasteData = event.clipboardData.getData("text/plain").trim();
        if (/^[0-9]{6}$/.test(pasteData)) {
          inputs.current.forEach((input, index) => {
            input.value = pasteData[index];
          });
        }
      } catch (error) {}
    }

    inputs.current.forEach((input, index) => {
      input.addEventListener("keydown", (event) => handleKeyDown(event, index));
    });
    window.addEventListener("paste", handlePaste);

    return () => {
      inputs?.current?.forEach((input, index) => {
        input?.removeEventListener("keydown", (event) =>
          handleKeyDown(event, index),
        );
      });
    };
  }, []);

  async function handleVerification(e) {
    try {
      e.preventDefault();
      if (!email) throw "No email registered, please sign up again";
      if (inputs?.current?.length != 6) throw "Incomplete code";
      inputs?.current?.map((d) => {
        if (!d.value.match(/^[0-9]$/))
          throw "Improper code, OTP should be numeric";
      });
      let code = [...inputs?.current?.map((d) => +d.value)].join("").trim();
      let resp = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_VERIFY_EMAIL_OTP,
        0,
        {
          email: email,
          code: code,
          stage: authStages.VERIFIED,
        },
      );
      if (resp?.data) {
        core.setData(core.data.ACCESS_TOKEN, resp.data.token);
        core.setData(core.data.UID, resp.data.user);
        setUser({
          email: email,
          id: resp.data.user,
          stage: resp?.data?.stage ?? authStages?.VERIFIED,
        });
        setStage(resp?.data?.stage ?? authStages?.VERIFIED);
      } else {
        setErrorMessage(resp?.error?.message);
      }
    } catch (error) {
      if (
        error == "No email registered, please sign up again" ||
        error == "Incomplete code" ||
        error == "Improper code, OTP should be numeric"
      )
        setErrorMessage(error);
      console.error(error);
    }
  }

  async function resendConfirmationCode() {
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.USER_API.POST_SEND_EMAIL_OTP,
        1,
        {
          email: email,
        },
      );
      if (json?.data) {
        alert("Code resent, please check on mail");
        console.log("code resent successfully");
      }
    } catch (err) {
      console.log("error resending code: ", err);
    }
  }

  return (
    <form className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
      <h1 className="w-full text-black text-4xl font-semibold">
        Enter Verification Code
      </h1>
      <h4 className="w-full text-slate-500 text-base">
        Please enter the 6-digit code sent to you at <b>{email}</b>
      </h4>
      <div className="w-full flex justify-between my-2">
        {[...Array(6)].map((_, index) => (
          <input
            key={index}
            className="border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600 w-12"
            type="text"
            maxLength="1"
            min={0}
            max={9}
            id={`otp-input-${index}`}
            ref={(el) => (inputs.current[index] = el)}
          />
        ))}
      </div>
      {String(errorMessage)?.length > 0 && (
        <p className={`w-full flex text-red-900 text-sm`}>{errorMessage}</p>
      )}
      <button
        className="btn-primary btn-md h-12 min-w-[120px]"
        onClick={handleVerification}
      >
        Continue
      </button>
      <div className="w-full flex items-center">
        <span> Didn't get the code</span>
        <a
          onClick={resendConfirmationCode}
          className="text-blue inline-block ml-2 hover:text-blue-900 group"
        >
          <svg
            className="mr-2 inline-block"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              className=" group-hover:fill-blue-900 fill-blue-800"
              d="M16.2718 6.71476H14.1038C13.3409 6.71476 12.7246 7.35309 12.7246 8.14318C12.7246 8.93327 13.3409 9.57159 14.1038 9.57159H19.6208C20.3837 9.57159 21 8.93327 21 8.14318V2.42953C21 1.63944 20.3837 1.00112 19.6208 1.00112C18.8579 1.00112 18.2415 1.63944 18.2415 2.42953V4.71499L17.4829 3.92936C13.7116 0.0235465 7.59986 0.0235465 3.82851 3.92936C0.0571628 7.83517 0.0571628 14.1648 3.82851 18.0706C7.59986 21.9765 13.7116 21.9765 17.4829 18.0706C18.0217 17.5127 18.0217 16.6065 17.4829 16.0485C16.9442 15.4906 16.0692 15.4906 15.5305 16.0485C12.8366 18.8384 8.4705 18.8384 5.77668 16.0485C3.08286 13.2587 3.08286 8.73686 5.77668 5.94699C8.4705 3.15713 12.8366 3.15713 15.5305 5.94699L16.2718 6.71476Z"
              fill="#043DAB"
              stroke="white"
              strokeWidth="0.6"
            />
          </svg>
          Send Again
        </a>
      </div>
    </form>
  );
}
