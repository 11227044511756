import React from "react";

const GenericContent = ({ data }) => {
  return (
    <>
      {data?.prev_stage && data?.new_stage && (
        <div className="w-full flex justify-start items-center gap-4">
          <span className="px-2 py-1 rounded-lg bg-gray-300 text-gray-900 text-sm not-italic font-medium w-auto">
            {data?.prev_stage}
          </span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M17.25 8.25 21 12m0 0-3.75 3.75M21 12H3"
            />
          </svg>

          <span className="px-2 py-1 rounded-lg bg-bluegreen-200 text-gray-900 text-sm not-italic font-medium w-auto">
            {data?.new_stage}
          </span>
        </div>
      )}
      {!(data?.prev_stage && data?.new_stage) &&
        data?.body &&
        String(data?.body)?.length > 0 && (
          <p className="text-gray-800 text-sm not-italic font-normal w-full">
            {data?.body}
          </p>
        )}
    </>
  );
};

export default GenericContent;
