import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  company: "",
  position: "",
  department: "",
  location: [""],
  salary: "",
  jobType: [],
  workplace: "",
  noticePeriod: "",
  description: "",
  roles: "",
  experienceEducation: "",
  experienceLevelMin: "",
  experienceLevelMax: "",
  SalaryCurrency: "",
  expectedSalaryMin: "",
  expectedSalaryMax: "",
  salaryDuration: "",
  expectdEquityMin: "",
  expectdEquityMax: "",
  companyUrl: "",
  companyIndustryType: "",
  aboutCompany: "",
  criterias: [],
  pipeline: [
    "Application Review",
    "Screening",
    "Interview",
    "Submitted",
    "Accepted",
    "Hired",
    "Rejected",
  ],
  // ... other initial state properties
  loading: false,
  error: null,
};

// Define an async thunk for fetching job details
export const fetchJobDetails = createAsyncThunk(
  "jobDetails/fetchJobDetails",
  async (jobId, { rejectWithValue }) => {
    try {
      // Replace the following line with the actual API call to fetch job details using jobId
      // For example: const response = await axios.get(`/api/jobs/${jobId}`);
      // Ensure to handle errors appropriately and return the data if successful
      const jobDetailsData = {}; // Replace this with the actual response data from the API
      return jobDetailsData;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

const jobDetailsSlice = createSlice({
  name: "jobDetails",
  initialState,
  reducers: {
    setJobDetails: (state, action) => {
      return { ...state, ...action.payload };
    },
    // ... other reducers for updating specific properties of jobDetails
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchJobDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state = { ...state, ...action.payload };
      })
      .addCase(fetchJobDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { setJobDetails } = jobDetailsSlice.actions;

export default jobDetailsSlice.reducer;
