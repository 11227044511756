import React from "react";
import LandingText from "../../components/auth/LandingText";
import LoginPageImg from "../../components/assets/candidate/LogInPageImg.png";
import CustomButton from "../../layouts/Button";
import { useNavigate } from "react-router-dom";

const GmailVerificationComplete = () => {
  const nav = useNavigate();
  return (
    <main className="w-screen md:h-screen min-h-screen h-full flex md:flex-row flex-col py-5 md:py-0 bg-defaultBg overflow-scroll scrollbar-hide">
      <div className="w-full md:w-7/12 h-auto md:h-full flex items-center justify-center flex-col">
        <LandingText />
        <img
          src={LoginPageImg}
          className="max-w-full h-auto -mt-16"
          alt="AI based decision engine"
        />
      </div>
      <div className="shadow-[0px_4px_32px_#043dab14] w-full md:w-5/12 h-auto md:h-full rounded-none md:rounded-l-lg md:bg-white bg-defaultBg px-4 md:px-0 mt-4 md:mt-0">
        <div className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
          <h1 className="w-full text-black text-4xl font-semibold">
            Email verification complete
          </h1>
          <h4 className="col-xs-12 no-padding w-full text-slate-500 text-base">
            Thank you for verifying your email.
          </h4>
          <button
            className="btn-primary btn-md h-12 min-w-[120px]"
            onClick={() => nav("/")}
          >
            {"Go to dashboard ->"}
          </button>
        </div>
      </div>
    </main>
  );
};

export default GmailVerificationComplete;
