import React, { useEffect, useState } from "react";
import Modal from "../../components/modal/Modal";

function InviteRecruiter({ modalDismiss, allRoles }) {
  const [inputEmail, setInputEmail] = useState("");
  const [role, setRole] = useState("");

  const onCancel = () => {
    modalDismiss();
  };

  // const inviteCandidate = async () => {
  //     try {

  //         const json = await core.API(
  //             core.API_METHODS.POST,
  //             core.RECRUITER_API.POST_INVITE_CANDIDATE_ASSESSMENT,
  //             1,
  //             {
  //                 email: emailList?.length > 0 ? emailList : [inputEmail],
  //                 assessment: AssessmentId,
  //                 expiryHrs: expiryHrs,
  //             }
  //         );
  //         if (json.data) {
  //             setMessage({
  //                 type: "success",
  //                 message: "Invitation sent to candidate.",
  //             });
  //             setTimeout(() => modalDismiss(), 2500);
  //             // toast.success(json.data.message);
  //         } else {
  //             setMessage({
  //                 type: "error",
  //                 message:
  //                     json?.error?.message ?? "Something went wrong. Please try again",
  //             });
  //             // toast.error(json.error.message);
  //         }
  //     } catch (error) {
  //         setMessage({
  //             type: "error",
  //             message: error ?? "Something went wrong. Please try again",
  //         });
  //     }
  // };

  const modalElements = {
    heading: "Invite new team member",
    subheading:
      "Invited team members receive an email from SproutsAI. Invites expires after 7 days.",
    modalDismiss: () => onCancel(),
    onSave: () => {
      inviteCandidate();
    },

    defaultButtons: {
      primaryDisabled: !(String(inputEmail)?.length > 0),
    },
  };
  return (
    <Modal {...modalElements}>
      <div className="w-full">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Teammate email
        </h3>
        <input
          type="email"
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          required
          value={inputEmail}
          placeholder="Press enter to insert email"
          onChange={(e) => {
            setInputEmail(e.target.value);
          }}
        />
      </div>
      <div className="w-full assessment">
        <h3
          className="w-full text-black text-sm font-normal"
          htmlFor="assessment-dropdown"
        >
          Permission access level
        </h3>
        <select
          name="assessment-dropdown"
          required
          className="w-full border border-gray-400 px-4 py-3 rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] disabled:pointer-events-none"
          onChange={(e) => setRole(e.target.value)}
          defaultValue={""}
          style={{
            appearance: "none", // Remove default arrow icon
            paddingRight: "24px", // Adjust padding to make space for the placeholder
            backgroundImage:
              "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
            backgroundRepeat: "no-repeat",
            backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
            backgroundSize: "16px", // Adjust size of the arrow icon
          }}
        >
          <option disabled value={""}>
            Select role
          </option>
          {allRoles?.map((role) => (
            <option key={role?.id} value={role?.id}>
              {role?.name}
            </option>
          ))}
        </select>
      </div>
    </Modal>
  );
}

export default InviteRecruiter;
