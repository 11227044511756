import React, { useMemo, useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import Title from "antd/es/skeleton/Title";
import { current } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
const localizer = momentLocalizer(moment);

const CustomToolbar = ({ date, onNavigate }) => {
  const goToBack = () => {
    onNavigate("PREV");
  };

  const goToNext = () => {
    onNavigate("NEXT");
  };
  const now = new Date();
  const isToday = moment(now).isSame(moment(date), "day");
  return (
    <div className="rbc-toolbar">
      <span
        className={`rbc-btn-group ${isToday ? "cursor-not-allowed bg-gray-300 opacity-50" : ""}`}
      >
        <button type="button" onClick={goToBack} disabled={isToday}>
          &lt;
        </button>
      </span>
      <span className="rbc-toolbar-label">
        {moment(date).format("MMMM D, YYYY")}
      </span>
      <span className="rbc-btn-group">
        <button type="button" onClick={goToNext}>
          &gt;
        </button>
      </span>
    </div>
  );
};

function GoogleCalenderView({
  eventsForSelectedDay,
  CandidateAvailability,
  selectedEmails,
  selectedDate,
  onDateChange,
  SelectedTitle,
  handleTimeChange,
  StartTime,
  endTime,
  setInput,
}) {
  const [currentDate, setCurrentDate] = useState(selectedDate);

  useEffect(() => {
    setCurrentDate(selectedDate);
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setCurrentDate(date);
    onDateChange(date);
  };
  const calendarStyle = {
    height: 700,
    backgroundColor: "#ffffff",
    borderRadius: "8px",
    padding: "16px",
    fontFamily: "Arial, sans-serif",
  };
  const colorPalette = [
    "#3788d8", // Blue
    "#4caf50", // Green
    "#f44336", // Red
    "#9c27b0", // Purple
    "#00bcd4", // Cyan
    "#795548", // Brown
    "#607d8b", // Blue Grey
  ];

  const splitMultiDayEvents = (events) => {
    return events.flatMap((event) => {
      const { start, end, title, ...eventProps } = event;
      const startDate = moment(start).startOf("day");
      const endDate = moment(end).startOf("day");
      const daysDiff = endDate.diff(startDate, "days");

      if (daysDiff === 0) {
        return [event];
      }

      const fullRangeTitle = `${title} (${moment(start).format("MMM D, HH:mm")} - ${moment(end).format("MMM D, HH:mm")})`;

      return Array.from({ length: daysDiff + 1 }, (_, index) => {
        const day = moment(startDate).add(index, "days");
        return {
          ...eventProps,
          title: fullRangeTitle,
          start: index === 0 ? start : day.toDate(),
          end: index === daysDiff ? end : day.clone().endOf("day").toDate(),
          isMultiDay: true,
          originalEvent: event,
        };
      });
    });
  };

  const allEvents = useMemo(() => {
    return splitMultiDayEvents([
      ...eventsForSelectedDay,
      ...CandidateAvailability.map((event) => ({
        ...event,
        isCandidate: true,
      })),
    ]);
  }, [eventsForSelectedDay, CandidateAvailability]);

  const filteredEvents = useMemo(() => {
    return allEvents.filter(
      (event) =>
        event.isCandidate || selectedEmails.includes(event.interviewer),
    );
  }, [allEvents, selectedEmails]);

  const interviewerColors = useMemo(() => {
    const uniqueInterviewers = [
      ...new Set([
        ...selectedEmails,
        ...eventsForSelectedDay.map((event) => event.interviewer),
      ]),
    ];
    return Object.fromEntries(
      uniqueInterviewers?.map((interviewer, index) => [
        interviewer,
        colorPalette[index % colorPalette.length],
      ]),
    );
  }, [eventsForSelectedDay, selectedEmails]);

  const eventStyleGetter = (event) => {
    const duration = moment(event.end).diff(moment(event.start), "minutes");
    const isHalfHour = duration === 30;

    const baseStyle = {
      borderRadius: "4px",
      opacity: 0.8,
      color: "#fff",
      border: "0",
      fontWeight: "500",
      fontSize: "12px",
      padding: "2px 4px",
      minHeight: "24px",
      ...(isHalfHour && {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      }),
    };

    if (event.current) {
      return {
        style: { ...baseStyle, backgroundColor: "#37B7C3" },
      };
    }

    if (event.isCandidate) {
      return {
        style: { ...baseStyle, backgroundColor: "orange" },
      };
    }

    const backgroundColor =
      interviewerColors[event.interviewer] || colorPalette[0];

    if (event.isMultiDay) {
      return {
        style: {
          ...baseStyle,
          backgroundColor,
          borderRadius: 0,
        },
        className: "multi-day-event",
      };
    }

    return {
      style: { ...baseStyle, backgroundColor },
    };
  };

  const handleSelectEvent = (event) => {
    if (event.isMultiDay) {
      console.log("Multi-day event selected:", event.originalEvent);
    } else {
      console.log("Event selected:", event);
    }
  };
  const interviewersWithEvents = selectedEmails?.filter((email) =>
    allEvents.some((event) => event.interviewer === email),
  );
  const views = ["day"];

  const [selectedSlot, setSelectedSlot] = useState({
    date: moment(currentDate).format("YYYY-MM-DD"),
    start: StartTime ? moment(StartTime, "h:mm A").format("h:mm A") : "",
    end: endTime ? moment(endTime, "h:mm A").format("h:mm A") : "",
    title: SelectedTitle,
  });

  // Handle the selected timeslot
  const handleSelectSlot = (slotInfo) => {
    const start = moment(slotInfo.start);
    let end = moment(slotInfo.end);
    const maxEndTime = start.clone().endOf("day");
    if (start.isSameOrAfter(moment(start).hour(23).minute(30))) {
      end = maxEndTime;
    } else {
      end =
        end.isValid() && end.isAfter(start)
          ? end
          : start.clone().add(30, "minutes");
      if (end.isAfter(maxEndTime)) {
        end = maxEndTime;
      }
    }
    setSelectedSlot({
      date: start.format("YYYY-MM-DD"),
      start: start.format("h:mm A"),
      end: end.format("h:mm A"),
      title: SelectedTitle,
    });
  };
  useEffect(() => {
    setSelectedSlot((prevSlot) => ({
      ...prevSlot,
      title: SelectedTitle,
    }));
  }, [SelectedTitle]);

  useEffect(() => {
    setSelectedSlot((prevSlot) => ({
      ...prevSlot,
      start: StartTime
        ? moment(StartTime, "h:mm A").format("h:mm A")
        : prevSlot.start,
    }));
  }, [StartTime]);

  useEffect(() => {
    setSelectedSlot((prevSlot) => {
      const startMoment = moment(prevSlot.start, "h:mm A");
      let validEnd;

      if (startMoment.isSameOrAfter(moment(startMoment).hour(23).minute(30))) {
        validEnd = startMoment.clone().endOf("day").format("h:mm A"); // 11:59 PM
      } else {
        // Add 30 minutes if the end time is not provided or invalid
        validEnd = endTime
          ? moment(endTime, "h:mm A").format("h:mm A")
          : startMoment.clone().add(30, "minutes").format("h:mm A");

        const maxEndTime = startMoment.clone().endOf("day");
        if (moment(validEnd, "h:mm A").isAfter(maxEndTime)) {
          validEnd = maxEndTime.format("h:mm A");
        }
      }

      return {
        ...prevSlot,
        end: validEnd,
      };
    });
  }, [endTime]);

  useEffect(() => {
    let calculatedEnd = moment(selectedSlot?.start, "h:mm A").add(
      30,
      "minutes",
    );
    const startMoment = moment(selectedSlot?.start, "h:mm A");

    // Cap end time at 11:59 PM if start is between 11:30 PM and 11:59 PM
    const maxEndTime = startMoment.clone().endOf("day");
    if (startMoment.isSameOrAfter(moment(startMoment).hour(23).minute(30))) {
      calculatedEnd = maxEndTime;
    } else if (calculatedEnd.isAfter(maxEndTime)) {
      calculatedEnd = maxEndTime;
    }
    const finalEndTime = selectedSlot?.end || calculatedEnd.format("h:mm A");
    handleTimeChange(selectedSlot?.start, true, true);
    handleTimeChange(finalEndTime, false, true);

    setInput("date", currentDate);
  }, [selectedSlot.start]);

  useEffect(() => {
    setSelectedSlot((prevSlot) => {
      return {
        ...prevSlot,
        date: moment(selectedDate).format("YYYY-MM-DD"),
      };
    });
  }, [selectedDate]);
  const now = new Date();
  const allEventsWithSelectedSlot = useMemo(() => {
    return [
      ...filteredEvents,
      {
        start: moment(
          `${selectedSlot?.date} ${selectedSlot?.start}`,
          "YYYY-MM-DD h:mm A",
        ).toDate(),
        end: moment(
          `${selectedSlot?.date} ${selectedSlot?.end}`,
          "YYYY-MM-DD h:mm A",
        ).toDate(),
        title: selectedSlot.title,
        current: true,
      },
    ];

    // return filteredEvents;
  }, [filteredEvents, selectedSlot]);

  const EventComponent = ({ event }) => {
    const duration = moment(event.end).diff(moment(event.start), "minutes");
    const isHalfHour = duration === 30;

    if (isHalfHour) {
      return (
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          className="text-sm"
        >
          <span
            style={{
              marginLeft: "4px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    return (
      <div className="text-sm">
        <div>{event.title}</div>
      </div>
    );
  };

  const dayColumnWrapper = ({ event }) => {
    const duration = moment(event.end).diff(moment(event.start), "minutes");
    const isHalfHour = duration === 30;

    if (isHalfHour) {
      return (
        <div
          style={{ display: "flex", alignItems: "center", gap: "4px" }}
          className="text-sm"
        >
          <span
            style={{
              marginLeft: "4px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {event.title}
          </span>
        </div>
      );
    }
    return (
      <div className="text-sm">
        <div>{event.title}</div>
      </div>
    );
  };

  return (
    <div className="w-full">
      <div className="flex flex-wrap gap-4 mb-4">
        {interviewersWithEvents?.map((email, index) => (
          <div key={email} className="flex items-center px-4 py-3 my-2">
            <div
              className="w-4 h-4 mr-2 rounded-full"
              style={{
                backgroundColor:
                  interviewerColors[email] ||
                  colorPalette[index % colorPalette.length],
              }}
            ></div>
            <span>{email}</span>
          </div>
        ))}
      </div>
      <Calendar
        localizer={localizer}
        events={allEventsWithSelectedSlot ?? []}
        date={currentDate}
        defaultView="day"
        startAccessor="start"
        views={views}
        endAccessor="end"
        style={calendarStyle}
        onNavigate={handleDateChange}
        BackgroundWrapper="red"
        eventPropGetter={eventStyleGetter}
        onSelectEvent={handleSelectEvent}
        selectable={true}
        onSelectSlot={(slotInfo) => {
          handleSelectSlot(slotInfo);
        }}
        components={{
          toolbar: CustomToolbar,
          event: EventComponent,
          // dayColumnWrapper: dayColumnWrapper,
        }}
        formats={{
          timeGutterFormat: (date, culture, localizer) =>
            localizer.format(date, "HH:mm", culture),
          eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
            localizer.format(start, "HH:mm", culture) +
            " - " +
            localizer.format(end, "HH:mm", culture),
        }}
        min={
          new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0)
        }
      />
    </div>
  );
}

export default GoogleCalenderView;
