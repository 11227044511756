import React from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import LeadsCandidatesListItems from "./LeadsCandidatesListItems";

function LeadsCandidatesList() {
  const jobList = [
    { id: 1, title: "Job1" },
    { id: 2, title: "Job2" },
  ];
  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        {/* <JobSidebar active="Dashboard" /> */}
        <div className="w-full">
          {/* <HeaderNew /> */}
          <LeadCandidatesListContent />
        </div>
      </div>
    </div>
  );
}

export default LeadsCandidatesList;

const LeadCandidatesListContent = () => {
  return (
    <main className="relative block w-[calc(100%-16px)] h-[calc(100vh-20px)] top-[15px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      <LeadsCandidatesListItems />
    </main>
  );
};
