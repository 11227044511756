import React from "react";
import { ImAttachment } from "react-icons/im";
var parse = require("html-react-parser");

const TemplateData = ({ data, showType = true }) => {
  function getText(html) {
    var divContainer = document.createElement("div");
    divContainer.innerHTML = html;
    return divContainer.textContent || divContainer.innerText || "";
  }
  return (
    data && (
      <>
        <h2 className="text-gray-800 text-base not-italic font-medium ">
          {data?.template_name}
        </h2>
        {showType && (
          <h2 className="text-gray-700 text-sm not-italic font-semibold">
            {data?.template_type}
          </h2>
        )}
        <div className="w-full">
          <label className="text-sm font-normal not-italic">Subject</label>
          <p className="py-1 px-1.5 border-gray-300 border rounded-lg text-sm font-normal not-italic text-gray-800">
            {data?.subject}
          </p>
        </div>
        <div>
          <label className="text-sm font-normal not-italic">Attachments</label>

          {data.attachments?.length > 0 ? (
            <div className="flex flex-wrap gap-2">
              {data?.attachments?.map((file, index) => (
                <a
                  key={index}
                  href={file?.url}
                  target="_blank"
                  rel="noreferrer"
                  className="px-4 py-2 border-gray-600 bg-gray-200 flex items-center gap-1 rounded-lg  text-blue-600 text-sm font-medium not-italic"
                >
                  <ImAttachment />
                  {file?.name}
                </a>
              ))}
            </div>
          ) : (
            <p className="py-1 px-1.5 rounded-lg text-sm font-normal not-italic text-gray-800">
              No attachments
            </p>
          )}
        </div>
        <div className="w-full">
          <label className="text-sm font-normal not-italic">Body</label>
          <p className="prose p-4 border-gray-300 border rounded-lg text-sm font-normal not-italic text-gray-800 min-w-full">
            {parse(getText(data?.body))}
          </p>
        </div>
      </>
    )
  );
};

export default TemplateData;
