import { useContext, useEffect, useState } from "react";
import Description from "./Description";
import JobAnalytics from "./JobAnalytics";
import { useSelector } from "react-redux";
import {
  fetchDescription,
  reparseJobService,
} from "../../../../redux/services";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
import TabLayout from "../../../../layouts/TabLayout";
import { ScaleLoader } from "react-spinners";
import SourceCandidates from "../candidates/SourceCandidates";
import PreProspect from "../candidates/PreProspect";
import { FaSyncAlt } from "react-icons/fa";
import { JobPageContext } from "../../../../lib/context";
import { SlRefresh } from "react-icons/sl";

function JobDescription({ checkResource = false }) {
  const { view, setView, setRefreshButtonClicked } = useContext(JobPageContext);

  const { job, jobId } = useSelector((state) => {
    return {
      job: state?.JobExpandSlice?.description,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  const dispatch = useDispatch();

  const fetchDesc = async () => {
    if (!jobId) {
      return;
    }
    dispatch(fetchDescription(jobId + "?status=active"));
  };

  useEffect(() => {
    fetchDesc();
    if (job?.analytics?.skills?.length > 0 || job?.preferCriteria?.length > 0) {
      setView("all");
    }
  }, [jobId]);

  const [parse, setParse] = useState("Reparse");
  const reParse = async () => {
    dispatch(reparseJobService({ atsJobId: job?.atsJobId }));
    setParse("Parsing...");
  };

  const btnList = [
    {
      name: "All",
      id: "all",
    },
    {
      name: "Details",
      id: "details",
    },
    {
      name: "Analytics",
      id: "analytics",
    },
    {
      name: "AI-Tuned Profiles",
      id: "candidates",
    },
  ];
  return (
    <section
      className={`h-auto  max-h-[calc(100vh-178px)] ${view != "candidates" && "overflow-y-scroll"} scrollbar-hide`}
      id="description"
    >
      {job &&
        (job?.analytics?.skills?.length > 0 ||
          job?.preferCriteria?.length > 0) && (
          <>
            <div className="flex flex-row items-center justify-between">
              <div id="job-tablayout-parent">
                <TabLayout
                  btnList={btnList}
                  className="my-4"
                  state={view}
                  setState={setView}
                />
              </div>

              <div className="flex flex-row justify-end items-center">
                {view == "candidates" ? (
                  <button
                    className="border-button flex gap-2 items-center rounded-lg py-2 px-3 border border-[#DADAE4] bg-white shadow-jobPostButtonShadow"
                    onClick={() => setRefreshButtonClicked((prev) => prev + 1)}
                  >
                    <SlRefresh
                      size="1rem"
                      className="mr-2"
                      style={{
                        strokeWidth: 2,
                      }}
                    />
                    Refresh Profiles
                  </button>
                ) : null}
              </div>
            </div>
          </>
        )}

      {!job ? (
        <div className="flex justify-center h-[calc(100vh-140px)]  items-center">
          <ScaleLoader height={40} color="#1569EB" loading={true} />
        </div>
      ) : (
        // Render your actual content here

        <>
          {view != "candidates" && (
            <div
              className={`${
                view == "all"
                  ? "max-w-full grid grid-cols-12 gap-[60px]"
                  : "max-w-full grid grid-cols-12"
              } bg-white rounded-xl mt-2.5  p-6 `}
            >
              {view != "analytics" && view != "candidates" && (
                <Description
                  classTxt={
                    view == "all"
                      ? "grow shrink col-span-7"
                      : "w-full col-span-12"
                  }
                  job={job}
                />
              )}
              {view != "details" &&
                view != "candidates" &&
                (job?.analytics?.skills?.length > 0 ||
                  job?.preferCriteria?.length > 0) && (
                  <JobAnalytics
                    classTxt={
                      view == "all"
                        ? "analytics-dashboard  col-span-5"
                        : "analytics-dashboard  w-full  gap-y-8 gap-x-4 flex flex-row items-start col-span-12"
                    }
                    onlyAnalytics={view === "analytics"}
                    job={job?.analytics}
                    preferCriteria={job?.preferCriteria}
                  />
                )}
            </div>
          )}

          <div>{view === "candidates" && <PreProspect />}</div>
        </>
      )}
    </section>
  );
}
export default JobDescription;
