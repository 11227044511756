import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  Suspense,
} from "react";
import { useSelector } from "react-redux";
import { CandidatePageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
import InitialsLogo from "../../../all/InitialsLogo";
import { Bot, Pencil } from "lucide-react";
import {
  GoogleMeet,
  ZoomIcon,
  calendar,
  Outlook,
  TeamsLogo,
} from "../icons/Icons";
import { toast } from "react-toastify";
import moment from "moment-timezone";
import "moment-timezone";
import InterviewScheduledCard from "./InterviewScheduledCard";
import SvgIcon2 from "../icons/SvgIcon2";
import TextAreaBox from "../../../../layouts/TextAreaBox";
const core = require("../../../../lib/core");
import DatePicker from "react-datepicker";
import SelectBox from "../../../../layouts/SelectBox";
import "react-datepicker/dist/react-datepicker.css";
import GoogleCalenderView from "./GoogleCalenderView";
import InviteModal from "../../job/assessment/InviteModal";
import AvailabilityModal from "../../job/assessment/AvailabilityModal";
import { data } from "jquery";
import { convertToHTML } from "draft-convert";
import InputBox from "../../../../layouts/InputBox";
import TimezoneSelect from "./TimezoneSelect";
import CustomSelect from "../../../../layouts/CustomSelect";
import ConfirmationModal from "./ConfirmationModal";
import ProtectedComponent from "../../../../layouts/ProtectedComponent";
const ResumeTab = React.lazy(() => import("../Resume"));
import FeedbackIndividual from "./FeedbackIndividual";
import SelectAndSearch from "../../../../layouts/SelectAndSearch";
const now = moment();
const minutes = now.minutes();
const hour = now.hour();
let nearestTimeSlot, endTimeSlot;
if (hour === 23 && minutes >= 30) {
  nearestTimeSlot = "11:30 PM";
  endTimeSlot = "12:00 AM";
} else {
  const nearestMinutes = minutes < 30 ? 30 : 0;
  const nearestHour = minutes < 30 ? hour : hour + 1;
  const formattedHour = nearestHour.toString().padStart(2, "0");
  const formattedMinute = nearestMinutes.toString().padStart(2, "0");
  const ampm = nearestHour < 12 ? "AM" : "PM";
  const formattedHour12 = nearestHour % 12 || 12;
  nearestTimeSlot = `${formattedHour12}:${formattedMinute} ${ampm}`;
  const endTime = moment(nearestTimeSlot, "hh:mm A").add(30, "minutes");
  const formattedEndHour = endTime.format("hh");
  const formattedEndMinute = endTime.format("mm");
  const endAmpm = endTime.format("A");
  endTimeSlot = `${formattedEndHour}:${formattedEndMinute} ${endAmpm}`;
}
const scheduleData = {
  title: "",
  date: new Date(),
  link: "",
  candidate: "",
  recruiter: "",
  nameRecruiter: "",
  time: nearestTimeSlot,
  endtime: endTimeSlot,
  description: "",
  interviewStage: "",
  assistOption: "botAssist",
};
const ToggleButton = ({ options, value, onChange }) => {
  return (
    <div className="flex border border-gray-400 w-fit rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => onChange(option.value)}
          className={`px-2 py-1 text-sm rounded-md ${
            value === option.value
              ? "bg-gray-300 text-gray-900"
              : "text-gray-800"
          } ${index === 0 ? "" : ""} ${index === options.length - 1 ? "" : ""}`}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

const Combobox = ({
  frameworks,
  selectInterview,
  setSelectInterview,
  setSelectedOptions,
  setSkillsWarning,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const wrapperRef = useRef(null);

  const handleInputChange = (e) => {
    setSelectInterview(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option.name);
    setSelectInterview(option.name);
    setSelectedOptions((prev) => {
      const newList = [...option.interviewers];
      // const newList = [...prev, ...option.interviewers]

      return newList;
    });
    setSkillsWarning(option.hasSkills);
    setIsOpen(false);
  };
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative " ref={wrapperRef}>
      <InputBox
        type="text"
        value={selectInterview}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
        placeholder="Select interview stage"
        // className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500 placeholder-gray-600"
      />
      <div
        className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto px-1">
          {frameworks?.length > 0 ? (
            <>
              {" "}
              {frameworks?.map((option) => (
                <li
                  key={option.name}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-300 rounded "
                  onClick={() => handleOptionClick(option)}
                >
                  {option.name}
                </li>
              ))}
            </>
          ) : (
            <>
              <li
                key={""}
                className="px-4 py-2 bg-gray-200 cursor-pointer hover:bg-gray-300 tex-sm"
              >
                Generate Interview Plan...
              </li>
            </>
          )}
        </ul>
      )}
    </div>
  );
};
const ErrorMessage = ({ failedInterviewers, Text, isExternalError }) => {
  if (failedInterviewers.length === 0) return null;

  const uniqueFailedInterviewers = [...new Set(failedInterviewers)];
  const splitInterviewers = uniqueFailedInterviewers.map(
    (email) => email?.split("@")[0],
  );

  const icon = isExternalError ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-red-500 font-semibold mr-3"
      width="20"
      height="20"
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8"
      />
    </svg>
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="text-orange-500 font-semibold mr-3"
      width="15"
      height="15"
      viewBox="0 0 16 16"
    >
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m7.493.015l-.386.04c-1.873.187-3.76 1.153-5.036 2.579C.66 4.211-.057 6.168.009 8.253c.115 3.601 2.59 6.65 6.101 7.518a8.03 8.03 0 0 0 6.117-.98a8 8 0 0 0 3.544-4.904c.172-.701.212-1.058.212-1.887s-.04-1.186-.212-1.887C14.979 2.878 12.315.498 9 .064C8.716.027 7.683-.006 7.493.015m1.36 1.548a6.3 6.3 0 0 1 1.987.597c.698.34 1.18.686 1.747 1.253A6 6 0 0 1 13.84 5.16c.445.915.646 1.798.646 2.84a6.2 6.2 0 0 1-.66 2.867c-.172.351-.519.914-.681 1.105l-.055.065l-4.563-4.564L3.963 2.91l.065-.055c.191-.162.754-.509 1.105-.681a6.44 6.44 0 0 1 3.72-.611M7.48 8.534l4.56 4.561l-.067.053a7.7 7.7 0 0 1-1.106.68a6.8 6.8 0 0 1-1.987.616c-.424.065-1.336.065-1.76 0c-1.948-.296-3.592-1.359-4.627-2.993a7.5 7.5 0 0 1-.634-1.332a6.6 6.6 0 0 1-.189-3.584a6.8 6.8 0 0 1 1.096-2.388c.07-.095.133-.173.141-.173s2.065 2.052 4.573 4.56"
      />
    </svg>
  );

  return (
    <div
      data-tooltip-id="tooltip"
      data-tooltip-content={Text}
      className={`flex items-center ${isExternalError ? "bg-red-50" : "bg-orange-50"} border-l-4 ${isExternalError ? "border-red-500" : "border-orange-500"}  py-2  px-4 rounded-md my-2 w-auto h-auto shadow-md`}
    >
      <div>{icon}</div>
      <span className="block sm:inline font-medium">
        <span className="font-normal">{splitInterviewers.join(", ")}</span>
      </span>
    </div>
  );
};
const CustomCheckbox = ({ id, label, icon, checked, onChange }) => (
  <div
    className={`flex flex-col md:flex-row items-center px-2 py-1 gap-2 rounded-md shadow-md cursor-pointer transition-all duration-300 ${
      checked
        ? "bg-blue-700 text-white"
        : "bg-gray-300 text-gray-800 hover:bg-gray-400"
    }`}
    onClick={() => onChange(id)}
  >
    <div className="flex items-center gap-2">
      <div className="">{icon}</div>
      <p className="cursor-pointer m-0 text-sm font-normal select-none">
        {label}
      </p>
    </div>
  </div>
);

const colorArray = [
  "#61A3BA",
  "#A9A9A9",
  "#0C356A",
  "#04364A",
  "#99A98F",
  "#245953",
  "#8785A2",
  "#8D7B68",
];
const SchedulingInterview = ({ checkResource = false }) => {
  const [availabilities, setAvailabilities] = useState([]);
  const [Candavailabilities, setCandAvailabilities] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [isMeetLinkLoading, setisMeetLoading] = useState(false);
  const [formdetails, setFormdetails] = useState(scheduleData);
  const candidate = useSelector((state) => state?.jobCandidateMatch?.profile);
  console.log(candidate);
  const data = useSelector((state) => console.log(state));
  const [message, setMessage] = useState({});
  const [checked, setChecked] = useState(false);
  const [isZoomLinkLoading, setIsZoomloading] = useState(false);
  const applicant = useSelector((state) => state?.jobCandidateMatch?.profile);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [inputValue, setInputValue] = useState("");
  const [selectedemailOptions, setSelectedOptions] = useState([]);
  const [recommendedEmails, setRecommendedEmails] = useState([]);
  const [timeZone, settimeZone] = useState("");
  const [eventsForSelectedDay, setEventsForSelectedDay] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [InterviewertimeZone, setInterviewerTimeZone] = useState(
    moment.tz.guess(),
  );
  const [candidateMessage, setCandidateMessage] = useState("");
  const [candidateStatus, setCandidateStatus] = useState("Invite not sent");
  const [EventsLoading, setEventsLoading] = useState(false);
  const [isOutLookLoading, setIsOutlookLoading] = useState(false);
  const [teamsMeetId, setteamsMeetId] = useState("");
  const [failedFetchInterviewers, setFailedFetchInterviewers] = useState([]);
  const [service, setService] = useState("");
  const [interviewerMessage, setInterviewerMessage] = useState("");
  const [ZeroEventsInterviewers, setZeroEventsInterviewers] = useState([]);
  const { user } = useContext(core.UserContext);
  const [meetingLinkError, setmeetingLinkError] = useState("");
  const [authmessage, setauthmessage] = useState("");
  const [formValidMsg, setformValidmsg] = useState("");
  // console.log(user);
  const [showInvite, setShowInvite] = useState("");
  const [showSteps, setShowSteps] = useState(false);
  const [CalendarStatus, setCalendarStatus] = useState("");
  const instructionsRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [SkillsWarning, setSkillsWarning] = useState(true);
  const [MeetPlatformStatus, setMeetPlatformStatus] = useState({
    google: false,
    Microsoft: false,
    Zoom: false,
  });
  const EditingdataFormat = {
    title: "",
    date: new Date(),
    link: "",
    candidate: "",
    time: nearestTimeSlot,
    endtime: endTimeSlot,
    description: "",
    interviewStage: "",
    InterviewerEmails: [],
    assistOption: "botAssist",
  };
  const [EditTimezone, setEditTimezone] = useState("");
  const [EditFormdetailsData, setEdtitFormdetails] =
    useState(EditingdataFormat);
  const [isEditing, setIsEditing] = useState(false);
  const [EditMeetingId, setEditMeetingId] = useState("");
  useEffect(() => {
    if (isEditing) {
      setFormdetails((prevState) => ({
        ...prevState,
        title: EditFormdetailsData.title,
        // date: new Date(EditFormdetailsData.date),
        link: EditFormdetailsData.link,
        description: EditFormdetailsData.description,
        time: EditFormdetailsData.time,
        endtime: EditFormdetailsData.endtime,
        interviewStage: EditFormdetailsData.interviewStage,
        assistOption: EditFormdetailsData?.assistOption,
      }));
      setSelectedOptions([...EditFormdetailsData.InterviewerEmails]);
      setInput(
        "candidate",
        applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
      );
      setInput("date", new Date(EditFormdetailsData?.date));
      setEdtitFormdetails(EditingdataFormat);
      setInterviewerTimeZone(EditTimezone);
      console.log("EditTimeZone", EditTimezone, InterviewertimeZone);
    }
  }, [isEditing]);

  const [InterviewerEmails, setInterviewerEmails] = useState([]);
  const { jobId, jobDetails, jobPosition, OrgName } = useSelector((state) => {
    return {
      jobDetails: state?.JobExpandSlice?.assessment,
      jobId: state?.JobExpandSlice?.profile?.id,
      jobPosition: state?.JobExpandSlice?.profile?.position,
      OrgName: state?.JobExpandSlice?.profile?.company?.name,
    };
  });
  const [showConfirmation, setShowConfirmation] = useState("");
  const [pipelineData, setPipelineData] = useState([]);
  const GET_INTERVIEW_PLAN = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_INTERVIEW_PLAN + jobId,
        1,
        {},
      );
      if (json?.data?.plan?.stages) {
        console.log("JSON PLAN", json);
        const stageData = json.data.plan.stages.map((stage) => ({
          name: stage.name,
          interviewers: (stage.interviewers || []).map(
            (interviewer) => interviewer.email,
          ),
          hasSkills: stage.skills.length > 0,
        }));
        setPipelineData(stageData);
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
    }
  };
  useEffect(() => {
    GET_INTERVIEW_PLAN();
  }, []);
  useEffect(() => {
    if (pipelineData.length > 0 && formdetails.interviewStage.length > 0) {
      const matchingStage = pipelineData.find(
        (stage) => stage.name === formdetails.interviewStage,
      );

      if (matchingStage) {
        setSkillsWarning(matchingStage.hasSkills);
      } else {
        setSkillsWarning(true);
      }
    }
  }, [pipelineData]);

  const [Timings, setTimings] = useState({
    StartTime: "9:00 AM",
    endTime: "5:00 PM",
    StartDate: new Date(),
    EndDate: new Date(new Date().setDate(new Date().getDate() + 2)),
  });

  const [selectedEmail, setSelectedEmail] = useState(user.email ?? "");

  const Organization = OrgName
    ? OrgName
    : user?.organization?.name
      ? user.organization.name
      : "Org";
  const InviteTemplate = {
    subject: `Interview Availability for ${jobPosition} at ${Organization}`,
    body: `Dear ${applicant?.matchProfile?.first_name} ,
I hope this email finds you well.

We were impressed by your application for the ${jobPosition}  position at ${Organization} and would like to schedule an interview with you. Could you please provide your availability over the next few days so we can arrange a convenient time for the interview?

{{availability_link}}

We look forward to discussing your qualifications and the opportunity further.

Thank you, and we hope to hear from you soon.

Note: Inteviewer Availability
Days : From ${moment(Timings?.StartDate).format("DD-MMM-YYYY")} to ${moment(Timings?.EndDate).format("DD-MMM-YYYY")}
Timings : ${Timings?.StartTime} to ${Timings?.endTime}

Best regards,
${Organization}

    `,
  };
  const initialTemplate = `Dear {{first_name}},
I hope this email finds you well.

We were impressed by your application for the {{job_name}}  position at {{org_name}} and would like to schedule an interview with you. Could you please provide your availability over the next few days so we can arrange a convenient time for the interview?

{{availability_link}}

We look forward to discussing your qualifications and the opportunity further.

Thank you, and we hope to hear from you soon.

Best regards,
{{org_name}}`;
  const convertTemplateToHTML = (template) => {
    let html = template.replace(/\n/g, "<br>");
    html = `<p>${html}</p>`;

    return html;
  };
  const initialSubject =
    "Interview Availability for {{job_name}} at {{org_name}}";
  const [inviteloading, setInviteLoading] = useState(false);

  const formatDateTime = (date, time) => {
    // Combine date and time
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const formattedTime = moment(time, ["h:mm A"]).format("HH:mm:ss");
    return moment(`${formattedDate} ${formattedTime}`).toISOString();
  };
  const AvailabilityInvite = async () => {
    const startDateTime = formatDateTime(
      Timings?.StartDate,
      Timings?.StartTime,
    );
    const endDateTime = formatDateTime(Timings?.EndDate, Timings?.endTime);

    const bodyContent = convertTemplateToHTML(initialTemplate);
    const requestData = {
      subject: initialSubject,
      body: bodyContent,
      matchProfiles: [applicant?.matchProfile?._id],
      job: jobId,
      org_name: Organization,
      job_name: jobPosition,
      interviewerAvailability: {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        timezone: InterviewertimeZone,
      },
      from: selectedEmail,
    };

    setInviteLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_AVAILABILITY_INVITE,
        1,
        requestData,
      );

      if (response.code == 201) {
        setInviteLoading(false);
        setCandidateStatus("Pending");
        toast.success("Invite Sent Successfully");
      } else {
        toast.error("Error Sending Invite");
        setInviteLoading(false);
      }
    } catch (error) {
      setInviteLoading(false);
      toast.error("Something went wrong!");
    }
  };
  const tooltipRef = useRef(null);

  useEffect(() => {
    function handleClickOutsideInvite(event) {
      if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
        setShowTooltip(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutsideInvite);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideInvite);
    };
  }, [tooltipRef]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        instructionsRef.current &&
        !instructionsRef.current.contains(event.target)
      ) {
        setShowSteps(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    get_user_list();
  }, []);
  const get_user_list = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER_LIST_ORG,
        1,
      );
      if (json?.data) {
        const extractedEmails = json?.data.map((item) => item.email);
        setInterviewerEmails(extractedEmails);
      }
    } catch (error) {}
  };
  const isFormValid = () => {
    return (
      formdetails?.link?.length > 0 &&
      formdetails?.candidate?.length > 0 &&
      formdetails?.title?.length > 0 &&
      formdetails?.date &&
      formdetails?.time?.length > 0 &&
      formdetails?.endtime?.length > 0 &&
      selectedemailOptions?.length > 0 &&
      formdetails.interviewStage.length > 0
    );
  };
  const handleCheckClick = () => {
    if (!isFormValid()) {
      setChecked(false);
      setformValidmsg("Please fill the required fields");
    } else {
      setChecked(!checked);
      setformValidmsg("");
    }
  };
  useEffect(() => {
    // console.log(isFormValid());
    if (!isFormValid()) {
      setChecked(false);
    } else {
      setformValidmsg("");
    }
  }, [formdetails, selectedemailOptions]);
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    // const emailList = [
    //   "Sourav@sproutsai.com",
    //   "Niraj@sproutsai.com",
    //   "Rohit@sproutsai.com",
    // ];
    if (e.target.value.length > 0) {
      const filteredEmails = InterviewerEmails.filter((email) =>
        email.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      if (filteredEmails.length > 0) setRecommendedEmails(filteredEmails);
      else {
        setRecommendedEmails([`${e.target.value}`]);
      }
    } else {
      setRecommendedEmails([]);
      setWarningMessage("");
    }
  };

  const handleOptionClick = (option) => {
    if (option.includes("@") && option.includes(".")) {
      if (option === formdetails.candidate) {
        setWarningMessage("Candidate and interviewer cannot be the same.");
        return;
      }
    } else {
      setWarningMessage("Please enter a valid email");
      setTimeout(() => setWarningMessage(""), 2000);
      return;
    }
    if (!selectedemailOptions.includes(option)) {
      setSelectedOptions([...selectedemailOptions, option]);
      setInputValue("");
      setRecommendedEmails([]);
      setWarningMessage("");
    } else {
      setWarningMessage("Email already added");
      setTimeout(() => setWarningMessage(""), 2000);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (inputValue.includes("@") && inputValue.includes(".")) {
        handleOptionClick(inputValue);
      } else {
        setWarningMessage("Please enter a valid email");
        setTimeout(() => setWarningMessage(""), 2000);
      }
    }
  };
  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedemailOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
    if (updatedOptions.length === 0) {
      setFailedFetchInterviewers([]);
      setZeroEventsInterviewers([]);
      setEventsForSelectedDay([]);
      setauthmessage("");
    }
    setChecked((prev) => (isFormValid() ? prev : false));
  };

  useEffect(() => {
    fetchAvailability();
  }, [InterviewertimeZone]);
  const fetchAvailability = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_CAN_AVAILABILITY + applicant?.matchProfile?._id,
        1,
      );
      if (json?.data) {
        // console.log("json data", json.data);
        settimeZone(json?.data?.timezone);
        // console.log(timeZone);

        const avail = json?.data.availabilityData;

        setCandAvailabilities(avail);
        if (avail.length === 0) {
          setCandidateMessage("Availability expired invite again");
        }
        const formattedData = await avail?.flatMap((item) => {
          return item.slots.map((slot) => {
            return {
              title: "Candidate Availability",
              start: new Date(
                moment
                  .utc(slot.start_time)
                  .tz(InterviewertimeZone)
                  .format("M/D/YYYY, h:mm:ss A"),
              ),
              end: new Date(
                moment
                  .utc(slot.end_time)
                  .tz(InterviewertimeZone)
                  .format("M/D/YYYY, h:mm:ss A"),
              ),
              colorEvento: "orange",
              color: "white",
              interviewer: json?.data?.email,
              originalTimezone: json?.data?.timezone,
            };
          });
        });

        // setEventsForSelectedDay([...formattedData]);
        // console.log("Formatted Avail", formattedData)
        setAvailabilities(formattedData);
        setIsloading(false);
      } else {
        setIsloading(false);
        setCandidateMessage(
          "No availabilities found. Send an invite to request the candidate's availability.",
        );
        console.error("Error fetching data:", json?.message);
      }
    } catch (error) {
      console.error(error);
      setIsloading(false);
    }
  };

  const handleGetOutlookLink = async () => {
    setIsOutlookLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_TEAMS_LINK + user.email,
        1,
      );
      // console.log("TEAMS", response);
      if (response?.data) {
        setInput("link", response?.data?.link?.join_link);
        setteamsMeetId(response?.data?.link?.meet_id);
      } else {
        setmeetingLinkError(response?.message);
        setTimeout(() => setmeetingLinkError(""), 2000);
        console.log("error fetching outlook meet link", response);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsOutlookLoading(false);
    }
  };
  const getOutlookCalenderStatus = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_OUTLOOK_AUTH_STATUS +
          `${user.email}&service=microsoft`,
        1,
        {},
      );
      if (json?.data) {
        if (json.data.status) {
          setService("Microsoft");
          setCalendarStatus("Microsoft");
          setMeetPlatformStatus((prev) => ({ ...prev, ["Microsoft"]: true }));
        }
        return json.data.status;
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getGooglecalenderStatus = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-meet`,
        1,
        {},
      );
      if (response?.data) {
        if (response.data.status) {
          setService("google");
          setMeetPlatformStatus((prev) => ({ ...prev, ["google"]: true }));
        }
        return response.data.status;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };
  const getCalStatus = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS +
          `${user.email}&service=google-calendar`,
        1,
        {},
      );
      if (response?.data) {
        if (response.data.status) {
          setCalendarStatus("google");
        }
        return response.data.status;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };

  const getStatus = async (service) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_AUTH_STATUS + `${user.email}&service=zoom`,
        1,
        {},
      );
      if (response?.data) {
        if (response.data.status) {
          setService("zoom");
          setMeetPlatformStatus((prev) => ({ ...prev, ["Zoom"]: true }));
        }
        return response.data.status;
      }
    } catch (error) {
      console.log("error", error);
      return false;
    }
  };
  useEffect(() => {
    getOutlookCalenderStatus();
    getGooglecalenderStatus();
    getStatus();
    getCalStatus();
  }, []);
  const handleGetMeetLink = async () => {
    setisMeetLoading(true);
    const res = await getGooglecalenderStatus();
    if (!res) {
      setmeetingLinkError("Authorize google calender and google meet");
      setTimeout(() => setmeetingLinkError(""), 2000);
      setisMeetLoading(false);
      return;
    }
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_MEET_LINK +
          // user?.email,
          user.email,
        1,
      );
      if (json?.data) {
        setInput("link", json?.data?.link);
        setisMeetLoading(false);
        setteamsMeetId("");
      } else {
        setmeetingLinkError(json?.message);
        setTimeout(() => setmeetingLinkError(""), 2000);
        setisMeetLoading(false);
        console.error("Error fetching data:", json?.message);
      }
    } catch (error) {
      console.error(error);
      setisMeetLoading(false);
    }
  };

  const handleGetZoomLink = async () => {
    setIsZoomloading(true);
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.SCHEDULING_API.GET_ZOOM_LINK + user.email,
        1,
      );

      if (response?.data) {
        setInput("link", response.data.link);
        setIsZoomloading(false);
      } else if (response?.code == 401) {
        setmeetingLinkError(response?.message);
        setTimeout(() => setmeetingLinkError(""), 2000);
        setIsZoomloading(false);
        console.log("error fetching the Zoom Link");
      }
    } catch (error) {
      setIsZoomloading(false);
      setmeetingLinkError("Internal error");
      setTimeout(() => setmeetingLinkError(""), 2000);
      console.log("Error", error);
    }
  };
  useEffect(() => {
    setFailedFetchInterviewers([]);
    setZeroEventsInterviewers([]);
  }, [selectedemailOptions]);
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(";").shift();
    }
    return null;
  }
  const getRandomColor = (event) => {
    if (!formdetails.date || !formdetails.time || !formdetails.endtime) {
      return "#4CAF50";
    }

    const formStartTime = moment(
      `${formdetails.date} ${formdetails.time}`,
      "YYYY-MM-DD hh:mm A",
    );
    const formEndTime = moment(
      `${formdetails.date} ${formdetails.endtime}`,
      "YYYY-MM-DD hh:mm A",
    );
    const eventStartTime = moment(event.startTime);
    const eventEndTime = moment(event.endTime);

    if (
      isTimeConflict(formStartTime, formEndTime, eventStartTime, eventEndTime)
    ) {
      return "#F44336";
    } else {
      return "#4CAF50";
    }
  };
  const isTimeConflict = (start1, end1, start2, end2) => {
    return start1 < end2 && end1 > start2;
  };

  const [loading, setLoading] = useState(false);
  // const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const { setShowModal, setReload } = useContext(CandidatePageContext);
  const [showScheduleForm, setShowScheduleForm] = useState(false);

  const handleEditing = async () => {
    const bodyData = {
      matchProfile: candidate?.matchProfile?._id,
      title: formdetails.title,
      meeting_link: formdetails.link,
      start_time: moment
        .tz(
          `${moment(formdetails.date).format("YYYY-MM-DD")} ${formdetails.time}`,
          "YYYY-MM-DD h:mm A",
          InterviewertimeZone,
        )
        .utc()
        .toDate(),
      end_time: moment
        .tz(
          `${moment(formdetails.date).format("YYYY-MM-DD")} ${formdetails.endtime}`,
          "YYYY-MM-DD h:mm A",
          InterviewertimeZone,
        )
        .utc()
        .toDate(),
      // interviewer: ["65ce07f3edc4533e64f7ddee"],
      guest: selectedemailOptions,
      job: jobId,
      description: formdetails?.description,
      service:
        CalendarStatus === "Microsoft"
          ? "teams"
          : CalendarStatus == "google"
            ? "google-calendar"
            : CalendarStatus == "zoom"
              ? "google-calendar"
              : "",
      candidate_email: formdetails?.candidate,
      timezone: InterviewertimeZone,
      candidate_timezone: timeZone,
      stage: formdetails?.interviewStage,
      status: "Active",
      assistant_required:
        formdetails?.assistOption === "botAssist" ? true : false,
    };
    if (teamsMeetId.length > 0) {
      bodyData.teams_id = teamsMeetId;
    }

    if (EditMeetingId === "") {
      console.log("Meeting ID Error");
      toast.error("Meeting Id not found");
      return;
    }

    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.SCHEDULING_API.RESCHEDULE_MEETING +
          "/" +
          EditMeetingId +
          "/reschedule",
        1,
        bodyData,
      );

      if (json?.code == 200) {
        toast.success("Meeting Details Saved");
        setEdtitFormdetails(EditingdataFormat);
        setIsEditing(false);
        setEditMeetingId("");
        setLoading(false);
        setWarningMessage("");
        setauthmessage("");
        setSkillsWarning(true);
        setTimeWarning("");
        getInterviewData();
        localStorage.removeItem(
          `meetingFormData+${applicant?.matchProfile?._id}`,
        );
        setFormdetails(scheduleData);
        setInput(
          "candidate",
          applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
        );
      } else {
        toast.error(json?.message);
        setLoading(false);
      }
    } catch (error) {
      console.log("Unable to Reschedule!", error);
    }
  };
  const [CancelLoading, setCancelLoading] = useState(false);
  const handleCancelMeeting = async () => {
    setCancelLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.CANCEL_MEETING + "/" + EditMeetingId + "/cancel",
        1,
      );

      if (json?.code == 200) {
        setEdtitFormdetails(EditFormdetailsData);
        setEditMeetingId("");
        setIsEditing(false);
        setFormdetails(scheduleData);
        setShowConfirmation("");
        setSkillsWarning(true);
        setTimeWarning("");
        localStorage.removeItem(
          `meetingFormData+${applicant?.matchProfile?._id}`,
        );
        getInterviewData();
        toast.success("Event Canceled Successfully");
      } else {
        toast.error("Error occured");
      }
    } catch (error) {
      console.log("Unable to cancel event", error);
    } finally {
      setCancelLoading(false);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (isEditing) {
      handleEditing();
      return;
    }
    const bodyData = {
      matchProfile: candidate?.matchProfile?._id,
      title: formdetails.title,
      meeting_link: formdetails.link,
      start_time: moment
        .tz(
          `${moment(formdetails.date).format("YYYY-MM-DD")} ${formdetails.time}`,
          "YYYY-MM-DD h:mm A",
          InterviewertimeZone,
        )
        .utc()
        .toDate(),
      end_time: moment
        .tz(
          `${moment(formdetails.date).format("YYYY-MM-DD")} ${formdetails.endtime}`,
          "YYYY-MM-DD h:mm A",
          InterviewertimeZone,
        )
        .utc()
        .toDate(),
      // interviewer: ["65ce07f3edc4533e64f7ddee"],
      guest: selectedemailOptions,
      job: jobId,
      description: formdetails?.description,
      service:
        CalendarStatus === "Microsoft"
          ? "teams"
          : CalendarStatus == "google"
            ? "google-calendar"
            : CalendarStatus == "zoom"
              ? "google-calendar"
              : "",
      candidate_email: formdetails?.candidate,
      timezone: InterviewertimeZone,
      candidate_timezone: timeZone,
      stage: formdetails?.interviewStage,
      status: "Active",
      contact_email: selectedEmail,
      assistant_required:
        formdetails?.assistOption === "botAssist" ? true : false,
    };
    if (teamsMeetId.length > 0) {
      bodyData.teams_id = teamsMeetId;
    }

    const accessToken = getCookie("access_token");
    // console.log("Here In SUbmit", accessToken);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_SCHEDULE_INTERVIEW,
        1,
        bodyData,
      );
      if (json?.code == 201) {
        setFormdetails(scheduleData);
        setSelectedOptions([]);
        setInput(
          "candidate",
          applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
        );
        setWarningMessage("");
        setauthmessage("");
        setSkillsWarning(true);
        toast.success("Meeting scheduled successfully");
        localStorage.removeItem(
          `meetingFormData+${applicant?.matchProfile?._id}`,
        );
        getInterviewData();
        if (edit)
          setMessage({
            type: "success",
            message: "Meeting re-scheduled successfully",
          });
        else
          setMessage({
            type: "success",
            message: "Meeting scheduled successfully",
          });
        setReload((prev) => prev + 1);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("An Error occurred");
        setMessage({
          type: "error",
          message: json?.error?.message,
        });
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      setMessage({
        type: "error",
        message:
          "An error occurred while scheduling the interview. Please try again.",
      });
    } finally {
      setChecked(false);
      setTimeWarning("");
    }
  };

  const handleAssistOptionChange = (option) => {
    setFormdetails((prev) => ({
      ...prev,
      assistOption: option,
    }));
  };

  const setInput = async (key, value) => {
    if (key === "date") {
      setFormdetails((prevState) => ({ ...prevState, [key]: value }));
      setSelectedDate(value);
      saveFormToLocalStorage(formdetails);
      // console.log("Date Changes", value);
    } else if (key === "time") {
      setFormdetails((prevState) => {
        const newState = { ...prevState, [key]: value };
        if (value >= prevState.endtime) {
          newState.endtime = "";
        }
        return newState;
      });
      saveFormToLocalStorage(formdetails);
    } else {
      setFormdetails((prevState) => {
        const newState = { ...prevState, [key]: value };
        saveFormToLocalStorage(newState);
        return newState;
      });
    }
  };

  // console.log(applicant);
  useEffect(() => {
    // setInput("date", new Date());
    setSelectedDate(new Date());
  }, []);

  useEffect(() => {
    setZeroEventsInterviewers([]);
    setFailedFetchInterviewers([]);
  }, [selectedDate]);
  console.log("applicant", applicant);
  useEffect(() => {
    setInput(
      "candidate",
      applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
    );
    setCandidateStatus(applicant?.matchProfile?.availablity_status);
  }, [applicant]);

  useEffect(() => {
    if (selectedDate && selectedemailOptions.length > 0) {
      const fetchData = async () => {
        const availability = await fetchInterviewerAvailability(selectedDate);
        setEventsForSelectedDay(availability);
      };
      fetchData();
      if (selectedemailOptions.length === 0) {
        setFailedFetchInterviewers([]);
        setauthmessage("");
      }
    }
  }, [selectedDate, selectedemailOptions, InterviewertimeZone]);

  function stringToColor(string) {
    if (!colorArray || colorArray.length === 0) {
      return null; // Handle the case when the colorArray is empty or undefined
    }

    let hash = 0;
    for (let i = 0; i < string?.length; i += 1) {
      hash = string?.charCodeAt(i) + ((hash << 5) - hash);
    }

    const index = Math.abs(hash) % colorArray.length; // Calculate the index based on the hash
    return colorArray[index];
  }
  const handleCancel = () => {
    setFormdetails(scheduleData);
    setInput("date", new Date());
    setInput(
      "candidate",
      applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
    );
    setSelectedOptions([]);
    setauthmessage("");
    setWarningMessage("");
    setformValidmsg("");
    setmeetingLinkError("");
    setShowScheduleForm(false);
    setEdtitFormdetails(EditingdataFormat);
    setIsEditing(false);
    setEditMeetingId("");
    showConfirmation("");
    setShowInvite("");
    setSkillsWarning(true);
    setTimeWarning("");
    localStorage.removeItem(`meetingFormData+${applicant?.matchProfile?._id}`);
  };
  useEffect(() => {
    console.log("formdetails", formdetails);
  }, [formdetails]);

  const fetchInterviewerAvailability = async (date) => {
    setEventsLoading(true);
    setFailedFetchInterviewers([]);
    setZeroEventsInterviewers([]);
    try {
      const allInterviewerEvents = await Promise.all(
        selectedemailOptions.map(async (interviewerEmail) => {
          try {
            const userDomain = user.email?.split("@")[1];
            const interviewerDomain = interviewerEmail?.split("@")[1];

            if (userDomain !== interviewerDomain) {
              setFailedFetchInterviewers((prev) => [...prev, interviewerEmail]);
              return [];
            }
            const requestBody = {
              coordinatorEmail: user.email,
              interviewerEmail: interviewerEmail,
              timezone: InterviewertimeZone,
              date: date.toISOString().split("T")[0],
              service: CalendarStatus == "Microsoft" ? "Microsoft" : "google",
            };

            const response = await core.API(
              core.API_METHODS.POST,
              core.SCHEDULING_API.GET_CALENDER_EVENTS,
              1,
              requestBody,
            );

            if (response?.data && response?.data.length > 0) {
              setZeroEventsInterviewers((prev) =>
                prev.filter((email) => email !== interviewerEmail),
              );
              setFailedFetchInterviewers((prev) =>
                prev.filter((email) => email !== interviewerEmail),
              );
              return response.data.map((event) => ({
                title: event?.summary,
                start: new Date(event.startTime),
                end: new Date(event.endTime),
                colorEvento: stringToColor(interviewerEmail),
                color: "white",
                interviewer: interviewerEmail,
              }));
            } else if (response?.data.length == 0) {
              setZeroEventsInterviewers((prev) => [...prev, interviewerEmail]);
              return [];
            }

            setFailedFetchInterviewers((prev) => [...prev, interviewerEmail]);
            setauthmessage("");
            return [];
          } catch (error) {
            setauthmessage("Authenticate calendar to fetch events");
            // setTimeout(() => setWarningMessage(""), 2000);
            console.error(
              `Error fetching events for ${interviewerEmail}:`,
              error,
            );
            return [];
          }
        }),
      );

      const Events = allInterviewerEvents.flat();
      setEventsLoading(false);
      return Events;
    } catch (error) {
      console.error("Error in fetchInterviewerAvailability:", error);
      setEventsLoading(false);
      if (error.message.includes("401")) {
        toast.error(`User unauthorized !`);
      } else {
        toast.error(
          `Failed to fetch events for ${interviewerEmail}. Please try again.`,
        );
      }
      return [];
    } finally {
      setEventsLoading(false);
    }
  };
  // const eventsForSelectedDay = selectedemailOptions.flatMap((email) => {
  //   return (interviewerMeetings[email] || []).filter((event) => {
  //     const eventDate = new Date(event.start);
  //     const selectedDateWithoutTime = new Date(
  //       selectedDate.getFullYear(),
  //       selectedDate.getMonth(),
  //       selectedDate.getDate()
  //     );

  //     return (
  //       eventDate.getFullYear() === selectedDateWithoutTime.getFullYear() &&
  //       eventDate.getMonth() === selectedDateWithoutTime.getMonth() &&
  //       eventDate.getDate() === selectedDateWithoutTime.getDate()
  //     );
  //   });
  // });

  // function setMeetingTime(timeSlot) {
  //   console.log(timeSlot);

  //   const startTime = moment(timeSlot.start_time).format("hh:mm A");
  //   const endTime = moment(timeSlot.end_time).format("hh:mm A");
  //   const date = moment(timeSlot.start_time).format("YYYY-MM-DD");

  //   setInput("date", date);
  //   setInput("time", startTime);Fe
  //   setInput("endtime", endTime);
  // }

  const [scheduledTab, setScheduledTab] = useState(<></>);
  const { reload } = useContext(CandidatePageContext);

  const getInterviewData = async () => {
    // let json = await core.API(
    //   core.API_METHODS.POST,
    //   core.RECRUITER_API.GET_INTERVIEWS,
    //   1,
    //   {
    //     match_profile_id: candidate?.matchProfile?._id,
    //   },
    // );

    try {
      const requestBody = {
        match_profile_id: candidate?.matchProfile?._id,
        status: "active", // Add the Get Active Events
      };
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.GET_INTERVIEWS,
        1,
        requestBody,
      );

      if (json?.data) {
        if (json?.code === 200 && Array.isArray(json?.data)) {
          setScheduledTab(
            <InterviewScheduledCard
              // dataList={json.data.filter((iter) => iter?.status === "active")}
              setEdtitFormdetails={setEdtitFormdetails}
              setInterviewerTimeZone={setEditTimezone}
              setShowScheduleForm={setShowScheduleForm}
              setIsEditing={setIsEditing}
              setEditMeetingId={setEditMeetingId}
              dataList={json.data}
              matchProfileId={candidate?.matchProfile?._id}
              checkResource={checkResource}
              setSelectedEmail={setSelectedEmail}
            />,
          );
          setLoading(false);
          setShowScheduleForm(false);
        }
      } else {
        console.error("Error fetching interview data:", json.message);
      }
    } catch (error) {
      console.error("Error fetching interview data:", error);
    }
  };

  const [tabName, setTabName] = useState("interview");
  useEffect(() => {
    getInterviewData();
  }, [tabName, reload, candidate?.matchProfile?._id]);
  const [TimeWarning, setTimeWarning] = useState("");
  useEffect(() => {
    const checkIsTimepassed = () => {
      const now = moment();
      const selectedDateTime = moment(formdetails?.date).set({
        hour: moment(formdetails.time, "hh:mm A").hour(),
        minute: moment(formdetails.time, "hh:mm A").minute(),
      });

      if (selectedDateTime.isBefore(now)) {
        setTimeWarning("Selected start time has already passed");
      } else {
        setTimeWarning("");
      }
    };

    checkIsTimepassed();
  }, [formdetails?.date, formdetails?.time]);

  //time related functions
  const generateTimeOptions = (is24HourFormat) => {
    return Array.from({ length: 96 }, (_, index) => {
      const hour = Math.floor(index / 4);
      const minute = (index % 4) * 15;
      const formattedMinute = minute.toString().padStart(2, "0");

      if (is24HourFormat) {
        // 24-hour format: 00:00 to 23:45
        const formattedHour = hour.toString().padStart(2, "0");
        return {
          value: `${formattedHour}:${formattedMinute}`,
          label: `${formattedHour}:${formattedMinute}`,
        };
      } else {
        // 12-hour format: 12:00 AM to 11:45 PM
        const adjustedHour = hour % 12 || 12;
        const ampm = hour < 12 ? "AM" : "PM";
        return {
          value: `${adjustedHour}:${formattedMinute} ${ampm}`,
          label: `${adjustedHour}:${formattedMinute} ${ampm}`,
        };
      }
    });
  };
  const [is24HourFormat, setIs24HourFormat] = useState(false);
  const [timeOptions, setTimeOptions] = useState(
    generateTimeOptions(is24HourFormat),
  );

  const convertTo24HourFormat = (time12h) => {
    if (!time12h.includes("AM") && !time12h.includes("PM")) {
      return time12h; // Already in 24-hour format
    }

    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${String(hours).padStart(2, "0")}:${minutes}`;
  };

  const convertTo12HourFormat = (time24h) => {
    if (time24h.includes("AM") || time24h.includes("PM")) {
      return time24h; // Already in 12-hour format
    }

    let [hours, minutes] = time24h.split(":");
    const suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert "00" to "12"
    return `${hours}:${minutes} ${suffix}`;
  };

  // const timeOptions = Array.from({ length: 96 }, (_, index) => {
  //   const hour = Math.floor(index / 4);
  //   const minute = (index % 4) * 15;
  //   const formattedHour = hour.toString().padStart(2, "0");
  //   const formattedMinute = minute.toString().padStart(2, "0");
  //   const ampm = hour < 12 ? "AM" : "PM";
  //   const formattedHour12 = hour % 12 || 12;

  //   const time = `${formattedHour12}:${formattedMinute} ${ampm}`;
  //   return {
  //     value: time,
  //     label: time,
  //   };
  // });
  const getFilteredTimeOptions = (startTime, isStartTimeSelected) => {
    const formatTime = (time) => {
      return is24HourFormat
        ? convertTo24HourFormat(time)
        : convertTo12HourFormat(time);
    };

    const parseTime = (time) => {
      return moment(time, is24HourFormat ? "HH:mm" : "hh:mm A");
    };

    if (!isStartTimeSelected) {
      return timeOptions;
    } else {
      const formattedStartTime = formatTime(startTime);
      const startTimeMoment = parseTime(formattedStartTime);

      return timeOptions.filter((option) => {
        const optionTimeMoment = parseTime(option.value);
        return optionTimeMoment.isAfter(startTimeMoment);
      });
    }
  };

  const handleTimeFormatToggle = (value) => {
    setIs24HourFormat(value);

    const convertedStartTime = formdetails.time
      ? value
        ? convertTo24HourFormat(formdetails.time)
        : convertTo12HourFormat(formdetails.time)
      : "";

    const convertedEndTime = formdetails.endtime
      ? value
        ? convertTo24HourFormat(formdetails.endtime)
        : convertTo12HourFormat(formdetails.endtime)
      : "";

    setInput("time", convertedStartTime);
    setInput("endtime", convertedEndTime);
    setTimeOptions(generateTimeOptions(value));
  };

  const handleTimeChange = (time, isStartTime, isCandidateTime = false) => {
    const formattedTime = is24HourFormat
      ? convertTo24HourFormat(time)
      : convertTo12HourFormat(time);

    if (isCandidateTime) {
      if (isStartTime) setInput("time", formattedTime);
      else {
        setInput("endtime", formattedTime);
      }
      return;
    }
    if (isStartTime) {
      setInput("time", formattedTime);
      const endTimeMoment = moment(
        formdetails.endtime,
        is24HourFormat ? "HH:mm" : "hh:mm A",
      );
      if (
        formdetails.endtime &&
        (moment(formattedTime, is24HourFormat ? "HH:mm" : "hh:mm A").isAfter(
          endTimeMoment,
        ) ||
          (moment(formattedTime, "HH:mm").isSameOrAfter(
            "11:59 PM",
            "hh:mm A",
          ) &&
            endTimeMoment.isBefore("12:00 AM", "hh:mm A")))
      ) {
        setInput("endtime", "");
      } else {
        setMessage({});
      }
    } else {
      const startTimeMoment = moment(
        formdetails.time,
        is24HourFormat ? "HH:mm" : "hh:mm A",
      );
      const endTimeMoment = moment(
        formattedTime,
        is24HourFormat ? "HH:mm" : "hh:mm A",
      );
      if (startTimeMoment.isSame(endTimeMoment)) {
        setInput("endtime", "");
        return;
      }
      if (startTimeMoment.isSameOrAfter("11:45 PM", "hh:mm A")) {
        if (
          endTimeMoment.isBefore(startTimeMoment) ||
          endTimeMoment.isAfter("11:59 PM", "hh:mm A")
        ) {
          setInput("endtime", "");
        } else {
          setInput("endtime", formattedTime);
        }
      } else {
        if (formdetails.time && endTimeMoment.isBefore(startTimeMoment)) {
          setInput("endtime", "");
        } else {
          setInput("endtime", formattedTime);
        }
      }
    }
  };
  const eventsByInterviewer = eventsForSelectedDay.reduce((acc, event) => {
    const interviewer = event.interviewer || "Unknown"; // Assume each event has an interviewer field
    if (!acc[interviewer]) {
      acc[interviewer] = [];
    }
    acc[interviewer].push(event);
    return acc;
  }, {});

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (interviewer) => {
    setOpenDropdown(openDropdown === interviewer ? null : interviewer);
  };

  function convertToTimeZone(date, timeZone) {
    return new Date(
      new Intl.DateTimeFormat("en-US", {
        timeZone,
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      }).format(date),
    );
  }

  // useEffect(() => {
  //   setAvailabilities(
  //     availabilities.map((item) => {
  //       return {
  //         ...item,
  //         start: convertToTimeZone(item.start, InterviewertimeZone),
  //         end: convertToTimeZone(item.end, InterviewertimeZone),
  //       };
  //     }),
  //   );
  // }, [InterviewertimeZone]);

  // console.log(availabilities, InterviewertimeZone);
  const saveFormToLocalStorage = (formData) => {
    localStorage.setItem(
      `meetingFormData+${applicant?.matchProfile?._id}`,
      JSON.stringify(formData),
    );
  };
  const loadFormFromLocalStorage = () => {
    const savedData = localStorage.getItem(
      `meetingFormData+${applicant?.matchProfile?._id}`,
    );
    if (savedData) {
      return JSON.parse(savedData);
    }
    return null;
  };
  useEffect(() => {
    const savedFormData = loadFormFromLocalStorage();
    if (savedFormData) {
      setFormdetails(savedFormData);
      if (savedFormData.date) {
        setInput("date", new Date(savedFormData.date));
      }
      handleTimeFormatToggle(is24HourFormat);
      setInput(
        "candidate",
        applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
      );
    } else {
      setFormdetails(scheduleData);
      setInput(
        "candidate",
        applicant?.applicant?.email[0] ?? applicant?.applicant?.work_email[0],
      );
      setInput("date", new Date());
    }
  }, []);
  const [currentTab, setCurrentTab] = useState("");
  const handleSideButtonClick = (tabName) => {
    if (currentTab === tabName) {
      setCurrentTab("");
    } else {
      setCurrentTab(tabName);
    }
  };

  const renderSplitComponent = () => {
    switch (currentTab) {
      case "Resume":
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ResumeTab />
          </Suspense>
        );
      case "Feedback":
        return (
          <FeedbackIndividual
            appId={applicant?.matchProfile?._id}
            jobId={jobId}
            checkResource={checkResource}
          />
        );
      default:
        return null;
    }
  };
  return (
    <div className="bg-defaultBg p-4 h-full overflow-y-auto">
      <div className="relative w-0 h-0 flex justify-end">
        <div className="fixed top-1/6 -translate-y-1/2 z-[400] flex flex-col justify-start gap-4 mr-6">
          <button
            onClick={() => handleSideButtonClick("Resume")}
            type="button"
            className={`rounded-full p-4 bg-white hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg ${currentTab !== "Resume" ? "text-gray-800" : "text-blue"} `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m8 18v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1zm-4-8a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
              />
            </svg>
          </button>
          <button
            onClick={() => handleSideButtonClick("Feedback")}
            type="button"
            className={`rounded-full p-4 bg-white ${currentTab !== "Feedback" ? "text-gray-800" : "text-blue"} hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M9 22c-.6 0-1-.4-1-1v-3H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2h-6.1l-3.7 3.7c-.2.2-.4.3-.7.3zm1-6v3.1l3.1-3.1H20V4H4v12zm6.3-10l-1.4 3H17v4h-4V8.8L14.3 6zm-6 0L8.9 9H11v4H7V8.8L8.3 6z"
              />
            </svg>
          </button>
        </div>
      </div>
      <div className="flex flex-row gap-2 justify-start transition-all duration-300 ease-in-out">
        <div
          className={`${currentTab.length > 0 ? "w-1/2" : "w-full"} transition-all duration-300 ease-in-out`}
        >
          {!showScheduleForm && (
            <ProtectedComponent
              showModal={true}
              code={checkResource ? "Interview_create" : "no_code"}
            >
              <button
                className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                onClick={() => {
                  setShowScheduleForm(true);
                  setCurrentTab("");
                }}
              >
                {/* <SvgIcon2 className="w-5 h-5 text-[#252e4b] flex" /> */}
                <span>+ Schedule Interview</span>
              </button>
            </ProtectedComponent>
          )}

          {!showScheduleForm && scheduledTab && (
            <>
              <h2 className="text-gray-700 text-sm not-italic font-normal mt-4">
                Scheduled Interviews
              </h2>
              <div className="w-full  h-screen overflow-y-auto rounded-lg border-b border-gray-100  mb-4 mt-2">
                {scheduledTab}
              </div>
            </>
          )}
        </div>
        {currentTab && !showScheduleForm && (
          <div className="w-1/2 h-screen bg-white rounded-md overflow-y-auto border-l border-gray-200 transition-all duration-300 ease-in-out">
            <div className="p-4">{renderSplitComponent()}</div>
          </div>
        )}
      </div>
      {showScheduleForm && (
        <div className="flex flex-row gap-4 relative">
          <div className="w-[20%] px-4 py-4 bg-white rounded-lg">
            <h2 className="text-gray-800 text-sm font-medium not-italic mb-2 ">
              Candidate availability
            </h2>
            <div className="flex items-center gap-4">
              {candidateStatus?.length > 0 ? (
                <span
                  className={`text-sm font-medium px-2 py-1 my-2 rounded-full ${
                    candidateStatus === "Completed"
                      ? "bg-green-100 text-green-800"
                      : "bg-yellow-100 text-yellow-800"
                  }`}
                >
                  {candidateStatus}
                </span>
              ) : null}
            </div>
            <div>
              {!user?.userIntegrations?.refresh_token &&
                user?.email == selectedEmail && (
                  <span className="text-red-500 text-xs font-normal">
                    {`Verify email to send invite.`}
                  </span>
                )}
            </div>
            <div className="flex items-center justify-start mt-2 gap-4">
              <button
                className="flex gap-2 items-center rounded-lg py-1 px-3 border border-[#DADAE4] btn-primary shadow-jobPostButtonShadow"
                onClick={() => {
                  AvailabilityInvite();
                }}
                disabled={!user?.userIntegrations?.refresh_token}
              >
                {inviteloading ? (
                  <div className="flex justify-center items-center">
                    <ScaleLoader height={12} color="#FFF" loading={true} />
                  </div>
                ) : (
                  <>Request availability</>
                )}
              </button>
              <div ref={tooltipRef}>
                <button
                  className="w-6 h-6 flex items-center justify-center rounded-full text-gray-200 relative overflow-hidden"
                  onClick={() => setShowTooltip(!showTooltip)}
                >
                  <div
                    className={`transition-transform duration-300 ease-in-out text-gray-600 ${showTooltip ? "translate-y-full" : ""}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 48 48"
                      className="w-6 h-6"
                    >
                      <path
                        fill="none"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="4"
                        d="M9.858 18C6.238 21 4 24 4 24s8.954 12 20 12c1.37 0 2.708-.185 4-.508M20.032 12.5c1.282-.318 2.61-.5 3.968-.5c11.046 0 20 12 20 12s-2.239 3-5.858 6m-17.828-9.379a5 5 0 0 0 7.186 6.95M42 42L6 6"
                      />
                    </svg>
                  </div>
                  <div
                    className={`absolute top-0 left-0 text-gray-600 transition-transform duration-300 ease-in-out ${showTooltip ? "" : "-translate-y-full"}`}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="2em"
                      height="2em"
                      viewBox="0 0 48 48"
                      className="w-6 h-6"
                    >
                      <g
                        fill="none"
                        stroke="currentColor"
                        strokeLinejoin="round"
                        strokeWidth="4"
                      >
                        <path d="M24 36c11.046 0 20-12 20-12s-8.954-12-20-12S4 24 4 24s8.954 12 20 12Z" />
                        <path d="M24 29a5 5 0 1 0 0-10a5 5 0 0 0 0 10Z" />
                      </g>
                    </svg>
                  </div>
                </button>

                {showTooltip && (
                  <div className="absolute z-10 w-[60%] top-[-20px] left-[200px] p-4 mt-2 bg-white rounded-lg shadow-lg border border-gray-200">
                    <div className="flex items-center justify-between my-2">
                      <p> {InviteTemplate.subject}</p>
                      <button
                        onClick={() => {
                          setShowTooltip(!showTooltip);
                          setShowInvite("InviteCandidate");
                        }}
                        className="px-3 py-1 bg-gray-400 text-gray-800 rounded-lg hover:bg-gray-500 hover:text-gray-900"
                      >
                        Edit
                      </button>
                    </div>
                    <p>
                      <pre className="whitespace-pre-wrap text-sm">
                        {InviteTemplate.body}
                      </pre>
                    </p>
                  </div>
                )}
              </div>
              {showInvite === "InviteCandidate" ? (
                <>
                  <AvailabilityModal
                    modalDismiss={() => setShowInvite("")}
                    matchprofileIds={[applicant?.matchProfile?._id]}
                    Timings={Timings}
                    setTimings={setTimings}
                    InterviewertimeZone={InterviewertimeZone}
                    selectedEmail={selectedEmail}
                    setSelectedEmail={setSelectedEmail}
                  />
                </>
              ) : (
                <></>
              )}
            </div>

            {/* <h6 className={timeZone === "" ? "hidden" : " text-sm py-2 "}>
              Candidate Timezone : {timeZone}{" "}
            </h6> */}
            <div className="max-h-[700px] overflow-y-auto">
              {isLoading ? (
                <div className="flex justify-center items-center">
                  <ScaleLoader height={14} color="#1569EB" loading={true} />
                </div>
              ) : (
                <>
                  {Candavailabilities?.length > 0 ? (
                    (() => {
                      const groupedSlots = Candavailabilities?.flatMap(
                        (item) => item.slots,
                      ).reduce((acc, slot) => {
                        const startDate = moment
                          .utc(slot.start_time)
                          .tz(InterviewertimeZone)
                          .format("YYYY-MM-DD");
                        if (!acc[startDate]) acc[startDate] = [];
                        acc[startDate].push(slot);
                        return acc;
                      }, {});

                      const sortedDates = Object.keys(groupedSlots).sort();

                      return sortedDates.map((date, dateIndex) => {
                        const slotsForDate = groupedSlots[date].sort((a, b) =>
                          moment
                            .utc(a.start_time)
                            .tz(InterviewertimeZone)
                            .diff(
                              moment.utc(b.start_time).tz(InterviewertimeZone),
                            ),
                        );

                        return (
                          <div key={dateIndex} className="mb-4">
                            <div className="w-full flex flex-col gap-1 my-1">
                              <div className="w-full">
                                {slotsForDate?.map((timeSlot, slotIndex) => {
                                  const startTime = moment
                                    .utc(timeSlot.start_time)
                                    .tz(InterviewertimeZone);
                                  const endTime = moment
                                    .utc(timeSlot.end_time)
                                    .tz(InterviewertimeZone);
                                  const spansDays = !startTime.isSame(
                                    endTime,
                                    "day",
                                  );
                                  const handleSlotClick = () => {
                                    const duration = moment.duration(
                                      endTime.diff(startTime),
                                    );
                                    const isLessThanOneHour =
                                      duration.asMinutes() < 60;

                                    let newEndTime = isLessThanOneHour
                                      ? endTime
                                      : startTime.clone().add(1, "hour");

                                    if (
                                      spansDays &&
                                      newEndTime.isAfter(
                                        startTime.clone().endOf("day"),
                                      )
                                    ) {
                                      newEndTime = startTime
                                        .clone()
                                        .endOf("day");
                                    }

                                    handleTimeChange(
                                      startTime.format("hh:mm A"),
                                      true,
                                      true,
                                    );
                                    handleTimeChange(
                                      newEndTime.format("hh:mm A"),
                                      false,
                                      true,
                                    );

                                    setInput("date", date ? date : null);
                                  };

                                  return (
                                    <div
                                      key={slotIndex}
                                      onClick={handleSlotClick}
                                      data-tooltip-id="tooltip"
                                      data-tooltip-content={"Click to Schedule"}
                                      className="w-full bg-defaultBg p-4 rounded-md mt-2 border-l-4 border-green-500 transition-shadow duration-300 hover:shadow-lg"
                                    >
                                      <div className="w-full">
                                        <div className="text-xs sm:text-sm text-gray-700 font-semibold py-2">
                                          {spansDays
                                            ? `${startTime.format("DD-MM-YYYY")} - ${endTime.format("DD-MM-YYYY")}`
                                            : startTime.format("DD-MM-YYYY")}
                                        </div>
                                        <div className="flex flex-col sm:flex-row items-start sm:items-center gap-2">
                                          <div className="flex items-center gap-2">
                                            <span>
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="15"
                                                height="15"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                className="flex-shrink-0"
                                              >
                                                <path
                                                  d="M11 11H10.25C10.25 11.3228 10.4566 11.6094 10.7628 11.7115L11 11ZM11.75 6C11.75 5.58579 11.4142 5.25 11 5.25C10.5858 5.25 10.25 5.58579 10.25 6H11.75ZM13.7628 12.7115C14.1558 12.8425 14.5805 12.6301 14.7115 12.2372C14.8425 11.8442 14.6301 11.4195 14.2372 11.2885L13.7628 12.7115ZM11.75 11V6H10.25V11H11.75ZM10.7628 11.7115L13.7628 12.7115L14.2372 11.2885L11.2372 10.2885L10.7628 11.7115ZM20.25 11C20.25 16.1086 16.1086 20.25 11 20.25V21.75C16.9371 21.75 21.75 16.9371 21.75 11H20.25ZM11 20.25C5.89137 20.25 1.75 16.1086 1.75 11H0.25C0.25 16.9371 5.06294 21.75 11 21.75V20.25ZM1.75 11C1.75 5.89137 5.89137 1.75 11 1.75V0.25C5.06294 0.25 0.25 5.06294 0.25 11H1.75ZM11 1.75C16.1086 1.75 20.25 5.89137 20.25 11H21.75C21.75 5.06294 16.9371 0.25 11 0.25V1.75Z"
                                                  fill="#252E4B"
                                                />
                                              </svg>
                                            </span>
                                            <span className="text-xs sm:text-sm whitespace-nowrap">
                                              {startTime.format("hh:mm A")}
                                            </span>
                                          </div>
                                          <span className=" sm:inline">
                                            {"-"}
                                          </span>
                                          <span className="text-xs sm:text-sm whitespace-nowrap">
                                            {endTime.format("hh:mm A")}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      });
                    })()
                  ) : (
                    <div className="text-gray-800 text-sm text-center my-10 font-normal">
                      {candidateMessage}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="relative w-[30%] bg-white rounded-lg">
            {isEditing && (
              <div className="absolute top-0 right-0 text-xs px-4 py-2 bg-yellow-200 rounded-tr-lg rounded-b-md text-yellow-800">
                Editing event
              </div>
            )}
            <form
              onSubmit={handleSubmit}
              className="flex flex-col gap-4 px-2 py-4"
            >
              <div className="w-full">
                <label
                  htmlFor="title"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Title
                </label>
                <InputBox
                  type="text"
                  name="title"
                  value={formdetails.title}
                  required="required"
                  placeholder="SproutsAI interview"
                  onChange={(event) => {
                    setInput("title", event.target.value);
                  }}
                  // className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500 "
                />
              </div>
              <div className="w-full">
                <label
                  className=" text-right text-[14px] font-normal not-italic required"
                  htmlFor=""
                >
                  From
                  {!user?.userIntegrations?.refresh_token &&
                    user?.userIntegrationsList.some(
                      (s) => s.email == selectedEmail,
                    ) && (
                      <span className="text-red-400 text-xs font-normal ml-2 text-wrap">
                        {`( Your email is not verified. Please verify your email before
                    sending the email.)`}
                      </span>
                    )}
                </label>
                <div
                  className={`${isEditing ? "opacity-50 pointer-events-none" : ""}`}
                >
                  <SelectAndSearch
                    placeholder="Select email"
                    defalultValue={selectedEmail}
                    data={[
                      ...user?.userIntegrationsList?.map((integration) => ({
                        name: integration?.email,
                        id: integration?.email,
                      })),
                      // {
                      //   name: "no-reply@sproutsai.com",
                      //   id: "no-reply@sproutsai.com",
                      // },
                    ]}
                    setSelectedTemplate={setSelectedEmail}
                  />
                </div>
              </div>
              <div className="w-full relative ">
                <label
                  htmlFor="candidate"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Candidate email
                </label>
                <InputBox
                  className=" w-full placeholder-gray-600  border border-gray-300 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder:text-[#A9A9AC] bg-gray-100 text-gray-700 "
                  type="email"
                  required
                  name="candidate"
                  readOnly={true}
                  placeholder="Candidate Email"
                  defaultValue={
                    applicant?.applicant?.email[0] ??
                    applicant?.applicant?.work_email[0] ??
                    ""
                  }
                  value={formdetails?.candidate}
                  onChange={(event) => {
                    setInput("candidate", event.target.value);
                  }}
                />
                {!formdetails?.candidate && (
                  <span className="absolute top-6 right-0 flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-500 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-800"></span>
                  </span>
                )}
              </div>
              <div className="w-full">
                <label className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5">
                  Select interview
                </label>
                <Combobox
                  frameworks={pipelineData}
                  selectInterview={formdetails.interviewStage}
                  setSelectedOptions={setSelectedOptions}
                  setSelectInterview={(value) => {
                    setInput("interviewStage", value);
                  }}
                  setSkillsWarning={setSkillsWarning}
                />
                {!SkillsWarning && (
                  <>
                    <div className="flex items-center text-yellow-800 w-fit bg-yellow-100 border border-yellow-200 rounded-md px-2 py-1 my-2 gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 256 256"
                      >
                        <g fill="currentColor">
                          <path
                            d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                            opacity="0.2"
                          />
                          <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                        </g>
                      </svg>
                      <p className="text-yellow-800 text-xs m-0">
                        This interview stage has no skills listed
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="w-full">
                <label
                  htmlFor="candidate"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Interviewer email
                </label>
                {warningMessage && (
                  <div className="flex items-center text-yellow-800 bg-yellow-100 border border-yellow-200 rounded-md px-2 py-1 my-2 gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 256 256"
                    >
                      <g fill="currentColor">
                        <path
                          d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                          opacity="0.2"
                        />
                        <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                      </g>
                    </svg>
                    <p className="text-yellow-800 text-sm m-0">
                      {warningMessage}
                    </p>
                  </div>
                )}
                {authmessage && (
                  <div className="flex items-center text-gray-800 bg-gray-400 border border-gray-300 rounded-md px-2 py-1 my-1 gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="currentColor"
                        d="M5 22q-.825 0-1.412-.587T3 20V6q0-.825.588-1.412T5 4h1V3q0-.425.288-.712T7 2t.713.288T8 3v1h8V3q0-.425.288-.712T17 2t.713.288T18 3v1h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm0-2h14V10H5zM5 8h14V6H5zm0 0V6zm7 6q-.425 0-.712-.288T11 13t.288-.712T12 12t.713.288T13 13t-.288.713T12 14m-4 0q-.425 0-.712-.288T7 13t.288-.712T8 12t.713.288T9 13t-.288.713T8 14m8 0q-.425 0-.712-.288T15 13t.288-.712T16 12t.713.288T17 13t-.288.713T16 14m-4 4q-.425 0-.712-.288T11 17t.288-.712T12 16t.713.288T13 17t-.288.713T12 18m-4 0q-.425 0-.712-.288T7 17t.288-.712T8 16t.713.288T9 17t-.288.713T8 18m8 0q-.425 0-.712-.288T15 17t.288-.712T16 16t.713.288T17 17t-.288.713T16 18"
                      />
                    </svg>
                    <p className="text-gray-800 text-sm m-0">{authmessage}</p>
                  </div>
                )}

                <div className="relative">
                  <div className="flex flex-wrap items-center ">
                    {selectedemailOptions?.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-[#F3F5F9] rounded-full gap-2 pr-2 m-1 border-2 border-gray-300"
                      >
                        <InitialsLogo
                          str={option}
                          fontSize="12px"
                          width="20px"
                          height="20px"
                          borderRadius="50%"
                        />
                        <span
                          className="text-sm break-all"
                          data-tooltip-id="tooltip"
                          data-tooltip-content={option}
                        >
                          {option}
                        </span>
                        <button
                          onClick={() => handleRemoveOption(index)}
                          className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M24 24L8 8M24 8L8 24"
                              stroke="#001B4B"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                    <InputBox
                      id="email-input"
                      type="email"
                      // className="flex-grow outline-none text-sm p-1 placeholder:text-[#A9A9AC]"
                      placeholder={
                        selectedemailOptions.length === 0
                          ? "Enter email addresses"
                          : ""
                      }
                      value={inputValue}
                      onChange={handleInputChange}
                      onKeyPress={handleKeyPress}
                    />
                  </div>

                  {recommendedEmails?.length > 0 && (
                    <ul className="absolute z-10 left-0 right-0 mt-1 border border-gray-200 rounded-md shadow-lg bg-white max-h-48 overflow-y-auto">
                      {recommendedEmails.map((email, index) => (
                        <li
                          key={index}
                          className="px-3 py-2 hover:bg-gray-200 cursor-pointer text-base "
                          onClick={() => handleOptionClick(email)}
                        >
                          {email}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="w-full flex flex-col gap-4">
                <div className="w-full flex flex-row gap-4 mt-1">
                  <div className="w-fit">
                    <ToggleButton
                      options={[
                        { value: true, label: "24-hour " },
                        { value: false, label: "12-hour " },
                      ]}
                      value={is24HourFormat}
                      onChange={handleTimeFormatToggle}
                    />
                  </div>
                </div>
                <div className="w-full flex flex-row gap-4">
                  <div className="w-1/3">
                    <label
                      htmlFor="date"
                      className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                    >
                      Date
                    </label>

                    <DatePicker
                      id="customDatePicker"
                      autoComplete="off"
                      selected={
                        formdetails?.date ? new Date(formdetails.date) : null
                      }
                      dateFormat="MMM d, yyyy"
                      className=" focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500 w-full h-[36px] border border-gray-400 p-2 rounded-lg text-center stext-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
                      minDate={new Date()}
                      required
                      onChange={(date) => {
                        setInput("date", date ? date : null);
                      }}
                    />
                  </div>
                  <div className="w-1/3">
                    <label
                      htmlFor="time"
                      className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                    >
                      Start time
                    </label>

                    <div className="relative">
                      <CustomSelect
                        options={timeOptions}
                        value={formdetails.time}
                        onChange={(value) => handleTimeChange(value, true)}
                        placeholder="Start Time"
                        is24HourFormat={is24HourFormat}
                        className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500"
                      />
                    </div>
                  </div>

                  <div className="w-1/3">
                    <label
                      htmlFor="endtime"
                      className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                    >
                      End time
                    </label>

                    <div className="relative">
                      <CustomSelect
                        options={getFilteredTimeOptions(
                          formdetails?.time,
                          !!formdetails.time,
                        )}
                        value={formdetails.endtime}
                        onChange={(value) => handleTimeChange(value, false)}
                        placeholder="End Time"
                        is24HourFormat={is24HourFormat}
                        className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {TimeWarning && (
                <div className="flex items-center text-yellow-800 bg-yellow-100 border border-yellow-200 rounded-md px-2 py-1 my-2 gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 256 256"
                  >
                    <g fill="currentColor">
                      <path
                        d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                        opacity="0.2"
                      />
                      <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                    </g>
                  </svg>
                  <p className="text-yellow-800 text-sm m-0">{TimeWarning}</p>
                </div>
              )}
              <div className="w-full">
                <label
                  htmlFor="AssistOption"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Choose assistance
                </label>
                <div className="flex flex-col md:flex-row gap-2 items-center">
                  <CustomCheckbox
                    id="botAssist"
                    label="Bot assist"
                    icon={<Bot size={15} />}
                    checked={formdetails.assistOption === "botAssist"}
                    onChange={() => handleAssistOptionChange("botAssist")}
                  />
                  <CustomCheckbox
                    id="noteTaker"
                    label="Note taker"
                    icon={<Pencil size={15} />}
                    checked={formdetails.assistOption === "noteTaker"}
                    onChange={() => handleAssistOptionChange("noteTaker")}
                  />
                </div>
              </div>
              <div className="w-full">
                <label
                  htmlFor="meetinglink"
                  className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
                >
                  Meeting link
                </label>
                {meetingLinkError && (
                  <p className="text-red-900 text-xs my-1 px-1">
                    {meetingLinkError}
                  </p>
                )}
                <div className="flex flex-col gap-4 my-3">
                  <button
                    type="button"
                    onClick={handleGetMeetLink}
                    className={`flex items-center rounded-lg border border-gray-500 text-gray-800 h-10 px-4 py-2 text-[14px] gap-2 ${
                      !MeetPlatformStatus.google
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-900 hover:text-blue-900"
                    }`}
                    disabled={!MeetPlatformStatus?.google}
                  >
                    <GoogleMeet width="35" height="35" />
                    {isMeetLinkLoading ? (
                      <ScaleLoader height={10} color="#1569EB" loading={true} />
                    ) : (
                      "Add Google Meet "
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={handleGetOutlookLink}
                    className={`flex items-center rounded-lg border border-gray-500 text-gray-800 h-10 px-4 py-2 text-[14px] gap-2 ${
                      !MeetPlatformStatus.Microsoft
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-900 hover:text-blue-900"
                    }`}
                    disabled={!MeetPlatformStatus?.Microsoft}
                  >
                    <TeamsLogo width="35" height="35" />
                    {isOutLookLoading ? (
                      <ScaleLoader height={10} color="#1569EB" loading={true} />
                    ) : (
                      "Add Microsoft Teams "
                    )}
                  </button>
                  <button
                    type="button"
                    onClick={handleGetZoomLink}
                    className={`flex items-center rounded-lg border border-gray-500 text-gray-800 h-10 px-4 py-2 text-[14px] gap-2 ${
                      !MeetPlatformStatus.Zoom
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:border-blue-900 hover:text-blue-900"
                    }`}
                    disabled={!MeetPlatformStatus?.Zoom}
                  >
                    <ZoomIcon width="35" height="30" />{" "}
                    {isZoomLinkLoading ? (
                      <ScaleLoader height={10} color="#1569EB" loading={true} />
                    ) : (
                      "Add Zoom Conference"
                    )}
                  </button>
                </div>
                <InputBox
                  type="text"
                  name="meetinglink"
                  placeholder="Meeting link"
                  value={formdetails?.link}
                  required="required"
                  onChange={(event) => {
                    setInput("link", event.target.value);
                  }}
                  // className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500"
                />

                <label
                  htmlFor=""
                  className="w-full mt-3 text-gray-800 text-sm not-italic font-normal  after:text-red-900 after:ml-2"
                >
                  Description
                </label>
                <TextAreaBox
                  onChange={(e) => setInput("description", e.target.value)}
                  value={formdetails?.description}
                  // className="focus-within:ring-2 focus-within:ring-blue-500 focus-within:border-blue-500"
                />
              </div>
              <div>
                {CalendarStatus === "" ? (
                  <div className="relative">
                    {showSteps && (
                      <div
                        ref={instructionsRef}
                        className="absolute bottom-full mb-2 bg-white border border-gray-200 shadow-lg rounded-md p-4 z-10 w-72"
                      >
                        <p className="font-semibold mb-2">
                          To set up calendar integration:
                        </p>
                        <ol className="list-decimal list-inside text-sm">
                          <li>
                            Go to{" "}
                            <a
                              href="/settings"
                              className="underline underline-offset-2 text-blue"
                            >
                              Settings
                            </a>
                          </li>
                          <li>Select the Integration tab</li>
                          <li>
                            Authenticate the service used by your organization
                          </li>
                        </ol>
                      </div>
                    )}
                    <p className="text-red-800 text-sm my-2 px-2 font-medium ">
                      Calendar integration not set up. Event cannot be added.
                      <span
                        className="ml-2 text-red-600 hover:text-red-800 focus:outline-none py-1"
                        onMouseEnter={() => setShowSteps(!showSteps)}
                        onClick={() => setShowSteps(!showSteps)}
                        aria-label="Show setup instructions"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5 inline-block align-text-bottom"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                          />
                        </svg>
                      </span>
                    </p>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div className="w-full flex items-start gap-4">
                <input
                  type="checkbox"
                  className="w-5 h-5"
                  id="confirm"
                  checked={checked}
                  onClick={handleCheckClick}
                />
                <label
                  htmlFor="confirm"
                  className="grow text-gray-800 text-sm not-italic font-normal"
                >
                  Please confirm that the meeting details are accurate before
                  sending the invite.
                </label>
              </div>
              {formValidMsg && (
                <div className="w-fit flex items-center text-red-800 bg-red-100 border border-red-300 rounded-md p-2 my-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-red-500 font-semibold mr-3"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2M12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8"
                    />
                  </svg>
                  <p className="text-sm m-0">{formValidMsg}</p>
                </div>
              )}
              <div className="flex flex-row justify-end items-center">
                <button
                  className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                  onClick={handleCancel}
                  type="button"
                >
                  {isEditing ? "Back" : "Cancel"}
                </button>
                {isEditing && (
                  <button
                    className="rounded-lg border btn-sec border-button h-[40px] text-[16px] px-4 mr-4 flex items-center gap-1"
                    onClick={() => {
                      setShowConfirmation("cancelEvent");
                    }}
                    type="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="1.5em"
                      height="1.5em"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill="currentColor"
                        d="M14.5 3A2.5 2.5 0 0 1 17 5.5v4.1a5.5 5.5 0 0 0-1-.393V7H4v7.5A1.5 1.5 0 0 0 5.5 16h3.707q.149.524.393 1H5.5A2.5 2.5 0 0 1 3 14.5v-9A2.5 2.5 0 0 1 5.5 3zm0 1h-9A1.5 1.5 0 0 0 4 5.5V6h12v-.5A1.5 1.5 0 0 0 14.5 4M19 14.5a4.5 4.5 0 1 1-9 0a4.5 4.5 0 0 1 9 0m-4.5.707l1.146 1.147a.5.5 0 0 0 .708-.708L15.207 14.5l1.147-1.146a.5.5 0 0 0-.708-.708L14.5 13.793l-1.146-1.147a.5.5 0 0 0-.708.708l1.147 1.146l-1.147 1.146a.5.5 0 0 0 .708.708z"
                      />
                    </svg>
                    Event
                  </button>
                )}
                <button
                  type="submit"
                  className={`bg-blue-800 border border-blue-800 text-white btn-md ${
                    !checked || !isFormValid()
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={!checked || !isFormValid() || loading}
                >
                  {loading ? (
                    <ScaleLoader height={14} color="#ffffff" loading={true} />
                  ) : isEditing ? (
                    "Save"
                  ) : (
                    "Invite"
                  )}
                </button>
              </div>
            </form>
            {showConfirmation == "cancelEvent" && (
              <ConfirmationModal
                ModalHeading="Confirm Event Cancellation"
                modalDismiss={() => {
                  setShowConfirmation("");
                }}
                Cancelevent={handleCancelMeeting}
                CancelLoading={CancelLoading}
              />
            )}
          </div>
          <div className="w-[50%] flex flex-col  bg-white rounded-lg p-4">
            <div className="flex fex-row items-start h-20">
              <div className="flex flex-wrap gap-4 items-start w-[40%]">
                <div className="flex gap-2 items-center px-4 py-3">
                  {!formdetails?.candidate ? (
                    <></>
                  ) : (
                    <>
                      <span className="w-[15px] h-[15px] rounded-lg bg-[orange]"></span>
                      <span className="break-word ">
                        {(candidate?.applicant?.first_name ?? "") +
                          (candidate?.applicant?.last_name ?? "") +
                          " (Candidate)"}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="w-[60%]">
                <div className="flex flex-col gap-2">
                  <h3>Timezone</h3>
                  <TimezoneSelect
                    Timezone={InterviewertimeZone}
                    setTimezone={setInterviewerTimeZone}
                  />
                </div>
              </div>
            </div>
            <ErrorMessage
              failedInterviewers={ZeroEventsInterviewers}
              Text={"No events found "}
            />
            <ErrorMessage
              failedInterviewers={failedFetchInterviewers}
              Text={"Events details are not available for external users"}
              isExternalError={true}
            />
            <GoogleCalenderView
              eventsForSelectedDay={eventsForSelectedDay}
              selectedEmails={selectedemailOptions}
              CandidateAvailability={availabilities}
              selectedDate={selectedDate}
              onDateChange={setSelectedDate}
              InterviewertimeZone={InterviewertimeZone}
              SelectedTitle={formdetails?.title || "Selected Slot"}
              handleTimeChange={handleTimeChange}
              StartTime={formdetails?.time}
              endTime={formdetails?.endtime}
              setInput={setInput}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default SchedulingInterview;
