import { Link } from "react-router-dom";
import logo from "./Logo.svg";
import recruiterLogo from "./RecruiterLogo.svg";
import { useState, useEffect } from "react";
const core = require("./../../lib/core");

export default function Logo() {
  const [user, setUser] = useState();

  useEffect(() => {
    getUser();
  }, []);
  // Get user details
  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
        {},
      );
      if (json?.data?.user) {
        setUser(json?.data?.user);
      }
    } catch (err) {}
  }

  return (
    <Link to="/" className="logo">
      {
        user?.account === 0 && (
          <img src={logo} className="img-responsive" alt="SproutsAI logo" />
        )
        // ):(
      }
      {
        user?.account === 1 && (
          <img
            src={recruiterLogo}
            className="img-responsive"
            alt="SproutsAI logo"
          />
        )
        // )}
      }
    </Link>
  );
}
