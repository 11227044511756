import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../../lib/core");

export const resumeUploadStatusService = createAsyncThunk(
  "resumeUploadStatus/getStatus",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_RESUME_UPLOAD_STATUS + payload,
      1,
      payload,
    );
    // Async logic for validate ats token
  },
);

export const questionUploadStatusService = createAsyncThunk(
  "questionUploadStatus/getStatus",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.GET,
      core.JOB_API.GET_QUESTIONS_STATUS + "/" + payload,
      1,
    );
    // Async logic for validate ats token
  },
);

export const resumeUpdateStatusService = createAsyncThunk(
  "resumeUploadStatus/update",
  async (payload, thunkAPI) => {
    return await core.API(
      core.API_METHODS.PUT,
      core.RECRUITER_API.UPDATE_RESUME_UPLOAD_STATUS,
      1,
      payload,
    );
    // Async logic for validate ats token
  },
);
