import React, { useEffect, useState } from "react";
import InputBox from "../../layouts/InputBox";
import { TiSocialLinkedin } from "react-icons/ti";
import InitialsLogo from "../../components/all/InitialsLogo";
import { MdOutlineEmail } from "react-icons/md";
import { IoCallOutline } from "react-icons/io5";
import { Search, MapPin, Users, Lock, ArrowRight } from "lucide-react";
import ContactSalesModal from "./ContactSalesModal";
import { ScaleLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import TruncateText from "./TruncateText";

const core = require("../../lib/core");

const ContactItem = ({ icon, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <span
      className="ml-2 text-gray-600 hover:text-gray-800 bg-[#F3F5F9] rounded-md px-2 py-1 "
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <span className="flex items-center">
        {icon}
        {isExpanded && content}
      </span>
    </span>
  );
};

const LeadsCandidatesListItems = () => {
  const [candidates, setCandidates] = useState([]);
  const [job_position, setJob_position] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [jobId, setJobId] = useState("");
  const [jobLocation, setJobLocation] = useState("");

  useEffect(() => {
    setJobId(location.pathname.split("/").at(-1));
  }, [location]);

  const fetchCandidates = async () => {
    setLoading(true);
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_MATCH_PROFILES_PUBLIC + jobId,
        1,
      );
      if (list?.data) {
        setCandidates(list?.data?.content);
        setJob_position(list?.data?.job_position);
        setJobLocation(list?.data?.location);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (jobId) {
      fetchCandidates();
    }
  }, [jobId]);

  const jobs = [
    {
      title: "Chief NLP Scientist",
      locations: [
        "United States",
        "Europe",
        "India",
        "London",
        "Zurich",
        "Berlin",
        "Edinburgh",
        "Dublin",
        "San Francisco Bay Area",
        "Asia",
        "Athens",
        "Latino América",
      ],
      candidateCount: "3k+",
      matchPercentage: 92,
    },
  ];

  const [modal, setModal] = useState(false);

  // console.log("jobLocation", jobLocation);
  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center">
          <ScaleLoader height={20} color="#1563E9" loading={true} />
        </div>
      ) : (
        <div className=" h-screen bg-gray-100  flex flex-col md:flex-row   gap-4">
          <img
            src={
              "https://test.app.sproutsai.com/static/media/SproutsaiLogo.575c09b574c7fe1d814a23691c338240.svg"
            }
            className="absolute top-0 left-3 md:left-6  object-cover"
            alt="background"
          />

          {/* Left Sidebar */}
          <div className=" w-full md:w-1/4  bg-white py-6 border-r border-gray-200">
            <div className="space-y-6">
              {jobs?.map((job, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg  overflow-hidden transition-all duration-300 hover:shadow-xl"
                >
                  <div className="p-3 md:p-6">
                    <h2 className="text-2xl font-semibold text-blue-900 mb-2">
                      {job_position}
                    </h2>
                    {jobLocation && (
                      <div className="flex items-center text-gray-600 mb-4">
                        <MapPin size={18} className="mr-2" />
                        <p className="text-sm">
                          {jobLocation
                            ?.map((item) => item?.name)
                            ?.slice(0, 3)
                            ?.join(", ")}{" "}
                          {jobLocation?.length > 3 &&
                            `+ ${jobLocation?.length - 3} more`}
                        </p>
                      </div>
                    )}
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center text-blue-600">
                        <Users size={18} className="mr-2" />
                        <p className="text-sm font-medium">
                          {job.candidateCount} Matching Candidates
                        </p>
                      </div>
                      <div className="flex items-center">
                        <Lock size={18} className="mr-2 text-yellow-500" />
                        <p className="text-sm font-normal text-yellow-600">
                          Locked
                        </p>
                      </div>
                    </div>
                    <div className="bg-blue-100 rounded-full h-2 mb-4">
                      <div
                        className="bg-blue-600 rounded-full h-2 transition-all duration-500 ease-out"
                        style={{ width: `${job.matchPercentage}%` }}
                      ></div>
                    </div>
                    <p className="text-sm text-gray-600 mb-4">
                      We have{" "}
                      <span className="font-semibold text-blue-600">
                        {job.matchPercentage}% matching
                      </span>{" "}
                      candidate profiles for this role and location.
                    </p>
                    <button
                      className="w-full bg-blue-600 text-white py-1 px-2 md:py-2 md:px-4 rounded-full hover:bg-blue-700 transition duration-300 flex items-center justify-center"
                      onClick={(e) => {
                        setModal(true);
                      }}
                    >
                      Contact Sales to Unlock Profiles
                      <ArrowRight size={18} className="ml-2" />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 p-3 md:p-6 bg-[#F3F5F9] h-[calc(100vh-20px)]  rounded-[16px] overflow-scroll scrollbar-candidate">
            <div className="flex space-x-6 mb-6">
              <button className="font-semibold border-b-2 border-purple-600 pb-2">
                Needs Review{" "}
                <span className="bg-gray-200 px-2 py-1 rounded-full text-sm">
                  {candidates?.length}
                </span>
              </button>
            </div>

            {candidates?.map((candidate, index) => (
              <div
                key={index}
                className="bg-white p-2 md:p-6 rounded-lg shadow mb-2"
              >
                <div className="flex items-start">
                  <div className="flex-1">
                    <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-2 space-y-2 sm:space-y-0">
                      <div className="flex items-center">
                        <InitialsLogo
                          str={candidate?.applicant?.first_name}
                          width="50px"
                          height="50px"
                        />
                        <div className="flex flex-col ml-2">
                          <h3 className="text-lg sm:text-xl font-semibold">
                            {candidate?.applicant?.first_name}{" "}
                            {candidate?.applicant?.last_name &&
                              !candidate?.applicant?.last_name.includes(
                                "**",
                              ) && (
                                <span className="">
                                  {candidate?.applicant?.last_name}
                                </span>
                              )}
                          </h3>
                          <p className="text-sm sm:text-base text-gray-600">
                            {candidate?.applicant?.title}
                          </p>
                        </div>
                      </div>

                      <div className="flex items-center">
                        {candidate?.applicant?.email?.length > 0 && (
                          <ContactItem
                            icon={<MdOutlineEmail className="" size={20} />}
                            content={
                              <span className="text-gray-800 ml-2">
                                {candidate?.applicant?.email[0]}
                              </span>
                            }
                          />
                        )}
                        {candidate?.applicant?.phone?.length > 0 && (
                          <ContactItem
                            icon={<IoCallOutline className="" size={20} />}
                            content={
                              <span className="text-gray-800 ml-2">
                                {candidate?.applicant?.phone[0]}
                              </span>
                            }
                          />
                        )}
                        <button
                          className="text-gray-600 hover:text-gray-800 bg-[#F3F5F9] rounded-md ml-2 p-2 sm:px-2 sm:py-1"
                          onClick={() => {
                            if (candidate?.applicant?.linkedin.includes("**")) {
                              setModal(true);
                            } else {
                              window.open(
                                candidate?.applicant?.linkedin,
                                "_blank",
                              );
                            }
                          }}
                        >
                          <TiSocialLinkedin className="w-5 h-5 sm:w-6 sm:h-6 md:w-7 md:h-7 lg:w-[20px] lg:h-[20px]" />
                        </button>
                        <button
                          className="text-gray-600 hover:text-gray-800 bg-[#F3F5F9] rounded-md  ml-2 p-2 sm:px-2 sm:py-1 text-xs sm:text-sm"
                          onClick={() => {
                            if (
                              candidate?.applicant?.resume_link.includes("**")
                            ) {
                              setModal(true);
                            } else {
                              window.open(
                                candidate?.applicant?.resume_link,
                                "_blank",
                              );
                            }
                          }}
                        >
                          Resume
                        </button>
                      </div>
                    </div>

                    {candidate.applicant.location &&
                      candidate.applicant.location[0] && (
                        <div className="flex items-center mb-2 mt-5">
                          <svg
                            className="w-4 h-4 mr-2 text-gray-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                          <span className="text-sm text-gray-600">{`${candidate.applicant.location[0].city}, ${candidate.applicant.location[0].state}`}</span>
                        </div>
                      )}
                    {candidate.applicant.education &&
                      candidate.applicant.education[0] && (
                        <div className="flex items-center mb-2">
                          <svg
                            className="w-4 h-4 mr-2 text-gray-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M12 14l9-5-9-5-9 5 9 5z" />
                            <path d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 14l9-5-9-5-9 5 9 5z"
                            />
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z"
                            />
                          </svg>
                          <span className="text-sm text-gray-600">
                            {`${candidate.applicant.education[0]?.degree ? candidate.applicant.education[0].degree : ""}${candidate.applicant.education[0]?.degree && candidate.applicant.education[0]?.school ? " - " : ""}${candidate.applicant.education[0]?.school ? candidate.applicant.education[0].school : ""}`}
                          </span>
                        </div>
                      )}

                    <TruncateText
                      text={
                        candidate?.applicant?.summary
                          ?.split(" ")
                          ?.slice(2)
                          ?.join(" ")
                          .charAt(0)
                          .toUpperCase() +
                        candidate?.applicant?.summary
                          ?.split(" ")
                          ?.slice(2)
                          ?.join(" ")
                          ?.slice(1)
                      }
                      maxLines={3}
                      className="bg-gray-100 py-4 rounded text-gray-600 w-full"
                    />

                    {/* <p className="bg-gray-100 py-4 rounded text-gray-600 ">
                      {/* remove first two latter from summary */}
                    {/* Start summary with capital letter */}
                    {/* {candidate?.applicant?.summary?.charAt(0).toUpperCase() +
                        candidate?.applicant?.summary?.slice(1)}
                      {" "} */}
                    {/* {candidate?.applicant?.summary
                        ?.split(" ")
                        ?.slice(2)
                        ?.join(" ")
                        .charAt(0)
                        .toUpperCase() +
                        candidate?.applicant?.summary
                          ?.split(" ")
                          ?.slice(2)
                          ?.join(" ")
                          ?.slice(1)}
                    </p> */}

                    {/* <p className="bg-gray-100 py-4 rounded">
                  <ul className="text-gray-600 list-disc list-inside">
                    <li>– MERN Stack Developer with 2 years of experience.</li>
                    <li>
                      Proficient in front-end and back-end technologies:
                      React.js, Node.js, MongoDB, and Express.js.
                    </li>
                    <li>
                      Experience in developing AI-based applications, video
                      streaming, and real-time web applications.
                    </li>
                    <li>
                      Led development teams, collaborated with cross-functional
                      teams, and optimized application performance.
                    </li>
                    <li>
                      Skilled in RESTful APIs, React, Redux, WebSockets, and
                      various modern web development tools.
                    </li>
                    <li>
                      Education: B.Tech from JECRC University (CGPA: 7.1/10).
                    </li>
                  </ul>
                </p> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
          {modal && <ContactSalesModal modalDismiss={(e) => setModal(false)} />}
        </div>
      )}
    </>
  );
};

export default LeadsCandidatesListItems;
