import * as d3 from "d3";
export function ellipsis(nodes, width, nodesLength) {
  nodes.each(function (a, i) {
    if (i == 0 || i == nodesLength - 1) {
      width = width - 20;
    } else {
      width = width;
    }
    var self = d3.select(this),
      textLength = self.node().getComputedTextLength(),
      text = self.text();
    while (textLength > width - 2 * 10 && text.length > 0) {
      text = text.slice(0, -1);
      self.text(text + "...");
      textLength = self.node().getComputedTextLength();
    }
  });
}

export function ellipsisVariable(...props) {
  props[0].each(function (d, i) {
    var self = d3.select(this),
      textLength = self.node().getComputedTextLength(),
      text = self.text();

    while (textLength > props[1][i] - 2 * 10 && text.length > 0) {
      text = text.slice(0, -1);
      self.text(text + "...");
      textLength = self.node().getComputedTextLength();
    }
  });
}
