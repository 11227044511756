import React, { useState } from "react";
import Modal from "../../modal/Modal";

function AddPipelineModal({ onSave, modalDismiss }) {
  const [stageValue, setStageValue] = useState("");
  const [message, setMessage] = useState({});

  const addStageFunction = () => {
    try {
      onSave(stageValue);
      setStageValue("");
      setMessage({ type: "success", message: "New Stage has been added." });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      onSave={addStageFunction}
      modalDismiss={() => {
        setStageValue("");
        modalDismiss();
      }}
      heading="Add New Stages"
      subheading="You can add sub stages for this particular stage."
    >
      <div className="w-full">
        <label
          htmlFor=""
          className="w-full text-gray-800 text-sm font-medium not-italic after:content-['*'] after:text-red-900 after:ml-1"
        >
          Title
        </label>
        <input
          required
          onChange={(e) => setStageValue(e.target.value)}
          value={stageValue}
          className="w-full rounded-lg bg-white border border-gray-500 h-10 py-2.5 px-4 active:border active:border-blue active:shadow-inputShadow focus:border-blue focus:shadow-inputShadow"
          type="text"
        />
      </div>
      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
}

export default AddPipelineModal;
