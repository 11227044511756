// import React, { useEffect, useState } from "react";
// import InputBox from "../../../layouts/InputBox";
// import { ScaleLoader } from "react-spinners";

// export default function AutoCompleteInputLocation({
//   list,
//   idx,
//   setvalue,
//   initialArray,
//   classText,
//   autoSuggestion,
//   onInputChange,
//   addLocation,
//   loading,
//   handleFilterSubmit, // Add handleFilterSubmit as a prop
// }) {
//   const [hide, setHide] = useState(true);
//   const [searchValue, setSearchValue] = useState("");
//   const [searchList, setSearchList] = useState([]);

//   useEffect(() => {
//     const handleClickOutside = (e) => {
//       if (!e.target.closest(`#${idx}`)) {
//         setHide(true);
//       }
//     };

//     document.addEventListener("click", handleClickOutside);
//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, [idx]);

//   const search = (e) => {
//     const filteredItems = list?.filter((item) =>
//       item?.name?.toLowerCase()?.includes(e?.toLowerCase())
//     );
//     setSearchList(filteredItems);
//   };

//   useEffect(() => {
//     if (!autoSuggestion) {
//       setSearchList([searchValue]);
//     } else {
//       setSearchList(list);
//     }
//   }, [searchValue, list]);

//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       e.preventDefault();
//       if (searchValue) {
//         const newValue = searchValue;
//         setvalue([...initialArray, newValue]);
//         setSearchValue("");
//         setHide(true);
//         addLocation({ name: newValue });
//         handleFilterSubmit(); // Call handleFilterSubmit when Enter is pressed
//       }
//     }
//   };

//   return (
//     <div className={idx} id={idx}>
//       <InputBox
//         placeholder="Search..."
//         className={`auto-complete-input ${classText}`}
//         size={"small"}
//         onChange={(e) => {
//           search(e.target.value);
//           setSearchValue(e.target.value);
//           setHide(false);
//           if (onInputChange) onInputChange(e.target.value);
//         }}
//         onKeyDown={handleKeyDown}
//         value={searchValue}
//       />
//       {loading ? (
//         <div className="auto-complete-list mt-0 bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
//           <ScaleLoader height={14} color="#1369E9" loading={true} />
//         </div>
//       ) : (
//         searchList.length > 0 && (
//           <ul
//             className={
//               hide
//                 ? "auto-complete-list hide"
//                 : "auto-complete-list mt-0 bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 border top-[44px]"
//             }
//           >
//             {searchList
//               .filter((element) => !initialArray.includes(element.name))
//               .map((item, i) => (
//                 <li
//                   key={i}
//                   className="match-value px-[20px] py-[10px]"
//                   onClick={() => {
//                     setvalue([...initialArray, item.name]);
//                     setSearchValue("");
//                     setHide(true);
//                     addLocation(item);
//                     handleFilterSubmit(); // Call handleFilterSubmit when an item is selected
//                   }}
//                 >
//                   {item.name}
//                 </li>
//               ))}
//           </ul>
//         )
//       )}
//     </div>
//   );
// }

// import React, { useEffect, useState } from "react";
// import InputBox from "../../../layouts/InputBox";
// import { ScaleLoader } from "react-spinners";

// export default function AutoCompleteInputLocation({
//   list,
//   idx,
//   setvalue,
//   initialArray,
//   classText,
//   autoSuggestion,
//   onInputChange,
//   addLocation, // New prop to handle adding locations with unique ids
//   loading,
// }) {
//   const [hide, setHide] = useState(true);
//   const [searchValue, setSearchValue] = useState("");
//   const [searchList, setSearchList] = useState([]);

//   // Use useEffect to handle clicks outside the component to hide the suggestion list
//   useEffect(() => {
//     const handleClickOutside = (e) => {
//       if (!e.target.closest(`#${idx}`)) {
//         setHide(true);
//       }
//     };

//     document.addEventListener("click", handleClickOutside);
//     return () => {
//       document.removeEventListener("click", handleClickOutside);
//     };
//   }, [idx]);

//   // Search function to filter the list based on the input value
//   const search = (e) => {
//     const filteredItems = list?.filter((item) =>
//       item?.name?.toLowerCase()?.includes(e?.toLowerCase())
//     );
//     setSearchList(filteredItems);
//   };

//   // Update search list when searchValue or list changes
//   useEffect(() => {
//     if (!autoSuggestion) {
//       setSearchList([searchValue]);
//     } else {
//       setSearchList(list);
//     }
//   }, [searchValue, list]);

//   // Handle key down event for Enter key to add new locations
//   const handleKeyDown = (e) => {
//     if (e.key === "Enter") {
//       e.preventDefault(); // Prevent default form submission behavior
//       if (searchValue) {
//         const newValue = searchValue;
//         setvalue([...initialArray, newValue]);
//         setSearchValue("");
//         setHide(true);
//         addLocation({ name: newValue }); // Add location with unique id in parent component
//       }
//     }
//   };

//   return (
//     <div className={idx} id={idx}>
//       <InputBox
//         placeholder="Search..."
//         className={`auto-complete-input ${classText}`}
//         size={"small"}
//         onChange={(e) => {
//           search(e.target.value); // Call search function on input change
//           setSearchValue(e.target.value);
//           setHide(false); // Show the suggestion list
//           if (onInputChange) onInputChange(e.target.value); // Call onInputChange if provided
//         }}
//         onKeyDown={handleKeyDown} // Call handleKeyDown on key down event
//         value={searchValue} // Set the input value
//       />
//       {loading ? (
//         <div className="auto-complete-list mt-0 bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
//           <ScaleLoader height={14} color="#1369E9" loading={true} />
//         </div>
//       ) : (
//         searchList.length > 0 && (
//           <ul
//             className={
//               hide
//                 ? "auto-complete-list hide"
//                 : "auto-complete-list mt-0 bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 border top-[44px]"
//             }
//           >
//             {searchList
//               .filter((element) => !initialArray.includes(element.name))
//               .map((item, i) => (
//                 <li
//                   key={i}
//                   className="match-value px-[20px] py-[10px]"
//                   onClick={() => {
//                     setvalue([...initialArray, item.name]); // Add selected item to the list
//                     setSearchValue("");
//                     setHide(true); // Hide the suggestion list
//                     addLocation(item); // Add location with unique id in parent component
//                   }}
//                 >
//                   {item.name}
//                 </li>
//               ))}
//           </ul>
//         )
//       )}
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import InputBox from "../../../layouts/InputBox";
import { ScaleLoader } from "react-spinners";

export default function AutoCompleteInputLocation({
  list,
  idx,
  setvalue,
  initialArray,
  classText,
  autoSuggestion,
  onInputChange,
  addLocation,
  loading,
  handleFilterSubmit, // Add handleFilterSubmit as a prop
}) {
  const [hide, setHide] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchList, setSearchList] = useState([]);
  const [isStateUpdated, setIsStateUpdated] = useState(false); // New state to track if state updates are completed

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(`#${idx}`)) {
        setHide(true);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [idx]);

  const search = (e) => {
    const filteredItems = list?.filter((item) =>
      item?.name?.toLowerCase()?.includes(e?.toLowerCase()),
    );
    setSearchList(filteredItems);
  };

  useEffect(() => {
    if (!autoSuggestion) {
      setSearchList([searchValue]);
    } else {
      setSearchList(list);
    }
  }, [searchValue, list]);

  useEffect(() => {
    if (isStateUpdated) {
      handleFilterSubmit(); // Call handleFilterSubmit after state updates
      setIsStateUpdated(false); // Reset the state update tracker
    }
  }, [isStateUpdated, handleFilterSubmit]);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchValue) {
        const newValue = searchValue;
        setvalue([...initialArray, newValue]);
        setSearchValue("");
        setHide(true);
        addLocation({ name: newValue });
        setIsStateUpdated(true); // Mark state as updated
      }
    }
  };

  return (
    <div className={idx} id={idx}>
      <InputBox
        placeholder="Search..."
        className={`auto-complete-input ${classText}`}
        size={"small"}
        onChange={(e) => {
          search(e.target.value);
          setSearchValue(e.target.value);
          setHide(false);
          if (onInputChange) onInputChange(e.target.value);
        }}
        onKeyDown={handleKeyDown}
        value={searchValue}
      />
      {loading ? (
        <div className="auto-complete-list mt-0 bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 border top-[44px] flex items-center justify-center py-5">
          <ScaleLoader height={14} color="#1369E9" loading={true} />
        </div>
      ) : (
        searchList.length > 0 && (
          <ul
            className={
              hide
                ? "auto-complete-list hide"
                : "auto-complete-list mt-0 bg-white no-list-style py-2 rounded-lg shadow-menuOptionShadow z-10 border top-[44px]"
            }
          >
            {searchList
              .filter((element) => !initialArray.includes(element.name))
              .map((item, i) => (
                <li
                  key={i}
                  className="match-value px-[20px] py-[10px]"
                  onClick={() => {
                    setvalue([...initialArray, item.name]);
                    setSearchValue("");
                    setHide(true);
                    addLocation(item);
                    setIsStateUpdated(true); // Mark state as updated
                  }}
                >
                  {item.name}
                </li>
              ))}
          </ul>
        )
      )}
    </div>
  );
}
