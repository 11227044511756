import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../../../lib/core";
import Header from "./Header";
import SignOut from "./SignOut";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

export default function Menu({ menuRef }) {
  const { user } = useContext(UserContext);
  const [recrProfile, setRecrProfile] = useState(true);
  const [candProfile, setCandProfile] = useState(true);

  const [role, setRole] = useState(null);
  const [name, setName] = useState("");
  const [secret_key_superadmin, setSecretKeySuperadmin] = useState(null);
  const [super_admin_id, setSuperAdminId] = useState(null);
  const [users, setUsers] = useState([]);

  const [debouncedName, setDebouncedName] = useState(name);

  // Debouncing logic
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedName(name);
    }, 800); // Adjust the delay (300ms) as needed

    return () => {
      clearTimeout(handler);
    };
  }, [name]);

  useEffect(() => {
    // get_users_with_role()
    setRole(core.getData(core.data.MY_ROLE));
    setSecretKeySuperadmin(core.getData(core.data.SECRET_KEY));
    setSuperAdminId(core.getData(core.data.SUPER_ADMIN_ID));
  }, []);

  const get_users_with_role = async (name) => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.USER_API.GET_USERS_LIST + "?limit=100&email=" + name,
      1,
    );
    if (json?.data) {
      setUsers(json?.data);
    } else {
      setUsers([]);
    }
  };

  const delegateAccess = async (email) => {
    console.log(email, secret_key_superadmin, super_admin_id);
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.DELEGATE_ACCESS,
      1,
      {
        email: email,
        secret_key_superadmin: secret_key_superadmin,
        super_admin_id: super_admin_id,
      },
    );
    if (json?.data) {
      core.setData(core.data.ACCESS_TOKEN, json.data.token);
      core.setData(core.data.UID, json.data.user);
      window.location.reload();
      console.log(json);
    } else {
    }
  };

  useEffect(() => {
    if (role?.includes("Super Admin")) {
      if (debouncedName && debouncedName?.trim()?.length > 0) {
        get_users_with_role(debouncedName);
      } else {
        get_users_with_role();
      }
    }
  }, [role, debouncedName]);

  console.log(users);

  useEffect(() => {
    if (user.account == "1") {
      setRecrProfile(false);
    } else {
      setCandProfile(false);
    }
  }, [user]);

  const nav = useNavigate();

  return (
    <ul
      className=" absolute z-[100] w-[206px] bg-[#FFF]  top-[70px] ml-[-55px] p-[8px] shadow-menuOptionShadow rounded-[8px]"
      ref={menuRef}
    >
      {user.account === 0 && (
        <li
          onClick={Header.toggleMenu}
          className="py-[5px] px-[8px] cursor-pointer"
        >
          <div
            className="flex items-center"
            onClick={() => nav(`/profile/${user?.id}/edit`)}
            hidden={recrProfile}
          >
            <span className="mr-[10px]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M13.6045 16.2141C12.9445 16.4091 12.1645 16.4991 11.2495 16.4991H6.74953C5.83453 16.4991 5.05453 16.4091 4.39453 16.2141C4.55953 14.2641 6.56203 12.7266 8.99953 12.7266C11.437 12.7266 13.4395 14.2641 13.6045 16.2141Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.25 1.5H6.75C3 1.5 1.5 3 1.5 6.75V11.25C1.5 14.085 2.355 15.6375 4.395 16.215C4.56 14.265 6.5625 12.7275 9 12.7275C11.4375 12.7275 13.44 14.265 13.605 16.215C15.645 15.6375 16.5 14.085 16.5 11.25V6.75C16.5 3 15 1.5 11.25 1.5ZM9 10.6275C7.515 10.6275 6.315 9.42001 6.315 7.93501C6.315 6.45001 7.515 5.25 9 5.25C10.485 5.25 11.685 6.45001 11.685 7.93501C11.685 9.42001 10.485 10.6275 9 10.6275Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M11.6849 7.93501C11.6849 9.42001 10.4849 10.6275 8.99994 10.6275C7.51494 10.6275 6.31494 9.42001 6.31494 7.93501C6.31494 6.45001 7.51494 5.25 8.99994 5.25C10.4849 5.25 11.6849 6.45001 11.6849 7.93501Z"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <h5 className="text-[#252E4B] text-[16px] not-italic font-normal">
              My profile
            </h5>
          </div>
        </li>
      )}

      <li
        onClick={Header.toggleMenu}
        className="py-[5px] px-[8px] cursor-pointer mt-[5px]"
      >
        <div className="flex items-center" onClick={() => nav("/settings")}>
          <span className="mr-[10px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              <g id="vuesax/linear/setting">
                <g id="setting">
                  <path
                    id="Vector"
                    d="M2.25 6.83203V11.1595C2.25 12.7495 2.25 12.7495 3.75 13.762L7.875 16.147C8.4975 16.507 9.51 16.507 10.125 16.147L14.25 13.762C15.75 12.7495 15.75 12.7495 15.75 11.167V6.83203C15.75 5.24953 15.75 5.24953 14.25 4.23703L10.125 1.85203C9.51 1.49203 8.4975 1.49203 7.875 1.85203L3.75 4.23703C2.25 5.24953 2.25 5.24953 2.25 6.83203Z"
                    stroke="#252E4B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    id="Vector_2"
                    d="M9 11.25C10.2426 11.25 11.25 10.2426 11.25 9C11.25 7.75736 10.2426 6.75 9 6.75C7.75736 6.75 6.75 7.75736 6.75 9C6.75 10.2426 7.75736 11.25 9 11.25Z"
                    stroke="#252E4B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </svg>
          </span>
          <h5 className="text-[#252E4B] text-[16px] not-italic font-normal">
            Settings
          </h5>
        </div>
      </li>
      {/* <li onClick={Header.toggleMenu} className="py-[5px] px-[8px] cursor-pointer">
        <div className="flex items-center" onClick={() => nav("/settings")}>
          <span className="mr-[10px]"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <g id="vuesax/linear/message-question">
              <g id="message-question">
                <path id="Vector" d="M12.75 13.8204H9.75L6.41249 16.0403C5.91749 16.3703 5.25 16.0179 5.25 15.4179V13.8204C3 13.8204 1.5 12.3204 1.5 10.0704V5.57031C1.5 3.32031 3 1.82031 5.25 1.82031H12.75C15 1.82031 16.5 3.32031 16.5 5.57031V10.0704C16.5 12.3204 15 13.8204 12.75 13.8204Z" stroke="#252E4B" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M8.99986 8.51953V8.36206C8.99986 7.85206 9.31488 7.58205 9.62988 7.36455C9.93738 7.15455 10.2448 6.88456 10.2448 6.38956C10.2448 5.69956 9.68986 5.14453 8.99986 5.14453C8.30986 5.14453 7.75488 5.69956 7.75488 6.38956" stroke="#252E4B" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M8.99662 10.3125H9.00337" stroke="#252E4B" strokeLinecap="round" strokeLinejoin="round" />
              </g>
            </g>
          </svg></span>
          <h5 className="text-[#252E4B] text-[16px] not-italic font-normal">FAQs</h5>
        </div>
      </li> */}

      <li
        onClick={Header.toggleMenu}
        className="py-[5px] px-[8px]  text-[#FF475D] text-[16px] not-italic font-normal
       flex items-center cursor-pointer mt-[5px]"
      >
        {/* border-t border-t-[#E1E1EE]  */}
        <span className="mr-[10px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
          >
            <g id="vuesax/linear/logout">
              <g id="logout">
                <path
                  id="Vector"
                  d="M13.0801 10.965L15.0001 9.045L13.0801 7.125"
                  stroke="#FF475D"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M7.31982 9.04688L14.9473 9.04687"
                  stroke="#FF475D"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M8.81982 15C5.50482 15 2.81982 12.75 2.81982 9C2.81982 5.25 5.50482 3 8.81982 3"
                  stroke="#FF475D"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </g>
          </svg>
        </span>
        <SignOut />
      </li>

      {users?.length ? (
        <div className="overflow-y-scroll max-h-[50vh] no-scrollbar2 z-100">
          <div className=" step no-padding">
            <InputBox
              size={"small"}
              className="border border-[#DADAE4] text-[12px] h-[18px] mt-[15px]  bg-[#FFF] rounded-[8px]  py-[5px] px-[10px]"
              placeholder="Search..."
              value={name}
              onChange={(e) => setName(e.target.value, "")}
            />
          </div>

          {users.map((item) => (
            <>
              <li
                onClick={() => delegateAccess(item?.email)}
                className="cursor-pointer text-[#252E4B] text-[12px] not-italic font-normal my-2 py-1 hover:bg-gray-300 active:bg-gray-300 focus:outline-none focus:ring focus:ring-gray-300"
              >
                {item?.email}
              </li>
            </>
          ))}
        </div>
      ) : (
        <></>
      )}
    </ul>
  );
}
