import React from "react";

const ExpandButton = ({ onClick, Icon, ...props }) => {
  return (
    <button
      onClick={onClick}
      {...props}
      className="p-2  bg-white border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:border-transparent transition-colors duration-200"
    >
      <Icon
        size={20}
        style={{
          fontWeight: "0 !important", // Adjust brightness and contrast to make it appear less bold
          filter: "brightness(0.9) contrast(0.8)",
        }}
      />
    </button>
  );
};

export default ExpandButton;
