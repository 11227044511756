import React, { useEffect, useMemo, useState } from "react";
import { FaBriefcase } from "react-icons/fa";
import { FaGraduationCap } from "react-icons/fa";
import InitialsLogo from "../../../all/InitialsLogo";

const ApplicantAccordionV3 = ({ allData, idx = null }) => {
  return (
    <div
      className="flex flex-col flex-wrap relative p-[1.5rem] h-auto bg-[#F9FAFB] gap-4"
      id="accordion"
    >
      <div className="w-full flex items-center justify-between">
        <h4 className=" text-md font-medium mb-2 not-italic">
          Profile Details
        </h4>
      </div>

      <div className="grid grid-cols-2 gap-x-6 gap-y-0">
        {/* Experience */}
        <div className="w-full flex flex-col items-start justify-start">
          <div className="flex flex-col items-start justify-start gap-y-6">
            {/* <Exp expList={dummy[idx & 1]?.experience} /> */}
            <Exp expList={allData?.experience} />
          </div>
        </div>

        {/* Education */}
        <div className="w-full flex flex-col items-start justify-start">
          <div className="flex flex-col items-start justify-start gap-y-6">
            <Edu eduList={allData?.education} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicantAccordionV3;

const Exp = ({ expList }) => {
  // Filter out experiences with missing required fields
  const validExpList =
    expList?.filter((exp) => {
      if (exp?.positions?.length > 0) {
        // For experiences with positions array
        return (
          exp?.company &&
          exp.positions.some((pos) => pos?.title && pos?.start_date)
        );
      }
      // For single position experiences
      return exp?.company && exp?.title && exp?.start_date;
    }) || [];

  if (validExpList.length === 0) {
    return (
      <div className="flex justify-start items-stretch flex-col gap-y-5">
        <div className="flex items-center gap-x-2">
          <FaBriefcase className="text-[#16182f] w-4 h-4" />
          <h2 className="text-base font-medium text-[#16182f]">
            Work Experience
          </h2>
        </div>
        <p className="text-gray-800">Experience Not found</p>
      </div>
    );
  }

  return (
    <div className="flex justify-start items-stretch flex-col gap-y-5">
      <div className="flex flex-row items-center justify-start gap-x-2 mb-4">
        <FaBriefcase className="text-[#16182f] w-4 h-4" />
        <h2 className="text-base font-medium text-[#16182f]">
          Work Experience
        </h2>
      </div>

      {validExpList.map((exp, i) => (
        <div key={`experience-${i}`}>
          {exp?.positions?.length > 0 ? (
            <div className="flex flex-col gap-6">
              {exp.positions
                .filter((position) => position?.title && position?.start_date)
                .map((position, idx) => (
                  <div
                    key={`position-${i}-${idx}`}
                    className="grid grid-cols-[50px_1fr] items-center gap-x-4"
                  >
                    <InitialsLogo
                      width="50px"
                      height="50px"
                      fontSize="24px"
                      styleClass="rounded-xl border border-[#e1e1ee]"
                      str={exp.company}
                    />
                    <div className="flex flex-col gap-y-1">
                      <p className="text-base font-semibold text-[#252e4b]">
                        {position.title}
                      </p>
                      <p className="text-sm text-[#1369e9]">{exp.company}</p>
                      <p className="text-sm text-[#252e4b]">
                        {position.start_date} -{" "}
                        {position?.end_date || "Present"}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <div className="grid grid-cols-[50px_1fr] items-center gap-x-4">
              <InitialsLogo
                width="50px"
                height="50px"
                fontSize="24px"
                styleClass="rounded-xl border border-[#e1e1ee]"
                str={exp.company}
              />
              <div className="flex flex-col gap-y-1">
                <p className="text-base font-semibold text-[#252e4b]">
                  {exp.title}
                </p>
                <p className="text-sm text-[#1369e9]">{exp.company}</p>
                <p className="text-sm text-[#252e4b]">
                  {exp.start_date} - {exp?.end_date || "Present"}
                </p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const Edu = ({ eduList }) => {
  const [eduShowAll, setEduShowAll] = useState(true);

  // Filter out education entries with missing required fields
  const validEduList =
    eduList?.filter((edu) => edu?.title && edu?.degree) || [];

  const displayedEduList = eduShowAll ? validEduList : validEduList.slice(0, 5);

  if (validEduList.length === 0) {
    return (
      <div className="flex justify-start items-stretch flex-col gap-y-5">
        <div className="flex items-center gap-x-2">
          <FaGraduationCap className="text-[#16182f] w-4 h-4" />
          <h2 className="text-base font-medium text-[#16182f]">Education</h2>
        </div>
        <p className="text-gray-800">Education Not found</p>
      </div>
    );
  }

  return (
    <div className="flex justify-start items-stretch flex-col gap-y-5">
      <div className="flex flex-row items-center justify-start gap-x-2 mb-4">
        <FaGraduationCap className="text-[#16182f] w-4 h-4" />
        <h2 className="text-base font-medium text-[#16182f]">Education</h2>
      </div>
      <div className="flex flex-col gap-6">
        {displayedEduList.map((education, i) => (
          <div
            className="grid grid-cols-[50px_1fr] items-center gap-x-4"
            key={"education" + i}
          >
            <InitialsLogo
              width="50px"
              height="50px"
              fontSize="24px"
              styleClass="rounded-xl border border-[#e1e1ee]"
              str={education.title}
            />
            <div className="flex flex-col gap-y-1">
              <p className="text-base font-semibold text-[#252e4b]">
                {education.degree}
              </p>
              <p className="text-sm text-[#1369e9]">{education.title}</p>
              <p className="text-sm text-[#252e4b]">
                {education?.start_year &&
                  education?.end_year &&
                  `${education.start_year} - ${education.end_year}`}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
