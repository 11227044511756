//all work

import React, { useEffect, useState, useMemo } from "react";
import InputBox from "../../../layouts/InputBox";
import LineChart from "../../analytics/LineChart";
import ProgressBar from "../../analytics/ProgressBar";
import Skills from "../createJob2/Skills";
import TextAreaBox from "../../../layouts/TextAreaBox";
import { set, update } from "draft-js/lib/DefaultDraftBlockRenderMap";
import { useOutletContext } from "react-router-dom";
const core = require("../../../lib/core");

const JobTempData = ({
  setData,
  data,
  showType = false,
  contextAvail = false,
}) => {
  data.editAccess = true;
  const outletContext = useOutletContext();
  const [showSection, setShowSection] = useState([]);
  const [customAttributes, setCustomAttributes] = useState([]);
  const [isEditingCustomAttribute, setIsEditingCustomAttribute] =
    useState(null);
  const [customAttributeInputValue, setCustomAttributeInputValue] = useState(
    JSON.parse(JSON.stringify(data?.custom_attributes || [])),
  );

  const handleEditCustomAttribute = (index) => {
    setIsEditingCustomAttribute(index);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Prevents the form from submitting on Enter key press
    }
  };

  const handleDelete = (index) => {
    const updatedCustomAttributes = customAttributeInputValue.filter(
      (_, idx) => idx !== index,
    );
    setCustomAttributeInputValue(updatedCustomAttributes);
    setTimeout(() => {
      console.log("Updated Custom Attribute State:", customAttributeInputValue);
      update_template();
    }, 0);
  };

  const [isEditing, setIsEditing] = useState({
    jobType: false,
    jobTitle: false,
    workplaceType: false,
    department: false,
    salary: false,
    experience: false,
    benefits: false,
    jobSummary: false,
    rolesAndResponsibility: false,
    education: false,
    skills: false,
    customAttribute: false,
  });
  const [editingKey, setEditingKey] = useState(null);
  const setEditingState = (key) => {
    setIsEditing((prevState) => {
      const newState = Object.keys(prevState).reduce((acc, curr) => {
        acc[curr] = curr === key;
        return acc;
      }, {});
      return newState;
    });
    setEditingKey(key);
  };
  const [errorList, setErrorList] = useState({});
  const errorMemo = useMemo(() => ({ errorList, setErrorList }), [errorList]);
  const [skills, setSkills] = useState(data?.skills);
  const [width, setWidth] = useState(500);
  const [loading, setLoading] = useState(false); // Add loading state
  const [jobTitleInputValue, setJobTitleInputValue] = useState(
    data.job_position,
  );
  const [workplaceTypeInputValue, setWorkplaceTypeInputValue] = useState(
    data.workplace_type,
  );

  const [soft_skills, setSoftSkills] = useState(data.soft_skills);
  const [criteriasVal, setCriteriasVal] = useState(data.criterias);
  // const [salaryInputValue, setSalaryInputValue] = useState(data.salary);
  const [salaryInputValue, setSalaryInputValue] = useState({
    min: data?.salary?.min || "",
    max: data?.salary?.max || "",
  });

  const [experienceInputValue, setExperienceInputValue] = useState({
    min: "",
    max: "",
  });

  const [jobSummaryInputValue, setJobSummaryInputValue] = useState(
    data.job_details,
  );
  const [
    rolesAndResponsibilityInputValue,
    setRolesAndResponsibilityInputValue,
  ] = useState(data.roles_and_responsibilities);
  const [educationInputValue, setEducationInputValue] = useState(
    data.education,
  );

  const [benefitsInputValue, setBenefitsInputValue] = useState(data.benefits); //update 1

  const [JobTypeArray, setJobTypeArray] = useState([
    { type: "Full-time", status: false },
    { type: "Regular/permanent", status: false },
    { type: "Part-time", status: false },
    { type: "Internship", status: false },
    { type: "Contract/temporary", status: false },
    { type: "Volunteer", status: false },
  ]);

  const [departments, setDepartments] = useState([
    { name: "Engineering and Development", status: false },
    { name: "Design and Creative", status: false },
    { name: "Marketing and Sales", status: false },
    { name: "Customer Support and Service", status: false },
    { name: "Product Management", status: false },
    { name: "Operations and Management", status: false },
    { name: "Finance and Accounting", status: false },
    { name: "Legal and Compliance", status: false },
    { name: "Research and Development", status: false },
    { name: "Quality Assurance and Testing", status: false },
    { name: "Healthcare and Medical", status: false },
    { name: "Education and Training", status: false },
    { name: "Manufacturing and Production", status: false },
  ]);

  const [workplaceTypeArray, setWorkplaceTypeArray] = useState([
    //2nd aug update 2
    { type: "Onsite", status: false },
    { type: "Hybrid", status: false },
    { type: "Remote", status: false },
  ]);

  const [benefits, setBenefits] = useState([
    { benefit: "Vision insurance", status: false },
    { benefit: "Flexible schedule", status: false },
    { benefit: "Tuition reimbursement", status: false },
    { benefit: "Referral program", status: false },
    { benefit: "Employee discount", status: false },
    { benefit: "Spending account", status: false },
    { benefit: "Health insurance", status: false },
    { benefit: "Paid time off", status: false },
    { benefit: "Dental insurance", status: false },
    { benefit: "Life insurance", status: false },
    { benefit: "401(K) matching Retirement plan", status: false },
    { benefit: "Computer assistance", status: false },
    { benefit: "Employee assistance program", status: false },
    { benefit: "Health saving account", status: false },
    { benefit: "Relocation assistance", status: false },
  ]);

  useEffect(() => {
    console.log("useEffect triggered with data:", data);
    if (data || editingKey !== null) {
      console.log("Data:", data.custom_attributes);
      setJobTitleInputValue(data.job_position);
      setWorkplaceTypeInputValue(data.workplace_type);
      setSalaryInputValue(data.salary);
      setExperienceInputValue({
        min: data.experience?.min || "",
        max: data.experience?.max || "",
      });
      setJobSummaryInputValue(data.job_details);
      setSkills(data?.skills);
      setRolesAndResponsibilityInputValue(data.roles_and_responsibilities);

      setBenefitsInputValue(data.benefits); //update 2

      setEducationInputValue(data.education);
      setCustomAttributeInputValue(
        JSON.parse(JSON.stringify(data?.custom_attributes || [])),
      );
      // Update JobTypeArray based on data.job_type
      if (data.job_type && Array.isArray(data.job_type)) {
        const updatedJobTypeArray = JobTypeArray.map((item) => ({
          ...item,
          status: data.job_type.includes(item.type),
        }));
        setJobTypeArray(updatedJobTypeArray);
      }

      // Update departments based on data.department
      if (data.department) {
        let updatedDepartments = [...departments];
        if (Array.isArray(data.department)) {
          updatedDepartments = updatedDepartments.map((item) => ({
            ...item,
            status: data.department.includes(item.name),
          }));
        } else if (typeof data.department === "string") {
          updatedDepartments = updatedDepartments.map((item) => ({
            ...item,
            status: item.name === data.department,
          }));
        }
        setDepartments(updatedDepartments);
      }
      if (data.benefits && Array.isArray(data.benefits)) {
        const updatedBenefits = benefits.map((item) => ({
          ...item,
          status: data.benefits.includes(item.benefit),
        }));
        setBenefits(updatedBenefits);
      }
    }
  }, [data, editingKey]);

  const addNewJobType = (value) => {
    if (
      JobTypeArray.map((d) => String(d.type).toLowerCase()).includes(
        String(value).toLowerCase(),
      )
    ) {
      throw new Error("Job type already added");
    }
    setJobTypeArray([
      ...JobTypeArray,
      { type: String(value).trim(), status: true },
    ]);
  };

  const handleNewJobType = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() === "") return;
        addNewJobType(e.target.value);
        setErrorList((current) => {
          const { type, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      setErrorList({ ...errorList, type: error.message });
    }
  };
  // making it single select for now
  const toggleJobTypeStatus = (index) => {
    const newArray = JobTypeArray.map((item, idx) =>
      idx === index
        ? { ...item, status: !item.status }
        : { ...item, status: false },
    );
    setJobTypeArray(newArray);
  };
  const toggleBenefitStatus = (index) => {
    const newArray = benefits.map((item, idx) => ({
      ...item,
      status: idx === index ? !item.status : item.status,
    }));
    setBenefits(newArray);
  };

  const toggleWorkplaceTypeStatus = (index) => {
    // 2nd aug update 3
    // This allows single selection, set other items to false
    const newArray = workplaceTypeArray.map((item, idx) =>
      idx === index
        ? { ...item, status: !item.status }
        : { ...item, status: false },
    );
    setWorkplaceTypeArray(newArray);
  };

  const toggleDepartmentStatus = (index) => {
    const newArray = departments.map((item, idx) => ({
      ...item,
      status: idx === index ? !item.status : item.status,
    }));
    setDepartments(newArray);
  };

  const toggleSection = (key) => {
    if (showSection.includes(key)) {
      setShowSection(showSection.filter((item) => item !== key));
    } else {
      setShowSection([...showSection, key]);
    }
  };

  // const update_template = async () => {
  //   setLoading(true);
  //   const mergedCustomAttributes = [
  //     ...customAttributes,
  //     ...(Array.isArray(customAttributeInputValue)
  //       ? customAttributeInputValue
  //       : []),
  //   ];

  //   try {

  //     if (!data || ! data.remote_id) {
  //       console.error('Invalid data or data.id is undefined');
  //       setLoading(false);
  //       return;
  //     }
  //     console.log('remote_id:', data.remote_id);
  //     console.log('**Update template data:**');
  //     console.log(data)

  //     const list = await core.API(
  //       core.API_METHODS.PUT,
  //       core.TEMPLATE_API.PUT_UPDATE_JOB_TEMPLATE +  data.remote_id,
  //       1,
  //       {
  //         job_type: JobTypeArray?.filter((item) => item?.status).map(
  //           (item) => item?.type,
  //         ),
  //         template_name: jobTitleInputValue,
  //         workplace_type: workplaceTypeInputValue,
  //         department: departments
  //           ?.filter((item) => item?.status)
  //           .map((item) => item?.name),
  //         salary: salaryInputValue,
  //         experience: experienceInputValue,
  //         skills: skills,
  //         benefits: benefits
  //           ?.filter((item) => item?.status)
  //           .map((item) => item?.benefit),
  //         job_details: jobSummaryInputValue,
  //         roles_and_responsibilities: rolesAndResponsibilityInputValue,
  //         education: educationInputValue,
  //         custom_attributes: mergedCustomAttributes,
  //         remote_id: data.remote_id,
  //       },
  //     );

  //     if (list?.data) {
  //       console.log('API response data:', list.data);
  //       setData(list.data);
  //       setIsEditing({
  //         jobType: false,
  //         jobTitle: false,
  //         workplaceType: false,
  //         department: false,
  //         salary: false,
  //         experience: false,
  //         benefits: false,
  //         jobSummary: false,
  //         rolesAndResponsibility: false,
  //         education: false,
  //         skills: false,
  //       });
  //       setLoading(false);
  //       setCustomAttributes([]);
  //       setIsEditingCustomAttribute(null);
  //       contextAvail && outletContext?.reloadList();
  //       // Update data with newly edited values
  //     }
  //   } catch (error) {
  //     setLoading(false);
  //     console.error(error);
  //   }
  // };

  const update_template = async () => {
    setLoading(true);

    // if (!data || !data.remote_id) {
    //   console.error("Invalid data or data.remote_id is undefined");
    //   setLoading(false);
    //   return;
    // }
    console.log("Current workplaceTypeArray:", workplaceTypeArray);

    // Build the complete payload with existing values and updated values
    const updateData = {
      remote_id: data.remote_id, // Always include remote_id
      // job_type: isEditing.jobType
      //   ? JobTypeArray?.filter((item) => item?.status).map((item) => item?.type)
      //   : data.job_type,

      job_type: isEditing.jobType
        ? JobTypeArray.filter((item) => item?.status)
            .map((item) => item?.type)
            .join(", ") // Join array elements into a string separated by commas
        : data.job_type,

      template_name: isEditing.jobTitle
        ? jobTitleInputValue
        : data.template_name ?? data.job_position,
      // workplace_type: isEditing.workplaceType
      //   ? workplaceTypeInputValue
      //   : data.workplace_type,

      // workplace_type: isEditing.workplaceType
      //   ? workplaceTypeArray
      //       .filter((item) => item.status)
      //       .map((item) => item.type)
      //   : data.workplace_type,

      workplace_type: isEditing.workplaceType
        ? workplaceTypeArray
            .filter((item) => item.status)
            .map((item) => item.type)
            .join(", ") // Join array elements into a string separated by commas
        : data.workplace_type,

      soft_skills: isEditing?.soft_skills ? soft_skills : data.soft_skills,
      department: isEditing.department
        ? departments?.filter((item) => item?.status).map((item) => item?.name)
        : data.department,
      salary: isEditing.salary ? salaryInputValue : data.salary,
      experience: isEditing.experience
        ? { min: experienceInputValue?.min, max: experienceInputValue?.max }
        : data.experience,
      criterias: isEditing.criterias ? criteriasVal : data.criterias,
      skill_score: isEditing.skillScore ? skillScore : data.skill_score,
      skills: isEditing.skills ? skills : data.skills,
      // benefits: isEditing.benefits
      //   ? benefits?.filter((item) => item?.status).map((item) => item?.benefit)
      //   : data.benefits,
      benefits: isEditing.benefits ? benefitsInputValue : data.benefits, //update 4

      job_details: isEditing.jobSummary
        ? jobSummaryInputValue
        : data.job_details,
      roles_and_responsibilities: isEditing.rolesAndResponsibility
        ? rolesAndResponsibilityInputValue
        : data.roles_and_responsibilities,
      education: isEditing.education ? educationInputValue : data.education,
      custom_attributes: isEditing.customAttribute
        ? customAttributes
        : data.custom_attributes,
    };

    console.log("**Update template data:**");
    console.log(updateData);

    try {
      const response = await core.API(
        core.API_METHODS.PUT,
        core.TEMPLATE_API.PUT_UPDATE_JOB_TEMPLATE,
        1,
        updateData,
      );

      if (response?.data) {
        console.log("API response data:", response.data);
        setData(response.data);
        setIsEditing({
          jobType: false,
          jobTitle: false,
          workplaceType: false,
          department: false,
          salary: false,
          experience: false,
          benefits: false,
          jobSummary: false,
          rolesAndResponsibility: false,
          education: false,
          skills: false,
          customAttribute: false,
        });
        setLoading(false);
        setCustomAttributes([]);
        setIsEditingCustomAttribute(null);
        contextAvail && outletContext?.reloadList();
      }
    } catch (error) {
      setLoading(false);
      console.error("Update failed:", error);
    }
  };

  const cancelEditing = (field) => {
    setIsEditing({ ...isEditing, [field]: false });
    switch (field) {
      case "customAttribute":
        setCustomAttributeInputValue(
          JSON.parse(JSON.stringify(data?.custom_attributes || [])),
        );
        setIsEditingCustomAttribute(null);
        break;
      case "jobTitle":
        setJobTitleInputValue(data.job_position);
        break;
      case "jobType":
        setJobTypeArray(
          JobTypeArray.map((item) => ({
            ...item,
            status: data.job_type.includes(item.type),
          })),
        );
        break;
      case "workplaceType":
        //setWorkplaceTypeInputValue(data.workplace_type);

        setWorkplaceTypeArray(
          workplaceTypeArray.map((item) => ({
            ...item,
            status: data.workplace_type.includes(item.type),
          })),
        );

        break;
      case "department":
        setDepartments(
          departments.map((item) => ({
            ...item,
            status: data.department.includes(item.name),
          })),
        );
        break;
      case "salary":
        setSalaryInputValue(data.salary);
        break;
      case "experience":
        setExperienceInputValue(data.experience);
        break;
      // case "benefits":
      //   setBenefits(
      //     benefits.map((item) => ({
      //       ...item,
      //       status: data.benefits.includes(item.benefit),
      //     })),
      //   );
      //   break;
      case "benefits":
        setBenefitsInputValue(data.benefits);
        break; //update 3

      case "jobSummary":
        setJobSummaryInputValue(data.job_details);
        break;
      case "rolesAndResponsibility":
        setRolesAndResponsibilityInputValue(data.roles_and_responsibilities);
        break;
      case "education":
        setEducationInputValue(data.education);
        break;
      case "skills":
        setSkills(data.skills);
        break;
      default:
        break;
    }
  };

  const getTypeColor = (num) => {
    switch (num) {
      case -2:
        return "#FF6C7E";
      case -1:
        return "#FFAA33";
      case 1:
        return "#1369E9";
      case 2:
        return "teal";
      default:
        return "gray";
    }
  };

  const sections = [
    {
      key: "description",
      name: "Description",
      content: (
        <>
          {data?.job_position && (
            <div className="mb-4 group ">
              <label className="block text-sm font-normal not-italic">
                Job title
              </label>
              {isEditing.jobTitle ? (
                <div>
                  <InputBox
                    type="text"
                    className="mb-1 flex"
                    value={jobTitleInputValue}
                    onChange={(e) => setJobTitleInputValue(e.target.value)}
                    placeholder="Type here"
                  />
                  <button
                    className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                    onClick={() => cancelEditing("jobTitle")}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                    onClick={update_template}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    {data.job_position}
                  </p>
                  {data?.editAccess && (
                    <span
                      className="ml-2 cursor-pointer invisible group-hover:visible"
                      onClick={() => setEditingState("jobTitle")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {data?.job_type && (
            <div className="flex mb-4 gap-1 group relative">
              <span className="job-detail-info-title block text-sm font-normal not-italic">
                Job type{" "}
                {isEditing.jobType ? (
                  <div>
                    {/* <InputBox
                      type="text"
                      className="mb-1 flex"
                      value={jobTypeInputValue}
                      onChange={(e) => {
                        if (
                          e.target.value.trim() === "" &&
                          Object.keys(errorList).includes("type")
                        ) {
                          setErrorList((current) => {
                            const { type, ...rest } = current;
                            return rest;
                          });
                        }
                        setJobTypeInputValue(e.target.value);
                      }}
                      onKeyDown={handleNewJobType}
                      placeholder="Type here"
                    /> */}
                    <div className="flex gap-1 group flex-wrap mt-2">
                      {JobTypeArray?.map((item, index) => (
                        <div
                          key={index}
                          className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                            py-[7px] px-[12px] 
                              ${
                                item?.status
                                  ? "hover:!bg-[#699c8f]"
                                  : "hover:!bg-[#00b98e34]"
                              }
                           `}
                          style={{
                            background: item?.status
                              ? "#00B98D"
                              : "rgba(51, 52, 142, 0.03)",
                          }}
                          onClick={() => toggleJobTypeStatus(index)}
                        >
                          <p
                            className={`text-sm ${
                              item?.status
                                ? "font-medium text-[#FFFFFF]"
                                : "font-normal text-[#252E4B]"
                            }`}
                          >
                            {item?.type}
                          </p>
                        </div>
                      ))}
                    </div>
                    {Object.keys(errorList)?.length > 0 &&
                      Object.values(errorList)?.map((item) => (
                        <p className="text-[#ff0000] text-[10px] mt-[5px] ml-[5px]">
                          {item}
                        </p>
                      ))}
                    <button
                      className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                      onClick={() => cancelEditing("jobType")}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                    <button
                      className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                      onClick={update_template}
                      disabled={loading}
                    >
                      {loading ? "Updating..." : "Update"}
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                      {Array.isArray(data.job_type)
                        ? data.job_type.join(", ")
                        : data.job_type}
                    </p>
                    {data?.editAccess && (
                      <span
                        className="ml-2 cursor-pointer invisible group-hover:visible"
                        onClick={() => setEditingState("jobType")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M12 20h9" />
                          <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                        </svg>
                      </span>
                    )}
                  </div>
                )}
              </span>
            </div>
          )}
          {data?.workplace_type && (
            <div className="flex mb-4 gap-1 group relative">
              <span className="workplace-detail-info-title block text-sm font-normal not-italic">
                Workplace type{" "}
                {isEditing.workplaceType ? (
                  <div>
                    <div className="flex gap-1 group flex-wrap mt-2">
                      {workplaceTypeArray.map((item, index) => (
                        <div
                          key={index}
                          className={`mt-[8px] rounded-[68px] border border-[#E1E1EE] flex items-center py-[7px] px-[12px]
                  ${item.status ? "hover:!bg-[#699c8f]" : "hover:!bg-[#00b98e34]"}
                `}
                          style={{
                            background: item.status
                              ? "#00B98D"
                              : "rgba(51, 52, 142, 0.03)",
                          }}
                          onClick={() => toggleWorkplaceTypeStatus(index)}
                        >
                          <p
                            className={`text-sm ${
                              item.status
                                ? "font-medium text-[#FFFFFF]"
                                : "font-normal text-[#252E4B]"
                            }`}
                          >
                            {item.type}
                          </p>
                        </div>
                      ))}
                    </div>
                    <button
                      className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                      onClick={() => cancelEditing("workplaceType")}
                      disabled={loading}
                    >
                      Cancel
                    </button>
                    <button
                      className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                      onClick={update_template}
                      disabled={loading}
                    >
                      {loading ? "Updating..." : "Update"}
                    </button>
                  </div>
                ) : (
                  <div className="flex items-center">
                    <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                      {Array.isArray(data.workplace_type)
                        ? data.workplace_type.join(", ")
                        : data.workplace_type}
                    </p>
                    {data?.editAccess && (
                      <span
                        className="ml-2 cursor-pointer invisible group-hover:visible"
                        onClick={() => setEditingState("workplaceType")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-5 w-5"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M12 20h9" />
                          <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                        </svg>
                      </span>
                    )}
                  </div>
                )}
              </span>
            </div>
          )}

          {data?.department && (
            <div className="mb-4 group">
              <label className="block text-sm font-normal not-italic">
                Department
              </label>
              {isEditing.department ? (
                <div>
                  {/* <InputBox
                    type="text"
                    className="mb-1 flex"
                    value={departmentInputValue}
                    onChange={(e) => setDepartmentInputValue(e.target.value)}
                    placeholder="Type here"
                  /> */}
                  <div className="flex gap-1 flex-wrap mt-2">
                    {departments?.map((item, index) => (
                      <div
                        key={index}
                        className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                          py-[7px] px-[12px] 
                            ${
                              item?.status
                                ? "hover:!bg-[#699c8f]"
                                : "hover:!bg-[#00b98e34]"
                            }
                         `}
                        style={{
                          background: item?.status
                            ? "#00B98D"
                            : "rgba(51, 52, 142, 0.03)",
                        }}
                        onClick={() => toggleDepartmentStatus(index)}
                      >
                        <p
                          className={`text-sm ${
                            item?.status
                              ? "font-medium text-[#FFFFFF]"
                              : "font-normal text-[#252E4B]"
                          }`}
                        >
                          {item?.name}
                        </p>
                      </div>
                    ))}
                  </div>
                  <button
                    className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                    onClick={() => cancelEditing("department")}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                    onClick={update_template}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    {Array.isArray(data.department)
                      ? data.department.join(", ")
                      : data.department}
                  </p>
                  {data?.editAccess && (
                    <span
                      className="ml-2 cursor-pointer invisible group-hover:visible"
                      onClick={() => setEditingState("department")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {data?.salary && (
            <div className="mb-4 group">
              <label className="block text-sm font-normal not-italic">
                Salary
              </label>
              {isEditing.salary ? (
                <div>
                  <InputBox
                    type="text"
                    className="mb-1 flex"
                    value={salaryInputValue.min}
                    onChange={(e) =>
                      setSalaryInputValue((prev) => ({
                        ...prev,
                        min: parseInt(e.target.value),
                      }))
                    }
                    placeholder="Min salary"
                  />
                  <InputBox
                    type="text"
                    className="mb-1 flex"
                    value={salaryInputValue.max}
                    onChange={(e) =>
                      setSalaryInputValue((prev) => ({
                        ...prev,
                        max: parseInt(e.target.value),
                      }))
                    }
                    placeholder="Max salary"
                  />
                  <button
                    className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                    onClick={() => cancelEditing("salary")}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                    onClick={() => update_template("salary")}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    {data.salary.min} - {data.salary.max}
                  </p>
                  {data?.editAccess && (
                    <span
                      className="ml-2 cursor-pointer invisible group-hover:visible"
                      onClick={() => setEditingState("salary")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {data?.experience && (
            <div className="mb-4 group">
              <label className="block text-sm font-normal not-italic">
                Experience
              </label>
              {isEditing.experience ? (
                <div>
                  <InputBox
                    type="text"
                    className="mb-1 flex"
                    value={experienceInputValue.min}
                    onChange={(e) =>
                      setExperienceInputValue((prev) => ({
                        ...prev,
                        min: e.target.value,
                      }))
                    }
                    placeholder="Min experience"
                  />
                  <InputBox
                    type="text"
                    className="mb-1 flex"
                    value={experienceInputValue.max}
                    onChange={(e) =>
                      setExperienceInputValue((prev) => ({
                        ...prev,
                        max: e.target.value,
                      }))
                    }
                    placeholder="Max experience"
                  />
                  <button
                    className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                    onClick={() => cancelEditing("experience")}
                  >
                    Cancel
                  </button>
                  <button
                    className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                    onClick={() => update_template("experience")}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    Min: {data.experience.min}
                  </p>
                  <p className="px-2 text-sm font-normal not-italic">-</p>
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    Max: {data.experience.max}
                  </p>
                  {data?.editAccess && (
                    <span
                      className="ml-2 cursor-pointer invisible group-hover:visible"
                      onClick={() => setEditingState("experience")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {data?.benefits && (
            <div className="mb-4 group">
              <label className="block text-sm font-normal not-italic">
                Benefits
              </label>
              {isEditing.benefits ? (
                <div>
                  <InputBox
                    multiline
                    rows={6}
                    className="mb-1 flex"
                    value={benefitsInputValue}
                    onChange={(e) => setBenefitsInputValue(e.target.value)}
                    placeholder="Type benefits here"
                  />
                  {/* <textarea
                    // rows={6}
                    className="mb-1 flex"
                    value={benefitsInputValue}
                    onChange={(e) => setBenefitsInputValue(e.target.value)}
                    placeholder="Type benefits here"
                  /> */}
                  <button
                    className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                    onClick={() => cancelEditing("benefits")}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                    onClick={update_template}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    {data.benefits}
                  </p>
                  {data?.editAccess && (
                    <span
                      className="ml-2 cursor-pointer"
                      onClick={() => setEditingState("benefits")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
          {data?.job_details && (
            <div className="mb-4 group">
              <label className="block text-sm font-normal not-italic">
                Job summary
              </label>
              {isEditing.jobSummary ? (
                <div>
                  <InputBox
                    multiline
                    rows={6}
                    className="mb-1 flex"
                    value={jobSummaryInputValue}
                    onChange={(e) => setJobSummaryInputValue(e.target.value)}
                    placeholder="Type here"
                  />
                  <button
                    className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                    onClick={() => cancelEditing("jobSummary")}
                    disabled={loading}
                  >
                    Cancel
                  </button>
                  <button
                    className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                    onClick={update_template}
                    disabled={loading}
                  >
                    {loading ? "Updating..." : "Update"}
                  </button>
                </div>
              ) : (
                <div className="flex items-center">
                  <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                    {data.job_details}
                  </p>
                  {data?.editAccess && (
                    <span
                      className="ml-2 cursor-pointer invisible group-hover:visible"
                      onClick={() => setEditingState("jobSummary")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path d="M12 20h9" />
                        <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                      </svg>
                    </span>
                  )}
                </div>
              )}
            </div>
          )}
        </>
      ),
    },
    {
      key: "skills",
      name: "Skills",
      content: (
        <div>
          <div className="w-full relative overflow-x-scroll">
            <LineChart
              id="job-skills"
              data={skills?.filter((iter) => iter?.criteria != "Misc")}
              chartHeight={240}
              width={Math.max(width - 20, skills?.length * 125)}
              color={"steelblue"}
              clickFunction={false}
            />
          </div>
          {isEditing.skills ? (
            <div>
              <Skills
                classTxt={"w-full flex flex-col left-input mt-[28px]"}
                skills={skills}
                setSkills={setSkills}
              />
              <button
                className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                onClick={() => cancelEditing("skills")}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                onClick={update_template}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          ) : (
            <div className="flex flex-wrap gap-x-4 gap-y-2 relative group">
              {/* {data.skills.map((skill, index) => (
                <span
                  key={index}
                  className="py-1 px-2 border border-gray-400 rounded-lg text-sm font-normal not-italic text-gray-800 "
                >
                  {skill}
                </span>
              ))} */}

              <div>
                {skills?.length > 0 &&
                  skills.map(
                    (data, it) =>
                      data?.skills?.length > 0 && (
                        <div className="w-full mb-4" key={it}>
                          <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                            {data?.criteria}
                          </h4>
                          <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                            {data?.skills.map((skill, i) => (
                              <div
                                className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                                key={i}
                              >
                                <span
                                  className="w-[45%] text-[#252E4B] text-[14px] not-italic font-normal leading-7 truncate overflow-hidden  mr-[10px]"
                                  onMouseOver={(e) => {
                                    var overflowed =
                                      e.target.scrollWidth >
                                      e.target.clientWidth;
                                    e.target.title = overflowed
                                      ? e.target.textContent
                                      : "";
                                  }}
                                  onMouseOut={(e) => (e.target.title = "")}
                                >
                                  {skill?.label}
                                </span>
                                <span className="w-[45%]  mr-[10px]">
                                  <ProgressBar
                                    id={"skills-bar" + it + i}
                                    style_classes="w-full"
                                    data={Math.abs(skill?.value)}
                                    height={8}
                                    barColor={
                                      getTypeColor(skill?.type) ?? "teal"
                                    }
                                    bgColor="#EFF0F2"
                                    width={width * 0.45 - 10}
                                    fromRight={skill?.value < 0}
                                  />
                                </span>
                                <span style={{ width: 20 }}>
                                  {Math.floor(skill?.value)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      ),
                  )}
              </div>
              {data?.editAccess && (
                <span
                  className="ml-2 cursor-pointer absolute top-0 right-0 invisible group-hover:visible"
                  onClick={() => setEditingState("skills")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 20h9" />
                    <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                  </svg>
                </span>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      key: "roles",
      name: "Roles and responsibility",
      content: (
        <div className="mb-4 group">
          {/* <label className="block text-sm font-normal not-italic">Roles and Responsibility</label> */}
          {isEditing.rolesAndResponsibility ? (
            <div>
              <InputBox
                multiline
                rows={6}
                className="mb-1 flex"
                value={rolesAndResponsibilityInputValue}
                onChange={(e) =>
                  setRolesAndResponsibilityInputValue(e.target.value)
                }
                placeholder="Type here"
              />
              <button
                className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                onClick={() => cancelEditing("rolesAndResponsibility")}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                type="button"
                className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                onClick={update_template}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                {data.roles_and_responsibilities}
              </p>
              {data?.editAccess && (
                <span
                  className="ml-2 cursor-pointer invisible group-hover:visible"
                  onClick={() => setEditingState("rolesAndResponsibility")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 20h9" />
                    <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                  </svg>
                </span>
              )}
            </div>
          )}
        </div>
      ),
    },
    {
      key: "qualification",
      name: "Qualification",
      content: (
        <div className="mb-4 group">
          <label className="block text-sm font-normal not-italic">
            Education
          </label>
          {isEditing.education ? (
            <div>
              <InputBox
                onKeyPress={handleKeyPress} // Add the key press handler
                type="text"
                className="mb-1 flex"
                value={educationInputValue}
                onChange={(e) => setEducationInputValue(e.target.value)}
                placeholder="Type here"
              />
              <button
                className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
                onClick={() => cancelEditing("education")}
                disabled={loading}
              >
                Cancel
              </button>
              <button
                className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
                onClick={update_template}
                disabled={loading}
              >
                {loading ? "Updating..." : "Update"}
              </button>
            </div>
          ) : (
            <div className="flex items-center">
              <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
                {data.education}
              </p>
              {data?.editAccess && (
                <span
                  className="ml-2 cursor-pointer invisible group-hover:visible"
                  onClick={() => setEditingState("education")}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M12 20h9" />
                    <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
                  </svg>
                </span>
              )}
            </div>
          )}
        </div>
      ),
    },

    // {
    //   key: "custom",
    //   name: "Custom attributes",
    //   content: (
    //     <div>
    //       {customAttributeInputValue?.length >= 0 && (
    //         <div>
    //           {customAttributeInputValue?.map((item, index) => (
    //             <div key={index} className="mb-4 group">
    //               {isEditingCustomAttribute === index ? (
    //                 <div>
    //                   <div className="flex justify-between gap-4">
    //                     <InputBox
    //                       disabled={loading}
    //                       value={customAttributeInputValue[index]?.name}
    //                       placeholder={"Custom attribute name"}
    //                       onChange={(e) => {
    //                         let temp = [...customAttributeInputValue];
    //                         temp[index].name = e.target.value;
    //                         setCustomAttributeInputValue(temp);
    //                       }}
    //                     />
    //                     <button
    //                       className="btn-sec btn-md h-[48px]"
    //                       data-tooltip-id="tooltip"
    //                       data-tooltip-content={"Delete"}
    //                       onClick={async () => {
    //                         setLoading(true);
    //                         try {
    //                           const updatedCustomAttributes =
    //                             customAttributeInputValue.filter(
    //                               (_, idx) => idx !== index,
    //                             );
    //                           setCustomAttributeInputValue(
    //                             updatedCustomAttributes,
    //                           );
    //                         } catch (e) {
    //                           console.log(e);
    //                         }
    //                         setLoading(false);
    //                       }}
    //                     >
    //                       <svg
    //                         xmlns="http://www.w3.org/2000/svg"
    //                         fill="none"
    //                         viewBox="0 0 24 24"
    //                         strokeWidth={1.5}
    //                         stroke="red"
    //                         className="w-5 h-5"
    //                       >
    //                         <path
    //                           strokeLinecap="round"
    //                           strokeLinejoin="round"
    //                           d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
    //                         />
    //                       </svg>
    //                     </button>
    //                   </div>
    //                   <div className="mt-[28px]">
    //                     <TextAreaBox
    //                       disabled={loading}
    //                       placeholder={"Description..."}
    //                       size="200px"
    //                       value={customAttributeInputValue[index]?.value}
    //                       onChange={(e) => {
    //                         let temp = [...customAttributeInputValue];
    //                         temp[index].value = e.target.value;
    //                         setCustomAttributeInputValue(temp);
    //                       }}
    //                     />
    //                   </div>
    //                 </div>
    //               ) : (
    //                 <>
    //                   <label className="block text-sm font-normal not-italic">
    //                     {item?.name}
    //                   </label>
    //                   <div className="flex items-center">
    //                     <p className="py-1 px-2 border border-gray-300 rounded-lg text-sm font-normal not-italic text-gray-800">
    //                       {item?.value}
    //                     </p>
    //                     {data?.editAccess && (
    //                       <span
    //                         className="ml-2 cursor-pointer invisible group-hover:visible"
    //                         onClick={() => handleEditCustomAttribute(index)}
    //                       >
    //                         <svg
    //                           xmlns="http://www.w3.org/2000/svg"
    //                           className="h-5 w-5"
    //                           fill="none"
    //                           viewBox="0 0 24 24"
    //                           stroke="currentColor"
    //                           strokeWidth={2}
    //                           strokeLinecap="round"
    //                           strokeLinejoin="round"
    //                         >
    //                           <path d="M12 20h9" />
    //                           <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
    //                         </svg>
    //                       </span>
    //                     )}
    //                   </div>
    //                 </>
    //               )}
    //             </div>
    //           ))}

    //           {isEditingCustomAttribute !== null && (
    //             <div className="mt-4 flex">
    //               <button
    //                 className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
    //                 onClick={() => cancelEditing("customAttribute")}
    //                 disabled={loading}
    //               >
    //                 Cancel
    //               </button>
    //               <button
    //                 className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
    //                 onClick={update_template}
    //                 disabled={loading}
    //               >
    //                 {loading ? "Updating..." : "Update"}
    //               </button>
    //             </div>
    //           )}
    //         </div>
    //       )}

    //       <button
    //         type="button"
    //         className="mt-[20px] "
    //         onClick={() => {
    //           setCustomAttributes([
    //             ...customAttributes,
    //             { name: "", value: "" },
    //           ]);
    //         }}
    //       >
    //         + Add custom attribute
    //       </button>
    //       {customAttributes?.map((item, index) => (
    //         <div
    //           key={index}
    //           className="w-full flex flex-col mt-[28px] border-t border-gray-500 pt-[28px]"
    //           id="description"
    //         >
    //           <label
    //             className="text-sm required font-normal ml-[8px] text-gray-800 not-italic"
    //             htmlFor="description"
    //           >
    //             Custom attribute
    //           </label>
    //           <div className="flex justify-between gap-4">
    //             <InputBox
    //               disabled={loading}
    //               value={item?.name}
    //               placeholder={"Custom attribute name"}
    //               onChange={(e) => {
    //                 let temp = [...customAttributes];
    //                 temp[index].name = e.target.value;
    //                 setCustomAttributes(temp);
    //               }}
    //             />
    //             <button
    //               className="btn-sec btn-md h-[48px]"
    //               data-tooltip-id="tooltip"
    //               data-tooltip-content={"Delete"}
    //               onClick={() => {
    //                 let temp = [...customAttributes];
    //                 temp.splice(index, 1);
    //                 setCustomAttributes(temp);
    //               }}
    //             >
    //               <svg
    //                 xmlns="http://www.w3.org/2000/svg"
    //                 fill="none"
    //                 viewBox="0 0 24 24"
    //                 strokeWidth={1.5}
    //                 stroke="red"
    //                 className="w-5 h-5"
    //               >
    //                 <path
    //                   strokeLinecap="round"
    //                   strokeLinejoin="round"
    //                   d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
    //                 />
    //               </svg>
    //             </button>
    //           </div>
    //           <div className="mt-[28px]">
    //             <TextAreaBox
    //               disabled={loading}
    //               placeholder={"Description..."}
    //               size="200px"
    //               value={item?.value}
    //               onChange={(e) => {
    //                 let temp = [...customAttributes];
    //                 temp[index].value = e.target.value;
    //                 setCustomAttributes(temp);
    //               }}
    //             />
    //           </div>
    //           <button
    //             className="mt-[20px] btn-primary py-2 px-4 rounded-full"
    //             onClick={update_template}
    //             disabled={
    //               loading ||
    //               customAttributes[index].name === "" ||
    //               customAttributes[index].value === ""
    //             }
    //           >
    //             {loading ? "Saving..." : "Save"}
    //           </button>
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
  ];

  return (
    <core.JobFormContext.Provider value={errorMemo}>
      {data && (
        <>
          {data?.job_position && (
            <>
              <div className="flex items-center">
                <h6 className="text-gray-800 text-base not-italic font-medium">
                  {data.job_position}
                </h6>
                {data?.owner != "undefined" && data?.owner ? (
                  <p className="text-gray-600 text-sm ml-2">{`Owner - ${data?.owner}`}</p>
                ) : (
                  ""
                )}
              </div>
            </>
          )}
          {showType && data?.template_type && (
            <h6 className="text-gray-700 text-sm not-italic font-semibold">
              {data.template_type}
            </h6>
          )}

          <div className="flex flex-col gap-4">
            {/* <EdiText type="text" value={"value"} onSave={()=>{}} /> */}
            {sections.map((section, index) => (
              <div key={index}>
                <h6
                  onClick={() => toggleSection(section?.key)}
                  className="text-gray-800 text-sm not-italic font-semibold mb-2 flex gap-2 items-center hover:font-bold cursor-pointer group"
                >
                  {section.name}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-4 h-4 group-hover:animate-pulse group-hover:stroke-2 transition-all duration-300 ease-in-out"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m19.5 8.25-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </h6>
                {showSection.includes(section?.key) ? section.content : null}
              </div>
            ))}
          </div>
        </>
      )}
    </core.JobFormContext.Provider>
  );
};

export default JobTempData;
