import React, { useState } from "react";

function QuestionsModalTest({ questions }) {
  const getLevel = (item) => {
    if (item?.level_tag?.includes("Hard")) {
      return "#FF475D";
    } else if (item?.level_tag?.includes("Easy")) {
      return "#00AC84";
    } else return "#FF9500";
  };

  const [showAns, setShowAns] = useState(null);

  return (
    <div className="col-xs-12 no-padding">
      <div className="col-xs-12 no-padding modal-close-button">
        <h4 className="col-xs-12 no-padding heading-text">Interview Q/A</h4>
        <button data-dismiss="modal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7.00195 7L17.002 17"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 17L17 7"
              stroke="#252E4B"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div className="question-answer-section col-xs-12 no-padding">
        {questions.map((item, index) => (
          <div className="question col-xs-12 no-padding" key={index}>
            <span
              className="question-number col-xs-1 no-padding"
              style={{ width: "5%" }}
            >{`${index + 1}.`}</span>
            <div className="col-xs-10 no-padding" style={{ width: "90%" }}>
              <span className="question-text">{item?.question}</span>

              <div className="col-xs-12 no-padding questions-tag">
                <span className="difficulty-tag ">
                  <span
                    className="color"
                    style={{ backgroundColor: getLevel(item) }}
                  ></span>
                  {item?.level_tag}
                </span>

                {item?.subject_tag?.map((data, index) => (
                  <span
                    className="subject-tag"
                    key={index}
                    // style={{
                    //   border: "1px solid grey",
                    //   borderRadius: "7px",
                    //   marginLeft: "15px",
                    //   marginBottom:"10px"
                    // }}
                  >
                    {data}
                  </span>
                ))}
                {item?.topic_tag?.map((data, idx) => (
                  <span
                    key={idx}
                    className="subject-tag"
                    // style={{
                    //   border: "1px solid grey",
                    //   borderRadius: "7px",
                    //   marginLeft: "15px",
                    //   marginBottom:"10px"
                    // }}
                  >
                    {data}
                  </span>
                ))}
              </div>

              {index === showAns && (
                <div className="col-xs-12 no-padding">
                  <span className="col-xs-12 no-padding sample-answer-tag">
                    Sample answer
                  </span>

                  <div className="col-xs-12 no-padding text-section">
                    <div
                      className="col-xs-12 no-padding header-sample-ans"
                      style={{
                        background:
                          "var(--moray-eel-green-1, rgba(0, 172, 133, 0.10))",
                      }}
                    >
                      <span className="header-tag">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          fill="none"
                        >
                          <path
                            d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
                            fill="#00AC85"
                          />
                          <path
                            d="M6.61719 10.9985L9.53562 13.917L15.3828 8.08008"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        Best answer
                      </span>
                      <span>
                        Score{" "}
                        <span
                          style={{
                            borderRadius: "34px",
                            background: "var(--gray-gray-1, #FFF)",
                            boxShadow:
                              "0px 1px 2px 0.5px rgba(62, 72, 84, 0.12)",
                            padding: "8px 11px",
                            marginLeft: "8px",
                          }}
                        >
                          7-10
                        </span>
                      </span>
                    </div>
                    <div className="col-xs-12 no-padding answer-text">
                      <span>{item?.sample_answer} </span>
                      <div className="col-xs-12 no-padding questions-tag">
                        <span
                          className="subject-tag"
                          style={{ display: "flow-root", fontStyle: "italic" }}
                        >
                          <span className="col-xs-12 no-padding">
                            Score explaination :-
                          </span>
                          {item?.high_answer_reason}
                        </span>
                      </div>
                    </div>
                  </div>
                  {item.sample_answer_mid && (
                    <div className="col-xs-12 no-padding text-section">
                      <div
                        className="col-xs-12 no-padding header-sample-ans"
                        style={{
                          background: "var(--orange-orange-1, #FFF5E6)",
                        }}
                      >
                        <span className="header-tag">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <path
                              d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
                              fill="#FF9500"
                            />
                            <path
                              d="M9.19531 14.2454V9.16535C9.19531 8.96535 9.25531 8.77035 9.36531 8.60535L10.7303 6.57535C10.9453 6.25035 11.4803 6.02035 11.9353 6.19035C12.4253 6.35535 12.7503 6.90535 12.6453 7.39535L12.3853 9.03035C12.3653 9.18035 12.4053 9.31535 12.4903 9.42035C12.5753 9.51535 12.7003 9.57535 12.8353 9.57535H14.8903C15.2853 9.57535 15.6253 9.73535 15.8253 10.0154C16.0153 10.2854 16.0503 10.6354 15.9253 10.9904L14.6953 14.7354C14.5403 15.3554 13.8653 15.8604 13.1953 15.8604H11.2453C10.9103 15.8604 10.4403 15.7454 10.2253 15.5304L9.58531 15.0354C9.34031 14.8504 9.19531 14.5554 9.19531 14.2454Z"
                              fill="white"
                            />
                            <path
                              d="M7.605 8.18945H7.09C6.315 8.18945 6 8.48945 6 9.22945V14.2595C6 14.9995 6.315 15.2995 7.09 15.2995H7.605C8.38 15.2995 8.695 14.9995 8.695 14.2595V9.22945C8.695 8.48945 8.38 8.18945 7.605 8.18945Z"
                              fill="white"
                            />
                          </svg>
                          Average answer
                        </span>
                        <span>
                          Score{" "}
                          <span
                            style={{
                              borderRadius: "34px",
                              background: "var(--gray-gray-1, #FFF)",
                              boxShadow:
                                "0px 1px 2px 0.5px rgba(62, 72, 84, 0.12)",
                              padding: "8px 11px",
                              marginLeft: "8px",
                            }}
                          >
                            4-6
                          </span>
                        </span>
                      </div>
                      <div className="col-xs-12 no-padding answer-text">
                        <span>{item?.sample_answer_mid} </span>
                        <div className="col-xs-12 no-padding questions-tag">
                          <span
                            className="subject-tag"
                            style={{
                              display: "flow-root",
                              fontStyle: "italic",
                            }}
                          >
                            <span className="col-xs-12 no-padding">
                              Score explaination :-
                            </span>
                            {item?.mid_answer_reason}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  {item.sample_answer_low && (
                    <div className="col-xs-12 no-padding text-section">
                      <div
                        className="col-xs-12 no-padding header-sample-ans"
                        style={{ background: "var(--red-red-1, #FFEDEF)" }}
                      >
                        <span className="header-tag">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            fill="none"
                          >
                            <path
                              d="M11 21.3125C16.6719 21.3125 21.3125 16.6719 21.3125 11C21.3125 5.32812 16.6719 0.6875 11 0.6875C5.32812 0.6875 0.6875 5.32812 0.6875 11C0.6875 16.6719 5.32812 21.3125 11 21.3125Z"
                              fill="#FF475D"
                            />
                            <path
                              d="M12.8035 8.05055V13.1305C12.8035 13.3305 12.7435 13.5255 12.6335 13.6905L11.2685 15.7205C11.0535 16.0455 10.5185 16.2755 10.0635 16.1055C9.57354 15.9405 9.24854 15.3905 9.35354 14.9005L9.61354 13.2655C9.63354 13.1155 9.59354 12.9805 9.50854 12.8755C9.42354 12.7805 9.29854 12.7205 9.16354 12.7205H7.10854C6.71354 12.7205 6.37354 12.5605 6.17354 12.2805C5.98354 12.0105 5.94854 11.6605 6.07354 11.3055L7.30354 7.56055C7.45854 6.94055 8.13354 6.43555 8.80354 6.43555H10.7535C11.0885 6.43555 11.5585 6.55055 11.7735 6.76555L12.4135 7.26055C12.6585 7.45055 12.8035 7.74055 12.8035 8.05055Z"
                              fill="white"
                            />
                            <path
                              d="M14.3947 14.105H14.9097C15.6847 14.105 15.9997 13.805 15.9997 13.065V8.04C15.9997 7.3 15.6847 7 14.9097 7H14.3947C13.6197 7 13.3047 7.3 13.3047 8.04V13.07C13.3047 13.805 13.6197 14.105 14.3947 14.105Z"
                              fill="white"
                            />
                          </svg>
                          Bad answer
                        </span>
                        <span>
                          Score{" "}
                          <span
                            style={{
                              borderRadius: "34px",
                              background: "var(--gray-gray-1, #FFF)",
                              boxShadow:
                                "0px 1px 2px 0.5px rgba(62, 72, 84, 0.12)",
                              padding: "8px 11px",
                              marginLeft: "8px",
                            }}
                          >
                            0-3
                          </span>
                        </span>
                      </div>
                      <div className="col-xs-12 no-padding answer-text">
                        <span>{item?.sample_answer_low} </span>
                        <div className="col-xs-12 no-padding questions-tag">
                          <span
                            className="subject-tag"
                            style={{
                              display: "flow-root",
                              fontStyle: "italic",
                            }}
                          >
                            <span className="col-xs-12 no-padding">
                              Score explaination :-
                            </span>
                            <span className="col-xs-12 no-padding">
                              {item?.low_answer_reason}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div
              className="col-xs-1 no-padding right-side-icon"
              style={{ width: "5%", display: "flex", justifyContent: "center" }}
            >
              {index === showAns ? (
                <span
                  onClick={(e) => setShowAns(null)}
                  style={{ cursor: "pointer" }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              ) : (
                <span
                  onClick={(e) => setShowAns(index)}
                  style={{ cursor: "pointer" }}
                >
                  {" "}
                   {" "}
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.5 9L12 14.4844L17.5 9"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default QuestionsModalTest;
