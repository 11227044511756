import React from "react";

export default function Messaging() {
  return (
    <div className="col-xs-12 no-padding" id="general-setting">
      <div className="col-xs-12 rounded-[12px] sub-sections py-[22px]">
        <div className="flex flex-col">
          <span className="text-[16px] text-[#16182F] font-medium not-italic">
            Messaging
          </span>
          <span className="text-[14px] text-textColor1 font-normal not-italic leading-[22px]">
            Connect and communicate: manage your messaging
          </span>
        </div>
        <span className="col-xs-12 no-padding text-[#16182F] text-[16px] font-medium not-italic mt-[18px]">
          Who should be able to message you?
        </span>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input type="radio" name="message" />
          <label
            className="text-[#252E4B] text-[16px] font-normal not-italic"
            htmlFor=""
          >
            Companies I applied
          </label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input type="radio" name="message" />
          <label
            className="text-[#252E4B] text-[16px] font-normal not-italic"
            htmlFor=""
          >
            Companies I saved
          </label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <input type="radio" name="message" />
          <label
            className="text-[#252E4B] text-[16px] font-normal not-italic"
            htmlFor=""
          >
            Anyone on SproutsAI
          </label>
        </div>
        <div className="col-xs-12 no-padding checkbox-sec">
          <button
            disabled
            className="border border-button border-[#DADAE4] py-[8px] px-[14px] mt-[18px] bg-[#EFF0F2] rounded-[8px] shadow-jobPostButtonShadow"
          >
            <span className="text-[16px] text-[#5F6989] font-normal not-italic">
              Save changes{" "}
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
