import { createAsyncThunk } from "@reduxjs/toolkit";
const core = require("../../lib/core");

export const fetchDashboardData = createAsyncThunk(
  "dashboardData/fetchDashboardData",
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_DASHBOARD + "?page=" + payload,
        1,
      );
      const data = list?.data?.content; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

export const fetchDashboardTask = createAsyncThunk(
  "taskList/fetchTaskList",
  async (payload, { rejectWithValue }) => {
    try {
      const list = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_TASKS,
        1,
      );
      const data = list?.data?.tasks; // Replace this with the actual response data from the API
      return data;
    } catch (error) {
      // Handle errors and return the error message using rejectWithValue
      return rejectWithValue(error.message);
    }
  },
);

// Fetch All Goals
export const fetchAllGoals = createAsyncThunk(
  "goalsList/fetchAllGoals",
  async (_, { rejectWithValue }) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.TASK_API.GET_ALL_GOALS,
      );
      const data = response?.data; // Extract actual data from response
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Fetch All Goal Activity Options
export const fetchAllGoalsActivity = createAsyncThunk(
  "goalsList/fetchAllGoalsActivity",
  async (_, { rejectWithValue }) => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.TASK_API.GET_ALL_GOALS_ACTIVITY,
      );
      const data = response?.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);

// Service to create a new goal
export const createGoal = createAsyncThunk(
  "goals/createGoal",
  async (goalData, { rejectWithValue }) => {
    try {
      // Call the API to create a new goal
      const response = await core.API(
        core.API_METHODS.POST,
        core.TASK_API.POST_CREATE_GOAL,
        1,
        goalData,
      );

      // Extract and return the relevant data from the response
      const data = response?.data;
      return data;
    } catch (error) {
      // Return the error message if the API call fails
      return rejectWithValue(error.message);
    }
  },
);

// Service to Fetch All Tasks Count
export const fetchAllTasks = createAsyncThunk(
  "tasks/fetchAllTasks",
  async (_, { rejectWithValue }) => {
    console.log("fetchAllTasks");

    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_RECRUITER_TASKS,
        1,
        {},
      );
      const data = response?.data;

      if (data) {
        const {
          newApplicantCount,
          scorecardCount,
          sequenceLogsCount,
          availabilityCount,
        } = data;

        return {
          newApplicantCount,
          scorecardCount,
          sequenceLogsCount,
          availabilityCount,
        };
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
