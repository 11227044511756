/*
props structure 
props = {
    dropdownText : String
    dropdownData : List
}
*/
import { useEffect, useState } from "react";
import $ from "jquery";
import { useNavigate } from "react-router-dom";
import InitialsLogo from "../InitialsLogo";
import CandidateDrawer from "../../recruiter/candidate/CandidateDrawer";
const core = require("../../../lib/core");

export default function RecruiterNewSearchBar(props) {
  const [list, setList] = useState([]);
  const [hide, setHide] = useState([]);
  useEffect(() => {}, []);

  $(document).on("click", function (e) {
    if (
      $(e.target).is("#" + "recruiter_searchbar" + " .auto-complete-input") ===
        false &&
      $(e.target).is("#" + "recruiter_searchbar" + " .search-menu") === false
    ) {
      setHide(true);
    } else {
      setHide(false);
    }
  });

  // const [searchItem, setSearchItem] = useState()
  const nav = useNavigate();

  const getSearchResult = (searchItem) => {
    if (searchItem?.type == "Job" || searchItem?.type === "Location") {
      nav(`/job/active/${searchItem?._id}/description`);
    }
    if (searchItem?.type == "Candidate") {
      nav(`/job/${searchItem?.jobId}/candidate/${searchItem?._id}`);
    }
    if (searchItem?.type == "Company") {
      nav(`/company-profile/${searchItem?._id}`);
    }
    if (searchItem?.type === "Location") {
      nav(`/job/active/${searchItem?._id}/description`);
    } else {
    }
  };

  // function searchList(term) {
  //   try {
  //     setHide(false);
  //     if (term?.length < 1 && false) {
  //     } else {
  //       setTimeout(async () => {
  //         let response = await core.API(
  //           core.API_METHODS.GET,
  //           core.RECRUITER_API.GET_UNIVERSAL_SEARCH + "?key=" + term,
  //           1,
  //           {}
  //         );
  //         if (response?.data.results.length > 0) {
  //           setList(response?.data?.results);
  //         } else {
  //           setList([{ name: "No Option" }]);
  //         }
  //       }, 0);
  //     }
  //   } catch (err) {}
  // }

  let timeoutId;

  const searchList = (searchTerm) => {
    try {
      setHide(false);

      clearTimeout(timeoutId);

      timeoutId = setTimeout(async () => {
        if (searchTerm?.length >= 1) {
          const response = await core.API(
            core.API_METHODS.POST,
            core.RECRUITER_API.GET_UNIVERSAL_SEARCH,
            1,
            {
              key: searchTerm,
              searchFor: activeSearch.toLowerCase() || "",
            },
          );

          if (response?.data.length > 0) {
            setList(response?.data);
            // console.log("response  stored",list)
          } else {
            setList([{ name: "No Option" }]);
          }
        }
      }, 1000);
    } catch (err) {
      console.error("Error in searchList:", err);
    }
  };

  // const [activeSearch, setActiveSearch] = useState([]);
  const [activeSearch, setActiveSearch] = useState("");

  const [buttonArray, setButtonArray] = useState([
    "Jobs",
    "Candidates",
    "Companies",
    "Locations",
  ]);

  const [showCandidate, setShowCandidate] = useState(false);
  const [candId, setCandId] = useState("");

  return (
    // <form className="" id="recruiter_searchbar">
    <div
      className="relative h-12 z-100 w-[calc(33.333%+30px)] bg-[#F3F5F9] rounded-lg  flex items-center focus-within:bg-[#FFF] focus-within:border-b focus-within:rounded-t-[12px] focus-within:rounded-b-[0] focus-within:border-b-[#E1E1EE]  focus-within:shadow-searchboxShadow"
      id="recruiter_searchbar"
    >
      <div className="auto-complete-input">
        <ul
          className={`search-menu absolute w-[100%]   z-[100] max-h-[400px] overflow-y-auto top-[48px] rounded-b-[12px] p-[12px] scrollbar-hide bg-[#FFF] shadow-searchboxShadow ${
            hide ? " hide" : "auto-complete-input"
          }`}
        >
          <div className="auto-complete-input">
            <span className="text-[#5F6989] text-[12px] not-italic font-normal">
              I'm searching for
            </span>
            <div className="mt-[8px]">
              {buttonArray.map((item, idx) => (
                <button
                  key={"key" + idx}
                  className={`auto-complete-input border border-[#DADAE4] rounded-[166px] px-[10px] py-[6.5px]  mr-[6px] ${activeSearch?.includes(item) ? "bg-[#EFF0F2]" : "bg-[#FFF]"}`}
                  onClick={() => {
                    // If the clicked category is already active, clear the search
                    if (activeSearch === item) {
                      setActiveSearch("");
                    } else {
                      // Otherwise, set the new active search category
                      setActiveSearch(item);
                    }

                    // Trigger search with current input value
                    const inputElement = document.querySelector(
                      "#recruiter_searchbar .auto-complete-input",
                    );
                    if (inputElement) {
                      searchList(inputElement.value);
                    }
                  }}
                >
                  {item}
                </button>
              ))}
            </div>
          </div>

          {(!activeSearch || activeSearch === "Jobs") && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal ">
                Jobs
              </span>
              {list
                ?.filter((item) => item.type === "Job")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => {
                      $(
                        "#" + "recruiter_searchbar" + " .auto-complete-input",
                      ).val(item?.name);
                      getSearchResult(item);
                      // setSearchItem(item)
                      // getDirectdata()
                    }}
                  >
                    <div className="flex items-center">
                      {
                        <InitialsLogo
                          width={"26px"}
                          height={"26px"}
                          style={{ display: "inline-block" }}
                          str={item?.name}
                        />
                      }

                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}

          {(!activeSearch || activeSearch === "Candidates") && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal ">
                Candidates
              </span>
              {list
                ?.filter((item) => item.type === "Candidate")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => {
                      setShowCandidate(true);
                      setCandId(item?.matchedProfiles[0]?._id);
                    }}
                  >
                    <div className="flex items-center  p-1">
                      {
                        <InitialsLogo
                          width={"26px"}
                          height={"26px"}
                          style={{ display: "inline-block" }}
                          str={item?.first_name}
                        />
                      }

                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.first_name}
                      </span>
                      {/* <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.source}
                      </span> */}
                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic text-blue-800  font-normal">
                        {" "}
                        {item?.title}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}

          {(!activeSearch || activeSearch === "Companies") && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal">
                Companies
              </span>
              {list
                ?.filter((item) => item?.type === "Company")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => {
                      $(
                        "#" + "recruiter_searchbar" + " .auto-complete-input",
                      ).val(item.name);
                      // console.log($("#" + "recruiter_searchbar" + " .auto-complete-input"))
                      getSearchResult(item);
                      // setSearchItem(item)
                      // getDirectdata()
                    }}
                  >
                    <div className="flex items-center">
                      {
                        <InitialsLogo
                          width={"26px"}
                          height={"26px"}
                          style={{ display: "inline-block" }}
                          str={item?.name}
                        />
                      }

                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {" "}
                        {item?.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}

          {(!activeSearch || activeSearch === "Locations") && (
            <div className="mt-[18px]">
              <span className="text-[#5F6989] text-[12px] not-italic font-normal">
                Locations
              </span>
              {list
                ?.filter((item) => item?.type === "Job")
                .map((item, i) => (
                  <li
                    key={i}
                    className="mt-[12px] cursor-pointer"
                    onClick={() => getSearchResult(item)}
                  >
                    <div className="flex items-center">
                      <InitialsLogo
                        width={"26px"}
                        height={"26px"}
                        style={{ display: "inline-block" }}
                        str={item?.name}
                      />
                      <span className="ml-[8px] text-[#252E4B] text-[12px] not-italic font-normal">
                        {item?.name}
                      </span>
                    </div>
                  </li>
                ))}
            </div>
          )}
        </ul>
      </div>
      <svg
        className="absolute left-[16px] top-[50%] transform -translate-y-1/2"
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g id="Group 427319278">
          <path
            id="Star 8"
            d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
            fill="#5F6989"
          />
          <path
            id="Star 9"
            d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
            fill="#5F6989"
          />
          <path
            id="Star 10"
            d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
            fill="#5F6989"
          />
        </g>
      </svg>
      <input
        className="h-[48px] w-full pl-[50px] rounded-lg placeholder-[#5F6989] placeholder-[14px] bg-transparent p-[6px]  outline-none auto-complete-input border-none"
        type="text"
        onFocus={() => setHide(false)}
        placeholder="Search jobs, companies, candidates, locations...."
        onChange={(e) => {
          searchList(e.target.value);
        }}
      />
      {showCandidate && (
        <CandidateDrawer setShowCandidate={setShowCandidate} candId={candId} />
      )}
    </div>
  );
}
