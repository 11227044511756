// import { API, graphqlOperation } from "aws-amplify";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/all/header/Header";
import SideBar from "../../components/all/SideBar";
import Menu from "../../components/all/header/Menu";

export default function Message() {
  // const { user, roleId } = useAppContext(); // User details from session
  const [jobs, setJobs] = useState([]);
  const [appliedJobs, setAppliedJobs] = useState([]);
  const [jobLen, setJobLen] = useState();
  const [companyList, setCompanyList] = useState([]);
  useEffect(() => {
    fetchJobs();
    getAppliedJobsList();
    fetchCompany();
  }, [jobLen]);

  // console.log(roleId);

  const fetchCompany = async () => {
    // try {
    //   await API.graphql(graphqlOperation(listCompanies)).then((data) => {
    //     console.log("companies", data.data.listCompanies.items);
    //     setCompanyList(data.data.listCompanies.items);
    //   });
    // } catch (error) {
    //   console.log("error on fetching Jobs", error);
    // }
  };

  const getAppliedJobsList = async () => {
    // try {
    //   await API.graphql(
    //     graphqlOperation(jobsAppliedByCandidate, { candidateID: roleId })
    //   ).then((data) => {
    //     console.log("this is job", data.data.jobsAppliedByCandidate.items);
    //     setAppliedJobs(data.data.jobsAppliedByCandidate.items);
    //   });
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const fetchJobs = async () => {
    // setJobs(getAppliedJobsList(roleId));
    setJobLen(jobs.length);
    console.log("Saved Lists - ", jobs, jobLen);
  };

  console.log(jobs.length);

  return (
    <div className="container-fluid page messages candidate-page">
      <Header showOnlyLogo={false} menu={<Menu />} />
      <SideBar />
      <main className="main-content no-padding col-xs-12"></main>
      <div className="col-md-4" id="job-list">
        {appliedJobs.map((job) => (
          <div className="col-xs-12 jobPost no-padding">
            <div className="section-1 no-padding pb-3 col-xs-12">
              {companyList.filter(
                (num) => num.id == job.jobPost.companyID,
              )[0] && (
                <span className="company_name">
                  {
                    companyList.filter(
                      (num) => num.id == job.jobPost.companyID,
                    )[0].companyName
                  }
                </span>
              )}
            </div>
            <div className="col-xs-12 section-2 no-padding">
              <div className="col-xs-12 no-padding text-section">
                <h4 className="col-xs-12 no-padding position">
                  {job.jobPost.position}
                </h4>
                <h5 className="col-xs-12 no-padding location">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
                      stroke="#767676"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 2C9.87827 2 7.84344 2.84285 6.34315 4.34315C4.84285 5.84344 4 7.87827 4 10C4 11.892 4.402 13.13 5.5 14.5L12 22L18.5 14.5C19.598 13.13 20 11.892 20 10C20 7.87827 19.1571 5.84344 17.6569 4.34315C16.1566 2.84285 14.1217 2 12 2V2Z"
                      stroke="#767676"
                      strokeWidth="1.8"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  {job.jobPost.location}
                </h5>
                <h5 className="col-xs-12 no-padding job-status">
                  <span className="job-status-text">
                    {job.status == "NEW_APPLICANT" && (
                      <span>Application in progress</span>
                    )}
                    {job.status == "INTERVIEW" && (
                      <span>Application in progress</span>
                    )}
                    {job.status == "SCREENING" && (
                      <span>Application in progress</span>
                    )}
                    {job.status == "HIRED" && <span>Application selected</span>}
                    {job.status == "REJECTED" && (
                      <span>Application Rejected</span>
                    )}
                  </span>
                </h5>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="col-md-8 chat">
        <div className="col-xs-12 no-padding chat-section"></div>
        <div className="write-section col-xs-12 no-padding">
          <input className="col-xs-11" type={"text"} />
          <button className="col-xs-1 send">Send</button>
        </div>
      </div>
    </div>
  );
}
