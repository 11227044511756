import { useState, useEffect } from "react";
import $ from "jquery";
import { PropagateLoader } from "react-spinners";
import InputBox from "../../../layouts/InputBox";

export default function Autocomplete({
  type,
  defaultValue,
  value,
  idName,
  name,
  data,
  generate_template,
  searchFunction,
  onEnterFunction,
  addFunction,
  onSelectOption = () => {},
  required,
  selectionFunction,
  disabled,
  searchType,
  setShowCompanyPage,
  showCompany,
  companyNew,
  initialSelectedEmails, // Add prop for initial emails
  className = "",
  inputClassName = "",
  listClass = "",
  ...props
}) {
  const [hide, setHide] = useState(true);

  useEffect(() => {
    $(document).on("click", function (e) {
      if ($(e.target).is("#" + idName + " .auto-complete-input") === false) {
        setHide(true);
      } else {
        setHide(false);
      }
    });

    return () => {
      $(document).off("click");
    };
  }, [idName]);

  return (
    <div
      id={idName}
      className={`col-xs-12 no-padding auto-complete relative ${className}`}
    >
      <InputBox
        className={"col-xs-12 no-padding auto-complete-input " + inputClassName}
        type={type}
        name={name}
        value={value}
        required={required}
        placeholder={defaultValue}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onKeyDown={onEnterFunction}
        onClick={(e) => {
          if (!disabled) {
            searchFunction(e.target.value);
            setHide(false);
          }
        }}
        disabled={disabled}
        onChange={(e) => {
          console.log("e.target.value", e.target.value, disabled);
          if (!disabled) {
            console.log("e.target.value", e.target.value, disabled);
            searchFunction(e.target.value);
            selectionFunction(name, e.target.value);
          }
        }}
      />
      {!hide &&
        data?.length >= (searchType === "template" ? 0 : 1) &&
        value?.length > 0 && (
          <ul
            className={
              "auto-complete-list absolute bg-white no-list-style py-[20px] rounded-lg shadow-menuOptionShadow left-0 " +
              listClass
            }
          >
            {data[0] === "loader" ? (
              <span className="flex justify-center items-center">
                <PropagateLoader color="blue" />
              </span>
            ) : (
              <>
                {data?.map((item, i) => (
                  <li
                    key={i}
                    className="match-value px-[20px] py-[5px]"
                    onClick={() => {
                      selectionFunction(name, item?.name ?? item);
                      if (searchType === "company") {
                        setShowCompanyPage(false);
                      }
                      onSelectOption(item);
                      setHide(true);
                    }}
                  >
                    {item?.name ? item?.name : item?.email ? item?.email : item}
                    {"  "}
                    {item.source ? `(${item.source})` : ""}
                  </li>
                ))}
                {searchType === "company" && data?.length === 0 && (
                  <li
                    className="company-add-button"
                    onClick={() => {
                      setShowCompanyPage(true);
                    }}
                  >
                    + Add company
                  </li>
                )}
                {searchType === "template" && data?.length === 0 && (
                  <li
                    className="pl-[20px] py-[5px] cursor-pointer"
                    onClick={() => {
                      generate_template(value);
                    }}
                  >
                    <span className="flex items-center text-[16px]">
                      <svg
                        className="mr-2"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g id="Group 427319278">
                          <path
                            id="Star 8"
                            d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
                            fill="#5F6989"
                          ></path>
                          <path
                            id="Star 9"
                            d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
                            fill="#5F6989"
                          ></path>
                          <path
                            id="Star 10"
                            d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
                            fill="#5F6989"
                          ></path>
                        </g>
                      </svg>
                      Generate template for ({" "}
                      <b className="text-[#1369E9] ml-1 mr-1 ">{value}</b> )
                    </span>
                  </li>
                )}
              </>
            )}
          </ul>
        )}
    </div>
  );
}
