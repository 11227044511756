import { COLORS } from "./analyticsChartsData";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from "recharts";
import React, { useState, useEffect, useCallback } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./Cards";
import { isArrayEmpty, isObjectEmpty } from "./helper";
import DynamicLineChart from "./Charts/DynamicLineChart";
import DynamicBarChart from "./Charts/DynamicBarChart";
import Modal from "../../../components/modal/Modal";
import { BiExpandAlt } from "react-icons/bi";
import ExpandButton from "./ExpandButton";
import { IoFilterSharp } from "react-icons/io5";
import { MdFilterListOff } from "react-icons/md";
import FilterButton from "./FilterButton";
import FilterModal from "./FilterModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { GrPowerReset } from "react-icons/gr";

const AllCharts = ({
  applicantsPerRole = [],
  jdProcessedData = [],
  ActiveJobsResponse = [],
  resumeProcessedData = [],
  applicationSource = {},
  newapplicantsReviewed = [],
}) => {
  const [loading, setLoading] = useState(false);
  const [transformedDataState, setTransformedData] = useState([]);
  const [candidatePipelineData, setCandidatePipelineData] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [dynamicStageColors, setDynamicStageColors] = useState({});
  const [newApplications, setNewApplications] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalMessage, setModalMessage] = useState(null); // For messages
  const [visibleFilter, setVisibleFilter] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [appicantsPerRoleData, setApplicantPerRoleData] = useState([]);
  const [visibleNewApplicationsFilter, setVisibleNewApplicationsFilter] =
    useState(false);
  const [visibleTransformedDataFilter, setVisibleTransformedDataFilter] =
    useState(false);
  const [
    isApplicantsPerRoleSearchCleared,
    setIsApplicantsPerRoleSearchCleared,
  ] = useState(false);
  const [
    isCandidatePipelineSearchCleared,
    setIsCandidatePipelineSearchCleared,
  ] = useState(false);
  const [isNewApplicationsSearchCleared, setIsNewApplicationsSearchCleared] =
    useState(false);

  const showModal = (chart, title, message = null) => {
    setModalTitle(title);
    setModalContent(chart);
    setModalMessage(message); // Set the message
    setVisibleModal(true);
  };

  const handleCancel = () => {
    setVisibleModal(false);
    setModalContent(null);
    setModalMessage(null); // Clear the message
  };

  const transformData = (data) => {
    const allStagesSet = new Set();
    const transformedArray = data.map((job) => {
      const transformedJob = { name: job.name };
      let stagesSum = 0;

      job.stages.forEach((stage) => {
        if (
          stage?.name?.trim() === "" ||
          stage?.name === undefined ||
          stage?.name === "undefined"
        )
          return;
        allStagesSet.add(stage.name);
        const stageKey = stage?.name?.toLowerCase().replace(/ /g, "");
        transformedJob[stageKey] = stage.value;
        stagesSum += stage.value;
      });

      if (stagesSum === 0) {
        return null;
      }

      return transformedJob;
    });

    const transformedData = transformedArray.filter((job) => job !== null);

    const allStagesArray = Array.from(allStagesSet);

    // Define custom order
    const customOrder = [
      "Application Review",
      ...allStagesArray.filter(
        (stage) => !["Application Review", "Hired", "Rejected"].includes(stage),
      ),
      "Hired",
      "Rejected",
    ];

    return { transformedData, allStages: customOrder };
  };

  const assignColorsToStages = (stages) => {
    const predefinedColors = {
      "Application Review": "#FFA500", // Orange
      Screening: "#1E90FF", // DodgerBlue
      Interview: "#8A2BE2", // BlueViolet
      Submitted: "#FFD700", // Gold
      Accepted: "#32CD32", // LimeGreen
      Hired: "#008000", // Green
      Rejected: "#FF0000", // Red
    };

    const stageColors = {};

    stages.forEach((stage, index) => {
      if (predefinedColors[stage]) {
        stageColors[stage] = predefinedColors[stage];
      } else {
        stageColors[stage] = COLORS[index % COLORS.length]; // Use fallback colors if not predefined
      }
    });

    return stageColors;
  };

  useEffect(() => {
    setLoading(true);
    const { transformedData, allStages } = transformData(ActiveJobsResponse);
    console.log(transformedData, "transformedData");
    setCandidatePipelineData(transformedData);
    setTransformedData(transformedData);
    setAllStages(allStages);
    const dynamicColors = assignColorsToStages(allStages);
    setDynamicStageColors(dynamicColors);
    setNewApplications(newapplicantsReviewed);
    setApplicantPerRoleData(applicantsPerRole);
    setLoading(false);
  }, [ActiveJobsResponse, loading]);

  if (loading) {
    return null;
  }

  const truncateLabel = (label, maxLength = 10) => {
    return label.length > maxLength ? label.slice(0, maxLength) + "..." : label;
  };

  const applicationSourceData = Object.entries(applicationSource)
    .map(([key, value]) => ({
      name: key,
      value: parseFloat(value),
    }))
    .filter((data) => data.value >= 0.5);

  const hideFilterModal = () => {
    setVisibleFilter(false);
  };
  const filterByJobTitle = (data, searchName) => {
    return data?.filter((item) =>
      item?.role?.toLowerCase().includes(searchName?.toLowerCase()),
    );
  };
  const filterByJobName = (data, searchName) => {
    return data?.filter((item) =>
      item?.name?.toLowerCase().includes(searchName?.toLowerCase()),
    );
  };

  return (
    <div className="" style={{ backgroundColor: "rgb(243, 245, 249)" }}>
      <div className="w-full grid grid-cols-2 gap-[20px]">
        {!isArrayEmpty(appicantsPerRoleData) && (
          <Card>
            <CardHeader className="flex flex-row justify-between items-center">
              <h2 className="text-lg font-semibold">
                Applicants per Job Posting
              </h2>
              <div className="flex flex-row gap-x-1 items-center ">
                <FilterButton
                  onClick={() => setIsApplicantsPerRoleSearchCleared(true)}
                  Icon={GrPowerReset}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Clear Filter"}
                  IconclassName="scale-x-[-1]"
                />
                <FilterButton
                  onClick={() => setVisibleFilter(true)}
                  Icon={IoFilterSharp}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Filter"}
                />
                <ExpandButton
                  onClick={() => {
                    showModal(
                      <ResponsiveContainer width="99%" height={500}>
                        <BarChart data={appicantsPerRoleData}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="role"
                            angle={-45}
                            textAnchor="end"
                            tick={(props) => (
                              <g transform={`translate(${props.x},${props.y})`}>
                                <text
                                  x={0}
                                  y={0}
                                  dy={10}
                                  dx={0}
                                  textAnchor="end"
                                  transform="rotate(-45)"
                                  style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                                >
                                  {props.payload.value.length > 6
                                    ? `${props.payload.value.slice(0, 6)}...`
                                    : props.payload.value}
                                </text>
                              </g>
                            )}
                          />
                          <YAxis />
                          <Tooltip isAnimationActive={false} />
                          <Legend wrapperStyle={{ opacity: "0" }} />
                          <Bar dataKey="applicants" fill="#0088FE" />
                        </BarChart>
                      </ResponsiveContainer>,
                      "Applicants per Job Posting",
                    );
                  }}
                  Icon={BiExpandAlt}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Expand"}
                />
              </div>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="99%" height={300}>
                <BarChart data={appicantsPerRoleData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="role"
                    angle={-45}
                    textAnchor="end"
                    tick={(props) => (
                      <g transform={`translate(${props.x},${props.y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={10}
                          dx={0}
                          textAnchor="end"
                          transform="rotate(-45)"
                          style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                        >
                          {props.payload.value.length > 6
                            ? `${props.payload.value.slice(0, 6)}...`
                            : props.payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend wrapperStyle={{ opacity: "0" }} />
                  <Bar dataKey="applicants" fill="#0088FE" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}

        {!isArrayEmpty(jdProcessedData) && (
          <Card className="col-span-1">
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle>Number of JD Processed Per Month</CardTitle>
              <ExpandButton
                onClick={() => {
                  showModal(
                    <ResponsiveContainer width="100%" height={500}>
                      <LineChart data={jdProcessedData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          tickFormatter={(value) => {
                            const date = new Date(value);
                            return date.toLocaleDateString("default", {
                              month: "short",
                              year: "2-digit",
                            });
                          }}
                          interval={2}
                        />
                        <YAxis />
                        <Tooltip
                          labelFormatter={(value) => {
                            const date = new Date(value);
                            return date.toLocaleDateString("default", {
                              month: "long",
                              year: "numeric",
                            });
                          }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="job_count"
                          stroke="#8884d8"
                          name="Job Count"
                        />
                      </LineChart>
                    </ResponsiveContainer>,
                    "Number of JD Processed Per Month",
                  );
                }}
                Icon={BiExpandAlt}
                data-tooltip-id="my-tooltip-9"
                data-tooltip-content={"Expand"}
              />
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={jdProcessedData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value) => {
                      const date = new Date(value);
                      return date.toLocaleDateString("default", {
                        month: "short",
                        year: "2-digit",
                      });
                    }}
                    interval={2}
                  />
                  <YAxis />
                  <Tooltip
                    labelFormatter={(value) => {
                      const date = new Date(value);
                      return date.toLocaleDateString("default", {
                        month: "long",
                        year: "numeric",
                      });
                    }}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="job_count"
                    stroke="#8884d8"
                    name="Job Count"
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}

        {!isArrayEmpty(transformedDataState) && (
          <Card>
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle>Candidates in Pipeline Funnel</CardTitle>
              <div className="flex flex-row gap-x-1 items-center ">
                <FilterButton
                  onClick={() => setIsCandidatePipelineSearchCleared(true)}
                  Icon={GrPowerReset}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Clear Filter"}
                  IconclassName="scale-x-[-1]"
                />
                <FilterButton
                  onClick={() => setVisibleTransformedDataFilter(true)}
                  Icon={IoFilterSharp}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Filter"}
                />
                <ExpandButton
                  onClick={() => {
                    showModal(
                      <ResponsiveContainer width="99%" height={500}>
                        <BarChart data={transformedDataState} layout="vertical">
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis type="number" />
                          <YAxis
                            dataKey="name"
                            type="category"
                            width={120}
                            tick={({ x, y, payload }) => (
                              <text
                                x={x}
                                y={y}
                                dy={1}
                                textAnchor="end"
                                fill="#333"
                                fontSize={12}
                                fontFamily="Arial"
                              >
                                {truncateLabel(payload.value, 15)}
                              </text>
                            )}
                          />
                          <Tooltip isAnimationActive={false} />
                          <Legend />
                          {allStages.map((stage) => (
                            <Bar
                              key={stage}
                              dataKey={stage?.toLowerCase().replace(/ /g, "")}
                              name={stage}
                              stackId="stack"
                              fill={dynamicStageColors[stage]}
                            />
                          ))}
                        </BarChart>
                      </ResponsiveContainer>,
                      "Candidates in Pipeline Funnel",
                    );
                  }}
                  Icon={BiExpandAlt}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Expand"}
                />
              </div>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={transformedDataState} layout="vertical">
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis type="number" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    width={120}
                    tick={({ x, y, payload }) => (
                      <text
                        x={x}
                        y={y}
                        dy={1}
                        textAnchor="end"
                        fill="#333"
                        fontSize={12}
                        fontFamily="Arial"
                      >
                        {truncateLabel(payload.value, 15)}
                      </text>
                    )}
                  />
                  <Tooltip />
                  <Legend />
                  {allStages.map((stage) => (
                    <Bar
                      key={stage}
                      dataKey={stage?.toLowerCase().replace(/ /g, "")}
                      name={stage}
                      stackId="stack"
                      fill={dynamicStageColors[stage]}
                    />
                  ))}
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}

        {!isArrayEmpty(newApplications) && (
          <Card>
            <CardHeader className="flex flex-row justify-between items-center">
              <h2 className="text-lg font-semibold">
                New Applications (to be reviewed)
              </h2>
              <div className="flex flex-row gap-x-1 items-center ">
                <FilterButton
                  onClick={() => setIsNewApplicationsSearchCleared(true)}
                  Icon={GrPowerReset}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Clear Filter"}
                  IconclassName="scale-x-[-1]"
                />
                <FilterButton
                  onClick={() => setVisibleNewApplicationsFilter(true)}
                  Icon={IoFilterSharp}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Filter"}
                />
                <ExpandButton
                  onClick={() => {
                    showModal(
                      <ResponsiveContainer width="99%" height={500}>
                        <BarChart data={newApplications}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="role"
                            angle={-45}
                            textAnchor="end"
                            tick={(props) => (
                              <g transform={`translate(${props.x},${props.y})`}>
                                <text
                                  x={0}
                                  y={0}
                                  dy={16}
                                  textAnchor="end"
                                  transform="rotate(-45)"
                                  style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                                >
                                  {props.payload.value.length > 6
                                    ? `${props.payload.value.slice(0, 6)}...`
                                    : props.payload.value}
                                </text>
                              </g>
                            )}
                          />
                          <YAxis />
                          <Tooltip isAnimationActive={false} />
                          <Legend wrapperStyle={{ opacity: "0" }} />
                          <Bar
                            dataKey="applications"
                            fill="#00C49F"
                            name="New Applications"
                          />
                        </BarChart>
                      </ResponsiveContainer>,
                      "New Applications (to be reviewed)",
                    );
                  }}
                  Icon={BiExpandAlt}
                  data-tooltip-id="my-tooltip-9"
                  data-tooltip-content={"Expand"}
                />
              </div>
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="99%" height={300}>
                <BarChart data={newApplications}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="role"
                    angle={-45}
                    textAnchor="end"
                    tick={(props) => (
                      <g transform={`translate(${props.x},${props.y})`}>
                        <text
                          x={0}
                          y={0}
                          dy={16}
                          textAnchor="end"
                          transform="rotate(-45)"
                          style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                        >
                          {props.payload.value.length > 6
                            ? `${props.payload.value.slice(0, 6)}...`
                            : props.payload.value}
                        </text>
                      </g>
                    )}
                  />
                  <YAxis />
                  <Tooltip />
                  <Legend wrapperStyle={{ opacity: "0" }} />
                  <Bar
                    dataKey="applications"
                    fill="#00C49F"
                    name="New Applications"
                  />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}

        {!isArrayEmpty(resumeProcessedData) && (
          <Card className="col-span-1">
            <CardHeader className="flex flex-row justify-between items-center">
              <CardTitle>Number of Resume Processed Per Month</CardTitle>
              <ExpandButton
                onClick={() => {
                  showModal(
                    <ResponsiveContainer width="99%" height={500}>
                      <LineChart data={resumeProcessedData}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="date"
                          tickFormatter={(value) => {
                            const date = new Date(value);
                            return date.toLocaleDateString("default", {
                              month: "short",
                              year: "2-digit",
                            });
                          }}
                          interval={2}
                        />
                        <YAxis />
                        <Tooltip
                          labelFormatter={(value) => {
                            const date = new Date(value);
                            return date.toLocaleDateString("default", {
                              month: "long",
                              year: "numeric",
                            });
                          }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey="resumeCount"
                          stroke="#FFA500"
                          name="Resume Count"
                        />
                      </LineChart>
                    </ResponsiveContainer>,
                    "Number of Resume Processed Per Month",
                  );
                }}
                Icon={BiExpandAlt}
                data-tooltip-id="my-tooltip-9"
                data-tooltip-content={"Expand"}
              />
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={resumeProcessedData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="date"
                    tickFormatter={(value) => {
                      const date = new Date(value);
                      return date.toLocaleDateString("default", {
                        month: "short",
                        year: "2-digit",
                      });
                    }}
                    interval={2}
                  />
                  <YAxis />
                  <Tooltip
                    labelFormatter={(value) => {
                      const date = new Date(value);
                      return date.toLocaleDateString("default", {
                        month: "long",
                        year: "numeric",
                      });
                    }}
                  />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="resumeCount"
                    stroke="#FFA500"
                    name="Resume Count"
                  />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}

        {!isObjectEmpty(applicationSourceData) && (
          <Card>
            <CardHeader className="flex flex-row justify-between items-center">
              <h2 className="text-lg font-semibold">
                Application Source Breakdown
              </h2>
              <ExpandButton
                onClick={() => {
                  showModal(
                    <ResponsiveContainer width="99%" height={500}>
                      <PieChart>
                        <Pie
                          data={applicationSourceData}
                          cx="50%"
                          cy="50%"
                          labelLine={false}
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {applicationSourceData?.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip formatter={(value) => `${value}%`} />
                        <Legend />
                      </PieChart>
                    </ResponsiveContainer>,
                    "Application Source Breakdown",
                  );
                }}
                Icon={BiExpandAlt}
                data-tooltip-id="my-tooltip-9"
                data-tooltip-content={"Expand"}
              />
            </CardHeader>
            <CardContent>
              <ResponsiveContainer width="100%" height={300}>
                <PieChart>
                  <Pie
                    data={applicationSourceData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {applicationSourceData?.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip formatter={(value) => `${value}%`} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        )}

        {visibleModal && (
          <Modal
            heading={modalTitle}
            noBtns={true}
            message={modalMessage}
            subheading={""}
            modalDismiss={() => handleCancel()}
            className={"!w-[80vw]  !max-w-5xl"}
          >
            {modalContent}
          </Modal>
        )}

        <FilterModal
          data={applicantsPerRole}
          setData={setApplicantPerRoleData}
          visibleFilter={visibleFilter}
          setVisibleFilter={hideFilterModal}
          heading="Filter   Applicants per Job Posting"
          placeholder="Filter by job title"
          filterFunction={filterByJobTitle}
          clearSearchTerm={isApplicantsPerRoleSearchCleared}
          setClearSearchTerm={setIsApplicantsPerRoleSearchCleared}
        />

        <FilterModal
          data={candidatePipelineData}
          setData={setTransformedData}
          visibleFilter={visibleTransformedDataFilter}
          setVisibleFilter={setVisibleTransformedDataFilter}
          heading="Filter Candidates in Pipeline Funnel"
          placeholder="Filter by job title"
          filterFunction={filterByJobName}
          clearSearchTerm={isCandidatePipelineSearchCleared}
          setClearSearchTerm={setIsCandidatePipelineSearchCleared}
        />
        <FilterModal
          data={newapplicantsReviewed}
          setData={setNewApplications}
          visibleFilter={visibleNewApplicationsFilter}
          setVisibleFilter={setVisibleNewApplicationsFilter}
          heading="Filter New Applications (to be reviewed)"
          placeholder="Filter by job title"
          filterFunction={filterByJobTitle}
          clearSearchTerm={isNewApplicationsSearchCleared}
          setClearSearchTerm={setIsNewApplicationsSearchCleared}
        />
      </div>
      <ReactTooltip
        id="my-tooltip-9"
        noArrow={true}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: "400",
        }}
        place="top"
      />
    </div>
  );
};

export default AllCharts;
