import { createSlice } from "@reduxjs/toolkit";
import { fetchUserResources, fetchUserRole } from "../services";

const initialState = {
  isLoading: false,
  UserResources: [],
  UserRoles: [],
};

const UserInfoSlice = createSlice({
  name: "userInfoSlice",
  initialState,
  reducers: {
    // Add any other reducers you may need to manage the state
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserResources.fulfilled, (state, action) => {
        state.UserResources = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserResources.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchUserResources.pending, (state, action) => {
        state.isLoading = true;
      })

      .addCase(fetchUserRole.fulfilled, (state, action) => {
        state.UserRoles = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchUserRole.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(fetchUserRole.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

// Export actions
export const {} = UserInfoSlice.actions;

export default UserInfoSlice.reducer;
