import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
// import FormAutocomplete from "./Autocomplete";
import FormAutocomplete from "../../../recruiter/createJob/Autocomplete";
const core = require("../../../../lib/core");

export default function Skills({ criteriaSkilArray, setCriteriaSkilArray }) {
  const [criteria, setCriteria] = useState();
  const [value, setValue] = useState();
  const [listpreferSkill, setListpreferSkill] = useState([]);
  const [allCriterias, setAllCriterias] = useState([]);

  useEffect(() => {}, [criteriaSkilArray]);

  const handleKeyDown = (event) => {
    // event.preventDefault()
    if (event.key === "Enter") {
      // call your function here
      addcriteriaSkill();
    }
  };

  // addnewSkill
  function addnewSkill(criteria, value, event) {
    if (event.key === "Enter") {
      // call your function here
      if (
        value.length > 0 &&
        criteriaSkilArray
          .flatMap((obj) => obj.skills)
          .map((string) => string.label.toLowerCase())
          .includes(value.toLowerCase()) == false
      ) {
        let iter = null;
        var obj = criteriaSkilArray?.find((o, i) => {
          iter = i;
          return o.criteria == criteria;
        });
        if (obj) {
          criteriaSkilArray[iter].skills?.push({ label: value, value: 7 });
          setValue("");
        } else {
          setCriteriaSkilArray([
            ...criteriaSkilArray,
            {
              criteria: criteria,
              value: 7,
              skills: [{ label: value, value: 7 }],
            },
          ]);
        }
        // setReload(reload + 1);
        setValue("");
      } else {
        {
          value.length > 0 && toast.error("You already add this skill");
        }
      }
    }
  }

  function addcriteriaSkill(e) {
    // e.preventDefault()
    if (
      value.length > 0 &&
      criteriaSkilArray
        .flatMap((obj) => obj.keySkills)
        .map((string) => string.label.toLowerCase())
        .includes(value.toLowerCase()) == false
    ) {
      let iter = null;
      var obj = criteriaSkilArray?.find((o, i) => {
        iter = i;
        return o.label == criteria;
      });
      if (obj) {
        criteriaSkilArray[iter].keySkills?.push({ label: value, value: 7 });
        setValue("");
      } else {
        setCriteriaSkilArray([
          ...criteriaSkilArray,
          {
            label: criteria,
            value: 7,
            keySkills: [{ label: value, value: 7 }],
          },
        ]);
      }
      // setReload(reload + 1);
      setValue("");
    } else {
      {
        value.length > 0 && toast.error("You already add this skill");
      }
    }
  }

  function searchPreferCriteriaList(term) {
    try {
      if (term?.length < 1) {
        // setAllCriterias([]);
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.JOB_API.GET_ALL_CRITERIA + term,
            1,
            {},
          );
          if (response?.data?.criterias) {
            setAllCriterias(response?.data.criterias.map((d) => d.name));
          } else {
            setAllCriterias([]);
          }
        }, 300);
      }
    } catch (err) {
      setAllCriterias([]);
    }
  }

  // functions for search the skills
  function searchPreferSkillList(term) {
    try {
      if (term?.length < 1) {
        setListpreferSkill([]);
      } else {
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.JOB_API.GET_ALL_SKILLS + "?name=" + term,
            1,
            {},
          );
          if (response?.data?.skills?.length > 0) {
            setListpreferSkill(response?.data.skills.map((d) => d.name));
          } else {
            setListpreferSkill([]);
          }
        }, 300);
      }
    } catch (err) {
      setListpreferSkill([]);
    }
  }

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceCriteriaIndex = parseInt(result.source.droppableId, 10);
    const destinationCriteriaIndex = parseInt(
      result.destination.droppableId,
      10,
    );

    if (sourceCriteriaIndex === destinationCriteriaIndex) {
      const criteriaSkilArrayCopy = [...criteriaSkilArray];
      const skills = criteriaSkilArrayCopy[sourceCriteriaIndex].skills;
      const [reorderedItem] = skills.splice(sourceIndex, 1);
      skills.splice(destinationIndex, 0, reorderedItem);
      setCriteriaSkilArray(criteriaSkilArrayCopy);
    } else {
      const criteriaSkilArrayCopy = [...criteriaSkilArray];
      const sourceSkills = criteriaSkilArrayCopy[sourceCriteriaIndex].skills;
      const destinationSkills =
        criteriaSkilArrayCopy[destinationCriteriaIndex].skills;
      const [movedItem] = sourceSkills.splice(sourceIndex, 1);
      destinationSkills.splice(destinationIndex, 0, movedItem);
      setCriteriaSkilArray(criteriaSkilArrayCopy);
    }
  };

  const [reload, setReload] = useState(0);

  function removeSkillCriteria(skill, criteria, iter) {
    console.log(skill, criteria, iter);

    criteriaSkilArray[iter].keySkills = criteriaSkilArray[
      iter
    ].keySkills.filter((d) => d != skill);
    if (criteriaSkilArray[iter].keySkills.length <= 0) {
      setCriteriaSkilArray(
        criteriaSkilArray.filter((d) => d.label != criteria),
      );
    }
    setReload(reload + 1);
  }
  console.log(criteriaSkilArray);
  return (
    <section className="col-xs-12 no-padding" id="skills">
      {/* <h2 className="headingText">Add skills</h2> */}
      <div className="col-xs-12 form-section no-padding">
        <div className="col-xs-6 no-padding input-section-left">
          <label className="col-xs-12 no-padding" htmlFor="level">
            Category
          </label>
          <FormAutocomplete
            type="text"
            defaultValue="Type to search... "
            idName="criteria"
            required={false}
            value={criteria}
            name="criteria"
            data={allCriterias}
            searchFunction={searchPreferCriteriaList}
            selectionFunction={(name, value) => {
              setCriteria(value);
            }}
          />
        </div>
        <div className="col-xs-6 no-padding input-section-right">
          <label className="col-xs-12 no-padding" htmlFor="skill">
            Skill
          </label>
          <FormAutocomplete
            type="text"
            defaultValue="Type to search... "
            idName="skill"
            required={false}
            name="skill"
            value={value}
            data={listpreferSkill}
            onEnterFunction={(e) => {
              if (e.key == "Enter") {
                addcriteriaSkill();
              }
            }}
            searchFunction={searchPreferSkillList}
            selectionFunction={(name, value) => {
              setValue(value);
            }}
          />
        </div>
        <div className="col-xs-12 no-padding ">
          <DragDropContext onDragEnd={handleDragEnd}>
            {criteriaSkilArray?.map((criteria, criteriaIndex) => (
              <Droppable
                key={criteriaIndex}
                droppableId={`${criteriaIndex}`}
                className="col-xs-12"
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`${
                      snapshot.isDraggingOver ? "bg-gray-100" : "bg-white"
                    } p-4 mb-4 rounded-md col-xs-12 no-padding`}
                  >
                    <h3 className="col-xs-12 no-padding criteria-header">
                      {criteria?.label}
                      <span
                        className="remove-criteria"
                        // id={skill}
                        onClick={() => {
                          setCriteriaSkilArray(
                            criteriaSkilArray?.filter(
                              (iter) => iter?.label != criteria?.label,
                            ),
                          );
                        }}
                      >
                        <svg
                          className="w-[18px] h-[18px] ml-[30px]"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <g id="left icon">
                            <path
                              className="group-hover:stroke-[#910000]"
                              id="Vector"
                              d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                              stroke="#FF475D"
                              strokeLinecap="round"
                            ></path>
                          </g>
                        </svg>
                      </span>
                    </h3>

                    <ul className="skill-list col-xs-12 no-padding mt-[20px]">
                      {criteria?.keySkills?.map((skill, skillIndex) => (
                        <Draggable
                          key={skill?.label}
                          draggableId={skill?.label}
                          index={skillIndex}
                        >
                          {(provided, snapshot) => (
                            <li
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              className={`${
                                snapshot.isDragging ? "bg-gray-200" : ""
                              }  py-2 px-4 mb-2 cursor-move `}
                            >
                              <span className="text flex items-center">
                                {" "}
                                {skill?.label}
                                <span
                                  className="remove"
                                  id={skill}
                                  onClick={() => {
                                    removeSkillCriteria(
                                      skill,
                                      skill?.label,
                                      criteriaIndex,
                                    );
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M11.25 4.75L4.75 11.25M4.75 4.75L11.25 11.25"
                                      stroke="#001B4B"
                                      strokeWidth="1.5"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>{" "}
                                </span>
                              </span>
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </ul>
                  </div>
                )}
              </Droppable>
            ))}
          </DragDropContext>
        </div>
      </div>
    </section>
  );
}
