import React from "react";
import Modal from "../modal/Modal";
import { useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { ScaleLoader } from "react-spinners";
import { CONTENT } from "../../lib/constants";
const core = require("../../lib/core");

function AddOutreachCandidateModal({ modalDismiss }) {
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const [loadRetriveCandidates, setLoadRetriveCandidates] = useState(false);
  const [message, setMessage] = useState({});

  useEffect(() => {
    setLoadRetriveCandidates(false);
  }, [jobId]);

  async function fetch_outreach_candidates() {
    try {
      setMessage({});
      setLoadRetriveCandidates(true);
      let response = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_FETCH_OUTREACH_CANDIDATES,
        1,
        {
          jobId: jobId,
        },
      );
      setLoadRetriveCandidates(false);

      if (response?.data) {
        setMessage({
          type: "success",
          message: CONTENT.MODAL_CONTENT.RETRIVE_CANDIDATE.SUCCESS,
        });
      } else {
        setMessage({ type: "error", message: response?.error });
      }
    } catch {}
  }
  return (
    <Modal
      heading={CONTENT.MODAL_CONTENT.RETRIVE_CANDIDATE.HEADING}
      subheading={CONTENT.MODAL_CONTENT.RETRIVE_CANDIDATE.SUBHEADING}
      modalDismiss={modalDismiss}
      noBtns={true}
      dismissOnSave={false}
    >
      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
      <div className="flex justify-end">
        <button className={"btn-md btn-sec mr-[20px]"} onClick={modalDismiss}>
          {"Cancel"}
        </button>

        <button
          className="btn-primary btn-md  "
          disabled={loadRetriveCandidates}
          onClick={() => fetch_outreach_candidates()}
        >
          {loadRetriveCandidates === true ? (
            <ScaleLoader height={14} color="#ffffff" loading={true} />
          ) : (
            <span className="text-base font-normal not-italic">Add</span>
          )}
        </button>
      </div>
    </Modal>
  );
}

export default AddOutreachCandidateModal;
