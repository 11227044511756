import React, { useState, useEffect, useRef } from "react";
import { ScaleLoader } from "react-spinners";
import TextAreaBox from "../../../layouts/TextAreaBox";
function AnimatedTextFormAutocomplete({
  type,
  value,
  idName,
  template,
  tempName,
  name,
  data,
  generate_template,
  setStatus,
  status,
  generate_job,
  searchFunction,
  loadingData,
  onEnterFunction,
  addFunction,
  onSelectOption,
  required,
  selectionFunction,
  searchType,
  setShowCompanyPage,
  showCompany,
  companyNew,
  initialSelectedEmails,
  className = "",
  inputClassName = "",
  listClass = "",
  loading,
  ...props
}) {
  console.log(tempName);
  console.log("Hi");
  console.log(tempName); //myupdate
  const [disabled, setDisabled] = useState(!value?.trim());
  const [typedPlaceholder, setTypedPlaceholder] = useState("");
  const [placeholderIndex, setPlaceholderIndex] = useState(0);
  const [typingDirection, setTypingDirection] = useState(1); // 1 for typing, -1 for deleting
  const textareaRef = useRef(null);

  const placeholderTexts = [
    "Additional comments...",
    // "Seeking a Software Engineer with 5 years of experience",
    // "Looking for a Data Analyst with 5 years of experience",
    // "Hiring a Software Developer with 5 years of professional experience",
    // "In need of a Data Specialist with 5 years of expertise",
    // "Searching for a Senior Software Engineer with 5 years of experience",
    // "Wanting a Data Analyst with a minimum of 5 years of experience",
    // "Requesting applications for a Software Engineer with 5 years in the field",
    // "Desiring a Data Analyst with at least 5 years of experience",
    // "Seeking an experienced Software Engineer with 5 years in the industry",
    // "Looking for a seasoned Data Analyst with 5 years of hands-on experience",
  ];

  const handleButtonClick = () => {
    if (textareaRef.current) {
      generate_template(textareaRef.current.value);
    }
    // setDisabled(true);
  };

  const handleButtonJobClick = () => {
    if (textareaRef.current) {
      generate_job(textareaRef.current.value);
    }
    // setDisabled(true);
  };

  useEffect(() => {
    setDisabled(false);
  }, [value]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const text = placeholderTexts[placeholderIndex];
  //     const currentLength = typedPlaceholder.length;
  //     const targetLength = text.length;

  //     if (typingDirection === 1) {
  //       // Typing
  //       if (currentLength < targetLength) {
  //         setTypedPlaceholder(text.substring(0, currentLength + 1));
  //       } else {
  //         // Start deleting
  //         setTypingDirection(-0.5);
  //       }
  //     } else {
  //       // Deleting
  //       if (currentLength > 0) {
  //         setTypedPlaceholder(text.substring(0, currentLength - 1));
  //       } else {
  //         // Start typing the next placeholder
  //         const nextIndex = (placeholderIndex + 1) % placeholderTexts.length;
  //         setPlaceholderIndex(nextIndex);
  //         setTypingDirection(1);
  //       }
  //     }
  //   }, 1000); // Typing speed

  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!e.target.closest(`#${idName} .auto-complete-input`)) {
        // setHide(true);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [idName]);

  return (
    <div id={idName} className={`auto-complete relative ${className}`}>
      <div className="relative">
        <textarea
          ref={textareaRef}
          className={`w-full auto-complete-input ${inputClassName}`}
          name={name}
          value={value}
          placeholder="Additional comments..."
          style={{
            fontSize: "16px",
            minHeight: "80px",
            height: "80px", // Adjust the height as needed
            width: "100%", // Adjust the width as needed
            // border: "1px solid #ccc", // Adjust the border as needed
            borderRadius: "4px", // Optional: Add border radius for rounded corners
            padding: "10px 10px 40px", // Optional: Add padding for better appearance
            boxSizing: "border-box", // Ensure padding and border are included in the element's total width and height
            position: "relative",
          }}
          onChange={(e) => {
            selectionFunction("template_name", e.target.value);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              generate_template(e.target.value);
              e.preventDefault();
            }
          }}
          {...props}
        />
        {tempName?.trim()?.length > 0 && (
          <>
            <div className="flex justify-end gap-2">
              <p className="mt-2">{status}</p>

              {/* {loading ? (
                <ScaleLoader height={14} color="#ffffff" loading={true} />
              ) : (
                <button
                  type="button"
                  onClick={handleButtonClick}
                  disabled={disabled}
                  className="btn-md btn-secondary flex items-center justify-center bg-gray-400 cursor-pointer rounded-lg "
                >
                  Preview template
                </button>
              )} */}

              <button
                type="button"
                onClick={handleButtonJobClick}
                // disabled={loading}
                className="btn-md btn-primary flex items-center justify-center border-none cursor-pointer rounded-lg bg-blue-800"
              >
                {loading ? (
                  <ScaleLoader height={14} color="#ffffff" loading={true} />
                ) : (
                  <>
                    <svg
                      width="15px"
                      height="15px"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 16 16"
                      fill="currentColor"
                      className="size-3"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5 4a.75.75 0 0 1 .738.616l.252 1.388A1.25 1.25 0 0 0 6.996 7.01l1.388.252a.75.75 0 0 1 0 1.476l-1.388.252A1.25 1.25 0 0 0 5.99 9.996l-.252 1.388a.75.75 0 0 1-1.476 0L4.01 9.996A1.25 1.25 0 0 0 3.004 8.99l-1.388-.252a.75.75 0 0 1 0-1.476l1.388-.252A1.25 1.25 0 0 0 4.01 6.004l.252-1.388A.75.75 0 0 1 5 4ZM12 1a.75.75 0 0 1 .721.544l.195.682c.118.415.443.74.858.858l.682.195a.75.75 0 0 1 0 1.442l-.682.195a1.25 1.25 0 0 0-.858.858l-.195.682a.75.75 0 0 1-1.442 0l-.195-.682a1.25 1.25 0 0 0-.858-.858l-.682-.195a.75.75 0 0 1 0-1.442l.682-.195a1.25 1.25 0 0 0 .858-.858l.195-.682A.75.75 0 0 1 12 1ZM10 11a.75.75 0 0 1 .728.568.968.968 0 0 0 .704.704.75.75 0 0 1 0 1.456.968.968 0 0 0-.704.704.75.75 0 0 1-1.456 0 .968.968 0 0 0-.704-.704.75.75 0 0 1 0-1.456.968.968 0 0 0 .704-.704A.75.75 0 0 1 10 11Z"
                        clipRule="evenodd"
                      />
                    </svg>{" "}
                    Generate job
                  </>
                )}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default AnimatedTextFormAutocomplete;
