import React from "react";

const Plus = ({ height, width }) => {
  return (
    <svg
      width={width || "10"}
      height={height || "10"}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.5 0.615234C5.5 0.339092 5.27614 0.115234 5 0.115234C4.72386 0.115234 4.5 0.339092 4.5 0.615234V4.11523H1C0.723857 4.11523 0.5 4.33909 0.5 4.61523C0.5 4.89138 0.723857 5.11523 1 5.11523H4.5V8.61523C4.5 8.89138 4.72386 9.11523 5 9.11523C5.27614 9.11523 5.5 8.89138 5.5 8.61523V5.11523H9C9.27614 5.11523 9.5 4.89138 9.5 4.61523C9.5 4.33909 9.27614 4.11523 9 4.11523H5.5V0.615234Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Plus;
