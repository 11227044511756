import React, { useEffect, useState } from "react";
import Header from "../../components/all/header/Header";
import SideBar from "../../components/all/SideBar";
import Menu from "../../components/all/header/Menu";
import EmptyState from "../../components/all/EmptyState";

// import ReactDOM from 'react-dom';
// import {Editor, EditorState} from 'draft-js';
// import 'draft-js/dist/Draft.css';
import { ContentState, convertToRaw } from "draft-js";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const core = require("../../lib/core");

export default function RecruiterProfile() {
  const [profiledata, setprofileData] = useState([]);
  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.RECRUITER_API.GET_PROFILE_DETAILS,
      1,
    );
    if (json?.data) {
      console.log(json.data);
      setprofileData(json.data);
    }
  };

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty(),
  );
  return (
    <div className="page recruiter" id="recruiterProfile">
      <Header category="recruiter" showOnlyLogo={false} menu={<Menu />} />
      <SideBar />
      <main className="main-content">
        <h2>My profile</h2>
        <div className="col-xs-12  top-section">
          <div className=" no-padding">
            <img src={profiledata?.pic} alt="" />
          </div>
          <div className=" text-section col-xs-12 no-padding">
            <div className=" button-section">
              <span className="name">{profiledata?.name}</span>
              <button className="edit-button">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M16.862 4.48725L19.5 7.12525M18 14.0002V18.7502C18 19.347 17.7629 19.9193 17.341 20.3412C16.919 20.7632 16.3467 21.0002 15.75 21.0002H5.25C4.65326 21.0002 4.08097 20.7632 3.65901 20.3412C3.23705 19.9193 3 19.347 3 18.7502V8.25025C3 7.65351 3.23705 7.08121 3.65901 6.65926C4.08097 6.2373 4.65326 6.00025 5.25 6.00025H10M16.862 4.48725L18.549 2.79925C18.9007 2.44757 19.3777 2.25 19.875 2.25C20.3723 2.25 20.8493 2.44757 21.201 2.79925C21.5527 3.15092 21.7502 3.6279 21.7502 4.12525C21.7502 4.62259 21.5527 5.09957 21.201 5.45125L10.582 16.0702C10.0533 16.5986 9.40137 16.987 8.685 17.2002L6 18.0002L6.8 15.3152C7.01328 14.5989 7.40163 13.9469 7.93 13.4182L16.862 4.48725V4.48725Z"
                    stroke="#363636"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{" "}
                <span>Edit</span>
              </button>
            </div>

            <span className="company col-xs-10 no-padding">
              Olx Autos|{profiledata.location}
            </span>
            <span className="description col-xs-10 no-padding">
              {profiledata.bio}
            </span>
          </div>
        </div>

        <div className="col-xs-12 bottom-section no-padding">
          <div className="col-xs-5 left-section">
            <div className="col-xs-12 heading-section no-padding">
              <span className="heading-text">Contact information</span>
              {/* <button className='edit-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.862 4.48725L19.5 7.12525M18 14.0002V18.7502C18 19.347 17.7629 19.9193 17.341 20.3412C16.919 20.7632 16.3467 21.0002 15.75 21.0002H5.25C4.65326 21.0002 4.08097 20.7632 3.65901 20.3412C3.23705 19.9193 3 19.347 3 18.7502V8.25025C3 7.65351 3.23705 7.08121 3.65901 6.65926C4.08097 6.2373 4.65326 6.00025 5.25 6.00025H10M16.862 4.48725L18.549 2.79925C18.9007 2.44757 19.3777 2.25 19.875 2.25C20.3723 2.25 20.8493 2.44757 21.201 2.79925C21.5527 3.15092 21.7502 3.6279 21.7502 4.12525C21.7502 4.62259 21.5527 5.09957 21.201 5.45125L10.582 16.0702C10.0533 16.5986 9.40137 16.987 8.685 17.2002L6 18.0002L6.8 15.3152C7.01328 14.5989 7.40163 13.9469 7.93 13.4182L16.862 4.48725V4.48725Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> {" "}

                                <span>Edit</span>
                            </button> */}
            </div>
            <div className="col-xs-12 no-padding contact-info-section">
              {/* <h5 className='col-xs-12 no-padding'><u> Contact details</u></h5> */}
              <div className="col-xs-12 no-padding detail">
                <span className="col-xs-3 no-padding key">Phone :</span>
                <span className="value col-xs-7 no-padding">
                  {profiledata.phone}
                </span>
              </div>
              <div className="col-xs-12 no-padding detail">
                <span className="key col-xs-3 no-padding">E-mail :</span>
                <span className="value col-xs-7 no-padding">
                  {profiledata.email}
                </span>
              </div>
              {/* <h5 className='col-xs-12 no-padding '><u> Website details</u></h5> */}
              {profiledata?.links?.map((item) => (
                <div className="col-xs-12 no-padding detail">
                  <span className="key col-xs-5 no-padding">{item?.name}</span>
                  <span className="value col-xs-7 no-padding">
                    {item?.link}
                  </span>
                </div>
              ))}
            </div>
          </div>
          {/* <div className='col-xs-7 right-section'>
                        <div className='col-xs-12 heading-section no-padding'>
                            <span className='heading-text'>Work experience</span>
                            <button className='edit-button'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M16.862 4.48725L19.5 7.12525M18 14.0002V18.7502C18 19.347 17.7629 19.9193 17.341 20.3412C16.919 20.7632 16.3467 21.0002 15.75 21.0002H5.25C4.65326 21.0002 4.08097 20.7632 3.65901 20.3412C3.23705 19.9193 3 19.347 3 18.7502V8.25025C3 7.65351 3.23705 7.08121 3.65901 6.65926C4.08097 6.2373 4.65326 6.00025 5.25 6.00025H10M16.862 4.48725L18.549 2.79925C18.9007 2.44757 19.3777 2.25 19.875 2.25C20.3723 2.25 20.8493 2.44757 21.201 2.79925C21.5527 3.15092 21.7502 3.6279 21.7502 4.12525C21.7502 4.62259 21.5527 5.09957 21.201 5.45125L10.582 16.0702C10.0533 16.5986 9.40137 16.987 8.685 17.2002L6 18.0002L6.8 15.3152C7.01328 14.5989 7.40163 13.9469 7.93 13.4182L16.862 4.48725V4.48725Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg> {" "}

                                <span>Edit</span>
                            </button>
                        </div>
                        {profiledata.experience == "" ? (
                            <div className="col-xs-12 workExperience-section">
                                <div className=' no-padding'>
                                    <img src="" alt="" />
                                </div>
                                <div className=' no-padding exp-details'>
                                    <span className='designation'>Recruiter</span>
                                    <span className='company'>Olx,Mumbai</span>
                                    <div className='col-xs-12 no-padding button-section'>
                                        <span className='description'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Consequuntur hic autem maxime minus officoluptatum, modi ea deleniti incidunt quidem, voluptatibus et totam reiciendis saepe rerum!</span>
                                        <button className='edit-button'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                <path d="M16.862 4.48725L19.5 7.12525M18 14.0002V18.7502C18 19.347 17.7629 19.9193 17.341 20.3412C16.919 20.7632 16.3467 21.0002 15.75 21.0002H5.25C4.65326 21.0002 4.08097 20.7632 3.65901 20.3412C3.23705 19.9193 3 19.347 3 18.7502V8.25025C3 7.65351 3.23705 7.08121 3.65901 6.65926C4.08097 6.2373 4.65326 6.00025 5.25 6.00025H10M16.862 4.48725L18.549 2.79925C18.9007 2.44757 19.3777 2.25 19.875 2.25C20.3723 2.25 20.8493 2.44757 21.201 2.79925C21.5527 3.15092 21.7502 3.6279 21.7502 4.12525C21.7502 4.62259 21.5527 5.09957 21.201 5.45125L10.582 16.0702C10.0533 16.5986 9.40137 16.987 8.685 17.2002L6 18.0002L6.8 15.3152C7.01328 14.5989 7.40163 13.9469 7.93 13.4182L16.862 4.48725V4.48725Z" stroke="#363636" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg> {" "}

                                            <span>Edit</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-xs-12 empty-state">
                                <EmptyState text={"No experience added"} />

                            </div>
                        )}
                    </div> */}
        </div>
      </main>
    </div>
  );
}
