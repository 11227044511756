import { useEffect } from "react";
import * as d3 from "d3";

export default function SpiderChart({
  id,
  chartSize,
  //   data,
  //   colors,
  range = [0, 10],
  ticks = [2, 4, 6, 8, 10],
  chartMargin,
}) {
  const type = ["Job requirement", "Candidate skills"];
  const data = [
    { Cat1: 9, Cat2: 6, Cat3: 9, Cat4: 6, Cat5: 8, Cat6: 7, Cat7: 8 },
    { Cat1: 4, Cat2: 5, Cat3: 7, Cat4: 9, Cat5: 8, Cat6: 6, Cat7: 9 },
  ];
  let colors = ["#FF7324", "#1369E9"];
  var tooltip;
  //   radialScale(num) => calculates the radius based on num value
  let radialScale = d3
    .scaleLinear()
    .domain(range)
    .range([0, (chartSize - 2 * chartMargin) / 2]);

  //   Size of chart inside the margins
  var innerChart = chartSize - 2 * chartMargin;
  // All features
  let features = Object.keys(data[0]);
  // Info about features
  let featureData = features.map((f, i) => {
    let angle = Math.PI / 2 + (2 * Math.PI * i) / features.length;
    return {
      name: f,
      angle: angle,
      label_coord: angleToCoordinate(angle, radialScale(1.5 + range[1])),
    };
  });

  useEffect(() => {
    try {
      var svg = d3
        .select("svg.spiderChart#" + id)
        .attr("width", chartSize)
        .attr("height", chartSize);

      svg.selectAll("circle").remove();
      svg.selectAll("text").remove();
      svg.selectAll("path").remove();
      svg.selectAll("g").remove();

      //   Axis group
      var axis = svg
        .append("g")
        .classed("axis", true)
        .attr("width", innerChart)
        .attr("height", innerChart);
      // Axis circles
      axis
        .selectAll("circle")
        .data(ticks)
        .enter()
        .append("circle")
        .attr("cx", chartSize / 2)
        .attr("cy", chartSize / 2)
        .attr("fill", "none")
        .attr("stroke", "#00000044")
        .attr("r", (d) => radialScale(d));

      // Axis circle labels
      axis
        .selectAll(".ticklabel")
        .data(ticks)
        .enter()
        .append("text")
        .attr("class", "ticklabel")
        .attr("x", chartSize / 2)
        .attr("y", (d) => chartSize / 2 - radialScale(d) - 2)
        .text((d) => d.toString());

      // Features label
      var g_feat = svg
        .append("g")
        .classed("g_feat", true)
        .attr("width", innerChart)
        .attr("height", innerChart);
      g_feat
        .selectAll(".axislabel")
        .data(featureData)
        .enter()
        .append("text")
        .attr("x", (d) => d.label_coord.x - 10)
        .attr("y", (d) => d.label_coord.y)
        .text((d) => d.name);

      //   Graph
      var graph = svg
        .append("g")
        .classed("linegraph", true)
        .attr("width", innerChart)
        .attr("height", innerChart);
      //   Line path
      let line = d3
        .line()
        .x((d) => d.x)
        .y((d) => d.y);
      graph
        .selectAll("path")
        .data(data)
        .enter()
        .append("path")
        .datum((d) => getPathCoordinates(d).map((d) => d.coordinates))
        .attr("d", line)
        .attr("trokeWidth", 1)
        .attr("stroke", (_, i) => colors[i])
        .attr("fill", (_, i) => colors[i])
        .attr("fill-opacity", 0.4)
        .on("mouseover", (e) => {
          graph
            .selectAll("path")
            .attr("trokeWidth", 1)
            .attr("fill-opacity", 0.4);
          d3.select(e.target).attr("trokeWidth", 2).attr("fill-opacity", 0.5);
        })
        .on("mousemove", (e) => {
          graph
            .selectAll("path")
            .attr("trokeWidth", 1)
            .attr("fill-opacity", 0.4);
          d3.select(e.target).attr("trokeWidth", 2).attr("fill-opacity", 0.5);
        })
        .on("mouseout", () => {
          graph
            .selectAll("path")
            .attr("trokeWidth", 1)
            .attr("fill-opacity", 0.4);
        });

      for (var i = 0; i < data.length; i++) {
        var path_data = getPathCoordinates(data[i], type[i]);
        var g = svg
          .append("g")
          .classed("dotgraph" + (i + 1), true)
          .attr("width", innerChart)
          .attr("height", innerChart);
        g.selectAll(".dot")
          .data(path_data)
          .enter()
          .append("circle")
          .attr("class", "dot")
          .attr("id", (_, e) => "p" + e)
          .attr("cx", (d) => d?.coordinates?.x)
          .attr("cy", (d) => d?.coordinates?.y)
          .attr("r", 3)
          .style("fill", d3.rgb(colors[i]).darker())
          .on("mouseover", (e, it) => {
            svg.selectAll("circle.dot").attr("r", 3);
            d3.select(e.target).attr("r", 4);

            tooltip = d3
              .select("body")
              .append("div")
              .classed("tooltip", true)
              .style("display", "block")
              .style("top", e.y + "px")
              .style("left", e.x + 25 + "px");
            tooltip.append("p").text(it?.type + " - ");
            tooltip.append("p").text(it?.name + " : " + it?.value + "/10");
          })
          .on("mousemove", (e) => {
            svg.selectAll("circle.dot").attr("r", 3);
            d3.select(e.target).attr("r", 4);
            tooltip
              .style("display", "block")
              .style("top", e.y + "px")
              .style("left", e.x + 25 + "px");
          })
          .on("mouseout", () => {
            svg.selectAll("circle.dot").attr("r", 3);
            d3.selectAll(".tooltip").remove();
          });
      }
    } catch (err) {
      console.error(err);
    }
  });

  //   Get x and y coordinates based on radius and angle
  function angleToCoordinate(angle, value) {
    let x = Math.cos(angle) * value;
    let y = Math.sin(angle) * value;
    return {
      x: innerChart / 2 + x + chartMargin,
      y: innerChart / 2 - y + chartMargin,
    };
  }

  //   Get list of x and y coordinates for the path => each point/feature in the path
  function getPathCoordinates(data_point, type) {
    let coordinates = [];
    for (var i = 0; i < features.length; i++) {
      let ft_name = features[i];
      let angle = Math.PI / 2 + (2 * Math.PI * i) / features.length;
      coordinates.push({
        name: features[i],
        type: type,
        value: data_point[ft_name],
        coordinates: angleToCoordinate(angle, radialScale(data_point[ft_name])),
      });
    }
    coordinates.push(coordinates[0]);
    return coordinates;
  }

  return <svg id={id} className="spiderChart"></svg>;
}
