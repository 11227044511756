import React, { useMemo, useState, useEffect } from "react";
import { minMaxValid } from "../createJob2/ValidateValues";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import InputBox from "../../../layouts/InputBox";
import TextAreaBox from "../../../layouts/TextAreaBox";
import SelectBox from "../../../layouts/SelectBox";
import FormAutocomplete from "../createJob/Autocomplete";
import AnimatedTextFormAutocomplete from "../createJob/AnimatedAutocomplete";
import { ScaleLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
import Skills from "../createJob2/Skills";
const core = require("../../../lib/core");

const EditJobTemplate = ({ data, setEdit, reload }) => {
  const { reloadList } = useOutletContext();
  const [skillsarray, setSkillsArray] = useState([]);
  const [formJobdetails, setFormJobdetails] = useState({});

  const nav = useNavigate();
  const template_id = useParams().id;

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [organization, setOrganization] = useState(false);

  const setdataFunction = async (data) => {
    setFormJobdetails({
      company: "",
      position: data?.job_position ?? "",
      template_name: data?.template_name ?? data?.job_position,
      positionDetails: "",
      status: "",
      location: [],
      type: [],
      salary: {
        currency: "$",
        min: data?.salary?.min ?? "",
        max: data?.salary?.max ?? "",
      },
      workplace: data?.workplace_type,
      organizationLevel: organization,
      roles: data?.roles_and_responsibilities ?? "",
      experienceEducation: data?.education ?? "",
      experienceLevel: data?.experience,
      experienceRange: {},
      description: data?.job_details ?? "",
      criterias: [],
      preferCriteria: [],
      equity: {},
      benefits: [],
      bonus: [],
      rawData: {},
    });
    setSkillsArray(data?.skills ?? []);

    if (data?.job_type) {
      const jobType = Array.isArray(data.job_type)
        ? data.job_type.map((item) =>
            item.split(" ").join("-").trim().toLowerCase(),
          )
        : [data.job_type.split(" ").join("-").trim().toLowerCase()];

      setJobTypeArray((prevJobTypeArray) => {
        // Update status of existing job types
        const updatedJobTypes = prevJobTypeArray.map((item) => ({
          ...item,
          status: jobType.includes(item.type),
        }));

        // Add new job types that are not already in the list
        const newJobTypes = jobType
          .filter(
            (type) => !prevJobTypeArray.some((item) => item.type === type),
          )
          .map((type) => ({ type, status: true }));

        // Add new job types to the front
        return [...newJobTypes, ...updatedJobTypes];
      });

      if (jobType.length > 0) {
        setJobTypeInputValue(jobType[0]);
      }
    }

    if (data?.department) {
      const departmentNames = Array.isArray(data.department)
        ? data.department
        : [data.department];

      setDepartments((prevDepartments) => {
        // Update status of existing departments
        const updatedDepartments = prevDepartments.map((department) => ({
          ...department,
          status: departmentNames.includes(department.name),
        }));

        // Filter out the departments that are already in the updated list
        const newDepartments = departmentNames
          .filter((dept) => !prevDepartments.some((d) => d.name === dept))
          .map((dept) => ({ name: dept, status: true }));

        // Add new departments to the front
        return [...newDepartments, ...updatedDepartments];
      });
    }

    if (data?.benefits) {
      setBenefits((prevBenefits) => {
        const benefit = Array.isArray(data.benefits)
          ? data?.benefits?.map((item) => ({
              benefits: item,
              status: true,
            }))
          : [{ benefits: data.benefits, status: true }];

        const array = prevBenefits?.filter(
          (item) => item?.benefit !== benefit.benefits,
        );

        if (!array.some((item) => item?.benefit === benefit.benefits)) {
          return [...benefit, ...array];
        }
        return prevBenefits;
      });
    }

    if (data?.custom_attributes) {
      setCustomAttributes(data?.custom_attributes);
    }
    // console.log("skills", skills);
  };
  const setSkills = (newSkills) => {
    setFormJobdetails((prevDetails) => ({
      ...prevDetails,
      skills: newSkills,
    }));
  };
  const getData = async (id) => {
    try {
      setLoadingData(true);
      const json = await core.API(
        core.API_METHODS.GET,
        core.TEMPLATE_API.GET_JOB_TEMPLATE_Detail + id,
        1,
      );
      if (json?.data) {
        setdataFunction(json?.data);
        setLoadingData(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    }
  };

  const [departments, setDepartments] = useState([
    { name: "Engineering and Development", status: false },
    { name: "Design and Creative", status: false },
    { name: "Marketing and Sales", status: false },
    { name: "Customer Support and Service", status: false },
    { name: "Product Management", status: false },
    { name: "Operations and Management", status: false },
    { name: "Finance and Accounting", status: false },
    { name: "Legal and Compliance", status: false },
    { name: "Research and Development", status: false },
    { name: "Quality Assurance and Testing", status: false },
    { name: "Healthcare and Medical", status: false },
    { name: "Education and Training", status: false },
    { name: "Manufacturing and Production", status: false },
    { name: "Other", status: false },
  ]);

  const [JobTypeArray, setJobTypeArray] = useState([
    { type: "Full-time", status: false },
    { type: "Regular/permanent", status: false },
    { type: "Part-time", status: false },
    { type: "Internship", status: false },
    { type: "Contract/temporary", status: false },
    { type: "Volunteer", status: false },
    { type: "Other", status: false },
  ]);
  function setJobdetailsInput(key, value) {
    setFormJobdetails({ ...formJobdetails, [key]: value });
  }

  const [errorList, setErrorList] = useState({});
  const errorMemo = useMemo(() => ({ errorList, setErrorList }), [errorList]);

  const select_data = {
    jobType: [
      "full time",
      "part time",
      "contract",
      "internship",
      "volunteer",
      "temporary",
      "other",
    ],
    workplace: ["On-site", "Remote", "Hybrid"],
  };

  const [jobTypeInputValue, setJobTypeInputValue] = useState("");
  const addNewJobtype = (value) => {
    if (
      JobTypeArray.map((d) => String(d.type).toLowerCase()).includes(
        String(value).toLowerCase(),
      )
    )
      throw "Job type already added";
    setJobTypeArray([
      ...JobTypeArray,
      { type: String(value).trim(), status: true },
    ]);
  };

  const handleNewJobType = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() == "") return;
        addNewJobtype(e.target.value);
        setJobTypeInputValue("");
        setErrorList((current) => {
          const { type, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      setErrorList({ ...errorList, type: error });
    }
  };

  const togglejobTypeStatus = (idx) => {
    let temp = [...JobTypeArray].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setJobTypeArray(temp);
  };

  const [departmentValue, setDepartmentValue] = useState("");
  const addNewDepartmenttype = (value) => {
    if (
      departments
        .map((d) => String(d.name).toLowerCase())
        .includes(String(value).toLowerCase())
    )
      throw "Department already added";
    setDepartments([
      ...departments,
      { name: String(value).trim(), status: true },
    ]);
  };

  const handleNewDepartment = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() == "") return;
        // minAlphaNumericVal(e.target.value);
        addNewDepartmenttype(e.target.value);
        setDepartmentValue("");
        setErrorList((current) => {
          const { department, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, department: error });
    }
  };

  const toggledepartmentStatus = (idx) => {
    let temp = [...departments].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setDepartments(temp);
  };

  const [loading, setLoading] = useState(false);

  console.log(formJobdetails, "formJobdetails");

  const Create_new_temp = async () => {
    setLoading(true);
    try {
      console.log("**Creating new job template:**");

      const list = await core.API(
        core.API_METHODS.POST,
        core.TEMPLATE_API.POST_NEW_JOB_TEMPLATE,
        1,
        {
          template_name:
            formJobdetails?.template_name ?? formJobdetails?.position,
          // job_position: formJobdetails?.position,
          job_details: formJobdetails?.description,
          organization_level: organization,
          experience: formJobdetails?.experienceLevel,
          job_type: JobTypeArray?.filter((item) => item?.status).map(
            (item) => item?.type,
          ),
          department: departments
            ?.filter((item) => item?.status)
            .map((item) => item?.name),
          benefits: benefits
            ?.filter((item) => item?.status)
            ?.map((item) => item?.benefit),
          salary: formJobdetails?.salary,
          skills: skillsarray,
          workplace_type: formJobdetails?.workplace,
          roles_and_responsibilities: formJobdetails?.roles,
          education: formJobdetails?.experienceEducation,
          custom_attributes: customAttributes,
        },
      );

      if (list.data) {
        console.log(
          "**Template creation successful! Response data:**",
          list.data,
        );

        reloadList();
        setLoading(false);
        nav("/templates/job/" + list?.data?.data?._id);
        reload();
        setEdit();
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      console.error("**Template creation failed!**");
    }
  };

  const update_template = async () => {
    console.log("update_template");
    setLoading(true);
    try {
      const list = await core.API(
        core.API_METHODS.PUT,
        core.TEMPLATE_API.PUT_UPDATE_JOB_TEMPLATE + template_id,
        1,
        {
          template_name: formJobdetails?.template_name,
          // job_position: formJobdetails?.position,
          job_details: formJobdetails?.description,
          benefits: benefits
            ?.filter((item) => item?.status)
            ?.map((item) => item?.benefit),
          experience: formJobdetails?.experienceLevel,
          job_type: JobTypeArray?.filter((item) => item?.status).map(
            (item) => item?.type,
          ),
          department: departments
            ?.filter((item) => item?.status)
            .map((item) => item?.name),
          salary: formJobdetails?.salary,
          skills: skillsarray,
          workplace_type: formJobdetails?.workplace,
          roles_and_responsibilities: formJobdetails?.roles,
          education: formJobdetails?.experienceEducation,
          custom_attributes: customAttributes,
        },
      );

      if (list?.data) {
        reloadList();
        reload();
        setEdit();
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.error("**Error creating template:", error);

      console.error(error);
    }
  };

  const generate_template = async (position) => {
    setLoadingData(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.TEMPLATE_API.POST_GENERATE_TEMPLATE,
        1,
        {
          hiring_info: position,
        },
      );
      if (json?.data) {
        // setdataFunction(json?.data);
        setSelectedTemplate(json?.data); // Store the generated template
        setLoadingData(false);
      }
    } catch (error) {
      console.error(error);
      setLoadingData(false);
    }
  };

  // Get all the jobtitles added in the database

  const handleMinExpInput = (event) => {
    try {
      setFormJobdetails((prevDetails) => {
        const newExperienceLevel =
          typeof prevDetails.experienceLevel === "string"
            ? { min: event.target.value }
            : { ...prevDetails.experienceLevel, min: event.target.value };

        return { ...prevDetails, experienceLevel: newExperienceLevel };
      });

      if (
        !(
          event.target.value.trim() === "" &&
          Object.keys(errorList).includes("experience")
        )
      ) {
        minMaxValid(
          +event.target.value,
          formJobdetails?.experienceLevel?.max ?? 100,
        );
      }
      setErrorList((current) => {
        const { experience, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, experience: error });
    }
  };

  const handleMaxExpInput = (event) => {
    try {
      setFormJobdetails((prevDetails) => {
        const newExperienceLevel =
          typeof prevDetails.experienceLevel === "string"
            ? { max: event.target.value }
            : { ...prevDetails.experienceLevel, max: event.target.value };

        return { ...prevDetails, experienceLevel: newExperienceLevel };
      });

      if (
        !(
          event.target.value.trim() === "" &&
          Object.keys(errorList).includes("experience")
        )
      ) {
        minMaxValid(
          formJobdetails?.experienceLevel?.min ?? 0,
          +event.target.value,
        );
      }
      setErrorList((current) => {
        const { experience, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, experience: error });
    }
  };

  const [customAttributes, setCustomAttributes] = useState([]);
  const [benefits, setBenefits] = useState([
    { benefit: "Vision insurance", status: false },
    { benefit: "Flexible schedule", status: false },
    { benefit: "Tuition reimbursement", status: false },
    { benefit: "Referral program", status: false },
    { benefit: "Employee discount", status: false },
    { benefit: "Spending account", status: false },
    { benefit: "Health insurance", status: false },
    { benefit: "Paid time off", status: false },
    { benefit: "Dental insurance", status: false },
    { benefit: "Life insurance", status: false },
    { benefit: "401(K) matching Retirement plan", status: false },
    { benefit: "Computer assistance", status: false },
    { benefit: "Employee assistance program", status: false },
    { benefit: "Health saving account", status: false },
    { benefit: "Relocation assistance", status: false },
    { benefit: "Others", status: false },
  ]);

  const [benefitInputValue, setBenefitInputValue] = useState("");

  const addNewBenefit = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    const existingBenefitIndex = benefits.findIndex(
      (benefit) => benefit.benefit.toLowerCase() === lowerCaseValue,
    );

    if (existingBenefitIndex !== -1) {
      // Benefit already exists, update its status to true
      const updatedBenefits = [...benefits];
      updatedBenefits[existingBenefitIndex] = {
        ...updatedBenefits[existingBenefitIndex],
        status: true,
      };
      setBenefits(updatedBenefits);
    } else {
      // Benefit doesn't exist, add it to the benefits array
      setBenefits([...benefits, { benefit: lowerCaseValue, status: true }]);
    }
  };

  const toggleBenefitStatus = (idx) => {
    const updatedBenefits = benefits.map((benefit, i) =>
      idx === i ? { ...benefit, status: !benefit.status } : benefit,
    );
    setBenefits(updatedBenefits);
  };

  const handleNewBenefit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      try {
        const trimmedValue = e.target.value.trim();
        if (trimmedValue === "") return;
        addNewBenefit(trimmedValue);
        setBenefitInputValue("");
        setErrorList((current) => {
          const { benefits, ...rest } = current;
          return rest;
        });
      } catch (error) {
        console.error(error);
        setErrorList({ ...errorList, benefits: error });
      }
    }
  };

  return (
    <core.JobFormContext.Provider value={errorMemo}>
      <div className="bg-[#FFFFFF] p-[18px] rounded-lg" id="">
        <h2 className="text-gray-800 text-base not-italic font-medium ">
          {data ? "Edit Template" : "Add new job template"}
        </h2>

        <div className="w-full flex flex-col mt-[28px]" id="position">
          <label
            htmlFor="position"
            className={`w-full required text-sm font-normal ml-[8px] text-gray-800 not-italic`}
          >
            Hiring Info
          </label>
          <div className="relative">
            <AnimatedTextFormAutocomplete
              type="text"
              value={formJobdetails?.template_name}
              idName="template_name"
              name="template_name"
              loadingData={loadingData}
              searchType="template"
              required={true}
              onSelectOption={(value) => {
                getData(value);
              }}
              generate_template={generate_template}
              selectionFunction={(name, value) => {
                setJobdetailsInput(name ?? "template_name", value);
              }}
              inputClassName={
                Object.keys(errorList).includes("template_name")
                  ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : ""
              }
            />
            {loadingData && (
              <span className="absolute right-4  mt-[14px]">
                <ClipLoader
                  size={20}
                  color="#1369E9"
                  loading={true}
                  cssOverride={{
                    borderWidth: 4,
                  }}
                />
              </span>
            )}
          </div>
        </div>

        {selectedTemplate && (
          <div className="w-full compensation-tags flex flex-wrap gap-[6px] border-2 p-4 mt-2">
            {selectedTemplate?.map((item, index) => {
              return (
                <div key={index} className="flex items-center gap-4 mt-2 mr-2">
                  <button
                    className={`mt-[4px] rounded-[68px] border border-[#E1E1EE] flex items-center
            py-[7px] px-[12px]
            ${item?.status ? "hover:!bg-[#699c8f]" : "hover:!bg-[#00b98e34]"}
          `}
                    type="button"
                    style={{
                      background: item?.status
                        ? "#00B98D"
                        : "rgba(51, 52, 142, 0.03)",
                    }}
                    onClick={() => setdataFunction(item)}
                  >
                    <span
                      style={{ color: item?.status ? "#FFFFFF" : "#252E4B" }}
                    >
                      {item?.template_name}
                    </span>
                  </button>
                </div>
              );
            })}
          </div>
        )}

        {!data && (
          <div className="w-full flex flex-col mt-[28px]" id="position">
            <label
              htmlFor="position"
              className={`w-full required text-sm font-normal  text-gray-800 not-italic`}
            >
              Template for
            </label>
            <div className="flex gap-10">
              <div className="flex  items-center">
                <input
                  type="radio"
                  name="tempfor"
                  className="mt-[-4px]"
                  defaultChecked={organization ? false : true}
                  onChange={(e) => {
                    setOrganization(false);
                  }}
                />
                <label
                  htmlFor=""
                  className=" text-sm mb-[-5px] font-normal ml-[8px] text-gray-800 not-italic"
                >
                  For me
                </label>
              </div>
              <div className="flex  items-center">
                <input
                  type="radio"
                  name="tempfor"
                  className="mt-[-4px]"
                  defaultChecked={organization ? true : false}
                  onChange={(e) => {
                    setOrganization(true);
                  }}
                />
                <label
                  htmlFor=""
                  className="text-sm mb-[-5px] font-normal ml-[8px] text-gray-800 not-italic"
                >
                  For organization
                </label>
              </div>
            </div>
          </div>
        )}

        <div className="w-full flex flex-col mt-[28px]" id="description">
          <label
            className=" required text-sm font-normal ml-[8px] text-gray-800 not-italic"
            htmlFor="description"
          >
            Job description
          </label>
          <TextAreaBox
            disabled={loading}
            size="200px"
            value={formJobdetails?.description}
            onChange={(e) => {
              setJobdetailsInput("description", e.target.value);
            }}
          />
        </div>

        <div className="w-full flex flex-col mt-[28px]" id="Job-type">
          <label
            htmlFor=""
            className="w-full  text-sm font-normal ml-[8px] text-gray-800 not-italic"
          >
            Add job type
            {Object.keys(errorList).includes("type") ? (
              <>
                {/* <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} /> */}
                <span className="text-red-800 text-sm">{errorList?.type}</span>
              </>
            ) : null}
          </label>
          <InputBox
            type="text"
            disabled={loading}
            className={
              Object.keys(errorList)?.includes("type")
                ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900 mb-1"
                : "w-full mb-1"
            }
            value={jobTypeInputValue}
            onChange={(e) => {
              if (
                e.target.value.trim() == "" &&
                Object.keys(errorList).includes("type")
              ) {
                setErrorList((current) => {
                  const { type, ...rest } = current;
                  return rest;
                });
              }
              setJobTypeInputValue(e.target.value);
            }}
            placeholder={"Type here"}
            onKeyDown={handleNewJobType}
          />

          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {JobTypeArray?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      item?.status
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                  type="button"
                  style={{
                    background: item?.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => togglejobTypeStatus(index)}
                >
                  <span style={{ color: item?.status ? "#FFFFFF" : "#252E4B" }}>
                    {item?.type}
                  </span>
                  {!item?.status ? (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>

        <Skills
          classTxt={"w-full flex flex-col left-input mt-[28px]"}
          // analytics={formJobdetails?.analytics}
          skills={skillsarray}
          setSkills={setSkillsArray}
        />

        {/* <div className="w-full left-input mt-[28px]">
          <label
            htmlFor=""
            className="w-full   text-sm font-normal ml-[8px] text-gray-800 not-italic"
          >
            Skills
            {Object.keys(errorList).includes("skill") ? (
              <>
                <span className="text-red-800 text-sm">{errorList?.skill}</span>
              </>
            ) : null}
          </label>
          <InputBox
            disabled={loading}
            type="text"
            className={
              Object.keys(errorList).includes("skill")
                ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900 mb-1"
                : "w-full mb-1"
            }
            value={skillValue}
            onChange={(e) => {
              if (
                e.target.value.trim() == "" &&
                Object.keys(errorList).includes("skill")
              ) {
                setErrorList((current) => {
                  const { skill, ...rest } = current;
                  return rest;
                });
              }
              setSkillValue(e.target.value);
            }}
            placeholder="Type here"
            onKeyDown={handleNewSkill}
          />

          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {skills?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      item.status
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                  type="button"
                  style={{
                    background: item.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => toggleskillStatus(index)}
                >
                  <span style={{ color: item.status ? "#FFFFFF" : "#252E4B" }}>
                    {item?.name}
                  </span>
                  {!item.status ? (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
          
        </div> */}

        <div className="w-full left-input mt-[28px]">
          <label
            htmlFor=""
            className="w-full   text-sm font-normal ml-[8px] text-gray-800 not-italic"
          >
            Department
            {Object.keys(errorList).includes("department") ? (
              <>
                {/* <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} /> */}
                <span className="text-red-800 text-sm">
                  {errorList?.department}
                </span>
              </>
            ) : null}
          </label>
          <InputBox
            disabled={loading}
            type="text"
            className={
              Object.keys(errorList).includes("department")
                ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900 mb-1"
                : "w-full mb-1"
            }
            value={departmentValue}
            onChange={(e) => {
              if (
                e.target.value.trim() == "" &&
                Object.keys(errorList).includes("department")
              ) {
                setErrorList((current) => {
                  const { department, ...rest } = current;
                  return rest;
                });
              }
              setDepartmentValue(e.target.value);
            }}
            placeholder="Type here"
            onKeyDown={handleNewDepartment}
          />

          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {departments?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      item.status
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                  type="button"
                  style={{
                    background: item.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => toggledepartmentStatus(index)}
                >
                  <span style={{ color: item.status ? "#FFFFFF" : "#252E4B" }}>
                    {item?.name}
                  </span>
                  {!item.status ? (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full  input-data-section mt-[28px]">
          <label
            htmlFor=""
            className="w-full   text-sm font-normal ml-[8px] text-gray-800 not-italic"
          >
            Workplace type
          </label>

          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {select_data?.workplace?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 

                  ${
                    formJobdetails?.workplace?.toLowerCase() ===
                    item?.toLowerCase()
                      ? "hover:!bg-[#699c8f]"
                      : "hover:!bg-[#00b98e34]"
                  }
                 `}
                  type="button"
                  style={{
                    background:
                      formJobdetails?.workplace?.toLowerCase() ===
                      item?.toLowerCase()
                        ? "#00B98D"
                        : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => setJobdetailsInput("workplace", item)}
                >
                  <span
                    style={{
                      color:
                        formJobdetails?.workplace?.toLowerCase() ===
                        item?.toLowerCase()
                          ? "#FFFFFF"
                          : "#252E4B",
                    }}
                  >
                    {item}
                  </span>
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full flex flex-wrap justify-between">
          <label className="w-3/5 text-gray-800 text-sm font-normal not-italic mt-6 mb-1">
            Years of experience <br />
          </label>
          <div className="w-3/5 flex justify-between experience-inputs ">
            <div className="col-xs-5 no-padding">
              <InputBox
                step={0.5}
                disabled={loading}
                type="number"
                className={
                  Object.keys(errorList).includes("salary")
                    ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                    : "w-full"
                }
                placeholder="Min"
                min={0}
                value={formJobdetails?.experienceLevel?.min}
                onChange={handleMinExpInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
            <span>
              <svg
                className="inline-block"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="40"
                viewBox="0 0 15 40"
                fill="none"
              >
                <path
                  d="M4 20H11"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <div className="col-xs-5 no-padding">
              <InputBox
                type="number"
                disabled={loading}
                step={0.5}
                className={
                  Object.keys(errorList).includes("salary")
                    ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                    : "w-full"
                }
                placeholder="Max"
                value={formJobdetails?.experienceLevel?.max}
                onChange={handleMaxExpInput}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </div>
        </div>

        <div className="w-full input-data-section mt-[28px]">
          <div className="w-full flex flex-col">
            <label
              htmlFor=""
              className="w-full text-sm font-normal ml-[8px] text-gray-800 not-italic"
            >
              Expected salary range
              {Object.keys(errorList).includes("salary") ? (
                <>
                  {/* <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} /> */}
                  <span className="text-red-800 text-sm">
                    {errorList?.salary}
                  </span>
                </>
              ) : null}
            </label>
            <div className="w-full salary-inputs flex justify-between">
              <SelectBox
                disabled={loading}
                // width={"10%"}
                className=" duration !bg-[right_10px_center] cursor-pointer"
                name="expectedSalaryCurrency"
                value={formJobdetails?.salary?.currency}
                defaultValue={"$"}
                onChange={(event) => {
                  setJobdetailsInput("salary", {
                    ...formJobdetails?.salary,
                    currency: event.target.value,
                  });
                }}
                options={["$", "₹"]}
              />

              <InputBox
                className={
                  Object?.keys(errorList)?.includes("salary")
                    ? "w-1/4 ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                    : "w-1/4"
                }
                width={"25%"}
                disabled={loading}
                name="expectedSalaryMin"
                type="number"
                placeholder="Type here"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                value={formJobdetails?.salary?.min}
                onChange={(event) => {
                  try {
                    setJobdetailsInput("salary", {
                      ...formJobdetails?.salary,
                      min: event.target.value,
                    });
                    if (
                      !(
                        event.target.value.trim() == "" &&
                        Object.keys(errorList).includes("salary")
                      )
                    ) {
                      minMaxValid(
                        +event.target.value,
                        formJobdetails?.salary?.max,
                        [0, Infinity],
                      );
                    }

                    setErrorList((current) => {
                      const { salary, ...rest } = current;
                      return rest;
                    });
                  } catch (error) {
                    console.error(error);
                    setErrorList({ ...errorList, salary: error });
                  }
                }}
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="40"
                viewBox="0 0 15 40"
                fill="none"
              >
                <path
                  d="M4 20H11"
                  stroke="#252E4B"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <InputBox
                disabled={loading}
                className={
                  Object.keys(errorList).includes("salary")
                    ? "w-1/4 ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                    : "w-1/4"
                }
                type="number"
                width={"25%"}
                placeholder="Type here"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                name="expectedSalaryMax"
                value={formJobdetails?.salary?.max}
                onChange={(event) => {
                  try {
                    setJobdetailsInput("salary", {
                      ...formJobdetails?.salary,
                      max: event.target.value,
                    });
                    if (
                      !(
                        event.target.value.trim() == "" &&
                        Object.keys(errorList).includes("salary")
                      )
                    ) {
                      minMaxValid(
                        formJobdetails?.salary?.min,
                        +event.target.value,
                        [0, Infinity],
                      );
                    }
                    setErrorList((current) => {
                      const { salary, ...rest } = current;
                      return rest;
                    });
                  } catch (error) {
                    console.error(error);
                    setErrorList({ ...errorList, salary: error });
                  }
                }}
              />
              <div className="w-1/4">
                <SelectBox
                  disabled={loading}
                  name="expectedSalaryDuration"
                  value={formJobdetails?.salary?.duration}
                  onChange={(event) => {
                    setJobdetailsInput("salary", {
                      ...formJobdetails.salary,
                      duration: event.target.value,
                    });
                  }}
                  options={[
                    "Per hour",
                    "Per day",
                    "Per week",
                    "Per month",
                    "Per year",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="w-full input-data-section mt-[28px]">
          <label className="w-full text-sm font-normal ml-[8px] text-gray-800 not-italic">
            Benefits offered
          </label>
          <InputBox
            type="text"
            disabled={loading}
            className={
              Object.keys(errorList).includes("benefits")
                ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900 mb-1"
                : "w-full mb-1"
            }
            value={benefitInputValue}
            onChange={(e) => {
              if (
                e.target.value.trim() === "" &&
                Object.keys(errorList).includes("benefits")
              ) {
                setErrorList((current) => {
                  const { benefits, ...rest } = current;
                  return rest;
                });
              }
              setBenefitInputValue(e.target.value);
            }}
            placeholder="Type here"
            onKeyDown={handleNewBenefit}
          />
          <div className="w-full flex flex-wrap gap-[6px]">
            {benefits.map((benefit, index) => (
              <button
                key={index}
                type="button"
                className={`mt-[8px] rounded-[68px] border border-[#E1E1EE] flex items-center py-[7px] px-[12px] ${
                  benefit.status
                    ? "hover:!bg-[#699c8f]"
                    : "hover:!bg-[#00b98e34]"
                }`}
                style={{
                  background: benefit.status
                    ? "#00B98D"
                    : "rgba(51, 52, 142, 0.03)",
                  transition: "background-color 0.5s",
                }}
                onClick={() => toggleBenefitStatus(index)}
              >
                <span
                  style={{
                    color: benefit.status ? "#FFFFFF" : "#252E4B",
                    transition: "color 0.5s",
                  }}
                >
                  {benefit.benefit}
                </span>
                {!benefit.status ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g id="add">
                      <path
                        id="Vector"
                        d="M4 8H12"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M8 12V4"
                        stroke="#252E4B"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g id="Right icon">
                      <path
                        id="Vector"
                        d="M4.66797 4.66602L11.3346 11.3327"
                        stroke="white"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        id="Vector_2"
                        d="M4.66732 11.3327L11.334 4.66602"
                        stroke="white"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                )}
              </button>
            ))}
          </div>
        </div>

        <div className="w-full flex flex-col mt-[28px]" id="description">
          <label
            className="text-sm font-normal ml-[8px] text-gray-800 not-italic"
            htmlFor="description"
          >
            Requirements
          </label>
          <TextAreaBox
            disabled={loading}
            size="200px"
            value={formJobdetails?.roles}
            onChange={(e) => {
              setJobdetailsInput("roles", e.target.value);
            }}
          />
        </div>

        <div className="w-full flex flex-col mt-[28px]" id="description">
          <label
            className=" text-sm font-normal ml-[8px] text-gray-800 not-italic"
            htmlFor="description"
          >
            Education
          </label>
          <TextAreaBox
            size="200px"
            disabled={loading}
            value={formJobdetails?.experienceEducation}
            onChange={(e) => {
              setJobdetailsInput("experienceEducation", e.target.value);
            }}
          />
        </div>

        {customAttributes?.map((item, index) => (
          <div
            className="w-full flex flex-col mt-[28px] border-t border-gray-500 pt-[28px] "
            id="description"
          >
            <label
              className=" text-sm required font-normal ml-[8px] text-gray-800 not-italic"
              htmlFor="description"
            >
              Custom attribute
            </label>
            <div className="flex justify-between gap-4">
              <InputBox
                disabled={loading}
                value={item?.name}
                placeholder={"Custom attribute name"}
                onChange={(e) => {
                  let temp = [...customAttributes];
                  temp[index].name = e.target.value;
                  setCustomAttributes(temp);
                }}
              />
              <button
                className="btn-sec btn-md h-[48px] "
                data-tooltip-id="tooltip"
                data-tooltip-content={"Delete"}
                onClick={() => {
                  let temp = [...customAttributes];
                  temp?.splice(index, 1);
                  setCustomAttributes(temp);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="red"
                  className="w-5 h-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-[28px]">
              <TextAreaBox
                disabled={loading}
                placeholder={"Description..."}
                size="200px"
                value={item?.value}
                onChange={(e) => {
                  let temp = [...customAttributes];
                  temp[index].value = e.target.value;
                  setCustomAttributes(temp);
                }}
              />
            </div>
          </div>
        ))}

        <div>
          <button
            className="mt-[20px] "
            onClick={() => {
              setCustomAttributes([
                ...customAttributes,
                { name: "", value: "" },
              ]);
            }}
          >
            + Add custom attribute
          </button>
        </div>

        <div className="w-full flex items-center gap-4 flex-row-reverse self-end mt-[28px]">
          <button
            className="btn-md btn-primary"
            disabled={
              customAttributes.some((item) => item.name.trim() === "") ||
              customAttributes.some((item) => item.value.trim() === "") ||
              loading ||
              formJobdetails?.jobType?.trim() === "" ||
              formJobdetails?.template_name?.trim() === "" ||
              formJobdetails?.description?.trim() === ""
            }
            onClick={(e) => {
              if (data) {
                update_template();
              } else {
                Create_new_temp();
              }
            }}
          >
            {loading ? (
              <ScaleLoader height={14} color="#ffffff" loading={true} />
            ) : (
              "Save"
            )}
          </button>
          <button
            className="btn-md btn-sec"
            onClick={() => {
              if (data) {
                setEdit();
              } else {
                nav(-1);
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    </core.JobFormContext.Provider>
  );
};

export default EditJobTemplate;
