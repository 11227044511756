import React, { useEffect, useState } from "react";
import Modal from "../../../modal/Modal";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");

const SkillItem = ({ skill, isSelected, onToggle }) => (
  <button
    className={`m-1 px-3 py-2 rounded-lg text-sm font-medium flex items-center justify-between transition-colors ${
      isSelected
        ? " bg-gray-300 text-gray-800 hover:bg-gray-400"
        : "border border-gray-300 text-gray-800 hover:bg-gray-200"
    }`}
    onClick={onToggle}
  >
    <span>{skill?.label}</span>
  </button>
);

function QuestionGenModal({ modalDismiss, type = "MCQ" }) {
  const { job, jobId } = useSelector((state) => {
    return {
      job: state?.JobExpandSlice?.description,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  console.log(job, type);

  const [skillGroups, setSkillGroups] = useState([]);
  const [newSkill, setNewSkill] = useState(""); // For new skill input

  const fetchSkillsGroup = async () => {
    try {
      const response = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_MCQ_QUESTION_STATUS + jobId,
        1,
        {},
      );
      if (response?.data) {
        if (response?.data?.mcq_criteria_status) {
          setSkillGroups(response?.data?.mcq_criteria_status);
          console.log(response?.data?.mcq_criteria_status);
        } else {
          setDefaultSkillsgrp();
          console.error("Failed to fetch skills group", response);
        }
      } else {
        console.error("Failed to fetch skills group", response);
        setDefaultSkillsgrp();
      }
    } catch (error) {
      console.error("Failed to fetch skills group", error);
      setDefaultSkillsgrp();
    }
  };

  useEffect(() => {
    fetchSkillsGroup();
  }, []);

  const setDefaultSkillsgrp = () => {
    if (job?.analytics) {
      console.log(job.analytics.skills);
      // Initialize skills with a 'status' key (true or false)
      const skillsWithStatus = job.analytics.skills.map((group) => ({
        ...group,
        skills: group.skills.map((skill) => ({
          ...skill,
          status: true, // Default status to false (not selected)
        })),
      }));
      setSkillGroups(skillsWithStatus);
    }
  };

  const toggleSkill = (groupIndex, skillLabel) => {
    setSkillGroups((prevGroups) =>
      prevGroups.map((group, i) => {
        if (i === groupIndex) {
          return {
            ...group,
            skills: group.skills.map((skill) =>
              skill.label === skillLabel
                ? { ...skill, status: !skill.status }
                : skill,
            ),
          };
        }
        return group;
      }),
    );
  };

  // Function to handle adding a new skill to the "Miscellaneous" group
  const addNewSkill = () => {
    if (!newSkill) return; // Don't add empty skills

    setSkillGroups((prevGroups) => {
      const miscGroupIndex = prevGroups.findIndex(
        (group) => group.criteria === "Misc",
      );

      // If "Miscellaneous" group exists, add skill to it
      if (miscGroupIndex !== -1) {
        return prevGroups.map((group, index) => {
          if (index === miscGroupIndex) {
            return {
              ...group,
              skills: [
                ...group.skills,
                { label: newSkill, status: true }, // Add new skill with default 'status: false'
              ],
            };
          }
          return group;
        });
      }

      // If "Miscellaneous" group doesn't exist, create it
      return [
        ...prevGroups,
        {
          criteria: "Misc",
          skills: [{ label: newSkill, status: true }],
        },
      ];
    });

    setNewSkill(""); // Clear input after adding
  };

  const filteredData = (data) => {
    // Remove skills with status: false
    const filter = data.map((group) => ({
      ...group,
      skills: group.skills.filter((skill) => skill.status === true), // Keep only skills with status: true
    }));
    return filter;
  };

  const [loading, setLoading] = useState(false);

  const handleGenerateQuestions = async () => {
    try {
      setLoading(true);
      const response = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_MCQ_QUESTIONS_GENERATE,
        1,
        {
          jobId: jobId,
          criterias: skillGroups,
          qsnGenCriteria: filteredData(skillGroups),
        },
      );
      if (response?.message == "OK") {
        setLoading(false);
        setMessage({
          type: "success",
          message:
            "Question generation has started successfully. Please allow some time for all questions to be generated. ",
        });
      } else {
        console.error("Failed to generate questions", response);
        setMessage({
          type: "error",
          message: "Something went wrong. Please try again",
        });
        setLoading(false);
      }
    } catch (error) {
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
      console.error("Failed to generate questions", error);
      setLoading(false);
    }
  };

  const [message, setMessage] = useState(null);

  const generatedescQuestion = async () => {
    setLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_QUESTIONS_GENERATE,
        // jobId,
        1,
        {
          jobId: jobId,
        },
      );
      if (response?.message == "OK") {
        setLoading(false);
        setMessage({
          type: "success",
          message:
            "Question generation has started successfully. Please allow some time for all questions to be generated. ",
        });
      } else {
        console.error("Failed to generate questions", response);
        setMessage({
          type: "error",
          message: "Something went wrong. Please try again",
        });
        setLoading(false);
      }
    } catch (error) {
      setMessage({
        type: "error",
        message: "Something went wrong. Please try again",
      });
      console.error("Failed to generate questions", error);
      setLoading(false);
    }
  };

  return (
    <Modal
      heading={`Generate ${type} questions`}
      subheading={"Generate the questions based on the selected skills"}
      modalDismiss={() => modalDismiss()}
      onSave={(e) => {
        if (type === "MCQ") {
          handleGenerateQuestions();
        } else {
          generatedescQuestion();
        }
      }}
      defaultButtons={{
        primaryLabel: loading ? (
          <ScaleLoader height={14} color="#ffffff" loading={true} />
        ) : (
          "Generate"
        ),
        primaryDisabled: loading,
      }}
      noBtns={message?.type === "success" ? true : false}
      className={"md:max-w-[60%] "}
    >
      {message ? (
        <div
          className={`text-white p-2 rounded-md ${
            message?.type === "success" ? "bg-green-500" : "bg-red-500"
          }`}
        >
          {message?.message}
        </div>
      ) : (
        <>
          <div className="max-h-[400px] w-full  overflow-auto flex flex-wrap">
            {Array?.isArray(skillGroups) && skillGroups?.length > 0 ? (
              skillGroups?.map((group, groupIndex) => (
                <div className="flex flex-wrap" key={group?.criteria}>
                  {Array?.isArray(group.skills) && group?.skills?.length > 0
                    ? group?.skills?.map((skill) => (
                        <SkillItem
                          key={`${group?.criteria}-${skill?.label}`}
                          skill={skill}
                          isSelected={skill?.status}
                          onToggle={() => toggleSkill(groupIndex, skill?.label)}
                        />
                      ))
                    : null}
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-500">
                No skill criteria available.
              </p>
            )}
          </div>

          {/* Input for adding new skills */}
          <div className="mt-4">
            <label
              className="block text-sm font-medium text-gray-700 "
              htmlFor="AssessmentName"
            >
              Add new skill (Press enter to add)
            </label>
            <div className="flex items-center gap-2">
              <input
                type="text"
                value={newSkill}
                onChange={(e) => setNewSkill(e.target.value)}
                className="border border-gray-300 rounded px-3 py-2 w-full "
                placeholder="Enter new skill"
                onKeyUp={(e) => {
                  if (e.key === "Enter") {
                    addNewSkill();
                  }
                }}
              />
              {/* <button
                onClick={addNewSkill}
                className=" bg-blue-500 text-white flex w-[50px] justify-center py-2 rounded"
              >
                Add
              </button> */}
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}

export default QuestionGenModal;
