import { createSlice } from "@reduxjs/toolkit";

// Initial state for the activeCard
const initialState = {
  activeCard: null,
};

const activeCardSlice = createSlice({
  name: "activeCard",
  initialState,
  reducers: {
    setActiveCard: (state, action) => {
      state.activeCard =
        state.activeCard === action.payload ? null : action.payload;
    },
  },
});

// Export the actions generated by createSlice
export const { setActiveCard } = activeCardSlice.actions;

// Export the reducer to be included in the store
export default activeCardSlice.reducer;
