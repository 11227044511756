import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const LandingText = () => {
  return (
    <header className="container lg:flex lg:mt-10 items-center">
      <div className="w-full flex flex-col justify-center items-center lg:block ">
        <h1 className="relative text-center text-slate-900 md:text-4xl text-xl font-black px-4 mb-2">
          Autonomous <span className=" text-blue-500">Agent </span> For
        </h1>
        <div>
          <Carousel
            autoPlay={true}
            axis={"vertical"}
            autoFocus={true}
            transitionTime={1000}
            showThumbs={false}
            showStatus={false}
            interval={1500}
            emulateTouch={false}
            infiniteLoop={true}
            showIndicators={false}
            stopOnHover={false}
            renderArrowPrev={() => {
              return <></>;
            }}
            renderArrowNext={() => {
              return <></>;
            }}
          >
            <div className="flex justify-center lg:justify-start w-full ">
              <h1 className="relative text-center text-slate-900 md:text-4xl text-xl font-black px-4 w-full">
                Hiring automation
              </h1>
            </div>
            <div className="flex text-left justify-center lg:justify-start w-full">
              <h1 className="relative text-center text-slate-900 md:text-4xl text-xl font-black px-4 w-full">
                Data enrichment
              </h1>
            </div>
            <div className="flex text-left justify-center lg:justify-start w-full">
              <h1 className="relative text-center text-slate-900 md:text-4xl text-xl font-black px-4 w-full">
                Document processing
              </h1>
            </div>
          </Carousel>
        </div>
      </div>
    </header>
  );
};

export default LandingText;
