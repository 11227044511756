import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import io from "socket.io-client";
import { useLocation } from "react-router-dom";
import { UserContext } from "./core";

const API_BASE =
  process.env.REACT_APP_ENV === "local"
    ? "http://localhost:3333"
    : process.env.REACT_APP_ENV === "development"
      ? "https://dev.api.sproutsai.com"
      : "https://api.sproutsai.com";

const socket = io(API_BASE);

const SocketContext = createContext();

export const chatContext = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [notification, setNotification] = useState([]);
  const [headerNotification, setHeaderNotification] = useState(0);
  const [toUser, setTouser] = useState("");

  useEffect(() => {
    if (user) {
      socket.emit("setup", user.id);
    }
  }, [user]);

  const location = useLocation();

  const chatdata = {
    socket,
    toUser,
    notification,
    setNotification,
    setTouser,
    headerNotification,
    setHeaderNotification,
  };

  const toUserRef = useRef(toUser);
  const headerNotificationRef = useRef(headerNotification);

  useEffect(() => {
    if (location.pathname.includes("/sms")) {
      toUserRef.current = toUser;
    } else {
      toUserRef.current = "";
    }
  }, [toUser, location.pathname]);

  useEffect(() => {
    socket.on("message", (message) => {
      console.log("messgae", message);
      if (toUserRef?.current?.length > 0) {
      } else {
        setNotification((prevnotif) => [
          ...prevnotif,
          { _id: message?.chatUser },
        ]);
      }
    });

    const handleNotification = (data) => {
      console.log("notification", data);
      // Increment headerNotificationRef.current and update state
      headerNotificationRef.current += 1;
      setHeaderNotification(headerNotificationRef.current);
    };

    socket.on("notification", handleNotification);
  }, []);

  useEffect(() => {
    headerNotificationRef.current = headerNotification;
  }, [headerNotification]);

  console.log(notification, toUser);

  return (
    <SocketContext.Provider value={chatdata}>{children}</SocketContext.Provider>
  );
};
