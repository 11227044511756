import React, { useState, useEffect, useCallback } from "react";
import {
  COLORS,
  ROLES,
  metricData,
  applicationSourceData,
  candidatePipeline,
  referralConversionRate,
  genderDiversity,
  interviewsPerHire,
  timeToFill,
  diversityMetrics,
  referralConversionRatesByQuarter,
  applicantLocations,
  costPerHireData,
  applicationCompletionRates,
  genderDiversityByRole,
  applicantLocationsByRole,
} from "./analyticsChartsData";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  AreaChart,
  Area,
} from "recharts";
import { Card, CardContent, CardHeader, CardTitle } from "./Cards";
import { TbZoomReset } from "react-icons/tb";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdZoomIn,
  MdZoomOut,
} from "react-icons/md";

const RecruiterChart = ({ ActiveJobsResponse = [] }) => {
  const [loading, setLoading] = useState(false);
  const [transformedDataState, setTransformedData] = useState([]);
  const [allStages, setAllStages] = useState([]);
  const [dynamicStageColors, setDynamicStageColors] = useState({});
  const [newApplications, setNewApplications] = useState([]);
  const [visibleBars, setVisibleBars] = useState(10);
  const [startIndex, setStartIndex] = useState(0);
  const transformData = (data) => {
    const allStagesSet = new Set();
    const transformedArray = data.map((job) => {
      const transformedJob = { name: job.position };
      let stagesSum = 0;

      job.stages.forEach((stage) => {
        allStagesSet.add(stage.name);
        const stageKey = stage?.name?.toLowerCase().replace(/ /g, "");
        transformedJob[stageKey] = stage.value;
        stagesSum += stage.value;
      });

      if (stagesSum === 0) {
        return null;
      }
      return transformedJob;
    });

    const transformedData = transformedArray.filter((job) => job !== null);
    return { transformedData, allStages: Array.from(allStagesSet) };
  };

  const iconStyle = {
    fontSize: "1.2rem",
    color: "black",
    cursor: "pointer",
  };
  const buttonStyle = {
    padding: "4px",
    minWidth: "28px",
    height: "28px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    outline: "none",
    background: "none",
    cursor: "pointer",
  };

  const disabledButtonStyle = {
    ...buttonStyle,
    color: "#bdbdbd",
    cursor: "not-allowed",
  };
  const assignColorsToStages = (stages) => {
    const stageColors = {};
    stages.forEach((stage, index) => {
      stageColors[stage] = COLORS[index % COLORS.length];
    });
    return stageColors;
  };
  const transformDataForNewApplications = (data) => {
    return data
      .map((job) => {
        const role = job.position;
        const applicationReviewStage = job.stages.find(
          (stage) => stage.name === "Application Review",
        );
        const applications = applicationReviewStage
          ? applicationReviewStage.value
          : 0;

        // Only include jobs with a non-zero application review value
        if (applications > 0) {
          return {
            role,
            applications,
          };
        }
        return null;
      })
      .filter((job) => job !== null);
  };

  useEffect(() => {
    setLoading(true);
    console.log("Original data:", ActiveJobsResponse);
    const { transformedData, allStages } = transformData(ActiveJobsResponse);
    console.log("Transformed data:", transformedData);
    setTransformedData(transformedData);
    setVisibleBars(transformedData.length);
    setAllStages(allStages);

    const dynamicColors = assignColorsToStages(allStages);
    setDynamicStageColors(dynamicColors);
    const newApps = transformDataForNewApplications(ActiveJobsResponse);
    setNewApplications(newApps);
    setLoading(false);
  }, [ActiveJobsResponse, loading]);

  const handleZoomIn = useCallback(() => {
    if (visibleBars > 5) {
      setVisibleBars((prev) => prev - 5);
    }
  }, [visibleBars]);

  const handleZoomReset = useCallback(() => {
    const length = transformedDataState.length;
    if (length !== visibleData.length) {
      setVisibleBars(length);
    }
  }, [visibleBars]);

  const handleZoomOut = useCallback(() => {
    if (visibleBars < transformedDataState.length) {
      setVisibleBars((prev) => prev + 5);
    }
  }, [visibleBars, transformedDataState.length]);

  const handleScroll = useCallback(
    (direction) => {
      setStartIndex((prev) => {
        const newIndex = direction === "up" ? prev - 1 : prev + 1;
        return Math.max(
          0,
          Math.min(newIndex, transformedDataState.length - visibleBars),
        );
      });
    },
    [visibleBars, transformedDataState.length],
  );

  if (
    ActiveJobsResponse.length === 0 ||
    transformedDataState.length === 0 ||
    loading
  ) {
    return null;
  }

  const visibleData = transformedDataState.slice(
    startIndex,
    startIndex + visibleBars,
  );

  return (
    <div className="p-2 " style={{ backgroundColor: "rgb(243, 245, 249)" }}>
      <div className="grid grid-cols-2 gap-[20px]">
        {/* <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Candidates in Pipeline Funnel
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={transformedDataState} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip />
                <Legend />
                {allStages.map((stage) => (
                  <Bar
                    key={stage}
                    dataKey={stage?.toLowerCase().replace(/ /g, "")}
                    name={stage}
                    stackId="stack"
                    fill={dynamicStageColors[stage]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card> */}
        <Card>
          <CardHeader>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <div className="flex flex-row justify-between items-center gap-x-2">
                  <h2 className="text-lg font-semibold">
                    Candidates in Pipeline Funnel
                  </h2>
                </div>

                <div className="flex flex-row gap-x-1 items-center ">
                  <div>
                    <span className="text-sm">
                      Showing {startIndex + 1} -{" "}
                      {Math.min(
                        startIndex + visibleBars,
                        transformedDataState.length,
                      )}{" "}
                      of {transformedDataState.length} jobs
                    </span>
                  </div>

                  <div className="flex flex-col gap-y-1">
                    <button
                      onClick={() => handleScroll("up")}
                      disabled={startIndex === 0}
                      style={
                        startIndex === 0 ? disabledButtonStyle : buttonStyle
                      }
                      className="hover:bg-gray-100 rounded"
                    >
                      <MdKeyboardArrowUp style={iconStyle} />
                    </button>
                    <button
                      onClick={() => handleScroll("down")}
                      disabled={
                        startIndex + visibleBars >= transformedDataState.length
                      }
                      style={
                        startIndex + visibleBars >= transformedDataState.length
                          ? disabledButtonStyle
                          : buttonStyle
                      }
                      className="hover:bg-gray-100 rounded"
                    >
                      <MdKeyboardArrowDown style={iconStyle} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardContent>
            <div className="flex items- justify-end gap-x-2">
              {/* <button onClick={handleZoomReset} size="sm"> */}
              <TbZoomReset style={iconStyle} onClick={handleZoomReset} />
              {/* </button> */}
              <button
                onClick={handleZoomIn}
                size="sm"
                disabled={visibleBars <= 5}
              >
                <MdZoomIn style={iconStyle} />
              </button>
              <button
                onClick={handleZoomOut}
                size="sm"
                disabled={visibleBars >= transformedDataState.length}
              >
                <MdZoomOut style={iconStyle} />
              </button>
            </div>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={visibleData} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis dataKey="name" type="category" width={150} />
                <Tooltip />
                <Legend />
                {allStages.map((stage) => (
                  <Bar
                    key={stage}
                    dataKey={stage?.toLowerCase().replace(/ /g, "")}
                    name={stage}
                    stackId="stack"
                    fill={dynamicStageColors[stage]}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              New Applications (to be reviewed)
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="99%" height={300}>
              <BarChart data={newApplications}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="role"
                  angle={-45}
                  textAnchor="end"
                  tick={(props) => (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        transform="rotate(-45)"
                        style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                      >
                        {props.payload.value.length > 6
                          ? `${props.payload.value.slice(0, 6)}...`
                          : props.payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis />
                <Tooltip />
                <Legend
                  wrapperStyle={{
                    opacity: "0",
                  }}
                />
                <Bar
                  dataKey="applications"
                  fill="#00C49F"
                  name="New Applications"
                />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        {/* second row */}
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Referral Conversion Rates by Quarter</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={referralConversionRatesByQuarter}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="quarter" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line
                  type="monotone"
                  dataKey="SoftwareEngineer"
                  stroke="#8884d8"
                />
                <Line
                  type="monotone"
                  dataKey="ProductManager"
                  stroke="#82ca9d"
                />
                <Line type="monotone" dataKey="DataAnalyst" stroke="#ffc658" />
                <Line
                  type="monotone"
                  dataKey="MarketingSpecialist"
                  stroke="#ff7300"
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Number of Interviews per Hire
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="99%" height={300}>
              <BarChart data={interviewsPerHire}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="role"
                  angle={-45}
                  textAnchor="end"
                  tick={(props) => (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        transform="rotate(-45)"
                        style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                      >
                        {props.payload.value.length > 6
                          ? `${props.payload.value.slice(0, 6)}...`
                          : props.payload.value}
                      </text>
                    </g>
                  )}
                />
                <YAxis />
                <Tooltip />
                <Legend
                  wrapperStyle={{
                    opacity: "0",
                  }}
                />
                <Bar dataKey="interviews" fill="#00C49F" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* third row */}
        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Application Completion Rates</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={applicationCompletionRates}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="role" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Area
                  type="monotone"
                  dataKey="Resume Submission"
                  stackId="1"
                  stroke="#8884d8"
                  fill="#8884d8"
                />
                <Area
                  type="monotone"
                  dataKey="Initial Assessment"
                  stackId="1"
                  stroke="#82ca9d"
                  fill="#82ca9d"
                />
                <Area
                  type="monotone"
                  dataKey="Technical Interview"
                  stackId="1"
                  stroke="#ffc658"
                  fill="#ffc658"
                />
              </AreaChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">
              Application Source Breakdown
            </h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={applicationSourceData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {applicationSourceData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip formatter={(value) => `${value}%`} />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        {/* fourth row */}
        <Card>
          <CardHeader>
            <h2 className="text-lg font-semibold">Gender Diversity by Role</h2>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="99%" height={300}>
              <BarChart
                layout="vertical"
                data={genderDiversityByRole}
                margin={{
                  top: 20,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis type="number" />
                <YAxis
                  type="category"
                  dataKey="role"
                  width={150}
                  tick={(props) => (
                    <g transform={`translate(${props.x},${props.y})`}>
                      <text
                        x={0}
                        y={0}
                        dy={16}
                        textAnchor="end"
                        transform="rotate(0)"
                        style={{ fontSize: "12px" }} // Optional: Adjust font size as needed
                      >
                        {props.payload.value.length > 20
                          ? `${props.payload.value.slice(0, 20)}...`
                          : props.payload.value}
                      </text>
                    </g>
                  )}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="Male" fill="#8884d8" name="Male" />
                <Bar dataKey="Female" fill="#82ca9d" name="Female" />
                <Bar dataKey="Non_binary" fill="#ffc658" name="Non-binary" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>

        <Card className="col-span-1">
          <CardHeader>
            <CardTitle>Applicant Locations by Role</CardTitle>
          </CardHeader>
          <CardContent>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={applicantLocationsByRole}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="role" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="California" stackId="a" fill="#8884d8" />
                <Bar dataKey="New_York" stackId="a" fill="#82ca9d" />
                <Bar dataKey="Texas" stackId="a" fill="#ffc658" />
                <Bar dataKey="Florida" stackId="a" fill="#ff7300" />
                <Bar dataKey="Illinois" stackId="a" fill="#a4de6c" />
                <Bar dataKey="Washington" stackId="a" fill="#d0ed57" />
                <Bar dataKey="Other" stackId="a" fill="#8dd1e1" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default RecruiterChart;
