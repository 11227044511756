import { async } from "q";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Header from "../../components/all/header/Header";
import BeatLoader from "react-spinners/BeatLoader";
import EducationForm from "../../components/candidate/profile/forms/Education";
import ExperienceForm from "../../components/candidate/profile/forms/Experience";
import PersonalForm from "../../components/candidate/profile/forms/Personal";
import Skills from "../../components/candidate/profile/forms/Skills";
import Profile from "../../components/candidate/profile/CandidateProfile";
import ConfirmProfile from "../../components/candidate/profile/ConfirmProfile";
import CustomButton from "../../layouts/Button";
const core = require("../../lib/core");

// take initial input data for education, experience and persional details
const education_data = {
  school: "",
  degree: "",
  major: "",
  start: "",
  end: "",
  ongoing: "",
  description: "",
  GPA: "",
};
const experience_data = {
  company: "",
  position: "",
  start: "",
  end: "",
  ongoing: "",
  description: "",
};
const initialState = {
  name: "",
  title: "",
  gender: "",
  location: "",
};
function CreateCandidateProfile() {
  const nav = useNavigate();
  // General variables
  const { user } = useContext(core.UserContext);
  const [parser, setParser] = useState(false);
  const [showSavebutton, setShowSavebutton] = useState("hidden");
  const [imgFile, setImgFile] = useState();
  const [img, setImg] = useState(
    "https://sproutsai-staging.s3.amazonaws.com/assets/avatar.png",
  );
  const [resumeFile, setResumeFile] = useState("Upload Resume");
  const [resumeFileName, setResumeFileName] = useState("Upload Resume");
  const [resumeBtnName, setResumeBtnName] = useState("Upload Resume");

  const [loading, setLoading] = useState(false);
  const [inputdata, setInputdata] = useState(initialState);

  // set intial state for education and experience
  const [educationArr, setEducationArr] = useState([education_data]);
  const [education, setEducation] = useState(education_data);
  const [experienceArr, setExperienceArr] = useState([experience_data]);
  const [experience, setExperience] = useState(experience_data);
  const [skillArr, setSkillArr] = useState([]);
  const [toolArr, setToolArr] = useState([]);
  const [criteriaSkilArray, setCriteriaSkilArray] = useState([]);

  // function to create input objects
  function setInput(key, value) {
    setInputdata({ ...inputdata, [key]: value });
  }

  // function for upload image and store in variable
  function onImgChange(file) {
    setImgFile(file);
    const reader = new FileReader();
    reader.onloadend = () => {
      setImg(reader.result);
    };
    reader.readAsDataURL(file);
  }

  // function for upload resume file and store in variable
  function onFileChange(file) {
    try {
      setResumeBtnName("Loading");
      setResumeFileName(file.name);
      setResumeFile(file);
      setResumeBtnName("Change Resume");
    } catch (err) {
      setResumeBtnName("Upload Resume");
    }
  }

  // upload image in s3 bucket
  async function upload_picture() {
    let formData = new FormData();
    formData.append("file", imgFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);
    fetch(core.USER_API.POST_UPLOAD_USER_PIC, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        uid: user?.id,
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {});
  }

  const [resumeLink, setResumeLink] = useState("");
  const [resumeData, setResumeData] = useState("");
  const [showNext, setShowNext] = useState(false);
  const [disablebutton, setDisablebutton] = useState(false);

  // uplaod resume in s3 bucket and it will give parsed resume data in response and we will set the variables to autofill the form data
  async function upload_resume() {
    setLoading(true);
    var formData = new FormData();
    formData.append("file", resumeFile);
    const token = await core.getData(core.data.ACCESS_TOKEN);

    try {
      await fetch(core.CANDIDATE_API.POST_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setResumeData(data.data.profile);
          inputdata.name = data.data.profile.name;
          inputdata.title = data.data.profile.title;
          setExperienceArr(data.data.profile.experience);
          setEducationArr(data.data.profile.education);
          setCriteriaSkilArray(data.data.profile.skills);
          setLoading(false);
          // showNext(!showNext)
          // toast.success("parsed successfully")
          // setResumeLink(data?.data?.link)
          handleSaveAndNext();
          setShowNext(false);
        });
    } catch (err) {
      setLoading(false);
      // showNext(true)
      toast.error("Please upload your resume ");
    }
  }

  async function add_skills(e) {
    e.preventDefault();
    try {
      inputdata.skills = skillArr;
      inputdata.tools = toolArr;
    } catch (err) {}
  }
  async function handleCreate(e) {
    e.preventDefault();
    create_profile(inputdata);
  }

  // Function for create profile
  async function create_profile(input_data) {
    input_data.resume = "";
    input_data.user = user.id;
    try {
      upload_picture();
      let response = await core.API(
        core.API_METHODS.PUT,
        core.CANDIDATE_API.PUT_UPDATE_PROFILE,
        1,

        {
          skills: criteriaSkilArray,
          name: input_data.name,
          title: input_data.title,
          bio: input_data.bio,
          education: educationArr,
          experience: experienceArr,
        },
      );
      nav("/dashboard", { replace: true });
      window.location.reload();
    } catch (err) {}
  }

  const [reload, setReload] = useState(0);

  // edit the experience data get from resume parser
  const handleChange = (index, key, value) => {
    setExperienceArr((prevExperience) => {
      const updatedExperience = [...prevExperience];
      updatedExperience[index][key] = value;
      return updatedExperience;
    });
  };
  // edit the education data get from resume parser
  const handleChangeEdu = (index, key, value) => {
    setEducationArr((prevEducation) => {
      const updatedEducation = [...prevEducation];
      updatedEducation[index][key] = value;
      return updatedEducation;
    });
  };

  // to add new experience
  const addEmptyExp = () => {
    setExperienceArr([
      ...experienceArr,
      {
        company: "",
        position: "",
        start: "",
        end: "",
        ongoing: "",
        description: "",
      },
    ]);
    setReload(reload + 1);
  };
  // to add new education
  const addEmptyEdu = () => {
    setEducationArr([
      ...educationArr,
      {
        school: "",
        degree: "",
        major: "",
        start: "",
        end: "",
        ongoing: "",
        description: "",
        GPA: "",
      },
    ]);
    setReload(reload + 1);
  };

  // const [checked, setChecked] = useState(inputdata?.ongoing ?? false);
  const date = new Date();
  const [todaysDate, setTodaysDate] = useState("");
  useEffect(() => {
    setTodaysDate(
      date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()),
    );
  }, [todaysDate]);

  function pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  const carouselRef = useRef(null);

  const handleSaveAndNext = () => {
    // Perform save logic here

    // Go to the next slide in the carousel
    if (carouselRef.current) {
      const currentSlide = carouselRef.current.querySelector(".active");
      const nextSlide = currentSlide.nextElementSibling;

      if (nextSlide) {
        nextSlide.click();
      }
    }
  };

  // frontend part is below
  return (
    <div className="page candidate-pages bg-[#F3F5F9]" id="create-profile">
      <div className="container no-padding">
        <div
          id="createCarousel"
          ref={carouselRef}
          className="carousel slide sub-container bg-[#FFF] overflow-scroll scrollbar-hide max-h-[100vh]"
          data-bs-interval="false"
          data-interval="false"
          data-pause="hover"
          data-ride="carousel"
        >
          {/* <!-- Indicators --> */}
          <ol className="carousel-indicators">
            <li
              data-target="#createCarousel"
              // data-slide-to="0"
              className="active"
            >
              <span></span>
              {resumeFileName != "Upload Resume" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="12" fill="#0B53DD" />
                  <path
                    d="M6 11.6923L9.9136 15.7063C10.0706 15.8673 10.3294 15.8673 10.4864 15.7063L18 8"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </li>
            <li
              data-target="#createCarousel"
              // data-slide-to="1"
            >
              <span></span>

              {inputdata?.name?.length > 0 &&
                inputdata?.title?.length > 0 &&
                inputdata?.bio?.length > 0 && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <circle cx="12" cy="12" r="12" fill="#0B53DD" />
                    <path
                      d="M6 11.6923L9.9136 15.7063C10.0706 15.8673 10.3294 15.8673 10.4864 15.7063L18 8"
                      stroke="white"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                    />
                  </svg>
                )}
            </li>
            <li
              data-target="#createCarousel"
              // data-slide-to="2"
            >
              <span></span>
              {experienceArr.filter(
                (obj) => !Object.values(obj).every((val) => val === ""),
              )?.length > 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="12" fill="#0B53DD" />
                  <path
                    d="M6 11.6923L9.9136 15.7063C10.0706 15.8673 10.3294 15.8673 10.4864 15.7063L18 8"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </li>
            <li
              data-target="#createCarousel"
              // data-slide-to="3"
            >
              <span></span>
              {criteriaSkilArray?.length > 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="12" fill="#0B53DD" />
                  <path
                    d="M6 11.6923L9.9136 15.7063C10.0706 15.8673 10.3294 15.8673 10.4864 15.7063L18 8"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </li>
            <li
              data-target="#createCarousel"
              // data-slide-to="4"
            >
              <span></span>
              {educationArr.filter(
                (obj) => !Object.values(obj).every((val) => val === ""),
              ).length > 0 && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle cx="12" cy="12" r="12" fill="#0B53DD" />
                  <path
                    d="M6 11.6923L9.9136 15.7063C10.0706 15.8673 10.3294 15.8673 10.4864 15.7063L18 8"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                  />
                </svg>
              )}
            </li>
            <li
              data-target="#createCarousel"
              // data-slide-to="5"
            >
              <span></span>
            </li>
          </ol>

          {/* <!-- Wrapper for slides --> */}
          <div className="carousel-inner" data-bs-interval="false">
            <div className="item active" id="upload-resume">
              <h1 className="headingText">{resumeFileName}</h1>
              <form
                className="col-xs-12 no-padding"
                encType="multipart/form-data"
                method="post"
                onSubmit={() => {
                  upload_resume();
                }}
              >
                <label className="col-xs-12 no-padding">
                  Upload your recent resume
                </label>
                <div className="col-xs-12 no-padding upload-section ">
                  <input
                    title={resumeBtnName}
                    type="file"
                    className="upload"
                    name="resume"
                    onChange={(e) => {
                      onFileChange(e.target.files[0]);
                    }}
                  />
                  <p>Supported formats: docx, pdf upto 2 MB</p>
                </div>
                <div className="parser-section col-xs-12 no-padding">
                  <input
                    disabled={disablebutton}
                    type="checkbox"
                    id="parser"
                    value={parser}
                    onChange={(e) => setShowNext(!showNext)}
                  />
                  <label htmlFor="parser">
                    Auto fill all the information from resume
                  </label>
                </div>
              </form>

              <div className="controller col-xs-12 no-padding ">
                {showNext ? (
                  <>
                    <button
                      className="right"
                      href="#createCarousel"
                      disabled={loading}
                      type="submit"
                      onClick={() => {
                        upload_resume();
                        setDisablebutton(true);
                      }}
                      hidden={showSavebutton}
                    >
                      {loading ? (
                        <BeatLoader
                          color="white"
                          loading={loading}
                          //cssOverride={override}
                          size={15}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : (
                        <span className="label">{"Next"}</span>
                      )}
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="right "
                      href="#createCarousel"
                      type="submit"
                      data-slide="next"
                      hidden={showSavebutton}
                    >
                      <span className="label">{"Next"}</span>
                    </button>
                  </>
                )}
              </div>
            </div>
            <div className="item" id="personal-slide">
              <h1 className="headingText">Personal Details</h1>
              <PersonalForm
                input_function={setInput}
                image_link={img}
                image_function={onImgChange}
                form_state={inputdata}
              />

              <div className="controller col-xs-12 no-padding">
                <button
                  className="left "
                  href="#createCarousel"
                  data-slide="prev"
                >
                  <span className="label">Back</span>
                </button>
                {inputdata?.name?.length > 0 &&
                  inputdata?.title?.length > 0 && (
                    <button
                      type="submit"
                      className="right"
                      href="#createCarousel"
                      data-slide="next"
                    >
                      <span className="label">Next</span>
                    </button>
                  )}
                {(inputdata?.name?.length == 0 ||
                  inputdata?.title?.length == 0 ||
                  inputdata?.name == undefined ||
                  inputdata?.title == undefined) && (
                  <button
                    type="submit"
                    className="right"
                    href="#createCarousel"
                    onClick={() =>
                      toast.error("Required field should not be left blank")
                    }
                  >
                    <span className="label">Next</span>
                  </button>
                )}
              </div>
            </div>
            <div className="item" id="experience-slide">
              <h1 className="headingText">Work experience</h1>
              {experienceArr?.length > 0 && (
                <div className="col-xs-12 no-padding all-exp">
                  {experienceArr.map((exp, index) => (
                    <div key={index} className="edit-candidate">
                      <h5 className="col-xs-12 no-padding">
                        Experience {index + 1}
                        <span
                          className="cursor-pointer"
                          onClick={() => {
                            let tempvar = experienceArr;
                            tempvar.splice(index, 1);
                            setExperienceArr(tempvar);
                            setReload(reload + 1);
                          }}
                        >
                          <svg
                            className="w-[18px] h-[18px]"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <g id="left icon">
                              <path
                                className="group-hover:stroke-[#910000]"
                                id="Vector"
                                d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                                stroke="#FF475D"
                                strokeLinecap="round"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </h5>
                      <label className="col-xs-12 no-padding">
                        Company Name:
                      </label>
                      <input
                        type="text"
                        className="col-xs-12"
                        value={exp["company"]}
                        onChange={(event) =>
                          handleChange(index, "company", event.target.value)
                        }
                      />

                      <label className="col-xs-12 no-padding">
                        Job Position:
                      </label>
                      <input
                        type="text"
                        className="col-xs-12"
                        value={exp["position"]}
                        onChange={(event) =>
                          handleChange(index, "position", event.target.value)
                        }
                      />

                      <div className="col-md-6 start ">
                        <label
                          htmlFor="start"
                          className="col-xs-12 no-padding required"
                        >
                          Start date
                        </label>
                        <input
                          className="col-md-12"
                          type="date"
                          name="start"
                          required="required"
                          onChange={(event) =>
                            handleChange(index, "start", event.target.value)
                          }
                          max={todaysDate}
                          value={exp?.start?.split("T")[0]}
                        />
                      </div>
                      <div className="col-md-6 end">
                        <label htmlFor="end" className="col-xs-12 no-padding">
                          End date
                        </label>
                        <input
                          className="col-xs-12 no-padding"
                          type="date"
                          name="final"
                          // onChange={(event) =>
                          //   handleChange(index, "end", event.target.value)
                          // }
                          // required={!checked}
                          disabled={experienceArr[index].ongoing}
                          min={exp?.start}
                          max={todaysDate}
                          value={
                            exp?.end?.split("T")[0] ?? exp?.eend?.split("T")[0]
                          }
                          onChange={(event) => {
                            if (new Date(event.target.value) < new Date()) {
                              handleChange(index, "end", event.target.value);
                            }
                          }}
                        />
                        <div className="col-xs-12 no-padding checkbox-input">
                          <input
                            type="checkbox"
                            value={exp?.ongoing}
                            onChange={(event) => {
                              // setChecked(event.target.checked);
                              handleChange(
                                index,
                                "ongoing",
                                event.target.checked,
                              );
                            }}
                          />
                          <label htmlFor="checkbox">
                            I currently work here
                          </label>
                        </div>
                      </div>

                      <label className="col-xs-12 no-padding">
                        Description:
                      </label>
                      <textarea
                        className="col-xs-12"
                        value={exp["description"]}
                        onChange={(event) =>
                          handleChange(index, "description", event.target.value)
                        }
                      />
                    </div>
                  ))}
                </div>
              )}
              {/* <ExperienceForm
                input_function={setExperienceInput}
                formState={experience}

              /> */}
              <div className="controller col-xs-12 no-padding">
                <button
                  className="left"
                  type="submit"
                  onClick={() => {
                    const hasEmptyObject = experienceArr.some((obj) =>
                      Object.values(obj).every((val) => val === ""),
                    );
                    if (hasEmptyObject) {
                    } else {
                      addEmptyExp();
                    }
                  }}
                >
                  <span className="label"> + Add work experience</span>
                </button>
                <button
                  className="left "
                  href="#createCarousel"
                  data-slide="prev"
                >
                  <span className="label">Back</span>
                </button>
                <button
                  className="right "
                  href="#createCarousel"
                  data-slide="next"
                  type="submit"
                >
                  <span className="label">Next</span>
                </button>
              </div>
            </div>
            <div className="item" id="skills-slide">
              <h1 className="headingText">Skills</h1>

              <Skills
                criteriaSkilArray={criteriaSkilArray}
                setCriteriaSkilArray={setCriteriaSkilArray}
              />

              <div className="controller col-xs-12 no-padding">
                <button
                  className="left "
                  href="#createCarousel"
                  data-slide="prev"
                >
                  <span className="label">Back</span>
                </button>
                <button
                  className="right "
                  href="#createCarousel"
                  data-slide="next"
                  onClick={add_skills}
                >
                  <span className="label">Next</span>
                </button>
              </div>
            </div>
            <div className="item" id="education-slide">
              <h1 className="headingText">Education</h1>
              {educationArr.map((edu, index) => (
                <div key={index} className="edit-candidate">
                  <h5 className="col-xs-12 no-padding">
                    Education {index + 1}
                    <span
                      className="cursor-pointer"
                      onClick={() => {
                        let tempvar = educationArr;
                        tempvar.splice(index, 1);
                        setEducationArr(tempvar);
                        setReload(reload + 1);
                      }}
                    >
                      <svg
                        className="w-[18px] h-[18px]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <g id="left icon">
                          <path
                            className="group-hover:stroke-[#910000]"
                            id="Vector"
                            d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                            stroke="#FF475D"
                            strokeLinecap="round"
                          ></path>
                        </g>
                      </svg>
                    </span>
                  </h5>
                  <label className="col-xs-12 no-padding">Institute:</label>
                  <input
                    type="text"
                    className="col-xs-12"
                    value={edu?.school}
                    onChange={(event) =>
                      handleChangeEdu(index, "school", event.target.value)
                    }
                  />
                  <div className="col-md-6 start ">
                    <label className="col-xs-12 no-padding">Degree:</label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={edu?.degree}
                      onChange={(event) =>
                        handleChangeEdu(index, "degree", event.target.value)
                      }
                    />
                  </div>
                  <div className="col-md-6 end ">
                    <label className="col-xs-12 no-padding">Score/CGPA:</label>
                    <input
                      type="text"
                      className="col-xs-12"
                      value={edu.GPA}
                      onChange={(event) =>
                        handleChangeEdu(index, "GPA", event.target.value)
                      }
                    />
                  </div>

                  <div className="col-md-6 start ">
                    <label
                      htmlFor="start"
                      className="col-xs-12 no-padding required"
                    >
                      Start date
                    </label>
                    <input
                      className="col-md-12"
                      type="date"
                      name="start"
                      required="required"
                      onChange={(event) =>
                        handleChangeEdu(index, "start", event.target.value)
                      }
                      max={todaysDate}
                      value={edu?.start?.split("T")[0]}
                      // onChange={(event) => {
                      //   if (new Date(event.target.value) < new Date()) {
                      //     input_function("start", event.target.value);
                      //   }
                      // }}
                    />
                  </div>
                  <div className="col-md-6 end">
                    <label htmlFor="end" className="col-xs-12 no-padding">
                      End date
                    </label>
                    <input
                      className="col-xs-12 no-padding"
                      type="date"
                      name="final"
                      onChange={(event) =>
                        handleChangeEdu(index, "end", event.target.value)
                      }
                      // required={!checked}
                      // disabled={checked}
                      min={edu?.start}
                      max={todaysDate}
                      // value={formState?.end?.split("T")[0] ?? formState?.eend?.split("T")[0]}
                      // onChange={(event) => {
                      //   if (new Date(event.target.value) < new Date()) {
                      //     input_function("eend", event.target.value);
                      //     input_function("end", event.target.value);
                      //   }
                      // }}
                    />
                  </div>
                  <label className="col-xs-12 no-padding">Description:</label>
                  <textarea
                    className="col-xs-12"
                    value={edu?.description}
                    onChange={(event) =>
                      handleChangeEdu(index, "description", event.target.value)
                    }
                  />
                </div>
              ))}

              {/* {educationArr?.length > 0 && (
                <div className="col-xs-12 no-padding all-edu">
                  {educationArr?.map((edu, i) => (
                    <div className="col-xs-12 no-padding edu-data" key={i}>
                      <div className="col-md-6 col-xs-12 no-padding left-section">
                        <h4>
                          {edu?.Degree} - {edu?.major}
                        </h4>
                        <h5>{edu?.institute}</h5>
                      </div>
                      <div className="col-md-5 col-xs-12 no-padding right-section">
                        <h5>

                          {new Date(edu?.start).toString()?.split(" ")[1]}{" "}
                          {new Date(edu?.start).toString()?.split(" ")[3]} -{" "}
                          {edu.ongoing == true ? (
                            <>
                              Present
                            </>
                          ) : (
                            <>
                              {edu.end != null &&
                                <>

                                  {new Date(edu?.end).toString()?.split(" ")[1]}{" "}
                                  {new Date(edu?.end).toString()?.split(" ")[3]}
                                </>
                              }
                            </>
                          )}
                        </h5>
                        <p>Grade : {edu.gpa}</p>
                      </div>
                      <button
                        className="col-xs-1 no-padding remove"
                        onClick={() => remove_education(edu)}
                      >
                        <img
                          src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/menu_cross.svg"
                          alt="remove"
                          className="img-responsive icon"
                        />
                      </button>
                      {edu.description != "" && (
                        <p className="col-xs-12 no-padding bottom-section">
                          {edu.description}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )} */}
              {/* <EducationForm
                input_function={setEducationInput}
                formState={education}
              /> */}
              <div className="controller col-xs-12 no-padding">
                <button
                  className="left"
                  type="submit"
                  onClick={() => {
                    const hasEmptyObject = educationArr.some((obj) =>
                      Object.values(obj).every((val) => val === ""),
                    );
                    if (hasEmptyObject) {
                    } else {
                      addEmptyEdu();
                    }
                  }}
                >
                  <span className="label">+ Add education</span>
                </button>
                <button
                  className="left "
                  href="#createCarousel"
                  data-slide="prev"
                >
                  <span className="label">Back</span>
                </button>
                <button
                  className="right "
                  href="#createCarousel"
                  data-slide="next"
                >
                  <span className="label">Next</span>
                </button>
              </div>
            </div>
            <div className="item" id="profile-page">
              {/* <h1 className="headingText"></h1> */}
              <div className="col-xs-12 no-padding" id="ConfirmationPage-slide">
                <ConfirmProfile
                  educationData={educationArr}
                  experienceData={experienceArr}
                  persionalData={inputdata}
                  skill={criteriaSkilArray}
                  image_link={img}
                />
              </div>
              <div className="controller col-xs-12 no-padding">
                <button
                  className="left"
                  href="#createCarousel"
                  data-slide="prev"
                >
                  <span className="label">Back</span>
                </button>
                <button className="right " type="submit" onClick={handleCreate}>
                  <span className="label">Save</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default CreateCandidateProfile;
