import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
const core = require("../../../../lib/core");

const Search = ({ disableSearch }) => {
  const ref = useRef();
  const [searchTxt, setSearchTxt] = useState("");
  const [showMenu, setShowMenu] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearchTxtUpdate = async () => {
    // Place your function logic here
    setShowMenu(true);
    const list = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST_PERSONAL +
        `?template_name=${searchTxt}`,
      1,
    );
    setSearchResults(list?.data ?? []);
  };

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setShowMenu(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (searchTxt.length > 0) handleSearchTxtUpdate();
  }, [searchTxt]);
  return (
    <div ref={ref} className="w-full absolute bottom-12 bg-white">
      <input
        value={searchTxt}
        onChange={(e) => setSearchTxt(e.target.value)}
        placeholder="Search here..."
        className={`absolute bottom-0 w-full h-8 border border-gray-400 p-2 rounded-lg text-base shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
        type="text"
      />
      {showMenu && searchResults?.length > 0 ? (
        <div className="absolute bottom-12 w-full h-auto max-h-[320px] flex flex-col py-1 bg-white border border-gray-300 overflow-y-auto rounded-lg">
          {searchResults?.map((result, index) => (
            <Link to={"/templates/job/" + result?._id} onClick={disableSearch}>
              <h2
                key={index}
                className="w-full h-auto p-2 bg-white hover:bg-gray-300 text-sm not-italic text-gray-800 truncate hover:text-blue-900"
              >
                {result?.template_name}
              </h2>
            </Link>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Search;
