import React from "react";
import * as core from "./../../../../lib/core";

const MeetingauthCard = ({
  Logo,
  Name,
  Link,
  disabled,
  authorized,
  authType,
  email_auth_type,
}) => {
  const handleAuthorize = async () => {
    if (authType === 1) {
      if (!disabled && !authorized) window.location.assign(Link);
    } else if (authType === 2) {
      localStorage.setItem("email_auth_type", email_auth_type);
      const json = await core.API(
        core.API_METHODS.POST,
        Link + "?redirect_uri=" + window.location.href,
        1,
        {},
      );
      if (json?.url) window.open(json?.url, "_blank");
    }
  };
  const cardStyle = disabled
    ? "border border-gray-400 bg-gray-200 opacity-50 cursor-not-allowed"
    : "border border-blue-800 shadow-md bg-white";

  const buttonStyle = disabled
    ? "btn-md btn-primary opacity-50 cursor-not-allowed"
    : "btn-md btn-primary";

  const buttonSecondaryStyle = disabled
    ? "btn-md btn-primary opacity-50 cursor-not-allowed"
    : "btn-md text-[green] bg-[#E9F7F1] border-[#2DCD8D] cursor-pointer";

  return (
    // <div
    //   className={`w-1/3 p-[12px] flex flex-col justify-center items-center rounded-[12px] items-start gap-4 ${cardStyle}`}
    // >
    //   <div className="flex flex-col gap-3 justify-center items-center">
    //     <span className="">{Logo}</span>
    //     <h3 className="text-lg font-base">{Name}</h3>
    //   </div>
    //   <div>
    //     <button
    //       className={authorized ? buttonSecondaryStyle : buttonStyle}
    //       onClick={handleAuthorize}
    //       disabled={disabled}
    //     >
    //       {authorized ? "Authorized" : "Authorize"}
    //     </button>
    //   </div>
    // </div>
    <button
      onClick={handleAuthorize}
      disabled={disabled}
      className={`flex gap-3 items-center | p-2 px-4 | border-2 border-gray-300 rounded-md | cursor-pointer | hover:shadow-md | w-[240px] ${authorized ? "border-green-900 text-green-900 bg-green-50 opacity-80" : disabled ? "bg-gray-500 opacity-60 cursor-not-allowed" : ""}`}
    >
      <span className="w-5">{Logo}</span>
      <span className=" font-base">{Name}</span>
    </button>
  );
};

export default MeetingauthCard;
