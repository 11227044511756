import { useEffect, useState } from "react";
import "./VoiceCall.css";
import Phone from "./Phone";
import { useDispatch } from "react-redux";
import { generateVoiceTokenService } from "../../redux/services/voiceService";
import { useSelector } from "react-redux";
import SingleUser from "../../components/voice/leftSideBar/SingleUser";
// import { getToken } from "./services";

const VoiceCall = ({
  checkResource = false,
  setHideCallBar,
  fetchNumber,
  chatDetail,
}) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const { tokenData } = useSelector((state) => {
    return { tokenData: state?.voiceSlice?.token };
  });

  console.log(chatDetail);

  const init = async () => {
    let payload = {
      identity: "+918005699799",
      room: "123456",
    };
    dispatch(generateVoiceTokenService(payload));
  };

  useEffect(() => {
    if (tokenData) {
      setToken(tokenData?.data?.message);
    }
  }, [tokenData]);

  useEffect(() => {
    init();
  }, []);

  return (
    <div className="w-[256px] ">
      {token ? (
        <Phone
          checkResource={checkResource}
          token={token}
          setHideCallBar={setHideCallBar}
          fetchNumber={fetchNumber}
          chatDetail={chatDetail}
        />
      ) : (
        <div
          className=" bg-[#18181B]  w-[256px] p-[8px] rounded-[12px]"
          onClick={() => init()}
        >
          Click to connect
        </div>
      )}
    </div>
  );
};

export default VoiceCall;
