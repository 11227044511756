import { combineReducers } from "redux";
import chimeMeetingReducer from "./slices/chimeSlice";
import createJobReducer from "./slices/CreateJobSlice";
import jobCandidateMatch from "./slices/JobMatchCandidatesSlice";
import JobExpandSlice from "./slices/JobExpandSlice";
import dashboardSlice from "./slices/DashboardSlice";
import { atsIntegrationReducer } from "./slices/atsIntegrationSlice";
import { getWebhooksAtsIntegrationSliceReducer } from "./slices/atsIntegrationSlice";
import voiceSlice from "./slices/voiceSlice";
import resumeUploadStatusSlice from "./slices/recruiter/resumeUploadStatusSlice";
import JobBoardSlice from "./slices/JobBoardSlice";
import UserInfoSlice from "./slices/UserInfoSlice";
import postedJobsAnalyticsSlice from "./slices/postedJobsAnalyticsSlice";
import activeCardSlice from "./slices/activeCardSlice";

const rootReducer = combineReducers({
  chimeMeeting: chimeMeetingReducer,
  createJob: createJobReducer,
  jobCandidateMatch: jobCandidateMatch,
  JobExpandSlice: JobExpandSlice,
  dashboardSlice: dashboardSlice,
  atsIntegrationSlice: atsIntegrationReducer,
  getWebhooksAtsIntegrationSlice: getWebhooksAtsIntegrationSliceReducer,
  voiceSlice: voiceSlice,
  resumeUploadStatusSlice: resumeUploadStatusSlice,
  JobBoardSlice: JobBoardSlice,
  UserInfoSlice: UserInfoSlice,
  postedJobsAnalyticsSlice: postedJobsAnalyticsSlice,
  activeCardSlice: activeCardSlice,
  // Add other reducers here
});

export default rootReducer;
