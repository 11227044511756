// JobIntegrationCard.js
import React, { useState, useContext, useEffect, useRef } from "react";
import { UserContext } from "../../../lib/core";
const core = require("../../../lib/core");
import ColorModal from "./ColorModal";
import SelectJobModal from "./SelectJobModal";
import Meetingauthorization from "./PlatformAuthentication/Meetingauthorization";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
const JobIntegrationCard = () => {
  const { user, setUser, getUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [toggle, setToggle] = useState(false);
  const [theme, setTheme] = useState("blue");
  const [selectedColor, setSelectedColor] = useState("");
  const [buttonText, setButtonText] = useState("Apply Theme");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isThemeModalOpen, setIsThemeModalOpen] = useState(false);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [jobList, setJobList] = useState([]);
  const dropdownRef = useRef(null);
  const [localToggle, setLocalToggle] = useState(false);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loadListings, setLoadListings] = useState(false);
  const handleToggle = () => {
    const newToggleState = !localToggle;
    setLocalToggle(newToggleState);

    update_org(selectedColor, newToggleState)
      .then(() => {})
      .catch((error) => {
        console.error("update_org error:", error);
      });
  };

  useEffect(() => {
    if (user) {
      setLocalToggle(user?.organization?.careers_page_integration);
      setToggle(user?.organization?.careers_page_integration);
      setSelectedColor(user?.organization?.theme_color);
      setTheme(user?.organization?.theme_color);
      setShowFilter(user?.organization?.showFilter ?? false);
    }
  }, [user]);

  const update_org = async (theme, int_status) => {
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.ORGANIZATION_API.UPDATE_ORGANIZATION,
        1,
        {
          careers_page_integration: int_status,
          theme: theme,
          org_id: user.organization._id,
        },
      );
      // Update the user context with the new data
      getUser();
      return Promise.resolve();
    } catch (error) {
      console.error("Error updating organization:", error);
      return Promise.reject(error);
    }
  };

  const updateOrgAttr = async (show) => {
    try {
      await core.API(
        core.API_METHODS.PUT,
        core.ORGANIZATION_API.UPDATE_ORGANIZATION,
        1,
        {
          org_id: user.organization._id,
          showFilter: show,
        },
      );
      console.log("updateOrgAttr completed successfully");
    } catch (error) {
      console.error("Error updating organization:", error);
    }
  };

  const fetchJob = async (currentPage = 1) => {
    setLoadListings(true);
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_ORG_JOB_LIST +
          `/${user?.organization?.search_name}?page=${currentPage}&posted=false`,
        1,
        {},
      );

      if (json && json.data && json.data?.content?.length > 0) {
        setJobList(json.data.content);
        setTotalPage(json.data.pagination.totalPages || 0);
        setLoadListings(false);
      } else {
        setJobList(false);
        setTotalPage(json.data.pagination.totalPages || 0);
        setLoadListings(false);
      }
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setLoadListings(false);
      setJobList(false);
    }
  };
  const removeJob = (index) => {
    setJobList((prevJobList) => prevJobList.filter((_, i) => i !== index));
  };

  const handlePageClick = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const isInitialMount = useRef(true);

  const sendCallback = async () => {
    console.log("I am inside the callack");
    // console.log(window.location.search);
    sessionStorage.setItem(
      "outlook_token",
      window?.location?.search?.split("?")[1]?.split("=")[1],
    );
    const type = localStorage.getItem("email_auth_type");
    isInitialMount.current = false;
    let json;
    if (type == "outlook") {
      try {
        json = await core.API(
          core.API_METHODS.POST,
          core.OUTLOOK_API.POST_OUTLOOK_CALLBACK +
            window.location.search +
            "&redirect_uri=" +
            window.location.href,
          1,
        );
        if (json.status == 400) {
          toast.error(json?.message || "Something went wrong", {
            position: "bottom-right",
            autoClose: 2000,
          });
        } else
          toast.success("Authentication successful", {
            position: "bottom-right",
            autoClose: 2000,
          });
      } catch (err) {
        console.log(err);
        toast.error(err?.response?.data?.message || "Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
    } else {
      console.log("I am inside the else and the callback in google");
      json = await core.API(
        core.API_METHODS.POST,
        core.GOOGLE_API.POST_EMAIL_CALLBACK +
          window.location.search +
          "&redirect_uri=" +
          window.location.href,
        1,
      );
      console.log("logging json", json);
      if (json?.status == 400) {
        toast.error(json?.message || "Something went wrong", {
          position: "bottom-right",
          autoClose: 2000,
        });
      } else {
        console.log("logging json but in else success", json);
        toast.success("Authentication successful", {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
    }
    if (String(json?.message)?.toLowerCase() == "success") {
      getUser();
      nav("/settings/integration");
    }
  };

  useEffect(() => {
    if (
      String(window?.location?.search)?.length > 0 &&
      isInitialMount.current
    ) {
      let outlook_token = sessionStorage.getItem("outlook_token");

      let newToken = window?.location?.search?.split("?")[1]?.split("=")[1];
      // console.log(outlook_token, newToken);
      if (outlook_token != newToken) {
        isInitialMount.current = false;
        sendCallback();
      } else {
        navigate("/settings/integration");
      }
    }
  }, []);

  return (
    <>
      <div>
        <Meetingauthorization />
      </div>
      <div className="bg-white shadow-md rounded-lg py-4 px-4 text-justify w-full mx-auto">
        <div className="w-full flex justify-between items-center border-b border-gray-400">
          <div className="w-3/4">
            <h2 className="text-lg font-medium text-gray-800">
              Job Integration Permission
            </h2>
            <p className="text-xs  pt-1 text-gray-700 mb-3">
              Provide the consent for Job posting integration
            </p>
          </div>
          <div
            className="flex flex-col relative w-auto text-gray-700"
            ref={dropdownRef}
          >
            {toggle && (
              <button
                onClick={() => {
                  fetchJob();
                  setIsJobModalOpen(true);
                }}
                className="btn-sec btn-md"
              >
                Manage job posts
              </button>
            )}
          </div>
        </div>
        <div className="text-gray-700 text-sm pt-8">
          <p className="text-xs font-medium text-gray-700 mb-4">
            We would like to integrate the job posts with your career page. This
            integration will allow us to post job openings directly on our
            website, making it easier for candidates to apply. Please review the
            information below and provide your consent for the integration.
          </p>
          <ul className="list-disc font-medium list-inside text-xs text-gray-700 mb-8">
            <li>Provide a logo and select a theme.</li>
            <li>Provide job details for the job post.</li>
            <li>Ensure all job posts are up-to-date and relevant.</li>
          </ul>
          {toggle && (
            <div className="mb-4">
              <label
                htmlFor="theme-selector"
                className="text-xs font-semibold text-gray-700 mb-2 block"
              >
                Select Theme
              </label>
              <button
                onClick={() => setIsThemeModalOpen(true)}
                className="w-1/4 p-2 text-xs border border-gray-400 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline cursor-pointer"
              >
                {selectedColor ? selectedColor : "Select a theme"}
              </button>
            </div>
          )}

          {toggle && (
            <div className="w-full mb-4">
              <label className="text-xs font-semibold text-gray-700 mb-2 block">
                Enable job filters
              </label>
              <div className="inline-flex w-auto border border-gray-600 rounded-lg">
                <button
                  onClick={() => {
                    setShowFilter(true);
                    updateOrgAttr(true);
                  }}
                  className={`px-6 py-2 font-bold rounded-l-lg ${showFilter ? "bg-blue-800 text-white" : "bg-gray-200 text-gray-800"}`}
                >
                  Enable
                </button>
                <button
                  onClick={() => {
                    setShowFilter(false);
                    updateOrgAttr(false);
                  }}
                  className={`px-6 py-2 font-bold rounded-r-lg ${!showFilter ? "bg-gray-600 text-white" : "bg-gray-200 text-gray-800"} `}
                >
                  Disable
                </button>
              </div>
            </div>
          )}
          {/* <div className="text-gray-700 text-sm flex flex-col items-center justify-center pt-8">
            <p className="text-xs font-medium text-center text-gray-700 mb-4">
              We prioritize user privacy and ensure that your data is only used for providing our scheduling service. We do not share data with third-party AI tools or build databases using it. Your data is secure and not shared with any third parties for AI processing or any other purposes beyond the intended scheduling service.
            </p>
            <p className="text-xs font-medium text-center text-gray-700 mb-4">Our app complies with the Google API Services User Data Policy, including the Limited Use requirements. Read more <a href="https://developers.google.com/terms/api-services-user-data-policy" className="text-blue-800 font-semibold hover:text-blue-950 hover:no-underline text-decoration:no-underline">here</a>.</p>

          </div> */}

          <div className="flex items-center justify-center mt-2 pt-5">
            <div className="flex items-center">
              <a
                href="/settings/guidelines"
                className="text-blue-800 font-semibold hover:text-blue-950 hover:no-underline text-decoration:no-underline"
                target="_blank"
              >
                Click here to view the guidelines
              </a>
            </div>
          </div>
          <div className="flex items-center justify-center pt-10 mt-5">
            <div className="flex">
              <button
                onClick={handleToggle}
                className={`px-6 py-2 font-bold rounded-l-lg ${toggle ? "bg-blue-800 text-white" : "opacity-50 bg-gray-300 text-gray-800"}`}
              >
                Allow
              </button>
              <button
                onClick={handleToggle}
                className={`px-6 py-2 font-bold rounded-r-lg ${!toggle ? "bg-red-900 text-white" : "opacity-50 bg-gray-300 text-gray-800"}`}
              >
                Disable
              </button>
            </div>
          </div>
        </div>
      </div>

      <ColorModal
        isOpen={isThemeModalOpen}
        onClose={() => setIsThemeModalOpen(false)}
        color={selectedColor}
        setColor={setSelectedColor}
        setTheme={(hexInput) => {
          setTheme(hexInput);
          update_org(hexInput, toggle);
        }}
      />
      <SelectJobModal
        isOpen={isJobModalOpen}
        onClose={() => setIsJobModalOpen(false)}
        jobList={jobList}
        removeJob={removeJob}
        reloadList={fetchJob}
        totalPage={totalPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        handlePageClick={handlePageClick}
        fetchJob={fetchJob}
        loadListings={loadListings}
        setLoadListings={setLoadListings}
      />
    </>
  );
};

export default JobIntegrationCard;
