import React from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";

const RecruiterLayout = ({ children, sidebarMenu, active }) => {
  return (
    <div className="flex lg:w-screen h-screen w-[1024px] lg:overflow-x-hidden overflow-x-scroll overflow-y-hidden">
      <JobSidebar active={active} CandidateFilter={sidebarMenu} />

      <div className="grow flex flex-col gap-3">
        <HeaderNew />
        <div className="grow pb-3 max-h-full overflow-hidden">{children}</div>
      </div>
    </div>
  );
};

export default RecruiterLayout;
