import { useEffect, useMemo, useState } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./screens/auth/Login";
import NewUserRegister from "./screens/auth/NewUserRegister";
import CreateProfile from "./screens/user/CreateProfile";
import Dashboard from "./screens/user/Dashboard";

import ProfilePage from "./screens/candidate/ProfilePage";
import AppliedJobs from "./screens/candidate/dashboard/AppliedJobs";
import SavedJobs from "./screens/candidate/dashboard/SavedJobs";
// import JobDescription from "./screens/jobBoard/Description";

// Recruiter
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";
import Zoom from "./components/candidate/tempFolder/Zoom";
import GoogleCal from "./components/recruiter/GoogleCal";
import CandidateDrawer from "./components/recruiter/candidate/CandidateDrawer";
import CompanyDetails from "./components/recruiter/company/CompanyDetails";
import CreateSequence from "./components/recruiter/sequencing/CreateSequence";
import SequencePageContent from "./components/recruiter/sequencing/SequencePageContent";
import Status from "./components/recruiter/sequencing/tabs/Status";
import Structure from "./components/recruiter/sequencing/tabs/Structure";
import CreateTemplate from "./components/recruiter/recruiterTemplates/CreateTemplate";
import EditJobTemplate from "./components/recruiter/recruiterTemplates/EditJobTemplate";
import TemplatePageContent from "./components/recruiter/recruiterTemplates/TemplatePageContent";
import TemplateStructure from "./components/recruiter/recruiterTemplates/tabs/Structure";
import TemplateStructure2 from "./components/recruiter/recruiterTemplates/tabs/jobTempStructure";
import LockedPage from "./layouts/LockedPage";
import { SocketProvider } from "./lib/ChatContext";
import Admin from "./screens/admin/Admin";
import AdminDashboard from "./screens/admin/Dashboard";
import NewUser from "./screens/admin/NewUser";
import Role from "./screens/admin/Role";
import TemplateManager from "./screens/admin/TemplateManager";
import User from "./screens/admin/User";
import ForgotPassword from "./screens/auth/ForgotPassword";
import InviteLogin from "./screens/auth/InviteLogin";
import InviteAuth from "./screens/auth/inviteAuth/InviteAuth";
import InvitePassword from "./screens/auth/inviteAuth/InvitePassword";
import InviteSignUp from "./screens/auth/inviteAuth/InviteSignUp";
import InviteVerifyCode from "./screens/auth/inviteAuth/InviteVerifyCode";
import Assessment from "./screens/candidate/assessment/Assessment";
import AssessmentDashboard from "./screens/candidate/assessment/Assessmentdashboard";
// import JobListing from "./screens/jobBoard/JobListing";
import AssessmentEvalReport from "./screens/recruiter/AssessmentEvalReport";
import ChatRecruiter from "./screens/recruiter/ChatRecruiter";
import CreateCompany from "./screens/recruiter/CreateCompany";
import CreateJob from "./screens/recruiter/CreateJob";
import EditCompany from "./screens/recruiter/EditCompany";
import GmailVerificationComplete from "./screens/recruiter/GmailVerificationComplete";
import HandPickedCandidate from "./screens/recruiter/HandPickedCandidate";
import { JobTemplateScreen } from "./screens/recruiter/JobTemplateScreen";
import RecruiterProfile from "./screens/recruiter/RecruiterProfile";
import EvaluationDoc from "./screens/recruiter/SubmitCandidate";
import TemplatePage from "./screens/recruiter/TemplatePage";
import Bot from "./screens/recruiter/botComponent/Bot";
import BrowseCandidates from "./screens/recruiter/candidate/BrowseCandidates";
import CandidateEvaluation from "./screens/recruiter/candidate/CandidateEvaluation";
import CompanyList from "./screens/recruiter/companyProfile/CompanyList";
import LiveMeeting from "./screens/recruiter/instantMeeting/LiveMeeting";
import MeetingNotes from "./screens/recruiter/instantMeeting/MeetingNotes";
import JobDetailPage from "./screens/recruiter/jobs/JobExpandedNew";
import SequencePage from "./screens/recruiter/sequence/SequencePage";
import ChartsTemplate from "./screens/temp/Chart";
import FormBuilder from "./screens/temp/FormBuilder";
import Sms from "./screens/twilio/Sms";
import Twilio from "./screens/twilio/Twilio";
import Message from "./screens/user/Message";
import NewSetting from "./screens/user/setting/NewSetting";
import VoiceCall from "./screens/voice/VoiceCall";
import "./style/all/style.scss";
// import jobTempStructure from "./components/recruiter/recruiterTemplates/tabs/jobTempStructure";
// import JobTemplatePageContent from "./components/recruiter/recruiterTemplates/JobTemplatePageContent";
import CandidatePool from "./screens/recruiter/candidate/CandidatePool";
// import JobTemplatePageContent from "./components/recruiter/recruiterTemplates/JobTemplatePageContent";
import { GoogleMeet } from "./components/recruiter/candidate/icons/Icons";

import Jobs from "./screens/jobPost/Jobs";
// import JobDescr from "./screens/jobPost/JobDescr";
import JobForm from "./screens/jobPost/JobForm";
import JobDesc from "./screens/jobPost/jobDescription/JobDesc";

import JobSubmitted from "./screens/jobPost/JobSubmitted";
import Guidelines from "./screens/user/setting/Guidelines";
// import { ThemeContext } from "./screens/context/ThemeContext";
import { ThemeProvider } from "./context/ThemeContext";
import Avavilabiltycheck from "./screens/recruiter/Avavilabiltycheck";
import VideoRecording from "./components/recruiter/job/assessment/VideoRecording";
import CreateCandidateProfile from "./screens/candidate/CreateCandidate";
import CreateNewProfile from "./screens/candidate/CreateNewProfile";
import CandidatePage from "./screens/recruiter/candidate/CandidatePage";
import PublicReviewCandidatepage from "./components/recruiter/job/candidates/PublicReviewCandidatepage";
import LeadList from "./screens/leadOnboard/LeadList";
import LeadsCandidatesList from "./screens/leadOnboard/LeadsCandidatesList";
import ProtectedRouteComponent from "./layouts/ProtectedRouteComponent";
import InvitedFeedback from "./components/recruiter/candidate/interview/interviewFeedback/InvitedFeedback";
import General from "./screens/user/setting/General";
import Password from "./screens/user/setting/Password";
import JobIntegrationCard from "./screens/user/setting/JobIntegrationCard";
import Messaging from "./screens/user/setting/Messaging";
import BrowsingAgent from "./screens/user/setting/BrowsingAgent";
import Notification from "./screens/user/setting/Notification";

const core = require("./lib/core");
export default function App() {
  const [user, setUser] = useState();
  const nav = useNavigate();
  const [profile, setProfile] = useState();

  const [resources, setResources] = useState([]);
  const [role, setRole] = useState();

  const userMemo = useMemo(
    () => ({ user, setUser, profile, resources, role }),
    [user, profile, resources, role],
  );
  const loc = useLocation();
  useEffect(() => {
    if (
      loc.pathname.includes("job-post") ||
      loc?.pathname?.includes("availability") ||
      loc?.pathname?.includes("outsource")
    ) {
    } else {
      if (core.checkAuth()) {
        getUser();
      } else {
        core.logout();
        console.log(loc);
        // nav("/login", { state: loc.pathname });
        nav("/login");
      }
    }
  }, []);
  // Get user details

  console.log("calling");
  useEffect(() => {
    const params = new URLSearchParams(loc.search);
    const auth = params.get("auth");
    const service = params.get("service");
    const message = params.get("message");

    const isAuthTrue = auth === "true";
    const isAuthFalse = auth === "false";

    if ((isAuthTrue || isAuthFalse) && service && message) {
      if (isAuthTrue) {
        toast.success(message);
      } else if (isAuthFalse) {
        toast.error(message);
      }

      // Clear the URL parameters without adding to the history stack
      nav("/settings", { replace: true });
      window.history.replaceState({}, document.title, loc.pathname);
    }
  }, [loc, nav]);

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setUser(json?.data?.user);
      } else {
        console.error("There is no user with these credentials");
        core.logout();
      }
    } catch (err) {
      console.error(err);
      core.logout();
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { resources, role } = await core.getResources();
      setResources(resources);
      setRole(role);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user?.id && user?.stage != "COMPLETED") {
      nav("/register", { replace: true });
      return;
    }
    // if (user?.account == 1 && user?.active != 1) {
    //   nav("/unactive");
    // }
  }, [user]);
  const ProtectedRoute = ({ children }) => {
    if (core.checkAuth()) {
      return children;
    } else {
      return <Navigate to="/login" state={loc?.pathname + loc.search} />;
    }
  };

  console.log("userMemo", userMemo);
  return (
    <core.UserContext.Provider value={{ ...userMemo, getUser }}>
      <SocketProvider>
        <Routes>
          <Route
            path="/reviewcandidate/:jobId/:matchProfileId"
            element={<PublicReviewCandidatepage />}
          />
          {/* Login */}
          <Route path="/linkedin" element={<LinkedInCallback />} />
          <Route path="/register" element={<NewUserRegister />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          {/* <Route path="/unactive" element={<UnactiveAccount />} /> */}
          <Route path="/invite" element={<InviteAuth />} />
          <Route path="/invitelogin" element={<InviteLogin />} />
          {/* Invite Pages */}
          <Route path="/invite-signup" element={<InviteSignUp />} />
          <Route path="/invite-enter-code" element={<InviteVerifyCode />} />
          <Route path="/invite-set-password" element={<InvitePassword />} />
        </Routes>
        {/* <ThemeContext> */}
        <ThemeProvider>
          <Routes>
            {/* job post */}
            <Route path="/job-post/:id" element={<Jobs />} />
            <Route path="/job-post/form/:id" element={<JobForm />} />
            <Route path="/job-post/details/:id" element={<JobDesc />} />
            <Route path="/job-post/submit" element={<JobSubmitted />} />
            <Route path="/settings/guidelines" element={<Guidelines />} />
            <Route
              path="/availability/:userid"
              element={<Avavilabiltycheck />}
            />
            <Route
              path="/outsource/candidates/id/:id"
              element={<LeadsCandidatesList />}
            >
              {/* Nested routes */}
              {/* Add more nested routes here */}
            </Route>

            {/* Login */}

            <Route path="/linkedin" element={<LinkedInCallback />} />
            <Route path="/register" element={<NewUserRegister />} />
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            {/* <Route path="/unactive" element={<UnactiveAccount />} /> */}
            <Route path="/invite" element={<InviteAuth />} />
            <Route path="/invitelogin" element={<InviteLogin />} />
            {/* Invite Pages */}
            <Route path="/invite-signup" element={<InviteSignUp />} />
            <Route path="/invite-enter-code" element={<InviteVerifyCode />} />
            <Route path="/invite-set-password" element={<InvitePassword />} />

            {/* Public Pages */}
            <Route path="/submit/:jobId" element={<EvaluationDoc />} />
            <Route
              path="/evaluation/:id/:interview_id"
              element={<CandidateEvaluation />}
            />
            {/* <Route path="/careers" element={<JobListing />} /> */}
            {/* <Route path="/jobpost/:id" element={<JobDescription />} /> */}
            <Route
              path="/assessment-report/:id"
              element={<AssessmentEvalReport />}
            />
            {/* interviewFeedback */}
            <Route
              path="/feedback/:jobId/:appId"
              element={
                <ProtectedRoute>
                  <InvitedFeedback />
                </ProtectedRoute>
              }
            />

            {/* Recruiter */}
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:id"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-profile"
              exact
              element={
                <ProtectedRoute>
                  {/* <CreateProfile /> */}
                  <CreateNewProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <NewSetting />
                </ProtectedRoute>
              }
            >
              <Route index element={<General />} />
              <Route path="password" element={<Password />} />
              <Route path="notification" element={<Notification />} />
              <Route path="integration" element={<JobIntegrationCard />} />
              <Route path="message" element={<Messaging />} />
              <Route path="browsingAgent" element={<BrowsingAgent />} />
            </Route>
            {/* cadidate-pool */}

            <Route
              path="/candidate-pool"
              exact
              element={
                <ProtectedRoute>
                  <CandidatePool />
                </ProtectedRoute>
              }
            >
              {/* <Route
                      path="show-candidate/:candidateId"
                      element={
                        <ProtectedRoute>
                          <CandidateDrawer source="candidate" />
                        </ProtectedRoute>
                      }
                    /> */}
            </Route>
            <Route
              path="/googleCal"
              element={
                <ProtectedRoute>
                  <GoogleCal />
                </ProtectedRoute>
              }
            />
            <Route
              path="/verification-complete"
              element={<GmailVerificationComplete />}
            />

            {/* Temporary/Practice files */}
            <Route path="/chart-temp" element={<ChartsTemplate />} />
            <Route path="/form-builder" element={<FormBuilder />} />
            {/* Recruiter */}
            <Route path="/voice-call" element={<VoiceCall />} />
            <Route path="/twilioapp" element={<Twilio />} />
            <Route
              path="/sms"
              element={
                <LockedPage page={"Communications_read"}>
                  <Sms />
                </LockedPage>
              }
            />
            <Route path="/zoom" element={<Zoom />} />

            {/* Recruiter */}
            <Route
              path="/"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/:id"
              exact
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/create-profiles"
              exact
              element={
                <ProtectedRoute>
                  <CreateProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  {/* <Setting /> */}
                  <NewSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path="/googleCal"
              element={
                <ProtectedRoute>
                  <GoogleCal />
                </ProtectedRoute>
              }
            />

            {user && (
              <>
                <Route
                  path="/dashboard"
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                      {/* <AssessmentDashboard /> */}
                    </ProtectedRoute>
                  }
                />
                {user?.account === 0 ? (
                  <>
                    <Route
                      path="/saved"
                      element={
                        <ProtectedRoute>
                          <SavedJobs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/applied"
                      element={
                        <ProtectedRoute>
                          <AppliedJobs />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/profile/:id/edit"
                      element={
                        <ProtectedRoute>
                          <ProfilePage />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/profile/:id"
                      element={
                        <ProtectedRoute>
                          <ProfilePage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assessment/:id"
                      element={
                        <ProtectedRoute>
                          <Assessment />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assessment"
                      element={
                        <ProtectedRoute>
                          <Assessment />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/assessment-dashboard"
                      element={
                        <ProtectedRoute>
                          <AssessmentDashboard />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/create-profile"
                      element={
                        <ProtectedRoute>
                          <CreateNewProfile />
                        </ProtectedRoute>
                      }
                    />
                  </>
                ) : user?.account === 1 ? (
                  <>
                    <Route
                      path="/admin/dashboard"
                      element={
                        <LockedPage page={"Analytics_read"}>
                          <AdminDashboard />
                        </LockedPage>
                      }
                    />
                    <Route
                      path="/edit-company"
                      element={
                        <ProtectedRoute>
                          <EditCompany />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/create-job"
                      element={
                        // <LockedPage page={"Job_create"}>
                        //   <CreateJob />
                        // </LockedPage>
                        <ProtectedRouteComponent code="Job_create">
                          <CreateJob />
                        </ProtectedRouteComponent>
                      }
                    />
                    <Route
                      path="/edit-job/:id"
                      element={
                        <ProtectedRoute>
                          <CreateJob />
                        </ProtectedRoute>
                      }
                    />

                    <Route path="/job">
                      <Route
                        path=":jobType"
                        element={
                          <ProtectedRoute>
                            <ProtectedRouteComponent code="Job_access_page">
                              <JobDetailPage />
                            </ProtectedRouteComponent>
                          </ProtectedRoute>
                        }
                      />
                      <Route
                        path=":jobType/:jobId/:tab"
                        element={
                          <ProtectedRoute>
                            <ProtectedRouteComponent code="Job_access_page">
                              <JobDetailPage />
                            </ProtectedRouteComponent>
                          </ProtectedRoute>
                        }
                      ></Route>
                    </Route>

                    <Route
                      path="candidate/:candidateId"
                      element={
                        <ProtectedRoute>
                          <CandidatePage />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/browse-candidates"
                      exact
                      element={
                        <ProtectedRoute>
                          <BrowseCandidates />
                        </ProtectedRoute>
                      }
                    >
                      <Route
                        path="show-candidate/:candidateId"
                        element={
                          <ProtectedRoute>
                            <CandidateDrawer source="candidate" />
                          </ProtectedRoute>
                        }
                      />
                    </Route>
                    <Route
                      path="/create-company"
                      element={
                        <ProtectedRoute>
                          <CreateCompany />
                        </ProtectedRoute>
                      }
                    />

                    <Route
                      path="/create-company"
                      element={
                        <ProtectedRoute>
                          <CreateCompany />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/company-profile"
                      element={
                        <LockedPage>
                          <CompanyList />
                        </LockedPage>
                      }
                    ></Route>
                    <Route
                      path="/company-profile/:companyId"
                      element={
                        <LockedPage>
                          <CompanyList />
                        </LockedPage>
                      }
                    ></Route>
                    <Route
                      path="/hand-picked"
                      element={
                        <ProtectedRoute>
                          <HandPickedCandidate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/company-details/:id"
                      element={
                        <ProtectedRoute>
                          <CompanyDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/sequences" element={<SequencePage />}>
                      <Route path="new" element={<CreateSequence />} />
                      <Route path=":id" element={<SequencePageContent />}>
                        <Route index element={<Structure />} />
                        <Route path="structure" element={<Structure />} />
                        <Route path="status" element={<Status />} />
                      </Route>
                    </Route>

                    <Route path="/templates/email" element={<TemplatePage />}>
                      <Route path="new" element={<CreateTemplate />} />
                      <Route path=":id" element={<TemplatePageContent />}>
                        <Route index element={<TemplateStructure />} />
                        {/* <Route
                          path="structure"
                          element={<TemplateStructure />}
                        /> */}
                        {/* <Route path="status" element={<Status />} /> */}
                      </Route>
                    </Route>

                    <Route
                      path="/templates/job"
                      element={<JobTemplateScreen />}
                    >
                      <Route path="new" element={<EditJobTemplate />} />
                      <Route path=":id" element={<TemplateStructure2 />} />
                      <Route
                        path=":id/edit"
                        element={<TemplateStructure2 editTemp={true} />}
                      />
                    </Route>

                    <Route
                      path="/recruiter-profile"
                      element={
                        <ProtectedRoute>
                          <RecruiterProfile />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/chat"
                      element={
                        <ProtectedRoute>
                          <Message />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/chats"
                      element={
                        <ProtectedRoute>
                          <ChatRecruiter />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/interview/:id"
                      element={
                        <ProtectedRoute>
                          <Bot />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/instant-meet/:id"
                      element={
                        <ProtectedRoute>
                          <MeetingNotes />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/instant-meet"
                      element={
                        <ProtectedRoute>
                          <MeetingNotes />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/instant-meet/live/:id"
                      element={
                        <ProtectedRoute>
                          <LiveMeeting />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/systemadmin" element={<Admin user={user} />}>
                      {/* Nested routes */}
                      <Route path="new-appearence" element={<NewUser />} />
                      <Route path="role/:teamId" element={<Role />} />
                      <Route path="users" element={<User />} />
                    </Route>
                    <Route
                      path="/company-profile"
                      element={
                        <LockedPage>
                          <CompanyList />
                        </LockedPage>
                      }
                    ></Route>
                    <Route
                      path="/company-profile/:companyId"
                      element={
                        <LockedPage>
                          <CompanyList />
                        </LockedPage>
                      }
                    ></Route>
                    <Route
                      path="/hand-picked"
                      element={
                        <ProtectedRoute>
                          <HandPickedCandidate />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/company-details/:id"
                      element={
                        <ProtectedRoute>
                          <CompanyDetails />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/sequences" element={<SequencePage />}>
                      <Route path="new" element={<CreateSequence />} />
                      <Route path=":id" element={<SequencePageContent />}>
                        <Route index element={<Structure />} />
                        <Route path="structure" element={<Structure />} />
                        <Route path="status" element={<Status />} />
                      </Route>
                    </Route>
                    <Route path="/templates" element={<TemplatePage />}>
                      <Route path="new" element={<CreateTemplate />} />
                      <Route path=":id" element={<TemplatePageContent />}>
                        <Route index element={<TemplateStructure />} />
                        <Route
                          path="structure"
                          element={<TemplateStructure />}
                        />
                        {/* <Route path="status" element={<Status />} /> */}
                      </Route>
                    </Route>
                    <Route
                      path="/recruiter-profile"
                      element={
                        <ProtectedRoute>
                          <RecruiterProfile />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/chat"
                      element={
                        <ProtectedRoute>
                          <Message />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/chats"
                      element={
                        <ProtectedRoute>
                          <ChatRecruiter />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/interview/:id"
                      element={
                        <ProtectedRoute>
                          <Bot />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/instant-meet/:id"
                      element={
                        <ProtectedRoute>
                          <MeetingNotes />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/instant-meet"
                      element={
                        <ProtectedRoute>
                          <MeetingNotes />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path="/instant-meet/live/:id"
                      element={
                        <ProtectedRoute>
                          <LiveMeeting />
                        </ProtectedRoute>
                      }
                    />
                    <Route path="/systemadmin" element={<Admin user={user} />}>
                      {/* Nested routes */}
                      <Route path="new-appearence" element={<NewUser />} />
                      <Route path="role/:teamId" element={<Role />} />
                      <Route path="users" element={<User />} />
                      <Route
                        path="template-manager"
                        element={<TemplateManager />}
                      />
                      {/* Add more nested routes here */}
                    </Route>

                    <Route path="/leads-list" element={<LeadList />}>
                      {/* Nested routes */}
                      {/* Add more nested routes here */}
                    </Route>
                  </>
                ) : null}
              </>
            )}
          </Routes>
        </ThemeProvider>
        {/* </ThemeContext> */}
      </SocketProvider>
      <ToastContainer />
      <Tooltip
        id="tooltip"
        noArrow={false}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "12px",
          padding: "4px 8px",
          fontWeight: "400",
          color: "white",
        }}
        place="bottom"
      />
    </core.UserContext.Provider>
  );
}
