import React, { useState, useEffect, useMemo } from "react";
import { FaEllipsisV } from "react-icons/fa";
import CreateGoalModal from "./CreateGoalModal";
import CustomDropdown from "./CustomDropDown";
import { useDispatch, useSelector } from "react-redux";
import {
  createGoal,
  fetchAllGoals,
  fetchAllGoalsActivity,
} from "../../../redux/services";
const core = require("../../../lib/core");

const DashboardGoal = () => {
  const dispatch = useDispatch();
  // Fetch goals from Redux store
  const fetchedGoals = useSelector(
    (state) => state?.dashboardSlice?.goalsList, // Access goals from Redux state
  );
  const fetchedGoalsActivityOptions = useSelector(
    (state) => state?.dashboardSlice?.goalActivityOptions, // Access goals from Redux state
  );

  // Local state for goals
  const [goals, setGoals] = useState([]);
  const [goalOptions, setGoalOptions] = useState([]);
  const [showAll, setShowAll] = useState(false);
  // Modal visibility state
  const [isModalVisible, setIsModalVisible] = useState(false);
  // Memoize the goals data from Redux to avoid unnecessary re-renders
  const goalsList = useMemo(() => fetchedGoals, [fetchedGoals]);
  const ActivityList = useMemo(
    () => fetchedGoalsActivityOptions,
    [fetchedGoalsActivityOptions],
  );

  useEffect(() => {
    // Update the local state with fetched goals from Redux store
    setGoals(goalsList);
  }, [goalsList]);
  useEffect(() => {
    // Update the local state with fetched goals from Redux store
    setGoalOptions(ActivityList);
  }, [ActivityList]);

  // Fetch goals when the component mounts
  useEffect(() => {
    dispatch(fetchAllGoals()); // Dispatch the action to fetch all goals
    dispatch(fetchAllGoalsActivity()); // Dispatch the action to fetch all goals activity options
  }, [dispatch]);

  // Handle clicking "See all goals" button
  const handleSeeAllClick = () => {
    setShowAll((prev) => !prev);
  };

  // Function to add a new goal
  const addGoal = async (newGoal) => {
    try {
      const payload = {
        goal_activity: newGoal.goal_activity,
        frequency: newGoal.frequency,
        total: newGoal.total,
      };
      await dispatch(createGoal(payload));
      // Refetch goals after successful creation
      dispatch(fetchAllGoals());
    } catch (error) {
      console.error("Error creating goal:", error);
    }
  };

  // Function to open modal
  const openModal = () => {
    setIsModalVisible(true);
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="bg-[#FFF] rounded-[12px] shadow-PipelineCardsShadow w-full h-[300px] overflow-hidden relative">
      <div
        className="sticky top-0 bg-white z-10 pt-[18px] px-[18px]"
        id="goal-top-menu"
      >
        <div className="flex justify-between items-center mb-6 gap-x-4">
          <div>
            <span className="text-[16px] text-[#252E4B] font-medium not-italic">
              My goals
            </span>
          </div>
          <div className="flex flex-row items-center gap-x-3">
            <button
              onClick={openModal}
              className="text-[16px] not-italic text-blue-600 hover:text-blue-800 font-medium text-sm"
            >
              Create goal
            </button>
            <button
              onClick={handleSeeAllClick}
              className="text-[16px] not-italic text-blue-600 hover:text-blue-800 font-medium text-sm"
            >
              {showAll ? "See less" : "See all"}
            </button>
          </div>
        </div>
      </div>
      {goals.length === 0 && (
        <div className="flex flex-col items-center justify-center h-[calc(100%-70px)] px-[18px] pb-[20px]">
          <p className="text-[16px] text-[#252E4B] font-medium not-italic">
            No goals to display
          </p>
        </div>
      )}
      <div className="overflow-scroll scrollbar-candidate h-[calc(100%-70px)] px-[18px] pb-[20px]">
        <div className="w-full flex flex-col justify-start items-start gap-y-4">
          {(showAll ? goals : goals.slice(0, 3)).map((goal) => (
            <div
              key={goal?._id}
              className="bg-white rounded-lg p-2 shadow border border-gray-100 hover:shadow-md transition duration-300 w-full"
            >
              <div className="flex justify-between items-start mb-2">
                <h3 className="text-black text-base font-medium not-italic">
                  {goal?.goal_activity?.title
                    ? goal?.goal_activity?.title
                    : "Goal title"}
                </h3>
                <button className="text-gray-600 hover:text-gray-600">
                  <CustomDropdown onDelete={() => {}} onEdit={() => {}} />
                </button>
              </div>
              <p className="text-sm text-gray-800 mb-3">{goal?.frequency}</p>
              <div className="flex items-center">
                <div className="flex-grow">
                  <div className="bg-blue-100 rounded-full h-2 overflow-hidden">
                    <div
                      className="bg-blue-500 h-full transition-all duration-500 ease-in-out"
                      style={{ width: `${goal?.percent ? goal?.percent : 0}%` }}
                    ></div>
                  </div>
                </div>
                <span className="ml-3 text-sm font-medium text-gray-600">
                  {goal?.completed ? goal?.completed : 0} of {goal?.total}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal for creating new goals */}
      <CreateGoalModal
        visible={isModalVisible}
        onClose={closeModal}
        addGoal={addGoal}
        goalOptions={goalOptions}
      />
    </div>
  );
};

export default DashboardGoal;
