import React, { useState } from "react";
// import { SurveyCreator, SurveyCreatorComponent } from "survey-creator-react";
// import "survey-core/survey.i18n.js";
// import "survey-creator-core/survey-creator-core.i18n.js";
// import { surveyJSON } from "./survey_json";
// import "survey-core/defaultV2.css";
// import "survey-creator-core/survey-creator-core.css";

function FormBuilder() {
  const options = {
    showLogicTab: true,
  };
  // const creator = new SurveyCreator(options);
  // console.log(creator)

  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState("");

  console.log(questions);

  const addQuestion = (type) => {
    setQuestions([...questions, { type, question: newQuestion, options: [] }]);
    setNewQuestion("");
  };

  const addOption = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push("");
    setQuestions(updatedQuestions);
  };

  const updateQuestion = (index, newQuestionText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].question = newQuestionText;
    setQuestions(updatedQuestions);
  };

  const updateOption = (questionIndex, optionIndex, newOptionText) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = newOptionText;
    setQuestions(updatedQuestions);
  };

  const removeQuestion = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions.splice(index, 1);
    setQuestions(updatedQuestions);
  };

  const removeOption = (questionIndex, optionIndex) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options.splice(optionIndex, 1);
    setQuestions(updatedQuestions);
  };

  return (
    <>
      <div className="p-[40px]">
        <h1>Form Builder</h1>
        <div>
          <input
            type="text"
            className="border border-black h-[40px] w-full rounded-md"
            placeholder="Enter a new question"
            value={newQuestion}
            onChange={(e) => setNewQuestion(e.target.value)}
          />
        </div>
        <div className="mt-[12px]">
          <button
            className="border rounded-sm px-[12px] py-[6px] border-blue mr-[12px]"
            onClick={() => addQuestion("MCQ")}
          >
            Add MCQ
          </button>
          <button
            className="border rounded-sm px-[12px] py-[6px] border-blue mr-[12px]"
            onClick={() => addQuestion("MSQ")}
          >
            Add MSQ
          </button>
          <button
            className="border rounded-sm px-[12px] py-[6px] border-blue mr-[12px]"
            onClick={() => addQuestion("LongAnswer")}
          >
            Add Long Answer
          </button>
        </div>
        {questions.map((question, questionIndex) => (
          <div key={questionIndex} className="mt-[10px]">
            <h1 className="mt-[40px] mb-[20px]">
              Question : {questionIndex + 1}
            </h1>

            <input
              className="border border-black h-[35px] w-[50%] rounded-md"
              type="text"
              value={question.question}
              onChange={(e) => updateQuestion(questionIndex, e.target.value)}
            />
            <button
              className="ml-[20px]"
              onClick={() => removeQuestion(questionIndex)}
            >
              Remove
            </button>

            {(question.type === "MCQ" || question.type === "MSQ") && (
              <div>
                {question.options.map((option, optionIndex) => (
                  <div key={optionIndex}>
                    <input
                      type="text"
                      className="border mt-[12px] border-black h-[25px] w-[30%] rounded-md"
                      value={option}
                      onChange={(e) =>
                        updateOption(questionIndex, optionIndex, e.target.value)
                      }
                    />
                    <button
                      onClick={() => removeOption(questionIndex, optionIndex)}
                    >
                      Remove
                    </button>
                  </div>
                ))}
                <button onClick={() => addOption(questionIndex)}>
                  Add Option
                </button>
              </div>
            )}
          </div>
        ))}

        <button
          className="border rounded-sm px-[12px] mt-[20px] py-[6px] border-blue bg-blue  text-[white]"
          onClick={() => console.log(questions)}
        >
          Save Form
        </button>
      </div>
    </>
  );
}

export default FormBuilder;
