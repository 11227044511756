// import { useSelector } from "react-redux";
// import { useState } from "react";
// import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
// import "@cyntler/react-doc-viewer/dist/index.css";
// import { useEffect } from "react";
// export default function ResumeTab() {
//   const profile = useSelector(
//     (state) => state?.jobCandidateMatch?.profile?.matchProfile?.resume_link,
//   );
//   const cover_letter_link = useSelector(
//     (state) => state?.jobCandidateMatch?.profile?.matchProfile?.cover_letter_link,
//   );

//   const { coverLetter, screeningAnswers } = useSelector((state) => {
//     return {
//       coverLetter:
//         state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_latter,
//       screeningAnswers:
//         state?.jobCandidateMatch?.profile?.screeningQsnAns
//           ?.screening_questions_answers,
//     };
//   });

//   const [docs, setDocs] = useState([]);

//   useEffect(() => {
//     if (profile) {
//       setDocs([{ uri: profile }]);
//     }
//   }, [profile]);

//   return (
//     <div className="w-full p-2 ">
//       <div className="flex items-center justify-center top-2 ">
//         <DocViewer
//           style={{
//             width: 1000,
//             borderColor: "lightgray",
//             borderRadius: 10,
//             borderWidth: 1,
//             minHeight: 900,
//           }}
//           config={{
//             header: {
//               disableHeader: true,
//               disableFileName: true,
//               retainURLParams: true,
//             },
//             noRenderer: {
//               overrideComponent: () => (
//                 <div className="w-full h-full flex justify-center items-center">
//                   Failed to load
//                 </div>
//               ),
//             },
//             pdfZoom: {
//               defaultZoom: 0.8, // 1 as default,
//             },
//           }}
//           documents={docs}
//           pluginRenderers={DocViewerRenderers}
//         />
//       </div>

//       {/* <iframe className="w-full h-full" title="PDF Viewer" src="https://sproutsai-staging.s3.amazonaws.com/03b96a99-22cf-4abb-9c98-442a843d73aa/file-sample_100kB.doc"/> */}

//       <div>
//         {" "}
//         {screeningAnswers?.length > 0 ? (
//           <div className="flex flex-col mt-2 p-2 bg-white rounded-lg">
//             <h2 className="text-base font-medium text-[#16182f] mb-2">
//               Screening Question Responses
//             </h2>
//             {screeningAnswers.map((question, index) => (
//               <div key={index} className="w-full p-4  rounded-md">
//                 <div className="">
//                   <h2 className="text-base font-medium text-[#16182f]">
//                     {`${index + 1}: ${question?.question?.title}`}
//                   </h2>
//                 </div>
//                 <div>
//                   <span className="text-gray-800 font-normal text-sm">
//                     {`Answer: ${question?.answer ? question?.answer : ""}`}
//                   </span>
//                 </div>
//               </div>
//             ))}
//           </div>
//         ) : null}
//       </div>
//     </div>
//   );
// }

import { useSelector } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { ScaleLoader } from "react-spinners";
import { RefreshCw } from "lucide-react";

export default function ResumeTab() {
  const profile = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.resume_link,
  );
  const cover_letter_link = useSelector(
    (state) =>
      state?.jobCandidateMatch?.profile?.matchProfile?.cover_letter_link,
  );
  const cover_letter = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?.cover_letter,
  );
  const all_details = useSelector((state) => state?.jobCandidateMatch?.profile);
  const { coverLetter, screeningAnswers } = useSelector((state) => {
    return {
      coverLetter:
        state?.jobCandidateMatch?.profile?.screeningQsnAns?.cover_letter,
      screeningAnswers:
        state?.jobCandidateMatch?.profile?.screeningQsnAns
          ?.screening_questions_answers,
    };
  });
  const intialDocs = profile ? [{ uri: profile }] : [];
  const intialCoverletter = cover_letter_link
    ? [{ uri: cover_letter_link }]
    : [];
  const [docs, setDocs] = useState(intialDocs);
  const [coverLetterDocs, setCoverLetterDocs] = useState(intialCoverletter);
  const [showFullCoverLetter, setShowFullCoverLetter] = useState(false);
  const [key, setKey] = useState(0);
  const [isloading, setIsLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  // useEffect(() => {
  //   if (profile) {
  //     setDocs([{ uri: profile }]);
  //   }
  //   if (cover_letter_link) {
  //     setCoverLetterDocs([{ uri: cover_letter_link }]);
  //   }
  // }, [profile, cover_letter_link]);

  useEffect(() => {
    setDocs(intialDocs);
    setCoverLetterDocs(intialCoverletter);
  }, []);
  const loadDocuments = useCallback(() => {
    setIsLoading(true);
    const promises = [];
    setKey((prevKey) => prevKey + 1);
    if (profile) {
      promises.push(
        fetch(profile).then(() =>
          setDocs([{ uri: profile + "?t=" + new Date().getTime() }]),
        ),
      );
    }
    if (cover_letter_link) {
      promises.push(
        fetch(cover_letter_link).then(() =>
          setCoverLetterDocs([
            { uri: cover_letter_link + "?t=" + new Date().getTime() },
          ]),
        ),
      );
    }

    Promise.all(promises)
      .then(() => setIsLoading(false))
      .catch(() => {
        if (retryCount < 3) {
          setRetryCount((prevCount) => prevCount + 1);
          setTimeout(loadDocuments, 1000); // Retry after 1 second
        } else {
          setIsLoading(false);
        }
      });
  }, [profile, cover_letter_link, retryCount]);

  useEffect(() => {
    loadDocuments();
  }, [loadDocuments, profile, cover_letter_link]);

  const handleDocumentLoadError = () => {
    if (retryCount < 5) {
      setRetryCount((prevCount) => prevCount + 1);
      loadDocuments();
    }
    loadDocuments();
  };

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
        setKey((prevKey) => prevKey + 1);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleCoverLetter = () => {
    setShowFullCoverLetter(!showFullCoverLetter);
  };

  const coverLetterPreview = cover_letter
    ? cover_letter.split("\n").slice(0, 5).join(" ") + "..."
    : "";

  setTimeout(() => {
    const style = document.createElement("style");
    style.textContent = `
        #pdf-zoom-in.disabled, #pdf-zoom-out.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      `;
    document.head.appendChild(style);

    const pdfPage = document.querySelector(".react-pdf__Page");
    const zoomInButton = document.getElementById("pdf-zoom-in");
    const zoomOutButton = document.getElementById("pdf-zoom-out");

    if (pdfPage && zoomInButton && zoomOutButton) {
      const checkScaleFactor = () => {
        const style = window.getComputedStyle(pdfPage);
        const scaleFactor = parseFloat(
          style.getPropertyValue("--scale-factor"),
        );

        if (scaleFactor >= 1.2) {
          zoomInButton.disabled = true;
          zoomInButton.classList.add("disabled");
        } else {
          zoomInButton.disabled = false;
          zoomInButton.classList.remove("disabled");
        }

        if (scaleFactor < 0.5) {
          zoomOutButton.disabled = true;
          zoomOutButton.classList.add("disabled");
        } else {
          zoomOutButton.disabled = false;
          zoomOutButton.classList.remove("disabled");
        }
      };

      // Initial check
      checkScaleFactor();

      // Set up a MutationObserver to watch for changes in the style attribute
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "style"
          ) {
            checkScaleFactor();
          }
        });
      });

      observer.observe(pdfPage, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }
  }, 3000);

  const ReloadButton = ({ onClick }) => (
    <button
      onClick={onClick}
      className=" bg-white text-gray-800 font-bold p-2 rounded-full inline-flex items-center"
    >
      <RefreshCw className="w-4 h-4 mr-2" />
    </button>
  );
  if (isloading) {
    return (
      <div className="flex items-center justify-center">
        <ScaleLoader height={30} color="#007aff" loading={true} />
      </div>
    );
  }
  return (
    <div className="w-full p-2" ref={containerRef}>
      {/* Resume DocViewer */}
      {docs?.length > 0 ? (
        <div className="flex flex-col px-2">
          {/* Resume DocViewer */}
          <div className="flex items-center gap-2">
            <h2 className="text-base font-medium text-[#16182f]">Resume</h2>
            <div>
              <ReloadButton onClick={loadDocuments} />
            </div>
          </div>
          <div className="relative flex items-center justify-center">
            <DocViewer
              key={key}
              style={{
                width: 1200,
                borderColor: "lightgray",
                borderRadius: 10,
                borderWidth: 1,
                minHeight: 900,
              }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
                noRenderer: {
                  overrideComponent: () => (
                    <div className="w-full h-full flex justify-center items-center">
                      Failed to load
                    </div>
                  ),
                },
                pdfZoom: {
                  defaultZoom: 1,
                },
              }}
              documents={docs}
              pluginRenderers={DocViewerRenderers}
              onError={handleDocumentLoadError}
            />
          </div>
        </div>
      ) : null}

      {/* Cover Letter DocViewer */}
      {coverLetterDocs.length > 0 ? (
        <div className="flex flex-col top-2 px-2 mt-8">
          <h2 className="text-base font-medium text-[#16182f]">Cover Letter</h2>
          <div className="relative flex items-center justify-center">
            <DocViewer
              key={key}
              style={{
                width: 1200,
                borderColor: "lightgray",
                borderRadius: 10,
                borderWidth: 1,
                minHeight: 900,
              }}
              config={{
                header: {
                  disableHeader: true,
                  disableFileName: true,
                  retainURLParams: true,
                },
                noRenderer: {
                  overrideComponent: () => (
                    <div className="w-full h-full flex justify-center items-center">
                      Failed to load
                    </div>
                  ),
                },
                pdfZoom: {
                  defaultZoom: 1,
                },
              }}
              documents={coverLetterDocs}
              pluginRenderers={DocViewerRenderers}
              onError={handleDocumentLoadError}
            />
          </div>
        </div>
      ) : cover_letter ? (
        <div className="flex flex-col top-2 mt-8 px-2">
          <h2 className="text-lg text-gray-700 font-semibold xl:ml-8 mb-4">
            Cover Letter
          </h2>
          <div className="bg-defaultBg p-4 rounded-md xl:w-[95%] mx-auto  ">
            <p className="text-sm text-gray-700 whitespace-pre-wrap text-justify">
              {showFullCoverLetter ? cover_letter : coverLetterPreview}
            </p>
            <button
              className="text-blue-600 text-sm mt-2"
              onClick={toggleCoverLetter}
            >
              {showFullCoverLetter ? "Read less" : "Read more"}
            </button>
          </div>
        </div>
      ) : null}

      <div>
        {screeningAnswers?.length > 0 ? (
          <div className="flex flex-col top-2 mt-8 px-2  mb-4">
            <h2 className="text-lg text-gray-700 font-semibold xl:ml-8 mb-2">
              Screening Questions
            </h2>
            {screeningAnswers.map((question, index) => (
              <div
                key={index}
                className="bg-defaultBg w-full p-4 my-2 rounded-md xl:w-[95%] mx-auto  "
              >
                <div className="">
                  <h2 className="text-sm font-semibold text-gray-800 ">
                    {question?.question?.question}
                  </h2>
                </div>
                <div className="mt-3">
                  <span className="text-gray-800 font-normal text-sm ">
                    {question?.answer ? question?.answer : ""}
                  </span>
                </div>
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
