import React, { useEffect, useState } from "react";
import ReviewCandidates from "./ReviewCandidates";
import { useParams } from "react-router-dom";
const core = require("../../../../lib/core");
const PublicReviewCandidatepage = () => {
  const { jobId, matchProfileId } = useParams();
  const [candidateData, setCandidateData] = useState({});
  function convertApiResponseToDesiredFormat(apiResponse) {
    console.log(apiResponse);
    const data = apiResponse.data.content;
    const applicant = data.applicant;

    return {
      currentCandidate: {
        applicant: {
          first_name: applicant.first_name || "",
          last_name: applicant.last_name || "",
          title: applicant.title || "",
          email: applicant.email?.[0] || "",
          phone: applicant.phone?.[0] || "",
          linkedin: applicant.linkedin || "",
          resume_link: applicant.resume_link || "",
          years_experience: applicant.years_experience || 0,
          months_experience: applicant.months_experience || 0,
        },
        current_stage: data.current_stage || "",
        current_source: data.current_source || "",
        remote_source: applicant.remote_source || "",
        _id: data._id || "",
        interview_status: data.interview_status || "",
        availablity_status: data.availablity_status || "",
        profileHighlights: data.profileHighlights || [],
        matchSummary: data.matchSummary || "",
        overallMatch: data.overallMatch || 0,
        skillsMatch: data.skillsMatch || 0,
        educationMatch: data.educationMatch || 0,
        jobPositionMatch: data.jobPositionMatch || 0,
        location_match: data.location_match || "",
        favourite: false,
      },
      jobName: "",
      matchScoreThreshold: apiResponse.data.match_criteria,
      notes: [],
      jobCriteria: apiResponse?.data?.jobCriteria ?? [],
      pipeline: apiResponse?.data?.pipeline ?? [],
      candidateExperience: {
        work_experience: (applicant.experience || []).map((exp) => ({
          company: exp.company || "",
          position: exp.position || "",
          skills: exp.Skills || [],
          description: exp.description || "",
          start: exp.start || "",
          end: exp.end || "",
          ongoing: exp.ongoing || false,
        })),
      },
    };
  }
  const fetchData = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_CANDIDATE_DATA + jobId + "/" + matchProfileId,
        1,
      );
      const Cand = convertApiResponseToDesiredFormat(json);
      setCandidateData(Cand);
      console.log(candidateData);
    } catch (error) {
      console.log("Error : Failed to fetch", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [jobId, matchProfileId]);

  return (
    <div>
      <ReviewCandidates
        candidates={[candidateData.currentCandidate]}
        isPublic={true}
        currentIndex={0}
        candidateExperience={candidateData.candidateExperience}
        matchScoreThreshold={candidateData.matchScoreThreshold}
        candidateData={candidateData}
      />
    </div>
  );
};

export default PublicReviewCandidatepage;
