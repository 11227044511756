import React from "react";
import Modal from "../../../components/modal/Modal";

function HelpModal({ modalDismiss, loading }) {
  return (
    <Modal
      heading={"Help Request Received"}
      subheading={""}
      onSave={modalDismiss}
      modalDismiss={modalDismiss}
    >
      <div className="">
        <div className="">
          <div className="">
            <p className="mb-2">
              Thank you for reaching out to us. We have successfully received
              your help request. Our support team will connect with you shortly
              to assist you with your concerns.
            </p>
            <p className="mb-2">
              In the meantime, if you need immediate assistance or have any
              additional questions, feel free to contact us directly at:
            </p>
            <p className="mb-4">
              <a href="mailto:support@sproutsai.com" className="text-blue-500">
                support@sproutsai.com
              </a>
            </p>
            <p className="italic text-sm">
              We appreciate your patience and look forward to resolving your
              issue as quickly as possible.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default HelpModal;
