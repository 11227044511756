import React from "react";

const CheckGreen = () => {
  return (
    <svg
      className="inline-block mr-2"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M7 13.5625C10.6094 13.5625 13.5625 10.6094 13.5625 7C13.5625 3.39062 10.6094 0.4375 7 0.4375C3.39062 0.4375 0.4375 3.39062 0.4375 7C0.4375 10.6094 3.39062 13.5625 7 13.5625Z"
        fill="#00AC85"
      />
      <path
        d="M4.21094 6.99781L6.06812 8.855L9.78906 5.14062"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckGreen;
