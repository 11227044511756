import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LoginPageLogo from "../../assets/candidate/SproutsaiLogo.svg";
import { ScaleLoader } from "react-spinners";
import { authStages } from "../../../lib/constants";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");
const { AuthStageContext } = require("../../../lib/context");

export default function Register() {
  const { setStage, email, setEmail, isInvited } = useContext(AuthStageContext);
  const nav = useNavigate();
  const loc = useLocation();
  const [loginText, setLoginText] = useState("Join now");
  const [errorMessage, setErrorMessage] = useState("");

  async function handleSignUp(e) {
    e.preventDefault();
    setLoginText(<ScaleLoader height={14} color="#ffffff" loading={true} />);
    const json = await core.API(
      core.API_METHODS.POST,
      core.USER_API.POST_SEND_EMAIL_OTP,
      0,
      {
        email: email,
      },
    );
    if (json?.data) {
      setStage(authStages.CREATED);
      setLoginText("Next");
    } else {
      setErrorMessage(json?.error?.message);
      setLoginText("Join now");
      if (json?.error?.stage === authStages.COMPLETED) {
        let timer = 5;
        const intervalId = setInterval(() => {
          setLoginText("Redirecting to login page in " + timer + " seconds");
          if (timer == 0) {
            clearInterval(intervalId);
            nav("/login");
          }
          timer = timer - 1;
        }, 1000);
        if (timer == 0) {
          clearInterval(intervalId);
          nav("/login");
        }
      }
    }
  }

  // useEffect(() => {
  //   console.log(loc?.pathname?.split("/")?.at(-1), loc?.search?.split("=").at(-1));
  //   if (loc?.pathname?.split("/")?.at(-1) === "invite") {
  //     setEmail(loc?.search?.split("=").at(-1));
  //   }
  // }, []);

  return (
    <form className="w-full px-4 md:px-[10%] py-5 md:h-screen h-auto relative rounded-lg bg-white flex flex-col items-start justify-center gap-3.5">
      <img
        src={LoginPageLogo}
        className="max-w-full h-8 mb-5"
        alt="SproutsAI"
      />
      <h1 className="w-full text-black text-4xl font-semibold">
        Let's get started!
      </h1>
      <h4 className="w-full text-slate-500 text-base">
        Enter your email to create your account
      </h4>
      <div className="my-2 w-full">
        <InputBox
          type="email"
          placeholder="Email"
          className=" w-full border border-gray-400 px-4 py-3 rounded-lg text-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
          value={email}
          required
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      {String(errorMessage)?.length > 0 && (
        <p className={`w-full flex text-red-900 text-sm`}>{errorMessage}</p>
      )}
      <button
        className="btn-primary btn-md h-12 min-w-[120px]"
        onClick={handleSignUp}
      >
        {loginText}
      </button>
      <h4 className="text-sm my-2 text-gray-600">
        By clicking Join now, you are agreeing to the SproutsAI user agreement,
        privacy, and cookie policy.
      </h4>
      <h4 className="text-gray-700 text-sm w-full">
        Already have an account?
        <Link to="/login" className="text-blue-800 ml-2">
          Log in
        </Link>
      </h4>
    </form>
  );
}
