import React, { useContext, useEffect, useRef, useState } from "react";
import { convertFromHTML, ContentState, EditorState, Modifier } from "draft-js";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import FeedbackCriteria from "./FeedbackCriteria";
import InputBox from "../../../../../layouts/InputBox";
import { useSelector } from "react-redux";
import {
  XCircle,
  AlertCircle,
  CheckCircle,
  Star,
  Award,
  Minus,
} from "lucide-react";
import { ScaleLoader } from "react-spinners";
import SvgIcon9 from "../../icons/SvgIcon9";
const core = require("../../../../../lib/core");

const NoDecisionIcon = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);
const ratingOptions = [
  {
    value: -1,
    label: "No decision",
    icon: NoDecisionIcon,
    color: "text-grey-700",
  },
  {
    value: 1,
    label: "Needs Improvement",
    icon: XCircle,
    color: "text-red-700",
  },
  {
    value: 2,
    label: "Satisfactory",
    icon: AlertCircle,
    color: "text-yellow-600",
  },
  { value: 3, label: "Proficient", icon: CheckCircle, color: "text-green-700" },
  { value: 4, label: "Exceptional", icon: Star, color: "text-blue-700" },
  // { value: 5, label: "Outstanding", icon: Award, color: "text-purple-700" },
];

const RatingButton = ({ option, isSelected, onClick }) => {
  const Icon = option.icon;
  return (
    <button
      onClick={() => onClick(option.value)}
      className={`flex flex-col items-center transition-colors ${
        isSelected
          ? `${option.color} bg-gray-100 font-semibold bg-white px-2 py-1 rounded-md`
          : "text-gray-600 hover:bg-white px-2 py-1 rounded-md"
      }`}
    >
      <Icon size={24} />
      <span className="text-xs mt-1">{option.label}</span>
    </button>
  );
};
const QuickFeedback = ({ name, initialRating, onRatingChange }) => {
  const [rating, setRating] = useState(initialRating);
  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);
  const handleRating = (newRating) => {
    setRating(newRating === rating ? 0 : newRating);
    onRatingChange(name, newRating === rating ? 0 : newRating);
  };

  return (
    <div className="flex items-center space-x-2">
      {ratingOptions.map((option) => (
        <RatingButton
          key={option.value}
          option={option}
          isSelected={rating === option.value}
          onClick={handleRating}
        />
      ))}
    </div>
  );
};
const Combobox = ({ frameworks, selectInterview, setSelectInterview }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState(frameworks);
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  console.log(frameworks, isOpen);

  // useEffect(() => {
  //     setFilteredOptions(
  //         frameworks?.filter((option) =>
  //             option.toLowerCase().includes(inputValue.toLowerCase())
  //         )
  //     )
  // }, [inputValue])

  const handleInputChange = (e) => {
    setSelectInterview(e.target.value);
    setIsOpen(true);
  };

  const handleOptionClick = (option) => {
    setSelectedValue(option);
    setSelectInterview(option.name);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={wrapperRef}>
      <InputBox
        type="text"
        value={selectInterview}
        onChange={handleInputChange}
        onClick={() => setIsOpen(true)}
        placeholder="Select interview stage"
      />
      <button
        className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        onClick={() => setIsOpen(!isOpen)}
      >
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20">
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </button>
      {isOpen && (
        <ul className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
          {frameworks
            ?.filter((item) => item.name != "Hired" && item.name != "Rejected")
            ?.map((option) => (
              <li
                key={option.name}
                className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                onClick={() => handleOptionClick(option)}
              >
                {option.name}
              </li>
            ))}
        </ul>
      )}
    </div>
  );
};

function AddNewFeedback({
  setShowSection,
  getAllfeedback,
  feedback,
  edit,
  candidateData,
  InterviewStages,
  Criterias,
  pipelineStages,
  invited,
  job_Id,
  app_Id,
}) {
  console.log("matchdata", candidateData);
  const app_Id_redux = useSelector(
    (state) => state?.jobCandidateMatch?.profile?.matchProfile?._id,
  );
  const appId = invited ? app_Id : app_Id_redux;
  const [criteriaSkilArray, setCriteriaSkilArray] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [selectInterview, setSelectInterview] = useState("");
  const [EvaluationDropdown, setEvaluationDropdown] = useState(false);

  const criteria_redux = useSelector(
    (state) => state?.JobExpandSlice?.profile?.criterias,
  );
  const Stages = useSelector(
    (state) => state?.JobExpandSlice?.profile?.pipeline,
  );
  const criterias = invited ? Criterias : criteria_redux;
  const pipeline = invited ? pipelineStages : Stages;

  const additionalCriteria = [
    {
      criteria: "Qualification",
      value: 0,
      comment: "",
      skills: [],
    },
    {
      criteria: "Experience",
      value: 0,
      comment: "",
      skills: [],
    },
  ];

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );

  const recomandationList = [
    "Definitely No",
    "No",
    "Yes",
    "Definitely Yes",
    "No Decision",
  ];

  const [selectRecomandation, setSelectRecomandation] = useState("");
  const JOBID = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  const jobId = invited ? job_Id : JOBID;
  const [aggregateScore, setaggregateScore] = useState(0);
  const IntialQuickFeedback = [
    { name: "Technical skills", rating: 0, comment: "" },
    {
      name: "Problem-solving and analytical thinking",
      rating: 0,
      comment: "",
    },
    { name: "Industry experience", rating: 0, comment: "" },
    { name: "Communication skills", rating: 0, comment: "" },
    { name: "Cultural fit", rating: 0, comment: "" },
  ];

  const [QuickFeedbackCriteria, setQuickFeedbackCriteria] =
    useState(IntialQuickFeedback);
  const [newCriteriaName, setNewCriteriaName] = useState("");
  const [editingId, setEditingId] = useState(null);
  const editFeedbackInputRef = useRef(null);

  const handleEditClick = (id) => {
    setEditingId(id);
  };

  const handleEditSave = (index, newName) => {
    setQuickFeedbackCriteria((prev) => {
      return prev.map((item, idx) =>
        idx === index ? { ...item, name: newName.trim() || item.name } : item,
      );
    });
    setEditingId(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        editFeedbackInputRef.current &&
        !editFeedbackInputRef.current.contains(event.target)
      ) {
        handleEditSave(editingId, editFeedbackInputRef.current.value);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [editingId]);

  const handleRating = (name, newRating) => {
    setQuickFeedbackCriteria((prev) =>
      prev.map((item) =>
        item.name === name ? { ...item, rating: newRating } : item,
      ),
    );
  };

  const handleComment = (index, comment) => {
    setQuickFeedbackCriteria((prev) =>
      prev.map((item, i) => (i === index ? { ...item, comment } : item)),
    );
  };

  const handleAddCriteria = () => {
    if (newCriteriaName.trim()) {
      setQuickFeedbackCriteria((prev) => [
        ...prev,
        { name: newCriteriaName.trim(), rating: 0, comment: "" },
      ]);
      setNewCriteriaName("");
    }
  };

  const { user } = useContext(core.UserContext);

  console.log("User", user);
  // const [InterviewerId,setInterviewerId]=useState(user?.id)
  const [interviewerName, setInterviewerName] = useState(
    user?.name ?? user?.email,
  );

  useEffect(() => {
    if (edit) {
      setCriteriaSkilArray(feedback?.feedback);
      setSelectInterview(feedback?.interviewStage);
      setSelectRecomandation(feedback?.recomandation);
      setQuickFeedbackCriteria(feedback?.QuickFeedback ?? IntialQuickFeedback);
      setEditorStateDesc(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(
              feedback?.overallComment
                .split("&lt;")
                .join("<")
                .split("&gt")
                .join(">"),
            ),
          ),
        ),
      );
      setInterviewerName(feedback?.externalInterviewer);
    } else {
      setQuickFeedbackCriteria(IntialQuickFeedback);
      if (candidateData) {
        setCriteriaSkilArray([
          ...additionalCriteria,
          ...candidateData?.jobCriteria?.map((criteria) => ({
            criteria: criteria?.criteria,
            value: 0,
            comment: "",
            required: criteria?.value,
            skills: criteria?.skills?.map((skill) => ({
              label: skill?.label,
              value: 0,
              comment: "",
              required: skill?.value,
            })),
          })),
        ]);
      } else {
        setCriteriaSkilArray([
          ...additionalCriteria,
          ...criterias?.map((criteria) => ({
            criteria: criteria?.criteria,
            value: 0,
            comment: "",
            required: criteria?.value,
            skills: criteria?.skills?.map((skill) => ({
              label: skill?.label,
              value: 0,
              comment: "",
              required: skill?.value,
            })),
          })),
        ]);
      }
    }

    setPipelineData(
      InterviewStages ??
        pipeline?.flatMap((stage) => {
          if (stage?.subStage?.length > 0) {
            const Newstage = {
              name: stage.subStage,
              interviewers: [],
            };
            return Newstage;
          } else {
            const NewStage = {
              name: stage.stage,
              interviewers: [],
            };
            return NewStage;
          }
        }),
    );
    // if (candidateData) {
    //   setPipelineData(InterviewStages ??
    //     candidateData?.pipeline?.flatMap((stage) => {
    //       if (stage?.subStage?.length > 0) {
    //         return stage.subStage;
    //       } else {
    //         return [stage.stage];
    //       }
    //     }),
    //   );
    // } else {
    //   setPipelineData( InterviewStages ??
    //     pipeline?.flatMap((stage) => {
    //       if (stage?.subStage?.length > 0) {
    //         return stage.subStage;
    //       } else {
    //         return [stage.stage];
    //       }
    //     }),
    //   );
    // }
  }, [criterias, pipeline, feedback]);
  const [isSubmitting, setisSubmitting] = useState(false);
  const createFeedback = async () => {
    try {
      setisSubmitting(true);
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_CREATE_INTERVIEWFEEBACK,
        1,
        {
          matchprofile: appId,
          jobId: jobId,
          interviewStage: selectInterview,
          overall_conclusions: convertToHTML(
            editorStateDesc.getCurrentContent(),
          ),
          evaluation_categories: criteriaSkilArray,
          overall_recommendation: selectRecomandation,
          externalInterviewer: interviewerName,
          isEdit: edit,
          fid: feedback?._id,
          QuickFeedbackCriteria: QuickFeedbackCriteria,
        },
      );

      if (json) {
        setShowSection("list");
        getAllfeedback();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setisSubmitting(false);
    }
  };
  return (
    <>
      <div className="mb-4">
        <div className="p-4 bg-[#fff] rounded-lg">
          <label className="text-sm font-normal  text-gray-800 not-italic required">
            Select interview
          </label>
          <Combobox
            frameworks={pipelineData}
            selectInterview={selectInterview}
            setSelectInterview={setSelectInterview}
          />

          <label
            htmlFor=""
            className=" mt-[30px] text-sm font-normal  text-gray-800 not-italic "
          >
            Overall conclusions
          </label>
          <Editor
            editorState={editorStateDesc}
            onEditorStateChange={setEditorStateDesc}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
          />
        </div>
      </div>
      <div className="p-2 my-2 bg-white rounded-lg">
        <label className="text-[16px] font-medium text-gray-800 not-italic my-2 px-2 ">
          Quick feedback
        </label>

        <div className="">
          {QuickFeedbackCriteria.map((item, index) => (
            <div
              key={index}
              className="border rounded-lg bg-bg1 px-2 py-2 mb-2"
            >
              <div className="flex items-center justify-between mb-1.5 px-2 py-2">
                <div className="flex items-center  space-x-2 flex-grow">
                  {editingId === index ? (
                    <InputBox
                      ref={editFeedbackInputRef}
                      type="text"
                      defaultValue={item.name}
                      width="50%"
                      size="small"
                      className="font-medium text-sm bg-bg1 px-2 py-1 rounded-md"
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleEditSave(index, e.target.value);
                        }
                      }}
                    />
                  ) : (
                    <label className="font-medium text-sm">{item.name}</label>
                  )}
                  {editingId !== index && (
                    <button
                      onClick={() => handleEditClick(index)}
                      className="p-1 text-gray-600 hover:text-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-300 rounded"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 28 28"
                      >
                        <path
                          fill="currentColor"
                          d="M19.289 3.15a3.932 3.932 0 1 1 5.56 5.56l-1.54 1.54l-5.56-5.56zm-2.6 2.6L4.502 17.937c-.44.44-.76.986-.928 1.586l-1.547 5.525a.75.75 0 0 0 .924.924l5.524-1.547a3.6 3.6 0 0 0 1.587-.928L22.25 11.311z"
                        />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="flex items-center gap-4 h-6">
                  <QuickFeedback
                    name={item.name}
                    initialRating={item.rating}
                    onRatingChange={handleRating}
                  />
                </div>
              </div>
              <textarea
                value={item.comment}
                onChange={(e) => handleComment(index, e.target.value)}
                placeholder={`Add a comment on ${item.name}`}
                className="w-full p-2 border rounded-md resize-none placeholder:text-gray-600"
                rows={3}
                style={{ overflowY: "auto", maxHeight: "120px" }}
              />
            </div>
          ))}

          <div className="flex items-center gap-2 bg-bg1 px-4 py-2 rounded-lg w-1/2">
            <InputBox
              type="text"
              value={newCriteriaName}
              onChange={(e) => setNewCriteriaName(e.target.value)}
              placeholder="Enter new criteria name"
              width="50%"
              size="medium"
              className="flex-grow p-2 rounded-md placeholder:text-gray-600"
            />
            <button
              onClick={handleAddCriteria}
              className="flex items-center gap-2 btn-sec btn-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1em"
                height="1em"
                viewBox="0 0 32 32"
              >
                <path
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 25V7m-9 9h18"
                />
              </svg>
              Criteria
            </button>
          </div>
        </div>
      </div>
      <div>
        {EvaluationDropdown == false ? (
          <div
            onClick={() => {
              !EvaluationDropdown && setEvaluationDropdown(!EvaluationDropdown);
            }}
            className="flex items-center justify-between rounded-lg p-4  bg-bg1 mb-4 mt-2"
          >
            <h4 className="text-[16px] font-medium text-gray-800 ">
              Detailed feedback{" "}
            </h4>
            <button
              onClick={() => {
                !EvaluationDropdown &&
                  setEvaluationDropdown(!EvaluationDropdown);
              }}
            >
              <svg
                className={`w-6 h-6 transform transition-transform duration-400 ${EvaluationDropdown ? "rotate-180" : "rotate-0"}`}
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        ) : (
          <div>
            <div className=" flex items-center justify-between px-4 py-2 mt-4">
              <label
                htmlFor=""
                className="text-md font-medium  text-gray-800 not-italic  "
              >
                Evaluation categories
              </label>
              <button
                onClick={() => setEvaluationDropdown(!EvaluationDropdown)}
              >
                <svg
                  className={`w-6 h-6 transform transition-transform duration-400 ${EvaluationDropdown ? "rotate-180" : "rotate-0"}`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            <div className="">
              <FeedbackCriteria
                classTxt={"analytics-dashboard w-full"}
                skills={criteriaSkilArray}
                setSkills={setCriteriaSkilArray}
              />
            </div>
          </div>
        )}
      </div>
      <div className="mt-[15px] rounded-lg p-4  bg-bg1">
        <h3 className="text-[16px] font-medium">Overall recommendation</h3>
        <label className="text-sm font-normal  text-gray-800 not-italic required mt-6">
          Did the candidate pass the interview?
        </label>
        <div className="flex  gap-3">
          {recomandationList?.map((item, index) => (
            <span
              onClick={() => setSelectRecomandation(item)}
              key={index}
              className={`bg-opacity-80 text-sm font-normal not-italic cursor-pointer w-1/6 flex justify-center rounded-lg py-2
                ${
                  selectRecomandation === item
                    ? ["No", "Definitely No"].includes(item)
                      ? "text-red-900 bg-red-300"
                      : ["Yes", "Definitely Yes"].includes(item)
                        ? "text-green-900 bg-green-300"
                        : "bg-blue-400 text-gray-800"
                    : "bg-gray-400 text-black"
                } 
                hover:bg-blue-200 hover:text-[#145ECC]`}
            >
              {item}
            </span>
          ))}
        </div>

        <div className="mt-6 ">
          <label className="text-sm font-normal  text-gray-800 not-italic required">
            Interviewer
          </label>
          <div className="flex justify-between items-center">
            <InputBox
              type="text"
              width="w-full"
              size="medium"
              value={interviewerName}
              onChange={(e) => setInterviewerName(e.target.value)}
              placeholder="Enter name"
            />

            <div className="flex items-center gap-2">
              <button
                className="btn-md btn-sec "
                onClick={(e) => setShowSection("list")}
              >
                cancel
              </button>

              <button
                disabled={
                  selectInterview?.length < 1 ||
                  selectRecomandation?.length < 1 ||
                  interviewerName?.length < 1 ||
                  isSubmitting
                }
                type="button"
                className={`btn-md btn-primary disabled:opacity-60 disabled:pointer-events-none !bg-blue ${isSubmitting ? "cursor-progess" : ""}`}
                onClick={() => {
                  createFeedback();
                }}
              >
                {isSubmitting ? (
                  <>
                    <ScaleLoader
                      height={14}
                      color="#ffffff"
                      loading={true}
                      className="px-10"
                    />
                  </>
                ) : (
                  "Submit feedback"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddNewFeedback;
