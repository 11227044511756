const core = require("../../../lib/core");

export const SearchNewApplicants = async (searchName = "", page = 1) => {
  try {
    // Make API call
    const response = await core.API(
      core.API_METHODS.GET,
      `${core.RECRUITER_API.GET_NEW_APPLICANT_SEARCH_ANALYTICS}?name=${searchName}&page=${page}`,
    );

    // Extract data from response
    const data = response?.data;

    // Return the data
    return data;
  } catch (error) {
    // Handle errors
  }
};

export const SearchActiveJobs = async (searchName = "", page = 1) => {
  try {
    // Make API call
    const response = await core.API(
      core.API_METHODS.GET,
      `${core.RECRUITER_API.GET_ACTIVE_JOBS_SEARCH_ANALYTICS}?name=${searchName}&page=${page}`,
    );

    // Extract data from response
    const data = response?.data;

    // Return the data
    return data;
  } catch (error) {
    // Handle errors
  }
};
