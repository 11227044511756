import { useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { toast } from "react-toastify";
import { Range, getTrackBackground } from "react-range";
import HamburgerSvg from "../components/assets/candidate/Hamburger.svg";
const core = require("../lib/core");

export default function EditSkillAnalytics({
  analytics,
  classTxt,
  skills,
  setSkills,
}) {
  const [reload, setReload] = useState(0);
  const [showTextbox, setShowTextbox] = useState(
    Array(skills?.length).fill(false),
  );
  const timelineColors = ["#00B998"];
  useEffect(() => {
    // console.log(analytics, classTxt, skills, setSkills);
  }, [reload, skills]);

  function showKeySkills(id) {
    setReload(reload + 1);
    if (id) {
      var data = skills?.filter((d) => d.label == id)[0];
    }
  }

  function editSkillValue(slabel, kslabel, value) {
    var skill_arr = skills;
    setReload(reload + 1);
    skill_arr.map((skill, i) => {
      if (skill.label == slabel) {
        skills[i].keySkills.map((ks, it) => {
          if (ks.label == kslabel || ks.label.name == kslabel) {
            ks.value = value;
          }
        });
        let values = skills[i].keySkills.map((d) => d.value);
        skills[i].value = values.reduce((a, b) => a + b) / values.length;
      }
    });
    setSkills(skill_arr);
    showKeySkills(slabel);
  }

  function reorder(array, from, to) {
    // Remove item from array
    let [removed] = array.splice(from, 1);
    // Add item to desired position in array
    array.splice(to, 0, removed);
    // console.log("Reordered - ", array);
  }

  function dragEnd(result) {
    const { source, destination, type } = result;
    // If the candidate is dropped outside of a Droppable, return
    if (!destination) {
      toast("Dragged item out of range");
      return;
    }
    // If the candidate is dropped in the same Droppable and position, return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      toast("Drag item further, it is in the same position");
      return;
    }
    if (type == "criteria") {
      reorder(skills, source.index, destination.index);
    }
    if (type == "skill") {
      let arr_idx = +source.droppableId.split("_")[0];
      reorder(skills[arr_idx].keySkills, source.index, destination.index);
      // console.log(skills);
    }
  }

  const [reloads, setReloads] = useState(0);
  const inputRef = useRef(null);

  return (
    // analytics != null && (
    <section className={classTxt} id="job-analytics">
      <div className="col-xs-12 data-section no-padding">
        <div className="col-xs-12 section-header no-padding">
          <h4 className="col-xs-9 no-padding">Skills</h4>
          <span className="edit col-xs-3 no-padding ">
            <span
              className="col-xs-3 no-padding edit"
              data-tooltip="Add Skills"
              onClick={() => {
                // console.log(skills);
                let skill_arr = [
                  { label: " ", value: 0, keySkills: [] },
                  ...skills,
                ];
                setSkills(skill_arr);
                setReload(reload + 1);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="4 4 24 24"
                fill="none"
                style={{ transform: "rotate(45deg)" }}
              >
                <path
                  d="M24 24L8 8M24 8L8 24"
                  stroke="#001B4B"
                  strokeWidth="2"
                  strokeLinecap="round"
                />
              </svg>
            </span>
          </span>
        </div>
        <div className="col-xs-12 no-padding" id="edit-skills">
          <DragDropContext onDragEnd={dragEnd}>
            <Droppable
              droppableId={"criteria_drop"}
              type="criteria"
              className="col-xs-12 no-padding"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className="col-xs-12 no-padding"
                >
                  {skills?.map((skill, it) => (
                    <Draggable
                      draggableId={
                        String(skill.label).split(" ").join("_") + "_" + it
                      }
                      index={it}
                      key={skill?.label}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="section col-xs-12 no-padding"
                        >
                          <div className="col-xs-12 section-header no-padding">
                            <span
                              {...provided.dragHandleProps}
                              data-tooltip="Hold and drag"
                              className="icon drag"
                            >
                              {" "}
                              <img
                                src={HamburgerSvg}
                                // src="https://sproutsweb-assets.s3.amazonaws.com/common-assets/icons/menu_hamburger.svg"
                                className="icon img-responsive"
                                alt="dragger"
                              />
                            </span>
                            <input
                              type={"text"}
                              className="col-xs-10"
                              placeholder={skill?.label}
                              defaultValue={skill?.label}
                              onChange={(e) => {
                                skill.label = e.target.value;
                              }}
                            />
                            <div className="value">
                              {Math.round(skill?.value)}
                            </div>
                            <div
                              className="icon"
                              data-tooltip="Add skill"
                              onClick={() => {
                                let showarr = showTextbox;
                                showarr[it] = !showarr[it];
                                setShowTextbox(showarr);
                                setReloads(reloads + 1);
                                // reload.func(reload.var + 1);
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="4 4 24 24"
                                fill="none"
                                style={{ transform: "rotate(45deg)" }}
                              >
                                <path
                                  d="M24 24L8 8M24 8L8 24"
                                  stroke="#001B4B"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </div>
                            <div
                              className="icon"
                              data-tooltip="Remove criteria"
                              onClick={() => {
                                setSkills(
                                  skills.filter((d) => d.label != skill?.label),
                                );
                                // reload.func(reload.var + 1);
                                setReloads(reloads + 1);
                              }}
                            >
                              {" "}
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="4"
                                viewBox="0 0 24 4"
                                fill="none"
                              >
                                <path
                                  d="M22.0186 0.400391H1.983C1.33008 0.400391 0.800781 0.967496 0.800781 1.66706V1.80039C0.800781 2.49995 1.33008 3.06706 1.983 3.06706H22.0186C22.6715 3.06706 23.2008 2.49995 23.2008 1.80039V1.66706C23.2008 0.967496 22.6715 0.400391 22.0186 0.400391Z"
                                  fill="#001B4B"
                                />
                              </svg>
                            </div>
                          </div>
                          <div className="input-section col-xs-12 no-padding">
                            {showTextbox[it] ? (
                              <input
                                className="col-xs-12 no-padding"
                                type="text"
                                name="name"
                                placeholder="Enter skill"
                                ref={inputRef}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter") {
                                    e.preventDefault();
                                    if (
                                      skill.keySkills.filter(
                                        (d) => d.label == e.target.value,
                                      ).length > 0
                                    ) {
                                      toast.error("Skill existing");
                                      console.log("Already exists");
                                      return;
                                    }
                                    skill.keySkills = [
                                      ...skill?.keySkills,
                                      {
                                        label: e.target.value,
                                        value: 5,
                                      },
                                    ];
                                    inputRef.current.value = "";
                                    let values = skill.keySkills.map(
                                      (d) => d.value,
                                    );
                                    skill.value =
                                      values.reduce((a, b) => a + b) /
                                      values.length;
                                    // reload.func(reload.var + 1);
                                    setReloads(reloads + 1);
                                  }
                                }}
                              />
                            ) : null}
                          </div>
                          <Droppable droppableId={it + "_drop"} type="skill">
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className="list-section col-xs-12 no-padding"
                              >
                                {skill?.keySkills?.map((ks, i) => (
                                  <Draggable
                                    draggableId={it + "_" + i}
                                    index={i}
                                    key={it + "_" + i}
                                  >
                                    {(provided, snapshot) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className="col-xs-12 no-padding line"
                                      >
                                        <div
                                          className="col-xs-4 no-padding name"
                                          {...provided.dragHandleProps}
                                        >
                                          {ks?.label?.name ?? ks?.label}
                                        </div>
                                        <div className="col-xs-7 level">
                                          <Range
                                            step={1}
                                            min={0}
                                            max={10}
                                            values={[ks?.value]}
                                            onChange={(value) => {
                                              editSkillValue(
                                                skill?.label,
                                                ks.label.name ?? ks.label,
                                                value[0],
                                              );
                                              // skill?.keySkills[it].value = value[0];
                                            }}
                                            renderTrack={({
                                              props,
                                              children,
                                            }) => (
                                              <div
                                                {...props}
                                                style={{
                                                  ...props.style,
                                                  height: "12px",
                                                  width: "90%",
                                                  borderRadius: "4px",
                                                  right: 0,
                                                  position: "relative",
                                                  background:
                                                    getTrackBackground({
                                                      values: [ks?.value],
                                                      colors: [
                                                        "#00B998",
                                                        "#f2f2f2",
                                                      ],
                                                      min: 0,
                                                      max: 10,
                                                    }),
                                                }}
                                              >
                                                {children}
                                              </div>
                                            )}
                                            renderThumb={({ props }) => (
                                              <div
                                                {...props}
                                                style={{
                                                  ...props.style,
                                                  height: "20px",
                                                  width: "20px",
                                                  borderRadius: "50%",
                                                  background: "white",
                                                  border: "2px solid #999",
                                                }}
                                              />
                                            )}
                                          />
                                        </div>
                                        <div style={{ width: 20 }}>
                                          {ks.value}
                                        </div>
                                        <button
                                          onClick={(e) => {
                                            e.preventDefault();
                                            skill.keySkills =
                                              skill?.keySkills.filter(
                                                (d) => d.label != ks.label,
                                              );
                                            // reload.func(reload.var + 1);
                                            setReloads(reloads + 1);
                                          }}
                                        >
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="4"
                                            viewBox="0 0 24 4"
                                            fill="none"
                                          >
                                            <path
                                              d="M22.0186 0.400391H1.983C1.33008 0.400391 0.800781 0.967496 0.800781 1.66706V1.80039C0.800781 2.49995 1.33008 3.06706 1.983 3.06706H22.0186C22.6715 3.06706 23.2008 2.49995 23.2008 1.80039V1.66706C23.2008 0.967496 22.6715 0.400391 22.0186 0.400391Z"
                                              fill="#001B4B"
                                            />
                                          </svg>
                                        </button>
                                      </div>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </section>
    // )
  );
}
