import React, { useState, useEffect, Children } from "react";
import ColorPicker from "react-pick-color";
import Modal from "../../../components/modal/Modal";

const ColorModal = ({ isOpen, onClose, color, setColor, setTheme }) => {
  const [hexInput, setHexInput] = useState(color);
  const quickPickColors = [
    "#6b7280",
    "#475569",
    "#525252",
    "#dc2626",
    "#7c2d12",
    "#f97316",
    "#fcd34d",
    "#facc15",
    "#22c55e",
    "#059669",
    "#15803d",
    "#065f46",
    "#2dd4bf",
    "#0d9488",
    "#22d3ee",
    "#0891b2",
    "#155e75",
    "#0369a1",
    "#0c4a6e",
    "#1e40af",
    "#4338ca",
    "#7e22ce",
    "#d946ef",
    "#a21caf",
    "#f472b6",
    "#db2777",
    "#f43f5e",
    "#be123c",
  ];

  useEffect(() => {
    setHexInput(color);
  }, [color]);

  const handleHexInputChange = (e) => {
    const newColor = e.target.value;
    setHexInput(newColor);
    if (/^#[0-9A-F]{6}$/i.test(newColor)) {
      setColor(newColor);
    }
  };

  const handleQuickPick = (color) => {
    setColor(color);
    setHexInput(color);
  };

  const handleSave = () => {
    setTheme(hexInput);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <>
      <Modal
        heading={"Select Theme"}
        onSave={handleSave}
        modalDismiss={onClose}
        onCancel={onClose}
      >
        <div>
          {/* <div className="fixed inset-0 flex items-center justify-center z-50 bg-gray-200 bg-opacity-50"> */}
          {/* <div className="bg-white rounded-lg p-4 shadow-lg w-1/2">
        <div className="flex justify-between items-center mb-4">
          <p className="text-2xl font-bold">Select Theme</p>
          <button
            className="text-gray-700 border border-gray-500 px-4 py-1 text-md rounded-md font-bold hover:text-white hover:bg-blue-800 hover:border-blue-800"
            onClick={onClose}
          >
            ✕
          </button>
        </div> */}
          <ColorPicker
            color={color}
            onChange={(color) => setColor(color.hex)}
            theme={{ borderRadius: "8px", width: "100%" }}
          />
          <div className="mt-4">
            <label htmlFor="hexInput" className="block text-gray-700 mb-2">
              Enter Hex Code:
            </label>
            <input
              id="hexInput"
              type="text"
              value={hexInput}
              onChange={handleHexInputChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:border-gray-700"
              placeholder="#FFFFFF"
            />
          </div>
          <div className="mt-4">
            <label htmlFor="hexInput" className="block text-gray-700 mb-2">
              Quick Pick
            </label>
            <div className="grid grid-cols-10 gap-2">
              {quickPickColors.map((quickColor) => (
                <div
                  key={quickColor}
                  className="w-8 h-8 cursor-pointer rounded"
                  style={{ backgroundColor: quickColor }}
                  onClick={() => handleQuickPick(quickColor)}
                ></div>
              ))}
            </div>
          </div>
          {/* <div className="mt-4 flex justify-end">
            <button
              onClick={onClose}
              className="bg-gray-300 text-gray-800 rounded-md px-4 py-2 mr-2 hover:bg-gray-500"
            >
              Cancel
            </button>
            <button
              onClick={handleSave}
              className="bg-blue-800 text-white rounded-md px-4 py-2 hover:bg-blue-600"
            >
              Save
            </button>
          </div> */}
        </div>
      </Modal>
    </>
  );
};

export default ColorModal;
