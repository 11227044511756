import React, { useState, useEffect, useRef, useContext } from "react";
import Modal from "../../../components/modal/Modal";
import { ScaleLoader } from "react-spinners";
import Skeleton from "react-loading-skeleton";
import ReactPaginate from "react-paginate";
const core = require("../../../lib/core");

const SelectJobModal = ({
  isOpen,
  onClose,
  jobList,
  reloadList,
  totalPage,
  currentPage,
  setCurrentPage,
  handlePageClick,
  fetchJob,
  loadListings,
  setLoadListings,
}) => {
  const { user } = useContext(core.UserContext);
  const [loading, setLoading] = useState(-1);
  const [showDropdown, setShowDropdown] = useState(-1);

  useEffect(() => {
    setLoading(-1);
  }, [jobList]);

  const toggleJobState = async (index) => {
    try {
      setLoading(index);
      setShowDropdown(-1);
      const job = jobList[index];
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_UPDATE_JOB_STATUS + job.id,
        1,
        { posting_status: !job.posting_status },
      );
      reloadList();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchJob(currentPage);
  }, [currentPage]);

  return !isOpen ? null : (
    <Modal
      heading={"Manage job posts"}
      noBtns={true}
      subheading={`Manage the posting of different jobs on the ${user?.organization?.name}  career page`}
      className={"!w-11/12 !max-w-5xl"}
      modalDismiss={onClose}
    >
      {!loadListings && jobList === false ? (
        <div className="flex justify-center items-center w-full h-full min-h-[20rem]">
          <p className="text-gray-600 text-xl">No Matches</p>
        </div>
      ) : (
        <div className="relative pb-3 w-full h-full min-h-[20rem]">
          {!loadListings && jobList.length > 0 ? (
            <>
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="text-center mb-4">
                    <th className="w-1/6 pb-2 text-center">Status</th>
                    <th className="w-2/6 pb-2 text-center">Post Name</th>
                    <th className="w-2/6 pb-2 text-center">Location</th>
                    <th className="w-1/6 pb-2 text-center">Workplace</th>
                  </tr>
                </thead>
                <tbody className="mt-10">
                  {jobList.map((job, index) => (
                    <tr key={index} className="border-t border-gray-400 my-4">
                      <td className="py-4">
                        <div className="relative flex items-center justify-center">
                          <button
                            onClick={() =>
                              showDropdown == index
                                ? setShowDropdown(-1)
                                : setShowDropdown(index)
                            }
                            className={`w-28 h-7 flex items-center justify-center gap-2 text-center rounded-md px-3 py-1 text-white ${
                              job?.posting_status
                                ? "bg-green-700"
                                : loading == index
                                  ? "bg-blue-700"
                                  : "bg-red-700"
                            }`}
                          >
                            {loading == index ? (
                              <ScaleLoader
                                height={12}
                                color={"#FFFFFF"}
                                loading={true}
                              />
                            ) : job?.posting_status ? (
                              "Live"
                            ) : (
                              "Inactive"
                            )}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={2}
                              stroke="currentColor"
                              className="w-4 h-4"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m19.5 8.25-7.5 7.5-7.5-7.5"
                              />
                            </svg>
                          </button>
                          {showDropdown == index && (
                            <div className="absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg top-9 left-8">
                              <button
                                onClick={() => toggleJobState(index)}
                                className={`block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 ${
                                  job?.posting_status
                                    ? "pointer-events-none"
                                    : ""
                                }`}
                              >
                                {"Make live"}
                              </button>
                              <button
                                onClick={() => toggleJobState(index)}
                                className={`block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-blue-100 ${
                                  !job?.posting_status
                                    ? "pointer-events-none"
                                    : ""
                                }`}
                              >
                                {"Make inactive"}
                              </button>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="py-4 text-center">{job?.position}</td>
                      <td className="py-4 text-center">
                        {job?.location?.[0]?.name}
                      </td>
                      <td className="py-4 text-center ">{job?.workplace}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              {totalPage > 1 && (
                <div className="mt-10 flex justify-center relative bg-[#FFF] border-t border-t-[#EFF0F2] rounded-lg">
                  <ReactPaginate
                    previousLabel={"<- Previous"}
                    nextLabel={"Next ->"}
                    breakLabel={"..."}
                    pageCount={totalPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"custom-pagination"}
                    subContainerClassName={"pages custom-pagination"}
                    activeClassName={"active"}
                    forcePage={currentPage - 1}
                  />
                </div>
              )}
            </>
          ) : (
            [1, 2].map((_, i) => (
              <div
                key={"skelRow" + i}
                className="flex justify-center items-center gap-6 h-12 mt-4"
              >
                <div className="w-1/6">
                  <Skeleton className="w-full" height={30} width={112} />
                </div>
                <div className="w-1/3">
                  <Skeleton className="w-full" height={10} />
                </div>
                <div className="w-1/3">
                  <Skeleton className="w-full" height={10} />
                </div>
                <div className="w-1/6">
                  <Skeleton className="w-full" height={10} />
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </Modal>
  );
};

export default SelectJobModal;
