export const authStages = {
  CREATED: "CREATED",
  // Verified, but pwd not set => Set password
  VERIFIED: "VERIFIED",
  // Pwd set but account type not selected => Set account
  SECURED: "SECURED",
  // Account selected but profile not created => Create profile
  SELECTED: "SELECTED",
  // Account submitted => Admin will accept/reject => Active status
  SUBMITTED: "SUBMITTED",
  // Account rejected => Admin rejected the account
  REJECTED: "REJECTED",
  // All steps completed => Dashboard
  COMPLETED: "COMPLETED",
};

export const defaultLikertValues = [
  { name: "Poor", value: 1 },
  { name: "Weak", value: 2 },
  { name: "Average", value: 3 },
  { name: "Good", value: 4 },
  { name: "Excellent", value: 5 },
];

export const defaultYesNoValues = ["Yes", "No"];

export const CONTENT = {
  EMPTY_STATE: {
    JOB_LIST: "",
    JOB_DETAILS: "",
    COMPANY_LIST: "",
    COMPANY_DETAILS: "",
    CANDIDATES_TABLE: "",
    CANDIDATES_PIPELINE: "",
    CANDIDATES_BROWSER: "",
    ASSESSMENTS: "",
    ASSESSMENT_INVITES: "",
    ASSESSMENT_REPORTS: "",
    INTERVIEW_SCHEDULED: "",
    INTERVIEW_REPORTS: "",
    NOTIFICATIONS: "",
    MESSAGES: "",
  },
  MODAL_CONTENT: {
    RETRIVE_CANDIDATE: {
      HEADING: "SproutsAI recommended candidates",
      SUBHEADING:
        "Retrieve top talent from SproutsAI's candidate database, and use the status button to see retrieval progress status.",
      SUCCESS: "Candidate retrieved successfully",
      ERROR: "",
    },
    ADD_CANDIDATE_LINKEDIN_URL: {
      HEADING: "Add candidates",
      SUBHEADING: "Add candidates by using Linkedin URL.",
      SUCCESS:
        "The resume is being processed to evaluate the match. This may take a few moments. Once the matching is complete the profile will show under the candidate tab.",
      ERROR: "",
    },
    ATTACH_CANDIDATE_TO_JOB: {
      HEADING: "Apply to a job",
      SUBHEADING: "Select job(s) to attach this candidate to",
      SUCCESS:
        "Candidates Added. The resume is being processed to evaluate the match. This may take a few moments. Once the matching is complete the profile will show under the candidate tab.",
      ERROR: "",
    },
    // CHANGE JOB STATUS
    ARCHIVE_JOB: {
      SUBHEADING:
        "Are you sure you want to archive this job. This action cannot be undone.",
      SUCCESS: "Job is successfully moved to archive",
      ERROR: "",
    },
    DELETE_JOB: {
      SUBHEADING:
        "Are you sure you want to delete this job. This action cannot be undone.",
      SUCCESS: "Job is successfully deleted",
      ERROR: "",
    },
    PUBLISH_JOB: {
      SUBHEADING: "Are you sure you want to publish this job.",
      SUCCESS: "Job is now made public and visible to all users.",
      ERROR: "",
    },
    UNPUBLISH_JOB: {
      SUBHEADING: "Are you sure you want to make this job private.",
      SUCCESS: "Job is now made private and moved to drafts",
      ERROR: "",
    },
    // CONFIRMATION BEFORE DELETING
    DELETE_CONFIRMATION: {
      DELETE_USER: { HEADING: "", SUBHEADING: "", SUCCESS: "", ERROR: "" },
      DELETE_CANDIDATE: { HEADING: "", SUBHEADING: "", SUCCESS: "", ERROR: "" },
    },
  },
};

export const ACTIVITY_TYPE = {
  EMAIL: "email",
  NOTE: "note",
  REPORT: "scorecard",
  SCHEDULED: "scheduled",
  ACTIVITY: "activity",
  SEQUENCE: "sequence",
};

export const ANSWERTYPES = {
  TEXT: "paragraph",
  NUMBER: "number",
  RADIO: "radio",
  MCQ: "checkbox",
  YESNO: "yes/no",
  LIKERT: "likert",
};
