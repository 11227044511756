import React, {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  useLayoutEffect,
} from "react";
import JobSidebar from "../../components/all/JobSidebar";
import HeaderNew from "../../components/all/header/HeaderNew";
import InitialsLogo from "../../components/all/InitialsLogo";
import VoiceCall from "../voice/VoiceCall";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import AddChatUserModal from "./AddChatUserModal";
import ScrollableFeed from "react-scrollable-feed";
import { chatContext } from "../../lib/ChatContext";
import "react-chat-elements/dist/main.css";
import { MessageList } from "react-chat-elements";
import { ScaleLoader } from "react-spinners";
import parse from "html-react-parser";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { IoMdArrowDropdown } from "react-icons/io";
import { toast } from "react-toastify";
import { User, Send } from "lucide-react";
import { Link } from "lucide-react";
const core = require("../../lib/core");

function Sms() {
  const { socket, setTouser, notification, setNotification } = chatContext();

  const [open, setOpen] = useState(true);
  const [chatList, setChatList] = useState([]);
  const [receivedMessages, setReceivedMessages] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getallchats();
  }, []);

  const getallchats = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.CHAT_API.GET_ALL_SMSCHATS,
        1,
      );
      if (json) {
        if (json.data.length > 0) {
          setChatList(
            json?.data?.sort((a, b) => a?.dateUpdated - b?.dateUpdated),
          );
        } else {
          setChatList([]);
        }
      }
    } catch {}
  };

  const groupedMessagesfunction = (messages) => {
    console.log(messages);
    // Create an object to group messages by date
    const groupedMessages = {};

    messages.forEach((message) => {
      // Extract the date from the message (assuming dateCreated contains the date)
      const messageDate = new Date(message?.date_created).toDateString();

      // Check if a group for this date exists, or create one
      if (!groupedMessages[messageDate]) {
        groupedMessages[messageDate] = [];
      }

      // Add the message to the corresponding group
      groupedMessages[messageDate]?.push(message);
    });
    return groupedMessages;
  };

  const [loadSendMessages, setLoadSendMessages] = useState(false);

  const [messagesList, setMessagesList] = useState([]);

  const getChatdetail = async (id) => {
    socket.emit("room_id", id);

    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.GET_MESSAGE_LIST,
        1,
        {
          chatUser: id,
        },
      );

      if (json?.data?.messages) {
        setMessagesList(json?.data?.messages);
        setLoadSendMessages(false);
        setLoading(false);
      } else {
        setLoadSendMessages(false);
      }
    } catch {
      setLoading(false);
      setLoadSendMessages(false);
    }
  };

  const seenNotificationUpdate = async (id) => {
    try {
      let json = await core.API(
        core.API_METHODS.PUT,
        core.CHAT_API.PUT_MESSAGE_NOTIFICATION,
        1,
        {
          _id: id,
        },
      );

      if (json.data) {
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setReceivedMessages(groupedMessagesfunction(messagesList));
  }, [messagesList]);

  const [newUserName, setNewUserName] = useState("");
  const [newUserNumber, setNewUserNumber] = useState("");

  return (
    <div
      className="w-screen h-screen p-0 overflow-hidden"
      id="job_details_page"
    >
      <div className={`flex flex-row w-screen h-screen left-0 relative top-0`}>
        <JobSidebar
          active="Messages"
          open={open}
          setOpen={setOpen}
          CandidateFilter={
            <SmsList
              getallchats={getallchats}
              setLoading={setLoading}
              chatList={chatList}
              getChatdetail={getChatdetail}
              setTo={setTouser}
              setUserDetail={setUserDetail}
              userDetail={userDetail}
              seenNotificationUpdate={seenNotificationUpdate}
              messagesList={messagesList}
              setNewUserName={setNewUserName}
              setNewUserNumber={setNewUserNumber}
              newUserNumber={newUserNumber}
              notification={notification}
              setNotification={setNotification}
            />
          }
        />
        <div className="w-full">
          <HeaderNew />
          <SmsContent
            chatList={chatList}
            socket={socket}
            setNotification={setNotification}
            notification={notification}
            setChatList={setChatList}
            seenNotificationUpdate={seenNotificationUpdate}
            loading={loading}
            messagesList={messagesList}
            setMessagesList={setMessagesList}
            receivedMessages={receivedMessages}
            userDetail={userDetail}
            getChatdetail={getChatdetail}
            loadSendMessages={loadSendMessages}
            setLoadSendMessages={setLoadSendMessages}
          />
        </div>
      </div>
    </div>
  );
}

export default Sms;

// const MessageContent = ({ message }) => {
//   const isInbound = message.position === 'left';
//   const formattedDate = format(new Date(message.date), 'MMM d, yyyy h:mm a');

//   return (
//     <div className="bg-white rounded-lg shadow-md p-6 mb-6 mx-3">
//       <div className="flex items-center justify-between mb-4">
//         <div className="flex items-center">
//           {isInbound ? (
//             <User className="w-6 h-6 text-blue-500 mr-2" />
//           ) : (
//             <Send className="w-6 h-6 text-green-500 mr-2" />
//           )}
//           <span className={`font-bold text-lg ${isInbound ? 'text-blue-600' : 'text-green-600'}`}>
//             {message.title}
//           </span>
//         </div>
//         <span className="text-sm text-gray-500">{formattedDate}</span>
//       </div>
//       <div className="text-gray-800 leading-relaxed text-justify ">
//         {parse(message.text
//           ?.split("&lt;")?.join("<")
//           ?.split("&gt;")?.join(">")
//         )}
//       </div>
//     </div>
//   );
// };

function SmsContent({
  seenNotificationUpdate,
  setNotification,
  notification,
  messagesList,
  setMessagesList,
  userDetail,
  chatList,
  setChatList,
  socket,
  loading,
  loadSendMessages,
  setLoadSendMessages,
  getChatdetail,
}) {
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [fetchNumber, setFetchNumber] = useState("");
  const userDetailRef = useRef(userDetail);
  const [showemailOption, setShowemailOption] = useState(false);
  const emailOptionRef = useRef(null);

  useEffect(() => {
    userDetailRef.current = userDetail;
  }, [userDetail]);

  const updateNotifications = (newItem) => {
    const updatedNotifications = [...notification];
    const existingIndex = updatedNotifications.findIndex(
      (item) => item._id === newItem.chatUser,
    );
    if (existingIndex !== -1) {
      updatedNotifications[existingIndex].notificationCount += 1;
    } else {
      updatedNotifications.push({
        _id: newItem.chatUser,
        notificationCount: 1,
      });
    }
    setNotification(updatedNotifications);
  };
  const styles = `
  .quill {
    border-radius: 4px;
    border: none;
    display: flex;
    flex-direction: column-reverse;
    margin-bottom: 0.2rem;
}

.custom-quill-editor .ql-container {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background: #fff;
    min-height: 200px;
    border: none !important;

}

.custom-quill-editor .ql-toolbar {
    border-radius: 4px;
    background: #F3F5F9;
    color: #000;
    border: none !important;
    padding: 8px 12px;

}

/*  toolbar button styles */
.custom-quill-editor .ql-toolbar button {
    color: #333;
}

.custom-quill-editor .ql-toolbar button:hover {
    color: #7b7878;
}

/* editor content area */
.custom-quill-editor .ql-editor {
    font-family: sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding-top: 20px;
    padding-bottom: 40px;
}

/* toolbar dropdowns  */
.custom-quill-editor .ql-toolbar .ql-picker-options {
    top: auto !important;
    bottom: 100% !important;
}

/* position of color picker */
.custom-quill-editor .ql-color-picker,
.custom-quill-editor .ql-background {
    .ql-picker-options {
        top: auto !important;
        bottom: 100% !important;
        transform: translateY(1px);
    }
}

/* Style placeholder text */
.custom-quill-editor .ql-editor.ql-blank::before {
    color: #a0aec0;
    font-style: normal;
    font-size: 1rem !important
}

/* Style the link popup */
.custom-quill-editor .ql-tooltip {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
    z-index: 9999 !important;
    background-color: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 10px;
}

/* Ensure the editor doesn't have any borders */
.custom-quill-editor,
.custom-quill-editor .ql-container,
.custom-quill-editor .ql-toolbar {
    border: none !important;
    outline: none !important;
}
    
  `;

  const [inputValue, setInputValue] = useState("");
  const [selectedCcOptions, setSelectedCcOptions] = useState([]);
  const [recommendedEmails, setRecommendedEmails] = useState([]);
  const [AllEmails, setAllEmails] = useState([]);
  const [transmissionMsg, setTransmissionMsg] = useState("Default");
  useEffect(() => {
    get_user_list();
  }, []);
  const get_user_list = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER_LIST_ORG,
        1,
      );
      if (json?.data) {
        const extractedEmails = json?.data.map((item) => item.email);
        setAllEmails(extractedEmails);
      }
    } catch (error) {}
  };
  useEffect(() => {
    socket.on("message", (message) => {
      if (userDetailRef.current?._id === message?.chatUser) {
        setMessagesList((prevMessages) => [...prevMessages, message]);
      } else {
        updateNotifications(message);
        updateLastMessageAndSeenById(message?.chatUser, message?.body);
      }
    });
  }, []);

  useEffect(() => {
    if (userDetail?._id) {
      seenNotificationUpdate(userDetail?._id);
    }
  }, [userDetail, messagesList]);

  const { user } = useContext(core.UserContext);

  // Function to update the last message and last seen based on message id
  const updateLastMessageAndSeenById = (id, message) => {
    setChatList((prevMessages) => {
      const updatedMessages = prevMessages.map((msg) => {
        if (msg._id === id) {
          return {
            ...msg,
            lastMessage: message,
            lastSeen: new Date().toISOString(), // Assuming you want to update lastSeen to current time
          };
        }
        return msg;
      });
      return updatedMessages;
    });
  };

  const sendMessage = async () => {
    if (message.length == 0 || subject.length == 0) {
      toast.warn("Enter All Fields");
      return;
    }
    let outgoingmsg = {
      type: "msg",
      date_created: new Date(),
      direction: "outbound-api",
      body: message,
      status: "delivered",
    };
    setLoadSendMessages(true);
    try {
      let attachmentUrls = [];
      if (attachments.length > 0) {
        setTransmissionMsg("Uploading");
        const formData = new FormData();
        for (let i = 0; i < attachments.length; i++) {
          formData.append("files", attachments[i]);
        }

        const token = await core.getData(core.data.ACCESS_TOKEN);
        const uploadResponse = await fetch(core.S3_API.POST_UPLOAD_FILE, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            uid: user?.id,
          },
          body: formData,
        });
        const uploadData = await uploadResponse.json();
        attachmentUrls = uploadData?.data || [];
        setTransmissionMsg("Default");
      }
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_EMAIL_CANDIDATE,
        1,
        {
          to: userDetail?.toEmail,
          cc: selectedCcOptions,
          body: message,
          subject: subject,
          matchprofile: userDetail?.matchProfile,
          applicant: userDetail?._id,
          job: userDetail?.jobId,
          name: userDetail?.name ?? "",
          s3AttachmentUrls: attachmentUrls,
        },
      );
      if (json?.code == 200) {
        try {
          let outgoingmsg = {
            type: "msg",
            date_created: new Date(),
            direction: "outbound-api",
            body: message,
            status: "delivered",
            attachments: attachmentUrls.map((url) => ({
              url,
              filename:
                attachments.find((file) => url.includes(file.name))?.name ||
                "Attachment",
            })),
          };
          let json = await core.API(
            core.API_METHODS.POST,
            core.CHAT_API.GET_SEND_MESSAGE,
            1,
            {
              body: message,
              toPhone: userDetail?.toPhone,
              jobId: userDetail?.jobId,
              matchProfile: userDetail?.matchProfile,
              toEmail: userDetail?.toEmail,
              toUserId: userDetail?.toUserId,
              chatUser: userDetail?._id,
              name: userDetail?.name,
              attachments: outgoingmsg.attachments,
            },
          );
          if (json) {
            setMessagesList((prevMessages) => [...prevMessages, outgoingmsg]);
            // setMessage("");
            setLoadSendMessages(false);
            updateLastMessageAndSeenById(userDetail?._id, message);
            socket.emit("message", {
              body: message,
              toPhone: userDetail?.toPhone,
              jobId: userDetail?.jobId,
              matchProfile: userDetail?.matchProfile,
              toEmail: userDetail?.toEmail,
              toUserId:
                user?.id === userDetail?.created_by
                  ? userDetail?.toUserId
                  : userDetail?.created_by,
              chatUser: userDetail?._id,
              name: userDetail?.name,
            });
          } else {
            setLoadSendMessages(false);
          }
        } catch {
          setLoadSendMessages(false);
        }
        setSelectedCcOptions([]);
        setSubject("");
        setMessage("");
        setAttachments([]);
        getChatdetail(userDetail?._id);
      }
    } catch (error) {
      console.log(error);
      toast.error("Error Sending Message");
    }
  };

  const editorRef = useRef(null);
  const quillInstanceRef = useRef(null);
  const fileInputRef = useRef(null);
  const [attachments, setAttachments] = useState([]);
  const initializeQuill = () => {
    if (editorRef.current && !quillInstanceRef.current) {
      const toolbarOptions = [
        [{ font: [] }],
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic"],
        ["link"],
        ["attachFile"],
      ];

      const quill = new Quill(editorRef.current, {
        modules: {
          toolbar: {
            container: toolbarOptions,
            handlers: {
              attachFile: handleAttachFile,
            },
          },
        },
        placeholder: "Write your message here",
        theme: "snow",
      });

      quill.on("text-change", (delta, oldDelta, source) => {
        if (source === "user") {
          setMessage(quill.root.innerHTML);
        }
      });

      quillInstanceRef.current = quill;

      // Add custom button icon
      const attachButton = document.querySelector(".ql-attachFile");
      if (attachButton) {
        attachButton.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M216.08 192v143.85a40.08 40.08 0 0 0 80.15 0l.13-188.55a67.94 67.94 0 1 0-135.87 0v189.82a95.51 95.51 0 1 0 191 0V159.74"/></svg>
        `;
      }
      quill.root.innerHTML = message;
    }
  }; // Empty dependency array ensures this effect runs only once

  useEffect(() => {
    if (quillInstanceRef.current) {
      const quill = quillInstanceRef.current;
      const currentContent = quill.root.innerHTML;
      if (currentContent !== message) {
        quill.root.innerHTML = message;
      }
    }
  }, [message]);

  useLayoutEffect(() => {
    let attempts = 0;
    const maxAttempts = 5;
    const interval = setInterval(() => {
      if (editorRef.current && !quillInstanceRef.current && showemailOption) {
        initializeQuill();
        clearInterval(interval);
      } else if (attempts >= maxAttempts) {
        clearInterval(interval);
      }
      attempts++;
    }, 1000);

    return () => clearInterval(interval);
  }, [showemailOption]);

  useEffect(() => {
    if (showemailOption) {
      initializeQuill();
    } else {
      // Preserve the message when hiding email option
      if (quillInstanceRef.current) {
        setMessage(quillInstanceRef.current.root.innerHTML);
        quillInstanceRef.current = null;
      }
    }
  }, [showemailOption]);
  const handleAttachFile = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setAttachments((prevAttachments) => [...prevAttachments, ...files]);
  };
  const removeAttachment = (index) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, i) => i !== index),
    );
  };

  const [hideCallBar, setHideCallBar] = useState(false);

  function formatDateTime(dateCreated) {
    const options = {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    const dateTime = new Date(dateCreated);
    return dateTime.toLocaleDateString(undefined, options);
  }
  function formatDate(dateStr) {
    // Convert the input date string to a Date object
    const inputDate = new Date(dateStr);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in days between the input date and the current date
    const timeDifference = (currentDate - inputDate) / (1000 * 3600 * 24);

    if (timeDifference < 1) {
      return "Today";
    } else if (timeDifference < 2) {
      return "Yesterday";
    } else if (timeDifference < 7) {
      // Format the input date as a day of the week (e.g., Sun, Mon, etc.)
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      return daysOfWeek[inputDate.getDay()];
    } else {
      // Format the input date in a custom way (e.g., 'Fri Sep 01 2023')
      const options = { year: "numeric", month: "short", day: "numeric" };
      return inputDate.toLocaleDateString("en-US", options);
    }
  }

  // const fetchRecordingUrl = async () => {
  //   try {
  //     // Replace with the actual URL for the recording from your Twilio response
  //     const response = await fetch(
  //       "https://api.twilio.com/2010-04-01/Accounts/ACc6de85618a2972db84f4727bbd8354b5/Recordings/RE1ba9290e98db6658bea65c34891fd85f/Transcriptions.json",
  //     );

  //     console.log(response);
  //     if (response.ok) {
  //       const data = await response.json();
  //     } else {
  //       console.error("Failed to fetch recording URL");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching recording URL:", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchRecordingUrl();
  // }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      // Call your function here
      if (message.length > 0 && subject.length > 0) {
        sendMessage();
      }

      // Optionally, you can prevent the default behavior (form submission)
      e.preventDefault();
    }
  };

  // change the showemailoption to false

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emailOptionRef.current &&
        !emailOptionRef.current.contains(event.target)
      ) {
        setShowemailOption(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [emailOptionRef]);

  const handleCcInputChange = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.length > 0) {
      const filteredEmails = AllEmails.filter((email) =>
        email.toLowerCase().includes(e.target.value.toLowerCase()),
      );
      if (filteredEmails.length > 0) setRecommendedEmails(filteredEmails);
      else {
        setRecommendedEmails([`${e.target.value}`]);
      }
    } else {
      setRecommendedEmails([]);
    }
  };

  const handleCcKeyPress = (e) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      addEmailIfValid(inputValue.trim());
    }
  };

  const handleRemoveCcOption = (index) => {
    const newOptions = selectedCcOptions.filter((_, i) => i !== index);
    setSelectedCcOptions(newOptions);
  };

  const handleCcOptionClick = (email) => {
    addEmailIfValid(email);
  };

  const addEmailIfValid = (email) => {
    if (isValidEmail(email) && !isDuplicateEmail(email)) {
      setSelectedCcOptions([...selectedCcOptions, email]);
      setInputValue("");
      setRecommendedEmails([]);
    } else {
      setInputValue("");
      setRecommendedEmails([]);
    }
  };

  const isValidEmail = (email) => {
    return email.includes("@") && email.includes(".");
  };

  const isDuplicateEmail = (email) => {
    return selectedCcOptions.includes(email);
  };

  const getTransmissionMsg = () => {
    switch (transmissionMsg) {
      case "Uploading":
        return (
          <div className="flex items-center gap-1 text-blue-800">
            Uploading files...
            <div className="inset-0 rounded-full border-2 w-4 h-4 border-blue-600 border-t-transparent animate-spin"></div>
          </div>
        );
      case "Default":
        return (
          <div className="flex items-center gap-1 text-blue-800">
            Sending email...
            <div className="inset-0 rounded-full border-2 w-4 h-4 border-blue-600 border-t-transparent animate-spin"></div>
          </div>
        );
    }
  };
  return (
    <main className="relative block w-[calc(100%-16px) h-[calc(100vh-56px)] top-[15px] p-[18px] mx-[8px] rounded-[16px] bg-[#F3F5F9] ">
      {/* {receivedMessages?.length>0 && */}
      <style>{styles}</style>
      {chatList?.length > 0 ? (
        <div className="relative flex flex-col h-[calc(100vh-100px)]">
          {userDetail?._id && (
            <div className="pb-[15px] flex items-center justify-between border-b-[2px] border-b-[#E1E1EE]">
              <span className="flex items-center">
                <InitialsLogo str={userDetail?.name} />
                <div className="flex flex-col">
                  <span className="ml-[8px] ">
                    {" "}
                    <span className="ml-[8px] text-[#252E4B] text-[18px] font-semibold not-italic leading-[24px]">
                      {userDetail?.name}
                    </span>
                    {userDetail?.position && (
                      <span className="text-[#fff] border border-[green] bg-[green] rounded-[20px] px-[15px] py-[3px] ml-[10px] text-[14px] font-semibold not-italic leading-[24px]">{`${userDetail?.position + "," + userDetail?.company}`}</span>
                    )}
                  </span>
                  <span className="ml-[8px] text-[#5F6989] text-[14px] font-normal not-italic ">
                    {userDetail?.to}
                  </span>
                </div>
              </span>
              <div className="flex">
                {userDetail?.toPhone && (
                  <span
                    className=" ml-[12px] cursor-pointer shadow-menuOptionShadow interview-button  p-[6px] bg-[#FFF] border border-[#DADAE4] w-[40px] h-[40px] flex items-center justify-center rounded-[8px]"
                    data-toggle="modal"
                    data-target="#call-modal"
                    onClick={() => {
                      setFetchNumber(userDetail?.toPhone);
                      setHideCallBar(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M7.2197 3.52275C7.00317 2.98526 6.47887 2.63281 5.89584 2.63281H3.93477C3.18875 2.63281 2.58398 3.23315 2.58398 3.97388C2.58398 10.2701 7.72467 15.3743 14.0658 15.3743C14.8119 15.3743 15.4165 14.7738 15.4165 14.033L15.4168 12.0856C15.4168 11.5067 15.0619 10.9862 14.5206 10.7712L12.641 10.025C12.1548 9.83183 11.6012 9.91875 11.1988 10.2516L10.7137 10.6533C10.1472 11.1221 9.31365 11.0848 8.7922 10.5671L7.42627 9.20958C6.90482 8.69183 6.86629 7.86485 7.33839 7.30234L7.74288 6.82073C8.07816 6.42125 8.16647 5.87139 7.97197 5.38858L7.2197 3.52275Z"
                        fill="#5F6989"
                      />
                    </svg>
                  </span>
                )}
              </div>
            </div>
          )}

          {hideCallBar && (
            <div className="absolute z-10 w-[256px] rounded-[12px] bg-[#FFF] right-0 bottom-[40px] ">
              <VoiceCall
                setHideCallBar={setHideCallBar}
                chatDetail={{ phone: [userDetail?.toPhone] }}
                fetchNumber={fetchNumber}
              />
            </div>
          )}

          {loading ? ( // Check if there are no messages
            <div className="h-[100%] flex items-center justify-center">
              <ScaleLoader height={28} color="#1569EB" loading={true} />
            </div>
          ) : messagesList?.length === 0 ? (
            <>
              <div className="h-[100%] flex items-center justify-center"></div>
            </>
          ) : (
            <ScrollableFeed>
              <MessageList
                className="message-list"
                lockable={true}
                toBottomHeight={"100%"}
                dataSource={messagesList?.map((message, index) => ({
                  position:
                    message?.direction === "inbound-api" ? "left" : "right",
                  type: "text",
                  text: (
                    <>
                      {parse(
                        message?.body
                          ?.split("&lt;")
                          ?.join("<")
                          ?.split("&gt;")
                          ?.join(">"),
                      )}
                      {message.attachments &&
                        message.attachments.length > 0 && (
                          <div className="mt-2">
                            <strong>Attachments:</strong>
                            <div className=" flex items-center flex-wrap gap-2">
                              {message.attachments.map((attachment, i) => (
                                <div
                                  key={i}
                                  className="px-2 py-1 flex items-center gap-1 rounded-md bg-gray-300"
                                >
                                  <Link className="text-gray-700" size={14} />
                                  <a
                                    href={attachment.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {attachment.name}
                                  </a>
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                    </>
                  ),
                  date: message?.date_created,
                  status: "sent",
                  forwarded: true,
                  title:
                    message?.direction === "inbound-api"
                      ? userDetail?.name
                      : "You",
                  titleColor:
                    message?.direction === "inbound-api" ? "blue" : "green",
                  titleAlign:
                    message?.direction === "inbound-api" ? "left" : "right",
                  titleSize: 14,
                  titleWeight: "bold",
                  titleStyle: "italic",
                  dateColor: "black",
                  dateSize: 10,
                  dateWeight: "bold",
                }))}
                renderMessageContent={(message) => (
                  <div dangerouslySetInnerHTML={{ __html: message.body }} />
                )}
              />
            </ScrollableFeed>
          )}

          {showemailOption ? (
            <div
              className="bg-white  mb-[12px] flex-grow  px-[12px] rounded-[8px] relative  w-full shadow-searchboxShadow min-h-[360px] overflow-y-auto"
              ref={emailOptionRef}
            >
              <button
                className="absolute top-1 right-1.5 p-2 bg-[#F3F5F9] text-[#1369E9] rounded-full"
                onClick={() => setShowemailOption(false)}
              >
                <IoMdArrowDropdown size={20} />
              </button>
              {/* Subject line */}
              <div className="flex flex-col mt-2 gap-2 border-b-2 border-gray-400 ">
                <div className="w-[95%]">
                  <input
                    type="text"
                    className="w-full p-2 outline-none text-gray-700 placeholder-gray-600 text-base"
                    placeholder="Enter Subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                </div>
                <div className="w-[95%] relative flex flex-col">
                  <div className="flex  flex-wrap items-center rounded-[8px] p-2">
                    <p className="mr-2">Cc</p>
                    {selectedCcOptions?.map((option, index) => (
                      <div
                        key={index}
                        className="flex items-center bg-[#F3F5F9] rounded-full gap-2 pr-2 m-1 border-2 border-gray-300"
                      >
                        <InitialsLogo
                          str={option}
                          fontSize="12px"
                          width="20px"
                          height="20px"
                          borderRadius="50%"
                        />
                        <span className="text-sm">{option}</span>
                        <button
                          onClick={() => handleRemoveCcOption(index)}
                          className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 32 32"
                            fill="none"
                          >
                            <path
                              d="M24 24L8 8M24 8L8 24"
                              stroke="#001B4B"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                    <input
                      id="cc-input"
                      type="email"
                      className="flex-grow focus:bg-white outline-none text-sm p-1 placeholder-gray-600 text-base"
                      placeholder={
                        selectedCcOptions.length === 0
                          ? "Add email addresses"
                          : ""
                      }
                      value={inputValue}
                      onChange={handleCcInputChange}
                      onKeyPress={handleCcKeyPress}
                    />
                  </div>
                  <div className="relative">
                    {recommendedEmails?.length > 0 && (
                      <ul className="absolute z-10 left-0 right-0 mt-1 border border-gray-200 rounded-md shadow-lg bg-white max-h-48 overflow-y-auto">
                        {recommendedEmails.map((email, index) => (
                          <li
                            key={index}
                            className="px-3 py-2 hover:bg-gray-200 cursor-pointer text-base"
                            onClick={() => handleCcOptionClick(email)}
                          >
                            {email}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              </div>
              <>
                <div className="quill custom-quill-editor relative">
                  <div ref={editorRef} />
                  <button
                    className="absolute bottom-2.5 z-100 right-[40px] "
                    onClick={() => sendMessage()}
                  >
                    {loadSendMessages ? (
                      <div className="transition-opacity duration-300 ease-in-out">
                        {getTransmissionMsg()}
                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M7.92668 3.52389L15.06 7.09056C18.26 8.69056 18.26 11.3072 15.06 12.9072L7.92668 16.4739C3.12668 18.8739 1.16835 16.9072 3.56835 12.1156L4.29335 10.6739C4.47668 10.3072 4.47668 9.69889 4.29335 9.33222L3.56835 7.88223C1.16835 3.09056 3.13502 1.12389 7.92668 3.52389Z"
                          fill="#1369E9"
                        />
                        <path
                          d="M10 9.98571L3 8.5V11.1L10 9.98571Z"
                          fill="white"
                        />
                      </svg>
                    )}
                  </button>
                </div>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  multiple
                />
                <div className="flex flex-wrap my-4 gap-2 ">
                  {attachments?.map((file, index) => (
                    <div
                      key={index}
                      className="px-4 py-2 rounded-md flex items-center gap-4 bg-defaultBg rounded-lg"
                    >
                      <span className="text-sm font-semibold">{file.name}</span>
                      <button
                        className="remove-attachment text-red-700"
                        onClick={() => removeAttachment(index)}
                      >
                        ✖
                      </button>
                    </div>
                  ))}
                </div>
              </>
            </div>
          ) : (
            <div className="bg-[#fff] mb-[12px]  px-[12px] rounded-[8px] shadow-searchboxShadow">
              <div className="flex items-center py-[9px]">
                <span className="w-[18px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <g id="Group 427319278">
                      <path
                        id="Star 8"
                        d="M5.54474 2.67969C6.04291 5.38384 8.15878 7.49971 10.8629 7.99787C8.15878 8.49603 6.04291 10.6119 5.54474 13.3161C5.04658 10.6119 2.93071 8.49603 0.226562 7.99787C2.93071 7.49971 5.04658 5.38384 5.54474 2.67969Z"
                        fill="#5F6989"
                      />
                      <path
                        id="Star 9"
                        d="M12.0904 0.632812C12.282 1.67287 13.0958 2.48667 14.1358 2.67827C13.0958 2.86987 12.282 3.68366 12.0904 4.72372C11.8988 3.68366 11.085 2.86987 10.0449 2.67827C11.085 2.48667 11.8988 1.67287 12.0904 0.632812Z"
                        fill="#5F6989"
                      />
                      <path
                        id="Star 10"
                        d="M12.9086 9.63281C13.1768 11.0889 14.3161 12.2282 15.7722 12.4964C14.3161 12.7647 13.1768 13.904 12.9086 15.3601C12.6403 13.904 11.501 12.7647 10.0449 12.4964C11.501 12.2282 12.6403 11.0889 12.9086 9.63281Z"
                        fill="#5F6989"
                      />
                    </g>
                  </svg>
                </span>
                <textarea
                  className=" w-[calc(100%-35px)] min-h-[20px] max-h-[80px] py-[9px] resize-none overflow-hidden bg-transparent p-[6px]  outline-none auto-complete-input "
                  name=""
                  id=""
                  rows={1}
                  value={"Email Message"}
                  onFocus={(e) => setShowemailOption(true)}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  placeholder="Enter your message..."
                ></textarea>
                <span
                  className="cursor-pointer text-[#1369E9] text-[14px] font-medium not-italic leading-[24px]  p-[6px] "
                  disabled={loadSendMessages}
                  onClick={(e) => {
                    sendMessage();
                    if (message.length > 0) {
                    }
                  }}
                >
                  {loadSendMessages ? (
                    <>Sending...</>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.92668 3.52389L15.06 7.09056C18.26 8.69056 18.26 11.3072 15.06 12.9072L7.92668 16.4739C3.12668 18.8739 1.16835 16.9072 3.56835 12.1156L4.29335 10.6739C4.47668 10.3072 4.47668 9.69889 4.29335 9.33222L3.56835 7.88223C1.16835 3.09056 3.13502 1.12389 7.92668 3.52389Z"
                        fill="#1369E9"
                      />
                      <path
                        d="M10 9.98571L3 8.5V11.1L10 9.98571Z"
                        fill="white"
                      />
                    </svg>
                  )}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          no chat there
        </div>
      )}
    </main>
  );
}

function SmsList({
  getallchats,
  chatList,
  getChatdetail,
  setLoading,
  seenNotificationUpdate,
  setTo,
  setUserDetail,
  userDetail,
  setNewUserName,
  setNewUserNumber,
  newUserNumber,
  notification,
  setNotification,
}) {
  const [filterChatList, setFilterChatlist] = useState(chatList);

  useEffect(() => {
    setFilterChatlist(chatList);
  }, [chatList]);

  const [option, setOption] = useState("name");

  function searchItems(keyword) {
    // Convert the keyword to lowercase for case-insensitive search
    const lowerCaseKeyword = keyword?.toLowerCase();

    // Use the filter() method to find items that match the keyword

    if (option === "position") {
      const filteredItems = chatList?.filter((item) =>
        item?.position?.toLowerCase().includes(lowerCaseKeyword),
      );
      setFilterChatlist(filteredItems);
    } else {
      const filteredItems = chatList?.filter((item) =>
        item?.name?.toLowerCase().includes(lowerCaseKeyword),
      );
      setFilterChatlist(filteredItems);
    }
  }

  const [showInterviewModal, setShowInterviewModal] = useState(false);
  const [showfilteroption, setShowfilteroption] = useState(false);

  useEffect(() => {
    const sortedMessages = chatList.sort(
      (a, b) => new Date(b.lastSeen) - new Date(a.lastSeen),
    );
    setFilterChatlist(sortedMessages);
  }, [chatList]);

  const addNewsmsUser = async (toUserId) => {
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.POST_ADD_SMSUSER,
        1,
        {
          name: toUserId.length > 0 ? "" : newUserName,
          toUserId: toUserId,
          toPhone: toUserId.length > 0 ? "" : "+" + newUserNumber,
        },
      );

      if (json) {
        getallchats();
        setShowInterviewModal(false);
      }
    } catch {}
  };
  const [checkedItems, setCheckedItems] = useState([]);
  const [hoveredItem, setHoveredItem] = useState(null);

  const handleItemClick = (item) => {
    console.log("Item", filterChatList);
    getChatdetail(item?._id);
    setTo(item?._id);
    setUserDetail(item);
    setLoading(true);
    seenNotificationUpdate(item?._id);
    setNotification(notification?.filter((iter) => iter._id !== item._id));
  };

  const handleCheckboxChange = (e, itemId) => {
    e.stopPropagation();
    if (checkedItems?.includes(itemId)) {
      setCheckedItems(checkedItems?.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };
  console.log("Checked", checkedItems);
  const showCheckbox = checkedItems?.length > 0;
  return (
    <div className="mt-[26px] relative  h-[calc(100vh-134px)]  ">
      <div>
        {showfilteroption && (
          <div className="absolute bg-[#F3F5F9] flex flex-col rounded-[12px] shadow-menuOptionShadow z-10 w-[240px] px-[20px] py-[15px] top-[40px]">
            <span
              className="cursor-pointer py-[6px]"
              onClick={(e) => {
                setShowfilteroption(false);
                setOption("name");
              }}
            >
              {" "}
              By name
            </span>
            <span
              className="cursor-pointer py-[6px]"
              onClick={(e) => {
                setShowfilteroption(false);
                setOption("position");
              }}
            >
              {" "}
              By Job
            </span>
          </div>
        )}
      </div>
      <div className="relative w-[240px] flex items-center justify-between">
        <input
          type="text"
          placeholder="Search..."
          onChange={(e) => searchItems(e.target.value)}
          className="bg-[#F3F5F9] rounded-[8px] w-[195px] h-[32px] pl-4"
        />
        <button
          className="border-[2px] w-[32px]  rounded-[12px] flex justify-center items-center h-[32px] bg-[#1369E9] "
          onClick={() => {
            setShowInterviewModal(!showInterviewModal);
          }}
        >
          <span className="text-[16px] font-medium">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M8 9.33073V7.33073M8 7.33073V5.33073M8 7.33073H6M8 7.33073H10M4.74905 12.4648L3.73307 13.2776C3.17829 13.7214 2.90077 13.9435 2.66732 13.9437C2.46429 13.9439 2.2723 13.8516 2.14564 13.6929C2 13.5105 2 13.1553 2 12.4449V4.79753C2 4.05079 2 3.67714 2.14532 3.39193C2.27316 3.14104 2.47698 2.93722 2.72786 2.80939C3.01308 2.66406 3.38673 2.66406 4.13346 2.66406H11.8668C12.6135 2.66406 12.9867 2.66406 13.2719 2.80939C13.5228 2.93722 13.727 3.14104 13.8548 3.39193C14 3.67686 14 4.05006 14 4.79534V9.86645C14 10.6117 14 10.9844 13.8548 11.2693C13.727 11.5202 13.5229 11.7244 13.272 11.8522C12.9871 11.9974 12.6143 11.9974 11.8691 11.9974H6.08171C5.80435 11.9974 5.66552 11.9974 5.53288 12.0246C5.41519 12.0488 5.30144 12.0886 5.19445 12.1432C5.07433 12.2046 4.96642 12.2909 4.75148 12.4629L4.74905 12.4648Z"
                stroke="white"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </button>
      </div>
      <div>
        {/* {showCheckbox && (
          <div className="px-[15px] my-4 flex justify-end">
            <button className="text-red-900 flex items-center gap-2 border border-red-900 rounded-md px-1 py-0.5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4 shrink-0 mt-px"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                ></path>
              </svg>
              Delete ({checkedItems?.length})
            </button>
          </div>
        )} */}
      </div>
      <div className="overflow-y-scroll h-[calc(100vh-180px)] mt-[15px] scrollbar-candidate">
        {filterChatList?.map((item, idx) => (
          <div
            className={`flex p-[15px] ${item?._id === userDetail?._id ? "bg-[#F3F5F9] rounded-[8px] " : ""}  cursor-pointer`}
            key={idx}
            onClick={() => handleItemClick(item)}
            onMouseLeave={() => {
              setHoveredItem(null);
            }}
          >
            {/* {showCheckbox || hoveredItem == item?._id ? (
              <div className="px-2 py-1 bg-gray-200 rounded-md">
                <input
                  type="checkbox"
                  className="w-5 h-5 rounded-md hover:border-[2px] "
                  checked={checkedItems?.includes(item?._id)}
                  onChange={(e) => handleCheckboxChange(e, item?._id)}
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
            ) : ( */}
            <span
            // onMouseEnter={() => {
            //   setHoveredItem(item?._id);
            // }}
            // onMouseLeave={() => setHoveredItem(null)}
            >
              <InitialsLogo str={item?.name} />
            </span>
            {/* )} */}
            <div className="flex flex-col ml-[15px] w-full">
              <span className="flex justify-between items-center w-full">
                <span className="inline-block text-[14px] w-[130px] not-italic text-[#252E4B] font-medium truncate">
                  {item?.name}
                </span>
              </span>
              <span className="flex justify-between items-center ">
                {item?.lastMessage && (
                  <span
                    data-tooltip-id="tooltip"
                    data-tooltip-content={parse(item?.lastMessage)}
                    className="inline-block w-[130px] text-[13px] not-italic font-normal text-[#5F6989] truncate"
                  >
                    {parse(item?.lastMessage)}
                  </span>
                )}
                {notification &&
                  notification.length > 0 &&
                  notification?.filter((iter) => iter._id === item._id)[0]
                    ?.notificationCount > 0 && (
                    <span className="w-[18px] h-[18px] rounded-[50%] bg-[red] flex justify-center items-center mr-[8px] text-[10px] font-medium text-[#ffffff]">
                      {
                        notification?.filter((iter) => iter._id === item._id)[0]
                          ?.notificationCount
                      }
                    </span>
                  )}
              </span>
            </div>
          </div>
        ))}
      </div>

      {showInterviewModal && (
        <AddChatUserModal
          modalDismiss={() => setShowInterviewModal(false)}
          addNewsmsUser={addNewsmsUser}
          setNewUserName={setNewUserName}
          newUserNumber={newUserNumber}
          setNewUserNumber={setNewUserNumber}
        />
      )}
    </div>
  );
}
