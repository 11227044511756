import * as d3 from "d3";
import { useEffect } from "react";
import { ellipsis } from "./TextWrap";
import { Tooltip as ReactTooltip } from "react-tooltip";

export default function LineChart({
  id,
  data,
  width,
  chartHeight,
  color,
  clickFunction,
}) {
  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;
  var chartWidth = Math.max(width, data?.length * 125);
  data = data.filter((d) => !!d.value);

  useEffect(() => {
    try {
      // console.log(data, chartWidth, data?.length * 125);
      // On every page load
      //  Inner dimensions of the chart => excluding the axes

      var chartMargin = { top: 30, bottom: 20, left: 10, right: 30 },
        innerWidth = chartWidth - (chartMargin.left + chartMargin.right),
        innerHeight = chartHeight - (chartMargin.top + chartMargin.bottom);

      // Select SVG and set dimensions
      var svg = d3
        .select("svg.lineChart#" + id)
        .attr("width", chartWidth)
        .attr("height", chartHeight);

      // Enable scaling to fit data to chart dimensions
      var xScale = d3
        .scalePoint()
        .domain(data.map((d) => checkLabel(d.criteria, d.label)))
        .range([0, innerWidth])
        .padding(0);
      var yScale = d3.scaleLinear().domain([10, 0]).range([0, innerHeight]);

      // Add axes to chart
      // Set axes, scaled to chart dimensions
      var xAxis = d3.axisBottom().scale(xScale);
      var yAxis = d3.axisLeft().scale(yScale).ticks(5);
      // Remove previous/necessary g tags
      svg.selectAll("g").remove();
      // Add the axis into grpup and add it to svg
      svg
        .append("g")
        .classed("xAxis", true)
        .call(xAxis)
        .attr(
          "transform",
          "translate(" +
            chartMargin.left +
            "," +
            String(innerHeight + chartMargin.top) +
            ")",
        )
        .selectAll(".tick text")
        .call(ellipsis, innerWidth / data.length - 10, data?.length)
        // .attr("style", "text-anchor:start")
        .attr("style", (_, e) => {
          if (e == 0) return "text-anchor:start";
          if (e == data?.length - 1) return "text-anchor:end";
          return "";
        });
      // .attr("transform", "translate(0,0)");
      svg
        .append("g")
        .call(yAxis)
        .classed("yAxis", true)
        .attr(
          "transform",
          "translate(" + chartMargin.left + "," + chartMargin.top + ")",
        );
      svg.selectAll(".yAxis .tick").remove();
      // Tooltip defination
      // d3.selectAll(".tooltip").remove();
      // var tooltip;
      // The line graph
      // Structure of line
      var line = d3
        .line()
        .x((d) => {
          return xScale(checkLabel(d.criteria, d.label)) + chartMargin.left;
        })
        .y((d) => {
          return yScale(d.value) + chartMargin.top;
        });
      // Line group
      var graph_line = svg
        .append("g")
        .classed("graph_line", true)
        .attr("width", innerWidth)
        .attr("height", innerHeight);
      // Adding data per data in group
      graph_line
        .append("path")
        .datum(data)
        .attr("class", "line")
        .attr("d", line)
        .style("fill", "none")
        .style("stroke", color)
        .style("strokeWidth", "2");

      // Pointer/Circles at every data point
      // Circle group
      var graph_circle = svg
        .append("g")
        .classed("graph_circle", true)
        .attr("width", innerWidth)
        .attr("height", innerHeight);
      // Circles added to group (inner smaller circle)
      graph_circle
        .selectAll("label")
        .data(data)
        .enter()
        .append("text")
        .text((d) => d?.value)
        .attr("x", function (d, i) {
          return Math.max(
            xScale(checkLabel(d.criteria, d.label)) + chartMargin.left,
            125 * i + chartMargin.left,
          );
        })
        .attr("y", function (d) {
          return yScale(d.value) + chartMargin.top - 12;
        })
        .style("fill", "black");
      graph_circle
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("cx", function (d, i) {
          return Math.max(
            xScale(checkLabel(d.criteria, d.label)) + chartMargin.left,
            125 * i + chartMargin.left,
          );
        })
        .attr("cy", function (d) {
          return yScale(d.value) + chartMargin.top;
        })
        .attr("r", 10)
        .style("fill", "white")
        .style("stroke", color)
        .style("strokeWidth", "2");
      // Circles outline feature added to group
      graph_circle
        .selectAll("dot")
        .data(data)
        .enter()
        .append("circle")
        .attr("class", "data-point")
        .attr("id", (_, i) => {
          return "p" + i;
        })
        .attr("cx", function (d, i) {
          return Math.max(
            xScale(checkLabel(d.criteria, d.label)) + chartMargin.left,
            125 * i + chartMargin.left,
          );
        })
        .attr("cy", function (d) {
          return yScale(d.value) + chartMargin.top;
        })
        .attr("r", 6)
        .style("fill", color)
        .style("cursor", "pointer");
      // .on("mouseover", (e, i) => {
      //   tooltip = d3
      //     .select("body")
      //     .append("div")
      //     .classed("tooltip", true)
      //     .text(() => {
      //       return "Result : " + i.value + "/10";
      //     })
      //     .style("display", "block")
      //     .style("top", e.y + "px")
      //     .style("left", e.x + 25 + "px");
      // })
      // .on("mousemove", (e) => {
      //   tooltip
      //     .style("display", "block")
      //     .style("top", e.y + "px")
      //     .style("left", e.x + 25 + "px");
      // })
      // .on("mouseout", function (e) {
      //   d3.selectAll(".tooltip").remove();
      // })
      // .on("click", (e, d) => {
      //   if (clickFunction) {
      //     //  Increasing circle size: - Step1 > Make sure all points have radius 6; Step2 > Increase radius of clicked point
      //     graph_circle.selectAll("circle.data-point").attr("r", 6);
      //     d3.select(e.target).attr("r", 7.5);
      //     //  Bolden criteria: - Step1 > Make sure all criterias have normal weight; Step2 > Increase weight of clicked point criteria, by getting the number
      //     var iter = +e.target.id.split("p").at(-1);
      //     svg.selectAll(".xAxis .tick text tspan").style("font-weight", 600);
      //     svg
      //       .select(
      //         ".xAxis .tick:nth-of-type(" +
      //           (iter + 1) +
      //           ") text tspan:last-of-type"
      //       )
      //       .style("font-weight", 800);
      //     clickFunction(checkLabel(d.criteria, d.label));
      //   }
      // });

      svg
        .selectAll(".xAxis .tick")
        .data(data)
        .attr("data-tooltip-id", "lineTextTooltip")
        .attr("data-tooltip-content", (d) => d?.criteria);
    } catch (err) {
      console.error(err);
    }
  }, [chartWidth, data]);

  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        words = text.text().split(/\s+/).reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")),
        tspan = text
          .text(null)
          .append("tspan")
          .attr("x", 0)
          .attr("y", y)
          .attr("dy", dy + "em");
      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }

  return (
    data.length > 0 && (
      <>
        <svg id={id} className="lineChart mb-5" />
        <ReactTooltip
          id="lineTextTooltip"
          noArrow={false}
          style={{
            zIndex: 9999,
            background: "#5F6989",
            fontSize: "12px",
            padding: "4px 8px",
            fontWeight: "400",
            color: "white",
          }}
          place="bottom"
        />
      </>
    )
  );
}
