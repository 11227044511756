import React from "react";
import { async } from "q";
import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { ScaleLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MdClear } from "react-icons/md";
import { FaRegFile } from "react-icons/fa";
import { MdArrowDropDown } from "react-icons/md";

const core = require("../../lib/core");

export default function FileUploader({
  dataUploaded,
  setDataUploaded,
  disbaleUploadResume,
  setDisableUploadresume,
  getIntDashboard,
  jobIdStatus,
  singleUpload,
  currentPath,
  disbaleUploadcandidate,
  inputdata,
  setInput,
  add_applicant,
  setApplicantData,
  applicantData,
  resumeFile,
  loadingAdd,
  setShowUploader,
}) {
  const { user } = useContext(core.UserContext);
  const [loading, setLoading] = useState(false);

  const loc = useLocation();

  useEffect(() => {
    setInput("jobId", loc.pathname.split("/").at(-1).split("&")[0]);
    setApplicantData({
      ...applicantData,
      ["job"]: loc.pathname.split("/").at(-1).split("&")[0],
    });
  }, [resumeFile]);

  const [resumeFileArray, setResumeFileArray] = useState();
  const [errMsg, setErrorMsg] = useState(null);

  console.log(currentPath);

  useEffect(() => {
    setResumeFileArray(resumeFile);
  }, [resumeFile]);

  const { jobId } = useSelector((state) => {
    return {
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  async function upload_resume() {
    setErrorMsg(null);
    setDisableUploadresume(true);
    try {
      if (resumeFile == "Upload Resume") {
        alert("Please select a resume");
      }
      setLoading(true);
      let formData = new FormData();
      for (let i = 0; i < resumeFileArray.length; i++) {
        formData.append("files", resumeFileArray[i]);
      }
      if (jobIdStatus != false) {
        formData.append("jobId", jobId);
      }

      console.log(formData);
      const token = await core.getData(core.data.ACCESS_TOKEN);
      await fetch(core.RECRUITER_API.POST_MULTIPLE_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.error) {
            setErrorMsg("Failed to upload");

            setDataUploaded(false);
            setDisableUploadresume(false);
            setLoading(false);
            return;
          }
          console.log(data?.data?.link);
          setInput("resumeLink", data?.data?.link);
          setApplicantData({
            ...applicantData,
            ["resumeLink"]: data?.data?.link,
          });
          setLoading(false);
          setDataUploaded(true);
        })
        .catch((err) => {
          setErrorMsg("Failed to upload");
          console.log(err);

          setDataUploaded(false);
          setDisableUploadresume(false);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setErrorMsg(err);
      setDataUploaded(false);
      setDisableUploadresume(false);
      setLoading(false);
    }
  }

  async function upload_single_resume() {
    // setLoading(true)
    var formData = new FormData();
    formData.append("file", resumeFileArray[0]);
    const token = await core.getData(core.data.ACCESS_TOKEN);

    try {
      if (resumeFile == "Upload Resume") {
        alert("Please select a resume");
      }
      setLoading(true);
      await fetch(core.CANDIDATE_API.POST_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          setDisableUploadresume(true);
          setLoading(false);
          setDataUploaded(true);
          console.log("success");
          getIntDashboard();
        });
    } catch (err) {
      setDisableUploadresume(false);
      setLoading(false);
      toast.error("there is something error");
      console.log("success");
    }
  }

  const [height, setHeight] = useState("auto");
  const [showall, setShowall] = useState(true);

  const down = () => {
    if (height == "auto") {
      setHeight("60px");
      setShowall(!showall);
    } else {
      setHeight("auto");
      setShowall(!showall);
    }
  };

  console.log(resumeFileArray);

  return (
    <div
      className="col-xs-12 no-padding"
      id="fileUpload"
      style={{ height: height }}
    >
      <form onSubmit={add_applicant}>
        <div className="w-full py-2 px-4  flex items-center justify-between bg-[#e0ebff] ">
          {/* Total Files */}
          <span className="text-[16px] font-medium">
            Total files: {resumeFileArray?.length ?? 0}
          </span>

          {dataUploaded ? (
            <span className="text-green-700 font-medium text-[16px]">
              Uploaded successfully
            </span>
          ) : (
            errMsg && <span className="text-red-500">{errMsg}</span>
          )}

          {/* Upload Button */}
          {!dataUploaded && (
            <div className="flex items-center gap-2 flex-row justify-normal ">
              <button
                className={`py-2 px-16 flex items-center justify-center bg-blue-600 hover:bg-blue-800 text-white rounded ${disbaleUploadResume ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={disbaleUploadResume}
                type="button"
                onClick={singleUpload ? upload_single_resume : upload_resume}
              >
                {loading ? (
                  <ScaleLoader height={14} color="#fff" loading={true} />
                ) : (
                  "Upload"
                )}
              </button>
              <div className="flex items-center gap-2">
                {/* Toggle View Button */}
                <button onClick={down} type="button" className="">
                  {showall ? (
                    <svg
                      x="0px"
                      y="0px"
                      width="14px"
                      height="14px"
                      viewBox="0 0 24 24"
                      focusable="false"
                    >
                      <path
                        fill="currentColor"
                        d="M21.17,5.17L12,14.34l-9.17-9.17L0,8l12,12,12-12z"
                      ></path>
                    </svg>
                  ) : (
                    <svg
                      x="0px"
                      y="0px"
                      width="14px"
                      height="14px"
                      viewBox="0 0 24 24"
                      focusable="false"
                    >
                      <path
                        fill="currentColor"
                        d="M2.83,18.83L12,9.66l9.17,9.17L24,16,12,4,0,16z"
                      ></path>
                    </svg>
                  )}

                  {/* <MdArrowDropDown
                  size={24}
                  className="text-blue-500 hover:text-blue-700"
                /> */}
                </button>

                {/* Close Button */}
                <button className="" onClick={() => setShowUploader(false)}>
                  <MdClear
                    size={24}
                    className="text-red-500 hover:text-red-700"
                  />
                </button>
              </div>
            </div>
          )}

          {/* Upload Status */}
          {dataUploaded && (
            <div className="flex items-center gap-2">
              {/* Toggle View Button */}
              <button onClick={down} type="button" className="">
                {showall ? (
                  <svg
                    x="0px"
                    y="0px"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    focusable="false"
                  >
                    <path
                      fill="currentColor"
                      d="M21.17,5.17L12,14.34l-9.17-9.17L0,8l12,12,12-12z"
                    ></path>
                  </svg>
                ) : (
                  <svg
                    x="0px"
                    y="0px"
                    width="14px"
                    height="14px"
                    viewBox="0 0 24 24"
                    focusable="false"
                  >
                    <path
                      fill="currentColor"
                      d="M2.83,18.83L12,9.66l9.17,9.17L24,16,12,4,0,16z"
                    ></path>
                  </svg>
                )}

                {/* <MdArrowDropDown
                  size={24}
                  className="text-blue-500 hover:text-blue-700"
                /> */}
              </button>

              {/* Close Button */}
              <button className="" onClick={() => setShowUploader(false)}>
                <MdClear
                  size={24}
                  className="text-red-500 hover:text-red-700"
                />
              </button>
            </div>
          )}
        </div>

        <div className="col-xs-12 bottom no-padding">
          {dataUploaded ? (
            <></>
          ) : (
            <>
              {resumeFileArray?.map((item, idx) => (
                <div
                  className="col-xs-12 no-padding list-resume py-2 bg-gray-50 rounded hover:bg-gray-100 transition-colors mb-1"
                  key={idx}
                >
                  <span className="col-xs-12 no-padding flex items-center">
                    <FaRegFile
                      size={20}
                      className="mr-2 text-gray-500 flex-shrink-0"
                    />
                    <span className="text-sm flex-grow min-w-0 overflow-hidden whitespace-nowrap text-ellipsis">
                      {item?.name}
                    </span>
                  </span>
                  <button
                    className="remove-resume-button text-red-500 hover:text-red-700"
                    type="button"
                    disabled={disbaleUploadResume}
                    onClick={(e) => {
                      var newArray = [...resumeFileArray];
                      newArray.splice(idx, 1);
                      setResumeFileArray(newArray);
                    }}
                  >
                    {<MdClear size={20} />}
                  </button>
                </div>
              ))}
            </>
          )}
        </div>
      </form>
    </div>
  );
}
