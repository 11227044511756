import { useEffect, useRef, useState } from "react";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";
import { CONTENT } from "../../../lib/constants";
const core = require("../../../lib/core");

const ApplyToJobModal = ({ id, candidate, modalDismiss }) => {
  const jobList = useSelector((state) => state?.JobExpandSlice?.jobPositions);
  const ref = useRef();
  const [showDropdown, setShowDropDown] = useState(false);
  const [searchList, setSearchList] = useState(jobList);
  const [jobSelected, setJobSelected] = useState([]);
  const [message, setMessage] = useState({});

  console.log(candidate);

  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (ref.current) {
        if (!ref.current.contains(e.target)) setShowDropDown(false);
        else setShowDropDown(true);
      }
    };
    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);
    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const handleSearch = (e) => {
    let jobRemaining = jobList.filter((job) => !jobSelected?.includes(job));
    let term = String(e.target.value);
    if (term?.length == 0) setSearchList(jobRemaining);
    let jobs = jobRemaining.filter((job) =>
      String(job?.name)
        .trim()
        .toLowerCase()
        .includes(term.trim().toLowerCase()),
    );
    setSearchList(jobs);
  };

  const selectJob = (job) => {
    if (jobSelected?.includes(job)) return;
    setJobSelected((prev) => [...prev, job]);
    ref.current.value = "";
  };

  const [applied, setApplied] = useState(false);

  const handleSubmit = async () => {
    try {
      const data = jobSelected?.map((job) => {
        return { jobId: job?._id, canidate_details_id: candidate };
      });
      await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_ATTACH_CANDIDATE_JOB,
        1,
        {
          data: data,
        },
      );
      setMessage({
        type: "success",
        message: CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.SUCCESS,
      });
      setApplied(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      heading={CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.HEADING}
      subheading={CONTENT.MODAL_CONTENT.ATTACH_CANDIDATE_TO_JOB.SUBHEADING}
      onSave={handleSubmit}
      dismissOnSave={false}
      z
      noBtns={applied}
    >
      {!applied && (
        <>
          <div className="w-full">
            <input
              className="w-full h-10 px-4 border-gray-500 border-2 rounded-lg"
              ref={ref}
              type="text"
              onChange={handleSearch}
            />
            {showDropdown && (
              <div className="bg-white shadow w-full top-[50%] left-40 py-1 rounded-lg max-h-[360px] z-[9990] overflow-auto list-none cursor-pointer">
                {searchList?.map((job) => (
                  <li
                    className="text-sm py-2 px-3 hover:bg-blue-100"
                    onClick={() => selectJob(job)}
                  >
                    {job?.name}
                  </li>
                ))}
              </div>
            )}
          </div>
          <div className="w-full flex flex-wrap gap-2">
            {jobSelected?.map((job) => (
              <span
                className="inline-block px-2 py-1 rounded-lg shadow border border-gray-300 bg-blue-100"
                key={job?._id}
              >
                {job?.name}
              </span>
            ))}
          </div>
        </>
      )}

      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
};

export default ApplyToJobModal;
